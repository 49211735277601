<div fxLayout="column" [ngClass]="{'w360' : callFrom == 'ConfirmFeedbackDialogComponent'}">
  <ng-container>
    <div fxFlex class="file-detail"
      *ngIf="(customField && customField.type == fieldType.Attachment && customField.value) || uploadedFileDetails != null">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="file">
        <div fxLayout="row" fxLayoutAlign="start center" >
          <div fxLayoutAlign="center center" class="extension">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.66797 1.33203H2.0013C1.64768 1.33203 1.30854 1.47251 1.05849 1.72256C0.808444 1.9726 0.667969 2.31174 0.667969 2.66536V13.332C0.667969 13.6857 0.808444 14.0248 1.05849 14.2748C1.30854 14.5249 1.64768 14.6654 2.0013 14.6654H10.0013C10.3549 14.6654 10.6941 14.5249 10.9441 14.2748C11.1942 14.0248 11.3346 13.6857 11.3346 13.332V5.9987M6.66797 1.33203L11.3346 5.9987M6.66797 1.33203V5.9987H11.3346"
                stroke="#AAADB8" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div fxLayout="column">
            <div *ngIf="customField.value || uploadedFileDetails != null">
              <div *ngIf="customField.details && customField.details?.downloadUrl" fxLayout="row" fxFlex>
                <div fxLayout="row"
                  class="file-name-exceeder"> <div class="name">{{customFieldService.getFileNameWithoutExtension(customField.details?.name)}}</div>
                  .{{customFieldService.getFileExtention(customField.details?.name).toLowerCase()}}</div>
              </div>
              <div *ngIf="customField.details && !customField.details?.downloadUrl && customField.details?.name"
                fxLayout="row" fxFlex>
                <div class="file-name-exceeder" fxLayout="row">
                  <div class="name">{{customFieldService.getFileNameWithoutExtension(customField.details?.name)}}</div>
                  .{{customFieldService.getFileExtention(customField.details?.name).toLowerCase()}}
                  
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <!-- <div>
                <a [downloadFile]="customField.details?.downloadUrl" download target="_blank">
                  <img src="../../../assets/images/{{customFieldService.getIconFileExtention(customField.details?.name)}}"
                    class="icon-extention-exceeder" />
                </a>
              </div> -->
              <div class="file-details-exceeder">
                  <!-- {{customFieldService.getFileExtention(customField.details?.name)}} -->
                  <span>
                    {{getFileSize(customField.details?.size)}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div fxLayoutAlign="end start" *ngIf="customField.details && !customField.details?.downloadUrl && customField.details?.name">
          <img src="assets/images/icons/close.svg" alt="close" class="imgRemove" (click)="removeFile()">
        </div> -->
        <div *ngIf="allowRemove && (customField.details && customField.details?.downloadUrl  && customField.details?.name)" fxLayoutAlign="end start">
          <mat-icon class="gray-icon" (click)="removeFile()">
            delete_outline
          </mat-icon>
        </div>
        <div *ngIf="!allowRemove && (customField.value || uploadedFileDetails != null)"  class="download-icon">
          <a [downloadFile]="customField.details?.downloadUrl" download target="_blank">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15M7 10L12 15M12 15L17 10M12 15V3"
                stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </a>

        </div>
      </div>
    </div>

    <div fxFlex class="file-detail"
      *ngIf="customField && customField.type == fieldType.Image && customField.details?.downloadUrl">
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
        <img class="image-preview cursor-pointer" [src]="customField.details.downloadUrl | secure | async"
          (click)="openPreview(customField.details.downloadUrl)">
        <div *ngIf="allowRemove" fxLayoutAlign="end start">
          <mat-icon class="gray-icon" (click)="removeFile()">
            delete_outline
          </mat-icon>
        </div>
      </div>
    </div>
  </ng-container>
  <mat-error *ngIf="maxFileSizeError">
    {{"Error.Max-File" | translate}} {{orgSettings.allowedMaxAttatchmentSize}} MB
  </mat-error>
  <mat-error *ngIf="fileExtensionError">
    {{"Error.Field-Support" | translate}} {{getFormatedExtenssions()}}
  </mat-error>
  <mat-error *ngIf="fileNameError">
    {{ orgSettings.invalidFileNameMessage | translateToLocale}}
  </mat-error>
</div>