export class NameValue {
  name: string;
  description?: string= '';
  value: any;
  disabled?: boolean;
  icon? : string;
  type?: any;
}
export class GroupedNameValue {
  name: string;
  options: NameValue[] = [];
  disabled?: boolean;
}
