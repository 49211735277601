import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { RequestCacheService } from '../services/request-cache.service';

@Injectable()
export class RequestCacheInterceptor implements HttpInterceptor {

  constructor(private cache: RequestCacheService) { }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // continue if not cacheable.
    if (this.shouldReset(request)) {
      this.cache.delete(request)
    }
    if (!this.isCacheable(request)) { return next.handle(request); }

    const cachedResponse = this.cache.get(request);
    return cachedResponse ?
      of(cachedResponse) : this.sendRequest(request, next, this.cache);
  }
  isCacheable(request: HttpRequest<unknown>) {
    return this.shouldReset(request) || (request.method == 'GET' && request.headers.get('cache-request'))
  }
  shouldReset(request: HttpRequest<unknown>) {
    return request.method == 'GET' && request.headers.get('reset-cache');
  }
  sendRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCacheService): Observable<HttpEvent<any>> {

    // No headers allowed in npm search request
    const noHeaderReq = request.clone({ headers: new HttpHeaders() });

    return next.handle(noHeaderReq).pipe(
      tap(event => {
        // There may be other events besides the response.
        if (event instanceof HttpResponse) {
          cache.put(request, event); // Update the cache.
        }
      })
    );
  }

}
