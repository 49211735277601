import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormControl, Validators, FormGroup } from '@angular/forms';
import { LabelService, ListModel, LabelModel } from '../../common/services/label-service';

import { AccountService } from '../services/account.service'
import { Roles } from '../../common/enums/roles-enum';
import { ThemeService } from '../../common/services/theme.service';
import { SystemSettingsService } from '../../common/services/system-settings-service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { InvitationLinkInformation } from '../../models/invitation-link-information';
import { MessageService } from '../../common/services/message-service';
import { UserInfoService } from '../../common/services/userinfo-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register-applicant',
  templateUrl: './register-applicant.component.html',
  styleUrls: ['./register-applicant.component.less']
})
export class RegisterApplicantComponent implements OnInit {

  isLoginError: boolean = false;
  isCallBack: boolean = false;
  isEmailSent: boolean = false;
  userType: string = '';
 
  invitationLinkInformation: InvitationLinkInformation = new InvitationLinkInformation;

  constructor(public labelService: LabelService, private accountService: AccountService, private router: Router,
    public themeService: ThemeService
    , private _systemSettingsService: SystemSettingsService
    , private _translationHelperService: TranslationHelperService
    , private _dataSharingService: DataSharingService
    , private _messageService: MessageService
    , private _userInfoService: UserInfoService
    ,public translate: TranslateService
    , private _route: ActivatedRoute) {
    this._dataSharingService.invitationLinkInformation.subscribe(resp => {
      this.invitationLinkInformation = resp;
      if (this.invitationLinkInformation.organizationName != "") {
        this.organization.setValue(this.invitationLinkInformation.organizationName);
      }
    });

  }

  fullName = new FormControl('', [Validators.required, Validators.minLength(3)]);
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  organization = new FormControl({ value: this.invitationLinkInformation.organizationName, disabled: true }, [Validators.required, Validators.minLength(3)]);

  ngOnInit() {
    if (this._systemSettingsService.systemSettings.enableRegistration.toLocaleLowerCase() == 'false') {
      this.router.navigateByUrl('/login');
    }
    let email = this._route.snapshot.paramMap.get('email');
    if (email) {
      this.email.setValue(email);
    }
    sessionStorage.clear();
    //localStorage.clear();
    this.labelService.updateClientLabel();
  }

  getFullnameErrorMessage() {
    return this.fullName.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.FULLNAME-REQUIRED') :
      this.fullName.hasError('minlength') ? this._translationHelperService.getTranslationByKey('RequiredMessages.FULLNAME-MINIMUM-CHARACTERS') :
        '';
  }

  getEmailErrorMessage() {
    return this.email.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-REQUIRED') :
      this.email.hasError('email') ? this._translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-INVALID') :
        '';
  }

  getPasswordErrorMessage() {
    return this.password.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.PASSWORD-REQUIRED') :
      this.password.hasError('password') ? this._translationHelperService.getTranslationByKey('RequiredMessages.PASSWORD-MINIMUM-CHARACTERS') :
        '';
  }

  getOrganizationErrorMessage() {
    return this.organization.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.ORGANIZATION-REQUIRED') :
      this.organization.hasError('minlength') ? this._translationHelperService.getTranslationByKey('RequiredMessages.ORGANIZATION-MINIMUM-CHARACTERS') :
        '';
  }

  goto(comp) {
    if (comp == 'reset') {
      this.router.navigateByUrl('/forgotpassword')
    }
  }

  OnSubmit() {
    this.isLoginError = false;
    this.isEmailSent = false;
    if (this.fullName.valid && this.email.valid) {
      this.isCallBack = true;

      let data = {
        name: this.fullName.value,
        email: this.email.value,
        definitionKey: this.invitationLinkInformation.definitionKey,
        invitationKey: this.invitationLinkInformation.invitationKey,
        password: this.password.value
      }

      this.accountService.applicantRegister(data).subscribe((data: any) => {
        this.isCallBack = false;
        if (data.status == 3) { //3 activated, 5 created but not activated  
          this.authenticateUser(data.personaId);
        } else {
          this.isEmailSent = true;
        }
      },
        (err: HttpErrorResponse) => {
          this.isCallBack = false;
          if (err.status == 409 && err.error.code == "Email-Exists") {
            this._messageService.showMessage(this._translationHelperService.getTranslationByKey('Registeration.APPLICANT-ALREADY-EXIST'));
          } else {
            this._messageService.showMessage(this._translationHelperService.getTranslationByKey('Common.ERROR'));
          }
        });
    }
  }

  private authenticateUser(personaId: number = 0) {
    this.accountService.userAuthentication(this.email.value, this.password.value, 0).subscribe((data: any) => {
      var personas = JSON.parse(data.personas);
      var result = {
        token: {
          accessToken: data.access_token,
          expiry: new Date(data['.expires']),
          refreshToken: data.refresh_token,
          persoans: personas
        },
        user: {
          name: data.name,
          email: data.email,
          role: data.role,
          imageUrl: data.imageUrl,
          organizationId: data.organizationId
        }
      };

      sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));
      this._userInfoService.update();
      if (this.userType != Roles.Applicant) {
        this.translate.use('en');
      }
     
      if (this._userInfoService.orgPersonas.length > 1) {
        this.accountService.userAuthentication(this._userInfoService.email, "", personaId).subscribe((data: any) => {
          this._userInfoService.setAuthorizationData(data);
        });
      } else {
        this.isCallBack = false;
        this._userInfoService.setAuthorizationData(data);
      }

      this._dataSharingService.systemLanguages.subscribe(systemLanguages => {
        this._translationHelperService.getOrganizationLocales().subscribe(resp => {
          let activeLocales = resp.body.split(',');
          if (activeLocales.length > 0) {
            systemLanguages.forEach(systemLanguage => {
              if (!systemLanguage.isDefault)
                systemLanguage.isActive = false;
            });
            systemLanguages.forEach(systemLanguage => {
              activeLocales.forEach(activeLocale => {
                if (systemLanguage.locale == activeLocale) {
                  systemLanguage.isActive = true;
                }
              })
            });
          }
        });

      });

    },
      (err: HttpErrorResponse) => {
        this.isCallBack = false;
      });
  }
}
