import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentName } from '../../common/enums/component-names-enum';
import { ActivatedRoute } from '@angular/router';
import { CustomizationSettingsSubmitModel } from '../../models/CustomizationSettingsSubmitModel';
import { AccountService } from '../services/account.service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../../common/services/message-service';
import { ApplicantDTO } from '../../models/ApplicantDTO';
import { ApplicantRequestService } from '../../applicant-request/services/applicant-request.service';
import { SubmitApplicantRequestComponent } from '../../applicant-request/submit-applicant-request/submit-applicant-request.component';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { UserInfoService } from '../../common/services/userinfo-service';
import { CommonHelperService } from '../../common/services/common-helper.service';

@Component({
  selector: 'app-submit-request-v2',
  templateUrl: './submit-request-v2.component.html',
  styleUrls: ['./submit-request-v2.component.less']
})
export class SubmitRequestV2Component implements OnInit {
  public routeParams = { definitionKey: '', email: '', invitationKey: '' };
  componentName: typeof ComponentName = ComponentName;
  componentCallTo: typeof ComponentCallTo = ComponentCallTo;
  public componentCallToString: string = '';
  public isUserLogin: boolean = false;
  public submitModel: CustomizationSettingsSubmitModel = new CustomizationSettingsSubmitModel();
  @ViewChild(SubmitApplicantRequestComponent) submitApplicantRequestComp: SubmitApplicantRequestComponent;
  constructor(private _route: ActivatedRoute
    , private accountService: AccountService
    , private _translationHelperService: TranslationHelperService
    , private _msgService: MessageService
    , private _applicantRequestService: ApplicantRequestService
    , public _dataSharingService: DataSharingService
    , private _userInfoService: UserInfoService
    ,public commonHelper: CommonHelperService) { }

  ngOnInit() {
    this.routeParams.definitionKey = this._route.snapshot.paramMap.get('definitionKey');
    this.routeParams.email = this._route.snapshot.paramMap.get('email');
    this.routeParams.invitationKey = this._route.snapshot.paramMap.get('invitationKey');
    this.componentCallToString = this.componentCallTo.ApplicantRequestAuthentication;
    sessionStorage.clear();
    this.isUserLogin = this.commonHelper.isUserLogIn();
  }

  changeComponentCall(data) {
    if (data.name && data.email) {
      this.submitModel.name = data.name;
      this.submitModel.emailAddress = data.email;
    }
    var callTo = data.callTo;
    if (callTo == this.componentCallTo.ApplicantRequestAuthentication) {
      this.componentCallToString = this.componentCallTo.ApplicantRequestAuthentication;
    }
    else if (callTo == this.componentCallTo.AddApplicantRequest) {
      this.componentCallToString = this.componentCallTo.AddApplicantRequest;
    }
    else if (callTo == this.componentCallTo.ApplicantProfile) {
      this.componentCallToString = this.componentCallTo.ApplicantProfile;
    }
  }

  private submitted: boolean = false;
  submitAll(event: boolean) {
    if (!this.submitted) {
      this.submitted = true;
      this.accountService.submitRequestUserAuthentication(this.submitModel.emailAddress, this.submitModel.name, this.routeParams.definitionKey).subscribe((data: any) => {
        var result = {
          token: {
            accessToken: data,
            expiry: '',
            refreshToken: '',
            persoans: []
          },
          user: {
            name: this.submitModel.name,
            email: this.submitModel.emailAddress,
            role: '',
            imageUrl: '',
            organizationId: 0
          }
        };
        sessionStorage.clear();
        sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));
        this._userInfoService.update();
        if (result.token.accessToken == '') {
          //this.customFeildList.formValues = this.mapPostValue();
          this._msgService.showMessage(this._translationHelperService.getTranslationByKey('LOGIN-ERROR-LOGIN'));
        } else {
          //call save profile here
          var applicantModel: ApplicantDTO = new ApplicantDTO();
          applicantModel.emailAddress = this.submitModel.emailAddress;
          applicantModel.name = this.submitModel.name;
          applicantModel.applicantSections = [];
          this._applicantRequestService.postApplicantDetails(this.submitModel, applicantModel).subscribe(resp => {
            this._dataSharingService.userFullName.next(this.submitModel.name);
            //submit application code here
            this.submitApplicantRequestComp.saveApplicationDefination();
          }, error => {

          });
        }
      },
        (err: HttpErrorResponse) => {

        });
    }
  }
}

export enum ComponentCallTo {
  ApplicantRequestAuthentication = "ApplicantRequestAuthentication",
  AddApplicantRequest = "AddApplicantRequest",
  ApplicantProfile = "ApplicantProfile",

}
