import { Pipe, PipeTransform } from '@angular/core';
import { FormSectionModel } from '../../models/CustomizationSettingsSubmitModel';
import { SectionVisibility } from '../enums/section-visibility-enum';


@Pipe({
  name: 'formSectionFilter'
})
export class FormSectionFilterPipe implements PipeTransform {
  public sectionVisibilityEnum = SectionVisibility;
  transform(sections: FormSectionModel[]): any {
    if (!sections) {
      return
    }
    return sections.filter(section => section.visibilityOn != this.sectionVisibilityEnum.None && section.visibilityOn != this.sectionVisibilityEnum.ApplicationDetails);
  }

}

@Pipe({
  name: 'formSectionDetailsFilter'
})
export class FormSectionDetailsFilterPipe implements PipeTransform {
  public sectionVisibilityEnum = SectionVisibility;
  transform(sections: FormSectionModel[]): any {
    if (!sections) {
      return
    }
    return sections.filter(section => section.visibilityOn != this.sectionVisibilityEnum.None && section.visibilityOn != this.sectionVisibilityEnum.SubmissionForm);
  }

}

@Pipe({
  name: 'sectionVisibleFilter',
  pure: false
})
export class FormSectionVisibleFilterPipe implements PipeTransform {
  transform(sections: FormSectionModel[]): any {
    if (!sections) {
      return
    }
    return sections.filter(section => section.isVisible != false);
  }

}


