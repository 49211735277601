import { Pipe, PipeTransform } from '@angular/core';
import { CustomizationSettingsSectionModel } from '../../models/CustomizationSettingsSubmitModel';
import { FeildType } from '../enums/field-type-enum';
import { CustomFieldLookupModel } from '../../profile-customization/Model/CustomFieldModel';

@Pipe({
  name: 'customFieldVisibleFilter',
  pure: false
})
export class CustomFieldVisibleFilterPipe implements PipeTransform {

  transform(customFields: any[]): any {
    if (!customFields) {
      return customFields;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return customFields.filter(fields => (fields.isVisible));
  }

}


@Pipe({
  name: 'profileSectionActiveAndVisibleFilter',
  pure: false
})
export class ProfileSectionActiveAndVisibleFilterPipe implements PipeTransform {

  transform(sections: CustomizationSettingsSectionModel[]): any {
    if (!sections) {
      return sections;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return sections.filter(section => section.isDefault || (section.isActive) && section.fields.filter(field => field.isVisible).length > 0);
  }

}

@Pipe({
  name: 'SectionsActiveAndVisibleFilter',
  pure: false
})
export class SectionActiveAndVisibleFilterPipe implements PipeTransform {

  transform(sections: any[]): any {
    if (!sections) {
      return sections;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return sections.filter(section => section.isDefault || (section.isActive) && section.fields.filter(field => field.isVisible).length > 0);
  }

}

@Pipe({
  name: 'customFieldDatabaseMapperFilter',
  pure: false
})
export class CustomFieldDatabaseMapperFilterPipe implements PipeTransform {

  transform(customFields: any[]): any {
    if (!customFields) {
      return customFields;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return customFields.filter(fields => (fields.type == FeildType.Text || fields.type == FeildType.MultiLine || fields.type == FeildType.SingleSelection || fields.type == FeildType.MultiSelection || fields.type == FeildType.Email || fields.type == FeildType.Hyperlink || fields.type == FeildType.PhoneNumber || fields.type == FeildType.Date || fields.type == FeildType.Number || fields.type == FeildType.Money || (fields.type == FeildType.LookupAddon)));
  }

}


@Pipe({
  name: 'LookupValuesSelectedFilter',
  pure: false
})
export class LookupValuesSelectedFilterPipe implements PipeTransform {

  transform(lookupValues: CustomFieldLookupModel[]): any {
    if (!lookupValues) {
      return lookupValues;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return (lookupValues.filter(field => field.isSelected).length > 0);
  }

}
