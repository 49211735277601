import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { OpenSubmittedApplicationService } from "../../../shared-modules/application-shared/services/open-submitted-application.service";
import { Location } from "@angular/common";

@Component({
  selector: "xcdrs-request-submitted-confirmation-dialog",
  templateUrl: "./request-submitted-confirmation-dialog.component.html",
  styleUrls: ["./request-submitted-confirmation-dialog.component.scss"],
})
export class RequestSubmittedConfirmationDialogComponent implements OnInit {
  isResubmitFromDetails = false;
  refApplicationId: number;
  refScfId: number; 
  isRecord = false;
  submitSuccessMessage : string = '';
  definitionId = 0; 
  assessmentId: number = 0;
  subDefApplicationId: number = 0;
  subDefApplicationName: string = '';
  subApplicationType: boolean = false;
  public reviseApplication : boolean = false;

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<RequestSubmittedConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _openSubmittedApplicationService : OpenSubmittedApplicationService,
    private location: Location,
  ) {
    this.isResubmitFromDetails = _data.isResubmitFromDetails;
    this.refApplicationId = _data.refApplicationId;
    this.refScfId = _data.refScfId; 
    this.isRecord = _data.isRecord;
    this.definitionId = _data.definitionId; 
    this.submitSuccessMessage = _data.submitSuccessMessage;
    this.assessmentId = _data.assessmentId;
    this.subDefApplicationId = _data.subDefApplicationId;
    this.subDefApplicationName = _data.subDefApplicationName
    this.subApplicationType = _data.subApplicationType;
    this.reviseApplication = _data.reviseApplication;

  }

  ngOnInit(): void { }

  closeDialog() {
    if (this.refApplicationId > 0) {
      this.router.navigate(
        // [`/applications/${this.refApplicationId}`],
        // { queryParams: { my: this._data.my, tab: this._data.tab } }
        ['/applications/' + this.refApplicationId +'/folder/' + this.definitionId + '/' + this.refScfId],
        { queryParams: { my: this._data.my, tab: this._data.tab, for: this._data.for, type: this.isRecord ? 'record' : ''  } }
      );
    } else if (this._data.feedOnBehalf) {
      if(this.isRecord){
        this.router.navigate(
          [`/records/${this._data.parentId}`],
          { queryParams: { my: this._data.my, tab: this._data.tab, type: 'record' } }
        );
      } else {
        this.router.navigate(
          [`/applications/${this._data.parentId}`],
          { queryParams: { my: this._data.my, tab: this._data.tab } }
        );
      }
    } else {
      if (!this.isResubmitFromDetails) {
        if (!this._data.isSubmission) {
          if(this.reviseApplication) {
            console.log('this._openSubmittedApplicationService.applicationId', this._openSubmittedApplicationService.applicationId);
            
            if(this.isRecord){
              this.router.navigate(
                [`/records/${this._openSubmittedApplicationService.applicationId}`],
                { queryParams: { my: this._data.my, tab: this._data.tab, type: 'record', revise: 'yes' } }
              );
            } else {
              this.router.navigate(
                [`/applications/${this._openSubmittedApplicationService.applicationId}`],
                { queryParams: { my: this._data.my, tab: this._data.tab, revise: 'yes'} }
              );
            }
          } else if(this._openSubmittedApplicationService.applicationId > 0 && this.assessmentId > 0){
            if(this.isRecord){
              this.router.navigate(
                [`/records/${this._openSubmittedApplicationService.applicationId}`],
                { queryParams: { my: this._data.my, tab: this._data.tab, type: 'record', asId: this.assessmentId, sAppId: this.subDefApplicationId, sAppName: this.subDefApplicationName, sbType: this.subApplicationType } }
              );
            } else {
              this.router.navigate(
                [`/applications/${this._openSubmittedApplicationService.applicationId}`],
                { queryParams: { my: this._data.my, tab: this._data.tab, asId: this.assessmentId, sAppId: this.subDefApplicationId, sAppName: this.subDefApplicationName, sbType: this.subApplicationType} }
              );
            }
            
          } else if (this._openSubmittedApplicationService.applicationId > 0) {
            if(this.isRecord){
              this.router.navigate(
                [`/records/${this._openSubmittedApplicationService.applicationId}`],
                { queryParams: { my: this._data.my, tab: this._data.tab, type: 'record' } }
              );
            } else {
              this.router.navigate(
                [`/applications/${this._openSubmittedApplicationService.applicationId}`],
                { queryParams: { my: this._data.my, tab: this._data.tab} }
              );
            }
          } else if(this.assessmentId > 0) {
            if(this.isRecord){
              this.router.navigate([`/records/${this.subDefApplicationId}`], {queryParams: {asId: this.assessmentId, isRecord: this.isRecord, sAppId: this.subDefApplicationId, sAppName: this.subDefApplicationName, sbType: this.subApplicationType}});
            }else{
              this.router.navigate([`/applications/${this.subDefApplicationId}`], {queryParams: {asId: this.assessmentId, isRecord: this.isRecord, sAppId: this.subDefApplicationId, sAppName: this.subDefApplicationName, sbType: this.subApplicationType}});
            }
          } else {
            if(this.isRecord){
              this.router.navigateByUrl("/records");
            }else{
              this.router.navigate([`/applications`], {queryParams: {tab: 1}});
            }
          }
        } else {
          this.router.navigate(
            [`/applications/submission/${this._data.id}/${this._data.parentId} `],
            { queryParams: { my: this._data.my, tab: this._data.tab } }
          );
        }
      }
    }
    this.dialogRef.close();
  }
}
