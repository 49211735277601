import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { EspSnackbarComponent } from '../../custom-material/esp-snackbar/esp-snackbar.component';

import { ToastAlertComponent } from '../../shared/toast-alert/toast-alert.component'
import { SnackbarType } from '../enums/common-enum';
import { DataSharingService } from './data-sharing.service';


@Injectable({
  providedIn: 'root'
})

export class MessageService {
  constructor(private _matSnackBar: MatSnackBar
    , public dataSharingService: DataSharingService) {

  }

  showMessage(message: string, duration: number = 5000, snackbarType: SnackbarType = SnackbarType.Success) {
    // this._matSnackBar.open(message, "", {
    //   duration,
    //   direction: this.dataSharingService.isRtl ? 'rtl' : 'ltr'
    // });
    let classToApply = 'snackbar-success';
    if (snackbarType == SnackbarType.Error) {
      classToApply = 'snackbar-error';

    }
    this._matSnackBar.openFromComponent(EspSnackbarComponent, {
      data: { message, snackbarType },
      duration,
      panelClass: [classToApply],
      direction: this.dataSharingService.isRtl ? 'rtl' : 'ltr',
      horizontalPosition: 'center',
      verticalPosition: 'top',

    })
  }

  showMessages(message: string, duration: number = 5000) {
    this._matSnackBar.open(message, "", {
      duration: duration,
      direction: this.dataSharingService.isRtl ? 'rtl' : 'ltr'
    });
  }

  showAlert(messageToShow: string, isSuccess: boolean) {
    this._matSnackBar.openFromComponent(ToastAlertComponent, {
      data: { textMessage: messageToShow, isSuccess: isSuccess },
      duration: 2000,
      panelClass: 'mat-snack-width',
    })
  }
}
