import { Injectable } from '@angular/core';
import { FormSectionModel } from '../../models/CustomizationSettingsSubmitModel';
import * as _ from 'lodash';
import { CustomFieldModel, Calculate, Reference, Condition } from '../../profile-customization/Model/CustomFieldModel';
import { CustomFieldService } from './custom-field.service';
import { CustomFieldValueModel } from '../../models/custom-field-value-model';
import { SectionValuesModel, SectionValueInstanceModel } from '../../models/section-values';
import { ApplicantSectionType } from '../enums/ApplicantSectionType';
import { FeildType, FieldTypes } from '../enums/field-type-enum';
import { LookupType } from '../enums/lookup-type-enum';
import { DynamicCurrencies, InvisibleFieldInfo } from '../../models/calculated-api-response.model';
import { lookup } from 'dns';
import { FormModel } from '../../definitions/models/application-definition';
import { ManageCurrenciesService } from '../../settings/manage-currencies/manage-currencies.service';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldFormService {

  constructor(private _customFieldService: CustomFieldService, 
    private _currencyService: ManageCurrenciesService
  ) {
  }

  static initNestedSections(sections: FormSectionModel[]) {
    sections.forEach((section: FormSectionModel, sectionIndex) => {
      let nestedSection: FormSectionModel = _.cloneDeep(section);
      nestedSection.fields.forEach((field: CustomFieldModel) => {
        field.id = parseInt(field.id + '' + sectionIndex);
      });
      section.nestedSections.push(nestedSection);
    });
    return sections;
  }

  static mapGenerateNestedSections(sections: FormSectionModel[], sectionValues: SectionValuesModel[], removeEmptyValueFields = false, resubmit: boolean = false) {
    sections.forEach((section: FormSectionModel, sectionIndex) => {
      section.nestedSections = [];
      if (sectionValues && sectionValues.length > 0) {
        let sectionValue: SectionValuesModel = _.find(sectionValues, { id: section.id });
        if (sectionValue == undefined) {
          let nestedSection: FormSectionModel = _.cloneDeep(section);
          nestedSection.fields.forEach((field: CustomFieldModel) => {
            field.id = parseInt(field.id + '' + sectionIndex);
          });
          nestedSection.fields = CustomFieldService.mapGetValueModel(nestedSection.fields, [], 0, removeEmptyValueFields, resubmit);
          CustomFieldFormService.pushNestedSection(section, nestedSection, removeEmptyValueFields);
        } else {
          sectionValue.instances.forEach((instance: SectionValueInstanceModel, instanceIndex) => {
            let nestedSection: FormSectionModel = _.cloneDeep(section);

            nestedSection.fields = CustomFieldService.mapGetValueModel(nestedSection.fields, instance.values, 0, removeEmptyValueFields, resubmit);
            nestedSection.fields = _.map(nestedSection.fields, function (field) {
              return _.assign(field, {
                id: parseInt(field.id + '' + instanceIndex)
              });
            });
            CustomFieldFormService.pushNestedSection(section, nestedSection, removeEmptyValueFields);
          });
        }
      } else {
        let nestedSection: FormSectionModel = _.cloneDeep(section);
        nestedSection.fields.forEach((field: CustomFieldModel) => {
          field.id = parseInt(field.id + '' + sectionIndex);
        });
        nestedSection.fields = CustomFieldService.mapGetValueModel(nestedSection.fields, [], 0, removeEmptyValueFields);
        CustomFieldFormService.pushNestedSection(section, nestedSection, removeEmptyValueFields);
      }

    });
    if (removeEmptyValueFields) {
      sections = sections.filter(sec => sec.nestedSections.length > 0);
    }
    return sections;
  }

  private static pushNestedSection(section: FormSectionModel, nestedSection: FormSectionModel, avoidEmptySection = false) {
    nestedSection.nestedSections = [];
    if (avoidEmptySection) {
      if (nestedSection.fields.filter(x => x.isVisible).length > 0) {
        section.nestedSections.push(nestedSection);
      }
    } else {
      section.nestedSections.push(nestedSection);
    }
  }
  static mapFormFieldsPostValue(sections: FormSectionModel[]): SectionValuesModel[] {
    // console.log('Sections',sections);
    let sectionValues: SectionValuesModel[] = [];
    sections.forEach((section: FormSectionModel, sectionIndex) => {
      if (section.type != ApplicantSectionType.ViewOnly) {
        let sectionValue: SectionValuesModel = new SectionValuesModel;
        sectionValue.id = section.id;
        sectionValue.instances = [];
        section.nestedSections.forEach((nestedSection: FormSectionModel, nestedSectionIndex) => {
          let sectionValueInstance: SectionValueInstanceModel = new SectionValueInstanceModel;
          sectionValueInstance.values = CustomFieldService.mapPostValueModel(nestedSection.fields, nestedSection.id);
          sectionValue.instances.push(sectionValueInstance);
        });
        sectionValues.push(sectionValue);
      }
    });
    return sectionValues;
  }

  static mapFormFieldsPostValueForCriteria(section: FormSectionModel): CustomFieldValueModel[] {
    let values: CustomFieldValueModel[] = [];
    values = CustomFieldService.mapPostValueModel(section.fields, section.id);
    return values;
  }

  assignValuesForMappedAndCalcualtedTypes(fieldValues: CustomFieldValueModel[], sections: FormSectionModel[], visibilityFields: InvisibleFieldInfo[] = [], visibilitySections: InvisibleFieldInfo[] = [], resubmit : boolean = false) {
    fieldValues.forEach(valueObject => {
      for (var i = 0; i < sections.length; i++) {
        var allowBreak: boolean = false;
        for (var j = 0; j < sections[i].fields.length; j++) {
          if (sections[i] && sections[i].fields[j].sectionCustomFieldId == valueObject.sectionCustomFieldId) {
            if(sections[i].fields[j].type == FeildType.SingleSelection) {
              if(valueObject && valueObject.value){
                //sections[i].nestedSections[valueObject.sectionIndex].fields[j].isReadOnly = true;
                sections[i].nestedSections[valueObject.sectionIndex].fields[j].value = valueObject.value;
              }
              if (valueObject && valueObject.lookupItems && valueObject.lookupItems.length > 0) {
                if (valueObject.targetFieldType == FeildType.SingleSelection) {
                  const option = valueObject.lookupItems;
                  // console.log('option1', option);

                  // if (option != null) {
                  //   option.forEach(o => {
                  //     sections[i].fields[j].lookupValues.push({
                  //       id: o.id,
                  //       isSelected: false,
                  //       isDeleted: false,
                  //       label: o.name,
                  //       order: 0,
                  //       color: o.color,
                  //       conditions: [],
                  //     });
                  //   })

                  // }
                  if(sections[i].fields[j].isMultipleAllowed){
                    sections[i].nestedSections[valueObject.sectionIndex].fields[j].lookupValues = <any>valueObject.lookupItems;
        
                    sections[i].nestedSections[valueObject.sectionIndex].fields[j].nestedFields.forEach(f => {
                      f.lookupValues = <any>valueObject.lookupItems;
                    })
                  } else {
                    sections[i].nestedSections[valueObject.sectionIndex].fields[j].lookupValues = <any>valueObject.lookupItems;
                  }
                }
              } else {
                if (valueObject.targetFieldType != FeildType.SingleSelection) {
                  sections[i].fields[j].type = valueObject.targetFieldType;
                  sections[i].fields[j].lookupValues = [];
                }
              }
              allowBreak = true;
              break;
            }
          }
          if (sections[i].nestedSections[valueObject.sectionIndex] && sections[i].nestedSections[valueObject.sectionIndex].fields[j].sectionCustomFieldId == valueObject.sectionCustomFieldId) {
            if (sections[i].nestedSections[valueObject.sectionIndex].fields[j].type != FeildType.LookupAddon) {

              if(valueObject.values && valueObject.values.length > 0 && sections[i].nestedSections[valueObject.sectionIndex].fields[j].value){
                const selectionOption = valueObject.values.find(item => item == sections[i].nestedSections[valueObject.sectionIndex].fields[j].value);
                if(!selectionOption){
                  sections[i].nestedSections[valueObject.sectionIndex].fields[j].value = "";
                }
              }else{
                //sections[i].nestedSections[valueObject.sectionIndex].fields[j].isReadOnly = true;
                sections[i].nestedSections[valueObject.sectionIndex].fields[j].value = valueObject.value;
              }
            }
            sections[i].nestedSections[valueObject.sectionIndex].fields[j].targetFieldType = valueObject.targetFieldType;
            if (valueObject.targetFieldType == FeildType.Attachment || valueObject.targetFieldType == FeildType.Image) {
              sections[i].nestedSections[valueObject.sectionIndex].fields[j].details = valueObject.details;
            } else if (valueObject.targetFieldType == FeildType.Money || valueObject.targetFieldType == FeildType.Price) {
              if (valueObject.value != null && valueObject.value.toString().indexOf(':') >= 0) {
                let valueArray = valueObject.value.split(':');
                sections[i].nestedSections[valueObject.sectionIndex].fields[j].value = valueArray[0];
                sections[i].nestedSections[valueObject.sectionIndex].fields[j].selectedCurrencyId = +valueArray[1];
                if (valueArray[4]) {
                  sections[i].nestedSections[valueObject.sectionIndex].fields[j].selectedCurrencySymbol = valueArray[4];
                }
                if(valueArray[5]){
                  sections[i].nestedSections[valueObject.sectionIndex].fields[j].selectedPriceUnit = valueArray[5];
                }
              }
            } else if (valueObject.targetFieldType == FeildType.MultiSelection) {
              sections[i].nestedSections[valueObject.sectionIndex].fields[j].lookupValues.forEach(lookupOption => {
                lookupOption.isSelected = false;
              });
              if (valueObject.value) {
                const valuesArray = valueObject.value.split(',');
                valuesArray.forEach(selectedId => {
                  let lookupOption = sections[i].nestedSections[valueObject.sectionIndex].fields[j].lookupValues.find(lv => lv.id == +selectedId);
                  if (lookupOption) {
                    lookupOption.isSelected = true;
                  }
                });
              }
            }
            //if (sections[i].nestedSections[valueObject.sectionIndex].fields[j].isMultipleAllowed) {
            if (valueObject.values && valueObject.values.length > 1) {
              sections[i].nestedSections[valueObject.sectionIndex].fields[j].type = FeildType.SingleSelection;
              //sections[i].nestedSections[valueObject.sectionIndex].fields[j].isReadOnly = false;
              sections[i].nestedSections[valueObject.sectionIndex].fields[j].lookupValues = [];
              valueObject.values.forEach(value => {
                if(valueObject.targetFieldType == FeildType.SingleSelection){
                  const option = valueObject.lookupItems.find(item => item.id.toString() == value);
                  if(option != null){
                    if(!sections[i].nestedSections[valueObject.sectionIndex].fields[j].lookupValues.find(lv => lv.id == value)){
                    sections[i].nestedSections[valueObject.sectionIndex].fields[j].lookupValues.push({
                      id: option.id,
                      isSelected: false,
                      isDeleted: false,
                      label: option.name,
                      order: 0,
                      color: option.color,
                      conditions: []
                    });
                    }
                  }
                } else{
                  let getValue = value;
                  if (valueObject.targetFieldType == FeildType.Date) {
                    getValue = this._customFieldService.getDateFieldValue(value, sections[i].nestedSections[valueObject.sectionIndex].fields[j].allowedValuesCriteria);
                  }else if (valueObject.targetFieldType == FeildType.DateTime) {
                    getValue = this._customFieldService.getDateTimeFieldValue(value);
                  } else if (valueObject.targetFieldType == FeildType.Money) {
                    getValue = this._customFieldService.getMoneyFieldValue(value);
                  }  if (valueObject.targetFieldType == FeildType.Price) {
                    getValue = this._customFieldService.getPriceFieldValue(value);
                  }
                  sections[i].nestedSections[valueObject.sectionIndex].fields[j].lookupValues.push({
                    id: value,
                    label: getValue,
                    isSelected: false,
                    isDeleted: false,
                    order: 0,
                    color: '',
                    conditions: []
                  });
                }
              });
            } else {
              //sections[i].nestedSections[valueObject.sectionIndex].fields[j].isReadOnly = true;
              if (valueObject.targetFieldType != FeildType.SingleSelection &&
                valueObject.targetFieldType != FeildType.MultiSelection) {
                sections[i].nestedSections[valueObject.sectionIndex].fields[j].type = valueObject.targetFieldType;
                sections[i].nestedSections[valueObject.sectionIndex].fields[j].lookupValues = [];
              }
            }
            //}
            allowBreak = true;
            break;
          }
        }
        if (allowBreak) {
          break;
        }
      }
    });
    // console.log('VisbilityFields', visibilitySections);

    this.setSectionsInvisible(visibilitySections, sections);
    this.setFieldsInvisible(visibilityFields, sections, resubmit);
  }
  assignValuesForCurrencyFields(fieldValues: DynamicCurrencies[], sections: FormSectionModel[]) {
      // Assuming form has a structure like: form.sections -> nestedSections -> fields
      sections.forEach(section => {
        section.nestedSections.forEach(nestedSection => {
          nestedSection.fields.forEach(field => {
            const matchingCurrency = fieldValues.find(currency => currency.sectionCustomFieldId === field.sectionCustomFieldId);
            
            if (matchingCurrency) {
              field.calculatedCurrency = true;
              this._currencyService.getCurrencies().subscribe(resp => {
                this._currencyService.currencies = resp.body.filter(C => C.id == matchingCurrency.currencyId);
                  field.allowedCurrencies = this._currencyService.currencies;
              });
            }
          });
  });
});

  }
  private setSectionsInvisible(visibilitySections: InvisibleFieldInfo[], sections: FormSectionModel[]){
    sections.map(s => s.isVisible = true);
    visibilitySections.forEach(visibilityInfo => {
      let section = sections.find(s => s.id == visibilityInfo.sectionId);
      // console.log('Section', section);

      if (section) {
          section.isVisible = visibilityInfo.isVisible;
      }
    });
  }

  private setFieldsInvisible(visibilityFields: InvisibleFieldInfo[], sections: FormSectionModel[], resubmit: boolean = false) {
    visibilityFields.forEach(visibilityInfo => {
      let section = sections.find(s => s.fields.find(f => f.sectionCustomFieldId == visibilityInfo.sectionCustomFieldId));
      if (section) {
        let fieldToInvisible = section.nestedSections[visibilityInfo.sectionIndex].fields.find(f => f.sectionCustomFieldId == visibilityInfo.sectionCustomFieldId);
        if (fieldToInvisible) {
          if(fieldToInvisible.isMultipleAllowed){
            fieldToInvisible.isVisible = visibilityInfo.isVisible;

            fieldToInvisible.nestedFields.forEach(f => {
              f.isVisible = visibilityInfo.isVisible
            })
            // let nestedfieldToInvisible = fieldToInvisible.nestedFields.find(nf => nf.sectionCustomFieldId == fieldToInvisible.sectionCustomFieldId);
            // nestedfieldToInvisible.isVisible = visibilityInfo.isVisible
          } else {
            fieldToInvisible.isVisible = visibilityInfo.isVisible;
          }
        }
      }
    });
    // alert(resubmit)
    // if(resubmit) {
      this.setFieldsReadOnly(visibilityFields, sections);
    // }
  }

  private setFieldsReadOnly(visibilityFields: InvisibleFieldInfo[], sections: FormSectionModel[]) {
    visibilityFields.forEach(visibilityInfo => {
      let section = sections.find(s => s.fields.find(f => f.sectionCustomFieldId == visibilityInfo.sectionCustomFieldId));
      if (section) {
        let fieldToMarkAsReadOnly = section.nestedSections[visibilityInfo.sectionIndex].fields.find(f => f.sectionCustomFieldId == visibilityInfo.sectionCustomFieldId);
        if (fieldToMarkAsReadOnly) {
          if(fieldToMarkAsReadOnly.isMultipleAllowed){
            fieldToMarkAsReadOnly.isReadOnly = visibilityInfo.isReadOnly;

            fieldToMarkAsReadOnly.nestedFields.forEach(f => {
              f.isReadOnly = visibilityInfo.isReadOnly
            })
            // let nestedfieldToMarkAsReadOnly = fieldToMarkAsReadOnly.nestedFields.find(nf => nf.sectionCustomFieldId == fieldToMarkAsReadOnly.sectionCustomFieldId);
            // nestedfieldToMarkAsReadOnly.isVisible = visibilityInfo.isVisible
          } else {
            fieldToMarkAsReadOnly.isReadOnly = visibilityInfo.isReadOnly;
          }
        }
      }
    });
  }

  assignApplicationToDefinitionLookups(sections: FormSectionModel[], definitionId: number, applicationId: number) {
    for (var i = 0; i < sections.length; i++) {
      sections[i].nestedSections.forEach(nestedSection => {
        let defLookups = nestedSection.fields.filter(f => f.type == FeildType.LookupAddon && f.lookupType == LookupType.Definition && f.lookUpId == definitionId)
        defLookups.forEach(field => {
          field.value = applicationId.toString();
          field.isReadOnly = true;
        });
      });
    }
  }

  assignValuesForMappedAndCalcualtedTypesSection(fieldValues: CustomFieldValueModel[], section: FormSectionModel) {
    fieldValues.forEach(valueObject => {
      for (var j = 0; j < section.fields.length; j++) {
        if (section.fields[j].sectionCustomFieldId == valueObject.sectionCustomFieldId) {
          if (section.fields[j].type != FeildType.LookupAddon) {
            section.fields[j].value = valueObject.value;
          }
          section.fields[j].targetFieldType = valueObject.targetFieldType;
          if (valueObject.targetFieldType == FeildType.Attachment || valueObject.targetFieldType == FeildType.Image) {
            section.fields[j].details = valueObject.details;
          } else if (valueObject.targetFieldType == FeildType.DateTime) {
            const customField: CustomFieldModel = new CustomFieldModel();
            customField.type = FeildType.DateTime;
            customField.value = valueObject.value;
            section.fields[j].value = this._customFieldService.getFeildValue(customField);
          } else if (valueObject.targetFieldType == FeildType.Date) {
            const customField: CustomFieldModel = new CustomFieldModel();
            customField.type = FeildType.Date;
            customField.value = valueObject.value;
            section.fields[j].value = this._customFieldService.getFeildValue(customField);
          }  else if (valueObject.targetFieldType == FeildType.Money) {
            const customField: CustomFieldModel = new CustomFieldModel();
            customField.type = FeildType.Money;
            customField.value = valueObject.value;
            section.fields[j].value = this._customFieldService.getFeildValue(customField);
          } else if (valueObject.targetFieldType == FeildType.Price) {
            const customField: CustomFieldModel = new CustomFieldModel();
            customField.type = FeildType.Price;
            customField.value = valueObject.value;
            section.fields[j].value = this._customFieldService.getFeildValue(customField);
          }
          break;
        }
      }
    });
  }

  addSection(sectionsList: FormSectionModel[], section: FormSectionModel) {
    let newSection = _.cloneDeep(section);//Object.assign({}, section);
    newSection.nestedSections = []; 
    newSection.fields.forEach((field: CustomFieldModel) => {
      field.id = parseInt(field.id + '' + _.random(1, 255));
    });
    newSection.fields = CustomFieldService.mapGetValueModel(newSection.fields, [], 0, false);
    sectionsList.push(newSection);
  }

  removeSection(sectionsList: FormSectionModel[], section: FormSectionModel) {
    _.pullAt(sectionsList, _.findIndex(sectionsList, section));
  }

  removeSectionByIndex(sectionsList: FormSectionModel[], index: number) {
    _.pullAt(sectionsList, index);
  }

  getVerificationFields(form: FormModel): CustomFieldModel[]{
    let fields: CustomFieldModel[] = [];
    form?.sections?.flatMap(section => section.nestedSections).flatMap(nestedSection => nestedSection.fields).filter(field => (field.type === FeildType.Email || field.type === FeildType.PhoneNumber) && field.verifiable)
    .forEach(verificationField => {
      if(verificationField.isMultipleAllowed){
        verificationField.nestedFields.forEach(nestedVerificationField => {
        if(nestedVerificationField.isRequired || nestedVerificationField.value)
        {
          fields.push(nestedVerificationField);
        }
       });
      }else{
        if(verificationField.isRequired || verificationField.value)
        {
          fields.push(verificationField);
        }
      }
    });
    return fields 
  }
}
