<div mat-dialog-content class="content-container" fxLayout="column" fxLayoutAlign="center center">
  <div>
    <img src="../../../../../../assets/images/request/tick.svg" />
  </div>
  <div class="dialog-bold-text" *ngIf="!isResubmitFromDetails"> 
    {{submitSuccessMessage | translateToLocale}}
  </div>
  <div class="dialog-bold-text"  *ngIf="isResubmitFromDetails">
    {{submitSuccessMessage | translateToLocale}}
  </div>
  <!-- <div class="dialog-normal-text" [translate]="'Application.SUBMIT-SUCCESS-MSG-DESC'" *ngIf="!isResubmitFromDetails">
  </div> -->
  <div>
    <button mat-flat-button class="dialog-btn-ok" color="primary" (click)="closeDialog()">
      {{'Common.DONE' | translate}}
    </button>
  </div>
</div>
