<div [formGroup]="customFieldForm">
    <div  *ngIf="customField.type == FType.Gallery" fxLayout="column">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <label class="wizart-field-label margin-t1" *ngIf="fieldOptions.floatLabel != 'never' && !customField.isLongLabel">
            {{(customField.label | translateToLocale)}} <span *ngIf="customField.isRequired">*</span>
            <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
            <mat-icon matSuffix style="font-size:18px;color:rgba(0, 0, 0, 0.2);margin-right:8px;" *ngIf="customField.isReadOnly">lock</mat-icon>
        </label>
        <div class="file-upload-portion">
            <div fxLayout="column">
                <div fxLayout="row" fxLayoutGap="22px" *ngIf="!customField.value">
                    <button  mat-flat-button class="gradient-btn" color="primary" class="btn-upload"
                        (click)="openFileUploader()">
                        <mat-icon>attach_file</mat-icon>
                        {{ (customField.type == FType.Attachment ? "ATTACH" : "UPLOAD-IMAGE") |translate}}
                    </button>
                    <div *ngIf="!customField.value" fxFlex fxLayoutAlign="start center">
                        <button mat-icon-button class="btn-add-field" (click)="openFileUploader()" *ngIf="!isDisabled || !customField.isReadOnly">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <div matSuffix *ngIf="mobileIcons" fxFlex fxLayoutAlign="end center"
                        class="mobile-fieldattachment-space">
                        <ng-container [ngTemplateOutlet]="mobilefieldsicon"></ng-container>
                    </div>
                </div>
                <div fxFlex class="file-detail" fxLayout="column" fxLayoutAlign="center center" *ngIf="customField.details.gallery">
                    <div class="file" fxLayout="row" fxLayoutAlign="start start" style="width: 100%;" *ngFor="let data of customField.details.gallery; let i =index">
                    
                        <img class="image-type-preview" [src]="data.downloadUrl | secure | async">
                        <div fxLayout="row" fxLayoutAlign="end start" fxFlex *ngIf="!isDisabled || !customField.isReadOnly">
                            <mat-icon class="color-gray cursor-pointer" 
                                (click)="removeAttachedFile(data.fileId)">
                                delete_outline
                            </mat-icon>
                        </div>
                        <div fxFlex fxLayoutAlign="end center" matSuffix *ngIf="mobileIcons">
                            <ng-container [ngTemplateOutlet]="mobilefieldsicon"></ng-container>
                        </div>
                    
                    </div>
                    
                    <ng-container *ngTemplateOutlet="addBtnForGalleryBoxControls"></ng-container>
                </div>
                <div fxFlex class="file-detail" fxLayout="column" fxLayoutAlign="center center" *ngFor="let data of galleryErrorList; let i =index">
                    <div class="file errorsFile" fxLayout="row" fxLayoutAlign="start start" style="width: 100%;">
                        <div>{{data.file}}</div>
                        <div fxLayout="row" fxLayoutAlign="end start" fxFlex *ngIf="!isDisabled || !customField.isReadOnly">
                            <mat-icon class="color-gray cursor-pointer"
                            (click)="removeErrorFile(i)">
                                delete_outline
                            </mat-icon>
                        </div>
                        
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start start" style="width: 100%; margin-bottom: 6px;">
                        <mat-error>
                            {{data.error}}
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
        <input [attr.data-type]="customField.type" style="display:none" #inputTypeFile type="file" multiple="multiple"
            class="custom-textbox" [(ngModel)]="customField.fileContent" formControlName="customFieldName{{customField.id}}"
            (change)="selectImageGallery($event,customField)"
            [requireFile]="customField.isRequired ? customField.details?.name ? false : true : false"
            [accept]="customField.allowedValuesCriteria" />
        <mat-error class="attachmentError" style="display:none">
            {{"Error.Value-Error" | translate}} {{customField.max}}
        </mat-error>
    </div>

    <div *ngIf="customField.type == FType.Gallery" style="margin: -5px 0px 15px 0px;">
        <mat-error
            *ngIf="!customField.value && customField.isRequired && isFileButtonTouched">
            {{"Error.Required" | translate}}
        </mat-error>
    </div>
</div>

<ng-template #addBtnForGalleryBoxControls>
    <div *ngIf="customField.details.gallery.length > 0" fxFlex fxLayoutAlign="start center">
      <button mat-icon-button class="btn-add-field" (click)="openFileUploader()" *ngIf="!isDisabled || !customField.isReadOnly">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </ng-template>