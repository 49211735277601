import { Injectable } from "@angular/core";
import { ActionFieldModel } from "../../models/action-field-model";
import { FeildType } from "../../common/enums/field-type-enum";

@Injectable({
    providedIn: 'root'
})
export class ActionFieldsDialogService {
    constructor(){

    }

     // Action fields dialog functions start
  searchByKeyWithParents(
    key: string,
    items: ActionFieldModel[],
    parentPath: ActionFieldModel[] = [],
    forInsertable = false
  ): ActionFieldModel[] {
    const result: ActionFieldModel[] = [];
  
    for (const item of items) {
      const newPath = [...parentPath, item];
      
      if(forInsertable){
        if (`{${item.key}}` === key) {
            result.push(...newPath);
        }
      } else{
        if (item.key === key) {
            result.push(...newPath);
        }
      }
  
      if (item.innerFields) {
        const innerResults = this.searchByKeyWithParents(key, item.innerFields, newPath, forInsertable);
        result.push(...innerResults);
      }
    }
  
    return result;
  }

  getSelectedField(key: string, items: ActionFieldModel[]): ActionFieldModel{
    const fields = this.searchByKeyWithParents(key, items);
    if(fields && fields.length > 0){
      return fields[fields.length - 1];
    }
    return null; 
  }

flattenActionFieldArray(arr: ActionFieldModel[]): ActionFieldModel[] {
    const flattenedArray: ActionFieldModel[] = [];
  
    for (const item of arr) {
      flattenedArray.push(item);
      if (item.innerFields && item.innerFields.length > 0) {
        flattenedArray.push(...this.flattenActionFieldArray(item.innerFields));
      }
    }
  
    return flattenedArray;
  }

  removeElementsByType(inputArray: ActionFieldModel[], type: FeildType): ActionFieldModel[] {
    const modifiedArray: ActionFieldModel[] = [];
  
    for (const model of inputArray) {
      if (model.type != type) {
        // If the section does not match, keep the element as-is
        modifiedArray.push(model);
      }
  
      if (model.innerFields && model.innerFields.length > 0) {
        // Recursively call the function for nested innerFields
        const modifiedInnerFields = this.removeElementsByType(model.innerFields, type);
        if (modifiedInnerFields.length > 0) {
          model.innerFields = modifiedInnerFields;
        }
      }
    }
  
    return modifiedArray;
  }

  filterBykeyStartsWith(inputArray: ActionFieldModel[], key: string): ActionFieldModel[] {
    let  modifiedArray = inputArray.filter(x => x.key.startsWith(key) || (x.innerFields?.length > 0));
    modifiedArray.forEach(actionField => {
       if(actionField.innerFields?.length > 0){
        actionField.innerFields = this.filterBykeyStartsWith(actionField.innerFields, key);
       }

    });
    return modifiedArray;
  }
  filterByType(inputArray: ActionFieldModel[], type: number): ActionFieldModel[] {
    let  modifiedArray = inputArray.filter(x => x.type == type || (x.innerFields?.length > 0));
    modifiedArray.forEach(actionField => {
       if(actionField.innerFields?.length > 0){
        actionField.innerFields = this.filterByType(actionField.innerFields, type);
       }

    });
    return modifiedArray;
  }
  filterByLookupId(inputArray: ActionFieldModel[], id: number): ActionFieldModel[] {
    let modifiedArray = inputArray.filter(x => x.fieldId == id || (x.innerFields?.length > 0));
    
    modifiedArray.forEach(actionField => {
        if (actionField.innerFields?.length > 0) {
            actionField.innerFields = this.filterByLookupId(actionField.innerFields, id);
        }
    });

    
    return modifiedArray.filter(x => x.fieldId == id || (x.innerFields?.length > 0));
  //   let  modifiedArray = inputArray.filter(x => x.fieldId == id || (x.innerFields?.length > 0));
  //   modifiedArray.forEach(actionField => {
  //      if(actionField.innerFields?.length > 0){
  //       actionField.innerFields = this.filterByLookupId(actionField.innerFields, id);
  //      }

  //   });
  //   console.log('modifiedArray',modifiedArray);
    
  //   return modifiedArray;
  }

  filterActionFields(inputArray: ActionFieldModel[], condition: (field: ActionFieldModel) => boolean): ActionFieldModel[]{
    let noRefInputArray = JSON.parse(JSON.stringify(inputArray));
    let modifiedArray = noRefInputArray.filter(f => f.innerFields?.length > 0 || condition(f)); //inputArray.filter(f => (!f?.key.includes('DT/Key') && !f.key.includes('DT/Number') && f.key.startsWith('DT'))  || f.type == FeildType.LookupAddon || 
    //f.type == FeildType.SingleSelection || f.type == FeildType.MultiSelection);
    modifiedArray.forEach(actionField => {
       if(actionField.innerFields?.length > 0){
        actionField.innerFields = this.filterActionFields(actionField.innerFields, condition);
       }

    });
    return modifiedArray;
  } 
  // Action fields dialog functions end
}