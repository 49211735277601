<div class="main-account-container">
  <div>
    <app-header-before-login></app-header-before-login>
    <div class="main-account-content">
      <div fxFlex fxLayout="row" fxLayoutAlign="center stretch">
        <div fxLayout="column" fxLayoutAlign="center" class="login-bg">
          <div class="login-dialog" ngClass.lt-sm="width100">
            <div class="dialog-top-bar"></div>
            <div #loginDialogDiv class="dialog-content-container bgc-white login-border-bottom login-padding">
              <div mat-dialog-title>
                <div fxLayout="row">
                  <div fxFlex fxLayout="column">
                    <div fxLayoutAlign="center center">
                      <img src="./assets/images/ic_dialog_success.png" />
                    </div>
                    <div class="title" translate="Submit-Success.Thanks-Submit">
                    </div>
                    <div class="title-description" translate="Submit-Success.Submit-Invitation">
                    </div>
                    <!-- <div style="margin-top:10px; font-size: 14px; text-align: center">
                      <span (click)="sendInviteLink()" class="theme-color cursor-pointer"
                        translate="Resend-Invite">here</span>
                    </div> -->
                  </div>
                </div>
              </div>
              <form #loginForm="ngForm">
                <div mat-dialog-actions class="action-btn-margin">

                  <div fxFlex fxLayoutAlign="center center">
                    <button mat-button class="add-button-gradiant" (click)="close()">
                      <span translate="CLOSE"></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
