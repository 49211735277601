import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'esp-search-text-box',
  templateUrl: './esp-search-text-box.component.html',
  styleUrls: ['./esp-search-text-box.component.scss']
})
export class EspSearchTextBoxComponent implements OnInit {
  @Input() espFormControl: FormControl;
  @Input() placeHolder = 'Search';
  @Input() backgroundGay = false;
  @Input() lessPaddingTop = false;
  constructor() { }

  ngOnInit(): void {
  }

  clearSearch(){
    this.espFormControl.setValue('');
  }

}
