<div class="exceeders-card" [ngClass]="{'no-box-shadow' : noBoxShadow}" fxFlex fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="16px">
    <svg width="179" height="131" viewBox="0 0 179 131" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M158.439 18.9594C158.439 18.9594 139.228 -0.918903 108.813 0.0331373C78.3978 0.985177 52.4642 20.2195 31.012 35.2902C9.55973 50.3609 3.80415 73.7708 16.2897 92.0413C28.7752 110.312 48.6377 102.624 73.9125 114.483C99.1873 126.342 103.992 133.082 132.182 130.198C160.372 127.314 181.809 97.8049 178.605 66.3954C175.402 34.986 158.439 18.9594 158.439 18.9594Z"
            fill="#F0F6E6" />
        <path
            d="M38.1117 17.8483H152.613C156.294 17.8483 159.279 20.837 159.279 24.5249V99.0201C159.279 102.708 156.294 105.697 152.613 105.697H38.1117C34.4303 105.697 31.445 102.708 31.445 99.0201V24.5249C31.445 20.837 34.4303 17.8483 38.1117 17.8483Z"
            fill="white" stroke="#303030" stroke-width="0.8" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M62.1393 73.2115C61.0348 73.2115 60.1393 72.3161 60.1393 71.2115V50.1765C60.1393 49.072 61.0348 48.1765 62.1393 48.1765H137.415C138.519 48.1765 139.415 49.072 139.415 50.1765V71.2115C139.415 71.2549 139.413 71.298 139.411 71.3407C139.413 71.3705 139.415 71.4006 139.415 71.431V77.3155C139.415 78.1981 138.355 78.648 137.72 78.0348L132.727 73.2115H62.1393Z"
            fill="#EEEEEE" />
        <path
            d="M119.787 52.5088H65.2879C65.1561 52.5088 65.0493 52.6157 65.0493 52.7474C65.0493 52.8792 65.1561 52.986 65.2879 52.986H119.787C119.919 52.986 120.026 52.8792 120.026 52.7474C120.026 52.6157 119.919 52.5088 119.787 52.5088Z"
            fill="#94959C" />
        <path
            d="M108.379 55.0772H65.2879C65.1561 55.0772 65.0493 55.184 65.0493 55.3158C65.0493 55.4475 65.1561 55.5543 65.2879 55.5543H108.379C108.511 55.5543 108.618 55.4475 108.618 55.3158C108.618 55.184 108.511 55.0772 108.379 55.0772Z"
            fill="#94959C" />
        <path
            d="M115.269 60.2176H65.2879C65.1561 60.2176 65.0493 60.3245 65.0493 60.4562C65.0493 60.588 65.1561 60.6948 65.2879 60.6948H115.269C115.401 60.6948 115.508 60.588 115.508 60.4562C115.508 60.3245 115.401 60.2176 115.269 60.2176Z"
            fill="#94959C" />
        <path
            d="M119.787 62.786H65.2879C65.1561 62.786 65.0493 62.8928 65.0493 63.0246C65.0493 63.1563 65.1561 63.2631 65.2879 63.2631H119.787C119.919 63.2631 120.026 63.1563 120.026 63.0246C120.026 62.8928 119.919 62.786 119.787 62.786Z"
            fill="#94959C" />
        <path
            d="M119.787 65.3544H65.2879C65.1561 65.3544 65.0493 65.4612 65.0493 65.5929C65.0493 65.7247 65.1561 65.8315 65.2879 65.8315H119.787C119.919 65.8315 120.026 65.7247 120.026 65.5929C120.026 65.4612 119.919 65.3544 119.787 65.3544Z"
            fill="#94959C" />
        <path
            d="M104.561 67.9227H65.2879C65.1561 67.9227 65.0493 68.0295 65.0493 68.1613C65.0493 68.2931 65.1561 68.3999 65.2879 68.3999H104.561C104.693 68.3999 104.8 68.2931 104.8 68.1613C104.8 68.0295 104.693 67.9227 104.561 67.9227Z"
            fill="#94959C" />
        <path
            d="M44.5082 17.4483V106.097H38.1117C36.2375 106.097 34.4401 105.351 33.1148 104.024C31.7895 102.697 31.045 100.897 31.045 99.0201V24.5249C31.045 22.648 31.7895 20.8481 33.1148 19.521C34.4401 18.1938 36.2375 17.4483 38.1117 17.4483H44.5082Z"
            fill="#B8B9BD" />
        <path
            d="M27.3403 88.8529C27.1725 88.8145 27.0125 88.7473 26.8672 88.6543C26.69 88.5063 26.5435 88.3245 26.4364 88.1195C26.3293 87.9146 26.2636 87.6905 26.243 87.4601C26.2225 87.2297 26.2476 86.9977 26.3168 86.7775C26.386 86.5572 26.498 86.353 26.6463 86.1768L32.7853 78.8356C32.9316 78.6582 33.1119 78.512 33.3156 78.4059C33.5192 78.2997 33.742 78.2356 33.9709 78.2175C34.1999 78.1993 34.4302 78.2275 34.6485 78.3002C34.8667 78.3729 35.0684 78.4887 35.2417 78.6409C35.4189 78.7889 35.5653 78.9706 35.6725 79.1755C35.7796 79.3805 35.8453 79.6047 35.8658 79.835C35.8864 80.0654 35.8613 80.2974 35.7921 80.5177C35.7229 80.7379 35.6109 80.9421 35.4625 81.1183L29.3235 88.4595C29.0722 88.7195 28.748 88.8963 28.3939 88.9665C28.0397 89.0368 27.6723 88.9972 27.3403 88.8529Z"
            fill="white" stroke="#303030" stroke-width="0.920502" stroke-miterlimit="10" />
        <path
            d="M11.5365 108.052C11.2919 107.95 11.0649 107.81 10.8642 107.636C10.3321 107.182 9.99918 106.536 9.93787 105.838C9.87656 105.14 10.0918 104.447 10.5368 103.91L25.9253 85.5159C26.3799 85.0083 27.0136 84.6989 27.6925 84.6529C28.3715 84.607 29.0424 84.8282 29.5637 85.2698C30.0849 85.7114 30.4156 86.3388 30.4858 87.0194C30.5561 87.7 30.3604 88.3806 29.9401 88.9172L14.5516 107.311C14.1961 107.744 13.7108 108.05 13.1677 108.184C12.6246 108.317 12.0525 108.271 11.5365 108.052Z"
            fill="#F2F2F2" stroke="#303030" stroke-width="0.920502" stroke-miterlimit="10" />
        <ellipse rx="24.4773" ry="24.5578"
            transform="matrix(0.998332 0.00166303 0.00164403 1.00166 52.2174 60.777)" fill="#86C04F"
            fill-opacity="0.05" />
        <path
            d="M60.9319 39.7296L60.9315 39.7295C56.8232 38.0003 52.3011 37.5331 47.9373 38.3871C43.5735 39.2411 39.5641 41.3779 36.4162 44.5269C33.2683 47.676 31.1233 51.696 30.2526 56.0783C29.3819 60.4606 29.8245 65.0084 31.5246 69.1464C33.2247 73.2845 36.1059 76.8268 39.8037 79.3252C43.5016 81.8236 47.8498 83.1658 52.2984 83.182C56.747 83.1982 61.096 81.8877 64.7952 79.4163C68.4944 76.945 71.3776 73.4238 73.0802 69.2983L73.0804 69.298C75.3559 63.7699 75.3551 57.5529 73.0778 52.0101C70.8006 46.4672 66.4327 42.0509 60.9319 39.7296ZM42.0162 85.5607C37.0957 83.495 32.8901 80.0109 29.9314 75.549C26.9727 71.087 25.3937 65.8476 25.3942 60.4932C25.3946 55.1388 26.9745 49.91 29.9341 45.468C32.8936 41.0261 37.0999 37.5705 42.0209 35.5383C46.9419 33.5061 52.3566 32.9884 57.5803 34.0508C62.8039 35.1131 67.6021 37.7077 71.3679 41.5065C75.1337 45.3053 77.6982 50.1377 78.7369 55.3927C79.7755 60.6477 79.2418 66.0892 77.203 71.0291C74.4604 77.6461 69.2165 82.8913 62.6207 85.6151C56.0249 88.339 48.6154 88.3194 42.0162 85.5607Z"
            fill="white" stroke="#303030" stroke-width="0.8" />
    </svg>
    <div><b [translate]="'Common.No-Items'"></b></div>
</div>