import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { SystemLanguage } from '../../models/system-language';

@Component({
  templateUrl: './switch-language-dialog.component.html',
  styleUrls: ['./switch-language-dialog.component.scss']
})
export class SwitchLanguageDialogComponent implements OnInit {

  public systemLanguages: SystemLanguage[];
  public preferredLanguage: SystemLanguage;

  constructor(
    private _dialogRef: MatDialogRef<SwitchLanguageDialogComponent>,
    
    public dataSharingService: DataSharingService
    , private translate: TranslateService
    , private translationHelperService: TranslationHelperService) {
    this.preferredLanguage = JSON.parse(localStorage.getItem('preferredLanguage'));
    if (!this.preferredLanguage) {
      var browserLang = translate.getBrowserLang();
      this.dataSharingService.systemLanguages.subscribe(languages => {
        //this.systemLanguages = languages;
        var currentLang = languages.filter(x => x.locale == browserLang)[0];
        if (!currentLang) {
          currentLang = languages.filter(x => x.locale == 'en')[0];
        }
        this.preferredLanguage = currentLang;
      });
    }

  }

  ngOnInit() {
    this.dataSharingService.systemLanguages.subscribe(systemLanguages => {
      this.translationHelperService.getOrganizationLocales().subscribe(resp => {
        let activeLocales = resp.body.split(',');
        if (activeLocales.length > 0) {
          systemLanguages.forEach(systemLanguage => {
            if (!systemLanguage.isDefault)
              systemLanguage.isActive = false;
          });
          systemLanguages.forEach(systemLanguage => {
            activeLocales.forEach(activeLocale => {
              if (systemLanguage.locale == activeLocale) {
                systemLanguage.isActive = true;
              }
            })
          });
        }

        this.systemLanguages = systemLanguages.filter(x => x.isActive);
      });
    });
    this.translate.use(this.preferredLanguage.locale);
  }

  switchLanguage(lang: SystemLanguage) {
    if (this.preferredLanguage && this.preferredLanguage.locale != lang.locale) {
      localStorage.setItem('preferredLanguage', JSON.stringify(lang));
      location.reload();
    }
    //this.translate.use(lang.locale);

  }
}
