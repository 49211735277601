<div fxLayout="column" fxLayoutAlign="center">

    <ng-container [ngTemplateOutlet]="isLongLabel"></ng-container>
    <mat-form-field [formGroup]="customFieldForm" [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'"
        fxFlex floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}"
        [ngClass]="{'compressed table-view-field' : showInTable}">
        <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label |
            translateToLocale}} <ng-container [ngTemplateOutlet]="tempNesterFieldIndex"></ng-container>
        </mat-label>
        <div matPrefix *ngIf="customField.isRealTime">
            <mat-icon style="font-size:22px;color:gray;margin-right:8px;">stars</mat-icon>
        </div>
        <input [attr.data-type]="customField.type" matInput [ngxMatDatetimePicker]="picker" (click)="reOpenCalendar()"
            (keydown.enter)="reOpenCalendar()"
            placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value" ngDefaultControl
            [required]="customField.isRequired" [min]="customField.calculatedMinDate"
            [max]="customField.calculatedMaxDate" readonly>
        <div matSuffix fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="customField.value && !customField.isReadOnly">
                <button mat-icon-button>
                    <mat-icon [ngClass]="{'disabled': customField.isReadOnly}" class="option-cross"
                        (click)="removeDate();" mat-ripple>
                        close
                    </mat-icon>
                </button>
            </div>
            <div>
                <mat-datepicker-toggle matSuffix [for]="picker">
                </mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [enableMeridian]="true" (closed)="onCloseDatePicker()">
                </ngx-mat-datetime-picker>
            </div>
            <mat-icon matSuffix style="font-size:22px;color:rgba(0, 0, 0, 0.2);margin-right:8px;" *ngIf="customField.isReadOnly">
                lock</mat-icon>
            <div matSuffix *ngIf="mobileIcons">
                <ng-container [ngTemplateOutlet]="mobilefieldsicon"></ng-container>
            </div>
        </div>

    </mat-form-field>
    <ng-container [ngTemplateOutlet]="addBtnForBoxControl"></ng-container>
</div>
