<div fxFlex class="btn-close-container">
  <button mat-icon-button mat-dialog-close aria-label="Close dialog" class="btn-close">
    <mat-icon><img src="assets/images/icons/close.svg" alt="close"></mat-icon>
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center center">
  <div fxLayout="column" fxFlex="360px" fxFlex.xs="100%" fxLayoutGap="24px">
    <ng-container>
      <div mat-dialog-title fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
        <div class="dialog-title" [translate]="'Application.SELECT-SUBMITTER'">
        </div>

        <div fxLayout="row" class="search-control-container">
          <mat-form-field class="no-border-h40 esp-text-search-input gray-control" appearance="standard" fxFlex>
            <input placeholder="{{'SEARCH' | translate}}" matInput maxlength="100" [formControl]="searchControl">
          </mat-form-field>
        </div>
      </div>
      <div mat-dialog-content class="applicants-container" fxLayout="column" infinite-scroll
        [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
        (scrolled)="onModalScrollDown()" [alwaysCallback]="true">
        <div *ngIf="showSpinner" fxFlex fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner></mat-spinner>
        </div>
        <div fxLayout="column" fxLayoutGap="16px">
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="applicant-card"
            *ngFor="let applicant of applicants;trackBy: trackByFn" (click)="selectApplicant(applicant)">
            <div fxLayoutAlign="center center">
              <ngx-avatar name="{{applicant.fullName}}" [src]=applicant.pictureUrl initialsSize="2" bgColor="#f2f2f2"
                fgColor="#807f83" size="40" textSizeRatio="2.5" [round]="true"></ngx-avatar>
            </div>
            <div fxFlex class="card-main-heading">{{applicant.fullName}}</div>
            <div fxLayoutAlign="end center">
              <xcdrs-esp-checkbox [checked]="applicant.isSelected"></xcdrs-esp-checkbox>
            </div>
          </div>
        </div>
        <div fxFlex="none" fxLayout="row" fxLayoutAlign="center center" *ngIf="!applicants.length && !showSpinner">
          <div fxFlex class="text-center" [translate]="'Application.NO-SUBMITTER-AVAILABLE'">
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
  <div fxLayout="column" fxFlex="360px" fxFlex.xs="100%" fxLayoutGap="24px">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <button mat-stroked-button color="primary" mat-dialog-close>
        {{'CANCEL' | translate}}
      </button>
      <button mat-flat-button [disabled]="!selectedApplicantId" class="gradient-btn" color="primary"
        (click)="goToSubmitForm()">
        {{'Common.SUBMIT' | translate}}
      </button>
    </div>
  </div>
</div>