<div [dir]="(dataSharingService.isRtl)?'rtl':'ltr'"
  [hidden]="this.submitApplicationDeeplinkingSettings.organizationId > 0" fxLayout="column">
  <div class="title" fxLayoutAlign="center start" [translate]="'Common.ORGANIZATION'">
  </div>
  <div class="gray-email-text" fxLayoutAlign="center start">
    {{userInfoService?.email}}
  </div>
  <div fxLayout="column" fxLayoutGap="21px" [class.org-list]="hasScroll">
    <esp-search-text-box [espFormControl]="searchText" [backgroundGay]="true" class="full-width">
    </esp-search-text-box>
    <ng-container *ngFor="let per of orgPersonaList ">
      <div class="account-item" [class.account-current]="per.organizationId == currentOrganizationId" [dir]="(dataSharingService.isRtl)?'rtl':'ltr'" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="start center" (click)="persoanSelected(per.id)">
        <div>
          <div class="avatar-container">
            <ngx-avatar name="{{per.name}}" [src]=per.imagerUrl initialsSize="2" bgColor="#ffffff" fgColor="#6cb33f"
              size="40" textSizeRatio="2.5" [round]="true"></ngx-avatar>
          </div>
        </div>

        <div fxLayout="column">
          <div class="org-name">
            {{per.organizationName}}
          </div>
          <div class="org-role">
            {{per.name}}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>