import { FeildType } from "../common/enums/field-type-enum";
import { Condition, CustomFieldLookupModel } from "../profile-customization/Model/CustomFieldModel";
import { NameId } from "./UserResult";
import { ActionValue } from "./action-model";
import { FieldValue } from "./iframe/definition-filter.model";

export class ActionFieldModel {
  constructor(
  ) {
    this.fieldLookups = [];
  }

  public key: string;
  public name: string;
  public name2?: string;
  public description?: string;
  public type: FeildType;
  public lookupType?: string
  public isSelected?: boolean;
  public fieldId?: number;
  public allowedValuesCriteria?: string;
  public fieldLookups?: CustomFieldLookupModel[];
  public fieldOrder?: number;
  public isMultipleAllowed?: boolean;
  public sectionOrder?: number;

  public section?: string; 
  public innerFields? : ActionFieldModel[]; 
  public lookupName?: string; 
}

export class BulkRequestsModel {
  constructor(
  ) {}

  public actionValues: ActionValue[];
  public definitionId: number;
  public applicationsId: number[];
  public isAllApplication: boolean;
  public search : string;
  public statuses : number [];
  public fieldValues: FieldValue[] = [];
  public searchValues: NameId[] = [];
  public filterContext : string = '';
  public conditions: Condition[] = [];
  
}

