
export enum Roles {
  Admin = "Admin",
  User = 'User',
  Applicant = 'Applicant'
}

export enum UserTypes {
  Admin = 1,
  Assessor = 2, //previously User
  User = 3 //previously Applicant
}
