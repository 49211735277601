import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { HttpResponse, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {

  public systemSettings: SystemSetting = null;
  public winHandler: boolean = true;
  private systemSettingCall = true;
  constructor(private http: HttpClient
    ) {

  }

  load() {
    return new Promise((resolve, reject) => {
      this.systemSettings = JSON.parse(localStorage.getItem('systemSettings'));
      if (this.systemSettings != null) {
        // if (this.systemSettings.windowsAuth) {
        //   if (this.systemSettings.windowsAuth.toLowerCase() == 'true') {
        //     if (this.winHandler) {
        //       this.winHandler = false;
        //       this.getToken(resolve);
        //     }
        //   } else {
        //     resolve(true);
        //   }
        // } else {
          this.getSystemSettings(resolve);
        // }
      }

      this.getSystemSettings(resolve);
    })
  }

  private getSystemSettings(resolve: any) {
    if (this.systemSettingCall) {
      this.systemSettingCall = false;
      this.http
        .get('/webapi/settings/')
        .subscribe(response => {
          this.systemSettings = <SystemSetting><any>response;
          localStorage.setItem('systemSettings', JSON.stringify(this.systemSettings));
          // if (this.systemSettings.windowsAuth && this.systemSettings.windowsAuth.toLowerCase() == 'true') {
          //   if (this.winHandler) {
          //     this.winHandler = false;
          //     this.getToken(resolve);
          //   }
          // } else {
            resolve(true);
          // }
        }, (error) => {
          console.error(error);
        });
    }
  }

  //private windowsHanlerCall(resolve: any) {
  //  this.http
  //    .get('/webapi/winlogin')
  //    .subscribe(response => {
  //      this.getToken(resolve);
  //    }, error => {

  //    });
  //}
  private getToken(resolve) {
    sessionStorage.clear();
    this.http
      .get('/webapi/orguser/winlogintoken')
      .subscribe(function(data : any){
        var personas = data.token.personas;
        let expirayDate : Date = new Date();
        expirayDate.setDate(expirayDate.getDate() + 365);
        var result = {
          token: {
            accessToken: data.token.accessToken,
            expiry: expirayDate,
            refreshToken: "",
            persoans: data.token.personas
          },
          user: {
            name: data.user.name,
            email: data.user.email,
            role: data.user.role,
            imageUrl: data.user.imageUrl,
            organizationId: data.user.organizationId,
            profileTemplateIds: data.user.profileTemplateIds ? data.user.profileTemplateIds.split(',').map(Number) : []
          }
        };

        sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));
        //this.userInfoService.update();
        resolve(true);
      }, error => {

      });
  }

}

export class SystemSetting {
  public systemTheme: string;
  public enableRegistration: string;
  public windowsAuth: string;
  public idenediClientId: string;
  public redirectUriForIdenedi: string;
  public redirectUriForIdenediProfile: string;
  public idenediAuthBaseUri: string; //url used to redirection for idenedi group selection
}
