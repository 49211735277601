import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { LabelService } from '../../common/services/label-service';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { AccountService } from '../../account/services/account.service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { FeildType } from '../../common/enums/field-type-enum';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MessageService } from '../../common/services/message-service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserInfoService } from '../../common/services/userinfo-service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, interval } from 'rxjs';
import { ApplicantService } from '../../applicant/applicant.service';
import { ApplicantModel } from '../../models/ApplicantModel';
import { SlideUpDown } from '../../common/animations/animations';
import { AllDefinitionsService } from '../../definitions/services/all-definitions.service';
import { IncompleteProfileComponent } from '../incomplete-profile/incomplete-profile.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApplicantRequestService } from '../services/applicant-request.service';
import { RouterExtService } from '../../common/services/router-ext.service';
import { ComponentName } from '../../common/enums/component-names-enum';

@Component({
  selector: 'app-applicant-request-authentication',
  templateUrl: './applicant-request-authentication.component.html',
  styleUrls: ['./applicant-request-authentication.component.less'],
  animations: [SlideUpDown]
})
export class ApplicantRequestAuthenticationComponent implements OnInit {

  public systemLogoExists: boolean = true;
  @Input() definitionKey: string = '';
  private _definitionId: number = 0;
  public isApplicantAlreadyMember: boolean = false;
  FType: typeof FeildType = FeildType;
  public isCallback = false;
  @Output() callComponentTo = new EventEmitter();
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  fullName = new FormControl('', [Validators.required, Validators.minLength(3)]);
  private personaId: number;
  public applicantNotActive: boolean = false;
  public isLoginError: boolean = false;
  loginErrorMsg: string = '';
  componentName: typeof ComponentName = ComponentName;

  authenticationForm = new FormGroup({
    fullName: this.fullName,
    email: this.email,
    password: this.password
  });
  constructor(public dataSharingService: DataSharingService
    , public labelService: LabelService
    , private accountService: AccountService
    , private _translationHelperService: TranslationHelperService
    , private _msgService: MessageService
    , private _userInfoService: UserInfoService
    , private translate: TranslateService
    , private router: Router
    , private route: ActivatedRoute
    , private applicantService: ApplicantService
    , private _definitionService: AllDefinitionsService
    , public requestDialog: MatDialog
    , private _applicationService: ApplicantRequestService
    , private routerExtService: RouterExtService
  ) { }

  ngOnInit() {
    if (this.definitionKey) {
      let emailText = this._translationHelperService.getTranslationByKey('Common.EMAIL');
      let nameText = this._translationHelperService.getTranslationByKey('Common.FULL-name');
    }
    this._definitionService.getApplicationDefinitionByKey(this.definitionKey).subscribe((resp:any) => {
      this._definitionId = resp.id;
    });
  }

  isSystemLogoExist(e) {
    this.systemLogoExists = false;
  }

  applicantAlreadyIsAMember() {
    this.isCallback = true;
    this.isLoginError = false;
    this.applicantNotActive = false;
    let emailRegularExpression: RegExp = /^\w+([-+.']\w+)*@\w+([-. ]\w+)*\.\w+([-. ]\w+)*$/;
    if (emailRegularExpression.test(this.email.value))
      this.accountService.isApplicantExistsWithDefinitionKey(this.email.value, this.definitionKey).subscribe((data: any) => {
        this.isCallback = false;
        if (data) {
          this.fullName.setValue(data.name);
          this.personaId = data.id;
          this.isApplicantAlreadyMember = true;
        } else {
          this.isApplicantAlreadyMember = false;
        }
      }, (err: HttpErrorResponse) => {
        this.isCallback = true;
        if (err.status == 409 && err.error.code == "ApplicantNotActivated") {
          this.applicantNotActive = true;
          this.personaId = err.error.result //personaId to be sent in invite link 
        }
      });
  }
  private emailToBeChecked: string = '';
  private checApplicantStatusInterval: any;
  sendInviteLink() {
    this.emailToBeChecked = this.email.value;
    var applicantModel: ApplicantModel = new ApplicantModel();
    applicantModel.id = this.personaId;
    this.applicantService.inviteAndReinviteApplicant(applicantModel).subscribe(resp => {
      this._msgService.showMessage("Invitation link sent to email");
      this.checApplicantStatusInterval = interval(10000).subscribe(() => {
        let emailRegularExpression: RegExp = /^\w+([-+.']\w+)*@\w+([-. ]\w+)*\.\w+([-. ]\w+)*$/;
        if (emailRegularExpression.test(this.emailToBeChecked) && this.emailToBeChecked == this.email.value) {
          this.accountService.isApplicantExists(this.emailToBeChecked).subscribe((data: any) => {
            this.checApplicantStatusInterval.unsubscribe();
          });
        } else {
          this.checApplicantStatusInterval.unsubscribe();
        }
      });
    });
  }

  getPasswordErrorMessage() {
    return this.password.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.PASSWORD-REQUIRED') :
      this.password.hasError('minlength') ? this._translationHelperService.getTranslationByKey('RequiredMessages.PASSWORD-MINIMUM-CHARACTERS') :
        '';
  }

  private initializeSystemLanguage() {
    this.dataSharingService.systemLanguages.subscribe(systemLanguages => {
      this._translationHelperService.getOrganizationLocales().subscribe(resp => {
        let activeLocales = resp.body.split(',');
        if (activeLocales.length > 0) {
          systemLanguages.forEach(systemLanguage => {
            if (!systemLanguage.isDefault)
              systemLanguage.isActive = false;
          });
          systemLanguages.forEach(systemLanguage => {
            activeLocales.forEach(activeLocale => {
              if (systemLanguage.locale == activeLocale) {
                systemLanguage.isActive = true;
              }
            })
          });
        }
      });

    });
  }
  incompleteApplicantProfileDialog(editable: boolean) {
    const incompleteAppilcantProfileRef = this.requestDialog.open(IncompleteProfileComponent, {
      width: '440px',
      autoFocus: false,
      data: { editable: editable, callFrom: this.componentName.ApplicantRequestAuthenticationComponent}
    });
    incompleteAppilcantProfileRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }
  continueToSubmission() {
    if (this.isApplicantAlreadyMember) {
      this.authenticateUser();
    } else {
      var data = {
        name: this.fullName.value,
        email: this.email.value,
        callTo: "ApplicantProfile"
      }
      this.callComponentTo.emit(data);
    }
  }

  goto() {
    this.router.navigateByUrl('/forgotpassword')
  }

  private authenticateUser() {
    this.isLoginError = false;
    this.isCallback = true;
    if (this.isApplicantAlreadyMember) {
      this.accountService.userAuthentication(this.email.value, this.password.value, this.personaId).subscribe((data: any) => {
        var personas = JSON.parse(data.personas);
        var result = {
          token: {
            accessToken: data.access_token,
            expiry: new Date(data['.expires']),
            refreshToken: data.refresh_token,
            persoans: personas
          },
          user: {
            name: data.name,
            email: data.email,
            role: data.role,
            imageUrl: data.imageUrl,
            organizationId: data.organizationId,
            profileTemplateIds: data.profileTemplateIds ? data.profileTemplateIds.split(',').map(Number) : [],
            applicantPersonaId: data.applicantPersonaId ? +data.applicantPersonaId : 0
          }
        };
        sessionStorage.clear();
        sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));
        this._userInfoService.update();

        this.initializeSystemLanguage();
        if (this._definitionId > 0) {
          this._applicationService.checkProfileStatus().subscribe(resp => {
            if (resp.body == "IN-COMPLETE") {
              this.incompleteApplicantProfileDialog(true);
            } else if (resp.body == "IN-COMPLETE-BY-ADMIN") {
              this.incompleteApplicantProfileDialog(false);
            } else {
              this.router.navigateByUrl('/request/submit/' + this._definitionId + '?type=definition');
            }
          });
        } else {
          this._definitionService.getApplicationDefinitionByKey(this.definitionKey).subscribe(resp => {
            this._definitionId = resp.body.id;
            this.router.navigateByUrl('/request/submit/' + this._definitionId + '?type=definition')
          });
        }
      },
        (err: HttpErrorResponse) => {
          this.isLoginError = true;
          this.isCallback = false;
          this.loginErrorMsg = this.accountService.getInvalidCredentialMessage(err, this.email.value);
        });
    }
  }
}
