import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IdenediLinkGroupModel } from "../../models/idenedi-link-group.model";
import { IdenediGroupModel } from "../../models/idenedi-group.model";

@Injectable({
  providedIn: 'root'
})
export class IdenediGroupService {
  constructor(
    private http: HttpClient
  ) {

  }

  link(idenediGroupId: string): Observable<any> {
    let data = {
      idenedi : idenediGroupId
    }
    return this.http.post<any>('/webapi/idenedigroup/link', data);
  }

  getIdenediGroup(): Observable<IdenediGroupModel>{
    return this.http.get<any>('/webapi/idenedigroup/group');
  }

  getIdenediLinkGroup(): Observable<IdenediLinkGroupModel>{
    return this.http.get<IdenediLinkGroupModel>('/webapi/idenedigroup');
  }

  saveIdenediLinkGroupMapping(group: IdenediLinkGroupModel): Observable<any>{
    return this.http.put<any>('/webapi/idenedigroup/save', group);
  }

  registerUserToOrganizationsByGroups(): Observable<any>{
    return this.http.get<any>('/webapi/idenedigroup/register');
  }

  syncGroupUsers(groupId: string): Observable<any>{
    return this.http.get<any>(`/webapi/idenedigroup/SyncUsers/${groupId}`);
  }

  syncIdenediUsers(): Observable<any>{
    return this.http.get<any>(`/webapi/idenedigroup/SyncUsers/`);
  }
}
