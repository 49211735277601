import { FormGroup, AbstractControl } from "@angular/forms";

// To validate password and confirm password
export function EndDateRangeValidator(formGroup: FormGroup): { [key: string]: any } | null {
  const start = formGroup.controls['start'];
  const end = formGroup.controls['end'];

  if (start.value && !end.value) {
    return { endDateRequried: true };
  } else {
    return null;
  }
}
