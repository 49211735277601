import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { SystemLanguage } from '../../models/system-language';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslationHelperService } from '../../common/services/translation-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ManageLanguagesService {

  constructor(
    private http: HttpClient,
    public _dataSharingService: DataSharingService,
    private translationHelperService: TranslationHelperService
  ) { }

  updateActiveSystemLanguages(activeLanguages): Observable<any> {

    return this.http.put<any>('/WebApi/organization/locales/' + activeLanguages, {});
  }

  checkForEnabledLanguages(): Observable<SystemLanguage[]> {
    return this._dataSharingService.systemLanguages.pipe(
      map(systemLanguages => {
        this.translationHelperService.getOrganizationLocales().subscribe(resp => {
          let activeLocales = resp.body.split(',');
          if (activeLocales.length > 0) {
            systemLanguages.forEach(systemLanguage => {
              if (!systemLanguage.isDefault)
                systemLanguage.isActive = false;
            });
            systemLanguages.forEach(systemLanguage => {
              activeLocales.forEach(activeLocale => {
                if (systemLanguage.locale == activeLocale) {
                  systemLanguage.isActive = true;
                }
              })
            });
          }
        });
        return systemLanguages;
      })
    );
  }
}
