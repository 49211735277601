import { Directive, Input } from "@angular/core";
import { NG_VALIDATORS, Validator, FormControl } from "@angular/forms";

@Directive({
  selector: "[requireFile]",
  providers: [
    { provide: NG_VALIDATORS, useExisting: FileValidator, multi: true },
  ]
})
export class FileValidator implements Validator {
  @Input()
  requireFile: boolean;
  validate(c: FormControl): { [key: string]: any } {
    if (this.requireFile) {
      return c.value == null || c.value.length == 0 ? { "required": true } : null;
    }else {
      return null;
    }
  }

  //validate(c: FormControl): { [key: string]: any } {
  //  return FileValidator.validate(c);
  //}
}


import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: 'input[type=file]',
  host: {
    '(change)': 'onChange($event.target.files)',
    '(blur)': 'onTouched()',
  },
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FileValueAccessor, multi: true },
  ],
})
export class FileValueAccessor implements ControlValueAccessor {
  value: any;
  onChange = _ => { };
  onTouched = () => { };

  writeValue(value) { }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
