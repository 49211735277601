import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter, ApplicationRef, TemplateRef } from '@angular/core';
import { CustomFieldModel } from '../../../profile-customization/Model/CustomFieldModel';
import { MessageService } from '../../../common/services/message-service';
import { SharedService } from '../../../shared/shared.service';
import { TranslationHelperService } from '../../../common/services/translation-helper.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FeildType } from '../../../common/enums/field-type-enum';
import { ErrorDetails, ValueDetails } from '../../../models/custom-field-value-model';
import { CustomFieldService } from '../../../common/services/custom-field.service';
import { D } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-esp-multi-file-uploader',
  templateUrl: './esp-multi-file-uploader.component.html',
  styleUrls: ['./esp-multi-file-uploader.component.scss']
})
export class EspMultiFileUploaderComponent implements OnInit {
  public images = [];
  @Output() isFileUploaded = new EventEmitter();
  @Output() isValid = new EventEmitter();
  @Output() isCallback = new EventEmitter();
  @Output() addNestedField = new EventEmitter<CustomFieldModel>();

  @Input() customFieldForm: FormGroup;
  @Input() customField: CustomFieldModel;
  @Input() fieldOptions: any = { showLabel: true };
  @Input() isDisabled;
  @Input() showHint: boolean = true;
  @Input() showHintIconSpace = true;
  @Input() isLastNestedField: boolean = true;
  @Input() nestedFieldIndex: number = 0;
  @Input() nestedFieldsCount: number;
  @Input() doFileUploadOnChange: boolean = true;
  FType = FeildType;
  // onBlur(customField: CustomFieldModel) {
  //   this.blur.emit(customField.isTigger);
  // }
  public isFileButtonTouched: boolean = false;
  public file: any;
  @ViewChild('inputTypeFile', { static: false }) inputTypeFile: ElementRef;

  @Input() addBtnForBoxControl: TemplateRef<any>;
  @Input() isLongLabel: TemplateRef<any>;
  @Input() addBtnForBoxControls: TemplateRef<any>;
  @Input() tempNesterFieldIndex: TemplateRef<any>;
  @Input() mobilefieldsicon: TemplateRef<any>;
  @Output() blur = new EventEmitter();
  onBlur(customField: CustomFieldModel) {
    this.blur.emit(customField.isTigger);
  }
  public xyz:CustomFieldModel[] =[]
  constructor(
    private customFieldService: CustomFieldService,
    private sharedService: SharedService,
    private _applicationRef: ApplicationRef

  ) { }

  ngOnInit(): void {
    this.customField.allowedValuesCriteria = ".png,.jpg,.jpeg,.gif";
    // console.log(this.customField);
    
    
  }
  ngAfterViewInit(): void {
    if(this.customField.isRequired && !this.customField.value) {
      this.isValid.emit(false);
    } else {
      this.isValid.emit(true);

    }
  }
  openFileUploader() {
    this.isFileButtonTouched = true;
    this.inputTypeFile.nativeElement.click();
    this.inputTypeFile.nativeElement.classList.add('ng-touched');
    this.isValid.emit(false);

  }
  removeAttachedFile(id) {
    this.isFileButtonTouched = true;
    this.customField.details.gallery = this.customField.details.gallery.filter(f => f.fileId != id); 
    this.resetFileInput();
    if(this.customField.isRequired && !this.customField.details.gallery?.length) {
      this.isValid.emit(false);
    }
    if(this.galleryErrorList?.length == 0 && this.customField.details.gallery?.length == 0) {
      this.customField.value = '';
    }

  }
  
  resetFileInput() {
    this.inputTypeFile.nativeElement.value = "";
  }

  getFormatedExtenssions(allowedEx: string) {
    let exArray = allowedEx.split(',');
    return exArray.join(', ');
  }

  myFileData =[]
  maxFileSizeError: boolean = false;
  fileExtensionError: boolean = false;
  fileExtensionDotLengthError: boolean = false;
  galleryErrorList : ErrorDetails[] = [];
  selectImageGallery(event,customField: CustomFieldModel) {
    this.isValid.emit(false);
    this.galleryErrorList = []
    if (event.target.files && event.target.files.length) {
      for(let i=0; i <  event.target.files.length; i++) {
        customField.value = event.target.files[i].name;
        // console.log('Value', customField.value);
        
        var fileSize = event.target.files[i].size;
        var thisRef = this;
        // console.log('filez', fileSize);
        
        var inMbs = (fileSize / 1024) / 1024;
        console.log('inMbs', inMbs);
        // console.log('customField.maxVal', customField.max);
        
        ;
        let file = event.target.files[i]
        if (this.customFieldService.getFileExtentionDotLength(file.name) > 1) {
          this.galleryErrorList.push({file:file.name, error:'File name cannot contain more than one dot'});
          this.isDisabled = false;
          this.isValid.emit(false);
          setTimeout(function () {
            thisRef.isValid.emit(false);
            thisRef._applicationRef.tick();
          })
          // console.log('GalleryList', this.galleryErrorList);

        } else if(customField.max && inMbs > customField.max){
          this.galleryErrorList.push({file:file.name, error:`File size error ${customField.max} MB`});
          this.isDisabled = false;
          this.isValid.emit(false);
          setTimeout(function () {
            thisRef.isValid.emit(false);
            thisRef._applicationRef.tick();
          })
        }else if (!this.validateFileExtension(file.name)){
          this.galleryErrorList.push({file:file.name, error:'This field only supports .png, .jpg, .jpeg, .gif'});
          this.isDisabled = false;
          this.isValid.emit(false);
          setTimeout(function () {
            thisRef.isValid.emit(false);
            thisRef._applicationRef.tick();
          })
        } else {
          this.onFileChange(file, customField);
        }
        
      }
      // console.log('GalleryList', this.galleryErrorList);
      
    }
  }
  onFileChange(event,customField) {
    var thisRef = this;
    if (event) {
      // console.log('Event', event);
      var reader = new FileReader();
      let formData = new FormData();
      reader.readAsDataURL(event);
      formData.append('file', event);

      reader.onload = () => {

        this.sharedService.uploadAttachmentWithScfid(formData, customField.sectionCustomFieldId).subscribe(resp => {
          if (resp) {
            if (!customField.details.gallery) {
              customField.details.gallery = [];
            }
            customField.details.gallery.push(resp.body);
            customField.fileContent = customField.details.gallery;
            if(thisRef.galleryErrorList.length == 0) {
              this.isValid.emit(true);
            }
            
          }
          
        }, error => {
          // this.isCallback.emit(false);
          // this.isDisabled = false;
          // this.removeAttachedFile(customField.details.gallery.fileId);
        });
      };
    }
  }

  removeErrorFile(i) {
    this.isFileButtonTouched = true;
    
    this.galleryErrorList.splice(i, 1);
    this.resetFileInput();
    // console.log('i', this.galleryErrorList.length);
    if(this.galleryErrorList?.length == 0 && this.customField.details.gallery == null) {
      this.customField.value = '';
    }
    if(this.galleryErrorList?.length == 0 && !this.customField.isRequired) {
      this.isValid.emit(true);
      this.isDisabled = true;
      // console.log('tue');
      
    } else if (this.galleryErrorList?.length == 0 && this.customField.isRequired && this.customField.details.gallery?.length){
      this.isValid.emit(true);
      this.isDisabled = true;
      // console.log('false');

    } else {
      this.isValid.emit(false);
      this.isDisabled = false;
    }
    
  }
 
  private validateFileExtension(name: String) {
    // console.log('Name', name);
    if (this.customField.allowedValuesCriteria.trim()) {
      var extensionsArray: string[] = this.customField.allowedValuesCriteria.split(',');
      if (extensionsArray[0] == "-2") {
        var extenssionFilter = extensionsArray.filter(x => x != "-2");
        var errorString = extenssionFilter.toString();
        this.customField.allowedValuesCriteria = errorString;
      }
      var ext = name.substring(name.lastIndexOf('.'));
      var lowerCaseExt = ext.toLowerCase();
      if (extensionsArray.find(x => x == lowerCaseExt) != undefined) {
        // console.log('1', this.customField);

        return true
      }
      // console.log('2', this.customField);
      return false

    }
    else {
      // console.log('3', this.customField);
      return true;
    }
  }
  
}
