import { Injectable } from '@angular/core';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { ManageCurrenciesService } from '../../settings/manage-currencies/manage-currencies.service';
import { FormSectionModel } from '../../models/CustomizationSettingsSubmitModel';
import { FeildType } from '../enums/field-type-enum';
import { ApplicationDetail } from '../../models/applicationdetailmodel';
import { AggregateOperator } from '../enums/aggregate-operator.enum';

@Injectable({
  providedIn: 'root'
})
export class MultiSectionAggregateService {
  constructor(
    private _currencyService: ManageCurrenciesService
  ) {
  }

  calculateAggregateFieldsForCriteria(applicationDetails: ApplicationDetail) {
    let allSections = applicationDetails.form.sections;
    allSections = allSections.concat(applicationDetails.stages.flatMap(s => s.criteriaList).flatMap(c => c.form.sections));
    this.calculateAggregateFields(allSections);
  }

  calculateAggregateFields(sectionList: FormSectionModel[]): boolean {
    let triggerGetCalculated = false;
    const multiSections = sectionList.filter(s => true);
    sectionList.filter(s => !s.isMultipule && s.nestedSections.length > 0).forEach(section => {
      let aggregatorFields = section.nestedSections[0].fields.filter(f => f.type == FeildType.Aggregator);
      if(aggregatorFields.length > 0){
        triggerGetCalculated = true;
      }
      aggregatorFields.forEach(aggregatorField => {
        let targetSection: FormSectionModel;
        let targetField: CustomFieldModel;
        for (let i = 0; i < multiSections.length; i++) {
          const aggregateFieldId = this.getAggregateCustomFieldId(aggregatorField.allowedValuesCriteria);
          const field = multiSections[i].fields.find(f => f.sectionCustomFieldId == aggregateFieldId);
          if (field) {
            targetSection = multiSections[i];
            targetField = field;
            break;
          }
        }

        if (targetField && targetSection) {
          switch (targetField.type) {
            case FeildType.Number: {
              let trigger = this.addValueInNestedSections(targetSection, aggregatorField);
              if(!triggerGetCalculated){
                triggerGetCalculated = trigger;
              }
              break;
            }
            case FeildType.Money: {
              const currencyFields = targetSection.nestedSections.flatMap(ns => ns.fields).filter(f => f.sectionCustomFieldId == targetField.sectionCustomFieldId);
              if (currencyFields.length > 0) {
                if (currencyFields.every(cf => cf.selectedCurrencyId == currencyFields[0].selectedCurrencyId || cf.selectedCurrencyId == 0)) {
                  let trigger = this.addValueInNestedSections(targetSection, aggregatorField, currencyFields[0].selectedCurrencySymbol);
                  if(!triggerGetCalculated){
                    triggerGetCalculated = trigger;
                  }
                } else {
                  let trigger = this.addValueInNestedSections(targetSection, aggregatorField, currencyFields[0].selectedCurrencySymbol, true);
                  if(!triggerGetCalculated){
                    triggerGetCalculated = trigger;
                  }
                }
              }
              break;
            }
            case FeildType.Calculated:
            case FeildType.Mapped: {
              let trigger = this.addValueInNestedSections(targetSection, aggregatorField);
              if(!triggerGetCalculated){
                triggerGetCalculated = trigger;
              }
              break;
            }
            // default: {
            //    //statements;
            //    break;
            // }
          }
        }
      });
    });
    return triggerGetCalculated;
  }

  private getValuesToAggregate(inputField: CustomFieldModel, aggNumbersArray: number[], prefixText: string = '', useBaseCurrency = false) {
    if (inputField) {
      if (inputField.type != FeildType.Money || (inputField.type == FeildType.Money && inputField.selectedCurrencyId > 0)) {
        if (inputField.type == FeildType.Money && useBaseCurrency) {
          this._currencyService.getCachedCurrencies().subscribe(currencies => {
            prefixText = currencies.find(c => c.isBase).symobl;
            const currency = currencies.find(c => c.id == inputField.selectedCurrencyId);
            if (currency) {
              const convertedBaseAmount = +inputField.value * currency.rateToBase;
              //total = convertedBaseAmount + total;
              aggNumbersArray.push(convertedBaseAmount);
            }
          });
        } else {
          //total = +inputField.value + total;
          if(inputField.lookupValues && inputField.lookupValues.length > 0){
            inputField.lookupValues.forEach(lv => {
              aggNumbersArray.push(+lv.id);
            });
          }else{
            if(inputField.value.toString().trim().indexOf(' ') > -1){ //SAR 50 currency handling
              const values = inputField.value.trim().split(' ').filter(v => v.trim() != '');
              if(values.length > 1){
                prefixText = values[0];
                aggNumbersArray.push(+values[1]);
              }
            }else{
              aggNumbersArray.push(+inputField.value);
            }
          }
        }
      }
    }
  }

  private addValueInNestedSections(section: FormSectionModel, aggregatorField: CustomFieldModel, prefixText: string = '', useBaseCurrency = false): boolean {
    let total = 0;
    let aggNumbersArray: number[] = [];
    let triggerGetCalculated = false;
    const aggOperator = this.getAggregateOperator(aggregatorField.allowedValuesCriteria);
    section.nestedSections.forEach((inputSection, sectionIndex) => {
      const aggregateFieldId = this.getAggregateCustomFieldId(aggregatorField.allowedValuesCriteria);
      const inputField = inputSection.fields.find(f => f.sectionCustomFieldId == aggregateFieldId);
      if (inputField)
        if (inputField.isMultipleAllowed) {
          if (inputField.nestedFields) {
            inputField.nestedFields.filter(nf => nf.value).forEach(nestedInputField => {
              this.getValuesToAggregate(nestedInputField, aggNumbersArray, prefixText, useBaseCurrency);
            });
          }
        } else {
          if (inputField.value) {
            this.getValuesToAggregate(inputField, aggNumbersArray, prefixText, useBaseCurrency);
          }
        }
    });
    if (aggNumbersArray.length > 0) {
      if(aggregatorField.isTigger){
        triggerGetCalculated = true;
      }
      switch (aggOperator) {
        case (AggregateOperator.Sum): {
          total = aggNumbersArray.reduce((sum, current) => sum + current, 0);
          break;
        }
        case (AggregateOperator.Min): {
          total = this.getMin(aggNumbersArray);
          break;
        }
        case (AggregateOperator.Max): {
          total = this.getMax(aggNumbersArray);
          break;
        }
        case (AggregateOperator.Average): {
          const sum = aggNumbersArray.reduce((sum, current) => sum + current, 0);
          total = sum / aggNumbersArray.length;
          break;
        }
        case (AggregateOperator.Count): {
          total = aggNumbersArray.length;

          break;
        }
      }
    }
    if (aggOperator == AggregateOperator.Count) {
      aggregatorField.value = total.toString();
    } else {
      aggregatorField.value = this.formatValue(total, prefixText);
    }
    return triggerGetCalculated;
  }

  private formatValue(value: number, prefix: string): string {
    if (prefix) {
      return `${prefix} ${value.toFixed(2).toString()}`;
    } else {
      return value.toFixed(2).toString();
    }
  }

  setInvisibleMultisectionsAfterSubmission(sectionList: FormSectionModel[]) {
    sectionList.filter(s => s.isMultipule).forEach(section => {
      for (let i = 0; i < section.nestedSections.length - 1; i++) {
        section.nestedSections[i].fields = section.nestedSections[i].fields.filter(f => f.type != FeildType.Aggregator);
      }
    });
  }

  private getAggregateCustomFieldId(allowedValuesCriteria: string): number {
    const aggInfo = JSON.parse(allowedValuesCriteria);

    if (typeof aggInfo === 'object' && aggInfo !== null) {
      return aggInfo.fieldId;
    } else {
      return aggInfo;
    }
  }

  private getAggregateOperator(allowedValuesCriteria: string): string {
    const aggInfo = JSON.parse(allowedValuesCriteria);

    if (typeof aggInfo === 'object' && aggInfo !== null) {
      return aggInfo.operator;
    } else {
      return AggregateOperator.Sum;
    }
  }

  private getMin(arr) {
    let min = arr[0];
    let i = arr.length;

    while (i--) {
      min = arr[i] < min ? arr[i] : min;
    }
    return min;
  }

  private getMax(arr) {
    let max = arr[0];
    let i = arr.length;

    while (i--) {
      max = arr[i] > max ? arr[i] : max;
    }
    return max;
  }
}
