<xcdrs-breadcrumbs *ngIf="callFrom != componentName.ApplicationDetailComponent && !cloneApplication">
  <ng-template #recordsRoute>
    <a routerLink="/records" [queryParams]="{'tab': tab}" [translate]="'Common.Records'"></a>
  </ng-template>
  <ng-container *ngIf="refApplicationId && !isVerificationStep">
    <a *ngIf="!isRecord else recordsRoute" routerLink="/applications" [queryParams]="{'tab': tab}">{{labelService.labels?.applications | translateToLocale}}</a>
    <a [routerLink]="['/applications/' + refApplicationId]"
    [queryParams]="{'my': isMySpace}">{{referencedDefinitionName ? referencedDefinitionName : selectedApplication.applicationName}}</a>
    <a *ngIf="folderAppFor == 'Main'" [routerLink]="['/applications/' + refApplicationId +'/folder/' + customFeildList?.id + '/' + refScfId]" 
      [queryParams]="{tab: tab, my: isMySpace, type: (isRecord ? 'record' : '')}">{{customFeildList?.name | translateToLocale}}</a>
  </ng-container>
  <ng-container *ngIf="feedOnBehalf && !isVerificationStep">
    <a *ngIf="!isRecord else recordsRoute" routerLink="/applications" [queryParams]="{'tab': tab}">{{labelService.labels?.applications | translateToLocale}}</a>
    <a *ngIf="isMySpace && tab == 1" routerLink="/applications/myspace/assign" [queryParams]="{'tab': tab}"
    [translate]="'Application.AssignedApplications'" [translateParams]="{value: labelService.labels?.applications | translateToLocale}"></a>
    <a [routerLink]="['/applications/' + parentApplicationId]"
      [queryParams]="{'my': isMySpace, 'tab': tab}">{{customFeildList.parentApplicationName | translateToLocale}}</a>
  </ng-container>
  <ng-container *ngIf="!refApplicationId && !feedOnBehalf && !isVerificationStep">
    <ng-container *ngIf="!isSubmission; else linkToMySpace">
      <a *ngIf="!isRecord else recordsRoute" routerLink="/applications">{{labelService.labels?.applications | translateToLocale}}</a>
      <a *ngIf="assessmentId == 0" routerLink="/request/catelog" [queryParams]="{'type': isRecord ? 'record' : ''}" [translate]="'Application.CATALOG'"></a>
      <a *ngIf="assessmentId > 0 && !isRecord" [routerLink]="['/applications/' + subDefApplicationId]" [queryParams]="{'type': isRecord ? 1 : 0, assessmentId: assessmentId}">{{subDefApplicationName | translateToLocale}}</a>
      <a *ngIf="assessmentId > 0 && isRecord" [routerLink]="['/records/' + subDefApplicationId]" [queryParams]="{'type': isRecord ? 1 : 0, assessmentId: assessmentId}">{{subDefApplicationName | translateToLocale}}</a>
    </ng-container>
    <ng-template #linkToMySpace>
      <a *ngIf="!isRecord else recordsRoute" routerLink="/applications" [queryParams]="{'tab': tab}">{{labelService.labels?.applications | translateToLocale}}</a>
      <a *ngIf="isMySpace && tab == 0" routerLink="/applications/myspace/invite" [queryParams]="{'tab': tab}"
        [translate]="'Application.FEEDS'"></a>
      <a routerLink="/applications/submission/{{selectedDefinationId}}/{{parentApplicationId}}"
        [queryParams]="{'my': isMySpace}">
        {{customFeildList.name | translateToLocale}}
      </a>
    </ng-template>
  </ng-container>
  <span *ngIf="reviseApplication" [translate]="'Application.REVISE-APPLICATION'"
  [translateParams]="{application: labelService.labels?.application | translateToLocale}"></span>
  <span *ngIf="!reviseApplication" [translate]="'Application.CREATE-APPLICATION'"
    [translateParams]="{application: labelService.labels?.application | translateToLocale}"></span>
</xcdrs-breadcrumbs>
<div class="exceeders-card" [ngClass]="{'wizard-card' : customFeildList.form?.isWizardView, 'clone-application-card' : cloneApplication}">
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!showSkeleton">
    <h1 *ngIf="callFrom != componentName.ApplicationDetailComponent && !isVerificationStep"> 
      <div *ngIf="(customFeildList?.form?.isWizardView && !resubmit && !isCallback) || cloneApplication" class="circle-back" (click)="openDraftConfirmationDialog()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.23387 8.49997L7.47307 11.641L6.77693 12.3589L2.28176 7.99997L6.77693 3.64102L7.47307 4.35892L4.23387 7.49997L14 7.49997L14 8.49997L4.23387 8.49997Z" fill="#101828"/>
        </svg>
        </div>
      {{customFeildList.name | translateToLocale}}
    </h1>
    <button mat-icon-button class="btn-close" *ngIf="!customFeildList?.form?.isWizardView && !resubmit" (click)="openDraftConfirmationDialog()">
      <mat-icon>close</mat-icon>
    </button>
    <div fxFlex fxLayoutAlign="end end" fxLayoutGap="6px">
      
      <button mat-stroked-button color="primary" class="request-cancel" id="btnSave"
        *ngIf="customFeildList.form?.isWizardView && !resubmit" mat-button [disabled]="isCallback"
        (click)="saveApplicationDefination(false)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14.0834 0.75H0.75V17.25H3.75H5.25H12.75H14.25H17.25V3.91661L14.0834 0.75ZM12.75 15.75H5.25L5.25 10.5L12.75 10.5V15.75ZM14.25 9L14.25 15.75H15.75V4.53793L13.5 2.28793V6.75H4.5V2.25H2.25V15.75H3.75L3.75 9L14.25 9ZM12 2.25H6V5.25H12V2.25ZM11.25 3H9.75V4.5H11.25V3Z"
            fill="#5BAA15" />
        </svg>
    
        {{"Common.SAVE" | translate}}
      </button>
    </div>
  </div>
  <div class="sections-container" *ngIf="!isVerificationStep" fxLayout="row" fxLayoutGap="5px">
    <div fxLayout="column" fxFlex fxLayoutAlign="start center">
      <!-- <xcdrs-left-sections *ngIf="!customFeildList?.form?.isWizardView" [customFeildList]="customFeildList" fxHide.xs></xcdrs-left-sections> -->
      <div fxLayout="column" fxFlex>
        <div class="skeleton-container" fxLayout="column" fxFlex *ngIf="showSkeleton">
          <div class="section-heading_ske"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="skeleton-button"></div>
            <div class="skeleton-button"></div>
          </div>
        </div>
        <ng-container *ngIf="!showSkeleton  && customFeildList.form">
  
          <ng-container *ngIf="!customFeildList?.form.isWizardView; else wizardView">
            <div fxflex
              *ngFor="let section of customFeildList.form.sections | sectionVisibleFilter;trackBy:trackByFn; let sectionIndex = index;  let isLastSection = last; let isFirstSection = first"
              fxLayout="row" fxFlexLayoutAlign="start center" fxLayoutGap="24px">
              <ng-container *ngTemplateOutlet="customFieldsForm; context: {section:section, sectionIndex:sectionIndex, isLastSection:isLastSection, isFirstSection:isFirstSection}">
              </ng-container>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" class="buttons-container" *ngIf="!customFeildList?.form.isWizardView">
              <ng-container *ngIf="!isVerificationStep">
                <button fxFlexOffset.gt-xs="64px" mat-stroked-button color="primary" *ngIf="resubmit" class="request-cancel" (click)="onCancel()">
                  {{customFeildList.form.cancelLabel}}
                </button>
                <button fxFlexOffset.gt-xs="64px" mat-stroked-button color="primary" *ngIf="!resubmit" id="btnSave" class="request-cancel" [disabled]="isCallback" (click)="saveApplicationDefination(false)">{{"Common.SAVE" | translate}}</button>
                <button *ngIf="verificationRequired && !allCodesVerified" mat-flat-button class="gradient-btn request-submit" color="primary" [disabled]="isCallback || !formIsValid" (click)="showVerificationStep()">{{"NEXT" | translate}}</button>
                <button mat-flat-button class="gradient-btn request-submit" id="btnSubmit" *ngIf="(!verificationRequired || verificationRequired && allCodesVerified)" color="primary"
                  [disabled]="!formIsValid || isCallback || !submissionApplicantsArray.valid" (click)="saveApplicationDefination()">
                  {{callFrom == componentName.ApplicationDetailComponent ? ("RESUBMIT" |translate) :
                  customFeildList.form.submitLabel}}
                </button>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="sections-container" *ngIf="isVerificationStep">
    <email-verification [fieldsToVerify]="verificationFields" (onAllCodesVerified)="onAllCodesVerified($event)" (onBackClick)="onBackClick($event)"></email-verification>
  </div>
</div>



<ng-template #wizardView>
  <mat-tab-group class="definition-tab-group" [(selectedIndex)]="selectedTabIndex" style="max-width: 1160px; width: 100%;">
    <mat-tab  *ngFor="let section of customFeildList.form.sections| sectionVisibleFilter;trackBy:trackByFn; let sectionIndex = index;  let isLastSection = last;  let isFirstSection = first">
    
      <!-- <div *ngIf="sectionIndex == selectedMatStep" fxflex fxLayout="column"> -->
          <ng-template mat-tab-label>
            <div [class.tab-invalid]="!formIsValid || !submissionApplicantsArray.valid" fxLayout="row" fxLayoutAlign="space-between center">
              <div  fxLayout="row" fxLayoutAlign="space-between center">
                <div class="filled-circle" *ngIf="selectedTabIndex === sectionIndex && section.isValid">
                  {{sectionIndex + 1}}
                </div>
                <div class="selected-circle" *ngIf="selectedTabIndex === sectionIndex && !section.isValid">
                  {{sectionIndex + 1}}
                </div>
                <!-- <div class="default-circle invalid-circle" *ngIf="selectedTabIndex !== sectionIndex && !section.isValid">
                  {{sectionIndex + 1}}
                </div> -->
                <div class="default-circle" *ngIf="selectedTabIndex !== sectionIndex">
                  {{sectionIndex + 1}}
                </div>
                <div class="tab-name" [ngClass]="{'bold-text' : selectedTabIndex == sectionIndex}">
                  {{section.defaultName | translateToLocale}}
                </div>
              </div>
              <button mat-flat-button class="icon-btn" *ngIf="!isLastSection">
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 8.825L3.7085 5L0 1.175L1.1417 0L6 5L1.1417 10L0 8.825Z" fill="#D0D5DD" />
                </svg>
              </button>
                
            </div>
          </ng-template>
          <div fxflex fxLayout="row" fxFlexLayoutAlign="start end" fxLayoutGap="24px" style="gap:10px;">
            <ng-container *ngTemplateOutlet="customFieldsForm; context: {section:section, sectionIndex:sectionIndex, isLastSection:isLastSection, isFirstSection:isFirstSection}">
            </ng-container>
          </div>
          <!-- <ng-template matTabContent> -->
            <!-- <div *ngIf="sectionIndex == selectedMatStep" fxflex fxLayout="column"> -->
              
              <!-- <div fxflex fxLayout="row" fxFlexLayoutAlign="start end" fxLayoutGap="24px" style="gap:10px;">
                <ng-container *ngTemplateOutlet="customFieldsForm; context: {section:section, sectionIndex:sectionIndex, isLastSection:isLastSection, isFirstSection:isFirstSection}">
                </ng-container>
              </div> -->
            <!-- </div> -->
          <!-- </ng-template> -->
      <!-- </div> -->
    </mat-tab>
  </mat-tab-group>
</ng-template>


<ng-template #customFieldsForm let-section="section" let-sectionIndex="sectionIndex" let-isLastSection="isLastSection" let-isFirstSection="isFirstSection">
  <xcdrs-circles-sections *ngIf="!customFeildList?.form.isWizardView && !isVerificationStep" fxHide.xs [section]="section" [firstInvalidSectionIndex]="getFirstInvalidSection()"
    [sectionIndex]="sectionIndex" [sectionsLength]="(customFeildList.form.sections | sectionVisibleFilter).length"
    [formStepper]="customFeildList?.form.isWizardView">
  </xcdrs-circles-sections>
  <div fxLayout="column" *ngIf="!isVerificationStep" fxLayoutAlign="center start" fxFlex [ngClass]="{'left-align-content' : customFeildList?.isWizardView &&  section.isTableView}" class="section-container" id="section-{{sectionIndex}}">
    <div fxLayout="row" class="full-width" style="padding-right: 22px;" fxLayoutAlign="space-between start">
      <h3 style="margin-bottom: 0px;">{{section.defaultName | translateToLocale}}</h3>
      <button *ngIf="section.aiEnabled" mat-flat-button color="primary" class="add-button-gradiant" mat-button
        [disabled]="isCallback" (click)="openAIDialog(section.id)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_7763_25139)">
            <path
              d="M6.61448 0.140259C5.7012 5.10113 4.96083 5.84146 0 6.75474C4.96087 7.66802 5.7012 8.40835 6.61448 13.3692C7.52776 8.40835 8.26809 7.66802 13.229 6.75474C8.26809 5.84146 7.52772 5.10113 6.61448 0.140259Z"
              fill="white" />
            <path
              d="M6.61448 0.140259C5.7012 5.10113 4.96083 5.84146 0 6.75474C4.96087 7.66802 5.7012 8.40835 6.61448 13.3692C7.52776 8.40835 8.26809 7.66802 13.229 6.75474C8.26809 5.84146 7.52772 5.10113 6.61448 0.140259Z"
              fill="url(#paint0_linear_7763_25139)" />
            <path
              d="M13.7925 9.44641C13.2117 12.6013 12.7408 13.0722 9.58594 13.653C12.7408 14.2338 13.2117 14.7046 13.7925 17.8595C14.3732 14.7046 14.8442 14.2337 17.999 13.653C14.8441 13.0722 14.3733 12.6013 13.7925 9.44641Z"
              fill="white" />
            <path
              d="M13.7925 9.44641C13.2117 12.6013 12.7408 13.0722 9.58594 13.653C12.7408 14.2338 13.2117 14.7046 13.7925 17.8595C14.3732 14.7046 14.8442 14.2337 17.999 13.653C14.8441 13.0722 14.3733 12.6013 13.7925 9.44641Z"
              fill="url(#paint1_linear_7763_25139)" />
          </g>
          <defs>
            <linearGradient id="paint0_linear_7763_25139" x1="13.229" y1="13.3692" x2="-2.23174" y2="9.79565"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#0E7BCA" />
              <stop offset="1" stop-color="#91C740" />
            </linearGradient>
            <linearGradient id="paint1_linear_7763_25139" x1="17.999" y1="17.8595" x2="8.16664" y2="15.5869"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#0E7BCA" />
              <stop offset="1" stop-color="#91C740" />
            </linearGradient>
            <clipPath id="clip0_7763_25139">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Fill with AI
      </button>
    <!-- {{section.id}} -->
  <input style="display:none" #inputTypeFile type="file" (change)="onFileSelected($event, section.id)" class="custom-textbox" />

    </div>
    <div class="section-alignment">
      
      <ng-container *ngIf="!section.isTableView; else multiSectionTableView">
        <div class="section-controls-container" fxLayout="column"
          *ngFor="let nestedSection of section.nestedSections; let nestedSectionIndex = index; let isLast = last; trackBy: trackByForNestedSections"
          (click)="markSectionAsActive(sectionIndex)">
          <div class="nested-section-label"
            *ngIf="section.isMultipule && section.fields.length > 0 && !section.childAccessibility" fxLayout="row"
            fxLayoutAlign="start center">
            <div fxFlex>
              {{section.defaultName | translateToLocale}}#{{nestedSectionIndex + 1}}
            </div>
            <div fxLayoutAlign="end center"
              *ngIf="!section.extSecId && section.isMultipule && section.nestedSections.length > 1 && !section.childAccessibility">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="removeNestedSection(section.nestedSections, nestedSectionIndex)">
                  <!--<mat-icon>delete_outline</mat-icon>-->
                  <mat-icon>
                    <img src="../../../assets/images/delete-icon.svg" />
                  </mat-icon>
                  <span [translate]="'REMOVE'"></span>
                </button>
              </mat-menu>
            </div>
          </div>
          <app-custom-fields-form style="width:100%;"
            (isAllFormFilesUploaded)="formFilesUplaodStatus[toNumber(sectionIndex+''+nestedSectionIndex)] = $event"
            [doFileUpload]="doFileUpload" [doFileUploadOnChange]="doFileUploadOnChange" [mainObject]="customFeildList"
            [fields]="nestedSection.fields" [fieldsSection]="nestedSection" [currenciesList]="currenciesList"
            [isModelChanged]="isModelChanged" [isInitExpended]="false" [isFormSection]="true"
            [isApplicantCriteriaFormSection]="true" [applicantId]="customFeildList.applicantId"
            (onModelChange)="isModelChanged = $event"
            (getIsFormValid)="setFormSectionsValid(section.id, nestedSectionIndex, $event)" (blur)="onFormFieldBlur($event)"
            [dynamicFieldsValues]="dynamicFieldsValues" [showDynamicSpinner]="showDynamicSpinner"
            [sectionIndex]="nestedSectionIndex" [clientMessage]="clientMessage" [resubmit]="resubmit">
          </app-custom-fields-form>
      
          <div fxLayoutAlign="start center" fxFlex class="add-nested-section-btn-container"
            *ngIf="!section.extSecId && section.isMultipule && section.fields.length > 0 && !section.childAccessibility && isLast">
            <button mat-button color="primary" data-action="addMultiSection"
              (click)="addNestedSection(section.nestedSections, section)" fxLayoutAlign="center center" fxLayout="row">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div>
                  <mat-icon fxFlex>add_circle_outline</mat-icon>
                </div>
                <div class="text-ellipsis">
                  <span [translate]="'ADD'" class="add-nested-section"></span>&nbsp;<span
                    class="add-nested-section">{{section.defaultName | translateToLocale}}</span>
                </div>
              </div>
            </button>
            <!--<div>
      
                              </div>-->
          </div>
        </div>
      </ng-container>
      <!-- Table view design only for multi section and wizard view only -->
      <ng-template #multiSectionTableView>
        <div class="tbl-submission-view tw-flex tw-flex-col">
          <div class="tw-flex th tr">
            <div class="tw-flex tw-flex-row">
              <div *ngFor="let field of section.nestedSections[0].fields" class="td tw-w-60 tw-px-6 tw-py-3" [ngClass]="{'money-width' : field.type == FType.Price, 'hidden' : !field.isVisible}">{{field.label}} {{field.isRequired ? '*' : ''}}</div>
              <div *ngIf="!section.extSecId && section.isMultipule && section.nestedSections.length > 1 && !section.childAccessibility" class="td tw-flex tw-flex-row">
                <!-- used for delete -->
              </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-row tr" *ngFor="let nestedSection of section.nestedSections; let nestedSectionIndex = index; let isLast = last; trackBy: trackByForNestedSections">
            <app-custom-fields-form 
                (click)="markSectionAsActive(sectionIndex)"
                (isAllFormFilesUploaded)="formFilesUplaodStatus[toNumber(sectionIndex+''+nestedSectionIndex)] = $event"
                [doFileUpload]="doFileUpload" [doFileUploadOnChange]="doFileUploadOnChange" [mainObject]="customFeildList"
                [fields]="nestedSection.fields" [fieldsSection]="nestedSection" [currenciesList]="currenciesList"
                [isModelChanged]="isModelChanged" [isInitExpended]="false" [isFormSection]="true"
                [isApplicantCriteriaFormSection]="true" [applicantId]="customFeildList.applicantId"
                (onModelChange)="isModelChanged = $event"
                (getIsFormValid)="setFormSectionsValid(section.id, nestedSectionIndex, $event)" (blur)="onFormFieldBlur($event)"
                [dynamicFieldsValues]="dynamicFieldsValues" [showDynamicSpinner]="showDynamicSpinner"
                [sectionIndex]="nestedSectionIndex" [clientMessage]="clientMessage" [resubmit]="resubmit"
                [viewAsTableRow]="true"
                [showRemoveSection]="!section.extSecId && section.isMultipule && section.nestedSections.length > 1 && !section.childAccessibility"
                (removeSection)="removeNestedSection(section.nestedSections, nestedSectionIndex)">
              </app-custom-fields-form>
          </div>
          <div class="tw-flex tw-flex-row tr" *ngIf="!section.extSecId && section.isMultipule && section.fields.length > 0 && !section.childAccessibility">
            <button mat-button color="primary" data-action="addMultiSection"
                (click)="addNestedSection(section.nestedSections, section)" fxLayoutAlign="center center" fxLayout="row">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                  <div>
                    <mat-icon fxFlex>add</mat-icon>
                  </div>
                  <div class="text-ellipsis">
                    <span [translate]="'Common.Add-Row'"></span>&nbsp;<span></span>
                  </div>
                </div>
              </button>
          </div>
        </div>
        
        <!-- <div class="section-controls-container" fxLayout="column"
          *ngFor="let nestedSection of section.nestedSections; let nestedSectionIndex = index; let isLast = last; trackBy: trackByForNestedSections"
          (click)="markSectionAsActive(sectionIndex)">
          <div class="nested-section-label"
            *ngIf="section.isMultipule && section.fields.length > 0 && !section.childAccessibility" fxLayout="row"
            fxLayoutAlign="start center">
            <div fxFlex>
              {{section.defaultName | translateToLocale}}#{{nestedSectionIndex + 1}}
            </div>
            <div fxLayoutAlign="end center"
              *ngIf="!section.extSecId && section.isMultipule && section.nestedSections.length > 1 && !section.childAccessibility">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="removeNestedSection(section.nestedSections, nestedSectionIndex)">
                  <mat-icon>
                    <img src="../../../assets/images/delete-icon.svg" />
                  </mat-icon>
                  <span [translate]="'REMOVE'"></span>
                </button>
              </mat-menu>
            </div>
          </div>
          <app-custom-fields-form style="width:100%;"
            (isAllFormFilesUploaded)="formFilesUplaodStatus[toNumber(sectionIndex+''+nestedSectionIndex)] = $event"
            [doFileUpload]="doFileUpload" [doFileUploadOnChange]="doFileUploadOnChange" [mainObject]="customFeildList"
            [fields]="nestedSection.fields" [fieldsSection]="nestedSection" [currenciesList]="currenciesList"
            [isModelChanged]="isModelChanged" [isInitExpended]="false" [isFormSection]="true"
            [isApplicantCriteriaFormSection]="true" [applicantId]="customFeildList.applicantId"
            (onModelChange)="isModelChanged = $event"
            (getIsFormValid)="setFormSectionsValid(section.id, nestedSectionIndex, $event)" (blur)="onFormFieldBlur($event)"
            [dynamicFieldsValues]="dynamicFieldsValues" [showDynamicSpinner]="showDynamicSpinner"
            [sectionIndex]="nestedSectionIndex" [clientMessage]="clientMessage" [resubmit]="resubmit">
          </app-custom-fields-form>
  
          <div fxLayoutAlign="start center" fxFlex class="add-nested-section-btn-container"
            *ngIf="!section.extSecId && section.isMultipule && section.fields.length > 0 && !section.childAccessibility && isLast">
            <button mat-button color="primary" data-action="addMultiSection" (click)="addNestedSection(section.nestedSections, section)"
              fxLayoutAlign="center center" fxLayout="row">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div>
                  <mat-icon fxFlex>add_circle_outline</mat-icon>
                </div>
                <div class="text-ellipsis">
                  <span [translate]="'ADD'" class="add-nested-section"></span>&nbsp;<span
                    class="add-nested-section">{{section.defaultName | translateToLocale}}</span>
                </div>
              </div>
            </button>
          </div>
        </div> -->
      </ng-template>
  
      <div *ngIf="isLastSection && customFeildList.type == defintionType.SUBMISSION" class="section-controls-container" fxLayout="column">
        <div class="who-can-submit-container">
          <xcdrs-who-can-submit
            [submissionApplicantsArray]="submissionApplicantsArray"
            [definitionId]="applicationId > 0 ? customFeildList?.id : applicationDefinitionId"
            [applicationId]="applicationId" [selectAllByDefault]="!resubmit"
            [notifySubmitters]="notifySubmitters" [notificationEnabled]="notificationEnabled" [whoToInviteType]="whoToInviteType" [allowedSubmitters]="allowedSubmitters"
            [applicationStatus]="customFeildList.applicationStatus" [applicationStatusId]="customFeildList.applicationStatusId">
          </xcdrs-who-can-submit>
        </div>
      </div>
  
      <div fxLayout="row" *ngIf="customFeildList?.form.isWizardView" fxLayoutAlign="center center" class="buttons-container stepper-buttons-container">
        <ng-container *ngIf="!isVerificationStep">
          <!-- <button mat-stroked-button color="primary" class="request-cancel" id="btnSave" *ngIf="!resubmit" mat-button [disabled]="isCallback"
          (click)="saveApplicationDefination(false)">{{"Common.SAVE" | translate}}</button> -->
          <button style="width : 225px" mat-stroked-button color="primary" class="request-cancel" id="btnBack" mat-button [disabled]="isCallback || isFirstSection"
          (click)="preSections(sectionIndex)">{{"Common.Back" | translate}}</button>
          <button style="width : 225px"  mat-stroked-button color="primary" *ngIf="resubmit" class="request-cancel" (click)="onCancel()">
            {{customFeildList.form.cancelLabel}}
          </button>
          <button style="width : 225px" *ngIf="!isLastSection" mat-flat-button class="gradient-btn request-submit" color="primary" [disabled]="isCallback || !section.isValid"
           (click)="nextSections(sectionIndex)">{{"NEXT" | translate}}</button>
            
          <button style="width : 225px" *ngIf="isLastSection && verificationRequired && !allCodesVerified" mat-flat-button class="gradient-btn request-submit" color="primary" [disabled]="isCallback">{{"NEXT" | translate}}</button>
          <button style="width : 225px" *ngIf="verificationRequired && !allCodesVerified" mat-flat-button class="gradient-btn request-submit" color="primary" [disabled]="isCallback" (click)="showVerificationStep()">{{"NEXT" | translate}}</button>
          <button style="width : 225px" *ngIf="isLastSection && (!verificationRequired || verificationRequired && allCodesVerified)" mat-flat-button id="btnSubmit" class="gradient-btn request-submit" color="primary"
            [disabled]="!formIsValid || isCallback || !submissionApplicantsArray.valid" (click)="saveApplicationDefination()">
            {{callFrom == componentName.ApplicationDetailComponent ? ("RESUBMIT" |translate) :
            customFeildList.form.submitLabel}}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>