import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { ApplicationDefinition, DefinitionController } from '../models/application-definition';
import { NameId } from '../../models/UserResult';
import { DefinitionStageModel } from '../models/definition-stage';
import { UserResult } from '../../models/UserResult';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { DelegateUser } from '../../models/delegate-model';
import { CustomFieldFormService } from '../../common/services/custom-field-form.service';
import { map } from 'rxjs/operators';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { FieldValue } from '../../models/iframe/definition-filter.model';
import { CriteriaOwner } from '../../models/criteria-owner';
import { ApplicantIdName, ChildSubmitterInfoModel } from '../../models/child-submitter-info.model';
import { FormArray } from '@angular/forms';
import { ComponentName } from '../../common/enums/component-names-enum';
import { TranslateService } from '@ngx-translate/core';
import { FieldSettings } from '../../models/field-settings.model';
import { LinkedDefinitionInfoModel } from '../../models/definition-settings.model';
import { FeildType } from '../../common/enums/field-type-enum';
import { TranslateToLocalePipe } from '../../common/pipes/translate-to-locale.pipe';
import { DefinitionExtendedSettings } from '../models/definition-extended-settings';
import { LookupAdOnsService } from '../../esp-settings/lookup-addons/services/lookup-ad-ons.service';
import { LookupAdonsModel } from '../../models/lookup-ad-ons-model';
import { BulkRequestsModel } from '../../models/action-field-model';

@Injectable({
  providedIn: 'root'
})
export class AllDefinitionsService {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private commonHelperService: CommonHelperService,
    private _translateService: TranslateService,
    private lookupAdOnsService : LookupAdOnsService,
    private _translateToLocalePipe: TranslateToLocalePipe) {
    this.headers = this.commonHelperService.getCurrentLocaleHeader();
  }

  getApplicationDefinitions(): Observable<HttpResponse<ApplicationDefinition[]>> {
    return this.http.get<ApplicationDefinition[]>('/webapi/definition', { observe: 'response' });
  }

  getApplicationDefinitionsWithChildern(): Observable<any> {
    return this.http.get<any>('/webapi/definition/forLookups/withChildern', { observe: 'response' });
  }

  getActiveApplicationDefinitions(categoryId: number = 0): Observable<HttpResponse<ApplicationDefinition[]>> {
    return this.http.get<ApplicationDefinition[]>('/webapi/definition/active?categoryId=' + categoryId, { headers: this.headers, observe: 'response' });
  }

  getFilteredCategories(type: string = ''): Observable<any> {
    let queryParams = '';
    if (type) {
      queryParams += '?type=' + type;
    }
    return this.http.get<Observable<any>>('/webapi/category/AllWithQuery' + queryParams, { headers: this.headers, observe: 'response' }).pipe(
      map(resp => resp.body)
    );
  }
  getDefinitionsForDynamicApplicant(definitionId, applicantKey): Observable<any> {
    return this.http.post<Observable<any>>('/webapi/category/AllWithQueryForDynamicApplicant', { definitionId: definitionId, key: applicantKey }, { headers: this.headers, observe: 'response' }).pipe(
      map(resp => resp.body)
    );
  }

  addDefinition(definition: ApplicationDefinition): Observable<any> {
    return this.http.post<any>('/webapi/definition/add', definition);
  }

  createDefinition(definition: ApplicationDefinition): Observable<any> {
    return this.http.post<any>('/webapi/definition', definition);
  }

  cloneDefinition(definition: ApplicationDefinition): Observable<any> {
    return this.http.post<any>('/webapi/definition/clone', definition);
  }

  updateDefinition(definition: ApplicationDefinition): Observable<any> {
    return this.http.put<any>('/webapi/definition/basic', definition);
  }

  getDefinitionDetails(definitionId: number): Observable<HttpResponse<ApplicationDefinition>> {
    return this.http.get<ApplicationDefinition>('/webapi/definition/' + definitionId, { observe: 'response' }).pipe(
      map(resp => {
        resp.body.form.sections.forEach(sec => {
          sec.fields.forEach(field => {
            for (const key in new FieldSettings()) {
              if (field.settings) {
                let setting = field.settings.find(s => s.key.toLowerCase() == key.toLowerCase());
                if (setting) {
                  field[key] = setting.value;
                }
              }
            }
          });
        });
        return resp;
      })
    );
  }
  getLookupAdon(id: number): Observable<HttpResponse<LookupAdonsModel>> {
    return this.http.get<any>('/WebApi/lookup/' + id, { observe: 'response' }).pipe(
      map(resp => {
        resp.body.form.sections.forEach(sec => {
          sec.fields.forEach(field => {
            for (const key in new FieldSettings()) {
              if (field.settings) {
                let setting = field.settings.find(s => s.key.toLowerCase() == key.toLowerCase());
                if (setting) {
                  field[key] = setting.value;
                }
              }
            }
          });
        });
        return resp;
      })
    );
  }

  getDefinitionFieldsForRollup(definitionId: number): Observable<CustomFieldModel[]>{
    return this.getDefinitionDetails(definitionId).pipe(
    map(resp => {

      let _definition = resp.body;
      let lookupAddonFieldsNumberAndCurrency: CustomFieldModel[] = [];
      //this.lookupAddonFilterFields = [];
      _definition.form.sections.forEach(section => {
          section.fields.forEach(field => {
            if (section.defaultName) {
              field.label = `${this._translateToLocalePipe.transform(section.defaultName)} - ${this._translateToLocalePipe.transform(field.label)}`;
            }
          });
          lookupAddonFieldsNumberAndCurrency = [...lookupAddonFieldsNumberAndCurrency, ...section.fields.filter(field => field.type == FeildType.Number || field.type == FeildType.Money || field.type == FeildType.Calculated)]
          //this.lookupAddonFilterFields = [...this.lookupAddonFilterFields, ...this.commonHelperService.getFieldsForLookupFilter(section.fields)];
      });
      _definition.stages.forEach(stage => {
        stage.criteriaList.forEach(criteria => {
          criteria.form.sections.forEach(section => {
              section.fields.forEach(field => {
                field.label = `${this._translateToLocalePipe.transform(stage.name)} - ${this._translateToLocalePipe.transform(criteria.name)} - ${this._translateToLocalePipe.transform(field.label)}`;
              });
              lookupAddonFieldsNumberAndCurrency = [...lookupAddonFieldsNumberAndCurrency, ...section.fields.filter(field => field.type == FeildType.Number || field.type == FeildType.Money || field.type == FeildType.Calculated)]
          });
        });
      });
      return lookupAddonFieldsNumberAndCurrency;
    })
    );
  }
  getLookupFieldsForRollup(definitionId: number): Observable<CustomFieldModel[]>{
    return this.getLookupAdon(definitionId).pipe(
    map(resp => {

      let _definition = resp.body;
      let lookupAddonFieldsNumberAndCurrency: CustomFieldModel[] = [];
      _definition.form.sections.forEach(section => {
          section.fields.forEach(field => {
            if (section.defaultName) {
              field.label = `${this._translateToLocalePipe.transform(section.defaultName)} - ${this._translateToLocalePipe.transform(field.label)}`;
            }
          });
          lookupAddonFieldsNumberAndCurrency = [...lookupAddonFieldsNumberAndCurrency, ...section.fields.filter(field => field.type == FeildType.Number || field.type == FeildType.Money || field.type == FeildType.Calculated)]
      });
      return lookupAddonFieldsNumberAndCurrency;
    })
    );
  }

  activateDefinition(definitionId: number): Observable<HttpResponse<any>> {
    return this.http.put<any>('/webapi/definition/' + definitionId + '/activate/', { observe: 'response' });
  }

  deactivateDefinition(definitionId: number): Observable<HttpResponse<any>> {
    return this.http.put<any>('/webapi/definition/' + definitionId + '/deactivate/', { observe: 'response' });
  }

  addStage(stage: DefinitionStageModel): Observable<HttpResponse<any>> {
    return this.http.post<any>('/webapi/definition/stage', stage);
  }

  getCriteriaOwners(definitionId: number, stageId, includeCurrentStageOwners = false ): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/definition/criteria/owners/' + definitionId + '/' + stageId + `?includeCurrentStage=${includeCurrentStageOwners ? "1" : "0"}`, { observe: 'response' });
  }

  getControllers(): Observable<CriteriaOwner[]> {
    return this.http.get<any>('/webapi/definition/controllers');
  }

  getDefinitionSelectedControllers(definitionId: number): Observable<DefinitionController> {
    return this.http.get<any>(`/webapi/definition/definitionControllers/${definitionId}`);
  }

  getDefinitionActions(definitionId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/definition/actions/' + definitionId, { observe: 'response' });
  }

  disableDefinitionAction(definitionId: number, isEnabled: boolean): Observable<HttpResponse<any>> {
    return this.http.put<any>('/webapi/definition/action/status/' + definitionId + '/' + isEnabled, {});
  }

  saveDefinition(definition: ApplicationDefinition): Observable<any> {
    return this.http.put<any>('/webapi/definition', definition, { headers: { 'locale': this._translateService.currentLang } })
  }

  //obsolete
  saveSubmissionRequest(definition: ApplicationDefinition, subDefinition: ApplicationDefinition): Observable<any> {
    return this.http.put<any>('/webapi/definition/submissionRequest', { definition: definition, subDefinition: subDefinition });
  }

  getApplicationCustomFeilds(id, parentApplicationId: number = 0, fieldValues: FieldValue[] = [], onBehalfApplicantId: number = 0, forMapping: boolean = false): Observable<any> {
    let payLoad = {
      id: +id,
      fieldValues: fieldValues,
      parentApplicationId: parentApplicationId,
      onBehalf: onBehalfApplicantId,
      forMapping: forMapping
    }
    return this.http.post<any>('/webapi/definition/forSubmission', payLoad, { headers: this.headers });
    // if (parentApplicationId > 0) {
    //   return this.http.post<any>(`/webapi/definition/getWithValues/${id}/${parentApplicationId}`, fieldValues);
    // } else {
    //   return this.http.post<any>('/webapi/definition/getWithValues/' + id, fieldValues);
    // }
  }

  getSearchableForm(id: number): Observable<any> {
    return this.http.get<any>('/webapi/definition/searchableForm/' + id);
  }

  getApplicationDefinitionByKey(key: string): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/definition/key/' + key, { observe: 'response' }).pipe(
      map(resp => resp.body)
    );
  }

  saveAsDraftApplication(definition: ApplicationDefinition): Observable<any> {
    return this.http.post<any>('/webapi/application/createv2', definition);
  }
  submitApplication(definition: ApplicationDefinition, onBehalfApplicantId: number, childApplicantsArray: FormArray = null, callFrom: ComponentName = null, isDraft: boolean = false, notifySubmitter = false, assessmentId: number = 0, revise: boolean = false): Observable<any> {
    let endPoint = '';
    if (callFrom == ComponentName.ApplicationDetailComponent) {
      endPoint = '/webapi/application/resubmitForm';
    } else if(revise) {
      endPoint = '/webapi/application/resubmitForm?revise=yes';
    } else {
      endPoint = '/webapi/application/submitv3';
    }

    let model = {
      application: definition,
      childApplicants: this.mapSubmitterFormArrayToModel(childApplicantsArray),
      onBehalfOf: onBehalfApplicantId,
      isSubmit: isDraft,
      notifySubmitter: notifySubmitter,
      refAssessmentId: assessmentId
    }
    return this.http.post<any>(endPoint, model);
  }

  private mapSubmitterFormArrayToModel(childApplicantsArray: FormArray): ChildSubmitterInfoModel[] {
    let childSubmitterInfoModel: ChildSubmitterInfoModel[] = null;
    if (childApplicantsArray != null && childApplicantsArray.length > 0) {
      childSubmitterInfoModel = [];
      for (let fg of childApplicantsArray.controls) {
        let childSubmitterInfo = new ChildSubmitterInfoModel();
        childSubmitterInfo.definitionId = fg.get('definitionId').value;
        let applicants: number[] = [];
        // applicants = fg.get('applicants').value;
        // let applicantIdNames: ApplicantIdName[] = [];
        // if (!applicants.includes(-1)) {
        //   applicants.forEach(id => {
        //     applicantIdNames.push({
        //       id: id,
        //       name: ''
        //     });
        //   });
        // }
        childSubmitterInfo.isAssign = fg.get('isAssign').value;
        if (childSubmitterInfo.isAssign) {
          childSubmitterInfo.applicants = [{ id: fg.get('applicants').value, name: '' }];
        } else {
          childSubmitterInfo.applicants = fg.get('applicants').value;
        }
        childSubmitterInfo.subUserGroupLists = fg.get('subUserGroupLists').value;
        childSubmitterInfoModel.push(childSubmitterInfo);
      }
    }
    return childSubmitterInfoModel;
  }

  getCommonFields(): Observable<HttpResponse<CustomFieldModel[]>> {
    return this.http.get<CustomFieldModel[]>('/webapi/definition/commonfields', { observe: 'response' });
  }

  addApplicationStagesCriteria(data: any, applicationId: number): Observable<any> {
    return this.http.post<any>('/webapi/application/criteria?applicationId=' + applicationId, data);
  }

  getIconsInfo(): Observable<HttpResponse<IDefinitionIconsInfo>> {
    return this.http.get<IDefinitionIconsInfo>('../../../assets/images/definition/definition-icons-info.json', { observe: 'response' });
  }

  sendEmails(data: any): Observable<any> {
    return this.http.post<any>('/webapi/definition/InviteApplicants', data);
  }

  revokeKey(definitionId: number): Observable<any> {
    return this.http.put<any>('/webapi/definition/revokeKey/' + definitionId, { observe: 'response' });
  }

  generateKey(definitionId: number): Observable<any> {
    return this.http.put<any>('/webapi/definition/generateKey/' + definitionId, { observe: 'response' });
  }

  //getInsertFieldsForAction(stageId: number, definitionId: number, isIncludeCurrentStage: boolean): Observable<HttpResponse<any>> {
  //  return this.http.get<any>('/webapi/definition/actionFields/' + stageId + '/' + definitionId + '/' + isIncludeCurrentStage, { observe: 'response' });
  //}

  getFieldsForProfile(profileId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/fields/availableFields/profileTemplate/' + profileId, { observe: 'response' });
  }

  getFieldsForLookup(lookupId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/fields/availableFields/lookup/' + lookupId, { observe: 'response' });
  }
  getFileInfo(fileId: string): Observable<any> {
    return this.http.get<any>('/webapi/definition/fileinfo/' + fileId, { observe: 'response' });
  }

  orderDefinitions(definitionIds: number[]): Observable<any> {
    return this.http.post<any>('/webapi/definition/reorder', definitionIds);
  }

  getDelegationsOfDefintion(id: number): Observable<HttpResponse<DelegateUser[]>> {
    return this.http.get<any>('/webapi/delegate/definition/' + id, { observe: 'response' });
  }

  deleteDefinition(id: number): Observable<any> {
    return this.http.delete<any>('/webapi/definition/delete/' + id);
  }

  setStartDate(definitionId: number, date?: Date) {
    return this.http.put<any>('/webapi/definition/startDate', { definitionId: definitionId, date: this.formatDateOnly(date) });
  }
  setEndDate(definitionId: number, date?: Date) {

    return this.http.put<any>('/webapi/definition/endDate', { definitionId: definitionId, date: this.formatDateOnly(date) });
  }
  formatDateOnly(date?: Date) {
    if (date) {
      const formatedDate = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
      return formatedDate
    } else {
      return null;
    }
  }

  revertUnpublishedChanges(definitionId: number) {
    return this.http.post<any>('/webapi/definition/revert/' + definitionId, {});
  }

  getDefinitionControllers() {
    return this.http.get<any>('/webapi/definition/controllers');
  }
  getProfileSectionFields(childDefinitionId: number) {
    return this.http.get<any>('/webapi/ApplicantCustomization/sectionsByDefinition/' + childDefinitionId);
  }

  getLinkedDefinitions(definitionId: number): Observable<LinkedDefinitionInfoModel[]> {
    return this.http.get<LinkedDefinitionInfoModel[]>(`/webapi/definition/referredDefinitions/${definitionId}`);
  }

  setLinkedDefinitionOrder(definitionId: number, refDefs:any[]): Observable<any>{
    let postModel = []; 
    refDefs.forEach((def, index) => {
      postModel.push({
        referenceId: def.definitionId,
        drillDownPath: def.dirllInfo,
        referenceType: 'Definition',
        order: index + 1,
        viewMode: def.viewMode,
      })
    });
    return this.http.put(`/webapi/definition/setRefrencesOder/${definitionId}`, postModel);
  }

  getDefinitionExtendedSettings(definitionId: number): Observable<HttpResponse<DefinitionExtendedSettings>> {
    return this.http.get<DefinitionExtendedSettings>('/webapi/definition/extendedSettings/' + definitionId, { observe: 'response' })
  }

  getRecordControllers(definitionId: number): Observable<CriteriaOwner[]>{
    return this.http.get<CriteriaOwner[]>(`/webapi/definition/OwningList/${definitionId}`);
  }

  getsecurityUserList(definitionId: number): Observable<CriteriaOwner[]>{
    return this.http.get<CriteriaOwner[]>(`/webapi/definition/securityUserList/${definitionId}`);
  }
  
  getSetMultiClocking(data): Observable<HttpResponse<boolean>> {
    return this.http.get<boolean>('/webapi/attendanceSettings/SetMultiClocking?data=' + data, { observe: 'response' })
  }
  getIPRestrictionApplied(data): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/attendanceSettings/IPRestrictionApplied?data=' + data, { observe: 'response' })
  }
  getSetClockInCutOffTime(data): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/attendanceSettings/SetClockInCutOffTime?data=' + data, { observe: 'response' })
  }

  getSetLateArrivalCutOffTime(data): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/attendanceSettings/SetLateArrivalCutOffTime?data=' + data, { observe: 'response' })
  }
  getSetWorkingDay(data): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/attendanceSettings/SetWorkingDays?data=' + data, { observe: 'response' })
  }
  getSetAllowedIps(data): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/attendanceSettings/SetAllowedIps?data=' + data, { observe: 'response' })
  }
  pdfLetterPreview(name, data) {
    return this.http.post('/webapi/application/pdf/letter/preview', data, { observe: 'response', responseType: 'blob' }).pipe(map(
      data => {
        var file = new Blob([data.body], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL); // if you want to open it in new tab
        var a         = document.createElement('a');
        a.href        = fileURL;
        a.target      = '_blank';
        a.download    = name + '.pdf';
        document.body.appendChild(a);
        a.click();
        return data;
      }
    ));
  }
  getAvailablesections(definitionId: number, stageId: number): Observable<any> {
    return this.http.get<any>(`/webapi/fields/availablesections?defintionId=${definitionId}&stageId=${stageId}`, { headers: this.headers, observe: 'response' });
  }
  canDeleteSelectedSection(sectionId: number): Observable<any> {
    return this.http.get<any>(`/webapi/definition/section/canDelete/${sectionId}`, { headers: this.headers, observe: 'response' });
  }
  getSelectedSection(sectionId: number): Observable<any> {
    return this.http.get<any>(`/webapi/definition/section/${sectionId}`, { observe: 'response' });

  }
  getapprovalIsEmailField(fieldPath: string): Observable<any> {
    return this.http.get<any>(`webapi/definition/approvalIsEmailField?fieldPath=${fieldPath}`, { headers: this.headers, observe: 'response' });

  }
  bulkEditApiApplication(bulkRequestsModel: BulkRequestsModel) {
    return this.http.post<any>('/webapi/bulkeditapiapplication' , bulkRequestsModel, {});
  }
  getImportHistory(definitionId: number, isEdit: boolean = true): Observable<any> {

    return this.http.get<any>(`/webapi/import/history/${definitionId}?isEdit=${isEdit}`, { headers: this.headers, observe: 'response' });
  }
  isRevisedactive(applicantionId: number): Observable<any>{
    return this.http.get<any>('/webapi/application/isrevisedactive/' + applicantionId, { headers: this.headers, observe: 'response' });
  }
}

export interface IDefinitionIconsInfo {
  informationTech: string[];
}
