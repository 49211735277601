import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-generic-empty-list',
  templateUrl: './generic-empty-list.component.html',
  styleUrls: ['./generic-empty-list.component.scss'],
  standalone: true,
  imports: [NgClass, FlexLayoutModule, TranslateModule]
})
export class GenericEmptyListComponent implements OnInit {
  @Input() noBoxShadow = false; 
  constructor() { }

  ngOnInit(): void {
  }

}
