import { Injectable } from "@angular/core";
import { AdminApplicationStatus } from "../../../common/enums/application-status-enum";

@Injectable({
    providedIn: 'root'
})
export class ReferenceApplicationsFilterStateService{
    filter = new Map<string, ReferenceApplicationsFilter>();
    tabFilter = new Map<string, ReferenceApplicationsTabFilter>(); 
    constructor(){
        
    }

    resetFilter(applicationId: string){
        if(this.filter.get(applicationId)){
            this.filter.delete(applicationId);
        }
    }

    clearFilters(){
        this.filter.clear();
    }

    setFilter(page: number, search: string, status: AdminApplicationStatus, applicationId: string){
        this.filter.set(applicationId, {
            page : page, 
            search : search,
            status : status
        });
    }

    resetTabFilter(applicationId: string){
        if(this.tabFilter.get(applicationId)){
            this.tabFilter.delete(applicationId);
        }
    }

    clearTabFilters(){
        this.tabFilter.clear(); 
    }

    setTabFilter(page: number, search: string, status: AdminApplicationStatus, definitionId: number, applicationId: string){
        this.tabFilter.set(applicationId, {
            page : page, 
            search : search,
            status : status,
            definitionId: definitionId
        });
    }
}

export interface ReferenceApplicationsFilter{
    page: number;
    search: string;
    status: AdminApplicationStatus;
    definitionId?: number; 
}

export interface ReferenceApplicationsTabFilter extends ReferenceApplicationsFilter {
    definitionId?: number; 
}