import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {Location} from '@angular/common';

@Component({
  selector: 'xcdrs-only-admin-can-submit-dialog',
  templateUrl: './only-admin-can-submit-dialog.component.html',
  styleUrls: ['./only-admin-can-submit-dialog.component.scss']
})
export class OnlyAdminCanSubmitDialogComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<OnlyAdminCanSubmitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _location: Location
  ) { }

  ngOnInit(): void {
  }

  back(){
    this._location.back();
  }

}
