import { Injectable } from '@angular/core';
import { LocaleMapper } from './locale-mapper';
import { DataSharingService } from '../../common/services/data-sharing.service';

@Injectable()
export class LocaleMapperService {

  constructor(private dataSharingService: DataSharingService) { }

  public createLocaleMapperItem(dataModel:any, localeMapAbleKeys:string[]) {
    return new LocaleMapper(dataModel, localeMapAbleKeys, this.dataSharingService);
  }
}
