export class InvitationLinkInformation {
  constructor() {
    this.categoryId = 0;
    this.createdBy = 0;
    this.definitionDescription = "";
    this.definitionId = 0;
    this.definitionKey = "";
    this.definitionImage = "";
    this.definitionName = "";
    this.organizationId = 0;
    this.organizationImage = "";
    this.organizationName = "";
    this.invitationKey = "";
    this.inivitionText = "";
  }
  public categoryId: number;
  public createdBy: number;
  public definitionDescription: string;
  public definitionId: number;
  public definitionKey: string;
  public definitionImage: string;
  public definitionName: string;
  public organizationId: number;
  public organizationImage: string;
  public organizationName: string;
  public invitationKey: string;
  public inivitionText: string;
}
