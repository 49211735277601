import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { SystemSettingsService } from "../services/system-settings-service";

@Injectable()
export class WinAuthCanActivateGuard implements CanActivate {

    constructor(private _systemSettingService?: SystemSettingsService) { }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return !(this._systemSettingService.systemSettings.windowsAuth && this._systemSettingService.systemSettings.windowsAuth.toLowerCase() == 'true');
    }
}