import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ApplicationListModel } from '../models/application-list-model';
import { ApplicationStatusPost } from '../../common/enums/application-status-enum';
import { ApplicantDetailsHelperService } from '../../common/services/applicant-details-helper.service';
import { CustomizationSettingsSubmitModel, ApplicantModelWithTemplate } from '../../models/CustomizationSettingsSubmitModel';
import { ApplicantDTO } from '../../models/ApplicantDTO';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { EmailVerificationRequest, EmailVerifyObj } from '../models/email-verification';

@Injectable({
  providedIn: 'root'
})
export class ApplicantRequestService {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private _applicantHelper: ApplicantDetailsHelperService
    , private commonHelperService: CommonHelperService) {
    this.headers = this.commonHelperService.getCurrentLocaleHeader();
  }

  getApplicationsWithPaging(pageNo: number, searchText: string, applicationStatus: ApplicationStatusPost, recordPerPage: number, categoryId?: number): Observable<any> {
    var catParam = "";
    if (categoryId)
      catParam = "&categoryId=" + categoryId;
    return this.http.get<Observable<any>>('/webapi/application/all?search=' + searchText + '&status=' + applicationStatus + '&PageNo=' + pageNo + '&RecordPerPage=' + recordPerPage + catParam, { observe: 'response' });
  }

  getApplicantDetails(): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/applicant/', { headers: this.headers, observe: 'response' });
  }

  postApplicantDetails(submitModel: CustomizationSettingsSubmitModel, applicantSavedModel: ApplicantDTO): Observable<HttpResponse<any>> {
    return this.http.put<any>('/webapi/applicant/', this._applicantHelper.mapToApplicantDTOForProfileWizard(submitModel, applicantSavedModel));
  }

  saveApplicantProfileType(profileTemplates: number[]): Observable<HttpResponse<any>> {
    return this.http.put<any>('/webapi/applicant/profiletemplate', profileTemplates);
  }

  postApplicantProfilePic(data: any): Observable<any> {
    return this.http.post<any>('/webapi/applicant/picture', data);
  }

  getApplicationDetails(applicationId, isResubmit: boolean = false): Observable<any> {
    return this.http.get<Observable<any>>(`/webapi/application/detailsv2/${applicationId}/${isResubmit}`, { observe: 'response', headers: this.headers });
  }

  deleteApplication(applicationId) {
    return this.http.delete<Observable<any>>('/webapi/application/' + applicationId, { observe: 'response' });
  }

  checkProfileStatus(applicantId: number = 0): Observable<any> {
    return this.http.get<Observable<any>>('/webapi/applicant/profileStatus/' + applicantId, { observe: 'response' });
  }

  getLinkedDefinitionsForApplicant(id: number, parentId: number): Observable<any> {
    return this.http.get<Observable<any>>(`/webapi/submittalRequest/${id}/${parentId}`, { headers: this.headers, observe: 'response' });
  }

  getDynamicValues(submitModel: CustomizationSettingsSubmitModel, applicantSavedModel: ApplicantDTO, sectionId: number, sectionIndex: number, definitionKey: string): Observable<any> {
    const applicantDTO: ApplicantDTO = this._applicantHelper.mapToApplicantDTOForProfileWizard(submitModel, applicantSavedModel);
    const applicantModel: ApplicantModelWithTemplate = { applicant: applicantDTO, sections: submitModel.sections };
    return this.http.post<any>(`/webapi/applicant/getRealTimeValuesDetailed/${sectionId}/${sectionIndex}/${definitionKey}`, applicantModel);
  }

  chilDefWithApplicants(definitionId: number): Observable<any> {

    return this.http.get<any>(`/webapi/application/chilDefWithApplicants/${definitionId}`, { observe: 'response' });
  }

  submissionChilDefWithSubmitters(definitionId: number, applicationId: number) {
    return this.http.get<any>(`/webapi/submittalRequest/chilDefWithApplicants/${definitionId}/${applicationId}`, { observe: 'response' });
  }


  getAvailableSubmitters(childDefinitionId: number, applicationId: number){
    return this.http.get<any>(`/webapi/submittalRequest/availableSubmitters?childDefinitionId=${childDefinitionId}&applicationId=${applicationId}`, { observe: 'response' })
  }

  sendVerificationCode(emailVerificationRequest:EmailVerificationRequest)
  {
    return this.http.post<any>(`/webapi/contactVerification`, emailVerificationRequest);
  }
  resendVerificationCode(emailVerificationRequest:EmailVerificationRequest)
  {
    return this.http.post<any>(`/webapi/contactVerification/resend`, emailVerificationRequest);
  }
  verifyEmailCode(emailVerifyObjArr:EmailVerifyObj)
  {
    return this.http.post<any>(`/webapi/contactVerification/verify`, emailVerifyObjArr);
  }
  getApplicationUnlockedField(applicationId): Observable<any> {
    return this.http.get<Observable<any>>(`/webapi/application/unlockableFields/${applicationId}`, { observe: 'response', headers: this.headers });
  }
}
