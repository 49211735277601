import { FormSectionValue } from '../../models/custom-field-value-model';
import { DefinitionType } from '../../definitions/models/application-definition';
import { ApplicationSummary } from '../../models/applicationdetailmodel';

export class ApplicationViewModel {
  constructor() {
  }
  id: number;
  category: string;
  definitionName: string;
  applicationNumber: string;
  status: string;
  submittedOn: string;
  definitionVersion: number;
  statusId: number;
  definitionStartDate: Date;
  definitionEndDate: Date;
  definitionIsActive: boolean;
  isMySubmitted: boolean;
}

export class AdminApplicationViewModel extends ApplicationViewModel {
  applicationId: number;
  applicantName: string;
  applicantId?: number;
  assessedOn: Date;
  createdOn: Date;
  startedOn: Date;
  applicationClass: string;
  iconName: string;
  stageStatuses: string[] = [];
  pendingFor: string;
  isSigned: boolean;
  formSectionValues: FormSectionValue[];
  isExpanded: boolean;
  pendingWith: string[];
  applicationCompletedOn?: Date;
  definitionType: DefinitionType;
  summary: ApplicationSummary;
  applicationCreatedBy?: string;
  public isRecord: boolean;
  lastModifiedOn?: Date;
  listImageUrl?: string;
  callInProgress?: boolean;
  currentState?: string; 
  isSelected?: boolean; 
}

