import { Injectable } from '@angular/core';
import { ActionFieldModel } from '../../models/action-field-model';
import { FeildType } from '../enums/field-type-enum';
import { CustomFieldModel, Condition } from '../../profile-customization/Model/CustomFieldModel';
import { LookupAdonsService } from '../../settings/lookup-ad-ons/lookup-ad-ons.service';
import * as _ from 'lodash';
import { ManageCurrenciesService } from '../../settings/manage-currencies/manage-currencies.service';
import { CustomFieldService } from './custom-field.service';
import { CustomFieldValueModel } from '../../models/custom-field-value-model';
import { ActionFieldsDialogService } from '../../form-builder/services/action-fields-diaolog.service';

@Injectable()
export class FiltersService {
  constructor(
    private _lookupAdonsService: LookupAdonsService,
    private _currencyService: ManageCurrenciesService,
    private _actionFieldsDialogService: ActionFieldsDialogService
  ) {

  }

  selectLookupFieldForFilter(event, index, customFieldModel: CustomFieldModel, actionFeilds: ActionFieldModel[], condition: Condition, forActionFieldLookupType: boolean = false) {
    // customFieldModel.rollup.conditions[index].value = '';
    let filterCustomField: ActionFieldModel;
    filterCustomField = this._actionFieldsDialogService.getSelectedField(event.value, actionFeilds);
    if (filterCustomField) {
      //filterCustomField = actionFeilds.filter(f => f.key == event.value)[0];
      condition.fieldType = filterCustomField.type;
      // var items = this.actionFeilds.find(x => x.key == event.value);
      // console.log('Event', event);
      // console.log('customFieldModel', customFieldModel);

      // console.log('condition.fieldType', condition.fieldType);
      // console.log('filterCustomField', filterCustomField);
      
      if (filterCustomField.type == FeildType.SingleSelection || filterCustomField.type == FeildType.MultiSelection) {
        condition.singleMultiLookupValues = filterCustomField.fieldLookups;
      } else if (filterCustomField && filterCustomField.type == FeildType.Status){
        this._lookupAdonsService.getFieldsForStatus(customFieldModel?.id).subscribe(resp => {
          condition.statusOptions = resp.body;
          // console.log('Status List', condition.statusOptions);
        });
      } else if (filterCustomField && filterCustomField.type == FeildType.LookupAddon) {
        this._lookupAdonsService.getLookupItemOptions(filterCustomField.fieldId, 0, null, 0, forActionFieldLookupType ? filterCustomField.lookupType : customFieldModel?.lookupType).subscribe(resp => {
          condition.lookUpOptions = resp;
          //let selectedLookups = this.lookUpOptions.filter(x => x.id == this.customField.value);
          //if (selectedLookups && selectedLookups.length > 0) {
          //    this.customField.actionValue = selectedLookups[0].name;
          //}
        }, error => { });
      } else if (filterCustomField.type == FeildType.Money) {
        this.initializeCurrencyControl(condition);
      }
    }
  }

  public initializeCurrencyControl(condition: Condition) {
    if (this._currencyService.currencies.length == 0) {
      this._currencyService.getCurrencies().subscribe(resp => {
        condition.currencies = resp.body;
        this.setSelectedCurrency(condition);
      });
    } else {
      condition.currencies = this._currencyService.currencies;
      this.setSelectedCurrency(condition);
    }
  }

  private setSelectedCurrency(condtion: Condition) {
    if (condtion.value) {
      let customField: CustomFieldModel = new CustomFieldModel();
      customField.type = FeildType.Money;
      customField.value = condtion.value;
      let objectValue: CustomFieldValueModel = new CustomFieldValueModel();
      objectValue.value = condtion.value;
      CustomFieldService.mapCurrencyWithThreeValues(customField);
      condtion.value = customField.value;
      if (condtion.formGroup) {
        condtion.formGroup.get('value').setValue(condtion.value, { emitEvent: false })
      }
      const selectedCurrency = condtion.currencies.find(x => x.id == customField.selectedCurrencyId);
      if (selectedCurrency) {
        condtion.selectedCurrency = selectedCurrency;
      }
    } else {
      if (condtion.currencies.length > 0) {
        condtion.selectedCurrency = condtion.currencies[0]
      }
    }
  }

  getFieldType(fieldPath: string, actionFeilds: ActionFieldModel[]): FeildType {
    const selectionActionField = this._actionFieldsDialogService.getSelectedField(fieldPath, actionFeilds);
    if(selectionActionField){
      return selectionActionField.type; 
    }
    // if (fieldPath && actionFeilds.filter(field => field.key == fieldPath)[0]) {
    //   var customField: ActionFieldModel = actionFeilds.filter(field => field.key == fieldPath)[0];
    //   //if (customField.type == FeildType.LookupAddon && this._lookupFields.filter(f => f.lookUpId == customField.lookUpId).length > 0) {
    //   //  return FeildType.SameLookupRollup;
    //   //} else {
    //   return actionFeilds.filter(field => field.key == fieldPath)[0].type;
    //   //}
    // }
  }


  public getActionNameByKey(key: string, actionFeilds: ActionFieldModel[]): string {
    let name: string = '';
    const action = this._actionFieldsDialogService.getSelectedField(key, actionFeilds) //actionFeilds.find(x => x.key == key);
    if (action) {
      name = action.name;
    }
    return name;
  }

  public filterActionFieldsForLookup(actionFeilds: ActionFieldModel[]) {
    this.addProfileTemplateOption(actionFeilds);
    return this._actionFieldsDialogService.filterActionFields(actionFeilds, item => item.type == FeildType.Text || item.type == FeildType.Email || item.type == FeildType.PhoneNumber || item.type == FeildType.Calculated || item.type == FeildType.Mapped || item.type == FeildType.MultiLine || item.type == FeildType.Number || item.type == FeildType.LookupAddon || item.type == 100 || item.type == FeildType.Status || item.type == FeildType.Money || item.type == FeildType.SingleSelection || item.type == FeildType.MultiSelection)
    //return actionFeilds.filter(item => item.innerFields?.length > 0 || item.type == FeildType.Text || item.type == FeildType.Email || item.type == FeildType.PhoneNumber || item.type == FeildType.Calculated || item.type == FeildType.Mapped || item.type == FeildType.MultiLine || item.type == FeildType.Number || item.type == FeildType.LookupAddon || item.type == 100 || item.type == FeildType.Status || item.type == FeildType.Money || item.type == FeildType.SingleSelection || item.type == FeildType.MultiSelection);
  }
  public triggerFilterActionFieldsForLookup(actionFeilds: ActionFieldModel[]) {
    this.addProfileTemplateOption(actionFeilds);
    this.addCurrentDateOption(actionFeilds);
    this.addCurrentDateTimeOption(actionFeilds);
    this.addStageStartDateOption(actionFeilds);
    return this._actionFieldsDialogService.filterActionFields(actionFeilds, item => item.type == FeildType.Text || item.type == FeildType.MultiLine || item.type == FeildType.Number || item.type == FeildType.LookupAddon || item.type == 100 || item.type == FeildType.Status || item.type == FeildType.Money || item.type == FeildType.SingleSelection || item.type == FeildType.MultiSelection || item.type == FeildType.Date || item.type == FeildType.DateTime || item.type == FeildType.Rating);
    //return actionFeilds.filter(item =>  item.innerFields?.length > 0 || item.type == FeildType.Text || item.type == FeildType.MultiLine || item.type == FeildType.Number || item.type == FeildType.LookupAddon || item.type == 100 || item.type == FeildType.Status || item.type == FeildType.Money || item.type == FeildType.SingleSelection || item.type == FeildType.MultiSelection || item.type == FeildType.Date || item.type == FeildType.DateTime || item.type == FeildType.Rating);
  }
  public reportFilterActionFieldsForLookup(actionFeilds: ActionFieldModel[]) {
    this.addProfileTemplateOption(actionFeilds);
    return this._actionFieldsDialogService.filterActionFields(actionFeilds, item => item.type == FeildType.Date || item.type == FeildType.Text || item.type == FeildType.MultiLine || item.type == FeildType.Number || item.type == FeildType.LookupAddon || item.type == 100 || item.type == FeildType.Status || item.type == FeildType.SingleSelection || item.type == FeildType.MultiSelection);
    //return actionFeilds.filter(item =>  item.innerFields?.length > 0 || item.type == FeildType.Date || item.type == FeildType.Text || item.type == FeildType.MultiLine || item.type == FeildType.Number || item.type == FeildType.LookupAddon || item.type == 100 || item.type == FeildType.Status || item.type == FeildType.SingleSelection || item.type == FeildType.MultiSelection);
  }

  private addProfileTemplateOption(actionFields: ActionFieldModel[]) {
    const flatActionFieds = this._actionFieldsDialogService.flattenActionFieldArray(actionFields);
    if (flatActionFieds.filter(f => f.type == 100).length > 0) {
      let field: ActionFieldModel = {
        type: 100,
        name: "Profile",
        key: "OPT",
        fieldId: 0,
        allowedValuesCriteria: null,
        fieldLookups: null,
        isSelected: false,
        isMultipleAllowed: false,
        sectionOrder: 0,
        fieldOrder: 0,
      };
      //_.remove(actionFields, f => f.type == 100);
      actionFields = this._actionFieldsDialogService.removeElementsByType(actionFields, 100);
      actionFields.push(field);
    }
  }
  private addCurrentDateOption(actionFields: ActionFieldModel[]) {
      let field: ActionFieldModel = {
        type: 4,
        name: "Date",
        description: "Current Date",
        section: "Current Date",
        key: "{**CURRENTDATE**}",
        fieldId: 0,
        allowedValuesCriteria: null,
        fieldLookups: null,
        isSelected: false,
        isMultipleAllowed: false,
        sectionOrder: 0,
        fieldOrder: 0,
      };
      actionFields.push(field);
    }
    private addStageStartDateOption(actionFields: ActionFieldModel[]) {
      let field: ActionFieldModel = {
        type: 4,
        name: "Date",
        description: "Stage Start Date",
        section: "Stage Start Date",
        key: "{**STAGESTARTDATE**}",
        fieldId: 0,
        allowedValuesCriteria: null,
        fieldLookups: null,
        isSelected: false,
        isMultipleAllowed: false,
        sectionOrder: 0,
        fieldOrder: 0,
      };
      actionFields.push(field);
    }
    private addCurrentDateTimeOption(actionFields: ActionFieldModel[]) {
      let field: ActionFieldModel = {
        type: 8,
        name: "Date Time",
        description: "Current Date Time",
        section: "Current Date Time",
        key: "{**CURRENTDATETIME**}",
        fieldId: 0,
        allowedValuesCriteria: null,
        fieldLookups: null,
        isSelected: false,
        isMultipleAllowed: false,
        sectionOrder: 0,
        fieldOrder: 0,
      };
      actionFields.push(field);
    }
}
