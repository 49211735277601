import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CanActivateGuard } from '../common/guard/can-activate-guard.service';
import { Roles } from '../common/enums/roles-enum';
// Components

// Routes
const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [CanActivateGuard], data: { title: 'Dashboard', pageTitle: 'Dashboard', expectedRole: Roles.Admin } }
  //,
  //{ path: 'sharedfields', loadChildren: 'app/settings/common-fields/common-fields.module#CommonFieldsModule' },
  //{ path: 'lookups', loadChildren: 'app/settings/lookup-ad-ons/lookup-ad-ons.module#LookupAdonsModule' },
  //{ path: 'status', loadChildren: 'app/settings/application-status/application-status.module#ApplicationStatusModule' }
];
// Module
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomizationRoutingModule { }
