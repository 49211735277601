<div [ngClass]="{'main-content-container': !isTabCall, 'dashboard-container':true}">
  <div *ngIf="!isTabCall">
    <div fxLayout="column" fxLayoutAlign="start start" class="header-container">
      <div fxLayout="row" fxLayoutAlign="start start" class="dashboard-heading" translate="Dashboard.Dashoard">
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" class="" translate="Dashboard.Invoice">
      </div>
    </div>
    <div fxLayout="row" fxFlex fxLayoutAlign="end center" fxLayoutGap="15px" *ngIf="true">
      <button mat-button class="add-button-gradiant" (click)="addInvoice()">
       <span translate="Dasboard.ADD INVOICE"></span>
      </button>
    </div>
  </div>
  <hr *ngIf="!isTabCall" />
  <div fxLayout="column" fxLayoutAlign="start center" class="carousel-container">
    
    <div fxFlexFill  (mouseenter) ="showButtonsOnCarousel(true)"  (mouseleave) ="showButtonsOnCarousel(false)">
      <div class="prev" (click)="prevCarouselItem()"  [@openCloseChevronLeft]="(showCarouselButtons) ? 'open' : 'closed'" *ngIf="dashboardTiles.length > 4 && tilesIndex > 0">
        <mat-icon *ngIf="(dashboardTiles.length > 4 && showCarouselButtons) && tilesIndex > 0">chevron_left</mat-icon>
      </div>
      <div fxFlexFill fxLayoutGap="20px grid" class="grid-container">
        <div [@cardSlide]="animate" fxFlex.xs="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.gt-md="25" fxFlex.lg="25" fxFlex.gt-lg="25" class="card-sizer"
             *ngFor="let dashboardTile of visibleDashboardTiles">
          <div fxFlexFill fxLayoutAlign="center center" style="">
            <mat-card fxFlex="100" class="dashboard-values-card " [ngClass]="
                      {'blue':dashboardTile.statusName == 'Pending',
                      'green':dashboardTile.statusName == 'Accepted',
                      'red':dashboardTile.statusName == 'Rejected',
                      'purple':dashboardTile.statusName != 'Pending' && dashboardTile.statusName != 'Accepted' && dashboardTile.statusName != 'Rejected'}
                      ">
              <div class="cornor-circle">

              </div>
              <div class="value">
                <span *ngIf="!showAmount" ><span *ngIf="dashboardTile.count>0 && dashboardTile.count<10">0</span>{{dashboardTile.count}}</span> <span *ngIf="showAmount">{{getDecimalPointsNubmer(dashboardTile.amount)}}{{dashboardTile.amountSymbole}}<span style="line-height: 10px;font-size: 12px;"> {{dashboardTile.currencySymobl}}</span></span>
              </div>
              <div class="name">
                <span *ngIf="showAmount">{{dashboardTile.count}} </span>{{dashboardTile.statusName | translateToLocale | translate}} {{labelService.labels?.applications | translateToLocale}}
              </div>
              <div class="status-positing" style="position: absolute;top: 15px;right: 15px;">
                <img *ngIf="dashboardTile.statusName != 'Accepted' && dashboardTile.statusName != 'Rejected'" src="../../../assets/images/dashboard/ic_dashboard_invoice_raised.png" />
                <img *ngIf="dashboardTile.statusName == 'Accepted'" src="../../../assets/images/dashboard/ic_dashboard_invoice_approved.png" />
                <img *ngIf="dashboardTile.statusName == 'Rejected'" src="../../../assets/images/dashboard/ic_dashboard_invoice_rejected.png" />
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="next" (click)="nextCarouselItem()" [@openCloseChevronRight]="(showCarouselButtons) ? 'open' : 'closed'" *ngIf="dashboardTiles.length > 4 && tilesIndex < dashboardTiles.length - 4">
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
  </div>
</div>

