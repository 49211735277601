import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderConfigurationService } from '../../form-builder/services/form-builder-configuration.service';
import { ApplicationDefinition } from '../../definitions/models/application-definition';
import { TemplateModelWithProfileList } from '../../profile-customization/Model/SettingModel';
import { ProfileCustomizationService } from '../../profile-customization/profilecustomization.service';

@Component({
  selector: 'app-profile-template-selection',
  templateUrl: './profile-template-selection.component.html',
  styleUrls: ['./profile-template-selection.component.scss']
})
export class ProfileTemplateSelectionComponent implements OnInit {
  private _disabled: boolean = false;
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  @Input() profileFormControl: FormControl;
  @Input() definition: ApplicationDefinition;
  constructor(
    private _profileCustomizationService: ProfileCustomizationService,
    private _fbConfigService: FormBuilderConfigurationService,
  ) { }

  ngOnInit(): void {
    this.getProfileTemplates();
  }
  profileTemplate: TemplateModelWithProfileList;
  profileTemplates: TemplateModelWithProfileList[] = [];
  getProfileTemplates() {
    this._profileCustomizationService.getProfileTemplatesList()
      .subscribe(resp => {
        this.profileTemplates = JSON.parse(JSON.stringify(resp.body));
        this.profileTemplates = this.profileTemplates.filter(temp => temp.isBase == true || temp.isPublished == true || temp.profileListId > 0);
        this.profileFormControl.setValidators([Validators.required])

        const baseProfileTemplate = this.profileTemplates.find(temp => temp.isBase);
        if (this.definition.id) {
          if (this.definition.profileTemplateId === 214) {
            if (baseProfileTemplate) {
              this.profileFormControl.setValue(baseProfileTemplate.id + '-' + baseProfileTemplate.profileListId)
            }
          } else {
            this.profileFormControl.setValue(this.definition.profileTemplateId + '-' + this.definition.profileListId)
          }
          if (this.definition.profileTemplateId !== 214 && baseProfileTemplate.id !== this.definition.profileTemplateId) {
            this.profileTemplates = this.profileTemplates.filter(pt => pt.id == this.definition.profileTemplateId)
          }
        }




      });
  }
  profileTemplateChanged(profileTemplateAndListId: string = '') {
    let splittedIds = profileTemplateAndListId.split('-');
    let pId = parseInt(splittedIds[0])
    let plId = parseInt(splittedIds[1])
    // const profileTemplate = this.profileTemplates.find(pt => pt.id == pId && pt.profileListId == plId)
    // if (profileTemplate) {
    this._fbConfigService.profileTemplateIds = [pId];
    this.definition.profileTemplateId = pId;
    this.definition.profileListId = plId;

    // }
  }
}
