import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DataSharingService } from '../../../common/services/data-sharing.service';
import { ThemeService } from '../../../common/services/theme.service';

@Component({
  selector: 'app-fill-with-ai-dialog',
  templateUrl: './fill-with-ai-dialog.component.html',
  styleUrls: ['./fill-with-ai-dialog.component.scss']
})
export class FillWithAiDialogComponent {
  title: string;
  ok: string;
  no: string;
  desc: string;
  sectionId: number = 0;
  @ViewChild('fileAttachment') fileAttachment: ElementRef;
  fileDetails: any = null
  formData;


  constructor(
    public themeService: ThemeService, 
    public dataSharingService: DataSharingService,
    public dialogRef: MatDialogRef<FillWithAiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    ) { }

  ngOnInit(): void {
    this.title = this._data.heading;
    this.ok = this._data.ok;
    this.no = this._data.no;
    this.desc = this._data.desc;
    this.sectionId = this._data.sectionId;
  }
  openFileUploader() {
    this.fileAttachment.nativeElement.click();
  }
  public attachmentName: any = '';
  onFileSelected(event: any, sectionId: number) {
    console.log(event);
    
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.attachmentName = input.files[0];
      this.fileDetails = {
        name: input.files[0].name,
        size: input.files[0].size,
        type: input.files[0].type,
      }
      // Create FormData to send the file
      const formDataa = new FormData();
      formDataa.append('file', this.attachmentName);
      this.formData = formDataa;
      console.log(this.formData);
      console.log(formDataa);
      
      // this.fillAIFields(formData, sectionId);
    }
  }
  removeFile() {
    this.fileDetails = null;

  }
}
