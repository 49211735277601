<app-auth-layout>
  <button class="back-btn" mat-button color="primary" (click)="goto('login')">
    <mat-icon>{{dataSharingService.isRtl? 'navigate_next' : 'navigate_before'}}</mat-icon>
    <span [translate]="'Common.Back'"></span>
  </button>
  <div fxFlex *ngIf="!isEmailSent">
    <h1 fxLayoutAlign="center center" [translate]="'ForgotPassword.ForgotPassword'">
    </h1>
    <div fxLayoutAlign="center center" class="description" [innerHTML]="'ForgotPassword.ResetPasswordMsg' |translate">
    </div>

    <form #forgotPassForm="ngForm" (ngSubmit)="OnSubmit(Email.value)">
      <div fxLayout="column">
        <mat-form-field appearance="outline" fxFlex [class.form-field-rtl]="dataSharingService.isRtl">
          <mat-label [translate]="'ForgotPassword.REGISTERED-EMAILID'"></mat-label>
          <input matInput #Email [(ngModel)]="emailModel" [formControl]="email" placeholder="{{'ForgotPassword.REGISTERED-EMAILID' | translate}}" name="email" class="forgot-input" required>
          <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
        </mat-form-field>

        <div *ngIf="isNotRegistered" class="error">
          {{'ForgotPassword.ACCOUNT-NOT-FOUND' | translate}}
        </div>
        <div *ngIf="isError" class="error">
          {{'Common.ERROR' | translate}}
        </div>
      </div>
      <div fxLayout="column" class="login-btns-container">
        <div fxLayout="row" fxFlex style="width: 100%;">
          <button fxFlex mat-flat-button class="gradient-btn auth-btn" color="primary"
            [disabled]="(email.invalid) || isCallBack">
            {{'ForgotPassword.ResetMyPassword' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div fxFlex *ngIf="isEmailSent">
    <h1 fxLayoutAlign="center center" [translate]="'ForgotPassword.RESET-LINK-SENT'">
    </h1>
    <div fxLayoutAlign="center center" class="description" [innerHTML]="'ForgotPassword.CLICK-ON-RESENT-LINK' |translate">
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="img-email-sent">
      <img src="../../../assets/images/{{themeService.imgSrc}}link-sent.png" />
    </div>
  </div>
</app-auth-layout>
