import { Component, OnInit } from '@angular/core';
import { LabelService } from '../../common/services/label-service';
import { ThemeService } from '../../common/services/theme.service';

@Component({
  selector: 'app-expired-link',
  templateUrl: './expired-link.component.html',
  styleUrls: ['./expired-link.component.less']
})
export class ExpiredLinkComponent implements OnInit {

  constructor(public labelService: LabelService, public themeService: ThemeService) { }

  ngOnInit() {
  }

}
