import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm, FormControl, Validators, FormGroup } from '@angular/forms';


import { AccountService } from '../services/account.service';
import { MessageService } from './../../common/services/message-service';
import { LabelService } from '../../common/services/label-service';
import { ThemeService } from '../../common/services/theme.service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { DataSharingService } from '../../common/services/data-sharing.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  isCallBack: boolean = false;
  isError: boolean = false;
  emailModel: string = '';
  isEmailSent: boolean = false;
  isNotRegistered: boolean = false;

  constructor(private accountService: AccountService, private router: Router
    , private msgService: MessageService, public labelService: LabelService,
    public themeService: ThemeService, private _translationHelperService: TranslationHelperService,
    public dataSharingService: DataSharingService) { }

  ngOnInit() {
  }


  email = new FormControl('', [Validators.required, Validators.email]);

  getEmailErrorMessage() {
    return this.email.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-REQUIRED') :
      this.email.hasError('email') ? this._translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-INVALID') :
        '';
  }

  goto(comp) {
    if (comp == 'login') {
      this.router.navigateByUrl('/login');
    }
  }

  OnSubmit() {
    this.isError = false;
    this.isCallBack = false;
    this.isEmailSent = false;
    this.isNotRegistered = false;

    if (this.email.valid) {
      this.isCallBack = true;
      this.accountService.resetPassword(this.emailModel).subscribe((data: any) => {
        this.isCallBack = false;
        this.isEmailSent = true;
        this.msgService.showMessage(this._translationHelperService.getTranslationByKey('Common.EMAIL-SUCCESSFULL-SENT'));
      },
        (err: HttpErrorResponse) => {
          this.isCallBack = false;
          if (err.status == 404) {
            this.msgService.showMessage(this._translationHelperService.getTranslationByKey('ForgotPassword.NO-AACOUNT-ASSOCIATED'));
          }
          else if (err.error && err.error.exceptionMessage) {

            if (err.error.exceptionMessage.indexOf('No account') > -1) {
              this.msgService.showMessage(err.error.exceptionMessages)
              this.isNotRegistered = true;
            } else {
              this.isError = true;
            }
          }


        });
    }
  }
}
