
export enum Reminders {
  TwoDay = "2D",
  OneDay = "1D",
  TwelveHours = "12H",
  TenHours = "10H",
  EightHours = "8H",
  SixHours = "6H",
  FourHours = "4H",
  TwoHours = "2H",
  OneHour = "1H",
}
