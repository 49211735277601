import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'xcdrs-esp-checkbox',
  templateUrl: './esp-checkbox.component.html',
  styleUrls: ['./esp-checkbox.component.scss']
})
export class EspCheckboxComponent implements OnInit {
  @Input() checked = false;
  constructor() { }

  ngOnInit(): void {
  }
}
