import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { CustomFieldModel } from '../../../profile-customization/Model/CustomFieldModel';
import { EmailVerificationRequest, EmailVerifyObj } from '../../models/email-verification';
import { ApplicantRequestService } from '../../services/applicant-request.service';
import { Guid } from 'guid-typescript';
import { FeildType } from '../../../common/enums/field-type-enum';

@Component({
  selector: 'email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

 private applicationRequestService: ApplicantRequestService;

  @Input()
  fieldsToVerify: CustomFieldModel[]=[];

  @Output()
  onAllCodesVerified:EventEmitter<boolean>=new EventEmitter<boolean>();

  @Output()
  onBackClick:EventEmitter<boolean>=new EventEmitter<boolean>();

  emailVerificationRequests:EmailVerificationRequest[]=[];
  curVerificationIndex=0;

  constructor(applicationRequestService:ApplicantRequestService) { 
    this.applicationRequestService = applicationRequestService;
  }

  ngOnInit(): void {

   this.emailVerificationRequests= this.fieldsToVerify.map(x=>{
      let obj:EmailVerificationRequest=new EmailVerificationRequest();
      obj.contact = x.value;
      obj.reference = Guid.create().toJSON().value;
      obj.type = x.type=== FeildType.Email?"Email":"Phone"
      return obj
    });
  }

  onVerificationSucceeded(obj:any)
  {
    this.curVerificationIndex += 1;

    if(this.curVerificationIndex >= this.emailVerificationRequests.length)
    {
      this.onAllCodesVerified.emit(true);
    }
  }
  back()
  {
    this.onBackClick.emit(true);
  }

}
