export class CommonEnums {
  public static StageDefaultStatus = '{"en":"In Progress", "ar":"In Progress", "fr":""}';
  public routeEnum = {
    Definition: 'Definition',
    DefinitionDetails: 'DefinitionDetails'
  };


}
export enum SnackbarType {
  Success = 'SUCCESS',
  Error = 'ERROR'
}

export enum FilterCallFrom {
  Builder = 'Builder',
  Criteria = 'Criteria'
}

export enum ChildDefinitionCallFrom {
  Stage = 'Stage',
  Definition = 'Definition'
}

export const ESP_DIALOG_DEFAULT_CONFIG = {
  width: '564px',
  closeOnNavigation: true,
  disableClose: true,
  panelClass: 'dialog-padding-32',
  autoFocus: false
}

export enum FormBuilderCallFrom {
  Definition = 'Definition',
  Criteria = 'Criteria',
  Lookup = 'Lookup',
  ProfileSection = 'ProfileSection',
}
export enum SubmitterType {
  Applicant = 'Applicant',
  UserSubGroup = 'UserSubGroup',
}