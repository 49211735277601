import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[customRequired][formControlName],[customRequired][formControl],[customRequired][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CustomRequiredDirective,
    multi: true
  }]
})
export class CustomRequiredDirective implements Validator {

  validate(c: FormControl): { [key: string]: any } | null {
    return customRequiredValidator()(c);
  }
}

export function customRequiredValidator(): ValidatorFn {
  return (c: FormControl): { [key: string]: any } | null => {
    let v = c.value;
    if (!v || v.toString().trim() == '') {
      return  { "customRequired": true };
    }
    return null;
  }
}
