<div fxFlex class="btn-close-container">
  <button mat-icon-button mat-dialog-close aria-label="Close dialog" class="btn-close" (click)="back()">
    <mat-icon><img src="assets/images/icons/close.svg" alt="close"></mat-icon>
  </button>
</div>
<!-- <div>
  <img src="../../../../../../assets/images/request/error_icon.svg">
</div>
<div class="title">Error message here...</div> -->
<div class="admin-text" [innerHTML]="data | translateToLocale"></div>
<div fxLayout="row" fxLayoutAlign="center center" class="btn-cancel-container">
  <button mat-stroked-button color="primary" fxFlex="300px" fxFlex.xs="100%" (click)="back()">
    {{"CLOSE"|translate}}
  </button>
</div>
