import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHeaderComponent } from '../app-header/app-header.component';
import {RouterModule} from '@angular/router';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import { PipesModule } from '../shared-modules/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrgAccountsListDialogComponent } from './org-accounts-list-dialog/org-accounts-list-dialog.component';
import { AccountSharedModule } from '../shared-modules/account-shared/account-shared.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SwitchLanguageControlComponent } from './switch-language-control/switch-language-control.component';
import { SwitchLanguageDialogComponent } from './switch-language-dialog/switch-language-dialog.component';
import { SecurePipe } from '../common/pipes/secure.pipe';

@NgModule({
  declarations: [
    AppHeaderComponent,
    OrgAccountsListDialogComponent,
    SwitchLanguageControlComponent,
    SwitchLanguageDialogComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule.forChild([]),
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    PipesModule,
    TranslateModule,
    FlexLayoutModule,
    AccountSharedModule,
    SecurePipe
  ],
  exports: [
    AppHeaderComponent
  ],
  entryComponents: [
    OrgAccountsListDialogComponent
  ]
})
export class AppHeaderModule { }
