import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormBuilderConfigurationService {
  public profileTemplateIds: any[] = [];
  public selectedStageId: number = 0;
  public isChildDefinition: boolean = false;
  constructor() { }
}
