import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormControl, Validators, FormGroup } from '@angular/forms';
import { LabelService, ListModel, LabelModel } from '../../common/services/label-service';

import { AccountService, GrantTypes } from '../services/account.service'
import { Roles } from '../../common/enums/roles-enum';
import { UserInfoService } from '../../common/services/userinfo-service';
import { SystemSettingsService } from '../../common/services/system-settings-service';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../common/services/message-service';
import { SlideUpDown } from '../../common/animations/animations';
import { IdenediAuthService } from '../services/idenedi-auth.service';
import { map } from 'rxjs/operators';
import { IdenediAuthModel } from '../../models/idenedi-auth-model';
import { SystemLanguage } from '../../models/system-language';
import { IdenediGroupService } from '../../organization/services/idenedi-goup.service';
import { I } from '@angular/cdk/keycodes';
import { DefinitionDialogService } from '../../definition/services/defintition-dialogs.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  //encapsulation: ViewEncapsulation.None,
  animations: [SlideUpDown]
})
export class LoginComponent implements OnInit {

  isLoginError: boolean = false;
  loginErrorMsg: string = '';
  isCallBack: boolean = false;

  orgList: any;

  constructor(public labelService: LabelService, private accountService: AccountService, private router: Router, private _userInfoService: UserInfoService
    , private route: ActivatedRoute, public systemSettingsService: SystemSettingsService
    , private _dataSharingService: DataSharingService, private _translationHelperService: TranslationHelperService
    , private translate: TranslateService
    , private messageService: MessageService
    , private idenediAuthService: IdenediAuthService
    , private _idenediGroupService: IdenediGroupService
    , private _definitionDialogService: DefinitionDialogService
    ) {
  }

  private _userType: string = "";
  private _key: string = "";
  private _idenediCode = "";
  private idenediState = "";
  private _invitationType: string = "";
  private _invitationKey: string = "";
  preferredLanguage: SystemLanguage;
  public higherParams;
  public higherCallFrom: string;
  private stemexeOrgId: number;
  private stemexeDefId: number;
  private stemexeDefType: boolean;
  public loader: boolean = false;
  ngOnInit() {
    this.preferredLanguage = JSON.parse(localStorage.getItem('preferredLanguage'));
    // if (!this.systemSettingsService.systemSettings.windowsAuth || this.systemSettingsService.systemSettings.windowsAuth.toLocaleLowerCase() != 'true') {
      this.route.queryParams.subscribe(params => {
        if(params.higherInit) {
          this.higherParams = params.higherInit;
        }
        if(params.callFrom) {
          this.higherCallFrom = params.callFrom;
        }
        this._key = params["key"];
        this._userType = params["type"];
        if (params['email']) {
          this.email.setValue(params["email"]);
        }
        if (this._key != "" && this._userType != "" && params["email"] != "") {
          //this.email.disable();
          //this.email.markAsDirty();
        }

        if (params["code"]) {
          this._idenediCode = params["code"];
          if (params["state"] && params["state"] != 'undefined') {
            this.idenediState = params["state"];
            const idenediStateArray = this.idenediState.split(';');
            if (idenediStateArray[0]) {
              this._invitationKey = idenediStateArray[0];
            }
            if (idenediStateArray[1]) {
              this._invitationType = idenediStateArray[1];
            }
            if (idenediStateArray[2] == "true") {
              this._key = this._invitationKey;
              this._userType = this._invitationType;
              this.authenticateUser(0, true);
            } else {
              if (this._invitationType.toLowerCase() == 'applicant' || this._invitationType.toLowerCase() == 'user') {
                this.createApplicantOrUserWithInvitationKey();
              }
            }
          } else {
            this.authenticateUser(0, true);
          }
        } 
        if (params ["stemexeCode"]) {
          this.loader = true;
          if (params['orgId']) {
            this.stemexeOrgId = parseInt(params["orgId"]);
          }
          if (params['type']) {
            this.stemexeDefType= params["type"];
          }
          if (params['defId']) {
            this.stemexeDefId= params["defId"];
          }
          this._idenediCode = params["stemexeCode"];
          this.authenticateUserbyOrgId(this._idenediCode, this.stemexeOrgId);
        }
      });
      this.route.params.subscribe(params => {
        if (params['email']) {
          this.email.setValue(params["email"]);
        }
      });
      sessionStorage.clear();
      // localStorage.clear();
    // } else {
      // this.authenticateWindowsUser();
    // }
    this.labelService.updateClientLabel();
  }

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);

  getEmailErrorMessage() {
    return this.email.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-REQUIRED') :
      this.email.hasError('email') ? this._translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-INVALID') :
        '';
  }

  getPasswordErrorMessage() {
    return this.password.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.PASSWORD-REQUIRED') :
      this.password.hasError('minlength') ? this._translationHelperService.getTranslationByKey('RequiredMessages.PASSWORD-MINIMUM-CHARACTERS') :
        '';
  }

  goto(comp) {
    if (comp == 'reset') {
      this.router.navigateByUrl('/forgotpassword')
    } else if (comp == 'register') {
      this.router.navigateByUrl('/register')
    }
  }

  OnSubmit() {
    this.isLoginError = false;
    this.isCallBack = false;
    if(this.higherParams) {
        let params = {
          higherInit: this.higherParams,
          higherCallFrom: this.higherCallFrom ?? '',
        }
        sessionStorage.setItem('higherParams', JSON.stringify(params));
    }
    if (this.email.valid && this.password.valid) {
      this.isCallBack = true;
      var personaId: number = 0;
      var redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl');
      if (redirectUrl) {
        if (!redirectUrl.startsWith('/iframe')) {
          redirectUrl = decodeURIComponent(redirectUrl);
        }
        var redirectParams = redirectUrl.substr(redirectUrl.indexOf('?') + 1).split('&');
        if (redirectParams.length > 0) {
          const personaParam = redirectParams.find(x => x.includes('personaId'));
          if (personaParam) {
            personaId = <number><any>personaParam.substr(personaParam.indexOf('=') + 1);
          }
        }
      }
      this.authenticateUser(personaId);

    }
  }
  idenediIdMappingError: boolean = false;
  idenediAuthToken: IdenediAuthModel = null;
  private authenticateUser(personaId: number = 0, isIdenediUser: boolean = false, grantType: GrantTypes = GrantTypes.Password) {
    var authRequest: any;
    if (isIdenediUser) {
      this.showIdenediLoader = true;
      if (grantType == GrantTypes.IdenediKey) {
        authRequest = this.accountService.userAuthenticationByIdenedi(this.idenediAuthToken.idenediId, this.idenediAuthToken.accessToken);
      } else {
        authRequest = this.accountService.userAuthentication("", "", 0, this._idenediCode);
      }
    } else {
      authRequest = this.accountService.userAuthentication(this.email.value, this.password.value, personaId);
    }

    authRequest.subscribe((data: any) => {
      this.showIdenediLoader = false;
      var personas = JSON.parse(data.personas);
      var result = {
        token: {
          accessToken: data.access_token,
          expiry: new Date(data['.expires']),
          refreshToken: data.refresh_token,
          persoans: personas
        },
        user: {
          name: data.name,
          email: data.email,
          role: data.role,
          imageUrl: data.imageUrl,
          organizationId: data.organizationId,
          profileTemplateIds: data.profileTemplateIds ? data.profileTemplateIds.split(',').map(Number) : [],
          applicantPersonaId: data.applicantPersonaId ? +data.applicantPersonaId : 0
        }
      };
      sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));
      this._userInfoService.update();
      if (this.idenediAuthToken && grantType != GrantTypes.IdenediKey) {//for idendi_key user is already linked
        this.linkIdenediUser();
      }
      if (this._userType == 'User') {
        this.accountService.linkToUser(this._key).subscribe(resp => {
          this.authenticateUser(<number><any>resp);
          this._userType = "";
        });
      }
      else if (this._userType == 'Applicant') {
        this.accountService.linkToApplicant(this._key).subscribe(resp => {
          this.authenticateUser(<number><any>resp);
          this._userType = "";
        });
      } else if (this._userType && this._userType.toLocaleLowerCase() == 'setandactivate') {
        this.accountService.linkAndActivate(this._key).subscribe(resp => {
          this.authenticateUser(<number><any>resp);
          this._userType = "";
        });
      }
      if(this._idenediCode){
        this.showIdenediLoader = true;
        this._idenediGroupService.registerUserToOrganizationsByGroups().subscribe(resp => {
          this.showIdenediLoader = false;
          if(resp && resp.length > 1){
            let redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl');
            if (redirectUrl && redirectUrl != '/') {
              this.router.navigate(['/accounts'], { queryParams: { redirectUrl: redirectUrl } });
            }
            else{
              this.router.navigateByUrl('/accounts');
            }
              this.initializeSystemLanguage();
          }else{
            this.checkRedirection(personaId, data);
          }
        }, (err) => {
          this.showIdenediLoader = false;
        });
      }else{
        this.checkRedirection(personaId, data);
      }
    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
        this.isCallBack = false;
        this.showIdenediLoader = false;
        if (err.status == 400 && err.error.error == "IdenediUserNotLinked") {
          this.idenediAuthToken = JSON.parse(err.error.error_description);
          this.idenediIdMappingError = true;
          this.router.navigate(
            ['.'],
            { relativeTo: this.route, queryParams: {} }
          );
          this._idenediCode = "";
        } else if (err.status == 400 && err.error.error == "IdenediAuthError") {
          this.isLoginError = true;
          this.loginErrorMsg = "User not authenticated on iDenedi"
        } else {
          this.loginErrorMsg = this.accountService.getInvalidCredentialMessage(err, this.email.value);
        }
      });
  }
  private authenticateUserbyOrgId(code: string, organizationId: number) {
    this.accountService.espToken(code, organizationId).subscribe(data =>{
      var personas = data.personas;
      var result = {
        token: {
          accessToken: data.token.accessToken          ,
          persoans: personas
        },
        user: {
          name: data.user.name,
          email: data.user.email,
          role: data.user.role,
          imageUrl: data.user.imageUrl,
          organizationId: data.user.organizationId,
          profileTemplateIds: data.user.profileTemplateIds ? data.user.profileTemplateIds.split(',').map(Number) : [],
          applicantPersonaId: data.user.applicantPersonaId ? +data.user.applicantPersonaId : 0
        }
      };
      sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));
      this._userInfoService.update();

      if(result.user.role == Roles.Admin && this.stemexeDefId) {
        this.router.navigate([`/definitions/${this.stemexeDefId}`], { queryParams: {'tab': 1} });
      } else {
        this.router.navigate([`/definitions`], { queryParams: {'type': this.stemexeDefType} });
        this._definitionDialogService.openCreateDefinitionDialog();
      }
    // },
    // (err: HttpErrorResponse) => {
    //   this.isLoginError = true;
    //   this.isCallBack = false;
    //   this.showIdenediLoader = false;
    //   if (err.status == 400 && err.error.error == "IdenediUserNotLinked") {
    //     this.idenediAuthToken = JSON.parse(err.error.error_description);
    //     this.idenediIdMappingError = true;
    //     this.router.navigate(
    //       ['.'],
    //       { relativeTo: this.route, queryParams: {} }
    //     );
    //     this._idenediCode = "";
    //   } else if (err.status == 400 && err.error.error == "IdenediAuthError") {
    //     this.isLoginError = true;
    //     this.loginErrorMsg = "User not authenticated on iDenedi"
    //   } else {
    //     this.loginErrorMsg = this.accountService.getInvalidCredentialMessage(err, this.email.value);
    //   }
    });
  }
  createDefinition() {
    const createDefRef = this._definitionDialogService.openCreateDefinitionDialog();

  }

  private checkRedirection(personaId: number, data: any){
    this._userType = this._key = null;
    let redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl');
      if (redirectUrl && !redirectUrl.startsWith('/iframe')) {
        redirectUrl = decodeURIComponent(redirectUrl);
      }
      if ((this._userInfoService.personas.length > 1 && personaId == 0) || (this.higherParams && this._userInfoService.personas.length == 1)) {
        if (redirectUrl && redirectUrl != '/') {
          this.router.navigate(['/accounts'], { queryParams: { redirectUrl: redirectUrl } });
        }
        else
          this.router.navigateByUrl('/accounts');
      } else {
        this.isCallBack = false;
        if (redirectUrl && redirectUrl != '/') {
          if (redirectUrl.indexOf('&personaId') > -1) {
            redirectUrl = redirectUrl.substr(0, redirectUrl.indexOf('&personaId'));
          }
          this._userInfoService.setAuthorizationData(data, redirectUrl);
        }
        else
          this._userInfoService.setAuthorizationData(data);
      }

      this.initializeSystemLanguage();

  }

  private createApplicantOrUserWithInvitationKey() {
    this.isCallBack = true;
    var createRequest: any;
    if (this._invitationType.toLowerCase() == 'user') {
      createRequest = this.accountService.accpetUserInviteForIdenedi(this._invitationKey, this._idenediCode);
    } else {
      createRequest = this.accountService.accpetApplicantInviteForIdenedi(this._invitationKey, this._idenediCode);
    }
    this.showIdenediLoader = true;
    createRequest.subscribe((data: any) => {
      this.isCallBack = false;
      if (data) {
        this.idenediAuthToken = data;
        this.authenticateUser(0, true, GrantTypes.IdenediKey);
      } else {
        this.showIdenediLoader = false;
        this.isLoginError = true;
        this.loginErrorMsg = "User not authenticated on iDenedi";
      }
      this.router.navigate(
        ['.'],
        { relativeTo: this.route, queryParams: {} }
      );
      this._idenediCode = "";
    },
      (err: HttpErrorResponse) => {
        this.showIdenediLoader = false;
        this.messageService.showMessage(err.message);
        this.isCallBack = false;
      });
  }

  private linkIdenediUser() {
    this.idenediAuthService.linkIdenediUser(this.idenediAuthToken).subscribe(data => { },
      error => {
        debugger;
        if (error.status == 409 && error.error.code == "UserAlreadyLinkedToIdenedi") {
          this.messageService.showMessage("Account cannot be linked! User already mapped with other iDenedi account");
        }
      }
    );
  }

  private initializeSystemLanguage() {
    this._dataSharingService.systemLanguages.subscribe(systemLanguages => {
      this._translationHelperService.getOrganizationLocales().subscribe(resp => {
        let activeLocales = resp.body.split(',');
        if (activeLocales.length > 0) {
          systemLanguages.forEach(systemLanguage => {
            if (!systemLanguage.isDefault)
              systemLanguage.isActive = false;
          });
          systemLanguages.forEach(systemLanguage => {
            activeLocales.forEach(activeLocale => {
              if (systemLanguage.locale == activeLocale) {
                systemLanguage.isActive = true;
              }
            })
          });
        }
      });

    });
  }

  private authenticateWindowsUser(personaId: number = 0) {
    this._userInfoService.update();
    if (this._userInfoService.orgPersonas.length > 1) {
      this.router.navigateByUrl('/accounts');
    } else {
      this.isCallBack = false;
      this._userInfoService.setWindowsAuthorizationData();
    }

    this.initializeSystemLanguage();
  }
  public showIdenediLoader: boolean = false;
  public redirectToIdenediLogin() {
    this.showIdenediLoader = true;
    var paramState: string = "";
    if (this._key && this._userType) {
      paramState = `${this._key};${this._userType};true`;
    }
    window.location.href = this.idenediAuthService.formatedIdenediLoginUrl(paramState);
  }
}
