import { SystemLanguage } from "../../models/system-language";
import { FormControl } from "@angular/forms";
import { Translation, TranslationDataModel } from "../../models/translation";
import { DataSharingService } from "../../common/services/data-sharing.service";

export class LocaleMapper {

  public systemLanguages: SystemLanguage[];
  public systemLanguagesAll: SystemLanguage[];
  public defaultLocale: SystemLanguage;
  public activeLocale: SystemLanguage;
  public dynamicFieldsToMakeDisableOnLocalization: FormControl[];
  public animate: string = 'cardIn';
  public dataLocaleModel: Translation[] = [];
  public dataModel: any = {};
  public localeMapAbleKeys: string[] = [];

  constructor(dataModel = {}, localeMapAbleKeys = [], public dataSharingService: DataSharingService) {
    this.dataModel = dataModel;
    this.localeMapAbleKeys = localeMapAbleKeys;


    this.loadSystemLanguages();
  }

  loadSystemLanguages() {
    this.dataSharingService.systemLanguages.subscribe(resp => {

      this.systemLanguagesAll = [];
      this.systemLanguages = [];
      resp.forEach(sysLanguage => {
        this.systemLanguagesAll.push(sysLanguage);
        if (sysLanguage.isActive || sysLanguage.isDefault)
          this.systemLanguages.push(sysLanguage);
      });
      this.activeLocale = this.systemLanguages[0];
      //this.systemLanguages = resp;
      //let preferredLanguage = JSON.parse(localStorage.getItem('preferredLanguage'));
      //if (preferredLanguage != null) {
      //  this.activeLocale = preferredLanguage;
      //}
      //else {
      //  this.activeLocale = this.systemLanguages[0];
      //}
      this.systemLanguages.forEach(systemLanguage => {
        if (systemLanguage.isDefault) {
          this.defaultLocale = systemLanguage;
        }
      });

      this.localeMapAbleKeys.forEach((fieldLabel, key) => {
        let definitionLocaleModelData: TranslationDataModel[] = [];

        let fieldValues = (this.IsJsonString(this.dataModel[fieldLabel])) ? JSON.parse(this.dataModel[fieldLabel]) : {};
        if (this.IsJsonString(this.dataModel[fieldLabel])) {
          fieldValues = JSON.parse(this.dataModel[fieldLabel]);
        }
        if(fieldValues == null){
          fieldValues = {};
        }
        this.systemLanguagesAll.forEach(systemLanguage => {
          if (fieldValues[systemLanguage.locale] == undefined) {
            fieldValues[systemLanguage.locale] = "";
            if (systemLanguage.locale == this.systemLanguages[0].locale) {
              fieldValues[systemLanguage.locale] = this.dataModel[fieldLabel];
            }
          }
          definitionLocaleModelData.push({ locale: systemLanguage.locale, value: fieldValues[systemLanguage.locale] });
        });

        this.dataLocaleModel.push({
          fieldLabel: fieldLabel,
          data: definitionLocaleModelData
        });

        this.dataModel[fieldLabel] = fieldValues[this.systemLanguages[0].locale];
      });

    });
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
      if (typeof JSON.parse(str) == "number") {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  getDefaultLocaleIndex() {
    return this.systemLanguagesAll.findIndex(x => x.locale == this.defaultLocale.locale);
  }

  getParsedLocaleString(str, locale: string = "") {
    if (locale == "") {
      locale = this.activeLocale.locale;
    }
    let parsedString: string = (this.IsJsonString(str)) ? JSON.parse(str) : str;
    if (this.IsJsonString(str)) {
      parsedString = parsedString[locale];
    }

    if (parsedString) {
      return parsedString.trim();
    } else {
      return parsedString;
    }
  }

  getParsedDefaultLocaleString(str, locale: string = "") {
    if (locale == "") {
      locale = this.defaultLocale.locale;
    }
    let parsedString = (this.IsJsonString(str)) ? JSON.parse(str) : str;
    if (this.IsJsonString(str)) {
      parsedString = parsedString[locale];
    }

    return parsedString;
  }

  setActiveLocale(locale = 'en') {
    this.systemLanguages.forEach(systemLanguage => {
      if (systemLanguage.locale == locale) {
        this.activeLocale = systemLanguage;
      }
    });
  }

  mapToDataLocaleMappableKey(fieldLabel: string, value: string) {
    this.dataLocaleModel.forEach(dataLocale => {
      if (dataLocale.fieldLabel == fieldLabel) {
        let translationDataModel: TranslationDataModel;
        if (this.IsJsonString(value)) {
          translationDataModel = JSON.parse(value);
        } else {
          translationDataModel = { locale: this.defaultLocale.locale, value: value };
        }
        dataLocale.data.forEach(translationData => {
          if (translationDataModel[translationData.locale]) {
            translationData.value = translationDataModel[translationData.locale];
          }
        })
      }
    });
  }

  removeMappingFromDataLocaleMappableKey(fieldLabel: string) {
    this.dataLocaleModel.forEach(dataLocale => {
      if (dataLocale.fieldLabel == fieldLabel) {
        dataLocale.data.forEach(translationData => {
          if (translationData.locale != this.defaultLocale.locale) {
            translationData.value = "";
          }
        })
      }
    });
  }

  isSystemHasArabic(): boolean {
    let systemHasArabic = false;
    this.systemLanguages.forEach(systemLanguage => {
      if (systemLanguage.locale == 'ar') {
        systemHasArabic = true;
      }
    });
    return systemHasArabic;
  }

  isMultiLanguageSystem(): boolean {
    return this.systemLanguages.length > 1;
  }

  isDefaultLocale(): boolean {
    return this.activeLocale.isDefault;
  }

  isMappingLastLocale(): boolean {
    return JSON.stringify(this.systemLanguages[this.systemLanguages.length - 1]) == JSON.stringify(this.activeLocale);
  }

  isMappingFirstLocale(): boolean {
    return this.systemLanguages[0].locale == this.activeLocale.locale;
  }

  getCurrentLocaleIndex(): number {
    let currentLocaleIndex = this.systemLanguages.findIndex(systemLanguage => systemLanguage.locale == this.activeLocale.locale);
    return currentLocaleIndex;
  }

  getNextLocale(indexDiff: number): SystemLanguage {
    return this.systemLanguages[this.getCurrentLocaleIndex() + indexDiff];
  }

  getNextLocaleIndex(indexDiff: number): number {
    let nextLocale: SystemLanguage = this.systemLanguages[this.getCurrentLocaleIndex() + indexDiff];
    let nextLocaleIndex = 0;
    this.systemLanguagesAll.forEach((systemLanguage, index) => {
      if (systemLanguage.locale == nextLocale.locale) {
        nextLocaleIndex = index;
      }
    });
    return nextLocaleIndex;
  }

  mapNextLocale() {
    this.animate = 'cardLeave';
    let currentLocaleIndex: number = this.getCurrentLocaleIndex();
    let nextLocaleIndex = this.getNextLocaleIndex(1);
    this.dataModel = this.mapToLocaleModel(this.dataModel, currentLocaleIndex, nextLocaleIndex);
    this.activeLocale = null;
    let self = this;
    setTimeout(function () {
      if (currentLocaleIndex != self.systemLanguages.length - 1) {
        self.activeLocale = self.systemLanguages[currentLocaleIndex + 1];
      }
    }, 0);

  }

  mapLocaleByIndex(nextLocaleIndex: number) {
    this.animate = 'cardLeave';
    let currentLocaleIndex: number = this.getCurrentLocaleIndex();
    if (currentLocaleIndex != nextLocaleIndex) {
      //let nextLocaleIndex = this.getNextLocaleIndex(1);
      this.dataModel = this.mapToLocaleModel(this.dataModel, currentLocaleIndex, nextLocaleIndex);
      //this.activeLocale = null;
      this.activeLocale = this.systemLanguages[nextLocaleIndex];
      let self = this;
      //setTimeout(function () {
      //  if (currentLocaleIndex != self.systemLanguages.length - 1) {
      //    self.activeLocale = self.systemLanguages[nextLocaleIndex];
      //  }
      //}, 0);
    }

  }

  mapPrevLocale() {
    this.animate = 'cardEnter';
    let currentLocaleIndex: number = this.getCurrentLocaleIndex();
    let prevLocaleIndex = this.getNextLocaleIndex(-1);
    this.dataModel = this.mapToLocaleModel(this.dataModel, currentLocaleIndex, prevLocaleIndex);
    this.activeLocale = null;
    let self = this;
    setTimeout(function () {
      if (currentLocaleIndex > 0) {
        self.activeLocale = self.systemLanguages[currentLocaleIndex - 1];
      }
    }, 0);
  }
  
  getLocaleByIndex(index: number): string {
    return this.systemLanguages[index].locale;
  }

  mapToLocaleModel(dataModel, localeIndex, nextLocaleIndex) {
    this.localeMapAbleKeys.forEach((fieldLabel, key) => {

      this.dataLocaleModel.forEach((dLM, dLMIndex, dLMArray) => {
        if (dLM.fieldLabel == fieldLabel) {
          const locale = this.getLocaleByIndex(localeIndex);
          let localeItem = this.dataLocaleModel[dLMIndex].data.find(d => d.locale == locale);
          localeItem.value = dataModel[fieldLabel];
        }
      });

      if (nextLocaleIndex != -1) {
        const nextLocale = this.getLocaleByIndex(nextLocaleIndex);
        dataModel[fieldLabel] = this.dataLocaleModel.filter(dLM => dLM.fieldLabel == fieldLabel)[0].data.find(d => d.locale == nextLocale).value;
        if (this.dataLocaleModel.filter(dLM => dLM.fieldLabel == fieldLabel)[0].data[nextLocaleIndex].value == "" && nextLocaleIndex != this.getDefaultLocaleIndex()) {
          // dataModel[fieldLabel] = this.dataLocaleModel.filter(dLM => dLM.fieldLabel == fieldLabel)[0].data[this.getDefaultLocaleIndex()].value;
        }
      }


    });

    return dataModel;
  }

  mapFromLocaleModel() {
    if (this.systemLanguages.length > 0)
      this.mapToLocaleModel(this.dataModel, this.getCurrentLocaleIndex(), -1);
    this.dataLocaleModel.forEach((dataLocaleModel, key) => {

      let fieldData: any = {};

      dataLocaleModel.data.forEach((dataLocaleModelData, dataLocaleModelDataIndex) => {
        fieldData[dataLocaleModelData.locale] = dataLocaleModelData.value;
      });
      this.dataModel[dataLocaleModel.fieldLabel] = this.stringifyFieldData(fieldData);

    });
  }

  mapFromLocaleModelNoReference(model: any, processLastActiveLang: boolean = true): any {
    // var updatedModel = JSON.parse(JSON.stringify(model));
    var updatedModel = Object.assign({}, model);
    if (this.systemLanguages.length > 0 && processLastActiveLang)
      this.mapToLocaleModel(this.dataModel, this.getCurrentLocaleIndex(), -1);
    this.dataLocaleModel.forEach((dataLocaleModel, key) => {

      let fieldData: any = {};

      dataLocaleModel.data.forEach((dataLocaleModelData, dataLocaleModelDataIndex) => {
        fieldData[dataLocaleModelData.locale] = dataLocaleModelData.value;
      });
      updatedModel[dataLocaleModel.fieldLabel] = this.stringifyFieldData(fieldData);
    });
    return updatedModel;
  }

  mapValueToCurrentLocale(model: any, propertyName: string, value: string){
    const propertyJson = JSON.parse(model[propertyName]);
    propertyJson[this.activeLocale.locale] = value; 
    model[propertyName] = JSON.stringify(propertyJson);
  }

  stringifyFieldData(fieldData): string {
    let isFieldDataEmpty: boolean = true;
    this.systemLanguagesAll.forEach(systemLanguage => {
      if (fieldData[systemLanguage.locale] == undefined) {
        fieldData[systemLanguage.locale] = "";
      }
      if (fieldData[systemLanguage.locale] != "" && isFieldDataEmpty) {
        isFieldDataEmpty = false;
      }
    });
    return (isFieldDataEmpty) ? "" : JSON.stringify(fieldData);
  }

}
