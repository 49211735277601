import { SubmissionOption } from '../common/enums/submission-option.enum';
import { CustomFieldModel } from '../profile-customization/Model/CustomFieldModel';

export class ChildSubmitterInfoModel {
  definitionId: number;
  applicationId: number;
  definitionName: string;
  isAssign: boolean;
  applicants: any[] = [];
  subUserGroupLists: any[] = [];
  applicantsOptions: ApplicantNameValue[] = [];
  noteField: CustomFieldModel;
  submissionOption?: SubmissionOption = null;
}

export class SecondaryInfo {
  secondaryName?: string;
}

export class ApplicantIdName extends SecondaryInfo {
  id: number;
  name: string
}

export class ApplicantNameValue {
  name: string;
  description?: string;
  value: any;
}
