import { OrganizationUser } from "../organization/models/organization-user";
import { JobRoleAssignment } from "./job-role-assignment-model";

export class JobRoleModel {

  public id: number;
  public name: string;
  public createdBy: number;
  public createdOn: Date;
  public isActive: boolean;
  public isExpended: boolean = false;
  public isAssignmentsExpended: boolean = false;
  public users: JobRoleUser[];
  public assignments: JobRoleAssignment[];
}

export class JobRoleUser {
  
  public id: number;
  public fullName: string;
  public email: string;
  public pictureUrl: string;
  public isSelected: boolean = false;

}
