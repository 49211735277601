<div class="verificationCodeBox" *ngIf="isVerificationCodeSent">
    <h2 *ngIf="verificationRequest.type==='Email'">Email Verification</h2>
    <h2 *ngIf="verificationRequest.type==='Phone'">Phone Verification</h2>
    <p class="subheading">We sent you verification code</p>
    
    <p class="contactInfo">{{verificationRequest.contact}}</p>

    <h3 class="subheading2">Fill The Code</h3>

    <mat-form-field [appearance]="'outline'" class="verifycodebox">
        <input type="text" matInput  [(ngModel)]="code" maxlength="4"/>
        <p class="codefailmsg"  *ngIf="isVerificationAttempted && !isVerificationAttemptSucceeded" [title]="errorMsg">{{errorMsg}}</p>
    </mat-form-field>
    <a [class.disabled]="!timeElapsed" class="resendverificationcode" (click)="resendVerificationCode()">Resend OTP Code</a>
    <button  mat-flat-button class="gradient-btn verify-btn" color="primary" [disabled]="!code || (code.length != 4)" (click)="verifyEmail()">{{"VERIFY" | translate}}</button>
</div>