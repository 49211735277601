import { Component, OnInit, Input, Output, EventEmitter, IterableDiffers, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
//import { SortablejsOptions } from 'angular-sortablejs';
import { CustomFieldModel, LookUpOptions } from '../../../profile-customization/Model/CustomFieldModel';
import { UserInfoService } from '../../../common/services/userinfo-service';
import { Roles } from '../../../common/enums/roles-enum';
import { FeildType } from '../../../common/enums/field-type-enum';
import { CurrencyModel } from '../../../models/currency-model';
import { AddCustomFieldDialogComponent } from '../../../profile-customization/add-custom-field-dialog/add-custom-field-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CustomFieldService } from '../../../common/services/custom-field.service';
import { ApplicationDefinition } from '../../../definitions/models/application-definition';
import { ComponentName } from '../../../common/enums/component-names-enum';
import { LocaleMapper } from '../../../shared/locale-mapper/locale-mapper';
import { DataSharingService } from '../../../common/services/data-sharing.service';
import { StageCriteriaType } from '../../../common/enums/stage-criteria-type';
import { FormSectionModel } from '../../../models/CustomizationSettingsSubmitModel';
import { AddCommonFieldDialogComponent } from '../../../definitions/add-common-field-dialog/add-common-field-dialog.component';
import { AddSectionDialogComponent } from '../../../definitions/add-section-dialog/add-section-dialog.component';
import { AddSettingsComponent } from '../../../profile-customization/add-settings/add-settings.component';
import { ActivatedRoute } from '@angular/router';
import { DeleteFieldDialogComponent } from '../../../shared/delete-field-dialog/delete-field-dialog.component'
import { CustomFieldValueModel } from '../../../models/custom-field-value-model';
import { FieldVisibility } from '../../../common/enums/field-visibility.enum';
import { NoteTypeVisibility } from '../../../common/enums/note-type-visibility-enum';
@Component({
  selector: 'app-custom-fields-form',
  templateUrl: './custom-fields-form.component.html',
  styleUrls: ['./custom-fields-form.component.scss']
})
export class CustomFieldsFormComponent implements OnInit, OnDestroy {
  definitionId: number;
  profileId: number;
  public rolesEnum = Roles;
  FType: typeof FeildType = FeildType;
  ComponentName: typeof ComponentName = ComponentName;

  @Input() isReadOnlyForm: boolean = false;
  @Input() isModelChanged: boolean = false;
  @Input() isInitExpended: boolean = false;
  @Input() componentName: string = '';
  @Input() currenciesList: CurrencyModel[] = [];
  @Input() fields: CustomFieldModel[] = [];
  @Input() mainObject: ApplicationDefinition;
  @Input() fieldsSection: any;
  @Input() sections: FormSectionModel[];
  @Input() sectionHeader?: boolean = false;

  @Input() ifNoFormValues: boolean = false;
  @Input() isFormSection: boolean = false;
  @Input() isApplicationCriteriaFormSection: boolean = false;
  @Input() isApplicantCriteriaFormSection: boolean = false;
  @Input() isApplicationAssessmentFormSection: boolean = false;
  @Input() isApplicantCriteriaFeedbackFormSection: boolean = false;

  @Input() isShowInListFunctional: boolean = false;

  @Input() applicantId: number = 0;

  @Input() localeMapper: LocaleMapper;

  @Input() doFileUploadOnChange: boolean = true;
  @Input() doFileUpload: boolean = false;
  @Input() forFilterView = false;
  @Input() viewAsTableRow = false;
  @Input() showRemoveSection = false;//used to show remove section cell
  //public doFilesUpload: boolean[];
  //@Input()
  //get doUploadFileAll(): boolean {
    //  return this.doFileUpload;
    //}
    //set doUploadFileAll(param: boolean | null) {
      //  if (this.doFileUpload) {
        //    this.doFilesUpload.forEach(dfu => {
  //      dfu = this.doFileUpload;
  //    });
  //  }
  //}
  isDeleteChange: boolean;
  @Output()
  onModelChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public selectedDefinitions: any[] = [];
  isCallback: boolean = false;

  filesUplaodStatus: {[key: string]: boolean} = {};//boolean[] = [];
  filesUploadStatusCheck(sectionCustomFieldId, fType, status) {
    if (fType == this.FType.Attachment) {
      this.filesUplaodStatus[sectionCustomFieldId] = status;
    }
  }

  allFormFilesUploaded: boolean = false;
  @Output() isAllFormFilesUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  /******************************Custom Field Validation Form Start*********************************/
  public customFieldValid: {[key: string]: boolean} = {};//boolean[] = [];
  @Output() getIsFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  private formIsValid: boolean = false;
  private iterableDiffer: any;
  /******************************Custom Field Validation Form End*********************************/

  //eventOptionsForFields: SortablejsOptions = {
  //  onUpdate: () => { this.isModelChanged = true; this.onModelChange.emit(this.isModelChanged); }
  //};
  private _lookupFields: CustomFieldModel[] = [];
  @Output() blur = new EventEmitter();
  @Output() removeSection = new EventEmitter();
  @Input() dynamicFieldsValues: CustomFieldValueModel[] = [];
  @Input() sectionIndex: number = 0;

  @Input() showDynamicSpinner: boolean = false;
  @Input() showHint: boolean = true;
  @Input() isDisabled;
  @Input() clientMessage;
  @Input() resubmit: boolean = false;
  @Input() callFrom: string = '';
  constructor(public userInfoService: UserInfoService
    , public deleteCustomFieldDialog: MatDialog
    , private _route: ActivatedRoute
    , public customFieldDialog: MatDialog, private _datePipe: DatePipe, public customFieldService: CustomFieldService, private _iterableDiffers: IterableDiffers, public _dataSharingService: DataSharingService) {

    /******************************Custom Field Validation From Start*********************************/
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    /******************************Custom Field Validation From End*********************************/

  }
  ngOnDestroy(): void {
    this.getIsFormValid.emit(true);
  }

  /******************************Custom Field Validation From Start*********************************/
  ngDoCheck() {
    //let changes = this.iterableDiffer.diff(this.customFieldValid);
    //if (changes) {
    this.formIsValid = this.isFormValid(this.customFieldValid);
    //}

    //let changes2 = this.iterableDiffer.diff(this.filesUplaodStatus);
    //if (changes2) {
    this.allFormFilesUploaded = this.isFormValid(this.filesUplaodStatus);
    //}

    if (Object.values(this.customFieldValid).length == 0) {
      this.formIsValid = true;
    }
    if (Object.values(this.filesUplaodStatus).length == 0) {
      this.allFormFilesUploaded = true;
    }

    this.getIsFormValid.emit(this.formIsValid && !this.isCallback);
    this.isAllFormFilesUploaded.emit(this.allFormFilesUploaded);
  }
  isFormValid(customFieldValidParam: {[key: string]: boolean}) {
    let formIsValid = false;
    if(Object.values(customFieldValidParam).every(validity => validity)){
      formIsValid = true;
    }
    return formIsValid;
  }

  toNumber(bin: string) {
    return parseInt(bin);
  }
  /******************************Custom Field Validation From End*********************************/

  ngOnInit() {
    this.definitionId = +this._route.snapshot.paramMap.get('id');
    this.profileId = +this._route.snapshot.paramMap.get('id');
    if (this.fields) {
      this._lookupFields = JSON.parse(JSON.stringify(this.fields.filter(x => x.type == this.FType.LookupAddon)));
    }
    this.getSelectedDefinitions();
    this.hideConditionalVisibilityFields();

   this.fields = this.fields.filter(fields => {
      if(fields.allowedValuesCriteria == null || fields.allowedValuesCriteria == NoteTypeVisibility.Edit || fields.allowedValuesCriteria == NoteTypeVisibility.Both){
        return fields.type == this.FType.Note;
      } else {
        return fields.type != this.FType.Note;
      }
    });
  }

  getSelectedDefinitions() {
    if (this.mainObject && this.mainObject.stages) {
      this.mainObject.stages.forEach(stage => {
        stage.criteriaList.forEach(criteria => {
          if (criteria.type == StageCriteriaType.Application) {
            if (!this.containsObject(this.selectedDefinitions, criteria.subDefinitionId)) {
              this.selectedDefinitions.push({ definitionId: criteria.subDefinitionId, definitionName: criteria.name })
            }

          }
        });
      });
    }
  }

  containsObject(list: any, id: number): boolean {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].definitionId === id) {
        return true;
      }
    }
    return false;
  }

  onModelChangeEmit() {
    this.isModelChanged = true;
    this.onModelChange.emit(this.isModelChanged);
  }

  getSelectedCurrenciesString(customField: any) {
    let selectedCurrenciesString = "";
    var customFieldsFormComponentReference: CustomFieldsFormComponent = this;
    let allowedValuesCriteriaArray: string[] = customField.allowedValuesCriteria.split(",");
    allowedValuesCriteriaArray.forEach(function (num) {
      if (customFieldsFormComponentReference.currenciesList != undefined)
        customFieldsFormComponentReference.currenciesList.forEach(function (currency) {
          if (currency.id == parseInt(num) && customField.value != num)
            selectedCurrenciesString += currency.code + ", ";
        });
    });

    return selectedCurrenciesString.slice(0, -2);
  }

  getDefaultCurrencyString(customField: any) {
    let defaultCurrencyString = "";
    var customFieldsFormComponentReference: CustomFieldsFormComponent = this;
    let allowedValuesCriteriaArray: string[] = customField.allowedValuesCriteria.split(",");
    if (customFieldsFormComponentReference.currenciesList != undefined)
      customFieldsFormComponentReference.currenciesList.forEach(function (currency) {
        if (String(currency.id) == customField.value) {
          defaultCurrencyString = currency.code;

          if (customField.allowedValuesCriteria.split(",").length > 1) {
            defaultCurrencyString += ", ";
          }
        }
      });

    return defaultCurrencyString;
  }

  initCurrencyStrings() {
    this.fields.forEach(customField => {
      if (customField.type == this.FType.Money) {
        customField.defaultCurrencyString = this.getDefaultCurrencyString(customField);
        customField.selectedCurrenciesString = this.getSelectedCurrenciesString(customField);
      }
    });

  }

  checkOwnership(field: CustomFieldModel): boolean {
    let disable = false;
    if (this.componentName == this.ComponentName.ListSettingsComponent) {
      return disable;
    }
    if (this.mainObject && this.mainObject.stages) {
      this.mainObject.stages.forEach(stage => {
        stage.criteriaList.forEach(criteria => {
          if (criteria.ownerIdUserLookupCustomFieldPath) {
            let ownerArray = criteria.ownerIdUserLookupCustomFieldPath.split('/');
            if (this.isFormSection) {
              if (ownerArray[0] && ownerArray[0].toLocaleLowerCase() == 'dt') {
                if (ownerArray[1] == field.sectionCustomFieldId.toString()) {
                  disable = true
                  return true;
                }
              }
            } else {
              if (ownerArray[0] && ownerArray[0].toLocaleLowerCase() == 'dct') {
                if (ownerArray[2] == field.id.toString()) {
                  disable = true;
                }
              }
            }
          }
        });
      });
    }
    return disable;
  }

  setValidity(sectionCustomFieldId: number | string, event) {
    this.customFieldValid[sectionCustomFieldId] = event;
  }

  onFieldBlur(sectionCustomFieldId) {
    this.blur.emit(sectionCustomFieldId);
  }

  private hideConditionalVisibilityFields(){
    this.fields.forEach(field => {
      if(field.visibility && field.visibility.type == FieldVisibility.Conditional){
        field.isVisible = false;
      }
    });
  }

  onAddNestedField(field: CustomFieldModel){
    CustomFieldService.addNestedField(field.nestedFields, field, this.fields.filter(f => f.isMultipleAllowed && f.nestedFields && f.nestedFields.length > 0).flatMap(field => field.nestedFields));
  }

  onDeletedNestedField(index: number, field: CustomFieldModel){
    CustomFieldService.removeFieldByIndex(field.nestedFields, index);
    this.blur.emit(field.isTigger);
  }
}
