import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, ElementRef, ViewChild, ApplicationRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validator, Validators, FormControl, UntypedFormControl } from '@angular/forms';
import { FeildType, FieldTypes } from '../../../common/enums/field-type-enum';
import { CustomizationSettingsSubmitModel } from '../../../models/CustomizationSettingsSubmitModel';

import { SharedService } from '../../../shared/shared.service';
import { ApplicantDetailsHelperService } from '../../../common/services/applicant-details-helper.service';
import { ManageCurrenciesService } from '../../../settings/manage-currencies/manage-currencies.service';
import { CurrencyModel } from '../../../models/currency-model';
import { LabelService } from '../../../common/services/label-service';
import { LookUpOptions, SubApplicationOptions, CustomFieldModel, LookUpsOnDefinition, RollUp, PriceAllowedValuesCriteria } from '../../../profile-customization/Model/CustomFieldModel';
import { LookupAdonsService } from '../../../settings/lookup-ad-ons/lookup-ad-ons.service';
import { ValueDetails, CustomFieldValueModel } from '../../../models/custom-field-value-model';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../../../application/services/application.service';
import { ComponentName } from '../../../common/enums/component-names-enum';
import { TranslationHelperService } from '../../../common/services/translation-helper.service';
import { ActivatedRoute } from '@angular/router';
import { DataSharingService } from '../../../common/services/data-sharing.service';
import { ApplicationDefinition } from '../../../definitions/models/application-definition';
import { CustomFieldService } from '../../../common/services/custom-field.service';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  map,
  switchMap,
} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { TagsService } from '../services/tags.service';
import { NameId } from '../../../models/UserResult';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { SubSink } from 'subsink';
import { LookupType } from '../../../common/enums/lookup-type-enum';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EspQuillEditorComponent } from '../../../custom-material/esp-quill-editor/esp-quill-editor.component';
import { EspRichTextEditorComponent } from '../../../custom-material/esp-date-format-controls/esp-rich-text-editor/esp-rich-text-editor.component';
import { AttachmentService } from '../services/attachment.service';
import { OrgSettingsStore } from '../../../organization/services/org-settings.store';
import { OrgSettings } from '../../../models/org-settings.model';
import { log } from 'console';
import { FormSharingService } from '../../../form-builder/services/form-sharing.service';

@Component({
  selector: 'app-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss']
})
export class CustomFieldComponent implements OnInit, AfterViewInit, OnDestroy {

  ComponentName: typeof ComponentName = ComponentName;
  FType: typeof FeildType = FeildType;
  public customValidation: boolean = true;
  public formFieldName: string = '';
  public allowedCurrencies: CurrencyModel[] = [];
  public selectedCurrency: CurrencyModel;
  private _currencies: CurrencyModel[] = [];
  public definitionDetails = new ApplicationDefinition();
  ratingValue: number;

  public lookUpsOnDefinitionArry: LookUpsOnDefinition[] = [];
  public hyperlinkLabelEnabled: boolean = false;
  @ViewChild('datePickerList', { static: false }) picker;
  @ViewChild('lookupSelect', { static: false }) lookupSelectElem: MatSelect;
  //@ViewChild('virtualScrollViewPort', { static: false }) virtualScrollViewPortElem;
  @ViewChild('ngxSearch', { static: false }) ngxSearch: any;
  @Input() formName: any;
  @Input() customField: any;
  private _isDisabled: boolean = false;
  @Input() get isDisabled(): boolean {
    return this._isDisabled
  }
  set isDisabled(disabled: boolean) {
    this._isDisabled = disabled;
    this._setDisableCheck(disabled);
  }
  private _readonly: boolean = false;
  @Input() get readonly(): boolean {
    return this._readonly
  }
  set readonly(disabled: boolean) {
    this._readonly = disabled;
  }
  private _field: CustomFieldModel;
  @Input() get field(): CustomFieldModel {
    return this._field
  }
  set field(field: CustomFieldModel) {
    this._field = field;
  }
  private _clientMessage;
  @Input() get clientMessage() {
    return this._clientMessage
  }
  set clientMessage(clientMessage) {
    if(clientMessage && clientMessage.SCFId > 0 && this.customField.sectionCustomFieldId == clientMessage.SCFId) {
          this.notUniqueMsg= clientMessage.Message;
          this.customFieldForm.controls[this.formFieldName].setErrors({'fieldNotUnique':true});
          this.customFieldForm.controls['isMultiLingualText'].setErrors({'fieldNotUnique':true});
          this.isValid.emit(false);
    }
    this._clientMessage = clientMessage;
  }
  @Input('applicant-id') applicantId: number = 0;
  @Input('application-id') applicationId: number = 0;
  @Input() componentName: string = '';
  @Input() forLookupId: number = 0;


  @Input() fieldOptions: any = { floatLabel: 'auto', showPlaceholder: true };

  @Input() doFileUploadOnChange: boolean = true;
  public file: any;
  @Input()
  get doFileUpload(): boolean {
    return this.doFileUploadVal;
  }
  set doFileUpload(param: boolean | null) {
    this.doFileUploadVal = param;
    if (this.customField.type == this.FType.Attachment && param) {
      this.onFileUpload(this.file, this.customField);
    }
  }
  public doFileUploadVal: boolean = false;

  @Output() isFileUploaded = new EventEmitter();
  @Output() isCallback = new EventEmitter();
  @Output() isValid = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() showTagableField = new EventEmitter<boolean>();
  @Output() addNestedField = new EventEmitter<CustomFieldModel>();
  @Output() deleteNestedField = new EventEmitter<number>()
  @Input() resubmit: boolean = false;
  @Input() showInTable: boolean = false;
  public notUniqueMsg:string='';

  onBlur(customField: CustomFieldModel) {
    if(customField.isRequired && customField.isUnique) {
      this.checkCustomFieldValueUnique(customField);
    }
    if(customField.isRequired && customField.isUnique && customField.isMultipleAllowed) {
      this.findDuplicates(this.field.nestedFields, 'value');
    }
    if(this.customField.type == FeildType.Hyperlink  && this.hyperlinkLabelEnabled && this.customFieldForm.controls['hyperlinkTitle'].value == '') {
      this.udpateValidityhyperlinkTitleFC() 
    }
    if(this.customField.type == FeildType.Hyperlink  && this.hyperlinkLabelEnabled && this.customField.value == "") {
      this.udpateValidityhyperlinkFC() 
    }
    this.blur.emit(customField.isTigger);
  }
  checkCustomFieldValueUnique(customField) {
    if(customField.isRequired && customField.isUnique)
    {
      let value = '';
      if (customField.value && customField.isMultiLingual) {
        let formatedValue = {
          en: customField.value,
          ar: customField.isMultiLingualText ?? '',
          fr: ""
        }
        value = JSON.stringify(formatedValue);
      } else if(customField.value && !customField.isMultiLingual) {
        value = customField.value
      }
      this._applicationService.checkCustomFieldValueUnique(customField.sectionCustomFieldId, value, this.applicationId ).subscribe(x=>{
        if(!x.isUnique)
        {
          this.notUniqueMsg=x.message;
          this.customFieldForm.controls[this.formFieldName].setErrors({'fieldNotUnique':true});
          this.customFieldForm.controls['isMultiLingualText'].setErrors({'fieldNotUnique':true});
          this.isValid.emit(false);
        }
      });
    }

  }
  findDuplicates(array, property) {
    const duplicates = [];
    const values = {};
    
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      const value = item[property];
      
      if (values[value]) {
        duplicates.push(item);
        this.notUniqueMsg ='Value is not Unique';
          this.customFieldForm.controls[this.formFieldName].setErrors({'fieldNotUnique':true});
          this.customFieldForm.controls['isMultiLingualText'].setErrors({'fieldNotUnique':true});
          this.isValid.emit(false);
      } else {
        values[value] = true;
        this.notUniqueMsg='';
        this.customFieldForm.controls[this.formFieldName].setErrors({'fieldNotUnique':false});
        this.customFieldForm.controls['isMultiLingualText'].setErrors({'fieldNotUnique':false});

        this.customFieldForm.controls[this.formFieldName].updateValueAndValidity();
        this.customFieldForm.controls['isMultiLingualText'].updateValueAndValidity();
        this.isValid.emit(true);
      }
    }
    return duplicates;
  }
  onKeyup() {
    if (this.customField.type == this.FType.Hyperlink && this.customField.value) {
      if (!this.customField.value.toLowerCase().startsWith('https')) {
        this.customField.value = 'https://' + this.customField.value;
      }
    }
  }

  removeHyphensAllowNumbers(event) {
    return CustomFieldService.removeHyphensAllowNumbers(event, this.customField.value);
  }

  public customFieldForm: FormGroup;
  private formBuilder: FormBuilder = new FormBuilder();
  @ViewChild('inputTypeFile', { static: false }) inputTypeFile: ElementRef;
  public selectedColor: any;
  public isFileButtonTouched: boolean = false;
  public lookUpOptions: LookUpOptions[] = [];
  public lookUpOptionsAll: LookUpOptions[] = [];
  public subApplicationOptions: SubApplicationOptions[] = [];
  @Input() sectionIndex: number = 0;
  @Input('dynamicFieldsValues')
  set dynamicFieldsValues(fieldValues: CustomFieldValueModel[]) {
    if (!this.customField.isVisible) {
      console.log('customField.isVisible', this.customField);
      
      this.isValid.emit(true);
    } else if (this.customField.isVisible && (this.customField?.type == FeildType.Rating || this.customField?.type == FeildType.RichText) &&  this.customField.isRequired && !this.customField.isReadOnly && !this.customField.value) {
      this.isValid.emit(false);
    } else if(this.customField.isVisible && this.customFieldForm?.invalid) {
      this.isValid.emit(false);
    }
   
    if (fieldValues) {
      if (this.customField.type == FeildType.LookupAddon) {
        let fieldValue = fieldValues.find(x => x.sectionCustomFieldId == this.customField.sectionCustomFieldId && x.sectionIndex == this.sectionIndex);
        if (fieldValue) {
          if (this.customField.allowedValuesCriteria) {
            // ITCHA change
            if(fieldValues && fieldValue.lookupItems.length == 1 && (this.customField.isRequired || this.customField.isReadOnly)) {
              this.customField.value = fieldValue.lookupItems[0].id.toString();
            } 
            this.lookUpOptionsAll = fieldValue.lookupItems;
            this._lookupOptionsSearchedList = fieldValue.lookupItems;
            this.lookUpOptions = this.lookUpOptionsAll.slice(0, this.lookUpOptionsAll.length >= 20 ? 20 : this.lookUpOptionsAll.length);
          } else {
            this.customField.value = fieldValue.value;
          }
          if(this.customField.type == FeildType.LookupAddon && this.customField.lookupType == LookupType.Definition && this.customField.lookUpId == this._refDefId){
           if(fieldValue.lookupItems.find(item => item.id == this.refApplicationId)){
            this.customField.value = this.refApplicationId.toString();
           }else{
            this.customField.value = '';
           }
          }
          this.setSelectedItem();
          let selectedLookups = this.lookUpOptionsAll.filter(x => x.id == this.customField.value);
          if (selectedLookups && selectedLookups.length > 0) {
            this.customField.actionValue = selectedLookups[0].name;
          }   
        }
      } else if (this.customField.type == FeildType.SingleSelection) {
        let fieldValue = fieldValues.find(x => x.sectionCustomFieldId == this.customField.sectionCustomFieldId && x.sectionIndex == this.sectionIndex);
        if(fieldValue) {
          if(fieldValues && fieldValue.lookupItems?.length == 1) {
            this.customField.value = fieldValue.lookupItems[0].id.toString();
          }
          //this.customField.lookupValues = fieldValue.lookupItems;
          fieldValue.lookupItems?.forEach((o, index) => {
            if(o.name) {
              this.customField.lookupValues[index].label = o.name;
            }
          });
          this.singleSelectionDefaultvalue();
        }
      }
      if(this.customField.type == FeildType.Text || this.customField.type == FeildType.MultiLine) {
        this.getSingleMultiLineTextValue();
      }
      if(this.customField.type == FeildType.Hyperlink) {
        this.getHyperLinkValue();
      }
    }
    // const fieldValue = fieldValues.find(x => x.sectionCustomFieldId == this.customField.sectionCustomFieldId && x.sectionIndex == this.sectionIndex);
    // if(fieldValue && fieldValue.values && fieldValue.values.length > 1){
    //   this.customField.type = FeildType.SingleSelection;

    // }
  }
  @Input() showDynamicSpinner: boolean = false;//display spenneri
  @Input() showHint: boolean = true;
  @Input() showHintIconSpace = true;
  @Input() isLastNestedField: boolean = true;
  @Input() nestedFieldIndex: number = 0;
  @Input() nestedFieldsCount: number;
  @Input() forFilterView = false;
  @Input() formBuilderFieldSettings: boolean;
  @Input() callFrom: string = '';
  public isFocused: boolean = false;
  public mobileIcons: boolean = false;
  public isRating: boolean = false;
  public  expandEditor: boolean = false;
  separatorKeysCodes: number[] = [COMMA, ENTER];
  tags: string[] = [];
  systemTags: NameId[] = [];
  filteredSystemTags: NameId[] = [];
  systemTagsLoading: boolean;
  fc: FormControl;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  private subs = new SubSink();
  public refApplicationId: number = 0;//used to populate definition lookup
  private _refDefId: number = 0; //used to populate definition lookup
  public hyperlinkRequired: boolean;
  uploadImgExceptionMsg = '';//used to show backend ecxeption message if frontend validation passed but backend throw any exception
  constructor(private cdref: ChangeDetectorRef, private sharedService: SharedService, private _applicantDetailService: ApplicantDetailsHelperService
    , private _currencyService: ManageCurrenciesService
    , public labelService: LabelService
    , private _lookupAdonsService: LookupAdonsService
    , public translate: TranslateService
    , private translationHelperService: TranslationHelperService
    , private _applicationService: ApplicationService
    , private _route: ActivatedRoute
    , private _dataSharingService: DataSharingService
    , private _applicationRef: ApplicationRef
    , public customFieldService: CustomFieldService
    , private _tagService: TagsService
    , private _matDialog: MatDialog
    , private attachmentService : AttachmentService
    , private orgSettingsStore: OrgSettingsStore
    , public formSharingService : FormSharingService
  ) {
    // construct our form and tell formbuilder what validators we need.
    this.subs.sink = this._route.queryParams.subscribe(params => {
      if (params['refAppId']) {
        this.refApplicationId = +params['refAppId'];
      }
      if (params['refDefId']) {
        this._refDefId = +params['refDefId'];
      }
    });
  }


  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  email = new FormControl('', [Validators.required, Validators.email]);

  // getEmailErrorMessage() {
  //   return this.email.hasError('email') ? this.translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-INVALID') : '';
  // }

  getPhoneNumberErrorMessage() {
    return this.translationHelperService.getTranslationByKey('ErrorMessages.INVALID-NUMBER');
  }
  hideNumberPrefix(name) {
    if(name.startsWith('NUM')) {
      let rename = name.split('-');
      return rename[1]
    }
  }

  getHyperLinkErrorMessage() {
    return this.translationHelperService.getTranslationByKey('ErrorMessages.INVALID-LINK');
  }
  private _setDisableCheck(disable: boolean) {
    if (!this.customFieldForm || !this.formFieldName) {
      return;
    }

    if (disable) {
      this.customFieldForm.get(this.formFieldName).disable();
      this.isValid.emit(true);

    } else {
      this.customFieldForm.get(this.formFieldName).enable();
      this.isValid.emit(this.customFieldForm.valid);

    }
  }
  ngOnInit() {
    
    if(this.showInTable){
      if(this.customField.type == FeildType.Image){
        this.customField.type = FeildType.Attachment; 
      }
    }
    this.singleSelectionDefaultvalue();

    let formFieldNames = {};
    if (this.customField.type == this.FType.Attachment) {
      //if (this.customField.details != null) {
      //  this.customField.details.name = this.customFieldService.getFileNameWithoutExtension(this.customField.details.name);
      //}
      this.isFileUploaded.emit(!this.customField.isRequired);
    }
    if (this.customField.type == this.FType.MultiSelection) {
      this.isValid.emit(!this.customField.isRequired);


      for (let lookup of this.customField.lookupValues) {
        formFieldNames["customFieldName" + lookup.id] = [{ value: undefined, disabled: this.isDisabled }];
      }

      if (this.customField.isRequired) {
        let validation: boolean = false;
        for (let lookup of this.customField.lookupValues) {
          if (lookup.isSelected) {
            validation = true;
            break;
          }
        }
        this.isValid.emit(validation);
      }
    }  else if (this.customField.type == this.FType.Date) {
      formFieldNames = {
        ["customFieldName" + this.customField.id]: [{ value: this.customField.value, disabled: this.isDisabled || this.customField.isRealTime }]
      };
    } else if (this.customField.type == this.FType.DateRange) {
      formFieldNames = {
        ["customFieldName" + this.customField.id]: [{ value: this.customField.value, disabled: this.isDisabled || this.customField.isRealTime, validators: [Validators.required] }]
      };
    } else if (this.customField.type == this.FType.Tags) {
      this.loadSystemTags();
      this.fc = new FormControl({ value: null, disabled: this.isDisabled }, this.customField.isRequired ? [Validators.required] : null);
      formFieldNames = {
        ["customFieldName" + this.customField.id]: this.fc
      };
      if (this.customField.value) {
        this.tags = this.customField.value.split(',');
      }
      this.udpateValidityTagFc();
    } else {
      formFieldNames = {
        ["customFieldName" + this.customField.id]: [{ value: undefined, disabled: this.isDisabled }]
      };
    }

    this.formFieldName = Object.keys(formFieldNames)[0];

    this.customFieldForm = this.formBuilder.group(formFieldNames);

    if(this.customField.type == FeildType.Price){
      this.customFieldForm.addControl('priceUnit', new FormControl('', this.customField.isRequired ? [Validators.required] : null))
    }
    if(this.customField.type == FeildType.Hyperlink && this.customField.settings != null && this.customField.settings.length > 0 && this.customField.settings.filter(s => s.key == 'hyperlinkLabelEnabled').length > 0) {
      let settings = this.customField.settings.filter(s => s.key == 'hyperlinkLabelEnabled');
      this.hyperlinkLabelEnabled = settings[0].value;
      console.log('this.customField',this.hyperlinkLabelEnabled);
      
    }
    if(this.customField.type == FeildType.Hyperlink && this.hyperlinkLabelEnabled){
      
      this.customFieldForm.addControl('hyperlinkTitle', new FormControl({value : '', disabled: this.isDisabled || this.customField.isReadOnly}));
    
    }
    if(this.customField.type == FeildType.Text && this.customField.isMultiLingual){
      this.customFieldForm.addControl('isMultiLingualText', new UntypedFormControl(''));
    }
    if(this.customField.type == FeildType.MultiLine && this.customField.isMultiLingual){
      this.customFieldForm.addControl('isMultiLingualMultiLine', new UntypedFormControl(''));
    }
    
    // here we are subscribing to changes to the form. This will fire anytime there is a change in our form.
    this.customFieldForm.valueChanges.subscribe((values) => {
      if (this.customField.type == this.FType.LookupAddon) {
        let selectedLookups = this.lookUpOptionsAll.filter(x => x.id == this.customField.value);
        if (selectedLookups && selectedLookups.length > 0) {
          this.customField.actionValue = selectedLookups[0].name;
        }
        if(this.lookUpOptionsAll.length == 1 && this.customField.isTigger && this.customField.isRequired && (this.customField.value == null || this.customField.value == '')) {
          this.onBlur(this.customField)
        }

        if (this.customField.allowedValuesCriteria) {
          if(!this.customField.rollup){
            this.customField.rollup = new RollUp();
            this.customField.rollup.conditions = JSON.parse(this.customField.allowedValuesCriteria);
          }
        } else {
          this.customField.rollup = new RollUp();
          this.customField.rollup.conditions = [];
        }
      }
      if (this.customField.type == this.FType.MultiSelection) {

        if (this.customField.isRequired) {
          let validation: boolean = false;
          for (let lookup of this.customField.lookupValues) {
            if (lookup.isSelected) {
              validation = true;
              break;
            }
          }
          this.isValid.emit(validation);
        }

      } else if (this.customField.type == FeildType.Tags) {
        this.filterSystemTags(values[this.formFieldName]);
      } else {
        this.isValid.emit(this.customFieldForm.valid);
      }
      if (this.customField.isReadOnly || !this.customField.isVisible) {
        this.isValid.emit(true);
      }
    });
    if(this.customField.type == this.FType.Hyperlink) {
      this.getHyperLinkValue();
    }
    if(this.customField.type == this.FType.Text || this.customField.type == this.FType.MultiLine) {
      this.getSingleMultiLineTextValue();
    }
    if (this.customField.type == this.FType.Money || this.customField.type == this.FType.Price) {
      if (this._currencyService.currencies.length == 0) {
        this._currencyService.getCurrencies().subscribe(resp => {
          this._currencyService.currencies = resp.body.filter(C => C.id != 100);
            this.getAllowedCurrencies();
        });
      } else {
          this.getAllowedCurrencies();
      }
    } else if (this.customField.type == this.FType.LookupAddon) {
      // console.log(this.customField);
      var definitionKey = this._route.snapshot.paramMap.get('definitionKey');
      if (this.customField.allowedValuesCriteria) {
        var relatedForFilterObj = JSON.parse(this.customField.allowedValuesCriteria);
        // console.log(relatedForFilterObj);
        let lookupFilterObject: LookUpsOnDefinition = new LookUpsOnDefinition();
        lookupFilterObject.relatedFormType = relatedForFilterObj.relatedFormType;
        lookupFilterObject.lookupId = relatedForFilterObj.lookupId;
        lookupFilterObject.referenceSectionCustomFieldId = relatedForFilterObj.refrenceSectionCustomFieldId;
        lookupFilterObject.sourceValueSCFId = relatedForFilterObj.sourceValueSCFId;
        lookupFilterObject.filterSCFIdInLookup = relatedForFilterObj.filterSCFIdInLookup;
        lookupFilterObject.label = relatedForFilterObj.label;
        // console.log(lookupFilterObject);
        this.lookUpsOnDefinitionArry.push(lookupFilterObject);
        if (relatedForFilterObj.lookupId == this.customField.lookUpId) {
          // console.log(this.customField.sectionCustomFieldId);
        }
        // console.log(this.lookUpsOnDefinitionArry);
        if (relatedForFilterObj.relatedFormType == 'Definition-Form') {
          //
        }
        else if (relatedForFilterObj.relatedFormType == 'Definition-Form') {

        }
      }
      let rollup: RollUp = new RollUp();
      rollup.conditions = [];
      if (this.customField.allowedValuesCriteria) {
        rollup.conditions = JSON.parse(this.customField.allowedValuesCriteria);
      }
      let ignoreLookupItemsLoadSetting = false; 
      //If settings has IgnoreLookupItemsLoad key then do not load lookup items - this is for Update Field Action - field with Update Field action will populate this field
      if(this.customField.settings && this.customField.settings.find(s => s.key == 'IgnoreLookupItemsLoad')){
        ignoreLookupItemsLoadSetting = true; 
      }
      if ((rollup.conditions.length == 0 || this.customField.lookUpId < -1600) && this.customField.lookUpId != -1604 && !ignoreLookupItemsLoadSetting) { //for plannex and other custom fields less than -1600 and not for plannex Requirements
        this._lookupAdonsService.getLookupItemOptions(this.customField.lookUpId, this.applicantId ? this.applicantId : 0, definitionKey, this.forLookupId, this.customField.lookupType, this.customField.sectionCustomFieldId?.toString()).subscribe(resp => {
          let response = resp
          if(this.customField.type == FeildType.LookupAddon && this.customField.lookupType == LookupType.Definition) {
            if(this.customField.settings != null && this.customField.settings.length > 0 && this.customField.settings.filter(s => s.key == 'ShowNumberPrefix').length > 0) {
              let settings = this.customField.settings.filter(s => s.key == 'ShowNumberPrefix');
              if(!settings[0].value) {
                response = resp.map(opt => {
                  return { ...opt, name: this.hideNumberPrefix(opt.name) };
                });
                
              }
            }
          }
          this.lookUpOptionsAll = response;
          this._lookupOptionsSearchedList = response;
          this.lookUpOptions = this.lookUpOptionsAll.slice(0, this.lookUpOptionsAll.length >= 20 ? 20 : this.lookUpOptionsAll.length);
          this.setSelectedItem();
          // ITCHA change
          if(this.lookUpOptions.length == 1 && (this.customField.isRequired || this.customField.isReadOnly)) {
            this.customField.value = this.lookUpOptions[0].id.toString()
          }
          let selectedLookups = this.lookUpOptionsAll.filter(x => x.id == this.customField.value);
          if (selectedLookups && selectedLookups.length > 0) {
            this.customField.actionValue = selectedLookups[0].name;
          }
        }, error => { });
      }
    } else if (this.customField.type == this.FType.SubApplication && this.applicationId != 0 && this.customField.allowedValuesCriteria != '') {
      this._applicationService.getApplicationSubApplications(this.applicationId, this.customField.allowedValuesCriteria).subscribe(resp => {
        this.subApplicationOptions = resp.body;
      }, error => { });
    }
    if (this.customField.isReadOnly || !this.customField.isVisible) {
      this.isValid.emit(true);
    }
    this.mobileIcons = (((this.customField.hint && this.showHint && this.customField.isMultipleAllowed) || (this.customField.isMultipleAllowed || this.nestedFieldsCount > 1)) || (this.customField.hint && this.showHint && !this.customField.isMultipleAllowed));
    this.hyperlinkRequired = this.customField.isRequired
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (!this.customField.isVisible || this.customField.isMultipleAllowed) {
      this.isValid.emit(true);
    }
  }

  public lookupOptionsSearchModel: string;
  private _lookupOptionsSearchedList: LookUpOptions[] = [];
  ngxSearch$: any;
  ngAfterViewInit() {
    //ShortText, LongText, SingleSelection
    if ([FeildType.Text, FeildType.MultiLine, FeildType.SingleSelection].includes(this.customField.type)) {
      const matField = document.getElementById(this.customField.id);
      if (matField) {
        if (matField.getElementsByClassName("mat-form-field-label")[0]) {
          matField.getElementsByClassName("mat-form-field-label")[0].setAttribute("style", "white-space: normal; background-color:#fff;");
          if (matField.getElementsByClassName("mat-form-field-label")[0]) {
            const height = matField.getElementsByClassName("mat-form-field-label")[0].clientHeight;
            if (height > 20) {
              matField.getElementsByClassName("mat-form-field-flex")[0].setAttribute("style", `min-height:${height + 40}px`);
              matField.getElementsByClassName("mat-form-field-label-wrapper")[0].setAttribute("style", "overflow: unset;");
              if (matField.getElementsByTagName('input')[0]) {
                matField.getElementsByTagName('input')[0].setAttribute("style", `height: ${height}px`);
              } else if (matField.getElementsByTagName('mat-select')[0]) {
                matField.getElementsByTagName('mat-select')[0].setAttribute("style", `margin-top: ${(height / 2) - 5}px`);
              }
            } else {
              matField.getElementsByClassName("mat-form-field-label")[0].setAttribute("style", "white-space: nowrap");
            }
          }
        }
      }
    }
    if (this.customField.type == FeildType.LookupAddon) {

      this.lookupSelectElem._openedStream.subscribe(() => {
        this.ngxSearch$ = fromEvent<any>(this.ngxSearch.searchSelectInput.nativeElement, 'keyup').pipe(
          map(event => event.target.value),
          debounceTime(400),
          distinctUntilChanged(),
          switchMap(search => this.searchLookupOptions(search)),
        ).subscribe();
        const panel = this.lookupSelectElem.panel.nativeElement;
        //this.virtualScrollViewPortElem.elementRef.nativeElement.scrollTop = 1;
        //setTimeout(() => {
        //  panel.scrollTop = 100;
        //}, 100);
        panel.addEventListener('scroll', event => {
          let pos = event.target.scrollTop + event.target.offsetHeight;
          let max = event.target.scrollHeight;
          if (Math.trunc(pos) >= Math.trunc(max - 50)) {
            if (this.lookUpOptions.length != this._lookupOptionsSearchedList.length) {
              this.lookUpOptions = this._lookupOptionsSearchedList.slice(0, this._lookupOptionsSearchedList.length > this.lookUpOptions.length + 20 ? this.lookUpOptions.length + 20 : this._lookupOptionsSearchedList.length);
              this.setSelectedItem();
              setTimeout(() => {
                event.target.scrollTop = pos - 100;
              }, 100);
            }
          }
        });
      });

      this.lookupSelectElem._closedStream.subscribe(() => {
        this.lookUpOptions = this.lookUpOptionsAll.slice(0, this.lookUpOptionsAll.length >= 20 ? 20 : this.lookUpOptionsAll.length);
        this.setSelectedItem();
        this.ngxSearch$.unsubscribe();
      });
    }
  }

  searchLookupOptions(search: string): Observable<LookUpOptions[]> {
    this._lookupOptionsSearchedList = this.lookUpOptionsAll.filter(x => x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    this.lookUpOptions = [];
    this.lookUpOptions = this._lookupOptionsSearchedList.slice(0, this._lookupOptionsSearchedList.length > this.lookUpOptions.length + 20 ? this.lookUpOptions.length + 20 : this._lookupOptionsSearchedList.length);
    //this.setSelectedItem();
    return new Observable<any>();
  }

  onLookupSelection($event) {
    this.setSelectedNameForLookupOptions();
  }

  private setSelectedNameForLookupOptions() {
    if (this.customField.value) {
      this.customField.selectedText = this.lookUpOptionsAll.find(x => x.id == this.customField.value).name;
    }
  }

  private setSelectedItem() {
    if (this.customField.value && !this.customField.isMultipleAllowed) {
      const selectedItem = this.lookUpOptionsAll.find(x => x.id == this.customField.value);
      if (selectedItem) {
        const index = this.lookUpOptions.findIndex(x => x.id == selectedItem.id);
        if (index > -1) {
          this.lookUpOptions.splice(index, 1);
        }
        this.lookUpOptions.unshift(selectedItem);
        this.customField.actionValue = selectedItem.name;
      } else {
        if (this.customField.value.indexOf('#') < 0) { //do not empty value for tag fields (Action Fields)
          this.customField.value = '';
        }
        this.onBlur(this.customField);
      }
    } else {
      if (Array.isArray(this.customField.value) && this.customField.value.length > 0) {
        let valueArray : any[] = [];
        if(this.customField.value.every(element => typeof element === 'string')) {
          valueArray = this.customField.value.map(str => Number(str));
        } else {
          valueArray = this.customField.value;
        }
        
        let values: any[] = [];
        if (valueArray.includes(-1)) { 
          values = this.lookUpOptionsAll.map((op) => op.id);
          values.push(-1); // Add -1 to the selection
          this.customField.value = values;
        } else {
          valueArray.forEach(value => {
            const selectedItem = this.lookUpOptionsAll.find(x => x.id == value);
            if (selectedItem) {
              const index = this.lookUpOptions.findIndex(item => item.id === selectedItem.id);

              if (index > -1) {
                this.lookUpOptions.splice(index, 1);
              }
              this.lookUpOptions.unshift(selectedItem);
            } else {
              if (value.indexOf('#') < 0) {
                const valueIndex = valueArray.indexOf(value);
                if (valueIndex > -1) {
                  valueArray.splice(valueIndex, 1);
                }
              }
        this.onBlur(this.customField);

            }
          });
          if(this.customField.value.every(element => typeof element === 'string')) {
            this.customField.value = this.customField.value.map(str => Number(str));
          } else {
            this.customField.value = this.customField.value;
          }
        }
        this.updateTriggerText();
        // this.onBlur(this.customField);
      }
    }
  }

  trackLookupElement(index: number, element: LookUpOptions) {
    return element ? element.id : null
  }

  // reOpenCalendar() {
  //   let self = this;
  //   setTimeout(
  //     () => {
  //       self.picker.open();
  //     },
  //     50
  //   );
  // }
  getHyperLinkValue() {
    if(this.customField.value) {
      // let value = JSON.parse(this.customField.value);
      // this.customField.value = value.url;
      // this.customField.hyperlinkTitle = value.title;
      try {
        let value = JSON.parse(this.customField.value);
      this.customField.value = value.url;
      this.customField.hyperlinkTitle = value.title;
      } catch (e) {
        this.customField.value = this.customField.value;
      }
    }
  }
  getSingleMultiLineTextValue() {
    if (this.customField.value) {
      if (this.getObjectValue(this.customField.value)) {
        try {
          let value = JSON.parse(this.customField.value);
          this.customField.value = value.en;
          if (this.customField.type == FeildType.Text) {
            this.customField.isMultiLingualText = value.ar;
          } else {
            this.customField.isMultiLingualMultiLine = value.ar;
          }
        } catch (e) {
          this.customField.value = this.customField.value;
        }
      } else {
        this.customField.value = this.customField.value;
      }
    }
  }
  getObjectValue(val){
    try {
      let value = JSON.parse(val);
      if (value.en || value.ar) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
  priceUnits: string[] = [];
  getAllowedCurrencies() {
    if(this.customField.type == FeildType.Money){
      this.customField.allowedValuesCriteriaArray = this.customField.allowedValuesCriteria.split(',');
    } else if(this.customField.type == FeildType.Price) {
        let settings: PriceAllowedValuesCriteria = JSON.parse(this.customField.allowedValuesCriteria);
        this.customField.allowedValuesCriteriaArray = settings.allowed;
        this.priceUnits = settings.units;
    }
    for (let i = 0; i < this.customField.allowedValuesCriteriaArray.length; i++) {
      if (this.customField.allowedValuesCriteriaArray[i] != '-1') {
        var currency: CurrencyModel = this._currencyService.currencies.filter(x => x.id == this.customField.allowedValuesCriteriaArray[i])[0];
        // this.allowedCurrencies.push(currency);
        // Check if currency is found
        if (currency) {
          this.allowedCurrencies.push(currency);
          console.log('allowedCurrencies',this.allowedCurrencies);
          
        } else {
          console.warn(`Currency with ID ${this.customField.allowedValuesCriteriaArray[i]} not found.`);
        }
      }
    }


    if (this.customField.value != null && this.customField.value.toString().indexOf(':') >= 0) {
      //amount:selectedCurrencyCode:amountInbaseCurrency:baseCurrencyCode:symbol
      var valueArray = this.customField.value.split(':');
      this.customField.value = valueArray[0];
      this.customField.selectedCurrencyId = valueArray[1];
      if (valueArray[4]) {
        this.customField.selectedCurrencySymbol = valueArray[4];
      }
      this.selectedCurrency = this.allowedCurrencies.filter(c => c.id == this.customField.selectedCurrencyId)[0];
      if(this.customField.type == FeildType.Price){
        this.customField.selectedPriceUnit = valueArray[5];
      }
    } else if (this.customField.selectedCurrencyId && this.customField.selectedCurrencyId > 0) {
      this.selectedCurrency = this.allowedCurrencies.filter(c => c.id == this.customField.selectedCurrencyId)[0];
      this.customField.selectedCurrencySymbol = this.selectedCurrency.symobl;
    }
    else {
      this.selectedCurrency = this.allowedCurrencies.filter(c => c.id == this.customField.value)[0];
      if (this.selectedCurrency) {
        this.customField.selectedCurrencyId = this.selectedCurrency.id
        this.customField.selectedCurrencySymbol = this.selectedCurrency.symobl;
      }
      this.customField.value = "";
    }
  }

  changeSelectedCurrency(currency: CurrencyModel) {
    this.selectedCurrency = currency;
    this.customField.selectedCurrencyId = currency.id;
    this.customField.selectedCurrencySymbol = currency.symobl;
    this.onBlur(this.customField);
  }
  getSelectedCurrencySymbol(): string {
    let code = '';
    if (this.allowedCurrencies.length > 0 && this.customField.selectedCurrencyId) {
      const selectedCurrency = this.allowedCurrencies.find(c => c.id == this.customField.selectedCurrencyId);
      if (selectedCurrency) {
        code = selectedCurrency.code;
      }
    }
    return code;
  }
  checkboxChange(lookup: any, customField: CustomFieldModel, isRequired: boolean, controlId) {
    //if (lookup.isSelected) {
    //  lookup.isSelected = false;
    //}
    //else {
    //  lookup.isSelected = true;
    //}
    this.validateCheckbox(customField, isRequired, controlId);
    //form.controls[controlName].updateValueAndValidity();
    this.blur.emit(this.customField.isTigger);
  }

  validateCheckbox(customField: CustomFieldModel, isRequired: boolean, controlId) {
    if (isRequired) {
      let validation: boolean = false;
      for (let item of customField.lookupValues) {
        if (item.isSelected) {
          validation = true;
          break;
        }
      }
      if (!validation) {
        if (controlId)
          document.getElementById(controlId).style.display = 'block';
        this.customValidation = false;
      } else {
        if (controlId)
          document.getElementById(controlId).style.display = 'none';
        this.customValidation = true;
      }

      this.isValid.emit(this.customValidation);
    }
  }
  //   formatBytes(bytes,decimals) {
  //     if(bytes == 0) return '0 Bytes';
  //     var k = 1024,
  //         dm = decimals <= 0 ? 0 : decimals || 2,
  //         sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  //         i = Math.floor(Math.log(bytes) / Math.log(k));
  //     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  //  }
  pushFile(event, customField: CustomFieldModel) {
    if (customField.details == null) {
      customField.details = new ValueDetails;
    }
    if (event.target.files.length > 0) {
      customField.fileContent = event.target.files[0];
      customField.details.name = event.target.files[0].name;
      customField.details.type = event.target.files[0].type;
      customField.details.size = this.customFieldService.bytesToSize(event.target.files[0].size);
      //customField.details.name = this.customFieldService.getFileNameWithoutExtension(customField.details.name);

      if (this.doFileUploadOnChange) {
        this.onFileUpload(event, customField);
      } else {
        this.file = event;
        if (event.target.files && event.target.files.length > 0) {
          customField.value = event.target.files[0].name;
        }
        this.isFileUploaded.emit(!this.customField.isRequired);
        this.isValid.emit(true);
      }
      // if (event.target.files[0].size > customField.max) {
      //   return document.getElementsByClassName("attachmentError")[0].removeAttribute("style");
      // } else if (event.target.files[0].size < customField.max) {
      //   return document.getElementsByClassName("attachmentError")[0].setAttribute("style", "");
      // }

      // if (event.target.files[0].size > customField.max) {
      //   this.isFileUploaded.emit(!this.customField.isRequired);
      //   this.isValid.emit(false);
      // }
    }
    else {
      customField.fileContent = null;
      customField.value = null;
    }
  }

  getBase64(file, customField: CustomFieldModel) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      customField.value = file.name + ',';
      customField.value += reader.result;
    };
    reader.onerror = function (error) {
    };
  }

  maxFileSizeError: boolean = false;
  fileExtensionError: boolean = false;
  fileExtensionDotLengthError: boolean = false;
  public orgSettings: OrgSettings;
  onFileUpload(event, customField: CustomFieldModel) {
    this.uploadImgExceptionMsg = '';
    var fileSize = event.target.files[0].size;
    var inMbs = (fileSize / 1024) / 1024;
    var thisRef = this;
    var definitionKey = this._route.snapshot.paramMap.get('definitionKey');
    this.orgSettingsStore.orgSettings$.subscribe(orgSettings => {
      this.orgSettings = orgSettings;
      if (event && event.target.files && event.target.files.length > 0) {
        customField.value = event.target.files[0].name;
        if (!this.attachmentService.validateFileName(event.target.files[0].name, this.orgSettings?.attachmentNameRegEx)) {
          this.maxFileSizeError = false;
          this.fileExtensionError = false;
          this.fileExtensionDotLengthError = true;
          this.isDisabled = false;
          this.isValid.emit(false);
          this.isFileUploaded.emit(false);
          this.isCallback.emit(false);
          setTimeout(function () {
            thisRef.isValid.emit(false);
            thisRef._applicationRef.tick();
          });
        } else {
          this.fileExtensionDotLengthError = false;
          let maxSize = customField.max == '' ? this.orgSettings?.allowedMaxAttatchmentSize : customField.max;
          if (!maxSize || inMbs <= Number(maxSize)) {
          let fileExtensions = this.customField.allowedValuesCriteria != '' ? this.customField.allowedValuesCriteria : this.orgSettings?.allowedAttatchments;
          if (this.validateFileExtension(event.target.files[0].name,fileExtensions )) {
              this.maxFileSizeError = false;
              this.fileExtensionError = false;
              this.isCallback.emit(true);
              this.isFileUploaded.emit(false);
              this.isValid.emit(true);
              this.isDisabled = true;
              var reader = new FileReader();
              var data: FormData = new FormData();
              data.append('file', event.target.files[0]);
              reader.readAsBinaryString(event.target.files[0]);
  
              reader.onload = function () {
                thisRef.sharedService.uploadAttachmentWithScfid(data, customField.sectionCustomFieldId).subscribe(resp => {
                  if (resp) {
                    customField.value = resp.body.fileId;
                    if (customField.type == FeildType.Image || customField.type == FeildType.Gallery) {
                      customField.details.downloadUrl = resp.body.downloadUrl;
                    }
                  }
                  thisRef.isCallback.emit(false);
                  thisRef.isDisabled = false;
                  thisRef.isFileUploaded.emit(true);
                  thisRef.onBlur(customField);
                }, error => {
                  thisRef.isCallback.emit(false);
                  thisRef.isDisabled = false;
                  thisRef.removeAttachedFile(customField);
                  thisRef.uploadImgExceptionMsg = error.error.exceptionMessage;
                });
              };
              reader.onerror = function (error) {
                thisRef.isCallback.emit(false);
                thisRef.isDisabled = false;
              };
            } else {
              this.maxFileSizeError = false;
              this.fileExtensionError = true;
              this.isDisabled = false;
              this.isValid.emit(false);
              this.isFileUploaded.emit(false);
              this.isCallback.emit(false);
              setTimeout(function () {
                thisRef.isValid.emit(false);
                thisRef._applicationRef.tick();
              })
            }
          } else {
            this.maxFileSizeError = true;
            this.fileExtensionError = false;
            this.isDisabled = false;
            this.isValid.emit(false);
            this.isFileUploaded.emit(false);
            this.isCallback.emit(false);
            setTimeout(function () {
              thisRef.isValid.emit(false);
              thisRef._applicationRef.tick();
            })
          }
        }
  
      }
     });
    var nameRegex = /^[a-zA-Z0-9\-_]*$/;
    // var definitionKey = this.definitionDetails.key;
  }

  private validateFileExtension(name: String, fileExtensions) {
    if (fileExtensions && fileExtensions.trim()) {
      var extensionsArray: string[] = fileExtensions.split(',');
      if (extensionsArray[0] == "-2") {
        var extenssionFilter = extensionsArray.filter(x => x != "-2");
        var errorString = extenssionFilter.toString();
        fileExtensions = errorString;
      }
      var ext = name.substring(name.lastIndexOf('.'));
      var lowerCaseExt = ext.toLowerCase();
      if (extensionsArray.find(x => x == lowerCaseExt) != undefined) {
        // console.log('1', this.customField);

        return true
      }
      // console.log('2', this.customField);
      return false

    }
    else {
      // console.log('3', this.customField);
      return true;
    }
  }

  removeAttachedFile(customField: CustomFieldModel) {
    this.isFileButtonTouched = true;
    this.resetFileInput(customField);
    customField.fileContent = null;
    customField.value = "";
    customField.details.name = "";
    this.isFileUploaded.emit(!customField.isRequired);
    this.isValid.emit(!customField.isRequired);
    this._applicationRef.tick();
    this.blur.emit(this.customField.isTigger);
  }

  resetFileInput(customField) {
    this.inputTypeFile.nativeElement.value = "";
  }

  openFileUploader() {
    this.isFileButtonTouched = true;
    this.inputTypeFile.nativeElement.click();
    //document.getElementById(controlId).click();
    this.inputTypeFile.nativeElement.classList.add('ng-touched');
    //document.getElementById(controlId).className += " ng-touched";
  }

  getIconFileExtention(fileName: string) {
    return this.customFieldService.getIconFileExtention(fileName);
  }

  onRatingChange(event) {
    this.customField.value = event.rating;
  }

  getTooltipText() {
    if (!this.customField) { return '' };
    let options = [];
    let ctrlName = 'customFieldName' + this.customField.id;
    if (this.customField.type == FeildType.SingleSelection) {
      options = this.customField.lookupValues;
    } else if (this.customField.type == FeildType.LookupAddon) {
      options = this.lookUpOptions;
    }
    if (options && options.length) {
      let opt = options.find(opt => opt.id == this.customFieldForm.value[ctrlName])
      if (opt) {
        return this.translationHelperService.translateToLocale(opt.name ?? opt.label)
      }
    }
    return '';
  }

  emitAddNestedField() {
    this.addNestedField.emit(this.customField);
  }

  emitDeleteNestedField() {
    this.deleteNestedField.emit(this.nestedFieldIndex);
  }

  setFocus() {
    this.isFocused = true;
  }

  getFormatedExtenssions() {
    let allowedEx = this.customField.allowedValuesCriteria != '' ?  this.customField.allowedValuesCriteria : this.orgSettings?.allowedAttatchments;
    let exArray = allowedEx.split(',');
    return exArray.join(', ');
  }
  Rating(rating) {
    this.isRating = rating;

  }

  addTag(event: MatChipInputEvent): void {
    if (event.value && event.value.trim() && event.value.trim().length > 0) {
      this.pushTag(event.value);
      event.chipInput!.clear();
      this.autocomplete.closePanel();
    }
  }

  tagSelected(event: MatAutocompleteSelectedEvent) {
    this.pushTag(event.option.value);

  }

  pushTag(tag: string) {
    this.tags.push(tag);

    this.tagInput.nativeElement.value = '';
    this.fc.setValue(null);

    this.customField.value = this.tags.join(',');
    this.udpateValidityTagFc();
  }

  removeTag(tag) {
    this.tags = this.tags.filter(t => t != tag);
    this.customField.value = this.tags.join(',');
    this.udpateValidityTagFc();
    this.autocomplete.closePanel();
  }

  udpateValidityTagFc() {
    if (this.customField.isRequired && this.tags.length == 0) {
      this.fc.setValidators([Validators.required]);
      this.fc.updateValueAndValidity();
      this.isValid.emit(false);
    } else {
      this.fc.setValidators(null);
      this.fc.updateValueAndValidity();
      this.isValid.emit(true);
    }
  }
  udpateValidityhyperlinkTitleFC() {
    if (this.customField.value != '' && this.hyperlinkLabelEnabled) {
      this.customFieldForm.controls['hyperlinkTitle'].setValidators([Validators.required]);
      this.customFieldForm.controls['hyperlinkTitle'].updateValueAndValidity();
      this.isValid.emit(false);
      
    } else {
      this.customFieldForm.controls['hyperlinkTitle'].setValidators(null);
      this.customFieldForm.controls['hyperlinkTitle'].updateValueAndValidity();
      this.isValid.emit(true);
    }
  }
  udpateValidityhyperlinkFC() {
    if ((this.customField.hyperlinkTitle != '' && this.hyperlinkLabelEnabled) || this.hyperlinkRequired) {
      this.customField.isRequired = true;
      this.isValid.emit(false);
    } else if(this.hyperlinkLabelEnabled && !this.hyperlinkRequired) {
      this.customField.isRequired = false;
    }
  }

  loadSystemTags() {
    this.systemTagsLoading = true;
    this._tagService.getTags(this.customField.sectionCustomFieldId).subscribe(tags => {
      this.systemTags = tags;
      this.systemTagsLoading = false;
    }, err => {
      this.systemTagsLoading = false;
    });
  }

  filterSystemTags(text: string) {
    if (!text) {
      this.filteredSystemTags = [];
    } else {
      text = text.trim();
      if (text.length > 0) {
        this.filteredSystemTags = this.systemTags.filter(t => t.name.toLowerCase().includes(text.toLowerCase()));
        if (!this.filteredSystemTags.find(fs => fs.name == text)) {
          this.filteredSystemTags.unshift({ id: 0, name: text });
        }
      }else{
        this.filteredSystemTags = [];
      }
    }
  }

  selectTagForFilter(tag: string){
    if(!this.customField.value){
      this.customField.value = tag;
    }else{
      let valArr: string[] = this.customField.value.split(',');
      if(valArr.includes(tag)){
        valArr = valArr.filter(val => val != tag);
        this.customField.value = valArr.join(',');
      }else{
        this.customField.value += `,${tag}`;
      }
    }
  }

  checkIfTagExists(tag: string) : boolean{
    if(this.customField.value){
      let valArr: string[] = this.customField.value.split(',');
      if(valArr.includes(tag)){
        return true;
      }
    }
    return false;
  }
  singleSelectionDefaultvalue() {
      this.selectedColor = this.customField.lookupValues?.find(option => option.id == this.customField.value);
      // console.log('selectedColor', this.selectedColor);


  }
  onSingleSelectionChange(event) {
    // console.log(event);
      this.selectedColor = this.customField.lookupValues.find(option => option.id == event.value);

  }
  compareChoice(o1, o2): boolean{
    console.log('compare with');
    return o1.label === o2.label;
  }
  openEditor() {
    this.expandEditor = true;
    const dialogRef = this._matDialog.open(EspRichTextEditorComponent, {
      width: '1160px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'confirmation-dialog',
      direction: this._dataSharingService.isRtl ? 'rtl' : 'ltr',
      data: {
        customField: this.customField,
        customFieldForm: this.customFieldForm,
        showHint: this.showHint,
      }
    });
    dialogRef.afterClosed().subscribe( result => {
      this.expandEditor = false;
    });
  }

  opentagableInput() {
    this.showTagableField.emit(true);
  }
  // toggleAllSelection() {
  //   const selectedValues = this.lookupSelect.selected.map(option => option.value);
  //   if (selectedValues.length === this.lookUpOptions.length) {
  //     // Deselect all
  //     this.lookupSelect.options.forEach((item: MatOption) => item.deselect());
  //   } else {
  //     // Select all
  //     this.lookupSelect.options.forEach((item: MatOption) => item.select());
  //   }
  //   this.lookupSelect.close();
  // }
  toggleAllSelection() {
    const valueArray = this.customField.value;
    
    let values: any[] = [];
    if (valueArray.includes(-1)) {
      // Deselect all options
      values = this.lookUpOptionsAll.map((op) => op.id);
      values.push(-1); // Add -1 to the selection
      console.log('values', values);
      
      this.customField.value = values;
    } else {
      this.customField.value = [];
    }
    this.updateTriggerText()
  }
  toggleItem() {
    const valueArray = this.customField.value;

    if (valueArray != 'undefined' && valueArray != '' && valueArray != null){
      if (this.lookUpOptionsAll.length < 21 && valueArray.filter((va) => va != -1).length == this.lookUpOptionsAll.length) {
        if (!valueArray.find((va) => va == -1)) {
          valueArray.push(-1);
          this.customField.value =valueArray;
        }
      } else {
        this.customField.value = valueArray.filter((va) => va != -1);
      }
    }
    this.updateTriggerText();
  }
  disSelectAllSelection() {
    this.customField.value = '';
  }
  public selectNamesList: string[] = [];
  public triggerText: string = '';
  updateTriggerText() {
    this.triggerText = "";
    this.selectNamesList = [];
    if (this.customField.value) {
      if(this.customField.value.includes(-1)) {
        this.triggerText = 'Select All';
      } else {
        let valueArray: any[] = []
        if(this.customField.value.every(element => typeof element === 'string')) {
          valueArray = this.customField.value.map(str => Number(str));
        } else {
          valueArray = this.customField.value;
        }
        const values = valueArray.filter((v) => v != -1);
          this.triggerText = this.lookUpOptionsAll
            .filter((op) => values.includes(op.id))
            .map((opt) => opt.name)
            .join(", ");
        }

      }
    }
    unlockField() {
      this.customField.isReadOnly = false;
    }
  }
