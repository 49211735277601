import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageVerificationDialogComponent } from './image-verification-dialog/image-verification-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ProfilePicErrorDialogComponent } from './profile-pic-error-dialog/profile-pic-error-dialog.component';



@NgModule({
  declarations: [
    ImageVerificationDialogComponent,
    ProfilePicErrorDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule
  ],
  entryComponents: [
    ImageVerificationDialogComponent,
    ProfilePicErrorDialogComponent
  ]
})
export class ImageVerificationModule { }
