<div class="header-container" [dir]="dataSharingService.isRtl ? 'rtl' : 'ltr'">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="header-logo" routerLink="/applications">
    <div fxLayoutAlign="start start" class="esp-short">{{labelService.labels.application_short_name}}</div>
    <div class="esp-desc">{{labelService.labels.application_long_name}}</div>
    <!--<a routerLink="/">
      <img src="assets/images/header/logo.png" alt="Exceeders" />
      <img class="small"
           src="assets/images/header/logo-small.png"
           alt="Exceeders" />
    </a>-->
  </div>
  <button *ngIf="isMobileScreen" #menuTrigger="matMenuTrigger" class="burger-menu-btn hamburger--squeeze"
    [ngClass]="menuTrigger.menuOpen? 'is-active': ''" mat-icon-button [matMenuTriggerFor]="menu">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
  <mat-menu #menu="matMenu" class="burger-menu" [backdropClass]="'burger-menu-backdrop'">
    <button mat-menu-item class="burger-menu-item" routerLink="/applications" routerLinkActive="active"
      (click)="clearFilters()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path class="icon-path" fill="#AAADB8" d="M7,15h7v2H7V15z M7,11h10v2H7V11z M7,7h10v2H7V7z M19,3h-4.2c-0.4-1.2-1.5-2-2.8-2S9.6,1.8,9.2,3H5
      C4.9,3,4.7,3,4.6,3c-0.4,0.1-0.7,0.3-1,0.5C3.4,3.8,3.3,4,3.2,4.2C3.1,4.5,3,4.7,3,5v14c0,0.3,0.1,0.5,0.2,0.8s0.2,0.5,0.4,0.6
      c0.3,0.3,0.6,0.5,1,0.5c0.1,0,0.3,0,0.4,0h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M12,2.8c0.4,0,0.8,0.3,0.8,0.8S12.4,4.2,12,4.2
      s-0.8-0.3-0.8-0.8S11.6,2.8,12,2.8z M19,19H5V5h14V19z" />
      </svg>
      <span>{{labelService.labels?.applications | translateToLocale}}</span>
    </button>

    <button mat-menu-item class="burger-menu-item" routerLink="/records" routerLinkActive="active"
      (click)="clearFilters()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path class="icon-path" fill="#AAADB8" d="M7,15h7v2H7V15z M7,11h10v2H7V11z M7,7h10v2H7V7z M19,3h-4.2c-0.4-1.2-1.5-2-2.8-2S9.6,1.8,9.2,3H5
      C4.9,3,4.7,3,4.6,3c-0.4,0.1-0.7,0.3-1,0.5C3.4,3.8,3.3,4,3.2,4.2C3.1,4.5,3,4.7,3,5v14c0,0.3,0.1,0.5,0.2,0.8s0.2,0.5,0.4,0.6
      c0.3,0.3,0.6,0.5,1,0.5c0.1,0,0.3,0,0.4,0h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M12,2.8c0.4,0,0.8,0.3,0.8,0.8S12.4,4.2,12,4.2
      s-0.8-0.3-0.8-0.8S11.6,2.8,12,2.8z M19,19H5V5h14V19z" />
      </svg>
      <span [translate]="'Common.Records'">Records</span>
    </button>

    <button mat-menu-item 
      class="burger-menu-item" routerLink="/profile" routerLinkActive="active" (click)="clearFilters()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0H24V24H0z" />
          <path class="icon-path" fill="#AAADB8" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M7.1,18.3c0.4-0.9,3-1.8,4.9-1.8
s4.5,0.9,4.9,1.8C15.6,19.4,13.9,20,12,20S8.4,19.4,7.1,18.3z M18.4,16.8c-1.4-1.7-4.9-2.3-6.4-2.3s-4.9,0.6-6.4,2.3
c-1-1.3-1.6-3-1.6-4.8c0-4.4,3.6-8,8-8s8,3.6,8,8C20,13.8,19.4,15.5,18.4,16.8z M12,6c-1.9,0-3.5,1.6-3.5,3.5S10.1,13,12,13
s3.5-1.6,3.5-3.5S13.9,6,12,6z M12,11c-0.8,0-1.5-0.7-1.5-1.5S11.2,8,12,8s1.5,0.7,1.5,1.5S12.8,11,12,11z" />
        </g>
      </svg>
      <span>{{'Common.PROFILE' | translate}}</span>
    </button>
    <button mat-menu-item *ngIf="userInfoService.role == 'Admin'" class="burger-menu-item" routerLink="/applicants"
      routerLinkActive="active" (click)="clearFilters()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0L24 0 24 24 0 24z" />
          <path class="icon-path" fill="#AAADB8" d="M9,13.8c-2.3,0-7,1.2-7,3.5V19h14v-1.8C16,14.9,11.3,13.8,9,13.8z M4.3,17c0.8-0.6,2.9-1.2,4.7-1.2
s3.8,0.7,4.7,1.2H4.3z M9,12c1.9,0,3.5-1.6,3.5-3.5S10.9,5,9,5S5.5,6.6,5.5,8.5S7.1,12,9,12z M9,7c0.8,0,1.5,0.7,1.5,1.5
S9.8,10,9,10S7.5,9.3,7.5,8.5S8.2,7,9,7z M16,13.8c1.2,0.8,2,2,2,3.4V19h4v-1.8C22,15.2,18.5,14.1,16,13.8z M15,12
c1.9,0,3.5-1.6,3.5-3.5S16.9,5,15,5c-0.5,0-1,0.1-1.5,0.3c0.6,0.9,1,2,1,3.2s-0.4,2.3-1,3.1C14,11.9,14.5,12,15,12z" />
        </g>
      </svg>
      <span>{{labelService.labels?.applicants | translateToLocale}}</span>
    </button>
    <button mat-menu-item *ngIf="userInfoService.role == 'Admin'" class="burger-menu-item" routerLink="/definitions"
      routerLinkActive="active" (click)="clearFilters()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0H24V24H0z" />
          <path class="icon-path" fill="#AAADB8" d="M8,16h8v2H8V16z M8,12h8v2H8V12z M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z
M18,20H6V4h7v5h5V20z" />
        </g>
      </svg>
      <span>{{labelService.labels?.definitions | translateToLocale}}</span>
    </button>
    <button mat-menu-item *ngIf="lookupAdonsList.length > 0" class="burger-menu-item" (click)="showLookupAdons($event)">


      <div fxLayout="row" class="full-width">
        <div fxFlex="none" [style.padding-top.px]="6">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fill-rule="evenodd">
              <path d="M0 0L24 0 24 24 0 24z" />
              <path class="icon-path" fill="#AAADB8"
                d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 4h7l5 5v8.58l-1.84-1.84c1.28-1.94 1.07-4.57-.64-6.28C14.55 8.49 13.28 8 12 8c-1.28 0-2.55.49-3.53 1.46-1.95 1.95-1.95 5.11 0 7.05.97.97 2.25 1.46 3.53 1.46.96 0 1.92-.28 2.75-.83L17.6 20H6V4zm8.11 11.1c-.56.56-1.31.88-2.11.88s-1.55-.31-2.11-.88c-.56-.56-.88-1.31-.88-2.11s.31-1.55.88-2.11c.56-.57 1.31-.88 2.11-.88s1.55.31 2.11.88c.56.56.88 1.31.88 2.11s-.31 1.55-.88 2.11z" />
            </g>
          </svg>
        </div>
        <div fxFlex>
          <span [translate]="'Common.Lookup'"></span>
        </div>
        <div fxFlex="none">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
      </div>
    </button>

    <div *ngIf="lookupAdonsList.length > 0 && showAdons" class="submenu-list">
      <button class="burger-menu-item pl-56" *ngFor="let lookupAdon of lookupAdonsList" mat-menu-item
        routerLink="/lookup/{{lookupAdon.id}}" routerLinkActive="active"
        (click)="clearFilters();lookupItemsListFilterService.resetData();">
        {{lookupAdon.name | translateToLocale}}
      </button>
    </div>
    <button mat-menu-item *ngIf="userInfoService.role == 'Admin'" class="burger-menu-item" routerLink="/settings"
      routerLinkActive="active" (click)="clearFilters()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path class="icon-path" fill="#AAADB8" d="M19.4,13c0-0.3,0.1-0.6,0.1-1c0-0.3,0-0.7-0.1-1l2.1-1.6c0.2-0.1,0.2-0.4,0.1-0.6l-2-3.5
C19.6,5.1,19.4,5,19.2,5c-0.1,0-0.1,0-0.2,0l-2.5,1c-0.5-0.4-1.1-0.7-1.7-1l-0.4-2.7C14.5,2.2,14.2,2,14,2h-4C9.8,2,9.5,2.2,9.5,2.4
L9.1,5.1C8.5,5.3,8,5.7,7.4,6.1l-2.5-1C4.9,5,4.8,5,4.8,5C4.6,5,4.4,5.1,4.3,5.3l-2,3.5C2.2,8.9,2.3,9.2,2.5,9.4L4.6,11
c0,0.3-0.1,0.6-0.1,1c0,0.3,0,0.7,0.1,1l-2.1,1.6c-0.2,0.1-0.2,0.4-0.1,0.6l2,3.5C4.4,18.9,4.6,19,4.8,19c0.1,0,0.1,0,0.2,0l2.5-1
c0.5,0.4,1.1,0.7,1.7,1l0.4,2.6c0,0.2,0.2,0.4,0.5,0.4h4c0.2,0,0.5-0.2,0.5-0.4l0.4-2.6c0.6-0.2,1.2-0.6,1.7-1l2.5,1
c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4-0.2l2-3.5c0.1-0.2,0.1-0.5-0.1-0.6L19.4,13z M17.5,11.3c0,0.3,0,0.5,0,0.7c0,0.2,0,0.4,0,0.7
l-0.1,1.1l0.9,0.7l1.1,0.8l-0.7,1.2l-1.3-0.5l-1-0.4l-0.9,0.7c-0.4,0.3-0.8,0.6-1.2,0.7l-1.1,0.4l-0.2,1.1L12.7,20h-1.4l-0.2-1.4
L11,17.5l-1.1-0.4c-0.4-0.2-0.8-0.4-1.2-0.7l-0.9-0.7l-1.1,0.4l-1.3,0.5l-0.7-1.2l1.1-0.8l0.9-0.7l-0.1-1.1c0-0.3-0.1-0.5-0.1-0.7
s0-0.4,0.1-0.7l0.1-1.1L5.8,9.4L4.7,8.6l0.7-1.2l1.3,0.5l1,0.4l0.9-0.7c0.4-0.3,0.8-0.6,1.2-0.7l1.1-0.4l0.2-1.1L11.3,4h1.4l0.2,1.3
L13,6.5l1.1,0.4c0.4,0.2,0.8,0.4,1.2,0.7l0.9,0.7l1.1-0.4l1.3-0.5l0.7,1.2l-1.1,0.9l-0.9,0.7C17.3,10.1,17.5,11.3,17.5,11.3z M12,8
c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,8,12,8z M12,14c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,14,12,14z" />
        </g>
      </svg>
      <span>{{labelService.labels?.settings | translateToLocale}}</span>
    </button>
    <button *ngIf="accountsCount > 1" mat-menu-item class="burger-menu-item"
      (click)="clearFilters();openSwitchOrgDialog()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path class="icon-path" fill="#AAADB8" d="M19.4,13c0-0.3,0.1-0.6,0.1-1c0-0.3,0-0.7-0.1-1l2.1-1.6c0.2-0.1,0.2-0.4,0.1-0.6l-2-3.5
C19.6,5.1,19.4,5,19.2,5c-0.1,0-0.1,0-0.2,0l-2.5,1c-0.5-0.4-1.1-0.7-1.7-1l-0.4-2.7C14.5,2.2,14.2,2,14,2h-4C9.8,2,9.5,2.2,9.5,2.4
L9.1,5.1C8.5,5.3,8,5.7,7.4,6.1l-2.5-1C4.9,5,4.8,5,4.8,5C4.6,5,4.4,5.1,4.3,5.3l-2,3.5C2.2,8.9,2.3,9.2,2.5,9.4L4.6,11
c0,0.3-0.1,0.6-0.1,1c0,0.3,0,0.7,0.1,1l-2.1,1.6c-0.2,0.1-0.2,0.4-0.1,0.6l2,3.5C4.4,18.9,4.6,19,4.8,19c0.1,0,0.1,0,0.2,0l2.5-1
c0.5,0.4,1.1,0.7,1.7,1l0.4,2.6c0,0.2,0.2,0.4,0.5,0.4h4c0.2,0,0.5-0.2,0.5-0.4l0.4-2.6c0.6-0.2,1.2-0.6,1.7-1l2.5,1
c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4-0.2l2-3.5c0.1-0.2,0.1-0.5-0.1-0.6L19.4,13z M17.5,11.3c0,0.3,0,0.5,0,0.7c0,0.2,0,0.4,0,0.7
l-0.1,1.1l0.9,0.7l1.1,0.8l-0.7,1.2l-1.3-0.5l-1-0.4l-0.9,0.7c-0.4,0.3-0.8,0.6-1.2,0.7l-1.1,0.4l-0.2,1.1L12.7,20h-1.4l-0.2-1.4
L11,17.5l-1.1-0.4c-0.4-0.2-0.8-0.4-1.2-0.7l-0.9-0.7l-1.1,0.4l-1.3,0.5l-0.7-1.2l1.1-0.8l0.9-0.7l-0.1-1.1c0-0.3-0.1-0.5-0.1-0.7
s0-0.4,0.1-0.7l0.1-1.1L5.8,9.4L4.7,8.6l0.7-1.2l1.3,0.5l1,0.4l0.9-0.7c0.4-0.3,0.8-0.6,1.2-0.7l1.1-0.4l0.2-1.1L11.3,4h1.4l0.2,1.3
L13,6.5l1.1,0.4c0.4,0.2,0.8,0.4,1.2,0.7l0.9,0.7l1.1-0.4l1.3-0.5l0.7,1.2l-1.1,0.9l-0.9,0.7C17.3,10.1,17.5,11.3,17.5,11.3z M12,8
c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,8,12,8z M12,14c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,14,12,14z" />
        </g>
      </svg>
      <span [translate]="'SWITCH-ORGANIZATION'"></span>
    </button>
    <button mat-menu-item class="burger-menu-item" routerLinkActive="active" (click)="openSwitchLanguageDialog()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path class="icon-path" fill="#AAADB8" d="M19.4,13c0-0.3,0.1-0.6,0.1-1c0-0.3,0-0.7-0.1-1l2.1-1.6c0.2-0.1,0.2-0.4,0.1-0.6l-2-3.5
C19.6,5.1,19.4,5,19.2,5c-0.1,0-0.1,0-0.2,0l-2.5,1c-0.5-0.4-1.1-0.7-1.7-1l-0.4-2.7C14.5,2.2,14.2,2,14,2h-4C9.8,2,9.5,2.2,9.5,2.4
L9.1,5.1C8.5,5.3,8,5.7,7.4,6.1l-2.5-1C4.9,5,4.8,5,4.8,5C4.6,5,4.4,5.1,4.3,5.3l-2,3.5C2.2,8.9,2.3,9.2,2.5,9.4L4.6,11
c0,0.3-0.1,0.6-0.1,1c0,0.3,0,0.7,0.1,1l-2.1,1.6c-0.2,0.1-0.2,0.4-0.1,0.6l2,3.5C4.4,18.9,4.6,19,4.8,19c0.1,0,0.1,0,0.2,0l2.5-1
c0.5,0.4,1.1,0.7,1.7,1l0.4,2.6c0,0.2,0.2,0.4,0.5,0.4h4c0.2,0,0.5-0.2,0.5-0.4l0.4-2.6c0.6-0.2,1.2-0.6,1.7-1l2.5,1
c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4-0.2l2-3.5c0.1-0.2,0.1-0.5-0.1-0.6L19.4,13z M17.5,11.3c0,0.3,0,0.5,0,0.7c0,0.2,0,0.4,0,0.7
l-0.1,1.1l0.9,0.7l1.1,0.8l-0.7,1.2l-1.3-0.5l-1-0.4l-0.9,0.7c-0.4,0.3-0.8,0.6-1.2,0.7l-1.1,0.4l-0.2,1.1L12.7,20h-1.4l-0.2-1.4
L11,17.5l-1.1-0.4c-0.4-0.2-0.8-0.4-1.2-0.7l-0.9-0.7l-1.1,0.4l-1.3,0.5l-0.7-1.2l1.1-0.8l0.9-0.7l-0.1-1.1c0-0.3-0.1-0.5-0.1-0.7
s0-0.4,0.1-0.7l0.1-1.1L5.8,9.4L4.7,8.6l0.7-1.2l1.3,0.5l1,0.4l0.9-0.7c0.4-0.3,0.8-0.6,1.2-0.7l1.1-0.4l0.2-1.1L11.3,4h1.4l0.2,1.3
L13,6.5l1.1,0.4c0.4,0.2,0.8,0.4,1.2,0.7l0.9,0.7l1.1-0.4l1.3-0.5l0.7,1.2l-1.1,0.9l-0.9,0.7C17.3,10.1,17.5,11.3,17.5,11.3z M12,8
c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,8,12,8z M12,14c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,14,12,14z" />
        </g>
      </svg>
      <span>Switch Language</span>
    </button>
    <button mat-menu-item class="burger-menu-item" routerLinkActive="active" (click)="clearFilters();logoutUser()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path class="icon-path" fill="#AAADB8" d="M19.4,13c0-0.3,0.1-0.6,0.1-1c0-0.3,0-0.7-0.1-1l2.1-1.6c0.2-0.1,0.2-0.4,0.1-0.6l-2-3.5
C19.6,5.1,19.4,5,19.2,5c-0.1,0-0.1,0-0.2,0l-2.5,1c-0.5-0.4-1.1-0.7-1.7-1l-0.4-2.7C14.5,2.2,14.2,2,14,2h-4C9.8,2,9.5,2.2,9.5,2.4
L9.1,5.1C8.5,5.3,8,5.7,7.4,6.1l-2.5-1C4.9,5,4.8,5,4.8,5C4.6,5,4.4,5.1,4.3,5.3l-2,3.5C2.2,8.9,2.3,9.2,2.5,9.4L4.6,11
c0,0.3-0.1,0.6-0.1,1c0,0.3,0,0.7,0.1,1l-2.1,1.6c-0.2,0.1-0.2,0.4-0.1,0.6l2,3.5C4.4,18.9,4.6,19,4.8,19c0.1,0,0.1,0,0.2,0l2.5-1
c0.5,0.4,1.1,0.7,1.7,1l0.4,2.6c0,0.2,0.2,0.4,0.5,0.4h4c0.2,0,0.5-0.2,0.5-0.4l0.4-2.6c0.6-0.2,1.2-0.6,1.7-1l2.5,1
c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4-0.2l2-3.5c0.1-0.2,0.1-0.5-0.1-0.6L19.4,13z M17.5,11.3c0,0.3,0,0.5,0,0.7c0,0.2,0,0.4,0,0.7
l-0.1,1.1l0.9,0.7l1.1,0.8l-0.7,1.2l-1.3-0.5l-1-0.4l-0.9,0.7c-0.4,0.3-0.8,0.6-1.2,0.7l-1.1,0.4l-0.2,1.1L12.7,20h-1.4l-0.2-1.4
L11,17.5l-1.1-0.4c-0.4-0.2-0.8-0.4-1.2-0.7l-0.9-0.7l-1.1,0.4l-1.3,0.5l-0.7-1.2l1.1-0.8l0.9-0.7l-0.1-1.1c0-0.3-0.1-0.5-0.1-0.7
s0-0.4,0.1-0.7l0.1-1.1L5.8,9.4L4.7,8.6l0.7-1.2l1.3,0.5l1,0.4l0.9-0.7c0.4-0.3,0.8-0.6,1.2-0.7l1.1-0.4l0.2-1.1L11.3,4h1.4l0.2,1.3
L13,6.5l1.1,0.4c0.4,0.2,0.8,0.4,1.2,0.7l0.9,0.7l1.1-0.4l1.3-0.5l0.7,1.2l-1.1,0.9l-0.9,0.7C17.3,10.1,17.5,11.3,17.5,11.3z M12,8
c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,8,12,8z M12,14c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,14,12,14z" />
        </g>
      </svg>
      <span>Logout</span>
    </button>
  </mat-menu>


  <div *ngIf="!isMobileScreen" class="header-menu">
    <ng-container *ngIf="isLoggedIn">
      <a class="menu-item" routerLink="/applications" routerLinkActive="active" (click)="clearFilters()">
        <div fxLayout="row" fxLayoutGap="4px">
          <div fxFlex="none">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path class="icon-path" fill="#AAADB8" d="M7,15h7v2H7V15z M7,11h10v2H7V11z M7,7h10v2H7V7z M19,3h-4.2c-0.4-1.2-1.5-2-2.8-2S9.6,1.8,9.2,3H5
      C4.9,3,4.7,3,4.6,3c-0.4,0.1-0.7,0.3-1,0.5C3.4,3.8,3.3,4,3.2,4.2C3.1,4.5,3,4.7,3,5v14c0,0.3,0.1,0.5,0.2,0.8s0.2,0.5,0.4,0.6
      c0.3,0.3,0.6,0.5,1,0.5c0.1,0,0.3,0,0.4,0h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M12,2.8c0.4,0,0.8,0.3,0.8,0.8S12.4,4.2,12,4.2
      s-0.8-0.3-0.8-0.8S11.6,2.8,12,2.8z M19,19H5V5h14V19z" />
            </svg>
          </div>
          <div fxFlex="none">
            <span>{{labelService.labels?.applications | translateToLocale}}</span>
          </div>
        </div>
      </a>

      <a class="menu-item" routerLink="/records" routerLinkActive="active" (click)="clearFilters()">
        <div fxLayout="row" fxLayoutGap="4px">
          <div fxFlex="none">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path class="icon-path" fill="#AAADB8" d="M7,15h7v2H7V15z M7,11h10v2H7V11z M7,7h10v2H7V7z M19,3h-4.2c-0.4-1.2-1.5-2-2.8-2S9.6,1.8,9.2,3H5
      C4.9,3,4.7,3,4.6,3c-0.4,0.1-0.7,0.3-1,0.5C3.4,3.8,3.3,4,3.2,4.2C3.1,4.5,3,4.7,3,5v14c0,0.3,0.1,0.5,0.2,0.8s0.2,0.5,0.4,0.6
      c0.3,0.3,0.6,0.5,1,0.5c0.1,0,0.3,0,0.4,0h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M12,2.8c0.4,0,0.8,0.3,0.8,0.8S12.4,4.2,12,4.2
      s-0.8-0.3-0.8-0.8S11.6,2.8,12,2.8z M19,19H5V5h14V19z" />
            </svg>
          </div>
          <div fxFlex="none">
            <span [translate]="'Common.Records'">Records</span>
          </div>
        </div>
      </a>

      <!-- <a *ngIf="userInfoService.role == 'Applicant' || userInfoService.applicantPersonaId > 0" class="menu-item"
        routerLink="/profile" routerLinkActive="active" (click)="clearFilters()">
        <div fxLayout="row" fxLayoutGap="4px">
          <div fxFlex="none">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0H24V24H0z" />
                <path class="icon-path" fill="#AAADB8" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M7.1,18.3c0.4-0.9,3-1.8,4.9-1.8
      s4.5,0.9,4.9,1.8C15.6,19.4,13.9,20,12,20S8.4,19.4,7.1,18.3z M18.4,16.8c-1.4-1.7-4.9-2.3-6.4-2.3s-4.9,0.6-6.4,2.3
      c-1-1.3-1.6-3-1.6-4.8c0-4.4,3.6-8,8-8s8,3.6,8,8C20,13.8,19.4,15.5,18.4,16.8z M12,6c-1.9,0-3.5,1.6-3.5,3.5S10.1,13,12,13
      s3.5-1.6,3.5-3.5S13.9,6,12,6z M12,11c-0.8,0-1.5-0.7-1.5-1.5S11.2,8,12,8s1.5,0.7,1.5,1.5S12.8,11,12,11z" />
              </g>
            </svg>
          </div>
          <div fxFlex="none">
            <span>{{'Common.PROFILE' | translate}}</span>
          </div>
        </div>


      </a> -->

      <a *ngIf="userInfoService.role == 'Admin'" class="menu-item" routerLink="/applicants" routerLinkActive="active"
        (click)="clearFilters()">
        <div fxLayout="row" fxLayoutGap="4px">
          <div fxFlex="none">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0L24 0 24 24 0 24z" />
                <path class="icon-path" fill="#AAADB8"
                  d="M9,13.8c-2.3,0-7,1.2-7,3.5V19h14v-1.8C16,14.9,11.3,13.8,9,13.8z M4.3,17c0.8-0.6,2.9-1.2,4.7-1.2
      s3.8,0.7,4.7,1.2H4.3z M9,12c1.9,0,3.5-1.6,3.5-3.5S10.9,5,9,5S5.5,6.6,5.5,8.5S7.1,12,9,12z M9,7c0.8,0,1.5,0.7,1.5,1.5
      S9.8,10,9,10S7.5,9.3,7.5,8.5S8.2,7,9,7z M16,13.8c1.2,0.8,2,2,2,3.4V19h4v-1.8C22,15.2,18.5,14.1,16,13.8z M15,12
      c1.9,0,3.5-1.6,3.5-3.5S16.9,5,15,5c-0.5,0-1,0.1-1.5,0.3c0.6,0.9,1,2,1,3.2s-0.4,2.3-1,3.1C14,11.9,14.5,12,15,12z" />
              </g>
            </svg>
          </div>
          <div fxFlex="none">
            <span>{{labelService.labels?.applicants | translateToLocale}}</span>
          </div>
        </div>



      </a>

      <a *ngIf="userInfoService.role == 'Admin'" class="menu-item" routerLink="/definitions" routerLinkActive="active"
        (click)="clearFilters()">
        <div fxLayout="row" fxLayoutGap="4px">
          <div fxFlex="none">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0H24V24H0z" />
                <path class="icon-path" fill="#AAADB8" d="M8,16h8v2H8V16z M8,12h8v2H8V12z M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z
       M18,20H6V4h7v5h5V20z" />
              </g>
            </svg>
          </div>
          <div fxFlex="none">
            <span>{{labelService.labels?.definitions | translateToLocale}}</span>
          </div>
        </div>


      </a>
      <a *ngIf="lookupAdonsList.length > 0" class="menu-item" [matMenuTriggerFor]="lookupsMenu"
        (click)="clearFilters()">
        <div fxLayout="row" fxLayoutGap="4px">
          <div fxFlex="none">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0L24 0 24 24 0 24z" />
                <path class="icon-path" fill="#AAADB8"
                  d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 4h7l5 5v8.58l-1.84-1.84c1.28-1.94 1.07-4.57-.64-6.28C14.55 8.49 13.28 8 12 8c-1.28 0-2.55.49-3.53 1.46-1.95 1.95-1.95 5.11 0 7.05.97.97 2.25 1.46 3.53 1.46.96 0 1.92-.28 2.75-.83L17.6 20H6V4zm8.11 11.1c-.56.56-1.31.88-2.11.88s-1.55-.31-2.11-.88c-.56-.56-.88-1.31-.88-2.11s.31-1.55.88-2.11c.56-.57 1.31-.88 2.11-.88s1.55.31 2.11.88c.56.56.88 1.31.88 2.11s-.31 1.55-.88 2.11z" />
              </g>
            </svg>
          </div>
          <div fxFlex="none">
            <div fxFlex="none">
              <span [translate]="'Common.Lookup'"></span>
            </div>
            <div fxFlex="none">
              <span>
                <mat-icon >keyboard_arrow_down</mat-icon>
              </span>
            </div>

          </div>
        </div>
      </a>
      <mat-menu #lookupsMenu="matMenu">
        <button *ngFor="let lookupAdon of lookupAdonsList" mat-menu-item routerLink="/lookup/{{lookupAdon.id}}"
          routerLinkActive="active" (click)="clearFilters();lookupItemsListFilterService.resetData();">
          {{lookupAdon.name | translateToLocale}}
        </button>
      </mat-menu>

      <a *ngIf="userInfoService.role == 'Admin'" class="menu-item" routerLink="/settings" routerLinkActive="active"
        (click)="clearFilters()">
        <div fxLayout="row" fxLayoutGap="4px">
          <div fxFlex="none">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <path class="icon-path" fill="#AAADB8" d="M19.4,13c0-0.3,0.1-0.6,0.1-1c0-0.3,0-0.7-0.1-1l2.1-1.6c0.2-0.1,0.2-0.4,0.1-0.6l-2-3.5
      C19.6,5.1,19.4,5,19.2,5c-0.1,0-0.1,0-0.2,0l-2.5,1c-0.5-0.4-1.1-0.7-1.7-1l-0.4-2.7C14.5,2.2,14.2,2,14,2h-4C9.8,2,9.5,2.2,9.5,2.4
      L9.1,5.1C8.5,5.3,8,5.7,7.4,6.1l-2.5-1C4.9,5,4.8,5,4.8,5C4.6,5,4.4,5.1,4.3,5.3l-2,3.5C2.2,8.9,2.3,9.2,2.5,9.4L4.6,11
      c0,0.3-0.1,0.6-0.1,1c0,0.3,0,0.7,0.1,1l-2.1,1.6c-0.2,0.1-0.2,0.4-0.1,0.6l2,3.5C4.4,18.9,4.6,19,4.8,19c0.1,0,0.1,0,0.2,0l2.5-1
      c0.5,0.4,1.1,0.7,1.7,1l0.4,2.6c0,0.2,0.2,0.4,0.5,0.4h4c0.2,0,0.5-0.2,0.5-0.4l0.4-2.6c0.6-0.2,1.2-0.6,1.7-1l2.5,1
      c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4-0.2l2-3.5c0.1-0.2,0.1-0.5-0.1-0.6L19.4,13z M17.5,11.3c0,0.3,0,0.5,0,0.7c0,0.2,0,0.4,0,0.7
      l-0.1,1.1l0.9,0.7l1.1,0.8l-0.7,1.2l-1.3-0.5l-1-0.4l-0.9,0.7c-0.4,0.3-0.8,0.6-1.2,0.7l-1.1,0.4l-0.2,1.1L12.7,20h-1.4l-0.2-1.4
      L11,17.5l-1.1-0.4c-0.4-0.2-0.8-0.4-1.2-0.7l-0.9-0.7l-1.1,0.4l-1.3,0.5l-0.7-1.2l1.1-0.8l0.9-0.7l-0.1-1.1c0-0.3-0.1-0.5-0.1-0.7
      s0-0.4,0.1-0.7l0.1-1.1L5.8,9.4L4.7,8.6l0.7-1.2l1.3,0.5l1,0.4l0.9-0.7c0.4-0.3,0.8-0.6,1.2-0.7l1.1-0.4l0.2-1.1L11.3,4h1.4l0.2,1.3
      L13,6.5l1.1,0.4c0.4,0.2,0.8,0.4,1.2,0.7l0.9,0.7l1.1-0.4l1.3-0.5l0.7,1.2l-1.1,0.9l-0.9,0.7C17.3,10.1,17.5,11.3,17.5,11.3z M12,8
      c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,8,12,8z M12,14c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,14,12,14z" />
              </g>
            </svg>
          </div>
          <div fxFlex="none">
            <span>{{labelService.labels?.settings | translateToLocale}}</span>
          </div>
        </div>


      </a>

      <a class="menu-item gray" [matMenuTriggerFor]="accountMenu">
        <img
          [src]="userInfoService.imageUrl ? (userInfoService.imageUrl | secure | async) : '../../../assets/images/default_profile_picture_2.png'"
          class="profile-img" />
        <mat-icon class="m-t-5">keyboard_arrow_down</mat-icon>
      </a>
      <mat-menu #accountMenu="matMenu">
        <button *ngIf="accountsCount > 1" mat-menu-item (click)="openSwitchOrgDialog()">
          <span [translate]="'SWITCH-ORGANIZATION'"></span>
        </button>
        <button  mat-menu-item
          routerLink="/profile" routerLinkActive="active" (click)="clearFilters()">
          <span [translate]="'Common.PROFILE'"></span>
        </button>
        <app-switch-language-control></app-switch-language-control>
        <button mat-menu-item (click)="logoutUser()">
          {{'HeaderNavBar.LOGOUT' | translate}}
        </button>
      </mat-menu>
    </ng-container>
  </div>
</div>
