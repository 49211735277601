
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { UserInfo } from '../../models/userinfo';
import { UserInfoService } from '../services/userinfo-service';
import { Location } from '@angular/common';
import { RouterExtService } from '../services/router-ext.service';
import { Roles } from '../enums/roles-enum';
import { DataSharingService } from '../services/data-sharing.service';

@Injectable()
export class CanActivateGuard implements CanActivate, CanActivateChild {
 
  constructor(private router: Router
    , private _userInfoService: UserInfoService
    , private _location: Location
    , private _routerExtService: RouterExtService
    , private _dataSharingService?: DataSharingService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this._userInfoService = new UserInfoService();
    if (sessionStorage.getItem('ngStorage-auth') != null) {
      if (next.data.expectedRole.includes(this._userInfoService.role)) {
        return true;
      } else {
        if (this._userInfoService.role == Roles.Admin) {
          this._dataSharingService.lookupAdons.next(null);
          this.router.navigateByUrl(UserInfoService.adminDefualtPage);
        } else if (this._userInfoService.role == Roles.User) {
          this._dataSharingService.lookupAdons.next(null);
          this.router.navigateByUrl(UserInfoService.adminDefualtPage);
        } else if (this._userInfoService.role == Roles.Applicant) {
          this._dataSharingService.lookupAdons.next(null);
          this.router.navigateByUrl(UserInfoService.applicantDefaultPage);
        }
      }
    } else {
      //debugger;
      //if (window.location.pathname && window.location.pathname != '' && window.location.pathname != '/')
      //  this.router.navigate(['/login'], { queryParams: { redirectUrl: window.location.pathname } });
      //else
      this._routerExtService.setRedirectUrl();
      return false;
    }
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(next, state);
  }

}
