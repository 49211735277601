export enum SectionVisibility {
    SubmissionForm="submissionform",
    ApplicationDetails="details",
    Both="both",
    None="none"
}
export enum SectionPdfView {
    Default="default",
    Horizontal="horizontal",
    Vertical="vertical",
}