import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
// import 'rxjs/add/operator/do';
import { MessageService } from '../common/services/message-service';
import { environment } from '../../environments/environment';
import { SystemSettingsService } from '../common/services/system-settings-service';
import { TranslationHelperService } from '../common/services/translation-helper.service';
import { RouterExtService } from '../common/services/router-ext.service';
import { SnackbarType } from '../common/enums/common-enum';
@Injectable()
export class HttpCutomInterceptor implements HttpInterceptor {
  constructor(
    private _messageService: MessageService, private _routr: Router
    , private _systemSettingsService: SystemSettingsService
    , private _translationHelperService: TranslationHelperService
    , private _routerExtService: RouterExtService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let changedReq: any;
    if (JSON.parse(sessionStorage.getItem('ngStorage-auth'))) {
      changedReq = req.clone({
        // withCredentials: (this._systemSettingsService.systemSettings && this._systemSettingsService.systemSettings.windowsAuth && this._systemSettingsService.systemSettings.windowsAuth.toLowerCase() == 'true'),
        setHeaders: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('ngStorage-auth')).token.accessToken}`,
          'Cache-Control': 'no-store'
        }
      });
    }
    else {
      changedReq = req.clone({
        // withCredentials: (this._systemSettingsService.systemSettings && this._systemSettingsService.systemSettings.windowsAuth && this._systemSettingsService.systemSettings.windowsAuth.toLowerCase() == 'true'),
        setHeaders: {
          'Cache-Control': 'no-store'
        }
      });
    }
    if (!environment.production && changedReq.url.indexOf('i18n') < 0 && changedReq.url.indexOf('images/definition') < 0 && changedReq.url.indexOf('timezones.json') < 0 && changedReq.url.indexOf('assets/config.json') < 0) {
      if(!changedReq.url.startsWith('http')) {
        changedReq.url = environment.baseUrl + changedReq.url;
        changedReq.urlWithParams = environment.baseUrl +  changedReq.urlWithParams;
      }
    }
    return next.handle(changedReq).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
      if (err instanceof HttpErrorResponse && !req.headers.has('ignoreErrorMessage')) {

        if (err.status == 401) {
          this._routerExtService.setRedirectUrl();
          this._messageService.showMessage(this._translationHelperService.getTranslationByKey('SESSION-EXPIRED'), undefined, SnackbarType.Error);
        } else if (err.status != 400 && err.status != 404 && err.status != 409){
          this._messageService.showMessage(this._translationHelperService.getTranslationByKey('Common.ERROR'), undefined, SnackbarType.Error);
        }
        //this.errorHandler.handleError(err);
      }
    }));
  }
}
