<div style="width:100%;height:20px;" *ngIf="applicationId != ''"></div>
<div class="main-content-height newDesign-container" fxFill fxLayout="row" fxLayoutAlign="center stretch"
     [ngClass]="{'noLigin-margin': (!isUserLogin)}">
  <div fxFlex>
    <div fxFlex="30%" fxLayout="column" class="gray-card defination-detail defination-detail-margin"
         fxLayoutAlign="center center" fxShow fxHide.lt-md="true" fxHide.lg="false">
      <div fxLayout="column" fxFlex fxLayoutAlign="center start" fxLayoutGap="70px">
        <div fxLayout="column" fxFlex fxLayoutGap="87px" fxLayoutAlign="center start">
          <div fxFlex class="gray-card-icon">
            <img src="../../../assets/images/ic_step_fill_profile1.svg" alt="">
            <div class="title" fxLayoutAlign="center center" [translate]="'SubmitForm.Complete-Profile'">
            </div>
            <div class="title-description float-right" fxLayoutAlign="center center">
              <div class="des-inner" [translate]="'SubmitForm.Add-details'">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex fxLayoutAlign="center end" class="paginator-control">
        <div class="mat-card newSection-card main-custom-pagination" fxFlex="100%" fxLayout="column">
          <div class="pagination-container">
            <div class="ngx-pagination" fxLayout="row" fxLayoutAlign="start center">
              <button class="pagination-previous" [ngClass]="{'disabled': pageNumer == 0}" (click)="previousSection()"></button>
              <!-- <div class="pagination-previous" [ngClass]="{'disabled': pageNumer == 0}" (click)="previousSection()">
                  </div> -->
              <div class="current" fxLayout="row" fxLayoutGap="20px" fxFlex fxLayoutAlign="center center">
                <div>{{pageNumer + 1}}</div>
                <div class="separator-line totalCount"></div>
                <div class="total-record totalCount">{{submitModel?.sections?.length}}</div>
              </div>
              <button class="pagination-next"
                      [ngClass]="{'disabled' : (((pageNumer + 1) == submitModel?.sections?.length) || !formIsValid )}"
                      (click)="nextSection()" fxLayoutAlign="end center"></button>
              <!-- <div class="pagination-next"
                [ngClass]="{'disabled' : (((pageNumer + 1) == submitModel?.sections?.length) || !formIsValid )}"
                (click)="nextSection()" fxLayoutAlign="end center"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="70%" fxFlex.lt-md="100%" fxLayout="column" class="white-card application-detail">
      <ng-container *ngFor="let section of submitModel.sections  | profileSectionActiveAndVisibleFilter; let i = index">
        <div fxFill class="inner-formContainer" *ngIf="i == pageNumer">
          <div *ngIf="!section.isMultipule" class="inner-formContainer-inner">
            <!--<div class="title mobile-containers" fxLayoutAlign="center center" [translate]="'SubmitForm.Complete-Profile'" fxShow
              fxHide.lt-md="false" fxHide.lg="true"
              style="font-size: 16px; font-weight: bold; margin-top: 0px !important; margin-bottom: 15px !important; text-align: center">
            </div>-->
            <form fxFlex #profileSectionSubmitForm="ngForm" fxLayout="column" fxFlex>
              <div fxLayout="column" fxFlex>
                <div fxLayout="column">
                  <div class="mat-card newSection-card">
                    <div fxLayoutAlign="start center" class="newSection-heading section-heading">
                      {{section.defaultName | translateToLocale }}
                    </div>
                  </div>
                  <div class="form-container">
                    <div *ngFor="let customField of section.fields | customFieldVisibleFilter; let i = index">
                      <app-custom-field [customField]="customField" [applicant-id]="applicantId"
                                        [isDisabled]="customField.isReadOnly" (isValid)="customFieldValid[i] = $event"
                                        (isCallback)="processing = $event" (blur)="onFormFieldBlur(customField)"
                                        [dynamicFieldsValues]="dynamicFieldsValues" [showDynamicSpinner]="showDynamicSpinner"></app-custom-field>
                    </div>
                  </div>
                </div>
                <div class="newButton-containers">
                  <div fxFlex fxLayoutAlign="start end">
                    <button mat-button class="draft-button button-gray-cancel" *ngIf="pageNumer > 0"
                            (click)="previousSection()">
                      {{"Common.BACK"|translate}}
                    </button>
                  </div>
                  <div fxFlex fxLayoutAlign="end end">
                    <button style="margin-right: 0;" mat-button class="add-button-gradiant" [disabled]="!formIsValid"
                            *ngIf="pageNumer < submitModel?.sections?.length" (click)="nextSection()">
                      {{"Common.CONTINUE" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="section.isMultipule" class="inner-formContainer-inner">
            <div class="title mobile-containers" fxLayoutAlign="center center" [translate]="'SubmitForm.Complete-Profile'"
                 fxShow fxHide.lt-md="false" fxHide.lg="true"
                 style="font-size: 16px; font-weight: bold;  margin-top: 0px !important; margin-bottom: 15px !important; text-align: center">
            </div>
            <div class="mat-card newSection-card">
              <div fxLayoutAlign="start center" class="newSection-heading section-heading">
                {{section.defaultName | translateToLocale }}
                <div class="nested-pagination" *ngIf="section.isMultipule && section.nestedSections.length > 1">
                  <div class="pagination-container">
                    <select class="pagination-select" name="nestedSectionPagination-{{sectionIndex}}"
                            [(ngModel)]="sectionNumer" (change)="updateValidations()">
                      <option *ngFor="let nestedSection of section.nestedSections; let index = index" [value]="index">
                        {{index + 1}}
                      </option>
                    </select>
                  </div>
                </div>
                <button class="custom-delete" mat-icon-button
                        (click)="removeNestedSection(section.nestedSections, sectionNumer)"
                        *ngIf="section.isMultipule && section.nestedSections.length > 1">
                  <mat-icon class="custom-icon">
                    <img class="max-width" src="../../../assets/images/delete-icon.svg" />
                  </mat-icon>
                </button>
                <div fxLayoutAlign="end center" fxFlex *ngIf="section.isMultipule && section.fields.length > 0">
                  <button mat-button [disabled]="!formIsValid || isCallback"
                          class="newSection-card mat-card newButton-white cursor-pointer theme-primary-color"
                          (click)="addNestedSection(section.nestedSections, section)">
                    <div class="lessMargin-center" [translate]="'ADD'"></div>
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="" fxLayout="column">
              <div class="" *ngFor="let detail of section.nestedSections; let nestedIndex = index">
                <div *ngIf="nestedIndex == sectionNumer" fxLayout="column" fxLayoutAlign="start start" fxFlex>
                  <form fxFlex #profileSectionSubmitForm="ngForm" style="width: 100%;">
                    <div fxLayout="column">
                      <div class="form-container">
                        <div *ngFor="let customField of detail.fields  | customFieldVisibleFilter; let i = index">
                          <app-custom-field [customField]="customField" [applicant-id]="applicantId"
                                            [isDisabled]="customField.isReadOnly" (isValid)="customFieldValid[i] = $event"
                                            (isCallback)="processing = $event" (blur)="onFormFieldBlur(customField)"
                                            [dynamicFieldsValues]="dynamicFieldsValues" [showDynamicSpinner]="showDynamicSpinner"></app-custom-field>
                        </div>
                      </div>
                      <div class="newButton-containers">
                        <div fxFlex fxLayoutAlign="start end">
                          <button mat-button class="draft-button button-gray-cancel" *ngIf="pageNumer > 0"
                                  (click)="previousSection()">
                            {{"Common.BACK"|translate}}
                          </button>
                        </div>
                        <div fxFlex fxLayoutAlign="end end">
                          <button style="margin-right: 0;" mat-button class="add-button-gradiant"
                                  [disabled]="!formIsValid" *ngIf="pageNumer < submitModel?.sections?.length"
                                  (click)="nextSection()">
                            {{"Common.CONTINUE" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div fxLayoutAlign="center center" class="fixed-paginator"
               [ngClass]="{'no-loginPaginator':(!isUserLogin == true)}">
            <div class="paginator-control mobile-containers" style="width: 100%;" fxShow fxHide.lt-md="false"
                 fxHide.lg="true">
              <div class="mat-card newSection-card main-custom-pagination" style="margin-bottom: 30px;" fxFlex="100%"
                   fxLayout="column">
                <div class="pagination-container">
                  <div class="ngx-pagination" fxLayout="row" fxLayoutAlign="start center">
                    <button class="pagination-previous" [ngClass]="{'disabled': pageNumer == 0}"
                            (click)="previousSection()"></button>
                    <!-- <div class="pagination-previous" [ngClass]="{'disabled': pageNumer == 0}" (click)="previousSection()">
                                </div> -->
                    <div class="current" fxLayout="row" fxLayoutGap="20px" fxFlex fxLayoutAlign="center center">
                      <div>{{pageNumer + 1}}</div>
                      <div class="separator-line totalCount"></div>
                      <div class="total-record totalCount">{{submitModel?.sections?.length}}</div>
                    </div>
                    <button class="pagination-next"
                            [ngClass]="{'disabled' : (((pageNumer + 1) == submitModel?.sections?.length) || !formIsValid )}"
                            (click)="nextSection()" fxLayoutAlign="end center"></button>
                    <!-- <div class="pagination-next"
                              [ngClass]="{'disabled' : (((pageNumer + 1) == submitModel?.sections?.length) || !formIsValid )}"
                              (click)="nextSection()" fxLayoutAlign="end center"></div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
