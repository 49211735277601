import { Injectable } from '@angular/core';
import { CategoryModel } from '../../models/CategoryModel';
import { ApplicationsCatalogViewType } from '../../common/enums/applications-catalog-view-type.enum';
import { ApplicationDefinition } from '../../definitions/models/application-definition';

@Injectable({
  providedIn: 'root'
})
export class AppilcationCatalogStateService {
  state: CatalogStateModel = null;
  reset(){
    this.state = null;
  }
}

export class CatalogStateModel{
  selectedView: ApplicationsCatalogViewType;
  searchText: string;
  optionList: CategoryModel[] = [];
  optionListOrgininal: CategoryModel[] = [];
  applicationDefinations: ApplicationDefinition[] = [];
}
