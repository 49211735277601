import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UserInfoService } from '../../../common/services/userinfo-service';
import { AccountService } from '../../../account/services/account.service';
import { DataSharingService } from '../../../common/services/data-sharing.service';
import { InvitationLinkInformation } from '../../../models/invitation-link-information';
import { SystemSettingsService } from '../../../common/services/system-settings-service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../common/services/message-service';
import { AccountModel } from '../../../models/account.model';
import { OrgAccountsService } from '../services/org-accounts.service';
import { SubSink } from 'subsink';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OrganizationService } from '../../../organization/services/organization.service';
import { SnackbarType } from '../../../common/enums/common-enum';
import _ from 'lodash';

@Component({
  selector: 'app-org-accounts-list',
  templateUrl: './org-accounts-list.component.html',
  styleUrls: ['./org-accounts-list.component.scss']
})
export class OrgAccountsListComponent implements OnInit, OnDestroy {
  searchText = new FormControl('');
  @Output() orgSwitched: EventEmitter<boolean> = new EventEmitter();
  public orgList: any[] = [];
  public showInDialog: boolean = false;
  public orgPersonaList: AccountModel[] = [];
  public AllorgPersonaList: AccountModel[];
  @Input() hasScroll: boolean = false;
  submitApplicationDeeplinkingSettings: InvitationLinkInformation = new InvitationLinkInformation;
  private _subs: SubSink = new SubSink();
  constructor(public userInfoService: UserInfoService
    , private _accountService: AccountService
    , private _orgAccountsService: OrgAccountsService
    , public dataSharingService: DataSharingService
    , public systemSettingsService: SystemSettingsService
    , private activatedRoute: ActivatedRoute
    , private translate: TranslateService
    , private messageService: MessageService,
    private _orgService: OrganizationService,
    private router: Router
  ) {
    this.dataSharingService.invitationLinkInformation.subscribe(resp => {
      this.submitApplicationDeeplinkingSettings = resp;
    });
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
  currentOrganizationId: string;
  public higherParams;
  public higherOrganizationId;
  public higherBaseUrl;
  public espOrganizations;
  public higherVacancy;
  public higherCallFrom;
  public higherGroupId;
  ngOnInit() {
    this.currentOrganizationId = this.userInfoService.organizationId.toString();
    let higherParams = JSON.parse(sessionStorage.getItem('higherParams'));
    this.higherParams = higherParams?.higherInit?.split(':');
    this.higherCallFrom = higherParams?.higherCallFrom;
    console.log('higherParams', this.higherParams);
    if(this.higherParams){
      this.higherBaseUrl = this.higherParams[0];
      this.higherOrganizationId = this.higherParams[1];
      this.espOrganizations = this.higherParams[2];
      if(this.higherParams.length == 4) {
        this.higherGroupId = this.higherParams[3];
      } else {
        this.higherVacancy = this.higherParams[3];
        this.higherGroupId = this.higherParams[4];
      }
    } 
    this.getOrgPersona();
    if (this.userInfoService.orgPersonas) {
      this.orgList = this.userInfoService.orgPersonas;
    } else if (this.userInfoService.personas) {
      this.orgList = this.userInfoService.filterUniqueOrganizationPersonas(this.userInfoService.personas);
    }

    if (this.submitApplicationDeeplinkingSettings.organizationId > 0) {
      this.persoanSelected(this.orgList.filter(x => x.orgId == this.submitApplicationDeeplinkingSettings.organizationId)[0].id);
    }

    this._subs.sink = this.searchText.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(value => {
      this.filteredOrg();
    });
  }
  ngAfterViewInit() {
    // this.definitionFilter$ = this.filterDefinitionsSelectElem._closedStream.subscribe(() => {
    //   ///this.getPage(this.pageNumer);
    // });
  }

  getOrgPersona() {
    this._subs.sink = this._orgAccountsService.userPersonas().subscribe(resp => {
      this.AllorgPersonaList = resp;
      this.orgPersonaList = this.AllorgPersonaList;
      if (this.higherParams?.length == 4 || this.higherParams?.length == 5) {
        this.espOrganizations = this.espOrganizations.split(',');
        this.espOrganizations = this.espOrganizations.map(Number);
        this.orgPersonaList = this.orgPersonaList.filter(o => this.espOrganizations.includes(o.organizationId));
        if(this.orgPersonaList.length == 1) {
          this.persoanSelected(this.orgPersonaList[0].id)
        }
        if(this.orgPersonaList.length < 1){
          this.messageService.showMessage('User is Unauthorized',5000, SnackbarType.Error);
        }
      } else {
        this.orgPersonaList = this.AllorgPersonaList;
      }
    });
  }

  public persoanSelected(personaId) {
    let redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('redirectUrl');
    // if (!this.systemSettingsService.systemSettings.windowsAuth || this.systemSettingsService.systemSettings.windowsAuth.toLocaleLowerCase() != "true") {
      this._accountService.userAuthentication(this.userInfoService.email, "", personaId).subscribe((data: any) => {
        this._orgService.isIdenediLinked = false;
        if(this.higherParams?.length == 4 || this.higherParams?.length == 5) {
          if(this.espOrganizations.length > 0 && this.userInfoService.role == 'Admin') {
            this.userInfoService.setAuthorizationData(data, '/definitions');
            this.orgSwitched.emit(true);
            if(this.higherParams?.length == 5) {
              this._accountService.getVacancyProfileDefinitionId(this.higherOrganizationId, this.higherBaseUrl, this.higherVacancy, this.higherGroupId).subscribe(resp => {
                let definationId = resp;
                // this.userInfoService.setAuthorizationData(data, `/definitions/${definationId}`);
                this.router.navigate([`/definitions/${definationId}`]);
              });
            } else if(this.higherParams?.length == 4 && this.higherCallFrom == "jobOffer") {
              this._accountService.getCandidateJobOfferDefinitionId(this.higherOrganizationId, this.higherBaseUrl, this.higherGroupId).subscribe(resp => {
                let definationId = resp;
                // this.userInfoService.setAuthorizationData(data, `/definitions/${definationId}`);
                this.router.navigate([`/definitions/${definationId}`]);
              });
            } else if(this.higherParams?.length == 4 && this.higherCallFrom == "clientProfile") {
              this._accountService.getClientProfileDefinitionId(this.higherOrganizationId, this.higherBaseUrl, this.higherGroupId).subscribe(resp => {
                let definationId = resp;
                // this.userInfoService.setAuthorizationData(data, `/definitions/${definationId}`);
                this.router.navigate([`/definitions/${definationId}`]);
              });
            } else if(this.higherParams?.length == 4 && this.higherCallFrom == "") {
              this._accountService.getCandidateProfileDefinitionId(this.higherOrganizationId, this.higherBaseUrl, this.higherGroupId).subscribe(resp => {
                let definationId = resp;
                // this.userInfoService.setAuthorizationData(data, `/definitions/${definationId}`);
                this.router.navigate([`/definitions/${definationId}`]);
              });
            }
          } else {
            return false
          }
        } else {
          this._orgService.isIdenediLinked = false;
          if (redirectUrl && redirectUrl != '/')
            this.userInfoService.setAuthorizationData(data, redirectUrl);
          else
            this.userInfoService.setAuthorizationData(data);
  
          this.orgSwitched.emit(true);
        }
      }, (err: HttpErrorResponse) => {
        if (err.status == 400 && err.error.error.indexOf('DeActivated') > -1) {
          this.translate.get(["LOGIN-DEACTIVATED-ERROR"]).subscribe(translations => {
            this.messageService.showMessage(translations['LOGIN-DEACTIVATED-ERROR']);
          });
        }
      });
    // } else {
    //   this._accountService.windowsUserAuthentication(personaId).subscribe((data: any) => {
    //     this.userInfoService.setWindowsAuthorizationDataInStorage(data);
    //     this.orgSwitched.emit(true);
    //   });
    // }
  }
  private filteredOrg() {
      const searchText: string = this.searchText.value.toLocaleLowerCase();
      if (searchText) {
        this.orgPersonaList = this.AllorgPersonaList.filter(u => u.organizationName.toLocaleLowerCase().includes(searchText));
      } else {
        this.orgPersonaList = this.AllorgPersonaList.filter(x => true);
      }
  }

}

