<svg *ngIf="!checked"
     xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <rect width="31" height="31" x=".5" y=".5" fill="#FFF" fill-rule="evenodd" stroke="#AAADB8" rx="15.5" />
</svg>

<svg *ngIf="checked"
     xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g fill="none" fill-rule="evenodd">
    <rect width="32" height="32" fill="#6CB33F" rx="16" />
    <path fill="#FFF" d="M20.078 11.503l-6.488 6.445-1.76-1.75c-.643-.637-1.684-.637-2.327 0-.641.639-.641 1.673 0 2.31l2.924 2.906c.321.319.742.478 1.163.478.42 0 .841-.16 1.163-.478l7.65-7.6c.642-.639.642-1.673 0-2.31-.642-.64-1.683-.64-2.325 0z" />
  </g>
</svg>


