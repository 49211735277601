<div class="main-account-container">
  <app-header-before-login></app-header-before-login>
  <div class="main-account-content">
    <div fxFlex fxLayout="row" fxLayoutAlign="center stretch">
      <div fxLayout="column" fxLayoutAlign="center" class="reset-bg">
        <div class="setpass-dialog bgc-white" ngClass.lt-sm="width100">
          <div class="dialog-top-bar"></div>
          <div class="dialog-content-container setpass-padding" [ngClass]="(dataSharingService.isRtl)?'rtl':'ltr'" [dir]="(dataSharingService.isRtl)?'rtl':'ltr'">
            <div mat-dialog-title class="margin-b30">
              <div fxLayout="row">
                <div fxFlex>
                  <div class="title">
                    {{'SetPassword.HEADING-TITLE' | translate}}
                  </div>
                  <div class="title-description">
                    {{'SetPassword.HEADING-DESCRIPTION' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <form [formGroup]="setPwForm" (ngSubmit)="OnSubmit(Password.value,ConfirmPassword.value)">
              <div mat-dialog-content>
                <div fxLayout="column">
                  <mat-form-field fxFlex>
                    <input matInput #Password [formControl]="password" placeholder="{{'Common.PASSWORD' | translate}}" name="password" class="setpass-input" type="password" passwordToggle required>
                    <mat-error *ngIf="password.invalid">{{getPasswordErrorMessage()}}</mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex>
                    <input matInput #ConfirmPassword [formControl]="confirmpassword" placeholder="{{'Common.CONFIRM-PASSWORD' | translate}}" name="confirmpassword" class="setpass-input" type="password" passwordToggle required>
                    <mat-error>{{getCnfrmPasswordErrorMessage()}}</mat-error>
                  </mat-form-field>
                </div>

                <div *ngIf="setPwForm.hasError('pwMissmatch') && confirmpassword.valid" class="error">
                  {{'SetPassword.PASSWORD-NOT-MATCH' | translate}}
                </div>
              </div>
              <div mat-dialog-actions class="action-btn-margin" fxLayout="column">
                <div fxFlex fxLayoutAlign="end end">
                  <button mat-button class="add-button-gradiant" [disabled]="!setPwForm.valid || isCallBack">{{'Common.SAVE-AND-PROCEED' | translate}}</button>
                </div>
                <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="idenedi-login-container" *ngIf="systemSettingsService.systemSettings.idenediClientId">
                  <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="line-breaker">
                    <div fxFlex="48" class="line"></div>
                    <div class="text">OR</div>
                    <div fxFles fxFlex="48" class="line"></div>
                  </div>
                  <div class="btn-idenedi-login-container" fxFlex>
                    <button fxFlex type="button" mat-button class="btn-idenedi-login" (click)="redirectToIdenediLogin()" [disabled]="isCallBack || showIdenediLoader">
                      <div fxLayout="row" fxLayoutAlign="center center">
                        LOGIN WITH IDENEDI
                        <mat-spinner *ngIf="showIdenediLoader" class="idenedi-spinner" diameter="30" color="white"></mat-spinner>
                      </div>
                    </button>
                  </div>
                  <!--<div class="idenedi-login-link" (click)="redirectToIdenediLogin()">Login with iDenedi</div><mat-spinner *ngIf="showIdenediLoader" class="idenedi-spinner" diameter="30"></mat-spinner>-->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
