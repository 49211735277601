<div fxLayout="column" fxLayoutAlign="center">
  <ng-container [ngTemplateOutlet]="isLongLabel"></ng-container>
  <ng-container [formGroup]="customFieldForm">
    <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex
      floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}"
      [ngClass]="{'ng-invalid mat-form-field-invalid' : customFieldForm.controls[fieldName].touched && (customFieldForm.controls[fieldName].errors?.endDateMissing || customFieldForm.controls[fieldName].errors?.required), 'compressed table-view-field' : showInTable}">
      <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label |
        translateToLocale}} <span *ngIf="customField.isRequired">*</span>
        <ng-container [ngTemplateOutlet]="tempNesterFieldIndex"></ng-container>
      </mat-label>
      <div matPrefix *ngIf="customField.isRealTime">
        <mat-icon style="font-size:22px;color:gray;margin-right:8px;">stars</mat-icon>
      </div>
      <mat-date-range-input [rangePicker]="picker" [min]="customField.minDate" [max]="customField.maxDate" >
        <input [attr.data-type]="customField.type" matStartDate [placeholder]="'Start date' | translate"
          (click)="reOpenCalendar()" (keydown.enter)="reOpenCalendar()" formControlName="start" ngDefaultControl
          [required]="customField.isRequired" readonly [disabled]="customField.isReadOnly">
        <input matEndDate [placeholder]="'End date' | translate" (click)="reOpenCalendar()"
          (keydown.enter)="reOpenCalendar()" formControlName="end" ngDefaultControl [required]="customField.isRequired"
          readonly [disabled]="customField.isReadOnly">
      </mat-date-range-input>
      <!-- <input matInput (click)="reOpenCalendar()" (keydown.enter)="reOpenCalendar()"
    placeholder="{{(fieldOptions.showPlaceholder)?(customField.label | translateToLocale):''}}"
    formControlName="customFieldName{{customField.id}}" ngDefaultControl
    [matDatepicker]="datePickerList" [required]="customField.isRequired" [min]="customField.minDate"
    [max]="customField.maxDate" readonly> -->
      <div matSuffix fxLayout="row" fxLayoutAlign="center center">
        <div *ngIf="customField.value && !customField.isReadOnly">
          <button mat-icon-button>
            <mat-icon [ngClass]="{'disabled': customField.isReadOnly}" class="option-cross" (click)="removeDate();"
              mat-ripple>
              close
            </mat-icon>
          </button>
      
        </div>
        <div>
          <mat-datepicker-toggle [for]="datePickerList" [disabled]="customField.isReadOnly"></mat-datepicker-toggle>
        </div>
        <mat-icon matSuffix style="font-size:22px;color:rgba(0, 0, 0, 0.2);margin-right:8px;" *ngIf="customField.isReadOnly">
          lock</mat-icon>
        <div matSuffix *ngIf="mobileIcons">
          <ng-container [ngTemplateOutlet]="mobilefieldsicon"></ng-container>
        </div>
      </div>
      <mat-date-range-picker #datePickerList></mat-date-range-picker>
      <!-- <mat-datepicker #datePickerList
    [startView]="customField.allowedValuesCriteria == 'yyyy' || customField.allowedValuesCriteria == 'MM/yyyy'? 'multi-year': 'month'"
    (yearSelected)="espDatePickerService.chosenYearHandler(customField, $event, datePickerList)"
    (monthSelected)="espDatePickerService.chosenMonthHandler(customField, $event, datePickerList)"
    (closed)="onCloseDatePicker()"
    [panelClass]="{'esp-month-year-picker': customField.allowedValuesCriteria == 'yyyy' || customField.allowedValuesCriteria == 'MM/yyyy'}">
  </mat-datepicker> -->
    </mat-form-field>
    <ng-container [ngTemplateOutlet]="addBtnForBoxControl"></ng-container>
    <input [(ngModel)]="customField.value" formControlName="customFieldName{{customField.id}}"
      [required]="customField.isRequired" fxHide="true">
  </ng-container>
</div>