import { EventEmitter } from "@angular/core";
import { Component, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, Validators } from "@angular/forms";
import { FeildType } from "../../../common/enums/field-type-enum";
import { SubmissionOption } from "../../../common/enums/submission-option.enum";
import { UserInfoService } from "../../../common/services/userinfo-service";
import { ApplicantNameValue, ChildSubmitterInfoModel } from "../../../models/child-submitter-info.model";
import { NameValue } from "../../../models/name-value.model";
import { CustomFieldModel } from "../../../profile-customization/Model/CustomFieldModel";
import { ApplicantRequestService } from "../../services/applicant-request.service";
import { Submitters } from "../../../models/calculated-api-response.model";

@Component({
  selector: "xcdrs-who-can-submit",
  templateUrl: "./who-can-submit.component.html",
  styleUrls: ["./who-can-submit.component.scss"],
})
export class WhoCanSubmitComponent implements OnInit {
  @Input() definitionId: number = 0;
  @Input() applicationId: number = 0;
  @Input() submissionApplicantsArray: FormArray;
  @Input() notifySubmitters: FormControl;
  @Input() selectAllByDefault: boolean = false;
  @Input() disabled: boolean = false;
  @Output() showInvitationTab: EventEmitter<boolean> = new EventEmitter();
  @Output() assignedToId: EventEmitter<number> = new EventEmitter();
  @Input() notificationEnabled: boolean;
  // @Input() allowedSubmitters: Submitters[];
  // For Invites List type Definitions
  public allowedSubmittersOptions: ApplicantNameValue[] = [];
  @Input() whoToInviteType : string;
  private _allowedSubmitters: Submitters[] = [];
  public preAllowedSubmitters: Submitters[] = [];
  @Input() get allowedSubmitters(): Submitters[] {
    return this._allowedSubmitters
  }
  set allowedSubmitters(allowedSubmitters: Submitters[]) {
    this._allowedSubmitters = allowedSubmitters;
    if (this.preAllowedSubmitters?.length > 0 && JSON.stringify(this.preAllowedSubmitters) == JSON.stringify(allowedSubmitters)) {
      this.preAllowedSubmitters = this.preAllowedSubmitters
    } else {
      this.preAllowedSubmitters = allowedSubmitters;
    }
    if(this.preAllowedSubmitters?.length > 0) {
      this.preAllowedSubmitters.map((x, index) => {
        this.allowedSubmittersOptions.push({ name: x.name, value: x.id, description: x.secondaryName });
      });
    }
  }
  selectedApplicantsIds: any[] = [];
  selectedUserGroupsIds: any[] = [];
  public childSubmitterInfoModel: ChildSubmitterInfoModel[] = [];
  SubmissionOptionEnum = SubmissionOption;
  @Input() applicationStatus: string = '';
  @Input() applicationStatusId: number = 0;
  constructor(
    private _applicantRequestService: ApplicantRequestService,
    private _fb: FormBuilder,
    private _userInfoService: UserInfoService
  ) { }

  ngOnInit(): void {
    this.loadDefWithApplicants();
  }

  loadDefWithApplicants() {
    let req;
    if (this.applicationId && this.applicationStatus != 'Draft') {
      req = this._applicantRequestService.submissionChilDefWithSubmitters(this.definitionId, this.applicationId)
    } else {
      req = this._applicantRequestService
        .chilDefWithApplicants(this.definitionId)
    }

    req.subscribe((resp) => {
      this.submissionApplicantsArray.clear();
      this.childSubmitterInfoModel = resp.body;
      let requesterOnlyChildDefs = this.childSubmitterInfoModel.filter(c => c.submissionOption == SubmissionOption.RequesterOnly);

      if (this.applicationId) {
        this.childSubmitterInfoModel = this.childSubmitterInfoModel.filter(c => !c.isAssign);
        if (this.childSubmitterInfoModel.length == 0 || requesterOnlyChildDefs.length > 0) {
          this.showInvitationTab.emit(false);
          if (this.childSubmitterInfoModel.length == 0) {
            let assigned: any = resp.body[0];
            if (assigned && assigned.applicantIds && assigned.applicantIds.length) {
              let currentUserId = assigned.applicantIds.find(a => a == this._userInfoService.applicantPersonaId)
              if (currentUserId) {
                this.assignedToId.emit(currentUserId)
              }
            }
          }
        } else {
          this.showInvitationTab.emit(true);

        }

      }

      this.childSubmitterInfoModel.forEach(def => {
        // const applicantIds: number[] = def.applicants.map(a => a.id);
        //def.isAssign = true;


        this.submissionApplicantsArray.push(this._fb.group({
          definitionId: [def.definitionId, Validators.required],
          applicants: [def.submissionOption == SubmissionOption.RequesterOnly ? [{ id: -2 }] : [], [Validators.required]],
          subUserGroupLists: [def.submissionOption == SubmissionOption.RequesterOnly ? [{ id: -2 }] : [], (def.isAssign) ? [] : [Validators.required]],
          isAssign: [def.isAssign, [Validators.required]],
        }));
        def.noteField = new CustomFieldModel();
        def.noteField.type = FeildType.Note;
        def.noteField.label =
          `{"en":"Select who can submit for ${def.definitionName}",
        "ar":"${def.definitionName} تحديد من يمكنهم تقديم طلب لأجل",
        "fr":"Select who can submit for ${def.definitionName}"}`;

        if (true) {
          def.applicantsOptions = [];
          def.applicants.map(x => {
            def.applicantsOptions.push({ name: x.name, value: x.id, description: x.secondaryName });
          });
        }
      });
    });
  }

  setAssign(index: number, value: boolean) {
    this.submissionApplicantsArray.controls[index].get('isAssign').setValue(value);
    const subUserGroupControl = this.submissionApplicantsArray.controls[index].get('subUserGroupLists');
    const applicantsControl = this.submissionApplicantsArray.controls[index].get('applicants');
    applicantsControl.setValue([]);
    applicantsControl.setValidators([Validators.required])
    applicantsControl.updateValueAndValidity();
    if (value) {
      subUserGroupControl.setValue([]);
      subUserGroupControl.setValidators([]);
    } else {
      subUserGroupControl.setValidators([Validators.required]);
    }
    subUserGroupControl.updateValueAndValidity();
  }
}
