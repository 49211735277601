import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ContentChange, CustomOption, QuillEditorComponent, QuillModules } from 'ngx-quill';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';


const Quill = require("quill");
const icons = Quill.import("ui/icons");
@Component({
  selector: 'app-esp-quill-editor',
  templateUrl: './esp-quill-editor.component.html',
  styleUrls: ['./esp-quill-editor.component.scss'],
})
export class EspQuillEditorComponent implements OnInit {
  @Input() customField: CustomFieldModel;
  @Input() customFieldForm: FormGroup;
  @Input() showHint: boolean = true;
  @Input() isExpanded: boolean
  @Output() blur = new EventEmitter();
  onBlur(customField: CustomFieldModel) {
    this.blur.emit(customField.isTigger);
  }
  @Output() isValid = new EventEmitter();
  customOptions: CustomOption[];
  quillConfig : QuillModules;
  constructor(){
  }

  ngOnInit(): void {
    this.quillConfig = {
      toolbar:  '.quill-toolbar-custom' + this.customField.sectionCustomFieldId,
    };
    this.customOptions = [
      {
        import: "attributors/style/size",
        whitelist: ["12px", "18px"]
      }
    ];
    icons[
      "bold"
    ] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0H24V24H0z" transform="matrix(1 0 0 -1 0 24)"/>
        <path class="fill-svg-themed" fill="#AAADB8" d="M15.6 11.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H9c-1.105 0-2 .895-2 2v10c0 1.105.895 2 2 2h5.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10.2 7.5H13c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-2.8c-.276 0-.5-.224-.5-.5V8c0-.276.224-.5.5-.5zm3.3 9h-3.3c-.276 0-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5h3.3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"/>
    </g></svg>`;

    icons[
      "italic"
    ] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0H24V24H0z" transform="matrix(1 0 0 -1 0 24)"/>
        <path class="fill-svg-themed" fill="#AAADB8" d="M10 6v1c0 .552.448 1 1 1h1.21l-3.42 8H7c-.552 0-1 .448-1 1v1c0 .552.448 1 1 1h6c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1h-1.21l3.42-8H17c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1h-6c-.552 0-1 .448-1 1z"/>
    </g>
</svg>`;

    icons[
      "underline"
    ] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0L24 0 24 24 0 24z"/>
        <path class="fill-svg-themed" fill="#AAADB8" d="M17.111 18.889c.675 0 1.222.547 1.222 1.222 0 .633-.48 1.153-1.097 1.216l-.125.006H6.89c-.675 0-1.222-.547-1.222-1.222 0-.633.48-1.153 1.097-1.216l.125-.006H17.11zm-1.04-14.222c.73 0 1.33.557 1.399 1.27l.006.134v6.04c0 3.124-2.452 5.667-5.476 5.667-2.952 0-5.36-2.424-5.472-5.445l-.004-.222v-6.04c0-.775.629-1.404 1.405-1.404.73 0 1.33.557 1.398 1.27l.006.134v6.04c0 1.534 1.2 2.778 2.667 2.778 1.413 0 2.577-1.153 2.662-2.609l.005-.169v-6.04c0-.775.629-1.404 1.404-1.404z"/>
    </g></svg>`;
    icons[
      "strike"
    ] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0H24V24H0z" transform="matrix(1 0 0 -1 0 24)"/>
        <path class="fill-svg-themed" fill="#AAADB8" d="M7.24 10.25c-.26-.48-.39-1.03-.39-1.67 0-.61.13-1.16.4-1.67.26-.5.63-.93 1.11-1.29.48-.35 1.05-.63 1.7-.83.66-.19 1.39-.29 2.18-.29.81 0 1.54.11 2.21.34.66.22 1.23.54 1.69.94.47.4.83.88 1.08 1.43.119.261.21.534.274.818.098.44-.179.875-.618.972-.058.013-.117.02-.177.02h-1.377c-.393 0-.735-.272-.824-.655-.018-.076-.037-.14-.058-.195-.09-.27-.24-.49-.44-.68-.2-.19-.45-.33-.75-.44-.3-.1-.66-.16-1.06-.16-.39 0-.74.04-1.03.13-.29.09-.53.21-.72.36-.19.16-.34.34-.44.55-.1.21-.15.43-.15.66 0 .48.25.88.74 1.21.38.25.77.48 1.41.7H7.39c-.05-.08-.11-.17-.15-.25zM21 12.505V12.5c0-.552-.448-1-1-1H4c-.552 0-1 .448-1 1s.448 1 1 1h8.62c.18.07.4.14.55.2.37.17.66.34.87.51.21.17.35.36.43.57.07.2.11.43.11.69 0 .23-.05.45-.14.66-.09.2-.23.38-.42.53-.19.15-.42.26-.71.35-.29.08-.63.13-1.01.13-.43 0-.83-.04-1.18-.13s-.66-.23-.91-.42c-.25-.19-.45-.44-.59-.75-.05-.111-.096-.24-.135-.38l-.015-.058c-.105-.452-.509-.772-.973-.772H7.18c-.402 0-.728.326-.728.728 0 .036.003.072.008.108.04.267.1.521.179.744.16.45.37.85.65 1.21.28.35.6.66.98.92.37.26.78.48 1.22.65.44.17.9.3 1.38.39.48.08.96.13 1.44.13.8 0 1.53-.09 2.18-.28.65-.19 1.21-.45 1.67-.79.46-.34.82-.77 1.07-1.27.25-.5.38-1.07.38-1.71 0-.6-.1-1.14-.31-1.61-.05-.11-.11-.23-.17-.33l2.875-.015c.55-.003.995-.45.995-1z"/>
    </g></svg>`;

    icons[
      "list"
    ] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0H24V24H0z" transform="matrix(1 0 0 -1 0 24)"/>
        <path class="fill-svg-themed" fill="#AAADB8" d="M4.477 16.5c.816 0 1.478.661 1.478 1.477 0 .815-.662 1.476-1.478 1.476S3 18.793 3 17.977 3.661 16.5 4.477 16.5zm16.023.467c.552 0 1 .447 1 1 0 .552-.448 1-1 1H9c-.552 0-1-.448-1-1 0-.553.448-1 1-1h11.5zM4.477 10.5c.816 0 1.478.661 1.478 1.477 0 .815-.662 1.476-1.478 1.476S3 12.793 3 11.977 3.661 10.5 4.477 10.5zm16.023.467c.552 0 1 .447 1 1 0 .552-.448 1-1 1H9c-.552 0-1-.448-1-1 0-.553.448-1 1-1h11.5zM4.477 4.5c.816 0 1.478.661 1.478 1.477 0 .815-.662 1.476-1.478 1.476S3 6.793 3 5.977 3.661 4.5 4.477 4.5zm16.023.467c.552 0 1 .447 1 1 0 .552-.448 1-1 1H9c-.552 0-1-.448-1-1 0-.553.448-1 1-1h11.5z"/>
    </g></svg>`;

    icons[
      "link"
    ] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0H24V24H0z"/>
        <path class="fill-svg-themed" fill="#AAADB8" d="M20.827 14.346l-2.549-2.549c-.648-.648-1.43-.97-2.358-.97-.943 0-1.74.337-2.406 1.018l-.141.144-1.365-1.364.145-.142c.68-.664 1.018-1.466 1.018-2.418 0-.927-.318-1.705-.959-2.346L9.687 3.181C9.047 2.526 8.265 2.2 7.33 2.2c-.927 0-1.705.318-2.346.96L3.18 4.95c-.655.64-.981 1.417-.981 2.344 0 .927.322 1.71.97 2.358L5.72 12.2c.648.648 1.43.97 2.358.97.942 0 1.74-.337 2.405-1.018l.142-.145 1.364 1.365-.144.141c-.68.665-1.019 1.467-1.019 2.418 0 .928.318 1.706.96 2.347l2.525 2.537c.64.656 1.422.982 2.356.982.928 0 1.706-.318 2.347-.96l1.803-1.79c.655-.64.98-1.418.98-2.344 0-.927-.321-1.71-.97-2.358zm-10.2-5.386c-.025-.024-.1-.102-.227-.232-.127-.131-.214-.219-.263-.264-.05-.045-.127-.106-.233-.184-.107-.078-.21-.13-.313-.16-.102-.028-.214-.042-.337-.042-.326 0-.604.114-.833.343-.229.229-.343.506-.343.833 0 .123.014.235.043.337.028.102.081.207.16.313.077.106.138.183.183.233.045.048.133.136.263.263.131.127.209.202.233.227-.245.253-.54.38-.882.38-.335 0-.613-.11-.833-.331l-2.55-2.549c-.228-.229-.342-.507-.342-.833 0-.319.114-.592.343-.821l1.801-1.79c.237-.22.515-.33.833-.33.327 0 .605.114.834.343l2.524 2.536c.229.23.343.507.343.834 0 .342-.135.64-.404.894zm8.675 8.565l-1.801 1.789c-.229.212-.506.319-.833.319-.336 0-.613-.11-.833-.331l-2.525-2.537c-.228-.229-.342-.506-.342-.833 0-.343.134-.641.404-.894.025.024.1.102.227.232.126.131.214.219.263.264.05.045.127.106.233.184.106.078.21.13.312.16.103.028.215.042.337.042.327 0 .605-.114.834-.343.228-.229.343-.506.343-.833 0-.122-.015-.235-.043-.337-.029-.102-.082-.206-.16-.312-.078-.107-.138-.184-.184-.233-.045-.05-.132-.137-.263-.264l-.233-.226c.245-.262.54-.393.882-.393.327 0 .605.115.833.343l2.549 2.55c.229.228.343.506.343.832 0 .319-.114.593-.343.82z"/>
    </g></svg>`;
    if (this.customField.isRequired && !this.customField.isReadOnly && !this.customField.value) {
      this.isValid.emit(false);
      // this.isRating = false;

    } else {
      this.isValid.emit(true);
    }
    if (this.customField.isRequired && !this.customField.label.includes('*')) {
      // this.isValid.emit(false);
      this.customField.label = this.customField.label + '*';
    } else {
      // this.isValid.emit(true);
      this.customField.label = this.customField.label
    }
    if (this.customField.value) { 
      this.customField.value = this.customField.value
    }
    // this.customField.value = "<p><strong><em><u>Rich Text</u></em></strong></p>"
  }

  onTextChange(ev: ContentChange): void {
    this.blur.emit(this.customField.isTigger);
  }

}
