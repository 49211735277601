import { Pipe, PipeTransform } from '@angular/core';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { FeildType } from '../enums/field-type-enum';
import { NoteTypeVisibility } from '../enums/note-type-visibility-enum';
import { DefinitionLookupVisibility, LookupType } from '../enums/lookup-type-enum';

@Pipe({
  name: 'submittedFieldsFilter',
  pure: false
})
export class SubmittedFieldFilter implements PipeTransform {

  transform(fields: CustomFieldModel[]): any {
    if (!fields) {
      return fields;
    }
     return fields.filter(field => {
      if (field.type == FeildType.LookupAddon && field.lookupType == LookupType.Definition && field.isMultipleAllowed == true && field.settings.filter(s => s.key == "multiLookupVisisbityType" && s.value == DefinitionLookupVisibility.ApplicationsTab ).length > 0) {
        return false; 
      } 
      else if(field.allowedValuesCriteria == NoteTypeVisibility.Read || field.allowedValuesCriteria == NoteTypeVisibility.Both) {
        return fields;
        
      } else {
        return field.type != FeildType.Note;
        
      }
    });
  }

}
