import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { CategoryModel } from '../../models/CategoryModel';
import { SystemLanguage } from '../../models/system-language';

@Injectable({
  providedIn: 'root'
})
export class ManageCategoriesService {
  constructor(private http: HttpClient) { }

  addCategory(category: CategoryModel): Observable<number> {
    return this.http.post<any>('/webapi/category/create-category', category);
  }

  updateCategory(category: CategoryModel): Observable<number> {
    return this.http.post<any>('/webapi/category/update-category', category);
  }

  getCategories(): Observable<HttpResponse<CategoryModel[]>> {
    return this.http.get<CategoryModel[]>('/webapi/category/', { observe: 'response' });
  }

  getCategoryByCurrentLocale(categories: CategoryModel[], selectedCategory: string, locale: SystemLanguage): CategoryModel {
    console.log(selectedCategory);
    return categories.find(cat => {
      const categoryJson = JSON.parse(cat.name);
      return categoryJson[locale.locale].toLocaleLowerCase() == JSON.parse(selectedCategory)[locale.locale].toLocaleLowerCase()
    });
  }

  addCategoryQuery(condition: any, categoryId: number): Observable<number> {
    return this.http.post<any>('/webapi/category/query/' + categoryId, condition);
  }

  removeCategoryQuery(categoryId: number): Observable<number> {
    return this.http.post<any>('/webapi/category/query/' + categoryId, null);
  }

  categoryOrder(ids: number[]): Observable<boolean> {
    return this.http.post<any>('/webapi/category/order', ids);
  }

}

