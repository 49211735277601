import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[currencyValidator][formControlName],[currencyValidator][formControl],[currencyValidator][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CurrencyValidatorDirective, multi: true }]
})
export class CurrencyValidatorDirective implements Validator {
    private _selectedCurrencyId: number;
    private _onChange: () => void;

  @Input()
  get selectedCurrencyId(): number{
    return this._selectedCurrencyId;
  }

  set selectedCurrencyId(value: number) {
    this._selectedCurrencyId = value;
    if (this._onChange) this._onChange();
  }

  validate(c: FormControl): { [key: string]: any } | null {
    return currencyValidator(this.selectedCurrencyId)(c);
  }

  //register validate function to call if input param changes otherwise validation will not trigger on input change
  registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
}

export function currencyValidator(selectedCurrencyId: number): ValidatorFn {
  return (c: FormControl): { [key: string]: any } | null => {
    let v = c.value;
    if(!v){
        return null;
    }
    else if((Number(v) > 0 || Number(v) == 0) && selectedCurrencyId > 0){
        return null; 
    } else{
        return { "currency": true }; 
    }
  }
}
