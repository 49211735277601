import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { CurrencyModel } from '../../models/currency-model';
import { LookupAdonsModel, LookupAddonsItemModel, LookupAddonsItemPaginationModel } from '../../models/lookup-ad-ons-model';
import { CustomFieldModel, LookUpOptions, RollUpOptions, LookUpsOnDefinition } from '../../profile-customization/Model/CustomFieldModel';
import { CustomFieldValueModel } from '../../models/custom-field-value-model';
import { CustomFieldService } from '../../common/services/custom-field.service';
import { DatabaseModel } from '../../models/database-mapper/database-model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LookupAdonsService {
  constructor(private http: HttpClient, private translate: TranslateService) { }

  createLookupAdon(lookupAdon: LookupAdonsModel): Observable<any> {
    return this.http.post<any>('/WebApi/lookup', lookupAdon);
  }

  updateLookupAdon(lookupAdon: LookupAdonsModel): Observable<any> {
    return this.http.put<any>('/WebApi/lookup', lookupAdon);
  }

  getLookupAdonsList(): Observable<LookupAdonsModel[]> {
    return this.http.get<any>('/WebApi/lookup');
  }

  getLookupAdonsListInfo(): Observable<LookupAdonsModel[]> {
    return this.http.get<any>('/WebApi/lookup/info/list');
  }

  getLookupAdon(id: number): Observable<LookupAdonsModel> {
    return this.http.get<any>('/WebApi/lookup/' + id);
  }

  toggleVisibility(lookupAdon: LookupAdonsModel): Observable<any> {
    return this.http.put<any>('/WebApi/lookup/Visibility', lookupAdon);
  }

  saveLookupTemplate(lookupAdon: LookupAdonsModel): Observable<any> {
    return this.http.put<any>('/WebApi/lookup/save-template', lookupAdon);
  }

  saveLookupItem(lookupAdon: LookupAdonsModel, isUpdate: boolean): Observable<any> {
    let lookupAddonItem: LookupAddonsItemModel = new LookupAddonsItemModel();
    lookupAddonItem.id = isUpdate ? lookupAdon.lookupItemId : 0;
    lookupAddonItem.lookup = lookupAdon;
    lookupAddonItem.lookup.id = lookupAdon.lookupId;
    //lookupAddonItem.lookup.id = lookupTemplateId;
    lookupAddonItem.values = CustomFieldService.mapPostValueModel(lookupAdon.form.sections[0].fields, lookupAdon.form.sections[0].id);
    return this.http.post<any>('/WebApi/lookupitem', lookupAddonItem);
  }

  getDynamicValues(lookupAdon: LookupAdonsModel) {
    let lookupAddonItem: LookupAddonsItemModel = new LookupAddonsItemModel();
    lookupAddonItem.id = lookupAdon.lookupItemId ? lookupAdon.lookupItemId : 0;
    lookupAddonItem.lookup = lookupAdon;
    lookupAddonItem.lookup.id = lookupAdon.lookupId;
    lookupAddonItem.values = CustomFieldService.mapPostValueModel(lookupAdon.form.sections[0].fields, lookupAdon.form.sections[0].id);
    return this.http.post<any>('/WebApi/lookupitem/updateFiledValues', lookupAddonItem);
  }

  syncLookupItemsList(LookupId: number, lookupAdonItemsList: LookupAddonsItemModel[]): Observable<any> {
    return this.http.post<any>('/WebApi/lookupitem/InsertUpdate/' + LookupId, lookupAdonItemsList);
  }

  filterLookUp(LookupId: number, definitionId: number, lookUpsOnDefinition: LookUpsOnDefinition[]): Observable<any> {
    return this.http.post<any>('/WebApi/lookup/getRelatedLookups/' + LookupId + '/' + definitionId, lookUpsOnDefinition);
  }

  getLookupItems(lookupAddonsItemPagination: LookupAddonsItemPaginationModel, locale = this.translate.currentLang): Observable<any> {
    return this.http.post<any>('/WebApi/lookupitem/Items', lookupAddonsItemPagination, { headers: { 'locale': locale } });
  }

  getLookupItemsCount(lookupId: number, applicantId: number = 0): Observable<any> {
    return this.http.get<any>('/WebApi/lookupitem/count/' + lookupId);
  }

  getApplicantWiseLookupItems(applicantId: number, lookupAddOnId: number): Observable<LookupAdonsModel[]> {
    return this.http.get<any>('/WebApi/lookupitem/' + lookupAddOnId + '/' + applicantId);
  }

  getLookupItemDetail(itemId: number): Observable<any> {
    return this.http.get<any>('/WebApi/lookupitem/Item/' + itemId);
  }

  getLookupAdonsInfoList(): Observable<LookupAdonsModel[]> {
    return this.http.get<any>('/WebApi/lookup/info/list');
  }

  getRollupOptions(lookupId: number): Observable<RollUpOptions> {
    return this.http.get<any>('/WebApi/lookup/usedlookupsbyid/' + lookupId + '/0');
  }

  getLookupItemOptions(lookupId: number, applicantId: number = 0, definitionKey: string = null, forLookupId: number = 0, lookupType: string, SCFId: string = '0'): Observable<LookUpOptions[]> {
    if (!definitionKey) {
      return this.http.get<any>('/webapi/lookupitem/list/' + lookupId + '/' + applicantId + '?forLookupId=' + forLookupId + '&type=' + lookupType + '&scfid='+ SCFId, { headers: {locale : this.translate.currentLang}});
    } else {
      return this.http.get<any>('/webapi/lookupitem/list/' + lookupId + '/' + 'definitionKey/' + definitionKey, { headers: {locale : this.translate.currentLang}});
    }
  }
  getFieldsForStatus(definitionId: number): Observable<HttpResponse<any>>{
    return this.http.get<any>(`/webapi/applicationstatuses/forDefinition/${definitionId}`, { observe: 'response' });
  }

  getImportProfile(lookupId: number): Observable<DatabaseModel> {
    return this.http.get<any>('/webApi/lookup/importprofile/' + lookupId);
  }

  mapLookupItems(lookuptemplate: LookupAdonsModel, lookupItems: LookupAddonsItemModel[]): LookupAdonsModel[] {
    // console.log('===');
    // console.log(lookuptemplate);
    // console.log('====');
    // console.log('====');
    // console.log(lookupItems);
    // console.log('====');

    let lookupAddonsItems: LookupAdonsModel[] = [];
    lookupItems.forEach(function (lookupItem) {
      let lookupAddonsItem: LookupAdonsModel = JSON.parse(JSON.stringify(lookuptemplate));
      lookupAddonsItem.id = lookupItem.id;
      lookupAddonsItem.lookupItemId = lookupItem.id;
      lookupAddonsItem.form.sections[0].fields = CustomFieldService.mapGetValueModel(lookupAddonsItem.form.sections[0].fields, lookupItem.values, lookuptemplate.titleCustomFieldId);
      lookupAddonsItem.isVisible = lookupItem.isVisible;
      lookupAddonsItem.formSectionValues = lookupItem.formSectionValues;
      lookupAddonsItems.push(lookupAddonsItem);
    });

    return lookupAddonsItems;
  }

  toggleLookupItemVisibility(lookupAddonItem: LookupAddonsItemModel): Observable<boolean> {
    return this.http.put<any>('/WebApi/lookupitem/Visibility', lookupAddonItem);
  }
}

