export class EmailVerificationRequest {
    contact:string;
    reference:string;
    type:string;
    
    isVerificationAttempted:boolean;
    isVerificationAttemptSucceeded:boolean;
    status: string;
  }
  export class EmailVerifyObj {
    contact:string;
    code:string;
    reference:string;
    type:string;
  }