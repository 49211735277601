import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { CustomFieldService } from '../../../common/services/custom-field.service';
import { LabelService } from '../../../common/services/label-service';
import { EndDateRangeValidator } from '../../../common/validators/end-date-range.validator';
import { CustomFieldModel } from '../../../profile-customization/Model/CustomFieldModel';
import { EspDatePickerService } from '../esp-date-format-service/esp-date-picker.service';

@Component({
  selector: 'xcdrs-esp-date-range-picker',
  templateUrl: './esp-date-range-picker.component.html',
  styleUrls: ['../esp-date-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
})
export class EspDateRangePickerComponent implements OnInit, AfterViewInit {
  @Input() customFieldForm: FormGroup;
  @Input() customField: any;
  @Input() fieldOptions: any = { floatLabel: 'auto', showPlaceholder: true };
  @Input() showHint: boolean = true;
  @Input() nestedFieldIndex: number = 0;
  @Input() nestedFieldsCount: number;
  private _isDisabled: boolean = false;
  @Input() get isDisabled(): boolean {
    return this._isDisabled
  }
  @Input() addBtnForBoxControl: TemplateRef<any>;
  @Input() isLongLabel: TemplateRef<any>;
  @Input() tempNesterFieldIndex: TemplateRef<any>;
  @Input() mobilefieldsicon: TemplateRef<any>;
  @Input() showInTable: boolean = false;
  public isFocused: boolean = false;
  public mobileIcons: boolean = false;
  @Output() blur = new EventEmitter();
  onBlur(customField: CustomFieldModel) {
    this.blur.emit(customField.isTigger);
  }
  @ViewChild('datePickerList', { static: false }) picker;
  fieldName: string;
  constructor(
    public labelService: LabelService
    , public translate: TranslateService
    , public customFieldService: CustomFieldService
    , public espDatePickerService: EspDatePickerService
    , private _adapter: DateAdapter<any>
    , @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {

  }
  ngAfterViewInit(): void {
    this.customFieldForm.valueChanges.subscribe(values => {
      console.log(values);
      if(values.start && values.end){
        this.customField.value = CustomFieldService.parseDateForPost(values.start) + '&' + CustomFieldService.parseDateForPost(values.end)
      }else{
        if(values.start && !values.end){
          this.customFieldForm.controls[this.fieldName].setErrors({'endDateMissing': true});
          this.customField.valid = false;
        }
        this.customField.value = '';
      }
      this.customFieldForm.get(this.fieldName).markAsTouched();
    });
  }


  removeDate() {
    this.customFieldForm.patchValue({
      start: '',
      end: ''
    });
    this.customFieldForm.get(this.fieldName).markAsTouched();
    this.espDatePickerService.removeDate(this.customField);
    this.onBlur(this.customField);
  }

  onCloseDatePicker() {
    if(this.espDatePickerService.onCloseDatePicker(this.customField)) {
      this.blur.emit(this.customField.isTigger);
    }
  }
  reOpenCalendar() {
    let self = this;
    setTimeout(
      () => {
        self.picker.open();
      },
      50
    );
  }
  ngOnInit(): void {
    let startDate = '';
    let endDate = '';
    if(this.customField.value){
      let dates = this.customField.value.split('&');
      if(dates && dates.length > 1){
        startDate = dates[0];
        endDate = dates[1];
      }
    }
    this.customFieldForm.addControl('start', new FormControl(startDate, this.customField.isRequired ? [Validators.required] : []));
    this.customFieldForm.addControl('end', new FormControl(endDate, this.customField.isRequired ? [Validators.required] : []));
    this.customFieldForm.setValidators(EndDateRangeValidator);
    this.fieldName = "customFieldName" + this.customField.id;

    this.mobileIcons = (((this.customField.hint && this.showHint && this.customField.isMultipleAllowed) || (this.customField.isMultipleAllowed || this.nestedFieldsCount > 1)) || (this.customField.hint && this.showHint && !this.customField.isMultipleAllowed));

    if (this.customField.value) {
      this.customField.value = this.customField.value;
    }
    if (this.customField.isReadOnly) {
      this.customFieldForm.get('start').disable();
      this.customFieldForm.get('end').disable();
    } else {
      this.customFieldForm.get('start').enable();
      this.customFieldForm.get('end').enable();
    }
    
    if(this.translate.currentLang == 'fr') {
      this._locale = 'fr';
      this._adapter.setLocale(this._locale);
    } else {
      this._locale = 'en';
      this._adapter.setLocale(this._locale);
    }
  }

}
