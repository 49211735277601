
export enum FeildType {
  All = 0,
  Text = 1,
  MultiLine = 2,
  Number = 3,
  Date = 4,
  SingleSelection = 5,
  MultiSelection = 6,
  Attachment = 7,
  DateTime = 8,
  Rating = 9, //remove this and check dependency
  Email = 10,
  Money = 11,
  Applicant = 12,
  LookupAddon = 13,
  SubApplication = 14,
  Hyperlink = 15,
  PhoneNumber = 16,
  RollUp = 17,
  SameLookupRollup = 100, //for frontend rollup use
  Calculated = 18,
  Mapped = 19, // RealTime
  Note = 20,
  Aggregator = 21,
  MappedEditable = 1019,
  DateRange = 22,
  Image = 23,
  Map = 24,
  Tags = 25,
  Price = 26,
  Status = 101,
  Gallery = 27,
  RichText = 28,
  Password = 29,
  Static = -1,
}

export const FieldTypes: any[] = [
  { label: 'Short Text', value: FeildType.Text, description: 'Good for short answers, like names and etc.', image: 'assets/images/field-types/short-text.svg' },
  { label: 'Long Text', value: FeildType.MultiLine, description: 'Good for long answers, like descriptions and reasons.', image: 'assets/images/field-types/long-text.svg' },
  { label: 'Email', value: FeildType.Email, description: '"someone@email.com".', image: 'assets/images/field-types/email.svg' },
  { label: 'Password', value: FeildType.Password, description: '', image: 'assets/images/field-types/short-text.svg' },
  { label: 'Phone Number', value: FeildType.PhoneNumber, description: '+971-XX-XXXXXXX', image: 'assets/images/field-types/phone.svg' },
  { label: 'Number', value: FeildType.Number, description: 'Good for quantity. Using numbers only is mandatory.', image: 'assets/images/field-types/number.svg' },
  { label: 'Date', value: FeildType.Date, description: 'Date picker, for Start Date, Joining Date, Closing Date, etc.', image: 'assets/images/field-types/date.svg' },
  { label: 'Date and Time', value: FeildType.DateTime, description: 'DateTime picker, for Start Date, Joining Date, Closing Date, etc.', image: 'assets/images/field-types/date.svg' },
  { label: 'Date Range', value: FeildType.DateRange, description: 'Date range picker, for Start Date and End Date.', image: 'assets/images/field-types/date.svg' },
  { label: 'Single Selection', value: FeildType.SingleSelection, description: 'Short list of options for user to select one from.', image: 'assets/images/field-types/single-selection.svg' },
  { label: 'Multiple Selection', value: FeildType.MultiSelection, description: 'Short list of options for user to select one or more.', image: 'assets/images/field-types/multi-selection.svg' },
  { label: 'Attachment', value: FeildType.Attachment, description: 'Good for attaching documents, photos, etc..', image: 'assets/images/field-types/attachment.svg' },
  { label: 'Image', value: FeildType.Image, description: 'Good for photos and previews', image: 'assets/images/field-types/attachment.svg' },
  { label: 'Rating', value: FeildType.Rating, description: 'Good for evaluations, feedbacks, grages, etc.', image: 'assets/images/field-types/rating.svg' },
  { label: 'Currency', value: FeildType.Money, description: 'Good for prices, amount of money with selection of currency.', image: 'assets/images/field-types/currency.svg' },
  { label: 'Price', value: FeildType.Price, description: 'Good for prices with unit, amount of money with selection of currency.', image: 'assets/images/field-types/currency.svg' },
  { label: 'Hyperlink', value: FeildType.Hyperlink, description: 'Good for external web links.', image: 'assets/images/field-types/hyperlink.svg' },
  { label: 'Lookup', value: FeildType.LookupAddon, description: 'It is a dropdown with records from selected data list.', image: 'assets/images/field-types/lookup.svg' },
  { label: 'Calculated Field', value: FeildType.Calculated, description: 'It is a dropdown with records from selected data list.', image: 'assets/images/field-types/lookup.svg' },
  { label: 'Note', value: FeildType.Note, description: '', image: 'assets/images/field-types/lookup.svg' },
  { label: 'Aggregator', value: FeildType.Aggregator, description: '', image: 'assets/images/field-types/lookup.svg' },
  { label: 'Gallery', value: FeildType.Gallery, description: 'Good for photos and previews', image: 'assets/images/field-types/attachment.svg' },
  { label: 'Rollup', value: FeildType.RollUp, description: '', image: 'assets/images/field-types/lookup.svg' },
  { label: 'Location', value: FeildType.Map, description: 'Good for getting exact location using google maps', image: 'assets/images/field-types/lookup.svg' },
  { label: 'Tags', value: FeildType.Tags, description: 'Good for adding tags for additional information', image: 'assets/images/field-types/lookup.svg' },
  { label: 'Rich Text', value: FeildType.RichText, description: '', image: 'assets/images/field-types/short-text.svg' },
  
];
