import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './account/login/login.component';
import { InvalidLinkComponent } from './account/invalid-link/invalid-link.component';
import { ExpiredLinkComponent } from './account/expired-link/expired-link.component';
import { ApplicantRequestListComponent } from './applicant-request/applicant-request-list/applicant-request-list.component';

import { CanDeactivateGuard } from './common/guard/can-deactivate-guard.service';
import { CanActivateGuard } from './common/guard/can-activate-guard.service';
import { CustomPreloadingStrategy } from './common/services/custom-preloading-service';
import { Roles } from './common/enums/roles-enum'
import { RegisterComponent } from './account/register/register.component';
//import { ManageCategoriesComponent } from './settings/manage-categories/manage-categories.component';

//canDeactivate: [CanDeactivateGuard], add in route
const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full', data: { title: '', pageTitle: '' } },
  { path: 'login', component: LoginComponent, data: { title: 'Login', pageTitle: 'Login' } },
  { path: 'login/:email', component: LoginComponent, data: { title: 'Login', pageTitle: 'Login' } },
  { path: 'register', component: RegisterComponent, data: { title: 'Register', pageTitle: 'Register' } },
  { path: 'expired', component: ExpiredLinkComponent, data: { title: 'Expired Link', pageTitle: 'Expired Link' } },

  { path: 'request', loadChildren: () => import('../app/applicant-request/applicant-request.module').then(m => m.ApplicantRequestModule) },
  { path: 'application', loadChildren: () => import('../app/application/application.module').then(m => m.ApplicationModule) },
  { path: 'applications', loadChildren: () => import('../app/application/application.module').then(m => m.ApplicationModule) },
  { path: 'records', loadChildren: () => import('../app/record-application/record-application.module').then(m => m.RecordApplicationModule) },
  { path: 'definitions-old', loadChildren: () => import('../app/definitions/definitions.module').then(m => m.DefinitionsModule) },
  { path: 'definitions', loadChildren: () => import('../app/definition/definition.module').then(m => m.DefinitionModule) },
  { path: 'applicants', loadChildren: () => import('../app/applicant/applicant.module').then(m => m.ApplicantModule) },
  { path: 'esp-settings', loadChildren: () => import('../app/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [CanActivateGuard], data: { expectedRole: Roles.Admin }  },
  { path: 'dashboard', loadChildren: () => import('../app/customization/customization.module').then(m => m.CustomizationModule) },
  { path: 'lookup', loadChildren: () => import('../app/lookup-ad-ons-items/lookup-ad-ons-items.module').then(m => m.LookupAdOnsItemsModule) },
  { path: 'profile', loadChildren: () => import('./applicant-profile/applicant-profile.module').then(m => m.ApplicantProfileModule)},
  { path: 'iframe', loadChildren: () => import('./iframe/iframe.module').then(m => m.IframeModule) },
  { path: 'invalidlink', component: InvalidLinkComponent, data: { title: 'Invalid Link', pageTitle: 'Invalid Link' } },
  { path: 'settings', loadChildren: () => import('./esp-settings/esp-settings.module').then(m => m.EspSettingsModule),
    canActivate: [CanActivateGuard], data: { expectedRole: Roles.Admin } },
  //{ path: '**', redirectTo: 'invalidlink', pathMatch: 'full' }
];
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes , {
    preloadingStrategy: CustomPreloadingStrategy,
    onSameUrlNavigation: 'reload'
})],
  providers: [CanDeactivateGuard, CustomPreloadingStrategy]
})
export class AppRoutingModule {
}
