<div class="main-account-container">
  <div>
    <app-header-before-login></app-header-before-login>
    <div class="main-account-content">
      <div fxFlex fxLayout="row" fxLayoutAlign="center stretch">
        <div fxLayout="column" fxLayoutAlign="center" class="login-bg">
          <div class="login-dialog" ngClass.lt-sm="width100">
            <div class="dialog-top-bar"></div>
            <div class="dialog-content-container bgc-white login-border-bottom login-padding">
              <div mat-dialog-title>
                <div fxLayout="row">
                  <div fxFlex>
                    <div class="title">
                      {{'Logout.HEADING' | translate}}
                    </div>
                    <div class="title-description">
                      {{'Logout.DESCRIPTION' | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
