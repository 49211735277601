import { Component, OnInit, Input, Output, EventEmitter, IterableDiffers } from '@angular/core';
import { SharedService } from '../shared.service';
import { ApplicantDetailsHelperService } from '../../common/services/applicant-details-helper.service';
import { CustomizationSettingsSubmitModel, CustomizationSettingsField, CustomizationSettingsSectionModel, FormSectionModel } from '../../models/CustomizationSettingsSubmitModel';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { FeildType } from '../../common/enums/field-type-enum'

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApplicantProfileAddSectionComponent } from '../applicant-profile-add-section/applicant-profile-add-section.component';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { SlideUpDown, CardLoad } from '../../common/animations/animations'
import { ApplicantSectionType } from '../../common/enums/ApplicantSectionType';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { TranslateService } from '@ngx-translate/core';
import { ProfileCustomizationService } from '../../profile-customization/profilecustomization.service';
import { TemplateModel } from '../../profile-customization/Model/SettingModel';
import { TranslateToLocalePipe } from '../../common/pipes/translate-to-locale.pipe';
import { LabelService } from '../../common/services/label-service';
import { Roles } from '../../common/enums/roles-enum';
import { UserInfoService } from '../../common/services/userinfo-service';
import { CustomFieldFormService } from '../../common/services/custom-field-form.service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { ApplicantDTO, ApplicantSectionDTO } from '../../models/ApplicantDTO';
import { ApplicantRequestService } from '../../applicant-request/services/applicant-request.service';
import { MessageService } from '../../common/services/message-service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { map, distinctUntilChanged, switchMap, tap, filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CustomFieldValueModel } from '../../models/custom-field-value-model';
import * as _ from 'lodash';

@Component({
  selector: 'app-applicant-profile-v2',
  templateUrl: './applicant-profile-v2.component.html',
  styleUrls: ['./applicant-profile-v2.component.less'],
  animations: [SlideUpDown, CardLoad]
})
export class ApplicantProfileV2Component implements OnInit {
  @Input() submitModel: CustomizationSettingsSubmitModel;;
  sectionsTemplate: FormSectionModel[];
  public fieldType: typeof FeildType = FeildType;
  public sectionType: typeof ApplicantSectionType = ApplicantSectionType;
  public defaultNameField: CustomFieldModel = new CustomFieldModel();
  public defaultEmailField: CustomFieldModel = new CustomFieldModel();
  public defaultProfileTemplateField: CustomFieldModel = new CustomFieldModel();
  pageNumer: number = 0;
  sectionNumer: number = 0;
  profileSection: any;
  public systemLogoExists: boolean = true;
  public iterableDiffer: any;
  public formIsValid: boolean = false;
  private customFieldValid: boolean[] = [];
  public isModelChanged: boolean = false;
  public processing: boolean = false;
  count: number = 0;
  public applicantModel: ApplicantDTO = new ApplicantDTO();
  private definitionKey: string;
  public isUserLogin: boolean = false;

  private _settingSubject = new BehaviorSubject(null);
  public dynamicFieldsValues: CustomFieldValueModel[] = [];
  public showDynamicSpinner: boolean = false;

  constructor(private _sharedService: SharedService, private _applicantDetailsHelper: ApplicantDetailsHelperService,
    public matDialog: MatDialog, private _datePipe: DatePipe
    , public _dataSharingService: DataSharingService
    , private translate: TranslateService
    , private _profileCustomizationService: ProfileCustomizationService
    , private translateToLocalePipe: TranslateToLocalePipe
    , public labelService: LabelService
    , public userInfoService: UserInfoService
    , private _iterableDiffers: IterableDiffers
    , public customFieldFormService: CustomFieldFormService
    , private _translationHelperService: TranslationHelperService
    , private _applicantRequestService: ApplicantRequestService
    , private _messageService: MessageService
    , private _applicantDetailService: ApplicantDetailsHelperService
    , private _route: ActivatedRoute
    , public commonHelper: CommonHelperService
    , private router: Router
  ) {
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    this.definitionKey = this._route.snapshot.paramMap.get('definitionKey');
  }

  @Input() applicantId: string = '';
  @Input() applicationId: string = '';
  @Output() feildMissing: EventEmitter<any> = new EventEmitter();

  @Output() callComponentTo = new EventEmitter();

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.customFieldValid);
    if (changes) {
      this.formIsValid = this.isFormValid(this.customFieldValid);
    }
    if (this.customFieldValid.length == 0) {
      this.formIsValid = true;
    }
  }
  ngOnInit() {
    this.translate.get(["Common.NAME", "Common.EMAIL", "Common.PROFILE-TYPE"]).subscribe(translation => {
      this.defaultNameField.label = translation["Common.NAME"];
      this.defaultEmailField.label = translation["Common.EMAIL"];
      this.defaultProfileTemplateField.label = translation["Common.PROFILE-TYPE"]
    });
    this._profileCustomizationService.getProfileTemplateSections(this.definitionKey).subscribe(resp => {
      this.initializeObject(resp.body);
    }, error => { });
    this.isUserLogin = this.commonHelper.isUserLogIn();
  }

  isFormValid(customFieldValidParam: boolean[]) {
    let formIsValid = false;
    if (customFieldValidParam.filter(item => item == false).length > 0) {
      return false;
    } else {
      return true;
    }
    for (let _i = 0; _i < customFieldValidParam.length; _i++) {
      if (_i == 0) {
        formIsValid = true;
      }
      if (!!customFieldValidParam[_i] == false) {
        formIsValid = false;
      }
    }
    return formIsValid;
  }

  isSystemLogoExist(e) {
    this.systemLogoExists = false;
  }

  addNestedSection(sectionsList: FormSectionModel[], section: FormSectionModel) {
    if (this.checkRequiredFieldsProvided(sectionsList[sectionsList.length - 1])) {
      var emptySection: FormSectionModel = this.getSectionTemplate(section.id);
      this.customFieldFormService.addSection(sectionsList, emptySection);
    }
    this.sectionNumer = sectionsList.length - 1;
  }

  private checkRequiredFieldsProvided(section: FormSectionModel): boolean {
    var allFieldsProvided: boolean = true;
    for (var i = 0; i < section.fields.length; i++) {
      if (section.fields[i].isVisible && section.fields[i].isRequired && !section.fields[i].isReadOnly && !section.fields[i].value) {
        allFieldsProvided = false;
        break;
      }
    }
    return allFieldsProvided;
  }

  removeNestedSection(sectionsList: FormSectionModel[], index: number) {
    this.customFieldFormService.removeSectionByIndex(sectionsList, index);
    if (this.sectionNumer != 0) {
      this.sectionNumer--;
    }
    setTimeout(() => { this.ngDoCheck(); }, 500);
  }

  getNumbersArray(arrayNumber: number): number[] {
    let numberArray: number[] = [];
    for (let i = 0; i < arrayNumber; i++) {
      numberArray.push(i + 1);
    }
    return numberArray;
  }

  initializeObject(resp: any) {
    this.submitModel.sections = resp;
    for (var i = 0; i < this.submitModel.sections.length; i++) {
      if (!this.submitModel.sections[i].isDefault && this.submitModel.sections[i].isActive) {
        for (var j = 0; j < this.submitModel.sections[i].fields.length; j++) {
          if (this.submitModel.sections[i].fields[j].isVisible) {
            this.count = this.count + 1;
            break;
          }
        }
      }
    }

    if (this.count == 0) {
      var data = {
        callTo: 'AddApplicantRequest'
      }
      this.callComponentTo.emit(data);
    }



    this.submitModel.sections = this.submitModel.sections.filter(sec => sec.fields.filter(field => field.isVisible).length > 0 && sec.isActive && sec.type == ApplicantSectionType.Editable);
    this.submitModel.sections.forEach(section => {
      if (section.isMultipule && section.nestedSections.length == 0) {
        section.nestedSections.push(JSON.parse(JSON.stringify(section)));
      }
    });

    this.checkForDynamicFields();
  }

  onSubmit() {
    this.processing = true;
    this.applicantModel.emailAddress = this.submitModel.emailAddress;
    this.applicantModel.name = this.submitModel.name;
    this.applicantModel.applicantSections = [];
    this._applicantRequestService.postApplicantDetails(this.submitModel, this.applicantModel).subscribe(resp => {
      this._dataSharingService.userFullName.next(this.submitModel.name);
      this.processing = false;
      this.callComponentTo.emit('AddApplicantRequest');
    }, error => {
      this.processing = false;
    });
  }

  private getSectionTemplate(id: number) {
    for (let i = 0; i < this.submitModel.sections.length; i++) {
      if (this.submitModel.sections[i].id === id) {
        var sectionToAdd: FormSectionModel = JSON.parse(JSON.stringify(this.submitModel.sections[i]));
        for (let j = 0; j < sectionToAdd.fields.length; j++) {
          sectionToAdd.fields[j].value = "";
        }
        return sectionToAdd
      }
    }
  }

  checkSectionRequired(section: CustomizationSettingsSectionModel) {
    if (section.type == ApplicantSectionType.Editable) {
      if (section.isMultipule && section.nestedSections.length > 0) {
        for (var i = 0; i < section.nestedSections.length; i++) {
          for (var j = 0; j < section.nestedSections[i].fields.length; j++) {
            if (section.nestedSections[i].fields[j].isVisible) {
              let isRequired = this.checkFeild(section.nestedSections[i].fields[j]);
              if (isRequired) {
                section.isRequiredMissing = true;
                this.feildMissing.emit(true);
              }
            }
          }
        }
      } else {
        for (var i = 0; i < section.fields.length; i++) {
          if (section.fields[i].isVisible) {
            let isRequired = this.checkFeild(section.fields[i]);
            if (isRequired) {
              section.isRequiredMissing = true;
              this.feildMissing.emit(true);
            }
          }
        }

      }
    }
  }

  checkFeild(feild: CustomizationSettingsField): boolean { // returns true when feild is required with no value
    switch (feild.type) {
      case FeildType.MultiSelection: {
        let invalid = true;
        if (feild.isRequired) {
          for (let i = 0; i < feild.lookupValues.length; i++) {
            if (feild.lookupValues[i].isSelected) {
              invalid = false;
            }
          }
        } else {
          invalid = false;
        }

        return invalid;
      }

      case FeildType.SingleSelection: {
        let invalid = true;
        if (feild.isRequired) {
          for (let i = 0; i < feild.lookupValues.length; i++) {
            if (feild.lookupValues[i].id == feild.value) {
              invalid = false;
            }
          }
        } else {
          invalid = false;
        }

        return invalid;
      }

      default: {
        if (feild.isRequired && feild.value == '') {
          return true;
        }
        return false;
      }

    }
  }

  toggleMatCard(section: CustomizationSettingsSectionModel, expanded: boolean) {
    section.isExpended = !expanded;
    this.checkSectionRequired(section);
    return section.isExpended;;

  }

  addClass(section): string {
    let borderClass = ''
    if (this.applicationId != '' || section.type != ApplicantSectionType.Editable) {
      if (section.isExpended && section.isRequiredMissing) {
        borderClass = 'margin-b1';
      } else if (section.isExpended && !section.isRequiredMissing) {
        borderClass = 'margin-b1';
      } else if (!section.isExpended && section.isRequiredMissing) {
        borderClass = 'margin-b20'
      } else if (!section.isExpended && !section.isRequiredMissing) {
        borderClass = 'margin-b20';
      }
    } else {
      if (section.isExpended && section.isRequiredMissing) {
        borderClass = 'margin-b1 red-line';
      } else if (section.isExpended && !section.isRequiredMissing) {
        borderClass = 'margin-b1';
      } else if (!section.isExpended && section.isRequiredMissing) {
        borderClass = 'margin-b20 red-line'
      } else if (!section.isExpended && !section.isRequiredMissing) {
        borderClass = 'margin-b20';
      }
    }


    return borderClass;
  }

  nextSection() {
    if (this.formIsValid) {
      if ((this.pageNumer + 1) == this.submitModel.sections.length) {
        //this.onSubmit();
        var data = {
          callTo: 'AddApplicantRequest'
        }
        this.callComponentTo.emit(data);
      } else if ((this.pageNumer + 1) != this.submitModel.sections.length) {
        this.pageNumer++;
        this._settingSubject.next(this.submitModel);
      }
      this.sectionNumer = 0;
      //setTimeout(() => {
        this.customFieldValid = [];
        this.ngDoCheck();
      //}, 100);
    }
  }

  previousSection() {
    if (this.pageNumer != 0) {
      this.pageNumer--;
      this._settingSubject.next(this.submitModel);
    }
    this.sectionNumer = 0;
    //setTimeout(() => {
      this.customFieldValid = [];
      this.ngDoCheck(); 
    //}, 100);
  }

  updateValidations() {
    setTimeout(() => { this.ngDoCheck(); }, 100);
  }

  onFormFieldBlur(customField: CustomFieldModel) {
    if (customField.isTigger) {
      this.showDynamicSpinner = true;
      this._settingSubject.next(this.submitModel);
    }
  }

  private checkForDynamicFields() {
    if (this.submitModel.sections.filter(s => s.fields.filter(f => f.isTigger || f.type == FeildType.Calculated || f.type == FeildType.Mapped || (f.type == FeildType.LookupAddon && f.allowedValuesCriteria && JSON.parse(f.allowedValuesCriteria).length > 0)).length > 0).length > 0) {
      this.applicantModel.emailAddress = this.submitModel.emailAddress;
      this.applicantModel.name = this.submitModel.name;
      this.applicantModel.applicantSections = [];
      this._settingSubject.pipe(
        filter(sumitModel => sumitModel != null),
        map(sumitModel => JSON.parse(JSON.stringify(sumitModel))),
        distinctUntilChanged((oldItem, newItem) => { return _.isEqual(oldItem, newItem) }),
        switchMap(submitModel => this._applicantRequestService.getDynamicValues(this.submitModel, this.applicantModel, this.submitModel.sections[this.pageNumer].id, 0, this.definitionKey)),
        tap((fieldValues: any) => {
          this.customFieldFormService.assignValuesForMappedAndCalcualtedTypesSection(fieldValues, <any>this.submitModel.sections[this.pageNumer]);
          this.dynamicFieldsValues = fieldValues;
          this.showDynamicSpinner = false;
        })
      ).subscribe();
      this._settingSubject.next(this.submitModel);
    }

  }

}
