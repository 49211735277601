import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { LabelService } from '../../../common/services/label-service';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldService } from '../../../common/services/custom-field.service';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { EspDatePickerService } from '../esp-date-format-service/esp-date-picker.service';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { CustomFieldModel } from '../../../profile-customization/Model/CustomFieldModel';
import { FormGroup } from '@angular/forms';


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-esp-date-picker',
  templateUrl: '../esp-date-picker.component.html',
  styleUrls: ['../esp-date-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})
export class EspDatePickerComponent implements OnInit {

  @Input() customFieldForm: FormGroup;
  @Input() customField: any;
  @Input() fieldOptions: any = { floatLabel: 'auto', showPlaceholder: true };
  @Input() showHint: boolean = true;
  @Input() nestedFieldIndex: number = 0;
  @Input() nestedFieldsCount: number;
  private _isDisabled: boolean = false;
  @Input() get isDisabled(): boolean {
    return this._isDisabled
  }
  @Input() addBtnForBoxControl: TemplateRef<any>;
  @Input() isLongLabel: TemplateRef<any>;
  @Input() tempNesterFieldIndex: TemplateRef<any>;
  @Input() mobilefieldsicon: TemplateRef<any>;
  @Input() showInTable: boolean = false;
  public isFocused: boolean = false;
  public mobileIcons: boolean = false;
  @Output() blur = new EventEmitter();
  onBlur(customField: CustomFieldModel) {
    this.blur.emit(customField.isTigger);
  }
  @ViewChild('datePickerList', { static: false }) picker;
  constructor(
    public labelService: LabelService
    , public translate: TranslateService
    , public customFieldService: CustomFieldService
    , public espDatePickerService: EspDatePickerService
    , private _adapter: DateAdapter<any>
    , @Inject(MAT_DATE_LOCALE) private _locale: string
  ) { }


  removeDate() {
    this.espDatePickerService.removeDate(this.customField);
    this.onBlur(this.customField);
  }

  onCloseDatePicker() {
    if(this.espDatePickerService.onCloseDatePicker(this.customField)) {
      this.blur.emit(this.customField.isTigger);
    }
  }
  reOpenCalendar() {
    let self = this;
    setTimeout(
      () => {
        self.picker.open();
      },
      50
    );
  }
  ngOnInit(): void {
    this.mobileIcons = (((this.customField.hint && this.showHint && this.customField.isMultipleAllowed) || (this.customField.isMultipleAllowed || this.nestedFieldsCount > 1)) || (this.customField.hint && this.showHint && !this.customField.isMultipleAllowed));

    if (this.customField.value) {
      this.customField.value = moment(this.customField.value);
    }
    if(this.translate.currentLang == 'fr') {
      this._locale = 'fr';
      this._adapter.setLocale(this._locale);
    } else {
      this._locale = 'en';
      this._adapter.setLocale(this._locale);
    }
  }

}
