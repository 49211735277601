import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { ValidatorsModule } from '../common/modules/validators.module';
import { SortablejsModule } from 'ngx-sortablejs';

import { AccountRoutingModule, routedComponents } from './account.routing'

/*Components*/
import { LoginComponent } from './login/login.component'

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { InvalidLinkComponent } from './invalid-link/invalid-link.component';
import { ExpiredLinkComponent } from './expired-link/expired-link.component';
import { RegisterComponent } from './register/register.component';
import { HeaderBeforeLoginComponent } from './header-before-login/header-before-login.component';
import { TranslateModule } from '@ngx-translate/core';
import { AccountsListComponent } from './accounts-list/accounts-list.component';
import { SharedModule } from '../shared/shared.module';
import { ApplicationLinkComponent } from './application-link/application-link.component';
import { RegisterApplicantComponent } from './register-applicant/register-applicant.component';
import { LogoutComponent } from './logout/logout.component';
import { VerifyApplicantComponent } from './verify-applicant/verify-applicant.component';
import { SubmitRequestComponent } from './submit-request/submit-request.component';
import { SubmitRequestLoginComponent } from './submit-request-login/submit-request-login.component';
import { SubmitRequestSuccessComponent } from './submit-request-success/submit-request-success.component';
import { SubmitRequestV2Component } from './submit-request-v2/submit-request-v2.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AccountSharedModule } from '../shared-modules/account-shared/account-shared.module';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule, ReactiveFormsModule,
    ValidatorsModule,
    SortablejsModule,
    AccountRoutingModule,
    TranslateModule,
    SharedModule,
    AccountSharedModule
  ],
    declarations: [routedComponents, InvalidLinkComponent, ExpiredLinkComponent, RegisterComponent, HeaderBeforeLoginComponent, AccountsListComponent, ApplicationLinkComponent, RegisterApplicantComponent, LogoutComponent, VerifyApplicantComponent, SubmitRequestComponent, SubmitRequestLoginComponent, SubmitRequestSuccessComponent, SubmitRequestV2Component, AuthLayoutComponent
  ],
  providers: [
    
  ]

})

export class AccountModule { }
