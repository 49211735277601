<div fxFlex class="btn-close-container">
  <button mat-icon-button mat-dialog-close aria-label="Close dialog" class="btn-close">
    <mat-icon><img src="assets/images/icons/close.svg" alt="close"></mat-icon>
  </button>
</div>

<div fxLayout="row" fxLayoutAlign="center center">
  <div mat-dialog-title fxLayout="column" fxFlex="360px" fxFlex.xs="100%">
    <div class="dialog-title" [translate]="'LINK-DEFINITON.Submissions-Stopped'">
    </div>
    <div class="dialog-description" [translate]="'LINK-DEFINITON.Confirm-Stop'">
    </div>
  </div>
</div>
<div mat-dialog-content></div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
  <div fxLayout="column" fxFlex="360px" fxFlex.xs="100%" fxLayoutGap="24px">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <button mat-stroked-button color="primary"
              mat-dialog-close tabindex="-1">
        <span [translate]="'Cancel'"></span>
      </button>
      <button mat-flat-button class="gradient-btn" color="primary"
              tabindex="-1" (click)="onUpdateStage()">
        <span [translate]="'LINK-DEFINITON.Stop-Submission'"></span>
      </button>
    </div>
  </div>
</div>
