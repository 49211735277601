import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormControl, Validators, FormGroup } from '@angular/forms';
import { LabelService, ListModel, LabelModel } from '../../common/services/label-service';

import { AccountService } from '../services/account.service'
import { Roles } from '../../common/enums/roles-enum';
import { UserInfoService} from '../../common/services/userinfo-service';
import { SystemSettingsService } from '../../common/services/system-settings-service';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationDefinition } from '../../definitions/models/application-definition';
import { CustomFieldService } from '../../common/services/custom-field.service';
import { AllDefinitionsService } from '../../definitions/services/all-definitions.service';
import { InvitationLinkInformation } from '../../models/invitation-link-information';
@Component({
  selector: 'app-submit-request-login',
  templateUrl: './submit-request-login.component.html',
  styleUrls: ['./submit-request-login.component.less']
})
export class SubmitRequestLoginComponent implements OnInit {

  isLoginError: boolean = false;
  isCallBack: boolean = false;

  @ViewChild('loginDialogDiv') loginDialogDiv: ElementRef;

  orgList: any;
  invitationLinkInformation: InvitationLinkInformation = new InvitationLinkInformation;
  triggerFalseClick() {
    let el: HTMLElement = this.loginDialogDiv.nativeElement as HTMLElement;
    el.click();
  }

  constructor(public labelService: LabelService, private accountService: AccountService, private router: Router, private _userInfoService: UserInfoService
    , private route: ActivatedRoute, public systemSettingsService: SystemSettingsService
    , private _dataSharingService: DataSharingService, private _translationHelperService: TranslationHelperService
    , private translate: TranslateService, private _definitionService: AllDefinitionsService) {
  }

  ngAfterViewInit() {
    this.triggerFalseClick();
  }

  private _userType: string = "";
  private _key: string = "";

  private applicationDefinition: ApplicationDefinition;
  ngOnInit() {
    // if (!this.systemSettingsService.systemSettings.windowsAuth || this.systemSettingsService.systemSettings.windowsAuth.toLocaleLowerCase() != 'true') {
      this.route.queryParams.subscribe(params => {
        this._key = params["key"];
        this._userType = params["type"];
        //this.email.setValue(params["email"]);
        if (this._key != "" && this._userType != "" && params["email"] != "") {
          //this.email.disable();
          //this.email.markAsDirty();
        }
      });
      this.route.params.subscribe(params => {
        if (params['email']) {
          this.email.setValue(params["email"]);
        }
      });
      sessionStorage.clear();
      this._dataSharingService.applicationDefinition.subscribe(resp => {
        if (JSON.stringify(resp) === JSON.stringify(new ApplicationDefinition)) {
          this.router.navigateByUrl('login');
          } else {
          this.applicationDefinition = resp;
          this.email.setValue(this.applicationDefinition['applicantEmail']);
          if (this.applicationDefinition['definitionKey'] != '')
            this.accountService.getInvitationLinkInformation(this.applicationDefinition['definitionKey']).subscribe(resp => {
              this.invitationLinkInformation = resp;
              this.invitationLinkInformation.definitionKey = this.applicationDefinition['definitionKey'];
              this._dataSharingService.invitationLinkInformation.next(this.invitationLinkInformation);
            });
        }
      });

    // } else {
    //   this.authenticateWindowsUser();
    // }
    this.labelService.updateClientLabel();
  }
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);

  getEmailErrorMessage() {
    return this.email.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-REQUIRED') :
      this.email.hasError('email') ? this._translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-INVALID') :
        '';
  }

  getPasswordErrorMessage() {
    return this.password.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.PASSWORD-REQUIRED') :
      this.password.hasError('minlength') ? this._translationHelperService.getTranslationByKey('RequiredMessages.PASSWORD-MINIMUM-CHARACTERS') :
        '';
  }

  goto(comp) {
    if (comp == 'reset') {
      this.router.navigateByUrl('/forgotpassword')
    } else if (comp == 'register') {
      this.router.navigateByUrl('/register')
    }
  }

  OnSubmit(email, password) {
    this.isLoginError = false;
    this.isCallBack = false;
    if (this.email.valid && this.password.valid) {
      this.isCallBack = true;
      this.authenticateUser();

    }
  }

  private authenticateUser(personaId: number = 0) {
    this.accountService.userAuthentication(this.email.value, this.password.value, personaId).subscribe((data: any) => {
      var personas = JSON.parse(data.personas);
      var result = {
        token: {
          accessToken: data.access_token,
          expiry: new Date(data['.expires']),
          refreshToken: data.refresh_token,
          persoans: personas
        },
        user: {
          name: data.name,
          email: data.email,
          role: data.role,
          imageUrl: data.imageUrl,
          organizationId: data.organizationId
        }
      };

      sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));
      this._userInfoService.update();
      if (this._userType != Roles.Applicant) {
        this.translate.use('en');
      }
      if (this._userType == 'User') {
        this.accountService.linkToUser(this._key).subscribe(resp => {
          this.authenticateUser(<number><any>resp);
          this._userType = "";
        });
      }
      else if (this._userType == 'Applicant') {
        this.accountService.linkToApplicant(this._key).subscribe(resp => {
          this.authenticateUser(<number><any>resp);
          this._userType = "";
        });
      }
      this._userType = this._key = null;
      
      if (this._userInfoService.orgPersonas.length > 1) {
        this._dataSharingService.invitationLinkInformation.subscribe((resp: InvitationLinkInformation) => {
          this.persoanSelected(this._userInfoService.orgPersonas.filter(x => x.orgId == resp.organizationId)[0].id);
        });
      } else {
        this.isCallBack = false;
        this._userInfoService.setAuthorizationData(data, '/request');
        this.saveApplicationDefination();
      }

      

      this.initializeSystemLanguage();

    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
        this.isCallBack = false;
      });
  }

  public persoanSelected(personaId) {
    this.accountService.userAuthentication(this._userInfoService.email, "", personaId).subscribe((data: any) => {
      this._userInfoService.setAuthorizationData(data, '/request');
      this.saveApplicationDefination();
    });
  }

  saveApplicationDefination() {
    this.applicationDefinition['formValues'] = this.mapPostValue();
    this._definitionService.saveAsDraftApplication(this.applicationDefinition).subscribe(data => {
      let id = data;
    },
      (err: HttpErrorResponse) => {
        
      }
    )
  }

  mapPostValue() {
    let customFieldValues: any = [];

    this.applicationDefinition.form.sections.forEach(section => {
      customFieldValues = customFieldValues.concat(CustomFieldService.mapPostValueModel(section.fields, section.id));
    });

    return customFieldValues;
  }

  private initializeSystemLanguage() {
    this._dataSharingService.systemLanguages.subscribe(systemLanguages => {
      this._translationHelperService.getOrganizationLocales().subscribe(resp => {
        let activeLocales = resp.body.split(',');
        if (activeLocales.length > 0) {
          systemLanguages.forEach(systemLanguage => {
            if (!systemLanguage.isDefault)
              systemLanguage.isActive = false;
          });
          systemLanguages.forEach(systemLanguage => {
            activeLocales.forEach(activeLocale => {
              if (systemLanguage.locale == activeLocale) {
                systemLanguage.isActive = true;
              }
            })
          });
        }
      });

    });
  }

  private authenticateWindowsUser(personaId: number = 0) {
    this._userInfoService.update();
    if (this._userInfoService.orgPersonas.length > 1) {
      this.router.navigateByUrl('/accounts');
    } else {
      this.isCallBack = false;
      this._userInfoService.setWindowsAuthorizationData();
    }

    this.initializeSystemLanguage();
  }
}
