import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { DatabaseModel, EntityModel } from '../../models/database-mapper/database-model';
import { ColumnModel } from '../../models/database-mapper/column-model';
@Injectable()
export class DatabaseMapperService {

  constructor(private http: HttpClient) { }

  getDatabases(model: DatabaseModel): Observable<string[]> {
    return this.http.post<any>('/webapi/database', model);
  }

  getTables(model: DatabaseModel): Observable<EntityModel[]> {
    return this.http.post<any>('/webapi/database/tables', model);
  }

  getOraTables(data: any): Observable<EntityModel[]> {
    return this.http.post<any>('/webapi/database/oratables', data);
  }

  getColumns(model: DatabaseModel): Observable<ColumnModel[]> {
    return this.http.post<any>('/webapi/database/table/columns', model);
  }

  getTableData(data: any, lookupId: number): Observable<any> {
    
    return this.http.post<any>('/webapi/database/table/data/' + lookupId, data);
  }

  uploadCSVFile(data: any): Observable<any> {
    return this.http.post<any>('/webapi/database/uploadcsv', data);
  }
}