<div class="abc">
    <mat-form-field appearance="outline" class="full-width compressed">
        <mat-label>
            Available for
        </mat-label>
        <mat-select disableOptionCentering [formControl]="profileFormControl"
            (selectionChange)="profileTemplateChanged($event.value)" [disabled]="disabled"
            required>
            <mat-option *ngFor="let template of profileTemplates" [value]="template.id+'-'+template.profileListId">
                {{template.name | translateToLocale }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>