import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from "@angular/flex-layout";
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomizationRoutingModule } from './customization-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AvatarModule } from 'ngx-avatar';
import { NgxPaginationModule } from 'ngx-pagination';
import { ValidatorsModule } from '../common/modules/validators.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashboardService } from './services/dashboard.service';
@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    CustomizationRoutingModule,
    FormsModule, ReactiveFormsModule,
    ValidatorsModule,
    NgxPaginationModule,
    AvatarModule,
    SharedModule
  ],
  declarations: [
    DashboardComponent
  ],
  exports: [
    DashboardComponent
  ],
  providers: [
    DashboardService
  ],
  entryComponents: [
  ]
})
export class CustomizationModule { }
