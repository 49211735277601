import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TranslationHelperService } from '../common/services/translation-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpResponse } from '@angular/common/http';
import { SettingModel, TemplateModel, ProfileTemplateListModel, TemplateModelWithProfileList } from './Model/SettingModel';
import { CustomFieldModel } from './Model/CustomFieldModel';
import { FormSectionModel } from '../models/CustomizationSettingsSubmitModel';

@Injectable({
  providedIn: 'root'
})
export class ProfileCustomizationService {
  constructor(
    private http: HttpClient,
    private _translationHelperService: TranslationHelperService,
    private translate: TranslateService
  ) { }

  saveSectionsAndFields(template: TemplateModel): Observable<any> {
    return this.http.post<any>('/webapi/ApplicantCustomization/save', template);
  }

  createNewTemplate(template: TemplateModel): Observable<any> {
    return this.http.post<any>('/webapi/ApplicantCustomization', template);
  }

  editTemplateProfile(template: TemplateModel): Observable<any> {
    return this.http.put<any>('/webapi/ApplicantCustomization/update', template);
  }

  deleteTemplateProfile(templateid: number): Observable<any> {
    return this.http.delete<any>('/webapi/ApplicantCustomization/'+ templateid);
  }

 getSettingSections(): Observable<HttpResponse<TemplateModel>> {
    return this.http.get<TemplateModel>('/webapi/ApplicantCustomization/sections', { observe: 'response' } );
  }

  getProfileTemplates(): Observable<HttpResponse<TemplateModel[]>> {
    return this.http.get<TemplateModel[]>('/webapi/ApplicantCustomization', { observe: 'response' });
  }

  getProfileTemplatesList(): Observable<HttpResponse<TemplateModelWithProfileList[]>> {
    return this.http.get<TemplateModelWithProfileList[]>('/webapi/ApplicantCustomization/withLists', { observe: 'response' });
  }

  getProfileTemplateDetails(id: number): Observable<HttpResponse<TemplateModel>> {
    return this.http.get<TemplateModel>('/webapi/ApplicantCustomization/' + id, { observe: 'response' });
  }

  saveProfileTemplateDetails(templateModel: TemplateModel): Observable<any> {
    return this.http.put<any>('/webapi/ApplicantCustomization/', templateModel);
  }

  getProfileTemplateSections(definitionKey: string): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/ApplicantCustomization//definitionKey/' + definitionKey, { observe: 'response' });
  }

  getProfileList(ptId: number): Observable<HttpResponse<ProfileTemplateListModel[]>> {
    return this.http.get<ProfileTemplateListModel[]>('/webapi/profileList/' + ptId, { observe: 'response' });
  }

  saveProfileList(profileList: ProfileTemplateListModel): Observable<any> {
    return this.http.post<any>('/webapi/profileList', profileList);
  }
  getApplicantProfile(applicantId): Observable<any> {
    return this.http.get<any>('/webapi/applicant/get/' + applicantId, {observe: 'response', headers: { 'locale': this.translate.currentLang }});
  }

  getProfileTemplateDetailsByDefinition(definitionId: number): Observable<HttpResponse<FormSectionModel[]>> {
    return this.http.get<FormSectionModel[]>(`/webapi/ApplicantCustomization/sectionsByDefinition/${definitionId}`, { observe: 'response' });
  }

  getProfileTemplateSectionsByTemplateIds(teplateIds: string): Observable<HttpResponse<FormSectionModel[]>> {
    return this.http.get<FormSectionModel[]>(`/webapi/ApplicantCustomization/sectionsByProfileTemplateIs/${teplateIds}`, { observe: 'response' });
  }

}
