import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xcdrs-breadcrumbs',
  template: `
  <div class="esp-breadcrumb" [class.breadcrumb-ltr]="!dataSharingService.isRtl" [class.breadcrumb-rtl]="dataSharingService.isRtl">
    <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./breadcrumbs.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class BreadcrumbsComponent implements OnInit {

  constructor(public dataSharingService: DataSharingService) { }

  ngOnInit(): void {
  }

}
