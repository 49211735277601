import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LookupItemsListFilterService {
  public searchText: string;
  public pageNumber: number;
  public tabIndex: number;
  public triggerRoute: string; 
  public readData: boolean; 
  constructor() { }

  public resetData() {
    this.readData = false;
    this.searchText = "";
    this.pageNumber = 1;
    this.tabIndex = 0;
    this.triggerRoute = ""; 
  }
}
