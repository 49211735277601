import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CustomFieldModel } from '../../../profile-customization/Model/CustomFieldModel';
import { DataSharingService } from '../../../common/services/data-sharing.service';
@Component({
  selector: 'app-esp-rich-text-editor',
  templateUrl: './esp-rich-text-editor.component.html',
  styleUrls: ['./esp-rich-text-editor.component.scss']
})
export class EspRichTextEditorComponent {
  
  @Input() customField: CustomFieldModel;
  @Input() customFieldForm: FormGroup;
  @Input() showHint: boolean = true;
  richtextValue: string = '';
  constructor(
    public dataSharingService: DataSharingService,
    public dialogRef: MatDialogRef<EspRichTextEditorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { 
    this.customField = this.data.customField,
    this.customFieldForm = this.data.customFieldForm,
    this.showHint = this.data.showHint 
  }
  ngOnInit(): void {
    this.richtextValue = this.customField.value;
    
  }
  onBlur(customField){

  }
  submit() {
    this.dialogRef.close();
  }
  cancel() {
    this.customField.value = this.richtextValue;
    this.dialogRef.close();
  }
}
