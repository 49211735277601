<div >
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title class="ma-0">
        <h4 fxFlex>{{customField.label}}</h4>
        <div fxFlex="none"> <button mat-icon-button mat-dialog-close (click)="cancel()" aria-label="Close dialog">
                <mat-icon><img src="assets/images/icons/close.svg" alt="close"></mat-icon>
            </button>
        </div>
    
    </div>
    
    <div [dir]="(dataSharingService.isRtl)?'rtl':'ltr'">
        <div mat-dialog-content class="editor">
            <div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="32px">
                <app-esp-quill-editor style="width: 100%;" class="full-width" [customFieldForm]="customFieldForm" [customField]="customField" [isExpanded]="true"
                    (blur)="onBlur(customField)" class="esp-quill-editor"></app-esp-quill-editor>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="16px" style="margin-top: 32px;">
        <!-- <button mat-stroked-button color="primary" mat-dialog-close>
            <span [translate]="'Cancel'"></span>
    
        </button>
        <button mat-flat-button class="gradient-btn" color="primary" [disabled]="!fg.valid || !fileDetails || isCallBack" (click)=uploadDocument()>
            <span *ngIf="!data.isEdit"  [translate]="'Application.UPLOAD'"></span>
            <span *ngIf="data.isEdit"  [translate]="'Common.UPDATE'"></span>
        </button> -->
        <!-- <div fxFlex="none" fxLayout="row" class="full-width" fxLayoutGap="10px" fxLayoutAlign="end end"> -->
            <button mat-stroked-button color="primary" (click)="cancel()"
                class="confirm-dialog-button">Cancel</button>
            <button mat-flat-button color="primary" class="confirm-dialog-button" (click)="submit()">Ok</button>
        <!-- </div> -->
    </div>