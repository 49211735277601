import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { LabelService } from '../../common/services/label-service';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldService } from '../../common/services/custom-field.service';



import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { FormGroup } from '@angular/forms';
import { EspDatePickerService } from '../esp-date-format-controls/esp-date-format-service/esp-date-picker.service';



const moment = _rollupMoment || _moment;




@Component({
  selector: 'app-esp-date-time-picker',
  templateUrl: './esp-date-time-picker.component.html',
  styleUrls: ['./esp-date-time-picker.component.scss']
})
export class EspDateTimePickerComponent implements OnInit {

  @Input() customFieldForm: FormGroup;
  @Input() customField: any;
  @Input() fieldOptions: any = { floatLabel: 'auto', showPlaceholder: true };
  @Input() showHint: boolean = true;
  @Input() nestedFieldIndex: number = 0;
  @Input() nestedFieldsCount: number;
  private _isDisabled: boolean = false;
  @Input() get isDisabled(): boolean {
    return this._isDisabled
  }
  @Input() addBtnForBoxControl: TemplateRef<any>;
  @Input() isLongLabel: TemplateRef<any>;
  @Input() tempNesterFieldIndex: TemplateRef<any>;
  @Input() mobilefieldsicon: TemplateRef<any>;
  @Input() showInTable: boolean = false;
  public isFocused: boolean = false;
  public mobileIcons: boolean = false;
  @Output() blur = new EventEmitter();
  onBlur(customField: CustomFieldModel) {
    this.blur.emit(customField.isTigger);
  }
  @ViewChild('picker', { static: false }) picker;
  constructor(
    public labelService: LabelService
    , public translate: TranslateService
    , public customFieldService: CustomFieldService
    , public espDatePickerService: EspDatePickerService
  ) { }


  removeDate() {
    this.espDatePickerService.removeDate(this.customField);
    this.onBlur(this.customField);
  }

  onCloseDatePicker() {
    if(this.espDatePickerService.onCloseDatePicker(this.customField)) {
      this.blur.emit(this.customField.isTigger);
    }
  }
  reOpenCalendar() {
    let self = this;
    setTimeout(
      () => {
        self.picker.open();
      },
      50
    );
  }
  ngOnInit(): void {
    this.mobileIcons = (((this.customField.hint && this.showHint && this.customField.isMultipleAllowed) || (this.customField.isMultipleAllowed || this.nestedFieldsCount > 1)) || (this.customField.hint && this.showHint && !this.customField.isMultipleAllowed));

    if (this.customField.value) {
      var offset = new Date(this.customField.value).getTimezoneOffset();
      // console.log(offset);
      this.customField.value = moment(this.customField.value).format('YYYY-MM-DDTHH:mm:ssZ');
    }
    // console.log('CustomField', this.customField);
    
  }

}

