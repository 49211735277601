import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from "@angular/core";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { GroupedNameValue, NameValue } from "../../models/name-value.model";

@Component({
  selector: "esp-mat-select",
  templateUrl: "./esp-mat-select.component.html",
  styleUrls: ["./esp-mat-select.component.scss"],
})
export class EspMatSelectComponent implements OnInit {
  @Input() espFormControl: FormControl;
  private _options: NameValue[];
  @Input() get options(): NameValue[] {
    return this._options;
  }
  set options(options: NameValue[]) {
    this._options = options;
    // this._cdr.detectChanges();
    this.updateTriggerText();
    this.toggleItem();
    this.checkForAllOptionDisable();
  }
  @Input() placeholder: string;
  @Input() allowMultiSelect: boolean = false;
  @Input() appearance = "standard";
  @Input() triggerTextInBoxes = false;
  @Input() hasGrouping = false;
  @Input() allowSelectAll = true;
  @Input() optionInTwoLines = false;
  @Input() showErrorMsg = true;
  @Output() optionChange = new EventEmitter();

  triggerText = "";
  disableSelectAll = false;
  isRequired = false;
  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.isRequired = this.checkControlIsRequired(this.espFormControl);
    if (this.allowMultiSelect) {
      this.updateTriggerText();
      this.toggleItem();
      this.checkForAllOptionDisable();
    }else if (!this.allowMultiSelect && this.optionInTwoLines){
      this.updateTriggerTextForSingleSelection();
    }
  }

  private checkControlIsRequired(control: AbstractControl) {
    if (!control) {
      return false;
    }
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }


  private checkForAllOptionDisable() {
    if (this.options.every((op) => op.disabled)) {
      this.disableSelectAll = true;
    }
  }

  toggleAllSelection() {
    const valueArray = this.espFormControl.value;
    let values: any[] = [];
    if (valueArray.includes(-1)) {
      values = this.options.map((op) => op.value);
      values.push(-1);
      this.espFormControl.setValue(values);
    } else {
      this.espFormControl.setValue("");
      this.espFormControl.setValue(
        this.options.filter((op) => op.disabled).map((op) => op.value)
      );
    }
    this.updateTriggerText();
  }

  toggleItem() {
    const valueArray = this.espFormControl.value;
    if ( this.allowMultiSelect && valueArray != 'undefined' && valueArray != '' && valueArray != null && this.allowSelectAll){
      if (valueArray.filter((va) => va != -1).length == this.options.length) {
        if (!valueArray.find((va) => va == -1)) {
          valueArray.push(-1);
          this.espFormControl.setValue(valueArray);
        }
      } else {
        this.espFormControl.setValue(valueArray.filter((va) => va != -1));
      }
      this.updateTriggerText();
    }
  }

  public selectNamesList: string[] = [];
  updateTriggerText() {
    this.triggerText = "";
    this.selectNamesList = [];
    if (this.espFormControl.value && this.allowMultiSelect) {
      const values = this.espFormControl.value.filter((v) => v != -1);
      if (!this.triggerTextInBoxes) {
        this.triggerText = this.options
          .filter((op) => values.includes(op.value))
          .map((opt) => opt.name)
          .join(", ");
      } else {
        this.selectNamesList = this.options
          .filter((op) => values.includes(op.value))
          .map((opt) => opt.name);
      }
    }
  }

  updateTriggerTextForSingleSelection(){
    if(this.optionInTwoLines){
      if (this.espFormControl.value) {
        this.triggerText = this.options
          .filter((op) => op.value == this.espFormControl.value)
          .map((opt) => opt.name)
          .join(", ");
      }
    }
  }
  optionChanged(e) {
    console.log('Change', e.value);
    this.optionChange.emit(e.value);
  }
}
