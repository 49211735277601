<button mat-menu-item [matMenuTriggerFor]="menuLanguages">
  {{preferredLanguage.localeName}}
</button>
<!--<span mat-icon-button *ngIf="systemLanguages && systemLanguages.length > 1" [matMenuTriggerFor]="menuLanguages" fxLayoutAlign="end end" class="cursor-pointer">
  <span>{{preferredLanguage.localeName}}</span>
  <mat-icon class="lang-arrow-icon">keyboard_arrow_down</mat-icon>
</span>-->
<mat-menu #menuLanguages="matMenu" [overlapTrigger]="false">
  <ng-container *ngFor="let lang of dataSharingService.systemLanguages |async">
    <button *ngIf="lang.isActive" (click)="switchLanguage(lang)" mat-menu-item>
      <span>{{lang.localeName}}</span>
    </button>
  </ng-container>
</mat-menu>

