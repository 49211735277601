import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFieldService } from '../../common/services/custom-field.service';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { ValueDetails } from '../../models/custom-field-value-model';
import { FeildType } from '../../common/enums/field-type-enum';
import { ImagePreviewDialogComponent } from './image-preview-dialog/image-preview-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { OrgSettingsStore } from '../../organization/services/org-settings.store';
import { OrgSettings } from '../../models/org-settings.model';
import { AttachmentService } from '../../shared-modules/form-submission-shared/services/attachment.service';

@Component({
  selector: 'xcdrs-attached-file-details',
  templateUrl: './attached-file-details.component.html',
  styleUrls: ['./attached-file-details.component.scss']
})
export class AttachedFileDetailsComponent implements OnInit {
  @Input() customField: CustomFieldModel;
  @Input() file: File = null;
  @Input() uploadedFileDetails: ValueDetails = null; //used for uploaded file in comment while responding
  @Input() allowRemove = false;
  @Output() onFileRemove = new EventEmitter();
  fieldType = FeildType
  @Input() checkValidation: boolean = false;
  @Output() isValid = new EventEmitter<any>();
  @Input() callFrom: string = '';
  constructor(
    public customFieldService: CustomFieldService,
    private _matDialog: MatDialog,
    public dataSharingService: DataSharingService,
    private orgSettingsStore: OrgSettingsStore,
    private attachmentService : AttachmentService
  ) { }

  ngOnInit(): void {
    if (this.file) {
      if(this.checkValidation) {
        this.handleFileInput(this.file)
      }
      this.customField = new CustomFieldModel();
      this.customField.value = this.file.name;
      this.customField.type = FeildType.Attachment;
      this.customField.details = new ValueDetails();
      //this.customField.details.downloadUrl = this.file.name;
      this.customField.details.name = this.file.name;
      this.customField.details.size = this.file.size.toString();
    } else if (this.uploadedFileDetails){
      if(!this.customField){
        this.customField = new CustomFieldModel();
      }
      this.customField.value = this.uploadedFileDetails.name;
      this.customField.details = this.uploadedFileDetails;
      if(this.checkValidation) {
        this.handleFileInput(this.uploadedFileDetails)
      }
    }
  }

  removeFile() {
    this.file = null;
    this.customField = null;
    this.onFileRemove.emit(true);
  }


  public getFileSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  openPreview(url){
    const dialogRef = this._matDialog.open(ImagePreviewDialogComponent, {
      minWidth: '100vw',
      height: '100vh',
      autoFocus: false,
      direction: this.dataSharingService.isRtl?'rtl':'ltr',
      panelClass: "no-padding-dialog",
      data: { imgUrl: url }
    });
  }
  public orgSettings: OrgSettings;
  public maxFileSizeError: boolean;
  public fileExtensionError: boolean;
  public fileNameError: boolean;
  handleFileInput(file): void {
    this.orgSettingsStore.orgSettings$.subscribe(orgSettings => {
      this.orgSettings = orgSettings;
      var fileSize = file.size;
      var inMbs = (fileSize / 1024) / 1024;
      if (file) {
        if (!this.attachmentService.validateFileName(file.name, this.orgSettings?.attachmentNameRegEx)) {
          this.maxFileSizeError = false;
          this.fileExtensionError = false;
          this.fileNameError = true;
          this.isValid.emit(false);
          // alert('file name');
        } else {
          this.fileNameError = false;
          if (!this.orgSettings?.allowedMaxAttatchmentSize || inMbs <= Number(this.orgSettings?.allowedMaxAttatchmentSize)) {
            if (this.attachmentService.validateFileExtension(file.name, this.orgSettings?.allowedAttatchments)) {
              this.maxFileSizeError = false;
              this.fileExtensionError = false;
              this.isValid.emit(true);
            } else {
              this.maxFileSizeError = false;
              this.fileExtensionError = true;
              this.isValid.emit(false);
              // alert('file extension error');
            }
          } else {
            this.maxFileSizeError = true;
            this.fileExtensionError = false;
            // alert('file size error');
            this.isValid.emit(false);
          }
        }
  
      }
    });
  }
  
  getFormatedExtenssions() {
    if(!this.orgSettings?.allowedAttatchments || this.orgSettings?.allowedAttatchments != '') {
      let exArray = this.orgSettings?.allowedAttatchments.split(',');
      return exArray.join(', ');
    }
  }
}
