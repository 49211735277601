import { Injectable } from '@angular/core';
import { ApplicationDetail, LabelValue } from '../../models/applicationdetailmodel';
import { StageCriteria } from '../../definitions/models/stage-criteria';
import { DefinitionStageModel } from '../../definitions/models/definition-stage';
import { ApplicationStatuses, ApplicationStatus, ApplicationClasses } from '../../common/enums/application-status-enum';
import { AdminApplicationViewModel, ApplicationViewModel } from '../../applicant-request/models/application-view-model';
import { TranslateToLocalePipe } from '../pipes/translate-to-locale.pipe';
import { ApplicationAction } from '../enums/application-action-enum';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CancelApplicationDialogComponent } from '../../application/cancel-application-dialog/cancel-application-dialog.component';
import { FormModel, DefinitionType } from '../../definitions/models/application-definition';
import { StopSubmissionsDialogComponent } from '../../shared/application-stages-details/submissions-tabs/stop-submissions-dialog/stop-submissions-dialog.component';
import { AssessmentStatus } from '../enums/assessment-status.enum';
import { TranslationHelperService } from './translation-helper.service';
import { MessageService } from './message-service';
import { CommonHelperService } from './common-helper.service';
import { Router } from '@angular/router';
import { DataSharingService } from './data-sharing.service';
import { AddSubmissionDialogComponent } from '../../shared/application-stages-details/submissions-tabs/add-submission-dialog/add-submission-dialog.component';
import { UserResult } from '../../models/UserResult';

@Injectable()
export class ApplicationDetailsHelperService {

  constructor(
    private translateToLocalePipe: TranslateToLocalePipe,
    private matDialog: MatDialog,
    private _translationHelperService: TranslationHelperService,
    private _msgService: MessageService,
    private _helperService: CommonHelperService,
    private _router: Router,
    public dataSharingService: DataSharingService
  ) {

  }

  setApplicationAssessedOnDate(applicationDetails: ApplicationDetail) {
    if (applicationDetails.applicationStatusId == ApplicationStatuses.Accepted) {
      const stages: DefinitionStageModel[] = applicationDetails.stages.filter(s => s.statusId == 1);
      if (stages.length > 0) {
        applicationDetails.applicationCompletedOn = stages[stages.length - 1].completedOn;
      } else {
        applicationDetails.applicationCompletedOn = applicationDetails.applicationSubmittedDate;
      }
    } else if (applicationDetails.applicationStatusId == ApplicationStatuses.Rejected) {
      const stages: DefinitionStageModel[] = applicationDetails.stages.filter(s => s.statusId == 2);
      if (stages.length > 0) {
        applicationDetails.applicationCompletedOn = stages[stages.length - 1].completedOn;
      }
    }
  }

  showStageIfOwnerCriteriaPending(applicationDetails: ApplicationDetail) {
    let currStageId = applicationDetails.currentStageId;
    let activeStageCriteria: StageCriteria = new StageCriteria();

    applicationDetails.stages.forEach(function (stage) {
      activeStageCriteria = stage.criteriaList.find(x => x.assessmentStatus == 'Active' && x.isOwner == true)
    });

    if (currStageId != 0) {
      let currStage: DefinitionStageModel = new DefinitionStageModel();

      if (activeStageCriteria && activeStageCriteria.stageId < currStageId) {
        currStage = applicationDetails.stages.find(x => x.id == activeStageCriteria.stageId);
      } else {
        currStage = applicationDetails.stages.find(x => x.id == currStageId);
      }
      if (currStage) {
        let hasOwnerCriteria = currStage.criteriaList.find(x => x.assessmentStatus == 'Active' && x.isOwner == true);

        if (hasOwnerCriteria) {
          currStage.isExpended = true;
        }
      }
    } else if (activeStageCriteria && activeStageCriteria.stageId) {
      let currStage = applicationDetails.stages.find(x => x.id == activeStageCriteria.stageId);
      if (currStage) {
        let hasOwnerCriteria = currStage.criteriaList.find(x => x.assessmentStatus == 'Active' && x.isOwner == true);

        if (hasOwnerCriteria) {
          currStage.isExpended = true;
        }
      }
    }

  }

  checkStageLock(applicationDetails: ApplicationDetail, stage: DefinitionStageModel): boolean {

    let itemReturn = stage.criteriaList.find(x => x.assessmentStatus == 'Active')

    if (applicationDetails.applicationStatusId == ApplicationStatuses.Rejected || stage.status == "Complete") {
      return false;
    }
    else if (stage.id == applicationDetails.currentStageId || itemReturn) {
      return true;
    } else {
      return false;
    }
  }

  setJumpedCriterias(applicationDetail: ApplicationDetail) {
    // applicationDetail.stages.forEach(stage => {
    //   let criteriaIds: number[] = stage.criteriaList.map(x => x.id);
    //   criteriaIds = criteriaIds.filter((n, i) => criteriaIds.indexOf(n) === i); // distinct
    //   criteriaIds.forEach(criteriaId => {
    //     let criterias = stage.criteriaList.filter(c => c.id == criteriaId)
    //     if (criterias.length > 1) {
    //       criterias.forEach((criteria, index) => {
    //         if (index != (criterias.length - 1)) {
    //           criteria.isJumped = true;
    //         }
    //       });
    //     }
    //   });
    // });

  }

  checkStageIsSigned(stage: DefinitionStageModel) {
    if (stage.criteriaList.filter(x => x.isSigned && (x.assessmentStatus == 'Accepted' || x.assessmentStatus == 'Rejected')).length > 0) {
      stage.isSigned = true;
    } else {
      stage.isSigned = false;
    }
  }

  checkHasCriteriaToRespond(stage: DefinitionStageModel) {
    stage.hasCriteriaToRespond = stage.criteriaList.filter(c => c.isEnabled && c.isOwner && c.assessmentStatus == AssessmentStatus.Active).length > 0;
  }

  setApplicationStatus(statusId: number, status: string): string {
    // let statusText: string;
    // switch (statusId) {
    //   case (ApplicationStatuses.Invited):
    //     statusText = ApplicationStatus.Invited;
    //     break;

    //   case (ApplicationStatuses.New):
    //     statusText = ApplicationStatus.New;
    //     break;

    //   case (ApplicationStatuses.Accepted):
    //     //case (this.applicationStatuses.Approved):
    //     statusText = ApplicationStatus.Accepted;
    //     break;

    //   case (ApplicationStatuses.Rejected):
    //     statusText = ApplicationStatus.Rejected;
    //     break;

    //   case (ApplicationStatuses.Cancelled):
    //     statusText = ApplicationStatus.Cancelled;
    //     break;
    //   default:
    //     statusText = this.translateToLocalePipe.transform(status);
    //     break;
    // }
    return this.translateToLocalePipe.transform(status);
  }

  setApplicationClass(statusId: number): string {
    let statusClass = '';
    switch (statusId) {
      case (ApplicationStatuses.Invited):
        statusClass = ApplicationClasses.Invited;
        break;

      case (ApplicationStatuses.New):
        statusClass = ApplicationClasses.Draft;
        break;

      case (ApplicationStatuses.Accepted):
        //case (this.applicationStatuses.Approved):
        statusClass = ApplicationClasses.Accepted;
        break;

      case (ApplicationStatuses.Rejected):
        statusClass = ApplicationClasses.Rejected;
        break;

      case (ApplicationStatuses.Cancelled):
        statusClass = ApplicationClasses.Cancelled;
        break;
      case (ApplicationStatuses.Deleted):
        statusClass = ApplicationClasses.Rejected;
        break;
      case (ApplicationStatuses.Archived):
        statusClass = ApplicationClasses.Rejected;
        break;
        case (ApplicationStatuses.Returned):
          statusClass = ApplicationClasses.Draft;
          break;
      default:
        statusClass = ApplicationClasses.New;
        break;
    }
    return statusClass;
  }

  public mapProperties(applicationDetails: ApplicationDetail): AdminApplicationViewModel {
    const application = new AdminApplicationViewModel();
    application.applicationId = applicationDetails.applicationId;
    application.applicantId = applicationDetails.applicantId;
    application.iconName = applicationDetails.iconName;
    application.definitionName = applicationDetails.name;
    application.category = applicationDetails.category;
    application.applicationNumber = applicationDetails.applicationNumber;
    application.submittedOn = applicationDetails.applicationSubmittedDate.toString();
    application.status = applicationDetails.applicationStatus;
    application.applicationClass = applicationDetails.applicationClass;
    application.pendingFor = applicationDetails.pendingFor;
    application.applicantName = applicationDetails.applicantName;
    application.statusId = applicationDetails.applicationStatusId;
    application.isSigned = applicationDetails.isSigned;
    application.id = applicationDetails.applicationId;
    application.applicationCompletedOn = applicationDetails.applicationCompletedOn;
    application.isMySubmitted = applicationDetails.isMySubmitted;
    application.definitionType = applicationDetails.type;
    application.summary = applicationDetails.summary;
    application.definitionIsActive = applicationDetails.isActive;
    application.definitionEndDate = applicationDetails.endDate;
    application.applicationCreatedBy = applicationDetails.applicationCreatedBy;
    return application;
  }

  openCancelRequestDialog(applicationAction: ApplicationAction, applicationId: number): MatDialogRef<CancelApplicationDialogComponent, any> {
    const cancelRequestDialogRef = this.matDialog.open(CancelApplicationDialogComponent, {
      width: '540px',
      data: { applicationId: applicationId, applicationAction: applicationAction },
      disableClose: true,
      autoFocus: false,
      direction: this.dataSharingService.isRtl ? 'rtl' : 'ltr'
    });

    return cancelRequestDialogRef;
  }

  closeSubmissionsDialog(applicationId: number): MatDialogRef<StopSubmissionsDialogComponent, any> {
    const visibilityDialogRef = this.matDialog.open(StopSubmissionsDialogComponent, {
      width: '552px',
      autoFocus: false,
      direction: this.dataSharingService.isRtl ? 'rtl' : 'ltr',
      data: { allowSubmission: true, applicationId: applicationId }
    });
    return visibilityDialogRef;
  }
  addSubmissionsDialog(childDefinitionId: number, applicationId: number, allowedUsers: UserResult[]): MatDialogRef<AddSubmissionDialogComponent, any> {
    const visibilityDialogRef = this.matDialog.open(AddSubmissionDialogComponent, {
      width: '552px',
      autoFocus: false,
      direction: this.dataSharingService.isRtl ? 'rtl' : 'ltr',
      data: { childDefinitionId: childDefinitionId , applicationId: applicationId, allowedUsers:  allowedUsers}
    });
    return visibilityDialogRef;
  }
  getParentApplicationForm(applicationDetails: ApplicationDetail): ApplicationDetail {
    let parentApplicationInfo = new ApplicationDetail();
    parentApplicationInfo.form = new FormModel();
    parentApplicationInfo.form.sections = applicationDetails.form.sections.filter(s => s.childAccessibility);
    if (parentApplicationInfo.form.sections.length > 0) {
      parentApplicationInfo.type = DefinitionType.SUBMISSION;
      return parentApplicationInfo;
    } else {
      return null;
    }
  }

  getFirstThreeItems(items: LabelValue[]): LabelValue[] {
    if (items.length > 3) {
      return items.slice(0, 3);
    } else {
      return items;
    }
  }
  getFirstFiveItems(items: LabelValue[]): LabelValue[] {
    if (items.length > 6) {
      return items.slice(0, 6);
    } else {
      return items;
    }
  }


  resubmitApplication(applicationId: number, definitionIsActive: boolean, definitionName: string, definitionEndDate: Date, isRecord: boolean) {
    if (!definitionIsActive) {
      let errorMsg = this._translationHelperService.getTranslationByKey('SUBMIT-APPLICATION-ERRORS.EXPIRED-IN-DRAFT');
      errorMsg = errorMsg.replace('[DefinitionName]', this.translateToLocalePipe.transform(definitionName));
      this._msgService.showMessage(errorMsg);
    } else if (definitionEndDate && this._helperService.isExpired(definitionEndDate)) {
      this._msgService.showMessage(this._translationHelperService.getTranslationByKey('SUBMIT-APPLICATION-ERRORS.EXPIRED'));
    }
    else {
      //this.navigateTo('/applications/request/submit/' + applicationId, true);
      this._router.navigate(['/request/submit/' + applicationId], {
        queryParams: {
          type: 'application',
          resubmit: true,
          isRecord: isRecord ? 1 : 0
        }
      });
    }
  }
}
