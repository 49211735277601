import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { CustomizationSettingsSectionModel, FormSectionModel } from '../models/CustomizationSettingsSubmitModel';
import { ApplicantDetailsHelperService } from '../common/services/applicant-details-helper.service'
import { CommonHelperService } from '../common/services/common-helper.service';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient, private _applicantHelperService: ApplicantDetailsHelperService
    , private commonHelperService: CommonHelperService) { }

  getApplicantDetails(): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/applicant/', { observe: 'response' });
  }

  updateApplicantProfileSection(index: number, section: FormSectionModel): Observable<HttpResponse<any>> {
    return this.http.put<any>('/webapi/applicant/section/' + section.id + '/' + index, this._applicantHelperService.mapToApplicantFieldValueDTOArray(section));
  }

  updateApplicantNameAndProfileType(name: string, profileTypes: number[] = null) {
    return this.http.put<any>('/webapi/applicant/basic', { name: name, profileTypes: profileTypes });
  }

  updateApplicantProfileSectionByAdmin(index: number, section: FormSectionModel, applicantId: string): Observable<HttpResponse<any>> {
    return this.http.put<any>('/webapi/applicant/' + applicantId +'/section/' + section.id + '/' + index, this._applicantHelperService.mapToApplicantFieldValueDTOArray(section));
  }

  GetApplicantProfileSectionCalculateAndRollupFields(section: CustomizationSettingsSectionModel, applicantId: string, sectionIndex: number): Observable<any> {
    let applicant: number = 0;
    if (applicantId) {
      applicant = +applicantId;
    }
    return this.http.post<any>('/webapi/applicant/getRealTimeValues/' + applicant + '/' + section.id + '/' + sectionIndex, this._applicantHelperService.mapToApplicantFieldValueDTOArray(section));
  }

  updateApplicantNameAndProfileTypeByAdmin(applicantId: string, name: string, profileTypes: number[], subTenants: number[]) {
    return this.http.put<any>('/webapi/applicant/' + applicantId + '/basic', { name: name, profileTypes : profileTypes, subTenants: subTenants});
  }

  getApplicantDetailById(applicantId): Observable<HttpResponse<any>> {
    let headers = this.commonHelperService.getCurrentLocaleHeader();
    return this.http.get<any>('/webapi/applicant/' + applicantId, { headers: headers, observe: 'response' });
  }

  getApplicantDetailByApplicationId(applicationId): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webApi/applicant/applicationV2/' + applicationId, { observe: 'response' });
  }


  addApplicantProfileSection(section: FormSectionModel): Observable<HttpResponse<any>> {
    return this.http.post<any>('/webapi/applicant/section/' + section.id, this._applicantHelperService.mapToApplicantFieldValueDTOArray(section));
  }

  addApplicantProfileSectionByAdmin(section: FormSectionModel, applicantId): Observable<HttpResponse<any>> {
    return this.http.post<any>('/webapi/applicant/' + applicantId + '/section/' + section.id, this._applicantHelperService.mapToApplicantFieldValueDTOArray(section));
  }

  uploadAttachment(data, key: string = ""): Observable<any> {
    if (!key) {
      return this.http.post('/webapi/upload', data, { observe: 'response' });
    }
    else {
      return this.http.post('/webapi/upload/'+ key, data, { observe: 'response' });
    }
  }

  uploadAttachmentWithScfid(data, scfid: number): Observable<any> {
    return this.http.post('/webapi/upload/file/'+ scfid, data, { observe: 'response' });
  }
}
