import { Injectable } from '@angular/core';
import { ApplicantDTO, ApplicantFieldValueDTO, ApplicantSectionDTO } from '../../models/ApplicantDTO';
import { CustomizationSettingsSubmitModel, CustomizationSettingsSectionModel, CustomizationSettingsField, CustomizationSettingsLookupModel} from '../../models/CustomizationSettingsSubmitModel';
import { FeildType } from '../../common/enums/field-type-enum';
import { CustomFieldService } from './custom-field.service';
import { Tenant } from '../../models/tenant.model';
@Injectable({
    providedIn: 'root'
})
export class ApplicantDetailsHelperService {

  constructor() {

  }

  mapToApplicantDTOForProfileWizard(submitModel: CustomizationSettingsSubmitModel, applicantSavedModel: ApplicantDTO): ApplicantDTO {
    let applicant: ApplicantDTO = new ApplicantDTO();
    applicant.applicantSections = [];
    applicant.id = submitModel.id;
    applicant.name = submitModel.name;
    applicant.emailAddress = submitModel.emailAddress;
    var i;
    for (i = 0; i < submitModel.sections.length; i++) {
      var j;
      let applicantFieldValueDTO: ApplicantFieldValueDTO[] = [];
      for (j = 0; j < submitModel.sections[i].fields.length; j++) {
        if (submitModel.sections[i].fields[j].value != '') {
          let applicantFieldValueItemDTO: ApplicantFieldValueDTO = new ApplicantFieldValueDTO();
          applicantFieldValueItemDTO.sectionFieldId = submitModel.sections[i].fields[j].sectionCustomFieldId;
          applicantFieldValueItemDTO.value = submitModel.sections[i].fields[j].value;
          if (submitModel.sections[i].fields[j].type == FeildType.Money && submitModel.sections[i].fields[j].value) {
            applicantFieldValueItemDTO.value = submitModel.sections[i].fields[j].value + ':' + submitModel.sections[i].fields[j].selectedCurrencyId + ':' + submitModel.sections[i].fields[j].selectedCurrencySymbol;
          }
          applicantFieldValueDTO.push(applicantFieldValueItemDTO);
        } else if (submitModel.sections[i].fields[j].type == 6) {
          var commaSepVal = [];
          for (let lookup of submitModel.sections[i].fields[j].lookupValues) {
            if (lookup.isSelected) {
              commaSepVal.push(lookup.id)
            }
          }
          if (commaSepVal.length > 0) {
            applicantFieldValueDTO.push({ sectionFieldId: submitModel.sections[i].fields[j].sectionCustomFieldId, value: commaSepVal.join(',') });
          }
        }
      }
      applicant.applicantSections.push({ sectionId: submitModel.sections[i].id, values: applicantFieldValueDTO, index: 0, lastUpdatedOn: new Date() });
      if (submitModel.sections[i].isMultipule) {
        let savedSection: ApplicantSectionDTO[] = applicantSavedModel.applicantSections.filter(s => s.sectionId == submitModel.sections[i].id);
        savedSection.splice(0, 1);
        savedSection.forEach(obj => {
          applicant.applicantSections.push(obj);
        });
      }
    }
    return applicant;
  }

  mapToApplicantFieldValueDTOArray(setting: any): ApplicantFieldValueDTO[]{
    let applicantFieldValueDTO: ApplicantFieldValueDTO[] = [];
    for (let j = 0; j < setting.fields.length; j++) {
      if (setting.fields[j].value != '') {
        var applicantFieldValueItem: ApplicantFieldValueDTO = { sectionFieldId: setting.fields[j].sectionCustomFieldId, value: setting.fields[j].value };
        if (setting.fields[j].type == FeildType.Money && setting.fields[j].value) {
          applicantFieldValueItem.value = setting.fields[j].value + ':' + setting.fields[j].selectedCurrencyId + ':' + setting.fields[j].selectedCurrencySymbol;
        } else if(setting.fields[j].type == FeildType.Date && setting.fields[j].value){
          applicantFieldValueItem.value = CustomFieldService.formatValue(setting.fields[j]);
        }
        applicantFieldValueDTO.push(applicantFieldValueItem);

      } else if (setting.fields[j].type == 6) {
        var commaSepVal = [];
        for (let lookup of setting.fields[j].lookupValues) {
          if (lookup.isSelected) {
            commaSepVal.push(lookup.id)
          }
        }
        if (commaSepVal.length > 0) {
          applicantFieldValueDTO.push({ sectionFieldId: setting.fields[j].sectionCustomFieldId, value: commaSepVal.join(',') });
        }
      }
    }
    return applicantFieldValueDTO;
  }

  applicantModel: ApplicantDTO;
  submitModel: CustomizationSettingsSubmitModel = new CustomizationSettingsSubmitModel();
  mapToViewModel(name: string, email: string, picUrl: string, sections: CustomizationSettingsSectionModel[], applicantModel: ApplicantDTO, idenediKey: string = '', subTenants: Tenant[]) {
    this.submitModel.name = name;
    this.submitModel.emailAddress = email;
    this.submitModel.profileTemplateString = applicantModel.profileTemplateString;
    this.submitModel.profileTemplates = applicantModel.profileTemplates;
    this.submitModel.profilePicUrl = picUrl;
    this.submitModel.sections = sections;
    this.submitModel.idenediKey = idenediKey;
    this.submitModel.subTenants = subTenants;

    this.applicantModel = applicantModel;
    for (var i = 0; i < this.submitModel.sections.length; i++) {
      let section = this.applicantModel.applicantSections.filter(x => x.sectionId == this.submitModel.sections[i].id);
      if (section.length == 1) {
        this.submitModel.sections[i].lastUpdatedOn = section[0].lastUpdatedOn;
      } else if (section.length > 1){
        section = section.sort(function (a, b) {
          return (new Date(a.lastUpdatedOn).getTime() > new Date(b.lastUpdatedOn).getTime()) ? 1 : ((new Date(b.lastUpdatedOn).getTime() > new Date(a.lastUpdatedOn).getTime()) ? -1 : 0);
        });
        this.submitModel.sections[i].lastUpdatedOn = section[section.length-1].lastUpdatedOn;
      }

      if (new Date(this.submitModel.sections[i].lastUpdatedOn).getFullYear() < 2010) {
        this.submitModel.sections[i].lastUpdatedOn = null;
      }

      if (this.submitModel.sections[i].isMultipule) {
        var nestedSectionsData = this.applicantModel.applicantSections.filter(item => item.sectionId == this.submitModel.sections[i].id);
        if (nestedSectionsData.length > 0) {
          for (var j = 0; j < nestedSectionsData.length; j++) {
            if (nestedSectionsData[j].values.length > 0) {
              this.addNestedSection(this.submitModel.sections[i], true, j);
            }
          }
        }
        //else {
        //  if (this.submitModel.sections[i].nestedSections.length == 0) {
        //    this.submitModel.sections[i].nestedSections.push(this.submitModel.sections[i]);
        //    this.submitModel.sections[i].nestedSections[0].index = 0;
        //  }
        //}
      }
      else {
        this.fillSectionFieldsData(i);
      }
    }
    return this.submitModel;
  }

  fillSectionFieldsData(i: number) {
    for (let field of this.submitModel.sections[i].fields) {
      this.fillFieldsData(field, this.submitModel.sections[i].id, 0);
    }
  }

  fillFieldsData(field: CustomizationSettingsField, sectionId: number, index: number) {
    var nestedSectionsData = this.applicantModel.applicantSections.filter(item => item.sectionId == sectionId && item.index == index)[0];
    if (nestedSectionsData) {
      var fieldData = nestedSectionsData.values.filter(item => item.sectionFieldId == field.sectionCustomFieldId)[0];
      if (fieldData) {
        if (field.type == 5) {
          //single selection
          field.value = fieldData.value;
        } else if (field.type == 6) {
          //multiple selection
          var lookupDataArray = fieldData.value.split(',');
          for (let lookup of field.lookupValues) {
            lookup.isSelected = false;
            for (let item of lookupDataArray) {
              if (lookup.id == item) {
                lookup.isSelected = true;
              }
            }
          }
          //this.validateCheckbox(field, field.isRequired, null);
        } else if (field.type == 7) {//attachment
          field.value = fieldData.value;
          field.details = fieldData.details;
          //if (field.details && field.details.name && field.details.name.length > 20) {
          //  field.details.name = field.details.name.substring(0, 19);
          //  field.details.name += '...';
          //}
        } else if (field.type == FeildType.LookupAddon) {
          field.value = fieldData.value;
          field.lookupValue = fieldData.lookupValue;
        } else if (field.type == FeildType.Mapped) {
          field.value = fieldData.value;
          field.targetFieldType = fieldData.type;
          if (fieldData.type == FeildType.Attachment) {
            field.details = fieldData.details;
          }
        }
        else {
          field.value = fieldData.value;
        }
      } else {
        //user has not answered this field
        if (field.type == 5) {
          //single selection
          for (let item of field.lookupValues) {
            if (item.isSelected) {
              field.value = item.id;
              break;
            }
          }
        }
      }
    }
  }

  addNestedSection(section: CustomizationSettingsSectionModel, fillData: boolean, index) {
    let newNestedSection = Object.assign({}, section);
    newNestedSection.index = index;
    let fields: CustomizationSettingsField[] = [];
    for (let item of section.fields) {
      if (!item.isReadOnly) {
      item.value = '';
      }
      if (fillData) {
        this.fillFieldsData(item, newNestedSection.id, newNestedSection.index);
      }
      let lookups: CustomizationSettingsLookupModel[] = [];
      for (let lookup of item.lookupValues) {
        lookups.push({ id: lookup.id, isSelected: lookup.isSelected, label: lookup.label, order: lookup.order, color: lookup.color, conditions: lookup.conditions });
      }
      fields.push({
        selectedCurrencySymbol: item.selectedCurrencySymbol ,id: item.id, isReadOnly: item.isReadOnly, isRequired: item.isRequired, label: item.label, lookupValues: lookups, maxDate: item.maxDate, maxVal: item.maxVal,
        minDate: item.minDate, minVal: item.minVal,min: item.min, max: item.max, objectId: item.objectId, order: item.order, type: item.type, value: fillData ? item.value : '', valueDate: item.valueDate,
        sectionTemplateFiledId: item.sectionTemplateFiledId, fileContent: null, isVisible: item.isVisible, details: item.details, lookUpId: item.lookUpId, lookupValue: item.lookupValue,
        allowedValuesCriteria : item.allowedValuesCriteria, isTigger: item.isTigger, sectionCustomFieldId: item.sectionCustomFieldId
      });
    }
    section.nestedSections.push({
      id: newNestedSection.id,
      defaultName: newNestedSection.defaultName,
      fields: fields,
      nestedSections: [], index: newNestedSection.index, isMultipule: true, order: newNestedSection.order,
      isExpended: false,
      isRequiredMissing: false,
      isActive: newNestedSection.isActive,
      lastUpdatedOn: newNestedSection.lastUpdatedOn,
      type: newNestedSection.type,
      isDefault: newNestedSection.isDefault,
      // isVisible: newNestedSection.isVisible

    });
  }

  fileNameSufixWithDot(name: string) {
    if (name && name.length > 20) {
      return name.substring(0, 19) + '...';
    }
    else {
      return name;
    }
  }
}
