import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';


import { LabelService } from '../../common/services/label-service';
import { ThemeService } from '../../common/services/theme.service';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { ComponentName } from '../../common/enums/component-names-enum';

@Component({
  selector: 'app-incomplete-profile',
  templateUrl: './incomplete-profile.component.html',
  styleUrls: ['./incomplete-profile.component.less']
})
export class IncompleteProfileComponent implements OnInit {
  callFrom: string = '';
  componentName: typeof ComponentName = ComponentName;
  public editable: boolean;
  constructor(public dialogRef: MatDialogRef<IncompleteProfileComponent>, private renderer: Renderer2
    , public labelService: LabelService, private route: Router, public _dataSharingService: DataSharingService, public themeService: ThemeService
    , @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.editable = data.editable;
      this.callFrom = data.callFrom;
    }
  }

  ngOnInit() {
    // if (this.callFrom == this.componentName.AddApplicantRequestDialogComponent) {
    //   this.logOut();
    // }
  }
  logOut() {
    sessionStorage.clear();
  }
  gotoProfile() {
    this.dialogRef.close();
    this.route.navigateByUrl('/profile');
  }
}
