import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabelService } from '../../common/services/label-service';
import { ApplicantService } from '../../applicant/applicant.service';
import { AccountService } from '../services/account.service';
import { InvitationLinkInformation } from '../../models/invitation-link-information';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { MessageService } from '../../common/services/message-service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';

@Component({
  selector: 'app-verify-applicant',
  templateUrl: './verify-applicant.component.html',
  styleUrls: ['./verify-applicant.component.less', '../login/login.component.scss']
})
export class VerifyApplicantComponent implements OnInit {

  constructor(private _route: ActivatedRoute
    , private _router: Router
    , public labelService: LabelService
    , private _applicantService: ApplicantService
    , private _accountService: AccountService
    , private _dataSharingService: DataSharingService
    , private _messageService: MessageService
    , private _translationHelperService: TranslationHelperService
  ) { }

  public key: string;
  public definitionKey: string;
  invitationLinkInformation: InvitationLinkInformation = new InvitationLinkInformation;

  ngOnInit() {
    sessionStorage.clear();
    //localStorage.clear();
    this.labelService.updateClientLabel();

    this._route.params.subscribe(params => {
      this.key = params['key'];
      this.definitionKey = params['definitionkey'];
    });

    this._accountService.getInvitationLinkInformation(this.definitionKey).subscribe(resp => {
      this.invitationLinkInformation = resp;
      if (this.invitationLinkInformation.definitionId == 0) {
        this._router.navigateByUrl('/invalidlink');
      }
      else {
        this.invitationLinkInformation.definitionKey = this.definitionKey;

        this._dataSharingService.invitationLinkInformation.next(this.invitationLinkInformation);
        this._applicantService.verifyApplicant(this.key).subscribe(resp => {
          this._messageService.showMessage(this._translationHelperService.getTranslationByKey('Verify-Applicant.ACCOUNT-ACTIVATED'));
          this._router.navigateByUrl('/login/' + resp);
        }, error => {
          this._router.navigateByUrl('/invalidlink');
        });
      }

    });
  }

}
