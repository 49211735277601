import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { root } from "rxjs/internal-compatibility";
import { OrgSettings } from "../../models/org-settings.model";
import { Observable } from "rxjs-compat";
import { OrgSettingsService } from "./org-settings.service";
import { catchError, shareReplay, switchMap, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class OrgSettingsStore {

    // private subject = new BehaviorSubject<OrgSettings>(null);

    orgSettings$: Observable<OrgSettings>;// = this.subject.asObservable();
    private orgSettings: OrgSettings = null; 
    constructor(
        private orgSettingsService: OrgSettingsService, 
        private http: HttpClient){
        this.orgSettings$ = this.loadOrgSettigs();
    }

    private loadOrgSettigs(): Observable<OrgSettings>{
        if(!this.orgSettings){
            return this.orgSettingsService.getOrgSettings().pipe(
                tap(settings => this.orgSettings = settings)
                //tap(settings => this.subject.next(settings))
            );//.subscribe();
        } else {
            of(this.orgSettings);
        }
    }

    saveAttachmentSettings(settings: Partial<OrgSettings>): Observable<any> {
        this.orgSettings = null; 
        return this.http.post('/webapi/orgSettings/attatchment', settings).pipe(
          tap(() => this.loadOrgSettigs())
        );
      }
}