import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { AdminApplicationStatus, AdminApplicationStatusPost, ApplicationStatusPost } from '../../common/enums/application-status-enum';
import { ApplicationFeedbackModel } from '../../models/ApplicationFeedbackModel';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { CustomFieldFormService } from '../../common/services/custom-field-form.service';
import { ApplicationDetail, LabelValue } from '../../models/applicationdetailmodel';
import { DefinitionStageModel } from '../../definitions/models/definition-stage';
import { StageCriteria } from '../../definitions/models/stage-criteria';
import { OrganizationUser } from '../../organization/models/organization-user';
import { AssinedApplicationModel } from '../../models/assinged-application-model';
import { GetApplicationListType } from '../../common/enums/get-application-list-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { FieldValue } from '../../models/iframe/definition-filter.model';
import { map } from 'rxjs/operators';
import { StageCommentModel } from '../../models/stage-commen.model';
import { FormArray, FormGroup } from '@angular/forms';
import { FolderDefinition, FolderDefinitionUsers } from '../../models/folder-definition.model';
import { SubmissionOption } from '../../common/enums/submission-option.enum';
import { NameId } from '../../models/UserResult';
import { app } from 'firebase';
import { ApplicationHistory } from '../../models/application-history.model';
import { ApplicationArchivedForm } from '../../models/application-archived-form.model';
import { ApplicationDocument } from '../../models/application-document.model';
import { CustomFieldModel, FieldUniqueCheckModel } from '../../profile-customization/Model/CustomFieldModel';
import { ApplicationDefinition } from '../../definitions/models/application-definition';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  constructor(
    private http: HttpClient,
    private _translationHelperService: TranslationHelperService,
    private translate: TranslateService
  ) { }

  getApplicationDetails(applicationId: number, filledFormId: number = 0, revise: boolean = false): Observable<HttpResponse<any>> {
    let revised = revise ? 'yes'  : ''
    return this.http.get<any>(`/webapi/application/detailsv3/${applicationId}?ffId=${filledFormId}&revise=${revised}`, { observe: 'response', headers: { 'locale': this.translate.currentLang } });
  }
  getApplicationDetailsByKeyAnonymous(applicationKey: any, code: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(`/webapi/application/bykey/${applicationKey}?code=${code}`, { observe: 'response', headers: {'locale': this.translate.currentLang }});
  }

  getLinkApplicationDetails(applicationId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/application/linkedApplicationInfo/' + applicationId, 
    { 
      observe: 'response', 
      headers: { 'locale': this.translate.currentLang } 
    });
  }
  getActionHistory(applicationId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/actionhistory/' + applicationId, { observe: 'response' });
  }

  getApplicationsWithPaging(pageNo: number, searchText: string, applicationStatus: number, recordPerPage: number, sortBy: number, isMySpace: boolean, applicantId: string, definitionId: string, linkApplicationId: string = null): Observable<any> {
    return this.http.get<Observable<any>>('/webapi/application/list?search=' + searchText + '&filter=' + applicationStatus + '&PageNo=' + pageNo + '&RecordPerPage=' + recordPerPage + '&isMySpace=' + isMySpace + '&sortBy=' + sortBy + '&applicantId=' + applicantId + '&definationId=' + definitionId + "&linkApplicationId=" + linkApplicationId, { observe: 'response' });
  }

  getDefinitionsForFilter(pageNo: number, searchText: string, applicationStatuses: number[], recordPerPage: number, sortBy: number, isMySpace: boolean, myApplications: boolean, applicantId: number, definitionId: number, parentApplicationId: number = 0, definitionIds: number[] = [], applicationListType: GetApplicationListType = GetApplicationListType.Open, fieldValues: FieldValue[] = [], isRecord = false): Observable<any> {
    const searchObject = {
      Search: searchText,
      Statuses: applicationStatuses,
      PageNo: pageNo,
      RecordPerPage: recordPerPage,
      //IsMySpace: isMySpace,
      //myApplications: myApplications,
      type: applicationListType,
      SortBy: sortBy,
      ApplicantId: applicantId,
      DefinationId: definitionId,
      Categoreis: [],
      ParentApplicationId: parentApplicationId,
      definitionIds: definitionIds.find(x => x === -1) ? [] : definitionIds.filter(x => x !== -1),
      fieldValues: fieldValues
    }
    return this.http.post<Observable<any>>(`/webapi/application/definitionForFilterv2?type=${isRecord ? 'record' : 'definition'}`,
      searchObject,
      {
        headers: { 'locale': this.translate.currentLang }
      }
    );
  }

  //Obsolete because getting total count effects performance for large data sets 
  getApplicationsWithPagingV2(pageNo: number, searchText: string, applicationStatuses: number[], recordPerPage: number, sortBy: number, isMySpace: boolean, myApplications: boolean, applicantId: number, definitionId: number, parentApplicationId: number = 0, definitionIds: number[] = [], applicationListType: GetApplicationListType = GetApplicationListType.Open, fieldValues: FieldValue[] = [], defFieldsSearchValues: NameId[] = []): Observable<any> {
    if (definitionId) {
      definitionIds = [definitionId];
    }
    const searchObject = {
      Search: searchText,
      Statuses: applicationStatuses,
      PageNo: pageNo,
      RecordPerPage: recordPerPage,
      //IsMySpace: isMySpace,
      //myApplications: myApplications,
      type: applicationListType,
      SortBy: sortBy,
      ApplicantId: applicantId,
      DefinationId: definitionId,
      Categoreis: [],
      ParentApplicationId: parentApplicationId,
      definitionIds: definitionIds.find(x => x === -1) ? [] : definitionIds.filter(x => x !== -1),
      fieldValues: fieldValues,
      searchValues: defFieldsSearchValues
    }
    return this.http.post<Observable<any>>('/webapi/application/listV4', searchObject, { headers: { 'locale': this.translate.currentLang } });
  }

  //used with infinite scroll 
  getApplicationsWithPagingV5(pageNo: number, searchText: string, applicationStatuses: number[], recordPerPage: number, sortBy: number, isMySpace: boolean, myApplications: boolean, applicantId: number, definitionId: number, parentApplicationId: number = 0, definitionIds: number[] = [], applicationListType: GetApplicationListType = GetApplicationListType.Open, fieldValues: FieldValue[] = [], defFieldsSearchValues: NameId[] = []): Observable<any> {
    if (definitionId) {
      definitionIds = [definitionId];
    }
    const searchObject = {
      Search: searchText,
      Statuses: applicationStatuses,
      PageNo: pageNo,
      RecordPerPage: recordPerPage,
      //IsMySpace: isMySpace,
      //myApplications: myApplications,
      type: applicationListType,
      SortBy: sortBy,
      ApplicantId: applicantId,
      DefinationId: definitionId,
      Categoreis: [],
      ParentApplicationId: parentApplicationId,
      definitionIds: definitionIds.find(x => x === -1) ? [] : definitionIds.filter(x => x !== -1),
      fieldValues: fieldValues,
      searchValues: defFieldsSearchValues
    }
    return this.http.post<Observable<any>>('/webapi/application/listV5', searchObject, { headers: { 'locale': this.translate.currentLang } });
  }

  getApplicationsWithPagingV5Count(pageNo: number, searchText: string, applicationStatuses: number[], recordPerPage: number, sortBy: number, isMySpace: boolean, myApplications: boolean, applicantId: number, definitionId: number, parentApplicationId: number = 0, definitionIds: number[] = [], applicationListType: GetApplicationListType = GetApplicationListType.Open, fieldValues: FieldValue[] = [], defFieldsSearchValues: NameId[] = []): Observable<number> {
    if (definitionId) {
      definitionIds = [definitionId];
    }
    const searchObject = {
      Search: searchText,
      Statuses: applicationStatuses,
      PageNo: pageNo,
      RecordPerPage: recordPerPage,
      //IsMySpace: isMySpace,
      //myApplications: myApplications,
      type: applicationListType,
      SortBy: sortBy,
      ApplicantId: applicantId,
      DefinationId: definitionId,
      Categoreis: [],
      ParentApplicationId: parentApplicationId,
      definitionIds: definitionIds.find(x => x === -1) ? [] : definitionIds.filter(x => x !== -1),
      fieldValues: fieldValues,
      searchValues: defFieldsSearchValues
    }
    return this.http.post<number>('/webapi/application/listV5/count', searchObject, { headers: { 'locale': this.translate.currentLang } });
  }
  //obsolete
  getApplicationsAssinged(pageNo: number, searchText: string, applicationStatuses: number[], recordPerPage: number, sortBy: number, isMySpace: boolean, myApplications: boolean, applicantId: string, definitionId: string, parentApplicationId: number = 0, definitionIds: number[] = [], fieldValues: FieldValue[] = []): Observable<any> {
    const searchObject = {
      Search: searchText,
      Statuses: applicationStatuses,
      PageNo: pageNo,
      RecordPerPage: recordPerPage,
      IsMySpace: isMySpace,
      myApplications: myApplications,
      SortBy: sortBy,
      ApplicantId: applicantId,
      DefinationId: definitionId,
      Categoreis: [],
      ParentApplicationId: parentApplicationId,
      definitionIds: definitionIds.find(x => x === -1) ? [] : definitionIds.filter(x => x !== -1),
      fieldValues: fieldValues
    }
    return this.http.post<Observable<any>>('/webapi/application/assigned', searchObject, { headers: { 'locale': this.translate.currentLang } });
  }

  getApplicationListItem(applicationId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/application/' + applicationId, { observe: 'response' });
  }
  //http://localhost:55334/webapi/application/list?search=&filter=0&pageNo=1&recordPerPage=10&isMySpace=true&sortBy=0

  dateDifference(submitedDate): string {
    let currDate = new Date;
    let diff = Math.floor(currDate.getTime() - new Date(submitedDate).getTime());
    let day = 1000 * 60 * 60 * 24;

    let days = Math.floor(diff / day);
    let months = Math.floor(days / 31);
    let years = Math.floor(months / 12);

    let message = days.toString();
    if (days == 0) {
      return 'Today'
    }

    if (days == 1) {
      message += ' ' + this._translationHelperService.getTranslationByKey('day');
    } else {
      message += ' ' + this._translationHelperService.getTranslationByKey('days');
    }

    return message;
  }

  sendComment(model) {
    return this.http.put('/webapi/assessment/comments', model);
  }
  resendAction(applicationid, model) {
    return this.http.put('/webapi/actionhistory/retry/' + applicationid, model);
  }
  updateAllowSubmission(applicationId, isSubmissionAllowed) {
    return this.http.put('/webapi/application/allowLinkedApplicationSubmission/' + applicationId + '/' + isSubmissionAllowed, {});
  }

  respondCriteria(model) {
    return this.http.post('/webapi/application/respondv2', model);
  }

  getApplicationFeedbackList(applicationId: number): Observable<HttpResponse<ApplicationFeedbackModel[]>> {
    return this.http.get<any>('/webapi/application/feedback/' + applicationId, { observe: 'response' });
  }

  addApplicationFeedback(data: any) {
    return this.http.put<any>('/webApi/application/comments', data);
  }

  deleteComment(commentId: number) {
    return this.http.delete<any>('/webApi/application/comment?commentId=' + commentId);
  }

  deleteCommentAttachment(fileGuid: string) {
    return this.http.delete<any>(`/webApi/application/commentAttachment?fileGuid=${fileGuid}`);
  }

  makeApplicationFeedbackVisibleToApplicant(model: any) {
    return this.http.post('/webapi/application/feedback/visibletoapplicant/' + model.applicationId, model.feedback);
  }

  saveCriteriaForm(model: any, assessmentId: number) {
    return this.http.post('/webapi/application/assessment/values?assessmentId=' + assessmentId, model);
  }

  getApplicationSubApplications(applicationId, allowedValuesCriteria): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/application/subApplications/' + applicationId + '/' + allowedValuesCriteria, { observe: 'response' });
  }

  applicationAssessmentDisable(applicationId: number, assessmentId: number) {
    return this.http.put('/webapi/application/assessment/disable/' + applicationId + '/' + assessmentId, {});
  }

  applicationAssessmentEnable(applicationId: number, assessmentId: number) {
    return this.http.put('/webapi/application/assessment/enable/' + applicationId + '/' + assessmentId, {});
  }

  generateApplicationPDF(applicationId: number, locale: string, applicationNumber: string) {
    return this.http.get('/webapi/application/pdf/' + applicationId + '/' + locale, { observe: 'response', responseType: 'blob' }).pipe(map(
      data => {
        var file = new Blob([data.body], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL); // if you want to open it in new tab
        var a         = document.createElement('a');
        a.href        = fileURL;
        a.target      = '_blank';
        a.download    = applicationNumber + '.pdf';
        document.body.appendChild(a);
        a.click();
        return data;
      }
    ));
    //environment.baseUrl
    //window.open('http://localhost:55334/webapi/application/pdf/12', '_blank');
  }

  generateStagePDF(stageId: number, applicationId: number, locale: string) {
    return this.http.get('/webapi/application/stagepdf/' + stageId + '/' + applicationId + '/' + locale, { observe: 'response', responseType: 'blob' });
    //environment.baseUrl
    //window.open('http://localhost:55334/webapi/application/pdf/12', '_blank');
  }

  private _getCalculatedFieldsPath: string = '/webapi/application/getCalculatedValuesV2';
  getCalcuateValues(definition: any, definitionKey: string = null, onBehalfApplicantId: number = 0, resubmit = false): Observable<any> {
    definition.sectionValues = CustomFieldFormService.mapFormFieldsPostValue(definition.form.sections);
    if (definitionKey) {
      return this.http.post<any>(`${this._getCalculatedFieldsPath}?onBehalf=${onBehalfApplicantId}&definitionKey=${definitionKey}&foredit=${resubmit ? 1 : 0}`, definition, { headers: { 'locale': this.translate.currentLang } });
    } else {
      return this.http.post<any>(`${this._getCalculatedFieldsPath}?onBehalf=${onBehalfApplicantId}&foredit=${resubmit ? 1 : 0}`, definition, { headers: { 'locale': this.translate.currentLang } });
    }
  }

  getCalcuateValuesForCriteria(definition: ApplicationDetail, definitionKey: string): Observable<any> {
    definition.stages.forEach((stage: DefinitionStageModel) => {
      stage.criteriaList.forEach((criteria: StageCriteria) => {
        if (criteria.form.sections.length > 0) {
          criteria.sectionValues = CustomFieldFormService.mapFormFieldsPostValue(criteria.form.sections);
          //criteria.formValues = CustomFieldFormService.mapFormFieldsPostValueForCriteria(criteria.form.sections[0]);
        }
      });
    });
    const defToPost: ApplicationDetail = JSON.parse(JSON.stringify(definition));
    defToPost.form.sections.forEach(sec => {
      delete sec.nestedSections;
    });
    defToPost.stages.flatMap(s => s.criteriaList).forEach(criteria => {
      criteria.form.sections.forEach(sec => {
        delete sec.nestedSections;
      });
    })
    return this.http.post<any>(this._getCalculatedFieldsPath + `?applicationKey=${definitionKey}`, defToPost);
  }

  checkCustomFieldValueUnique(id:number, value: string, applicationId: number):Observable<FieldUniqueCheckModel>{
    let obj={
      value: value,
      fieldSCFId: id,
      applicationId: applicationId
    }

    return this.http.put<FieldUniqueCheckModel>('/webapi/fieldValue/isUnique', obj, { headers: { 'locale': this.translate.currentLang } });
  }

  saveReassginOwner(model: any, applicationId: number, newOwnerId: number) {
    return this.http.post('/webapi/application/reassign/assessment?applicationId=' + applicationId + '&' + 'newOwnerId=' + newOwnerId, model);
  }
  saveReassignV2(model:any) {
    return this.http.put('/webapi/assessment/reassignV2', model);
  }

  cancelRequest(applicationId: number, comments: string, notifiedToPersonaIds: number[], notifyApplicant: boolean) {
    let submitModel = {
      applicationid: applicationId,
      comments: comments,
      notifiedToPersonaIds: notifiedToPersonaIds,
      notifyApplicant: notifyApplicant
    }
    return this.http.put('/webapi/application/cancel', submitModel);
  }

  reactivateRequest(applicationId: number) {
    return this.http.put(`/webapi/application/reactivate?applicationId=${applicationId}`, {});
  }

  getApplicationOwners(applicationId: number): Observable<HttpResponse<OrganizationUser[]>> {
    return this.http.get<OrganizationUser[]>(`/webapi/assessment/assessors/active?applicationId=${applicationId}`, { observe: 'response' });
  }

  getFeeds(dismissed = false): Observable<HttpResponse<{ applications :AssinedApplicationModel[], totalRecords : number}>> {
    return this.http.get<{ applications :AssinedApplicationModel[], totalRecords : number}>('/webapi/SubmittalRequest/feeds?dismissed=' + dismissed, { observe: 'response', headers: { 'locale': this.translate.currentLang } });
  }

  getApplicationFeeds(feedType: SubmissionOption, dismissed = false): Observable<{ applications :AssinedApplicationModel[], totalRecords : number}> {
    if(feedType == SubmissionOption.Assign){
      return this.http.get<{ applications :AssinedApplicationModel[], totalRecords : number}>('/webapi/submittalRequest/feeds/assigned?dismissed=' + dismissed, { observe: 'response', headers: { 'locale': this.translate.currentLang } }).pipe(
        map(resp => resp.body)
      );
    }else{
      return this.getFeeds(dismissed).pipe(
        map(resp => resp.body)
      );
    }
  }

  dismiss(applicationId: number, applicationType: string): Observable<any> {
    return this.http.get<any>(`/webapi/application/dismiss/${applicationId}/${applicationType}`, { observe: 'response' });
  }

  postQuestion(applicationId: number, question: StageCriteria): Observable<any> {
    return this.http.post(`/webapi/application/question?applicationId=${applicationId}`, question);
  }

  openStage(applicationId: number, stageId: number, assessmentId: number, comments: string): Observable<any> {
    let model = {
      applicationId: applicationId,
      stageToMoveId: stageId,
      assessmentId: assessmentId,
      comments: comments
    }
    return this.http.post(`/webapi/application/openStage`, model);
  }

  openStageComments(applicationId: number, stageId: number): Observable<StageCommentModel[]> {
    return this.http.get<any>(`/webapi/application/openStageComments/${applicationId}/${stageId}`, { observe: 'response' }).pipe(
      map(resp => resp.body)
    );
  }
  setRequestSubmitters(applicationId, childApplicantsArray: FormArray, notifySubmitters: boolean): Observable<any> {
    let endPoint = '/webapi/submittalRequest/SubmissionsSubmitters';
    let model = {
      applicationId: applicationId,
      allowedSubmitters: childApplicantsArray.value,
      notifySubmitter: notifySubmitters
    }
    return this.http.put<any>(endPoint, model);
  }
  getFolderReferenceApplications(applicationId: number, definitionId: number): Observable<FolderDefinition[]> {
    return this.http.get<any>(`/webapi/application/referenceApplications?applicationId=${applicationId}&definitionId=${definitionId}`)
  }

  getFolderReferenceApplicationsBasicInfo(applicationId: number, definitionId: number): Observable<FolderDefinition[]> {
    return this.http.get<any>(`/webapi/refapplications/all/${applicationId}/${definitionId}`)
  }

  getFolderReferenceApplicationsByDefIf(applicationId: number, referredDefinitionId: number, refernceSCFId: number, page: number = 0): Observable<FolderDefinition> {
    let query = '';
    if(page > 0){
      query = `?pageNo=${page}`
    }
    return this.http.get<any>(`/webapi/refapplications/details/${applicationId}/${referredDefinitionId}/${refernceSCFId}${query}`, { headers: { 'locale': this.translate.currentLang }})
  }

  postFolderReferenceApplicationsByDefIf(applicationId: number, referredDefinitionId: number, refernceSCFId: number, page: number, search: string, status: AdminApplicationStatus): Observable<FolderDefinition> {
    let payload = {
      applicationId: applicationId,
      referedDefinitionId: referredDefinitionId,
      refernceSCFId: refernceSCFId,
      pageNo: page,
      isAll: status == AdminApplicationStatus.All,
      search: search
    }
    return this.http.post<any>(`/webapi/refapplications/detailsV2`, payload);
  }

  getAllowedUsers(refDefId: number): Observable<FolderDefinitionUsers>{
    return this.http.get<any>(`/webapi/refapplications/allowedUsers/${refDefId}`);
  }

  uploadDocument(data: FormData) {
    return this.http.post('/webapi/document', data)
  }
  updateDocument(data: FormData) {
    return this.http.put('/webapi/document', data)
  }
  deleteDocument(docId: number) {
    return this.http.delete('/webapi/document?id='+docId)

  }
  getApplicationDocuments(applicationId: string, search: string = ''): Observable<ApplicationDocument[]> {
    return this.http.post<ApplicationDocument[]>('/webapi/document/get', { search: search, holderId: applicationId })
  }
  ChangeApplicant(applicationId, newApplicantId): Observable<HttpResponse<any>> {
    return this.http.get<any>(`WebApi/application/ChangeApplicant?applicationId=${applicationId}&newApplicantId=${newApplicantId}`, { observe: 'response' });
  }
  deleteApplication(id :number){
    return this.http.delete('/webApi/record/'+id)

  }
  archivedApplication(id :number){
    return this.http.put('/webapi/record/Archived/'+id, {})

  }
  undoDeleteArchive(id: number){
    return this.http.put('/webapi/record/Undoarchived/'+id, {})
  }
  applicationHistory(applicationId: number) {
    return this.http.get<ApplicationHistory[]>('/webapi/application/history/' + applicationId, { headers: { 'locale': this.translate.currentLang } })
  }
  applicationisLock(applicationId: number, isLock: boolean) {
    return this.http.get<any>(`/webapi/application/lock/${applicationId}/${isLock}`);
  }

  canAccessParentApplication(parentApplicationId: number): Observable<boolean>{
    return this.http.get<boolean>(`/webapi/application/canaccess/${parentApplicationId}`);
  }
  getApplicationVersionsList(applicationId: number): Observable<ApplicationArchivedForm[]>{
    return this.http.get<ApplicationArchivedForm[]>(`/webapi/application/archivedVersions/${applicationId}`);
  }
  getAnonKey(applicationId: number) : Observable<string>{
    return this.http.get<string>('/webapi/application/key/' + applicationId);
  }
  returnApplication(applicationId: number, comments: string){
    let model = {
      applicationId: applicationId,
      comments: comments
    }
    return this.http.put('/webapi/application/return' , model);
  }
  getAskaquestionUsers(definitionId: number, applicationDetails:  ApplicationDefinition):Observable<any> {
    return this.http.post<any>('/webapi/application/askaquestionUsers/' + definitionId, applicationDetails );
  }

  getAssigntoUsers(definitionId: number, applicationDetails:  ApplicationDefinition):Observable<any> {
    return this.http.post<any>('/webapi/application/assigntoUsers/' + definitionId, applicationDetails );
  }
  getApplicantContactsInfo(applicantId: number) {
    return this.http.get<LabelValue[]>('/webapi/applicant/contactsInfo/' + applicantId , { headers: { 'locale': this.translate.currentLang } });
  }
  sendReminder(assessmentId: number) {
    return this.http.get<LabelValue[]>('/webapi/application/sendRemider/' + assessmentId , { headers: { 'locale': this.translate.currentLang } });
  }
  getDefinitionData(file: any, definitionId:  number, sectionId: number):Observable<any> {
    return this.http.post<any>(`/webapi/upload/getDefinitionData/${definitionId}/${sectionId}`, file );
  }
}
