import { Pipe, PipeTransform } from '@angular/core';
import { LookupAdonsModel } from '../../models/lookup-ad-ons-model';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { FeildType } from '../../common/enums/field-type-enum';
import { ComponentName } from '../enums/component-names-enum';

@Pipe({
  name: 'lookupAdonsFilter',
  pure: false
})
export class LookupAdonsIsmainFilter implements PipeTransform {

  transform(lookupAdon: LookupAdonsModel[], source: string = "", profileTemplateIds: number[] = []): any {
    if (!lookupAdon) {
      return lookupAdon;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    if (source == 'sidenavbar') {
      return lookupAdon.filter(lookup => (lookup.isVisible && lookup.isMain));
    } else if (source == 'applicanttabs') {
      return lookupAdon.filter(lookup => (lookup.isVisible && lookup.isShowToApplicant) && LookupAdonsIsmainFilter.filterByProfileTemplate(lookup, profileTemplateIds));
    } else if (source == 'addCustomFieldGlobal') {
      return lookupAdon.filter(lookup => (lookup.isVisible && !lookup.isVariable && lookup.id != -3));
    }
    //  else if (source == ComponentName.DefinitionStagesComponent) {
    // return lookupAdon.filter(lookup => (lookup.isVisible && lookup.id != -1 && lookup.id != -2));
    //}
    else if (source == 'criteria' || source == ComponentName.DefinitionStagesComponent) {
      return lookupAdon.filter(lookup => (lookup.isVisible && lookup.id != -3) && LookupAdonsIsmainFilter.filterByProfileTemplate(lookup, profileTemplateIds));
    } else if (source == 'idGreaterThanZero') {
      return lookupAdon.filter(lookup => (lookup.id > 0));
    }
    else {
      return lookupAdon.filter(lookup => (lookup.isVisible && lookup.id != -3) && LookupAdonsIsmainFilter.filterByProfileTemplate(lookup, profileTemplateIds));
    }
  }

  private static filterByProfileTemplate(lookup: LookupAdonsModel, templateIds: number[]) {
    if (templateIds.length == 0 || !lookup.isVariable || lookup.id < 0 || lookup.profileTemplateIds.filter(a => templateIds.some(b => a == b)).length > 0) {
      return true
    } else {
      return false;
    }
  }
}


@Pipe({
  name: 'lookupFieldNotIsTitleFilter',
  pure: false
})
export class LookupFieldNotIsTitleFilter implements PipeTransform {

  transform(fields: CustomFieldModel[]): any {
    if (!fields) {
      return fields;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return fields.filter(field => (!field.isTitleField && (field.type == FeildType.Text || field.type == FeildType.Number || field.type == FeildType.Date || field.type == FeildType.DateTime || field.type == FeildType.Applicant)));
  }

}
