<h2 fxLayout="row" fxLayoutAlign="center center" *ngIf="loader" style="height: 100vh">Signing In....</h2>
<app-auth-layout *ngIf="!loader">
  <div fxFlex>
    <h1 fxLayoutAlign="center center" [translate]="'Sign-in'">
    </h1>
    <div fxLayoutAlign="center center" class="description" *ngIf="idenediIdMappingError">
      Your IDENEDI account is not linked with {{labelService.labels?.application_short_name | translateToLocale}}. To
      link your IDENEDI account with {{labelService.labels?.application_short_name | translateToLocale}} please login
      below with {{labelService.labels?.application_short_name | translateToLocale}} 'Exceeders' credentials or contact
      administration.
    </div>
    <form #loginForm="ngForm" (ngSubmit)="OnSubmit()">
      <div fxLayout="column">
        <mat-form-field appearance="outline" fxFlex [class.form-field-rtl]="preferredLanguage?.direction === 'rtl'">
          <mat-label [translate]="'EMAIL-ID'"></mat-label>
          <input matInput #Email autocomplete="username" [formControl]="email" placeholder="{{'EMAIL-ID' | translate}}"
            name="email" class="login-input" required>
          <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex [class.form-field-rtl]="preferredLanguage?.direction === 'rtl'">
          <mat-label [translate]="'PASSWORD'"></mat-label>
          <input matInput #Password autocomplete="current-password" [formControl]="password" type="password"
            placeholder="{{'PASSWORD' | translate}}" name="password" required>
          <mat-error *ngIf="password.invalid">{{getPasswordErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <div [@slideUpDown]="isLoginError" *ngIf="isLoginError" class="login-error" [class.margin-t18]="isLoginError">
        {{loginErrorMsg}}
      </div>
      <div fxLayout="row" fxLayoutAlign="end start" class="frgt-pass" *ngIf="!(systemSettingsService?.systemSettings?.windowsAuth?.toLocaleLowerCase() === 'true')">
        <div><a mat-anchor class="auth-link" (click)="goto('reset')" [translate]="'FORGOT-PASSWORD'"></a></div>
      </div>
      <div fxLayout="column" class="login-btns-container">
        <div fxLayout="row" fxFlex style="width: 100%;">
          <button fxFlex mat-flat-button class="gradient-btn auth-btn" color="primary"
            [disabled]="email.invalid || password.invalid || isCallBack" *ngIf="!idenediIdMappingError">
            {{'Sign-in' | translate}}
          </button>
          <button fxFlex mat-flat-button class="gradient-btn auth-btn" [disabled]="isCallBack"
            *ngIf="idenediIdMappingError">
            Sign in to link
          </button>
        </div>
        <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="idenedi-login-container"
          *ngIf="systemSettingsService.systemSettings.idenediClientId && !idenediAuthToken">
          <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="line-breaker">
            <div fxFlex="48" class="line"></div>
            <div class="text">OR</div>
            <div fxFles fxFlex="48" class="line"></div>
          </div>
          <div class="btn-idenedi-login-container" fxFlex>
            <button fxFlex type="button" mat-button class="btn-idenedi-login auth-btn"
              (click)="redirectToIdenediLogin()" [disabled]="isCallBack || showIdenediLoader">
              <div fxLayout="row" fxLayoutAlign="center center">
                Sign in with IDENEDI
                <mat-spinner *ngIf="showIdenediLoader" class="idenedi-spinner" diameter="30" color="white">
                </mat-spinner>
              </div>
            </button>
          </div>
          <!--<div class="idenedi-login-link" (click)="redirectToIdenediLogin()">Login with iDenedi</div><mat-spinner *ngIf="showIdenediLoader" class="idenedi-spinner" diameter="30"></mat-spinner>-->
        </div>
      </div>
    </form>

    <div class="register-acc" *ngIf="(systemSettingsService?.systemSettings?.enableRegistration.toLocaleLowerCase() == 'true') && !(systemSettingsService?.systemSettings?.windowsAuth?.toLocaleLowerCase() === 'true')">
      <span [translate]="'LOGIN-NO-ACCOUNT'"></span>&nbsp;<a class="auth-link" (click)="goto('register')" [translate]="'REGISTER-NOW'"></a>
    </div>
  </div>
</app-auth-layout>