import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { Organization } from '../models/organization';
import { OrganizationUser } from '../models/organization-user';
import { Country } from '../../models/country';
import { DelegateUser } from '../../models/delegate-model';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { OrgConfigurationsModel } from '../../models/org-configurations.model';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const httpFileOptions = {
  headers: new HttpHeaders({ 'Content-Type': undefined })
};

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private http: HttpClient
    , private commonHelperService: CommonHelperService) {
  }

  getOrganization(): Observable<HttpResponse<Organization>> {
    return this.http.get<Organization>('/webapi/organization/getorganization', { observe: 'response' });
  }

  updateOrganization(organization: Organization): Observable<any> {
    return this.http.put<any>('/webapi/organization', organization);
  }

  deleteOrganizationLogo(): Observable<any> {
    return this.http.delete<any>('/webapi/organization/logo', httpOptions);
  }

  addUpdateOrganizationLogo(data: any): Observable<any> {
    return this.http.post<any>('/webapi/organization/logo', data);
  }

  getOrganizationLogo(): Observable<any> {
    return this.http.get<any>('/webapi/organization/logo');
  }

  getOrganizationUsers(): Observable<HttpResponse<OrganizationUser[]>> {
    return this.http.get<OrganizationUser[]>('/webapi/organization/users', { observe: 'response' });
  }

  getOrganizationUsersV2(pageNumber: number, searchText: string): Observable<any> {
    return this.http.post<any>('/webapi/organization/usersv2', { pageNumber: pageNumber, searchString: searchText });
  }
  getOrganizationUsersV3(pageNumber: number, recordsPerPage: number = 12, profileTemplateIds: number[] = [], roles: number[] = [], searchString: string): Observable<any> {
    return this.http.post<any>('/webapi/organization/usersv3', { pageNumber, searchString, recordsPerPage, profileTemplateIds, roles });
  }
  async asyncGetOrganizationUsers() {
    return await this.http.get<OrganizationUser[]>('/webapi/organization/users', { observe: 'response' }).toPromise();
  }

  // Removed from backend 
  // getCountries(): Observable<HttpResponse<Country[]>> {
  //   return this.http.get<Country[]>('/webapi/country/all', { observe: 'response' });
  // }

  addOrganizationUser(organizationUser: any): Observable<any> {
    return this.http.post<any>('/webapi/orguser', organizationUser);
  }

  updateOrganizationUser(organizationUser: OrganizationUser): Observable<any> {
    return this.http.put<any>('/webapi/orguser', organizationUser);
  }

  updateOrganizationUserRole(organizationUser: OrganizationUser): Observable<any> {
    return this.http.put<any>('/webapi/orguser', organizationUser);
  }

  updateOrganizationUserImage(organizationUserId: number, data): Observable<any> {
    if (organizationUserId == undefined || organizationUserId == null) {
      organizationUserId = 0;
    }
    return this.http.post<any>('/webapi/orguser/uploadimage/' + organizationUserId, data);
  }

  deleteOrganizationUserImage(organizationUserId: number): Observable<any> {
    return this.http.delete<any>('/webapi/orguser/deleteimage/' + organizationUserId, httpOptions);
  }

  reinviteOrganizationUser(organizationUserId: number): Observable<any> {
    return this.http.post<any>('/webapi/orguser/reinvite/' + organizationUserId, {});
  }

  activateOrganizationUser(organizationUserId: number): Observable<any> {
    return this.http.post<any>('/webapi/orguser/activateuser/' + organizationUserId, {});
  }

  canDeactivateOrganizationUser(organizationUserId: number): Observable<any> {
    let headers = this.commonHelperService.getCurrentLocaleHeader();
    return this.http.get<any>('/webapi/orguser/candeactivateuser/' + organizationUserId, { headers: headers });
  }

  deactivateOrganizationUser(organizationUserId: number): Observable<any> {
    return this.http.post<any>('/webapi/orguser/deactivateuser/' + organizationUserId, {});
  }

  postDelegatedUser(delegateUser: DelegateUser): Observable<any> {
    return this.http.post<any>('/webapi/delegate/', delegateUser, {});
  }

  revokeDelegatedUser(delegateUser: DelegateUser): Observable<any> {
    return this.http.put<any>('/webapi/delegate/revoke/', delegateUser, {});
  }
  // postDelegatedUser(): Observable<any> {
  //     return this.http.post<any>('/webapi/delegate/', {});
  // }

  addOrganizationUserV2(userData: any): Observable<any> {
    return this.http.post<any>('/webapi/orguser/CreateInviteUser', userData);
  }

  enableTechnadopt(isEnabled: boolean): Observable<any> {
    return this.http.put<any>(`/webapi/organization/enableTechnadopt/${isEnabled}`, {});
  }

  enableHigher(isEnabled: boolean): Observable<any> {
    return this.http.put<any>(`/webapi/organization/enableHigher/${isEnabled}`, {});
  }

  enablePlanex(isEnabled: boolean): Observable<any> {
    return this.http.put<any>(`/webapi/organization/enablePlanex/${isEnabled}`, {});
  }

  getConfigurations(): Observable<OrgConfigurationsModel> {
    return this.http.get<OrgConfigurationsModel>(`/webapi/organization/configurations`);
  }

  isIdenediLinked = false;
  isLinkedWithIdenedi(): Observable<boolean>{
    if(!this.isIdenediLinked){
      return this.http.get<boolean>(`/webapi/organization/isIdenediLinked`).pipe(
        map(isLinked => this.isIdenediLinked = isLinked)
      );
    }else{
      return of(true);
    }
  }

  enableApplicantsAsAssessors(isEnabled: boolean): Observable<any>{
    return this.http.put(`/webapi/organization/enableApplicantAsAssessor/${isEnabled}`, {});
  }

  getApplicantAsAssessor(): Observable<boolean>{
    return this.http.get<boolean>(`/webapi/organization/getApplicantAsAssessor`);
  }
}
