import { Injectable } from "@angular/core";
import { GetApplicationListType } from '../enums/get-application-list-type.enum';

@Injectable({
  providedIn: 'root'
})

export class SessionStorageService {
  constructor() {

  }

  clearAllFilters() {
    sessionStorage.removeItem("applicationFilter" + GetApplicationListType.Assigned);
    sessionStorage.removeItem("applicationFilter" + GetApplicationListType.Open);
    sessionStorage.removeItem("applicationFilter" + GetApplicationListType.All);
    sessionStorage.removeItem("recordsApplicationFilter" + GetApplicationListType.Open);
    sessionStorage.removeItem("recordsApplicationFilter" + GetApplicationListType.All);
    sessionStorage.removeItem("applicantFilter");
    sessionStorage.removeItem("definitionCategoryFilter");
  }
}
