import { Component, OnInit, Input } from '@angular/core';
import { FormSectionModel } from '../../../models/CustomizationSettingsSubmitModel';

@Component({
  selector: 'xcdrs-circles-sections',
  templateUrl: './circles-sections.component.html',
  styleUrls: ['./circles-sections.component.scss']
})
export class CirclesSectionsComponent implements OnInit {
  @Input() section: FormSectionModel;
  @Input() firstInvalidSectionIndex: number;
  @Input() sectionIndex: number;
  @Input() sectionsLength: number; 
  @Input() formStepper: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
