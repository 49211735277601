import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

/** A router wrapper, adding extra functions. */
@Injectable({
  providedIn: 'root'
})
export class RouterExtService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(public router: Router, private _location: Location) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public setRedirectUrl() {
    if (this._location.path() !== '/login') {
      this.router.navigate(['login'], { queryParams: { redirectUrl: this._location.path() } });
    } else {
      this.router.navigateByUrl('/login');
    }
  }
}
