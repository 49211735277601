import { Directive, Input, HostListener } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[CustomHyperLink][formControlName],[CustomHyperLink][formControl],[CustomHyperLink][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomHyperLinkDirective, multi: true }],
  //host: {
  //  '(blur)': 'validate($event)'
  //}
  //selector: '[CustomHyperLink]'
})
export class CustomHyperLinkDirective implements Validator {

  //get ctrl() {
  //  return this.ngControl.control;
  //}
  //constructor(
  //  private ngControl: NgControl
  //) { }

  validate(c: FormControl): { [key: string]: any } {
    // let v = c.value;
    // if (v == null || v == '') {
    //   return null;
    // }
    // let emailRegularExpression: RegExp = /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-\.@:%_\+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/;
    // return (emailRegularExpression.test(v) == false) ? { "Hyperlink": true } : null;
    return customHyperLink(c);
  }

  //@HostListener("blur")
  //onBlur(): { [key: string]: any } {
  //  let value = this.ctrl.value;
  //  if (value == null || value == '') {
  //    return null;
  //  }
  //  let emailRegularExpression: RegExp = /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-\.@:%_\+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/;
  //  return (emailRegularExpression.test(value) == false) ? { "Hyperlink": true } : null;
  //}
}

export function customHyperLink(c: FormControl): { [key: string]: any } {
  let v = c.value;
  if (v == null || v == '') {
    return null;
  }
  let hyperlinkRegularExpression: RegExp = /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-\.@:%_\+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/;
  return (hyperlinkRegularExpression.test(v) == false) ? { "Hyperlink": true } : null;
}
