import { Injectable } from '@angular/core';
import { TranslationHelperService } from './translation-helper.service';
import { HttpHeaders } from '@angular/common/http';
import { FeildType } from '../enums/field-type-enum';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { FormSectionModel } from '../../models/CustomizationSettingsSubmitModel';
import { ActionFieldModel } from '../../models/action-field-model';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonHelperService {
  public fieldTypes: typeof FeildType = FeildType;
  documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>()
  public allConditionsList = [
    { value: "startswith", text: "Conditions.Starts" },
    { value: "endswith", text: "Conditions.Ends" },
    { value: "contains", text: "Conditions.Contains" },
    { value: "in", text: "Conditions.Match" },
    { value: "notin", text: "Conditions.DoesNotMatch" },
    { value: "greaterthan", text: "Conditions.GreaterThan" },
    { value: "lessthan", text: "Conditions.LessThan" },
    { value: "greaterequal", text: "Conditions.GreaterEqual" },
    { value: "lessequal", text: "Conditions.LessEqual" },
    { value: "equals", text: "Conditions.Equals" },
    { value: "notequals", text: "Conditions.NotEquals" },
    { value: "containsdata", text: "Conditions.ContainsData" },
    { value: "containsnodata", text: "Conditions.ContainsNoData" }
  ];
  constructor(private _translationHelperService: TranslationHelperService) {

  }

  fileNameSufixWithDot(name: string, countForDot: number) {
    if (name.indexOf("...") == -1 && name && name.length > countForDot) {
      return name.substring(0, (countForDot - 1)) + '...';
    }
    else {
      return name;
    }
  }

  sufixStringWithDot(name: string, countForDot: number) {
    if (name.indexOf("...") == -1 && name && name.length > countForDot) {
      return name.substring(0, (countForDot - 1)) + '...';
    }
    else {
      return name;
    }
  }

  getActionFieldText(value: string, suffix: boolean = false): string {
    if (value && value.indexOf('>') > -1) {
      if (suffix) {
        return value.substring(0, value.lastIndexOf('>') + 1)
      } else {
        return value.substring(value.lastIndexOf('>') + 1, value.length);
      }
    } else {
      return value;
    }
  }

  getDefinitionIcon(iconImage: string) {
    if (iconImage && iconImage.indexOf('/') > -1) {
      let icon: string[] = iconImage.split('/');
      return icon[1];
    } else {
      return iconImage;
    }
  }

  highlightText(actualText: string, searchtext: string) {
    actualText = this._translationHelperService.translateToLocale(actualText);
    return actualText.replace(new RegExp('(' + searchtext + ')', "ig"), '<span class="search-selection">$1</span>');
  }

  getCurrentLocaleHeader(): HttpHeaders {
    let headers = new HttpHeaders();

    let preferredLanguage = JSON.parse(localStorage.getItem('preferredLanguage'));
    if (preferredLanguage != null) {
      headers = new HttpHeaders({
        'locale': preferredLanguage.locale
      });
    } else {
      headers = new HttpHeaders({
        'locale': 'en'
      });
    }
    return headers;
  }

  isUserLogIn(): boolean {
    if (sessionStorage.getItem('ngStorage-auth') != null) {
      let ngStorageAuth = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
      if (ngStorageAuth['user'].role == '') {
        return false;
      }
      return true;
    }
  }
  //Custom Fields Methods start
  getConditions(fieldType: FeildType, formFieldType: FeildType = null, fieldPath: any = null) {
    if (fieldType == this.fieldTypes.Text || fieldType == this.fieldTypes.MultiLine || fieldType == this.fieldTypes.Email || fieldType == this.fieldTypes.Hyperlink || fieldType == this.fieldTypes.PhoneNumber || fieldType == this.fieldTypes.Calculated || fieldType == this.fieldTypes.Mapped) {
      return this.allConditionsList.filter(condition => condition.value == 'startswith' || condition.value == 'endswith' || condition.value == 'contains' || condition.value == 'equals' || condition.value == 'containsdata' || condition.value == 'containsnodata');
    } else if (fieldType == this.fieldTypes.SingleSelection || fieldType == this.fieldTypes.MultiSelection || fieldType == this.fieldTypes.LookupAddon || fieldType == FeildType.SameLookupRollup || fieldType == FeildType.Status) {
      if (formFieldType == FeildType.LookupAddon || formFieldType == FeildType.SameLookupRollup || formFieldType == FeildType.Status) {
        if (fieldPath && fieldPath == 'OPT') {
          return this.allConditionsList.filter(condition => condition.value == 'equals' || condition.value == 'containsdata' || condition.value == 'containsnodata');
        } else if(fieldType == FeildType.LookupAddon && fieldPath == 'PT/Id') {
          return this.allConditionsList.filter(condition => condition.value == 'in' || condition.value == 'notin');
        }
        // return this.allConditionsList.filter(condition => condition.value == 'equals' || condition.value == 'contains');
        return this.allConditionsList.filter(condition => condition.value == 'in' || condition.value == 'notin' || condition.value == 'containsdata' || condition.value == 'containsnodata');

      } else {
        return this.allConditionsList.filter(condition => condition.value == 'in' || condition.value == 'notin' || condition.value == 'containsdata' || condition.value == 'containsnodata');
      }
    } else if (fieldType == FeildType.Number || fieldType == FeildType.Rating || fieldType == FeildType.Money || fieldType == FeildType.Aggregator) {
      return this.allConditionsList.filter(condition => condition.value == 'greaterthan' || condition.value == 'lessthan' || condition.value == 'equals' || condition.value == 'lessequal' || condition.value == 'greaterequal' || condition.value == 'containsdata' || condition.value == 'containsnodata');
    } else if(fieldType == FeildType.Date || fieldType == FeildType.DateTime ) {
      return this.allConditionsList.filter(condition => condition.value == 'equals' || condition.value == 'containsdata' || condition.value == 'containsnodata' || condition.value == 'greaterthan' || condition.value == 'lessthan' || condition.value == 'lessequal' || condition.value == 'greaterequal');
    } else if(fieldType == FeildType.Attachment || fieldType == FeildType.Image ||  fieldType == FeildType.Gallery) {
      return this.allConditionsList.filter(condition => condition.value == 'containsdata' || condition.value == 'containsnodata');
    } else {
      return [];
    }
  }

  getFieldsForRollupFilter(customFields: ActionFieldModel[]) {
    return customFields.filter(field => (field.innerFields?.length > 0 || field.type == FeildType.Date || field.type == FeildType.SingleSelection
      || field.type == FeildType.MultiSelection || field.type == FeildType.Number
      || field.type == FeildType.Money || field.type == FeildType.MultiLine || field.type == FeildType.Text
      || field.type == FeildType.LookupAddon || field.type == FeildType.Status));
  }

  public getFieldNameForRollup(sections: FormSectionModel[], field: CustomFieldModel, addSectionName: boolean = false): string {
    var fieldName = '';
    sections.filter(section => {
      var searchedFeild = section.fields.find(f => f.sectionCustomFieldId == field.rollup.aggregatedFieldId);
      if (searchedFeild) {
        if (!addSectionName || !section.defaultName) {
          fieldName = this._translationHelperService.translateToLocale(searchedFeild.label);
        } else {
          fieldName = `${this._translationHelperService.translateToLocale(section.defaultName)} - ${this._translationHelperService.translateToLocale(searchedFeild.label)}`;
        }
      }
    });
    return fieldName;
  }

  public formatCalculateDisplayValue(customField: CustomFieldModel, actionFeilds: ActionFieldModel[]) {
    customField.value = '';
    let allowValueArray = JSON.parse(customField.allowedValuesCriteria);
    customField.calculations = allowValueArray.calculations;
    customField.calculations = customField.calculations.sort(function (obj1, obj2) {
      return obj1.order - obj2.order;
    });
    customField.calculations.forEach((calculation, index) => {
      var operand: string = '';
      if (calculation.fieldPath != '-1') {
        var actionField = actionFeilds.find(af => af.key == calculation.fieldPath);
        if (actionField) {
          operand = actionField.name;
        }
      } else {
        operand = calculation.value;
      }
      customField.value = customField.value + `${operand} ${calculation.operator ? calculation.operator.toUpperCase() + ' ' : ''}`
      customField.value = customField.value.replace(/>/gi, '-');
    });
  }
  public formatMappedDisplayValue(customField: CustomFieldModel, actionFeilds: ActionFieldModel[]) {
    const action = JSON.parse(customField.allowedValuesCriteria);
    customField.value = actionFeilds.find(x => x.key == action.fieldPath).name;
    customField.value = customField.value.replace(/>/gi, '-');
  }
  //Custom Fields Methods End

  public isExpired(endDate?: Date): boolean {
    if (!endDate) {
      return false;
    }
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let expirayDate = new Date(endDate);
    expirayDate.setHours(0, 0, 0, 0);
    if (endDate && today > expirayDate) {
      return true;
    } else {
      return false;
    }
  }

  public isNotStarted(startDate?: Date): boolean {
    if (!startDate) {
      return false;
    }
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let startDateFormated = new Date(startDate);
    startDateFormated.setHours(0, 0, 0, 0);
    if (today < startDateFormated) {
      return true;
    } else {
      return false;
    }
  }

  //length = 40;
  appendEllipsisStart(string: string, length: number): string {
    let stringWithEllipses: string = '';
    if (string) {
      stringWithEllipses = _.cloneDeep(string);
    }
    if (string.length > length) {
      stringWithEllipses = stringWithEllipses.substring((string.length - length));
      stringWithEllipses = '...' + stringWithEllipses;
    }
    return stringWithEllipses;
  }

}
