<div fxLayout="column" fxHide.xs fxFlex>
  <div class="section-circle" [ngClass]="section.isValid ? 'outline-green' : firstInvalidSectionIndex == sectionIndex ? 'outline-green' : 'outline-gray'" fxFlex="40px" fxLayoutAlign="center center">
    <div class="section-filled-circle" [ngClass]="section.isValid ? 'filled-green' : 'filled-white'">
      {{sectionIndex + 1}}
    </div>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="center stretch" *ngIf="!formStepper">
    <div fxFlex="1px" [ngClass]="section.isValid ? 'green-bar':'gray-bar'" [class.no-bar]="(sectionsLength - 1) ==  sectionIndex">
    </div>
  </div>
</div>
