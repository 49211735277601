import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByProperty'
})
export class FilterByPropertyPipe implements PipeTransform {

  transform(list: any[], args?: any): any {
    if (!list || !args) {
      return list;
    }

    var columnName = args[0];
    var columnValue = args[1];
    if (!columnValue) {
      columnValue = "";
    }
    return list.filter(listItem => ('' + listItem[columnName]).toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1);
  }

}

@Pipe({
  name: 'filterByMultipleProperties'
})
export class FilterByMultiplePropertiesPipe implements PipeTransform {
  transform(items: any[], searchText: string, properties: string[]): any[] {
    if (!items || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      for (const property of properties) {
        if (item[property] && item[property].toLowerCase().includes(searchText)) {
          return true;
        }
      }
      return false;
    });
  }
}

  
