import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { CustomFieldModel } from '../../../profile-customization/Model/CustomFieldModel';
import { CustomFieldService } from '../../../common/services/custom-field.service';
import { FeildType } from '../../../common/enums/field-type-enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonFieldsService } from '../../../settings/common-fields/common-fields.service';
import { DataSharingService } from '../../../common/services/data-sharing.service';
import { LookupType } from '../../../common/enums/lookup-type-enum';
@Component({
  selector: 'app-custom-field-application-submitted',
  templateUrl: './custom-field-application-submitted.component.html',
  styleUrls: ['./custom-field-application-submitted.component.scss']
})
export class CustomFieldApplicationSubmittedComponent implements OnInit {
  @Input() customField: CustomFieldModel;
  @Input() isLast: boolean = false;
  @Input() nestedFieldIndex: number = 0;
  @Input() nestedFieldsCount: number;

  @Input() showLabel:boolean = true;
  @Input() isTableView : boolean = false;
  @Input() callFrom:string = '';
  public count : number = 0;
  public fieldType: typeof FeildType = FeildType;
  safeHtml: SafeHtml;
  lookupTypeEnum = LookupType;
  constructor(
    public customFieldService: CustomFieldService,
    public dataSharingService: DataSharingService,
    private cdr: ChangeDetectorRef) { }
    FType: typeof FeildType = FeildType;
    formatedValue : any = '';
    multiLingulaArray : any[] = [];

  ngOnInit() {
    if (this.customField.type != FeildType.Attachment && this.customField.targetFieldType != FeildType.Attachment && this.customField.type != FeildType.Gallery && this.customField.targetFieldType != FeildType.Gallery && this.customField.type != FeildType.Image && this.customField.targetFieldType != FeildType.Image) {
      if (this.customField.type == FeildType.Mapped) {
        this.customField.type = this.customField.targetFieldType;
      }
      if (this.customField.type === FeildType.Money || this.customField.type === FeildType.Price || this.customField.type === FeildType.SingleSelection) {
        this.formatedValue = this.customFieldService.getFeildValue(this.customField);
        this.hasValue(this.formatedValue);
        if (this.customField.type === FeildType.SingleSelection && !this.formatedValue) {
          //In case of mapped
          this.formatedValue = this.customField.value;
          this.hasValue(this.formatedValue);
        } else if (this.customField.type === FeildType.SingleSelection){
          this.formatedValue = JSON.parse(this.formatedValue);
          this.hasValue(this.formatedValue);
        }
      } else if (this.customField.type == FeildType.MultiSelection) {
        this.customField.lookupValues = this.customField.lookupValues.filter(lv => lv.isSelected)
        if(this.customField.lookupValues.length > 0) {
          this.customField.hasValue = true
        }
      } else if(this.customField.type == FeildType.Note) {
        if(this.customField.label != null && this.customField.label != "") {
          this.customField.hasValue = true
        }
      } else if(this.customField.type == FeildType.Date || this.customField.type == FeildType.DateTime || this.customField.type == FeildType.DateRange) {
        this.formatedValue = this.customFieldService.getFeildValue(this.customField);
        this.hasValue(this.formatedValue);

      } else if(this.customField.type == FeildType.Hyperlink) {
        this.formatedValue = this.customFieldService.getFeildValue(this.customField);
        this.hasValue(this.formatedValue);
      } else if (this.customField.type == FeildType.LookupAddon && this.customField.lookupType == LookupType.Definition && !this.customField.isMultipleAllowed) {
        this.formatedValue = this.customFieldService.getFeildValue(this.customField);
        this.hasValue(this.formatedValue);

      } else if(this.customField.type == FeildType.Text || this.customField.type == FeildType.MultiLine) {
        this.formatedValue = this.customFieldService.getFeildValue(this.customField);
        if(this.isObject(this.formatedValue)) {
          this.multiLingulaArray = [
            { label: this.customField.label + '(English)', value: this.formatedValue.en ?? '-'},
            { label: this.customField.label + '(Arabic)', value: this.formatedValue.ar ?? '-'},
            // { label: this.customField.label + '(French)', value: this.formatedValue.fr ?? '-'},
          ];
        }
        this.hasValue(this.formatedValue);
      } else {
        this.customField.value = this.customFieldService.getFeildValue(this.customField);
        this.hasValue(this.customField.value);
      }
    } else {
      if(this.customField.details && (this.customField.details.downloadUrl || this.customField.details?.gallery?.length > 0)){
        this.customField.hasValue = true;
      }
    }
    if(((this.customField.type == this.FType.Money || this.customField.type == this.FType.Price || this.customField.type == this.FType.SingleSelection) &&  this.formatedValue) || (this.customField.type != this.FType.Money && this.customField.type != this.FType.Price && this.customField.type != this.FType.SingleSelection && this.customField.value) || (this.customField.type == 6 && this.customField.lookupValues && this.customField.lookupValues.length > 0) || (this.customField.type == this.FType.Note)) {
      this.incrementInstanceCount();
    }

    // console.log(this.customField);
  }
  incrementInstanceCount(): void {
    let newCount: number;
    if (this.callFrom === 'criteria') {
      newCount = this.customFieldService.incrementInstanceCount('criteria');
    } else if (this.callFrom === 'feeds') {
      newCount = this.customFieldService.incrementInstanceCount('feeds');
    } else {
      newCount = this.customFieldService.incrementInstanceCount('applicationDetails');
    }
    this.count = newCount;

    // console.log('this.count after increment:', this.count);
    this.cdr.detectChanges();

  }

  getIconFileExtention(fileName: string) {
    return this.customFieldService.getIconFileExtention(fileName);
  }
  hasValue(fieldValue) {
    if(fieldValue != null && fieldValue != "") {
      this.customField.hasValue = true;
    }
  }
  isObject(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }
  isExcludedType(customField: any): boolean {
    const excludedTypes = [
      FeildType.RichText, FeildType.Text, FeildType.MultiLine, FeildType.DateRange,
      FeildType.DateTime, FeildType.Date, FeildType.Money, FeildType.Price,
      FeildType.SingleSelection, 19, 3, 6, FeildType.Hyperlink,
      FeildType.Attachment, FeildType.Gallery, FeildType.Image, FeildType.Rating,
      FeildType.Note, FeildType.Map, FeildType.Tags, FeildType.Email, FeildType.PhoneNumber
    ];
  
    const excludedTargetFieldTypes = [
      FeildType.Hyperlink, FeildType.Attachment, FeildType.Gallery, FeildType.Image
    ];
  
    return !excludedTypes.includes(customField.type) &&
           !excludedTargetFieldTypes.includes(customField.targetFieldType);
  }
  
  isLookupAddonSingle(customField: any): boolean {
    return customField.type !== FeildType.LookupAddon && !customField.isMultipleAllowed;
  }
  

}
