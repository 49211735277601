import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ApplicantRequestService } from "../../../applicant-request/services/applicant-request.service";
import { FeildType } from "../../../common/enums/field-type-enum";
import { ChildSubmitterInfoModel } from "../../../models/child-submitter-info.model";
import { CustomFieldModel } from "../../../profile-customization/Model/CustomFieldModel";

@Component({
  selector: "app-submitters-select",
  templateUrl: "./submitters-select.component.html",
  styleUrls: ["./submitters-select.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SubmittersSelectComponent implements OnInit {
  // @Input() definitionId: number = 0;
  @Input() espFormGroup: FormControl;
  @Input() disabled: boolean = false;
  espFormControl: FormControl = new FormControl([], [Validators.required]);

  @Input() subUserGroupLists: any[] = []
  // @Input() applicants: any[] = [];
  @Input() selectAllByDefault: boolean = false;
  private _applicants: any[] = [];
  @Input() get applicants(): any[] {
    return this._applicants
  }
  set applicants(applicants: any[]) {
    this._applicants = applicants;
    if (this.selectAllByDefault) {
      this.espFormControl.setValue([this.selectAllValue]);
      this.toggleAllSelection();
    }
  }
  @Input() selectedApplicantsIds: any[] = [];
  @Input() selectedUserGroupsIds: any[] = [];
  applicantsFc: FormControl;
  subGroupsFc: FormControl;
  constructor(private _applicantRequestService: ApplicantRequestService) {

  }
  childSubmitterInfoModel: ChildSubmitterInfoModel[] = []
  ngOnInit(): void {
    this.applicantsFc = this.espFormGroup.get('applicants') as FormControl;
    this.subGroupsFc = this.espFormGroup.get('subUserGroupLists') as FormControl;
    this.subUserGroupLists = this.subUserGroupLists ?? []
    this.applicants = this.applicants ?? []

    if (this.selectAllByDefault) {
      this.espFormControl.setValue([this.selectAllValue]);
      this.toggleAllSelection();
    } else {
      let selectedOptions = [];
      let allApplicantsSelected = false;
      let allSubGroupsSelected = false;
      let noApplicantSelected = false;
      let noSubGroupSelected = false;
      if (this.selectedApplicantsIds && this.selectedApplicantsIds.length) {

        selectedOptions = this.applicants.filter(ap => this.selectedApplicantsIds.includes(ap.id))
        if (this.selectedApplicantsIds.includes(-1)) {
          allApplicantsSelected = true;
        }
        if (this.selectedApplicantsIds.includes(-2)) {
          noApplicantSelected = true;
        }
      }
      if (this.selectedUserGroupsIds && this.selectedUserGroupsIds.length) {
        selectedOptions = selectedOptions.concat(this.subUserGroupLists.filter(sug => this.selectedUserGroupsIds.includes(sug.id)))

        if (this.selectedUserGroupsIds.includes(-1)) {
          allSubGroupsSelected = true;
        }
        if (this.selectedUserGroupsIds.includes(-2)) {
          noSubGroupSelected = true;
        }
      }
      if (allApplicantsSelected || (allApplicantsSelected && allSubGroupsSelected)) {
        this.espFormControl.setValue([this.selectAllValue]);
        this.toggleAllSelection();
      } else if (noApplicantSelected && noSubGroupSelected) {
        this.espFormControl.setValue([this.noneValue]);
        this.toggleNoneSelection();
      } else {
        this.espFormControl.setValue(selectedOptions)
        this.toggleItem();
      }


    }
    this.updateValidator();
  }
  noneValue: any = {
    id: -2
  };
  selectAllValue: any = {
    id: -1
  };
  selectionChanged(values: any[]) {

  }
  toggleAllSelection() {
    const valueArray = this.espFormControl.value;
    let values: any[] = [];
    if (valueArray.includes(this.selectAllValue)) {
      values = this.applicants.concat(this.subUserGroupLists).concat(this.selectAllValue);
      this.espFormControl.setValue(values);
      this.applicantsFc.setValue([this.selectAllValue]);
      this.subGroupsFc.setValue([this.selectAllValue]);
    } else {
      this.espFormControl.setValue([]);
      this.applicantsFc.setValue([]);
      this.subGroupsFc.setValue([]);


    }
    this.updateValidator();

    this.updateTriggerText();
  }
  toggleNoneSelection() {
    const valueArray = this.espFormControl.value;
    if (valueArray.includes(this.noneValue)) {
      this.espFormControl.setValue([this.noneValue]);
      this.applicantsFc.setValue([this.noneValue]);
      this.subGroupsFc.setValue([this.noneValue]);
    } else {
      this.espFormControl.setValue([]);
      this.applicantsFc.setValue([]);
      this.subGroupsFc.setValue([]);
    }
    this.updateValidator();
    this.updateTriggerText();

  }
  updateValidator() {
    const valueArray = this.espFormControl.value;
    if (valueArray != 'undefined' && valueArray != '') {


      let anyApplicantSelected = this.applicants.some(applicant => valueArray.includes(applicant));
      let anySubGroupsSelected = this.subUserGroupLists.some(subgroup => valueArray.includes(subgroup));
      if (anyApplicantSelected) {
        this.subGroupsFc.clearValidators();
        this.applicantsFc.setValidators([Validators.required])
      }
      if (anySubGroupsSelected) {
        this.applicantsFc.clearValidators();
        this.subGroupsFc.setValidators([Validators.required])
      }
      this.applicantsFc.updateValueAndValidity();
      this.subGroupsFc.updateValueAndValidity();
    }
  }
  toggleItem() {

    const valueArray = this.espFormControl.value;
    if (valueArray != 'undefined' && valueArray != '') {


      let allApplicantsSelected = this.applicants.every(applicant => valueArray.includes(applicant));
      let noApplicantSelected = !this.applicants.some(applicant => valueArray.includes(applicant));
      let allSubGroupsSelected = this.subUserGroupLists.every(subgroup => valueArray.includes(subgroup));
      let noSubGroupsSelected = !this.subUserGroupLists.some(subgroup => valueArray.includes(subgroup));

      if (!allApplicantsSelected && !noApplicantSelected) {
        this.applicantsFc.setValue(this.applicants.filter(applicant => valueArray.includes(applicant)))
      }
      if (!allSubGroupsSelected && !noSubGroupsSelected) {
        this.subGroupsFc.setValue(this.subUserGroupLists.filter(subgroup => valueArray.includes(subgroup)))
      }
      if (allApplicantsSelected) {
        this.applicantsFc.setValue(this.applicants)
      }
      if (noApplicantSelected) {
        this.applicantsFc.setValue([])

      }
      if (allSubGroupsSelected) {
        this.subGroupsFc.setValue(this.subUserGroupLists)
      }
      if (noSubGroupsSelected) {
        this.subGroupsFc.setValue([])
      }

      if (noApplicantSelected && noSubGroupsSelected) {
        this.espFormControl.setValue([])
      } else {
        this.espFormControl.setValue(valueArray.filter(val => val != this.selectAllValue && val != this.noneValue))
      }

      if (noApplicantSelected && noSubGroupsSelected) {
        this.espFormControl.setValue([])
      }

    } else {
      this.espFormControl.setValue([])
      this.subGroupsFc.setValue([])
      this.applicantsFc.setValue([])

    }
    this.updateValidator();
    this.updateTriggerText();
  }
  triggerText = "";
  public selectNamesList: string[] = [];
  updateTriggerText() {
    this.triggerText = "";
    this.selectNamesList = [];
    if (this.espFormControl.value) {
      const values = this.espFormControl.value.filter((v) => v != this.selectAllValue);

      this.selectNamesList = this.applicants.concat(this.subUserGroupLists)
        .filter((op) => values.includes(op))
        .map((opt) => opt.name);

    }
  }
}
