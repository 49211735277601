import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { UserResult } from '../../models/UserResult';
import { UserInfoService } from '../../common/services/userinfo-service';
import { InvitationLinkInformation } from '../../models/invitation-link-information';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { IdenediAuthModel } from '../../models/idenedi-auth-model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  baseUrl: string = '/webapi/';

  constructor(private http: HttpClient
    , private userInfoService: UserInfoService
    , private translate: TranslateService
    , private router: Router) { }

  getHeadersWithoutoken() {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return headers;
  }

  organizationRegister(fullName, email, organization) {

    let headers = this.getHeadersWithoutoken();
    var data = {
      organizationName: organization,
      firstName: fullName,
      email: email
    }
    return this.http.post(this.baseUrl + 'organization', data, { headers: headers });
  }

  applicantRegister(data) {

    let headers = this.getHeadersWithoutoken();
    return this.http.post(this.baseUrl + 'applicant/Register', data, { headers: headers });
  }

  linkToUser(key: string) {
    return this.http.put(this.baseUrl + 'orguser/linkaccount/' + key, null);
  }

  linkToApplicant(key: string) {
    return this.http.put(this.baseUrl + 'applicant/linkaccount/' + key, null);
  }

  linkAndActivate(key: string) {
    return this.http.put(this.baseUrl + 'applicant/linkandactivate/' + key, null);
  }

  submitRequestUserAuthentication(email: string, name: string, definitionKey: string) {
    return this.http.get(`${this.baseUrl}applicant/token/for/${email}/${name}/definitionKey/${definitionKey}`);
  }

  isApplicantExists(email: string) {
    return this.http.get(`${this.baseUrl}applicant/exists/${email}/`);
  }

  isApplicantExistsWithDefinitionKey(email: string, definitionKey: string) {
    return this.http.get(`${this.baseUrl}applicant/exists/${email}/defKey/${definitionKey}/`);
  }

  userAuthentication(userName, password, personaId: number = 0, idenediCode: string = "") {

    let headers = this.getHeadersWithoutoken();
    var data = '';
    if (personaId > 0) {
      var refreshToken: string = '';
      if (JSON.parse(sessionStorage.getItem('ngStorage-auth'))) {
        refreshToken = JSON.parse(sessionStorage.getItem('ngStorage-auth')).token.refreshToken;
      }
      if (refreshToken == '' || refreshToken == null || refreshToken == undefined) {
        data = 'grant_type=password&username=' + encodeURIComponent(userName) + '&password=' + encodeURIComponent(password) + '&client_id=ESPWeb&scope=' + personaId;
      }
      else {
        data = 'grant_type=refresh_token&username=' + encodeURIComponent(userName) + '&password=&client_id=ESPWeb&scope=' + personaId + '&refresh_token=' + refreshToken;
      }
    } else {
      if (idenediCode) {
        data = 'grant_type=password&username=' + encodeURIComponent(userName) + '&password=' + encodeURIComponent(password) + '&client_id=ESPWeb' + '&idenedi_code=' + encodeURIComponent(idenediCode);
      } else {
        data = 'grant_type=password&username=' + encodeURIComponent(userName) + '&password=' + encodeURIComponent(password) + '&client_id=ESPWeb';
      }
    }
    return this.http.post(`${this.baseUrl}token?id=${personaId > 0 ? personaId : ""}`, data, { headers: headers });
  }

  userAuthenticationByIdenedi(idenedi: string, idenediAuthToken: string) {
    let headers = this.getHeadersWithoutoken();
    var data = `grant_type=idenedi_key&client_id=ESPWeb&idenedi=${idenedi}&idenedi_token=${idenediAuthToken}`;
    return this.http.post(`${this.baseUrl}token`, data, { headers: headers });
  }

  //use to send emai of locked account 
  accountLocked(userName: string) {
    let headers = this.getHeadersWithoutoken();
    var data = {
      EmailAddress: userName
    }
    return this.http.post(this.baseUrl + 'account/locked', data, { headers: headers });
  }

  windowsUserAuthentication(personaId: number) {
    return this.http.get(this.baseUrl + 'orguser/winlogintoken' + (personaId > 0 ? "?personaId=" + personaId : ""));
  }

  logout() {
    return this.http.get(this.baseUrl + 'orguser/logout');
  }

  //justaChangeTest(personaId, userName, password) {
  //  let headers = this.getHeadersWithoutoken();
  //  var data = 'grant_type=password&username=' + encodeURIComponent(userName) + '&password=' + encodeURIComponent(password) + '&client_id=ESPWeb&Persoan=' + personaId;
  //  return this.http.post(this.baseUrl + 'token', data, { headers: headers });
  //}

  resetPassword(email) {
    let headers = this.getHeadersWithoutoken();
    var data = {
      EmailAddress: email
    }
    return this.http.post(this.baseUrl + 'account/password/reset', data, { headers: headers });
  }

  validateKey(key) {
    let headers = this.getHeadersWithoutoken();
    return this.http.post(this.baseUrl + 'orguser/validate/' + key, { headers: headers });
  }

  validateKeyV2(key) {
    let headers = this.getHeadersWithoutoken();
    return this.http.post(this.baseUrl + 'orguser/invite/' + key, { headers: headers });
  }

  setPassword(key, password, activateAccount: boolean = false) {
    let headers = this.getHeadersWithoutoken();
    var data = {
      NewPassword: password,
      canActivateApplicant: activateAccount
    }
    return this.http.post(this.baseUrl + 'account/password?key=' + key, data, { headers: headers });
  }

  accpetUserInvite(key, password) {
    let headers = this.getHeadersWithoutoken();
    var data = {
      Password: password
    }
    return this.http.post(this.baseUrl + 'orguser/invite/' + key + '/accept', data, { headers: headers });
  }

  accpetApplicantInvite(key, password) {
    let headers = this.getHeadersWithoutoken();
    var data = {
      Password: password
    }
    return this.http.post(this.baseUrl + 'applicant/invite/' + key + '/accept', data, { headers: headers });
  }

  accpetApplicantInviteForIdenedi(key: string, idenediCode: string) {
    let headers = this.getHeadersWithoutoken();
    return this.http.post(this.baseUrl + 'applicant/invite/' + key + '/accept/' + idenediCode, null, { headers: headers });
  }

  accpetUserInviteForIdenedi(key: string, idenediCode: string) {
    let headers = this.getHeadersWithoutoken();
    return this.http.post(this.baseUrl + 'orguser/invite/' + key + '/accept/' + idenediCode, null, { headers: headers });
  }

  getInvitationLinkInformation(key: string): Observable<InvitationLinkInformation> {
    let headers = this.getHeadersWithoutoken();
    return this.http.get<InvitationLinkInformation>(this.baseUrl + 'definition/getbasicinfo/' + key, { headers: headers });
  }

  getInvalidCredentialMessage(err: HttpErrorResponse, email: string): string {
    var loginErrorMsg = '';
    if (err.status == 400 && err.error.error.indexOf('REMAINING:') > -1) {
      var attemptsRemaining: number = err.error.error.substr(err.error.error.indexOf(':') + 1)
      if (attemptsRemaining == 1) {
        this.translate.get(["LOGIN-LAST-ATTEMPT-ERROR"]).subscribe(translations => {
          loginErrorMsg = translations['LOGIN-LAST-ATTEMPT-ERROR'];
        });
      }
      else if (attemptsRemaining <= 3) {
        this.translate.get(["LOGIN-ATTEMPT-ERROR"]).subscribe(translations => {
          loginErrorMsg = translations['LOGIN-ATTEMPT-ERROR'];
          loginErrorMsg = loginErrorMsg.replace('{attemptsRemaining}', attemptsRemaining.toString());
        });
      } else {
        this.translate.get(["LOGIN-ERROR-LOGIN"]).subscribe(translations => {
          loginErrorMsg = translations['LOGIN-ERROR-LOGIN'];
        });
      }
    }
    else if (err.status == 400 && err.error.error.indexOf('LOCKED-NOW') > -1) {
      this.translate.get(["LOGIN-MINUTES-REMAINING"]).subscribe(translations => {
        loginErrorMsg = translations['LOGIN-MINUTES-REMAINING'];
        loginErrorMsg = loginErrorMsg.replace('{minutesRemaining}', err.error.error.substr(err.error.error.indexOf(':') + 1));
      });
      this.accountLocked(email).subscribe(resp => {

      });
    } else if (err.status == 400 && err.error.error.indexOf('LOCKED') > -1) {
      this.translate.get(["LOGIN-LOCKED-ERROR"]).subscribe(translations => {
        loginErrorMsg = translations['LOGIN-LOCKED-ERROR'];
      });
    } else if (err.status == 400 && err.error.error.indexOf('DeActivated') > -1) {
      this.translate.get(["LOGIN-DEACTIVATED-ERROR"]).subscribe(translations => {
        loginErrorMsg = translations['LOGIN-DEACTIVATED-ERROR'];
      });
    } else {
      this.translate.get(["LOGIN-ERROR-LOGIN"]).subscribe(translations => {
        loginErrorMsg = translations['LOGIN-ERROR-LOGIN'];
      });
    }
    return loginErrorMsg;
  }

  userAuthenticationSuccessHandler(data: any) {

  }
  getCandidateProfileDefinitionId(organizationId: number, baseUrl: string, higherGroupId): Observable<any> {
    const higherObject = {
      baseUrl: baseUrl,
      higherOrganizationId: organizationId,
      reference: '',
      groupId: higherGroupId
    }
    return this.http.post<any>(`/webapi/higher/getCandidateProfileDefinitionId` , higherObject);
  }
  getClientProfileDefinitionId(organizationId: number, baseUrl: string, higherGroupId): Observable<any> {
    const higherObject = {
      baseUrl: baseUrl,
      higherOrganizationId: organizationId,
      reference: '',
      groupId: higherGroupId
    }
    return this.http.post<any>(`/webapi/higher/getClientProfileDefinitionId` , higherObject);
  }
  getCandidateJobOfferDefinitionId(organizationId: number, baseUrl: string, higherGroupId): Observable<any> {
    const higherObject = {
      baseUrl: baseUrl,
      higherOrganizationId: organizationId,
      reference: '',
      groupId: higherGroupId
    }
    return this.http.post<any>(`/webapi/higher/getCandidateJobOfferDefinitionId` , higherObject);
  }
  getVacancyProfileDefinitionId(organizationId: number, baseUrl: string, reference: string, higherGroupId): Observable<any> {
    const higherObject = {
      baseUrl: baseUrl,
      higherOrganizationId: organizationId,
      reference: reference,
      groupId: higherGroupId
    }
    return this.http.post<any>(`/webapi/higher/getVacancyProfileDefinitionId` , higherObject);
  }
  espToken(code: string, OrganziationId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `orguser/idenediTokenV3/${code}/${OrganziationId}`);
  }

}

export enum GrantTypes {
  Password,
  RefreshToken,
  IdenediKey
}
