import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[customMin][formControlName],[customMin][formControl],[customMin][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CustomMinDirective,
    multi: true
  }]
})
export class CustomMinDirective implements Validator {
  @Input()
  customMin: number;

  validate(c: FormControl): { [key: string]: any } | null {
    return customMinValidator(this.customMin)(c);
  }

  //validate(control: AbstractControl): { [key: string]: any } | null {
  //  return this.forbiddenName ? forbiddenNameValidator(new RegExp(this.forbiddenName, 'i'))(control)
  //    : null;
  //}
}

export function customMinValidator(customMin): ValidatorFn {
  return (c: FormControl): { [key: string]: any } | null => {
    let v = c.value;
    if ((!v && v != 0) || customMin == null || v.toString().trim() == '') {
      return null;
    }
    return (Number(v) < Number(customMin) && !isNaN(v)) ? { "customMin": true } : null;
  }
}
