<button mat-icon-button mat-dialog-close aria-label="Close dialog" class="btn-close" [disabled]="callInProgress">
  <mat-icon><img src="assets/images/icons/close.svg" alt="close"></mat-icon>
</button>
<form #commentForm="ngForm" class="narrow-container" [ngClass]="(_dataSharingService.isRtl)?'rtl':'ltr'"
  [dir]="(_dataSharingService.isRtl)?'rtl':'ltr'">
  <div fxLayout="column" fxLayoutAlign="center center" mat-dialog-title>
    <ng-container *ngIf="applicationAction == applictionActionEnum.Cancel; else reactivateApp">
      <div class="dialog-title" [translate]="'Application.CLOSE-REQUEST-AS-CANCELLED'"
        [translateParams]="{application: labelService.labels?.application | translateToLocale}">
      </div>
      <div class="dialog-description" [translate]="'Application.REASON-FOR-CANCEL-DESC'"
        [translateParams]="{application: labelService.labels?.application | translateToLocale}">
      </div>
    </ng-container>
    <ng-template #reactivateApp>
      <div class="dialog-title" [translate]="'Application.REACTIVATE-REQUEST'"
        [translateParams]="{application: labelService.labels?.application | translateToLocale}">
      </div>
      <div class="dialog-description" [translate]="'Application.REASON-FOR-REACTIVATE-DESC'"
        [translateParams]="{application: labelService.labels?.application | translateToLocale}">
      </div>
    </ng-template>
  </div>

  <div mat-dialog-content>
    <div class="reason-container"
      [class.comments-control-container]="applicationAction == applictionActionEnum.Reactivate">
      <mat-form-field appearance="outline" fxFlex>

        <mat-label *ngIf="applicationAction == applictionActionEnum.Cancel"
          [translate]="'Application.REASON-FOR-CANCEL'">
        </mat-label>

        <mat-label *ngIf="applicationAction != applictionActionEnum.Cancel"
          [translate]="'Application.REASON-FOR-REACTIVATE'">
        </mat-label>
        <textarea matInput matTextareaAutosize="true" matAutosizeMinRows="3.19" matAutosizeMaxRows="10.19"
          [(ngModel)]="comments" name="comments" required></textarea>
      </mat-form-field>
    </div>
    <ng-container *ngIf="applicationAction == applictionActionEnum.Cancel">
      <div class="notify-users-text" [translate]="'Application.WHO-TO-NOTIFY'"></div>
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="applicant-card">
        <div fxLayoutAlign="center center">
          <ngx-avatar name="{{labelService.labels?.applicant | translateToLocale}}" src="" initialsSize="2"
            bgColor="#f2f2f2" fgColor="#807f83" size="40" textSizeRatio="2.5" [round]="true"></ngx-avatar>
        </div>
        <div fxFlex class="card-main-heading">{{labelService.labels?.applicant | translateToLocale}}</div>
        <div fxLayoutAlign="end center">
          <mat-checkbox name="cb-applicant" [(ngModel)]="notifyApplicant"></mat-checkbox>
        </div>
      </div>
      <ng-container *ngIf="!showSpinner">
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="applicant-card"
          *ngFor="let user of organizationUsers">
          <div fxLayoutAlign="center center">
            <ngx-avatar name="{{user.fullName}}" [src]=user.pictureUrl initialsSize="2" bgColor="#f2f2f2"
              fgColor="#807f83" size="40" textSizeRatio="2.5" [round]="true"></ngx-avatar>
          </div>
          <div fxFlex class="card-main-heading">{{user.fullName}}</div>
          <!--<div fxFlex fxLayoutAlign="start center">
          <div fxLayout="column">
            <div class="card-main-heading">{{user.fullName}}</div>
            <div class="card-main-heading"><span class="label-answer">{{user.email}}</span></div>
          </div>
        </div>-->
          <div fxLayoutAlign="end center">
            <mat-checkbox name="cb-{{user.id}}" [(ngModel)]="user.isSelected"></mat-checkbox>
          </div>
        </div>
      </ng-container>
      <div fxLayoutAlign="center center" class="spinner-container" *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
  </div>

</form>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
  <button mat-stroked-button color="primary" mat-dialog-close [disabled]="callInProgress">
    {{'CANCEL' | translate}}
  </button>
  <button mat-flat-button class="gradient-btn" color="primary" [disabled]="!commentForm.form.valid || callInProgress"
    (click)="submit()">
    <span *ngIf="applicationAction == applictionActionEnum.Cancel">
      {{'Application.CLOSE-REQUEST' | translate : {application:labelService.labels?.application | translateToLocale} }}
    </span>
    <span *ngIf="applicationAction != applictionActionEnum.Cancel" [translate]="'Application.RE-ACTIVATE'">

    </span>
  </button>
</div>