import { StageCriteria } from './stage-criteria';
import { ActionModel } from '../../models/action-model';
import { TriggerModel, ActionHistory } from '../../models/trigger-model';
import { FormGroup } from '@angular/forms';
import { ApplicationDefinition } from './application-definition';
import { SubmissionOption } from '../../common/enums/submission-option.enum';
import { Condition } from '../../profile-customization/Model/CustomFieldModel';
export class DefinitionStageModel {
  constructor() {
    this.type = StageType.Default;
    this.isExpended = false;
    this.actions = [];
    this.triggers = [];
    this.actionsHistory = [];
  }
  public id: number;
  public name: string = "";
  public nameLocale?: string = "";
  public criteriaCount: number;
  public isAll: boolean;
  public isEnabled: boolean;
  public isSystem: boolean;
  public applicationDefinationId: number;
  public criteriaList: StageCriteria[];
  public completedOn?: Date;
  public actions: ActionModel[];
  public triggers: TriggerModel[];
  public isExpended: boolean;
  public status: string;
  public statusLocale?: string = "";
  public statusId: number;
  public canAddSubApplicationField: boolean;
  public linkDefinitionSectionId: number;
  public linkDefinitionNameCustomFieldId: number;
  public linkDefinitionId: number;
  public linkDefinitionCanApplyMultiple: boolean;
  public linkDefinitionInvitation: boolean = false;
  public type: StageType;
  public linkDefinitionName: string;
  public isLinkDefinitionPublish: boolean = false;
  public linkDefDescription: string;
  public actionsHistory: ActionHistory[];
  createdBy: number;
  order: number;
  totalWeight: number;
  public isSigned?: boolean;
  public isLast?: boolean;
  public hasCriteriaToRespond?: boolean;
  public myActionRequired?: boolean;
  public formGroup?: FormGroup;
  public definition?: ApplicationDefinition
  public isDelete?: boolean;
  public childDefinitionProperties?: ChildDefinitionProperties
  public version?: number;
  public assignedToUser?: string;
  public showHistoryBtn?: boolean;
  public allowReturn?: boolean = true;
  public hideReturnStage: boolean = false;
  public allowReturnResubmit?: boolean = true;
  public notifyAssessors?: boolean = true;
}

export enum StageType {
  Default = "DEFAULT",
  Link = "LINK"
}
export enum ChildDefinitionClosingType {
  Assessor = "Assessor",
  Quantity = "Quantity",
  Date = "Date",
}
export class ChildDefinitionProperties {

  constructor() {
    this.stageId = 0;
    this.childDefinitionId = 0;
    this.nameFieldPath = '';
    this.descriptionFieldPath = '';
    this.closingType = ChildDefinitionClosingType.Assessor;
    this.stageIndex = -1;
    this.isMultiplesubmissionsallowed = false;
    this.stopSubmissionEnabled = false; 
    this.assessableByApplicant = false;
    this.assessableByAssessor = false;
    this.assessableByUsers = [];
    this.scfIdsToShow = '';
    this.sectionIdsToShow = ''; 
    this.submissionOption = '';
    this.notificationEmailBody = '';
    this.notificationEmailSubject = '';
    this.showAllTab = true; 
    this.showInvitationTab = true; 
    this.notificationEnabled = false;
    this.whoToInvite = new WhoToInvite();
  }
  public stageId: number;
  public childDefinitionId: number;
  public nameFieldPath: string;
  public nameFieldPathLocale?: string;
  public descriptionFieldPath: string;
  public descriptionFieldPathLocale?: string;
  public closingType: ChildDefinitionClosingType;
  public closingNumber: number;
  public stageIndex?: number;
  public isMultiplesubmissionsallowed?: boolean;
  public stopSubmissionEnabled?: boolean = false; 
  public assessableByApplicant: boolean;
  public assessableByAssessor: boolean;
  public assessableByUsers: number[];
  public scfIdsToShow: string;
  public sectionIdsToShow: string; 
  public submissionOption: string;
  public profileSubDetailSCFID:  number;
  public notificationEmailBody: string;
  public notificationEmailSubject: string;
  public showAcceptedTab: boolean; 
  public showAllTab: boolean; 
  public showInvitationTab: boolean; 
  public showOpenTab: boolean; 
  public showRejectedTab: boolean; 
  public settingsOptionForInvitationCard: string;
  public notificationEnabled: boolean;
  public formGroup?: FormGroup;
  public whoToInvite: WhoToInvite;
}
export class WhoToInvite {
  constructor() {
    this.type = 'SubmitterSelection';
    this.definitionId = 0;
    this.inviteToFieldPath = '';
    this.conditions = null;
    this.fallbackEmailPath = '';
  }
  public type: string;
  public definitionId: number;
  public inviteToFieldPath: string;
  public fallbackEmailPath: string;
  public conditions: Condition[] = [];
}
