import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LabelService } from '../../common/services/label-service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ManageCategoriesService } from '../../settings/manage-categories/manage-categories.service';
import { LocaleMapperService } from '../../shared/locale-mapper/locale-mapper.service';
import { LocaleMapper } from '../../shared/locale-mapper/locale-mapper';
import { ApplicationDefinition, DefinitionType } from '../../definitions/models/application-definition';
import { SubSink } from 'subsink';
import { startWith, map } from 'rxjs/operators';
import { TemplateModelWithProfileList } from '../../profile-customization/Model/SettingModel';
import { ProfileCustomizationService } from '../../profile-customization/profilecustomization.service';
import { AllDefinitionsService } from '../../definitions/services/all-definitions.service';
import { MessageService } from '../../common/services/message-service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryModel } from '../../models/CategoryModel';
import { DefinitionService } from '../services/definition.service';
import { CriteriaOwner, CriteriaOwnerType } from '../../models/criteria-owner';
import { customRequiredValidator } from '../../common/validators/custom-required-validator.directive';

@Component({
  selector: 'app-create-definition-dialog',
  templateUrl: './create-definition-dialog.component.html',
  styleUrls: ['./create-definition-dialog.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CreateDefinitionDialogComponent implements OnInit, OnDestroy {
  definitionModel = new ApplicationDefinition();
  createDefForm = this._fb.group({
    name: ['', [Validators.required, customRequiredValidator(), Validators.maxLength(50)]],
    description: ['', [Validators.required, customRequiredValidator(), Validators.maxLength(500)]],
    category: ['', [Validators.required, customRequiredValidator(), Validators.maxLength(50)]],
    isRecord: [false, [Validators.required]],
    profileTemplate: ['', [Validators.required]],
    definitionControllers: [[]]
  });
  private _categories: CategoryModel[];
  categoryOptions: string[];
  filteredOptions: Observable<string[]>;
  public definitionLocaleMapper: LocaleMapper;
  private _subs = new SubSink();
  callInProgress = false;
  defType: boolean;
  controllersList: CriteriaOwner[] = []
  constructor(
    public dialogRef: MatDialogRef<CreateDefinitionDialogComponent>,
    public labelService: LabelService,
    private _fb: FormBuilder,
    private _categoriesService: ManageCategoriesService,
    public localeMapperService: LocaleMapperService,
    private _definitionService: AllDefinitionsService,
    private _messageService: MessageService,
    private _translationHelperService: TranslationHelperService,
    private _router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['type']) {
        if(params['type'] == 'record') {
          this.createDefForm.get('isRecord').setValue(true);
        } else {
          this.createDefForm.get('isRecord').setValue(false);
        }
      }
    });
    this.loadCategories();
    this.loadControllers();
    this.definitionLocaleMapper = this.localeMapperService.createLocaleMapperItem(this.definitionModel, ['name', 'description', 'category']);
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
  selectValue: any;
  controllers: any = {}
  loadControllers() {
    this._definitionService.getDefinitionControllers().subscribe(res => {
      this.controllersList = res;
      this.controllers = {
        users: res.filter(c => c.type == 1),
        jobRoles: res.filter(c => c.type == 2),
      }
    })

  }

  loadCategories() {
    this._categoriesService.getCategories().subscribe(resp => {
      this._categories = resp.body;
      this.categoryOptions = resp.body.map(cat => this.definitionLocaleMapper.getParsedLocaleString(cat.name));
      this.filteredOptions = this.createDefForm.get('category').valueChanges.pipe(
        startWith(''),
        map(value => this.filter(value))
      );
    }, error => { });
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.categoryOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  setDefinitionControllers() {
    let selectedControllers: CriteriaOwner[] = this.createDefForm.getRawValue().definitionControllers;
    let mappedControllers = {
      users: selectedControllers.filter(c => c.type == CriteriaOwnerType.Users).map(c=>parseInt(c.id)),
      jobRoles: selectedControllers.filter(c => c.type == CriteriaOwnerType.JobRoles).map(c=>parseInt(c.id)),
    }
    this.definitionModel.definitionControllers = mappedControllers;
  }

  onSubmit() {
    this.callInProgress = true;
    this.setDefinitionControllers();
    // this._definitionFormService.removeFormGroupsFromDefintion(this.definitionModel)
    this.definitionModel.name = this.createDefForm.getRawValue().name;
    this.definitionModel.description = this.createDefForm.getRawValue().description;
    this.definitionModel.category = this.createDefForm.getRawValue().category;
    // Retrieve the raw value of the isRecord field from the form
    const isRecordValue : any = this.createDefForm.getRawValue().isRecord;

    // Check if isRecordValue is 'survey'
    if (isRecordValue === 'survey') {
        this.definitionModel.isRecord = true;
        this.definitionModel.isSurvey = true;
    } else {
        this.definitionModel.isRecord = isRecordValue;
        this.definitionModel.isSurvey = false;

    }

    this.definitionModel.isSubmission = false;
    this.definitionModel.type = DefinitionType.Default;
    // if (this.createDefForm.getRawValue().profileTemplate) {
      // this.definitionModel.profileTemplateId = this.createDefForm.getRawValue().profileTemplate.id;
      // this.definitionModel.profileListId = this.createDefForm.getRawValue().profileTemplate.profileListId;
      // console.log(this.definitionLocaleMapper.mapFromLocaleModelNoReference(this.definitionModel));
    // }
    let defToPost: ApplicationDefinition = this.definitionLocaleMapper.mapFromLocaleModelNoReference(this.definitionModel);
    const mappedCategory = this._categoriesService.getCategoryByCurrentLocale(this._categories, defToPost.category, this.definitionLocaleMapper.activeLocale);
    if (mappedCategory) {
      defToPost.category = mappedCategory.name;
    }

    this._definitionService.createDefinition(defToPost).subscribe(resp => {
      this._messageService.showMessage(this._translationHelperService.getTranslationByKey('Add-Definition-Dialog.SAVED-SUCCESSFULLY'));
      this.definitionModel.id = resp;
      this.definitionModel.isActive = false;
      this.definitionModel.createdOn = new Date();
      this.dialogRef.close({ data: this.definitionModel });
      this._router.navigate(['/definitions/' + this.definitionModel.id]);
    }, error => {
      this.callInProgress = false;
    });
  }
 public selectNamesList: string[] = [];

  getUpdateTriggerText(controllers) {
    this.selectNamesList = [];
    if (this.createDefForm.get('definitionControllers').value) {
      const values : CriteriaOwner[] = this.createDefForm.get('definitionControllers').value;
      const users = this.controllersList.filter(c => c.type == CriteriaOwnerType.Users);
      const jobRoles = this.controllersList.filter(c => c.type == CriteriaOwnerType.JobRoles);
      return this.selectNamesList = users.concat(jobRoles)
        .filter((op) => values.includes(op))
        .map((opt) => opt.name.concat('\n'));
 
    }
  }
}
