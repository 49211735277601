import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataSharingService } from './services/data-sharing.service';
import { UserInfoService } from './services/userinfo-service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(public dataSharingService: DataSharingService, public userInfoService: UserInfoService, private translate: TranslateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headerlocale = req.headers.get('locale')
    if (headerlocale == null) {
      if (this.userInfoService.role == 'Applicant') {
        let preferredLanguage = JSON.parse(localStorage.getItem('preferredLanguage'));
        if (preferredLanguage == null) {
          preferredLanguage = { locale: 'en' };
        }
        this.dataSharingService.interceptorLocale = preferredLanguage.locale;
      } else {
        this.dataSharingService.interceptorLocale = '*';
      }
      const dupReq = req.clone({ headers: req.headers.set('locale', this.dataSharingService.interceptorLocale) });
      return next.handle(dupReq);
    }
    else {
      return next.handle(req);
    }
  }
};
@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true }
  ]
})
export class InterceptorModule { }
