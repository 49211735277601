import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { NameId } from "../../../models/UserResult";

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  private systemTag: NameId[] = null;
  constructor(private http: HttpClient) {

  }

  getTags(scfId: number): Observable<NameId[]> {
    // if (this.systemTag) {
    //   return of(this.systemTag);
    // } else {
      return this.http.get<NameId[]>(`/webapi/tags/${scfId}`);
    //}
  }

}
