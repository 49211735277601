<div fxLayout="row" class="snack-container" fxLayoutAlign="start center">
    <div fxFlex="none" ngClass.md="pl-20" ngClass.lg="pl-20" class="mnb-4">
        <mat-icon *ngIf="data.snackbarType == SnackbarType.Success">check_circle</mat-icon>
        <mat-icon *ngIf="data.snackbarType == SnackbarType.Error">error</mat-icon>
    </div>
    <div fxFlex class="pl-16 pr-16">
        <span>{{data.message}}</span>
    </div>
    <div fxFlex="none" ngClass.md="pr-12" ngClass.lg="pr-12">
        <mat-icon class="pull-right cursor-pointer" [class.close-grey]="data.snackbarType == SnackbarType.Success"
            (click)="dismiss()">close
        </mat-icon>
    </div>
</div>