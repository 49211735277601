import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationsListComponent } from './applications-list/applications-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipesModule } from '../pipes/pipes.module';
import { ValidatorsModule } from '../../common/modules/validators.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialEssentialsModule } from '../../custom-material/material-essentials.module';
import { AppliationDetailsCardComponent } from './appliation-details-card/appliation-details-card.component';
import { ApplicationStagesStatusComponent } from './application-stages-status/application-stages-status.component';
import { SubmissionDetailsCardComponent } from './submission-applications-list/submission-details-card/submission-details-card.component';
import { LinkedApplicationsList } from './submission-applications-list/linked-applications-list/linked-applications-list.component';
import { EmptyApplicationsListComponent } from './applications-list/empty-applications-list/empty-applications-list.component';
import { RouterModule } from '@angular/router';
import { OpenCloseApplicationsTabsComponent } from './open-close-applications-tabs/open-close-applications-tabs.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { FormSectionsDetailsComponent } from './form-sections-details/form-sections-details.component';
import { EspControlsModule } from '../../custom-material/esp-controls.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { SubmittersSelectComponent } from './submitters-select/submitters-select.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FeedCardComponent } from './feed-card/feed-card.component';
import { FoldersReferenceApplicationsComponent } from './folders-reference-applications/folders-reference-applications.component';
import { SearchableFieldsDialogComponent } from './searchable-fields-dialog/searchable-fields-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SharedModule } from '../../shared/shared.module';
import { FormSubmissionSharedModule } from '../form-submission-shared/form-submission-shared.module';
import { RecordsApplicationsListComponent } from './records-applications-list/records-applications-list.component';
import { EmptyRecordsListComponent } from './records-applications-list/empty-records-list/empty-records-list.component';
import { FolderApplicationsFiltersComponent } from './folders-reference-applications/folder-applications-filters/folder-applications-filters.component';
import { SubmitFolderApplicationDialogComponent } from './folders-reference-applications/submit-folder-application-dialog/submit-folder-application-dialog.component';
import { RecordDetailsCardComponent } from './records-applications-list/record-details-card/record-details-card.component';
import { DeleteApplicantComponent } from '../../applicant-request/delete-applicant/delete-applicant.component';
import { FolderApplicationsListComponent } from './folders-reference-applications/folder-applications-list/folder-applications-list.component';
import { FormSubmissionEmailsVerificationComponent } from './form-submission-emails-verification/form-submission-emails-verification.component';
import { CodeInputModule } from 'angular-code-input';
import { MultiLookupVisibilityListComponent } from './multi-lookup-visibility-list/multi-lookup-visibility-list.component';
import { LookupMultiSelectComponent } from './multi-lookup-visibility-list/lookup-multi-select/lookup-multi-select.component';
import { GenericEmptyListComponent } from './generic-empty-list/generic-empty-list.component';
import { LookupMultiSelectItemsDialogComponent } from './multi-lookup-visibility-list/lookup-multi-select-items-dialog/lookup-multi-select-items-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedDialogModule } from '../../shared/shared-dialog.module';
import { ApplicationSummaryValuesComponent } from './application-summary-values/application-summary-values.component';
import { FolderApplicationsComponent } from './folders-reference-applications/folder-applications/folder-applications.component';
import { BreadcrumbsComponent } from '../../shared/breadcrumbs/breadcrumbs.component';
import { TabFolderApplicationsComponent } from './folders-reference-applications/tab-folder-applications/tab-folder-applications.component';
import { VarDirective } from '../../common/directives/ng-var.directive';
import { FormMultiSectionTableViewDialogComponent } from './form-sections-details/form-multi-section-table-view-dialog/form-multi-section-table-view-dialog.component';

@NgModule({
  declarations: [
    ApplicationsListComponent,
    AppliationDetailsCardComponent,
    ApplicationStagesStatusComponent,
    SubmissionDetailsCardComponent,
    LinkedApplicationsList,
    EmptyApplicationsListComponent,
    OpenCloseApplicationsTabsComponent,
    FormSectionsDetailsComponent,
    SubmittersSelectComponent,
    FeedCardComponent,
    FoldersReferenceApplicationsComponent,
    SearchableFieldsDialogComponent,
    RecordsApplicationsListComponent,
    EmptyRecordsListComponent,
    FolderApplicationsFiltersComponent,
    SubmitFolderApplicationDialogComponent,
    RecordDetailsCardComponent,
    DeleteApplicantComponent,
    FolderApplicationsListComponent,
    FormSubmissionEmailsVerificationComponent,
    MultiLookupVisibilityListComponent,
    LookupMultiSelectComponent,
    LookupMultiSelectItemsDialogComponent,
    ApplicationSummaryValuesComponent,
    FolderApplicationsComponent,
    TabFolderApplicationsComponent,
    FormMultiSectionTableViewDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ValidatorsModule,
    NgxPaginationModule,
    PipesModule,
    TranslateModule,
    MaterialEssentialsModule,
    MatTabsModule,
    MatDialogModule,
    RouterModule,
    EspControlsModule,
    MatCheckboxModule,
    NgxMatSelectSearchModule,
    FormSubmissionSharedModule,
    CodeInputModule,
    InfiniteScrollModule,
    SharedDialogModule,
    GenericEmptyListComponent,
    BreadcrumbsComponent,
    VarDirective
    //SharedModule
  ],
  exports: [
    ApplicationsListComponent,
    AppliationDetailsCardComponent,
    ApplicationStagesStatusComponent,
    SubmissionDetailsCardComponent,
    LinkedApplicationsList,
    OpenCloseApplicationsTabsComponent,
    FormSectionsDetailsComponent,
    SubmittersSelectComponent,
    FeedCardComponent,
    FoldersReferenceApplicationsComponent,
    RecordsApplicationsListComponent,
    FormSubmissionEmailsVerificationComponent,
    MultiLookupVisibilityListComponent,
    TabFolderApplicationsComponent
  ]
})
export class ApplicationSharedModule { }
