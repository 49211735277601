import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgForm, FormControl, Validators, FormGroup } from '@angular/forms';
import { LabelService, ListModel, LabelModel } from '../../common/services/label-service';

import { AccountService } from '../services/account.service'
import { Roles } from '../../common/enums/roles-enum';
import { ThemeService } from '../../common/services/theme.service';
import { SystemSettingsService } from '../../common/services/system-settings-service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less']
})
export class RegisterComponent implements OnInit {

  isLoginError: boolean = false;
  isCallBack: boolean = false;
  isEmailSent: boolean = false;

  constructor(public labelService: LabelService, private accountService: AccountService, private router: Router,
    public themeService: ThemeService
    , private _systemSettingsService: SystemSettingsService
    , private _translationHelperService: TranslationHelperService) {

  }

  ngOnInit() {
    if (this._systemSettingsService.systemSettings.enableRegistration.toLocaleLowerCase() == 'false') {
      this.router.navigateByUrl('/login');
    }
    sessionStorage.clear();
    //localStorage.clear();
    this.labelService.updateClientLabel();
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  fullName = new FormControl('', [Validators.required, Validators.minLength(3), this.noWhitespaceValidator]);
  email = new FormControl('', [Validators.required, Validators.email]);
  organization = new FormControl('', [Validators.required, Validators.minLength(3), this.noWhitespaceValidator]);

  getFullnameErrorMessage() {
    return this.fullName.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.FULLNAME-REQUIRED') :
      this.fullName.hasError('minlength') ? this._translationHelperService.getTranslationByKey('RequiredMessages.FULLNAME-MINIMUM-CHARACTERS') :
        '';
  }

  getEmailErrorMessage() {
    return this.email.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-REQUIRED') :
      this.email.hasError('email') ? this._translationHelperService.getTranslationByKey('RequiredMessages.EMAIL-INVALID') :
        '';
  }

  getOrganizationErrorMessage() {
    return this.organization.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.ORGANIZATION-REQUIRED') :
      this.organization.hasError('minlength') ? this._translationHelperService.getTranslationByKey('RequiredMessages.ORGANIZATION-MINIMUM-CHARACTERS') :
        '';
  }

  goto(comp) {
    if (comp == 'reset') {
      this.router.navigateByUrl('/forgotpassword')
    }
  }

  OnSubmit(fullName, email, organization) {
    this.isLoginError = false;
    this.isEmailSent = false;
    if (this.fullName.valid && this.email.valid && this.organization.valid) {
      this.isCallBack = true;
      this.accountService.organizationRegister(fullName, email, organization).subscribe((data: any) => {
        this.isCallBack = false;
        this.isEmailSent = true;
      },
        (err: HttpErrorResponse) => {
          this.isCallBack = false;
        });
    }
  }

}
