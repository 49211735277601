<!-- {{espFormGroup.value | json}} -->
<mat-form-field fxFlex appearance="outline" class="compressed">
  <mat-label [translate]="'Application.WHO-CAN-SUBMIT'"></mat-label>
  <mat-select panelClass="esp-multi-select" class="submitter-select" disableOptionCentering multiple [formControl]="espFormControl"
    (selectionChange)="selectionChanged($event.value)" [disabled]="disabled" required>
    <mat-select-trigger class="esp-select-trigger">
      <div fxLayout="row wrap" class="trigger-content">
        <ng-container
          *ngIf="!espFormControl.value.includes(selectAllValue) && !espFormControl.value.includes(noneValue)">
          <div *ngFor="let name of selectNamesList | filterByProperty:['name',filter.value];" class="trigger-box">
            {{name | translateToLocale}}
          </div>
        </ng-container>
        <ng-container *ngIf="espFormControl.value.includes(selectAllValue)">
          <div class="trigger-box">
            {{'All' | translate}}
          </div>
        </ng-container>
        <ng-container *ngIf="espFormControl.value.includes(noneValue)">
          <div class="trigger-box">
            {{'Common.None' | translate}}

          </div>
        </ng-container>
      </div>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search ngModel #filter="ngModel" [ngModelOptions]="{standalone: true}"
        [placeholderLabel]="('SEARCH'| translate)+'...'"
        [noEntriesFoundLabel]="('NoRequestFound.HEADING-TITLE'| translate)">
      </ngx-mat-select-search>
    </mat-option>

    <mat-option class="option-two-lines" [value]="selectAllValue" (click)="toggleAllSelection()">
      <div class="primary-text">{{'Common.SELECT-ALL' | translate}}</div>
    </mat-option>
    <mat-option class="option-two-lines" [value]="noneValue" (click)="toggleNoneSelection()">
      <div class="primary-text">{{'Common.None' | translate}}</div>
    </mat-option>
    <mat-optgroup label="Users" *ngIf="(applicants | filterByMultipleProperties:filter.value:['name', 'secondaryName'])?.length">
      <mat-option class="option-two-lines" *ngFor="let a of applicants | filterByMultipleProperties:filter.value:['name', 'secondaryName']"
        [value]="a" (click)="toggleItem()">
        <div class="option-text">
          <span class="primary-text">{{a.name | translateToLocale}}</span>
          <span class="secondry-text">{{a.secondaryName | translateToLocale}}</span>
        </div>  
      </mat-option>
    </mat-optgroup>
    <mat-optgroup label="Sub Groups" *ngIf="(subUserGroupLists | filterByMultipleProperties:filter.value:['name', 'secondaryName'])?.length">
      <mat-option class="option-two-lines" *ngFor="let g of subUserGroupLists | filterByMultipleProperties:filter.value:['name', 'secondaryName']"
        [value]="g" (click)="toggleItem()">{{g.name | translateToLocale}}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
<mat-error *ngIf="espFormControl.hasError('required') && (espFormControl.dirty || espFormControl.touched)">
  {{"Error.Required" | translate}}</mat-error>