import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { Organization } from '../models/organization';
import { OrgSettings } from '../../models/org-settings.model';
import { shareReplay } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const httpFileOptions = {
  headers: new HttpHeaders({ 'Content-Type': undefined })
};

@Injectable({
  providedIn: 'root'
})
export class OrgSettingsService {

  constructor(private http: HttpClient
    ) {
  }

  getOrgSettings(): Observable<OrgSettings> {
    return this.http.get<OrgSettings>('/webapi/orgSettings');
  }

}
