import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { CardLoad } from '../../common/animations/animations';
import { LabelService } from '../../common/services/label-service';
import { Subject, Subscription } from 'rxjs';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less'],
  animations: [CardLoad]
})
export class DashboardComponent implements OnInit, OnDestroy {
  public dashboardTiles: any[] = [];
  public visibleDashboardTiles: any[] = [];
  public showCarouselButtons: boolean = false;
  public tilesIndex: number = 0;
  public animate: string = 'cardIn';
  public showAmount: boolean = false;
  glitchInterval: any;
  @Input() isTabCall: boolean = false;
  @Input() resetDashboardSubject: Subject<boolean> = new Subject<boolean>();
  private _resetDashboardSubscription: Subscription;
  constructor(private _dashboardService: DashboardService, public labelService: LabelService) {
    //this.dashboardTiles = [
    //    {
    //      statusName: 'Pending',
    //      count: 23,
    //      amount: '0',
    //      amountSymbole: '',
    //      currencySymobl: '$'
    //    },
    //    {
    //      statusName: 'unresolved',
    //      count: 1,
    //      amount: '6.0000',
    //      amountSymbole: 'K',
    //      currencySymobl: '$'
    //    },
    //    {
    //      statusName: 'undecided',
    //      count: 1,
    //      amount: '7.8000',
    //      amountSymbole: 'K',
    //      currencySymobl: '$'
    //    },
    //    {
    //      statusName: 'Accepted',
    //      count: 18,
    //      amount: '2.4000',
    //      amountSymbole: 'K',
    //      currencySymobl: '$'
    //    },
    //    {
    //      statusName: 'Rejected',
    //      count: 2,
    //      amount: '0',
    //      amountSymbole: '',
    //      currencySymobl: '$'
    //    },
    //    {
    //      statusName: 'Pending',
    //      count: 23,
    //      amount: '0',
    //      amountSymbole: '',
    //      currencySymobl: '$'
    //    },
    //    {
    //      statusName: 'unresolved',
    //      count: 1,
    //      amount: '6.0000',
    //      amountSymbole: 'K',
    //      currencySymobl: '$'
    //    },
    //    {
    //      statusName: 'undecided',
    //      count: 1,
    //      amount: '7.8000',
    //      amountSymbole: 'K',
    //      currencySymobl: '$'
    //    },
    //    {
    //      statusName: 'Accepted',
    //      count: 18,
    //      amount: '2.4000',
    //      amountSymbole: 'K',
    //      currencySymobl: '$'
    //    },
    //    {
    //      statusName: 'Rejected',
    //      count: 2,
    //      amount: '0',
    //      amountSymbole: '',
    //      currencySymobl: '$'
    //    }
    //];

    //this.visibleDashboardTiles = this.dashboardTiles.filter((obj, index) => {
    //  return index < 4;
    //})
  }

  ngOnInit() {
    this.loadApplicationStatuses();
    this._resetDashboardSubscription = this.resetDashboardSubject.subscribe(response => {
      if (response) {
        this.loadApplicationStatuses();
      }
    });
  }

  loadApplicationStatuses() {
    this._dashboardService.getApplicationsStatuses().subscribe(resp => {
       this.dashboardTiles = resp.body;
      this.visibleDashboardTiles = this.dashboardTiles.filter((obj, index) => {
        return index < 4;
      })
      this.showAmount = this.dashboardTiles.filter((obj, index) => {
        return parseInt(obj.amount) > 0;
      }).length > 0;
    }, error => this.errorHandler(error));
  }

  getRandomClassName() {
    let classes = ['blue', 'purple'];
    return classes[Math.floor(Math.random() * classes.length)];
  }

  private errorHandler(error: Error): void {
  }

  nextCarouselItem() {
    this.animate = 'cardLeave';
    if (this.tilesIndex < this.dashboardTiles.length - 4)
      this.tilesIndex++;
    this.visibleDashboardTiles = [];
    this.resetCarouselItems();
    
    
  }

  prevCarouselItem() {
    this.animate = 'cardEnter';
    if (this.tilesIndex > 0)
      this.tilesIndex--;
    this.visibleDashboardTiles = [];
    this.resetCarouselItems();
  }

  getNewCarouselItems() {
    return new Promise(resolve => {
      var self = this;
      var visibleDashboardTiles = self.dashboardTiles.filter((obj, index) => {
        return index >= self.tilesIndex && index <= self.tilesIndex + 3;
      })
      resolve(visibleDashboardTiles);
    });
  }

  async resetCarouselItems() {
    this.visibleDashboardTiles = JSON.parse(JSON.stringify(await this.getNewCarouselItems()));
  }

  showButtonsOnCarousel(status) {
    this.showCarouselButtons = status;
  }

  getDecimalPointsNubmer(num: string) {
    return parseFloat(num).toFixed(1);
  }

  addInvoice() {
    alert("Add invoice clicked!");
  }

  ngOnDestroy() {
    this._resetDashboardSubscription.unsubscribe()
  }
}
