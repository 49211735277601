import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CustomFieldService } from "../../../common/services/custom-field.service";

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
    constructor( private customFieldService: CustomFieldService) {

    }

    validateFile(event: any, extensions, maxSize, nameRegex): Observable<FileValidationResult> {
        console.log('Inside validateFile method');
        const resultSubject = new Subject<FileValidationResult>();
        // try {
            if(event.target.files.length > 0) {
                var fileSize = event.target.files[0].size;
                var inMbs = (fileSize / 1024) / 1024;
                const isFileSizeValid = this.validateFileSize(inMbs , maxSize)
                const isFileNameValid = this.validateFileName(event.target.files[0].name, nameRegex);
                const isFileExtensionValid = this.validateFileExtension(event.target.files[0].name, extensions);

                
                // Create a result object
                const validationResult: FileValidationResult = {
                    isFileSizeValid: isFileSizeValid,
                    isFileNameValid: isFileNameValid,
                    isFileExtensionValid: isFileExtensionValid
                };
        
                // Emit the result through the Observable
                resultSubject.next(validationResult);
                // resultSubject.complete();
                console.log('Emitting validation result:', validationResult);

                return resultSubject.asObservable();
            }
        // } catch (error) {
        //     console.error('Error in validateFile:', error);
        //     return resultSubject.asObservable();
        // }
    }

    validateFileName(fileName, nameRegex): boolean {
        if(nameRegex !== '' && nameRegex != undefined && nameRegex != null) {
            let fileNameWithoutExtension = this.customFieldService.getFileNameWithoutExtension(fileName);        
            const regexObject = new RegExp(nameRegex);
            const validFileName = regexObject.test(fileNameWithoutExtension);
            return validFileName;
        } else {
            return true
        }
    }
    validateFileSize(fileSize, maxFileSize): boolean {
        if(fileSize > maxFileSize) {
            return true
        } else {
            return false
        }
    }
    validateFileExtension(name, extensions): boolean {
        if (extensions && extensions.trim()) {
            var extensionsArray: string[] = extensions.split(',');
            if (extensionsArray[0] == "-2") {
              var extenssionFilter = extensionsArray.filter(x => x != "-2");
              var errorString = extenssionFilter.toString();
              extensions = errorString;
            }
            var ext = name.substring(name.lastIndexOf('.'));
            var lowerCaseExt = ext.toLowerCase();
            if (extensionsArray.find(x => x == lowerCaseExt) != undefined) {
              return true
            }
            return false
      
          }
          else {
            return true;
          }
    }
    
}
interface FileValidationResult {
    isFileSizeValid: boolean;
    isFileNameValid: boolean;
    isFileExtensionValid: boolean;
}
