import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { AccountsListComponent } from './accounts-list/accounts-list.component';
import { ApplicationLinkComponent } from './application-link/application-link.component';
import { RegisterApplicantComponent } from './register-applicant/register-applicant.component';
import { LogoutComponent } from './logout/logout.component';
import { VerifyApplicantComponent } from './verify-applicant/verify-applicant.component';
// import { SubmitRequestComponent } from './submit-request/submit-request.component';
import { SubmitRequestV2Component } from './submit-request-v2/submit-request-v2.component';
import { SubmitRequestLoginComponent } from './submit-request-login/submit-request-login.component';
import { SubmitRequestSuccessComponent } from './submit-request-success/submit-request-success.component';
import { WinAuthCanActivateGuard } from '../common/guard/win-auth-can-activate-guard.service';
// Routes

const routes: Routes = [
  { path: 'forgotpassword', component: ForgotPasswordComponent, data: { title: 'ForgotPassword', pageTitle: 'Forgot Password' }, canActivate:[WinAuthCanActivateGuard] },
  { path: 'setpassword/:key/:type', component: SetPasswordComponent, data: { title: 'Set Password', pageTitle: 'Set Password' } },
  { path: 'setpassword/:key/:type/:definitionkey', component: SetPasswordComponent, data: { title: 'Set Password', pageTitle: 'Set Password' } },
  { path: 'accounts', component: AccountsListComponent, data: { title: 'Select Account', pageTitle: 'Select Account' } },
  { path: 'register/applicant', component: RegisterApplicantComponent, data: { title: 'Register Applicant', pageTitle: 'Register Applicant' } },
  { path: 'register/applicant/:email', component: RegisterApplicantComponent, data: { title: 'Register Applicant', pageTitle: 'Register Applicant' } },
  //{ path: 'application/link/:definitionKey', component: ApplicationLinkComponent, data: { title: 'Application Link', pageTitle: 'Application Link' } },
  // { path: 'application/link/:definitionKey', component: SubmitRequestComponent, data: { title: 'Submit Request', pageTitle: 'Submit Request' } },
  { path: 'application/link/:definitionKey', component: SubmitRequestV2Component, data: { title: 'Submit Request', pageTitle: 'Submit Request' } },
  //{ path: 'application/link/:definitionKey/:email', component: ApplicationLinkComponent, data: { title: 'Application Link', pageTitle: 'Application Link' } },
  // { path: 'application/link/:definitionKey/:email', component: SubmitRequestComponent, data: { title: 'Submit Request', pageTitle: 'Submit Request' } },
  { path: 'application/link/:definitionKey/:email', component: SubmitRequestV2Component, data: { title: 'Submit Request', pageTitle: 'Submit Request' } },
  //{ path: 'application/link/:definitionKey/:invitationKey/:email', component: ApplicationLinkComponent, data: { title: 'Application Link', pageTitle: 'Application Link' } },
  // { path: 'application/link/:definitionKey/:invitationKey/:email', component: SubmitRequestComponent, data: { title: 'Submit Request', pageTitle: 'Submit Request' } },
  { path: 'application/link/:definitionKey/:invitationKey/:email', component: SubmitRequestV2Component, data: { title: 'Submit Request', pageTitle: 'Submit Request' } },
  { path: 'logout', component: LogoutComponent, data: { title: 'Logout.TITLE', pageTitle: 'Logout.TITLE' } },
  { path: 'verify/:key/Applicant/:definitionkey', component: VerifyApplicantComponent, data: { title: 'Account Activation', pageTitle: 'Account Activation' } },
  { path: 'submit/request/login', component: SubmitRequestLoginComponent, data: { title: 'Login', pageTitle: 'login' } },
  { path: 'submit/request/success', component: SubmitRequestSuccessComponent, data: { title: 'Success', pageTitle: 'Success' } },
];

// Module
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
export const routedComponents = [
  LoginComponent,
  ForgotPasswordComponent,
  SetPasswordComponent,
  ApplicationLinkComponent
];

