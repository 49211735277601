export enum LookupType{
    LookupAddon = "Lookups",
    Definition = "Definitions",
    Applicant = "Applicants",
    User = "Users",
    JobRole = "JobRoles",
    BusinessUnits ="BusinessUnits"
}

export enum LookupIdEnum{
  OwningGroup = -2000
}

export enum DefinitionLookupVisibility{
  None = 'None',
  MainForm = 'MainForm',
  ApplicationsTab = 'ApplicationsTab'
}

export enum ViewPermission{
  All = 'All',
  AsPerPermission = 'AsPerPermission'
}