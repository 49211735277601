import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { CommentModel } from '../../models/applicationdetailmodel';
import { FormModel } from './application-definition';
import { CustomFieldValueModel } from '../../models/custom-field-value-model';
import { SignatureModel } from '../../models/signature.model';
import { CriteriaPermission } from '../../common/enums/criteria-permission.enum';
import { FormGroup } from '@angular/forms';
import { SectionValuesModel } from '../../models/section-values';
export class StageCriteria {
  constructor(
  ) {
    this.isExpended = false;
    this.comments = [];
  }
  public id: number;
  public name: string;
  public nameLocale?: string;
  public ownerId: number;
  public ownerIdJobRole: number;
  public ownerIdUserLookupCustomFieldPath: string;
  public ownerName: string;
  public daysToComplete: number;
  public slaHours: number;
  public weight: number;
  public isExpended: boolean;
  public isSystem: boolean;
  public isEnabled: boolean;
  public form: FormModel;
  public formValues: CustomFieldValueModel[];
  public sectionValues?: SectionValuesModel[];
  assessmentId: number;
  assessmentStatus: string;
  assessedOnDate?: Date;
  comments: CommentModel[];
  isOwner: boolean;
  stageId: number;
  public isJumped?: boolean;

  public subApplicantId: number;
  public subDefinitionId: number;
  public subApplicationId: number;
  public type: string;
  public isDisabled: boolean;
  public delegatedToName: string;
  public reassignAssessments: boolean;
  public reminder: string;
  public approveText: string;
  public approveTextLocale?: string;
  public rejectText: string;
  public rejectTextLocale?: string;
  public hasApplication: boolean;
  public customFieldsCount: number;
  public isSigned?: boolean;
  public isFaceRecognition?: boolean;
  public showInFeeds?: boolean;
  public isDelete?: boolean;
  public isHistory?: boolean;

  signature?: SignatureModel;
  permissions?: CriteriaPermission[];
  isReturned?: boolean;

  version?: number;

  public formGroup?: FormGroup;
  public includeAttachment? : boolean;
  public attachmentRequired? : boolean;
  public isProcess?: boolean;
  public approvalType?: string;
}

export class ReassignCriteriaOwner {
  public name: string;
}
