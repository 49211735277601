import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ApplicantRequestService } from '../../../../applicant-request/services/applicant-request.service';
import { ApplicationService } from '../../../../application/services/application.service';
import { LabelService } from '../../../../common/services/label-service';
import { MessageService } from '../../../../common/services/message-service';
import { UserInfoService } from '../../../../common/services/userinfo-service';
import { OrganizationUser } from '../../../../organization/models/organization-user';

@Component({
  templateUrl: './add-submission-dialog.component.html',
  styleUrls: ['./add-submission-dialog.component.scss']
})
export class AddSubmissionDialogComponent implements OnInit {
  public applicants: any[] = [];
  public allApplicants: any[] = [];
  delegateOpenedAssessments: boolean;
  public showOwners: boolean = false;
  public criteriaName: string;
  public selectedApplicantId = 0;
  searchControl = new FormControl({ value: '', disabled: true });
  private _subs = new SubSink();
  applicantsPage = 0;
  showSpinner = true;
  constructor(
    public applicationService: ApplicationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddSubmissionDialogComponent>,
    public labelService: LabelService,
    public _messageService: MessageService,
    public _applicantRequestService: ApplicantRequestService,
    public _userInfoService: UserInfoService
  ) { }

  ngOnInit(): void {
    this.loadApplicants();
    this._subs.sink = this.searchControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(value => {
      this.applicantsPage = 0;
      this.stopPagination = false;
      this.paginateApplicants();
    });
  }
  private loadApplicants() {

    this.allApplicants = this.data.allowedUsers;
    let loggedInApplicant = this.allApplicants.find(ap => ap.id == this._userInfoService.applicantPersonaId);
    if (loggedInApplicant) {
      this.allApplicants = this.allApplicants.filter(ap => ap.id != this._userInfoService.applicantPersonaId);
      this.allApplicants.unshift(loggedInApplicant)
      this.applicants = this.allApplicants
      this.selectApplicant(loggedInApplicant)
    }
    this.paginateApplicants();
    this.searchControl.enable();
    this.showSpinner = false;
  }

  selectApplicant(applicant) {
    this.applicants.filter(u => u.isSelected).forEach(u => u.isSelected = false);
    this.applicants.find(u => u.id == applicant.id).isSelected = true;
    this.selectedApplicantId = applicant.id;
  }


  trackByFn(index, item: OrganizationUser) {
    return item.id; // or item.id
  }

  goToSubmitForm() {
    if (!this.selectedApplicantId) { return; }
    this.dialogRef.close({ applicantionId: this.data.applicationId, applicantId: this.selectedApplicantId });
  }
  private paginateApplicants() {
    if (!this.stopPagination) {
      this.stopPagination = true;
      this.applicantsPage++;
      const end = this.applicantsPage * 10;
      const searchText: string = this.searchControl.value.toLocaleLowerCase();
      let filteredApplicants: any[] = [];
      if (searchText) {
        filteredApplicants = this.allApplicants.filter(u => u.fullName.toLocaleLowerCase().includes(searchText));
      } else {
        filteredApplicants = JSON.parse(JSON.stringify(this.allApplicants));
      }
      if (filteredApplicants.length >= end) {
        this.applicants = filteredApplicants.slice(0, end);
        this.stopPagination = false;
      } else {
        this.applicants = filteredApplicants;
      }
    }
  }

  stopPagination = false;
  onModalScrollDown() {
    this.paginateApplicants();
  }


}
