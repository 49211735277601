import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private http: HttpClient
    ) {
  }

  getNotification(): Observable<any> {
    const headers = new HttpHeaders({ 'ignoreProgressBar': '', 'ignoreErrorMessage': '' });
    return this.http.get<Observable<any>>('/webapi/notfications/', { headers: headers, observe: 'response' }).pipe(
      map(resp => resp.body)
    );
  }
}
