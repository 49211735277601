import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[CustomPhoneNumber][formControlName],[CustomPhoneNumber][formControl],[CustomPhoneNumber][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomPhoneNumberDirective, multi: true }]
})
export class CustomPhoneNumberDirective implements Validator {

  validate(c: FormControl): { [key: string]: any } {
    return customPhoneNumber(c);
  }


}

export function customPhoneNumber(c: FormControl): { [key: string]: any } {
  let v = c.value;
  if (v == null || v == '') {
    return null;
  }
  let emailRegularExpression: RegExp = /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/;
  return (emailRegularExpression.test(v) == false) ? { "PhoneNumber": true } : null;
}
