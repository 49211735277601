import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[customMax][formControlName],[customMax][formControl],[customMax][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomMaxDirective, multi: true }]
})
export class CustomMaxDirective implements Validator {
  @Input()
  customMax: number;

  validate(c: FormControl): { [key: string]: any } | null {
    return customMaxValidator(this.customMax)(c);
  }
}

export function customMaxValidator(customMax: number): ValidatorFn {
  return (c: FormControl): { [key: string]: any } | null => {
    let v = c.value;
    if (!v || customMax == 0 || customMax == null) {
      return null;
    }
    return (Number(v) > Number(customMax) && !isNaN(v)) ? { "customMax": true } : null;
  }
}
