export class CriteriaOwner {
  public id: string;
  public name: string;
  public description?: string;
  public type: CriteriaOwnerType
}

export enum CriteriaOwnerType {
  Users = 1,
  JobRoles = 2,
  UserLookup = 3,
  Public = 4, // used to show appliction to public in Definition Security
  FilterList = 5,
  ApplicationAssessors = 6,
  FieldPath = 7
}
