import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class SelectedApplicationService {
  public applicationName: string;
  public lookupaddonName: string;
  constructor() {
    
  }
  set setApplicationName(applicationName) {
    this.applicationName = applicationName;
  }
  get getApplicationName(): string {
    return this.applicationName;
  }

  set setLookupAddOnName(lookupaddonName) {
    this.lookupaddonName = lookupaddonName;
  }
  get getLookupAddOnName(): string {
    return this.lookupaddonName;
  }

}
