<div fxLayout="column" fxLayoutAlign="center" [formGroup]="customFieldForm">

    <!-- <input type="hidden" formControlName="customFieldName{{customField.id}}"  [(ngModel)]="customField.value" [required]="customField.isRequired"> -->
    <quill-editor [ngClass]="{'auto-hight' : isExpanded}"  class="editor" debounceTime="2000" [placeholder]="customField.label" [modules]="quillConfig"
      [customOptions]="customOptions" formControlName="customFieldName{{customField.id}}"
      [(ngModel)]="customField.value" (onContentChanged)="onTextChange($event)">
    </quill-editor>

    <div class="quill-toolbar-custom{{customField.sectionCustomFieldId}} quill-toolbar-custom">
      <span class="ql-formats" matTooltip="Font Size" matTooltipPosition="above">
        <select class="ql-size" >
          <option value="12px">Small</option>
          <option selected>Normal</option>
          <option value="18px">Large</option>
        </select>
      </span>
      <span class="ql-formats" *ngIf="isExpanded">
        <select class="ql-color"  matTooltip="Color" matTooltipPosition="above">
          <option selected="selected"></option>
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          <option value="#ffffff"></option>
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
        <select class="ql-background" matTooltip="Background" matTooltipPosition="above">
          <option selected="selected"></option>
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          <option value="#ffffff"></option>
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold" matTooltip="Bold" matTooltipPosition="above"></button>
        <button class="ql-italic" matTooltip="Italic" matTooltipPosition="above"></button>
        <button class="ql-underline" matTooltip="Underline" matTooltipPosition="above"></button>
        <button class="ql-strike" matTooltip="Strike" matTooltipPosition="above"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="bullet" matTooltip="Bullet list" matTooltipPosition="above"></button>
        <button *ngIf="isExpanded" class="ql-list" value="ordered" matTooltip="Ordered list" matTooltipPosition="above"></button>
        <button *ngIf="isExpanded" class="ql-direction" value="rtl" matTooltip="Direction" matTooltipPosition="above"></button>
      </span>
      <span class="ql-formats" *ngIf="isExpanded">
        <button class="ql-script" value="sub" matTooltip="Sub Script" matTooltipPosition="above"></button>
        <button class="ql-script" value="super" matTooltip="Super Script" matTooltipPosition="above"></button>
      </span>
      <span class="ql-formats" *ngIf="isExpanded">  
        <button class="ql-link" matTooltip="Link" matTooltipPosition="above"></button>
        <button class="ql-image" matTooltip="Image" matTooltipPosition="above"></button>
        <!-- <button class="ql-video" matTooltip="Video" matTooltipPosition="above"></button> -->
        <button class="ql-clean" matTooltip="Clean" matTooltipPosition="above"></button>
      </span>
    </div>

</div>