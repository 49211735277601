import { Injectable } from '@angular/core';
import { FeildType } from '../enums/field-type-enum';
import { DatePipe } from '@angular/common';
import { CustomizationSettingsField } from '../../models/CustomizationSettingsSubmitModel';
import { CustomFieldModel, CustomFieldLookupModel } from '../../profile-customization/Model/CustomFieldModel';
import { CustomFieldValueModel } from '../../models/custom-field-value-model';
import { TranslateToLocalePipe } from '../pipes/translate-to-locale.pipe';
import { ActionValue } from '../../models/action-model';
import * as moment from 'moment';
import { CurrencyModel } from '../../models/currency-model';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonFieldsService } from '../../settings/common-fields/common-fields.service';
@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {

  constructor(private _datePipe: DatePipe, private translateToLocalePipe: TranslateToLocalePipe, private _translateService: TranslateService, private sanitizer: DomSanitizer, private commonFieldService: CommonFieldsService) { }

  isFormValid(customFieldValidParam: boolean[]) {
    let formIsValid = false;
    for (let _i = 0; _i < customFieldValidParam.length; _i++) {
      if (_i == 0) {
        formIsValid = true;
      }
      if (!!customFieldValidParam[_i] == false) {
        formIsValid = false;
      }
    }
    return formIsValid;
  }

  getFeildValue(feild: CustomFieldModel) {
    switch (feild.type) {
      case FeildType.MultiSelection: {
        let values = '';
        for (let i = 0; i < feild.lookupValues.length; i++) {
          if (feild.lookupValues[i].isSelected) {
            values += this.translateToLocalePipe.transform(feild.lookupValues[i].label) + ', ';
          }

          if (feild.lookupValues.length - 1 == i && values != '') {
            values = this.translateToLocalePipe.transform(values.substring(0, values.length - 2));
          }
        }

        return values;
      }
      case FeildType.SingleSelection: {
        let values = '';
        for (let i = 0; i < feild.lookupValues.length; i++) {
          if (<string><any>feild.lookupValues[i].id == feild.value) {
            values = JSON.stringify({value: feild.lookupValues[i].label, color: feild.lookupValues[i].color});
          }
        }

        return values;
      }

      case FeildType.Date:{
        if (feild.value) {
          return this.getDateFieldValue(feild.value, feild.allowedValuesCriteria);
        } else {
          return '';
        }
      }
      case FeildType.DateTime: {
        if (feild.value) {
          return this.getDateTimeFieldValue(feild.value);
        } else {
          return '';
        }
      }

      case FeildType.DateRange: {
        let formatedDate = '';
        if (feild.value) {
          let dates = feild.value.trim().split('&');
          if(dates && dates.length > 1){
            formatedDate = this.getDateFieldValue(dates[0], feild.allowedValuesCriteria) + " - " + this.getDateFieldValue(dates[1], feild.allowedValuesCriteria);
          } else {
            formatedDate = feild.value;
          }
        }
        return formatedDate;
      }

      case FeildType.Attachment:
      case FeildType.Image:
      {
        //return feild.details && feild.details.name ? feild.details.name : '';
      }

      case FeildType.Money: {
        if (feild.value && feild.selectedCurrencySymbol) {
          return feild.selectedCurrencySymbol + ' ' + feild.value;
        }
        else if (feild.value && feild.value.indexOf(':') > 0) {
         return this.getMoneyFieldValue(feild.value);
        }
        return "";
      }
      case FeildType.Price: {
        if (feild.value && feild.value.indexOf(':') > 0) {
         return this.getPriceFieldValue(feild.value);
        }
        return "";
      }
      case FeildType.Hyperlink: {
        if(feild.value) {
          try {
            return JSON.parse(feild.value);
          } catch (e) {
            return feild.value
          }
        }
        return "";
      }
      case FeildType.Text:
      case FeildType.MultiLine: {
        if(feild.value) {
          // feild.value = "{\"en\":\"short text\",\"ar\":\"short text (ar)\",\"fr\":\"\"}";
          try {
            return JSON.parse(feild.value);
          } catch (e) {
            return feild.value
          }
        }
        return "";
      }
      case FeildType.RichText: {
        if (feild.value) {
          return this.commonFieldService.sanitizeHtml(feild.value);
        } else {
          return '';
        }
      }
      case FeildType.MultiLine: {
        return feild.value.replace(/\n/g, "<br />");
      }
      case FeildType.LookupAddon: {
        if(!feild.isMultipleAllowed) {
          return feild.selectedLookupText ? feild.selectedLookupText : feild.lookupValue;
        }
      }
      default:
        return feild.value;
    }
  }

  getDateTimeFieldValue(value: string): string {
    let momentDate = moment.parseZone(value);
    momentDate.locale(this._translateService.currentLang);
    return momentDate.format('DD MMM, YYYY, hh:mm A (Z)');
  }
  getDateFieldValue(value: string, format: string): string {
    //const momentFormated = moment.utc(feild.value).format('DD MMM, yyyy');
    let momentDate = moment.parseZone(value);
    momentDate.locale(this._translateService.currentLang);
    if(format == "yyyy"){
      return momentDate.format('YYYY');
    } else if (format == "MM/yyyy") {
      return momentDate.format('MMM, YYYY');
    } else {
      return momentDate.format('DD MMM, YYYY');
    }
    //const angularFormated = this._datePipe.transform(feild.value, 'dd MMM, yyyy');
    //return angularFormated;
  }

  getMoneyFieldValue(value: string): string{
    if (value && value.indexOf(':') > 0) {
      var values: string[] = value.split(':');
      if (values[4]) {
        return values[4] + ' ' + values[0]
      }
    }
    return "";
  }

  getPriceFieldValue(value: string): string{
    //100:1:100.00:1:$:GM
    if (value && value.indexOf(':') > 0) {
      var values: string[] = value.split(':');
      if (values[5]) {
        return `${values[4]} ${values[0]}/${values[5]}`;
      }
    }
    return "";
  }

  getIconFileExtention(fileName: string) {
    if (fileName) {
      const folder = 'file-extensions/';
      let extension = fileName.split('.').pop();
      extension = extension.toLocaleLowerCase();
      if (extension == "csv" || extension.startsWith("xl")) {
        return folder + "iconsDocumentsXlsGreen.svg"
      } else if (extension == "gif" || extension == "jpeg" || extension == "png" || extension == "jpg" || extension == "bmp") {
        return folder + "iconsDocumentsImgGreen.svg";
      } else if (extension.startsWith("pdf")) {
        return folder + "iconsDocumentsPdfGreen.svg";
      } else if (extension == "pptx" || extension == "ppt" || extension == "potx" || extension == "pot" || extension == "odp" || extension == "ppsx" || extension == "pps" || extension == "pptm" || extension == "potm" || extension == "ppsm") {
        return folder + "iconsDocumentsPptxGreen.svg";
      } else if (extension == "txt" || extension == "doc" || extension == "docx" || extension == "rtx" || extension == "rft" || extension == "text") {
        return folder + "iconsDocumentsDocxGreen.svg";
      }
      else {
        return folder + "iconsDocumentsUnknownGreen.svg";
      }
    }
  }

  //Method will return number of dots (.) occuring in file name
  getFileExtentionDotLength(fileName: string) {
    var num = fileName.match(/\./g).length;
    return num;
  }

  getFileExtention(fileName: string) {
    let extension: string = "";
    if (fileName) {
      extension = fileName.split('.').pop();
      extension = extension.toUpperCase();
    }
    return extension;
  }

  getFileNameWithoutExtension(fileName: string) {
    if (fileName) {
      let lastIndex = fileName.lastIndexOf('.');
      if (lastIndex > -1) {
        return fileName.substring(0, lastIndex)
      } else {
        return fileName;
      }
    }
  }
  public getFileSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 KB';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (i == 0) return bytes + ' ' + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  }

  public static mapPostValueModel(customFields: CustomFieldModel[], sectionId: number, fieldValues: CustomFieldValueModel[] = []): CustomFieldValueModel[] {
    let sectionIndex: number = 0;
    fieldValues = [];
    customFields.forEach(field => {
      //if (field.isVisible) {
      if (field.isMultipleAllowed) {
        if(field.nestedFields && field.type == FeildType.LookupAddon &&  field.nestedFields[0]?.value.length > 0) {
          const value = field.nestedFields[0].value;
          let valueArray = [];
          if (typeof value === 'string') {
             valueArray = [value];
          } else if (Array.isArray(value)) {
            valueArray = value
          }
          valueArray.forEach((value , fieldValueIndex) => {
            fieldValues.push(
              {
                id: 0,
                sectionCustomFieldId: field.sectionCustomFieldId,
                sectionId: sectionId,
                sectionIndex: sectionIndex,
                value: value,
                selectedLookupText: field.selectedLookupText,
                details: null,
                isSync: field.isSyncColumn,
                type: field.type,
                isRealTime: field.isRealTime,
                targetFieldType: field.targetFieldType,
                lookupItems: [],
                index: fieldValueIndex
              });
          });
        }
        else if (field.nestedFields) {
            field.nestedFields.forEach((nestedField, nestedFieldIndex) => {
              let value: string = "";
              value = this.formatValue(nestedField);
              // console.log('NestedField', field.nestedFields);
              // console.log('Nested Field Value', value);
              if(nestedField.type == FeildType.Attachment || nestedField.type == FeildType.Image || nestedField.type == FeildType.Gallery) {
                if(nestedField.details) {
                  if(value == nestedField.details.name) {
                    value = ''
                  } else {
                    value = this.formatValue(nestedField);
                  }
                }
              }
              fieldValues.push(
                {
                  id: 0,
                  sectionCustomFieldId: field.sectionCustomFieldId,
                  sectionId: sectionId,
                  sectionIndex: sectionIndex,
                  value: value,
                  selectedLookupText: field.selectedLookupText,
                  details: null,
                  isSync: field.isSyncColumn,
                  type: field.type,
                  isRealTime: field.isRealTime,
                  targetFieldType: field.targetFieldType,
                  lookupItems: [],
                  index: nestedFieldIndex
                });
            });
        }
      } else {
        // let values: string[] = [];
        let value: string = "";
        value = this.formatValue(field);
        // console.log('Field', field);
        // console.log('Field Value', value);
        if(field.type == FeildType.Attachment || field.type == FeildType.Image || field.type == FeildType.Gallery) {
          if(field.details) {
            if(value == field.details.name) {
              value = ''
            } else {
              value = this.formatValue(field);
            }
          }
        }

        // values.push(value);
          fieldValues.push(
            {
              id: 0,
              sectionCustomFieldId: field.sectionCustomFieldId,
              sectionId: sectionId,
              sectionIndex: sectionIndex,
              value: value,
              selectedLookupText: field.selectedLookupText,
              details: null,
              isSync: field.isSyncColumn,
              type: field.type,
              isRealTime: field.isRealTime,
              targetFieldType: field.targetFieldType,
              lookupItems: [],
              index: 0
            });
      }
    });
    return fieldValues;
  }

  public static formatValue(field: CustomFieldModel): string {
    let value: string = "";
    switch (field.type) {
      case FeildType.MultiSelection: {
        value = field.lookupValues.filter(x => x.isSelected).map(function (item) { return item.id }).join(",");
        break;
      }
      case FeildType.Money: {
        if (field.value && field.selectedCurrencyId && field.selectedCurrencySymbol) {
          value = field.value + ':' + field.selectedCurrencyId + ':' + field.selectedCurrencySymbol;
        } else {
          value = "";
        }
        break;
      }
      case FeildType.Price: {
        if (field.value && field.selectedCurrencyId && field.selectedCurrencySymbol && field.selectedPriceUnit) {
          value = field.value + ':' + field.selectedCurrencyId + ':' + field.selectedCurrencySymbol + ":" + field.selectedPriceUnit;
        } else {
          value = "";
        }
        break;
      }
      case FeildType.Hyperlink: {
        // if(field.settings != null && field.settings.length > 0 && field.settings.filter(s => s.key == 'hyperlinkLabelEnabled').length > 0) {
        //   let settings = field.settings.filter(s => s.key == 'hyperlinkLabelEnabled');
        //   field.hyperlinkLabelEnabled = settings[0].value;
        // }
        if (field.value) {
          let formatedValue = {
            title: field.hyperlinkTitle ? field.hyperlinkTitle : '',
            url: field.value
          }
          value = JSON.stringify(formatedValue);
        } else {
          value = "";
        }
        break;
      }
      case FeildType.Text: {
        if (field.value && field.isMultiLingual) {
          let formatedValue = {
            en: field.value,
            ar: field.isMultiLingualText ?? '',
            fr: ""
          }
          value = JSON.stringify(formatedValue);
        } else if(field.value && !field.isMultiLingual) {
          value = field.value
        } else {
          value = "";
        }
        break;
      }
      case FeildType.RichText: {
        if (field.value) {
          value = field.value
        } else {
          value = "";
        }
        break;
      }
      case FeildType.MultiLine: {
        if (field.value && field.isMultiLingual) {
          let formatedValue = {
            en: field.value,
            ar: field.isMultiLingualMultiLine ?? '',
            fr: ""
          }
          value = JSON.stringify(formatedValue);
        } else if(field.value && !field.isMultiLingual) {
          value = field.value
        } else {
          value = "";
        }
        break;
      }
      case FeildType.Attachment: {
        value = field.value;
        if (field.value == '') {
          field = null;
        }
        break;
      }
      case FeildType.Gallery: {
        let values: string[] = [];
        // console.log('Field', field);
        // console.log('Field Value', field.details.gallery);
        if(field.details && field.details.gallery) {
          field.details.gallery.forEach( d  => {
            values.push(d.fileId);
          });
        }
        if(values.length > 0) {
          value = JSON.stringify(values);
        } else {
          value = '';
        }
        // value = field.value;
        if (field.value == '') {
          field = null;
        }
        break;
      }
      case FeildType.DateTime: {
        if (field.value) {
          const date = moment(field.value);
          if (date.isValid()) {
            var offset = new Date(field.value).getTimezoneOffset();
            
            value = moment(field.value).format('YYYY-MM-DDTHH:mm:ssZ');//'YYYY-MM-DDT00:00:00Z'
            // console.log('1');

          } else {
            value = field.value;
            // console.log('2');
            
          }
        }
        break
      }
      case FeildType.Date: {
        if (field.value) {
          const date = moment(field.value);
          if (date.isValid()) {
            // console.log('Value',field.value);
            
            value = moment(field.value).utc(true).locale('en').format('YYYY-MM-DDT00:00:00Z');//'YYYY-MM-DDT00:00:00Z'
            // console.log('1');

          } else {
            value = field.value;
            // console.log('2');
            
          }
        }
        break
      }
      // case FeildType.LookupAddon: {
      //   if (field.value) {
      //     if(field.isMultipleAllowed) {
      //       value = field.value.join(', ');
      //     } else {
      //       value = field.value;
      //     }
          
      //   }
      //   break
      // }
      default: {
        value = field.value;
        break;
      }
    }
    return value;
  }

  static parseDateForPost(value: string): string{
    let parsedDate: string = '';
    if (value) {
      const date = moment(value);
      if (date.isValid()) {
        parsedDate = moment(value).utc(true).format('YYYY-MM-DDT00:00:00Z');//'YYYY-MM-DDT00:00:00Z'
      } else {
        parsedDate = value;
      }
    }
    return parsedDate;
  }

  static mapGetValueModel(customFields: CustomFieldModel[], customFieldsValues: CustomFieldValueModel[], titleFieldId: number = 0, removeEmptyValueField = false, resubmit = false): CustomFieldModel[] {
    let removeIndexes: number[] = [];
    customFields.forEach(function (field, index) {
      let valueObjects = customFieldsValues.filter(cfv => cfv.sectionCustomFieldId == field.sectionCustomFieldId);
      if (field.isMultipleAllowed) {
        field.nestedFields = [];
        if (valueObjects && valueObjects.length > 0) {
          const isAllLookupAddon = valueObjects.every(valueObject => valueObject.type === FeildType.LookupAddon);
          console.log('valueObjects', valueObjects);
          
          if (isAllLookupAddon) {
            const mergedValueObject: CustomFieldValueModel = {
              sectionIndex: valueObjects[0].sectionIndex,
              isMultiLangual: valueObjects[0]?.isMultiLangual,
              sectionCustomFieldId: valueObjects[0].sectionCustomFieldId,
              type: valueObjects[0].type,
              selectedLookupText: valueObjects[0].selectedLookupText,
              index: valueObjects[0].index,
              value: resubmit ? valueObjects.map(valueObject => valueObject.value) : valueObjects.map(valueObject => valueObject.selectedLookupText)
            };

            let nestedField: CustomFieldModel = _.cloneDeep(field);
            CustomFieldService.mapGetValueField(nestedField, index, mergedValueObject, titleFieldId, removeIndexes);
            CustomFieldService.addValidationKey(customFields.filter(f => f.isMultipleAllowed && f.nestedFields && f.nestedFields.length > 0).flatMap(field => field.nestedFields), nestedField);
            field.nestedFields.push(nestedField);
          } else {
            valueObjects.sort((a, b) => (a.index > b.index ? 1 : -1)).forEach((valueObject, valueIndex) => {
                let nestedField: CustomFieldModel = _.cloneDeep(field);
                CustomFieldService.mapGetValueField(nestedField, index, valueObject, titleFieldId, removeIndexes);
                CustomFieldService.addValidationKey(customFields.filter(f => f.isMultipleAllowed && f.nestedFields && f.nestedFields.length > 0).flatMap(field => field.nestedFields), nestedField);
                field.nestedFields.push(nestedField);
            });
          }
        } else {
          let nestedField: CustomFieldModel = _.cloneDeep(field);
          CustomFieldService.addValidationKey(customFields.filter(f => f.isMultipleAllowed && f.nestedFields && f.nestedFields.length > 0).flatMap(field => field.nestedFields), nestedField);
          field.nestedFields.push(nestedField);
        }
      } else {
        if (valueObjects && valueObjects.length != 0) {
          CustomFieldService.mapGetValueField(field, index, valueObjects[0], titleFieldId, removeIndexes);
        }
      }
    });

    if (removeEmptyValueField) {
      this.removeByIndex(removeIndexes, customFields);
      customFields = customFields.filter(f => customFieldsValues.map(v => v.sectionCustomFieldId).includes(f.sectionCustomFieldId));
    }
    return customFields;
  }

  static mapGetValueField(field: CustomFieldModel | any, index: number, valueObject: CustomFieldValueModel, titleFieldId: number = 0, removeIndexes: number[]) {
    if (valueObject.sectionCustomFieldId == field.sectionCustomFieldId) {
      field.isRealTime = valueObject.isRealTime
      if (field.id == titleFieldId) {
        field.isTitleField = true;
      }
      switch (field.type) {
        case FeildType.MultiSelection: {
          field.lookupValues.map(x => x.isSelected = false);
          if (valueObject.value) {
            valueObject.value.split(',').forEach(val => {
              field.lookupValues.forEach(lookupVal => {
                if (lookupVal.id == parseInt(val)) {
                  lookupVal.isSelected = true;
                }
              });
            });
          } else {
            removeIndexes.push(index)
          }
          break;
        }
        case FeildType.Money: {
          if (valueObject.value?.indexOf(':') > -1) {
            let moneyValues = valueObject.value.split(':');
            field.value = moneyValues[0];
            field.selectedCurrencyId = parseInt(moneyValues[1]);
            field.selectedCurrencySymbol = moneyValues[4];

          } else {
            removeIndexes.push(index);
          }
          break;
        }
        case FeildType.LookupAddon: {
          field.details = valueObject.details;
          field.selectedLookupText = valueObject.selectedLookupText;
          field.value = valueObject.value;
          if (!field.value) {
            removeIndexes.push(index);
          }
          break;
        }
        case FeildType.Attachment:
        case FeildType.Image: {
            field.details = valueObject.details;
            field.value = valueObject.value;
            if (!field.details) {
              removeIndexes.push(index);
            }
          break;
        }
        case FeildType.Gallery: {
        
          field.details.gallery = valueObject.details?.gallery;
          let value= valueObject.value.replace(/[\[\]"]+/g,"");
          let values = value.split(',');
          values.forEach(v => {
            field.value = v;
          });
          // console.log('Value', value.split(','));
    
        if (!field.details) {
          removeIndexes.push(index);
        }
          break;
        }
        case FeildType.Mapped:
        case FeildType.Calculated: {
          field.targetFieldType = valueObject.type;
          if (valueObject.type == FeildType.Attachment) {
            field.details = valueObject.details;
            if (!field.details) {
              removeIndexes.push(index);
            }
          } else if (field.targetFieldType == FeildType.MultiSelection) {
            if (valueObject.value) {
              const values = valueObject.value.split(',');
              values.forEach(value => {
                let lookup: CustomFieldLookupModel = new CustomFieldLookupModel();
                lookup.isSelected = true;
                lookup.label = value;
                field.lookupValues.push(lookup);
              })
            } else {
              removeIndexes.push(index);
            }
          }
        }
        default: {
          field.value = valueObject.value;
          if (!field.value) {
            removeIndexes.push(index);
          }
          break;
        }
      }
    }
  }

  static removeByIndex(indexes: number[], customFields: CustomFieldModel[]) {
    for (var i = indexes.length - 1; i >= 0; i--)
      customFields.splice(indexes[i], 1);
  }

  static mapGetActionValueModel(customFields: CustomFieldModel[], values: ActionValue[]): CustomFieldModel[] {
    if (values != null && values.length > 0)
      values.forEach(function (valueObject) {
        customFields.forEach(function (field) {
          if (+valueObject.actionKey == field.sectionCustomFieldId) {
            switch (field.type) {
              case FeildType.MultiSelection: {
                if (!valueObject.value.startsWith('{')) {
                  // console.log('CF service Manual', field.formGroup.value.isDynamic);
                  
                  field.lookupValues.forEach((item, index) => {
                    field.lookupValues[index].isSelected = false;
                  });
                  valueObject.value.split(',').forEach(val => {
                    field.lookupValues.forEach(lookupVal => {
                      if (lookupVal.id == parseInt(val)) {
                        lookupVal.isSelected = true;
                      }
                    });
                  });
                } else {
                  // console.log('CF service Dynamic', valueObject.value);
                  field.value = valueObject.value;
                }
                break;
              }
              case FeildType.Money: {
                if (valueObject.value.indexOf(':') > -1) {
                  let moneyValues = valueObject.value.split(':');
                  field.value = moneyValues[0];
                  field.selectedCurrencyId = parseInt(moneyValues[1]);
                  field.selectedCurrencySymbol = moneyValues[4];

                }
                break;
              }
              case FeildType.LookupAddon: {
                //field.selectedLookupText = valueObject.selectedLookupText;
                field.value = valueObject.value;
                break;
              }
              case FeildType.Attachment: {
                //field.details = valueObject.details;
                field.value = valueObject.value;
                break;
              }
              default: {
                field.value = valueObject.value;
                break;
              }
            }
          }
          field.actionValue = field.value;
        });
      });

    return customFields;
  }
  public static mapCurrencyWithThreeValues(field: CustomFieldModel) {
    if (field.value && field.value.indexOf(':') > -1) {
      let values: string[] = [];
      values = field.value.split(':');
      if (values[0]) {
        field.value = values[0]
      }
      if (values[1]) {
        field.selectedCurrencyId = +values[1];
      }
      if (values[2]) {
        field.selectedCurrencySymbol = values[2];
      }
    }
  }

  public fieldHasTag(value: string): boolean {
    if (value && value.toString().indexOf('#') > -1 && value.toString().indexOf('{') == 0 && value.toString().lastIndexOf('}') == value.length - 1) {
      return true;
    } else {
      return false;
    }
  }

  public static removeHyphensAllowNumbers(event, value: string) {
    var k;
    k = event.keyCode;
    if (event.keyCode == 46 && value && value.toString().indexOf('.') >= 0) {//46 is for . allow only one .
      return false;
    }
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 46);
  }

  setSelectedCurrenciesString(customField: CustomFieldModel, currenciesList: CurrencyModel[] = []) {
    let selectedCurrenciesString = "";
    let allowedValuesCriteriaArray: string[] = customField.allowedValuesCriteria.split(",");
    allowedValuesCriteriaArray.forEach(function (num) {
      currenciesList.forEach(function (currency) {
        if (currency.id == parseInt(num) && customField.value != num)
          selectedCurrenciesString += currency.code + ", ";
      });
    });

    customField.selectedCurrenciesString = selectedCurrenciesString.slice(0, -2);
  }

  setDefaultCurrencyString(customField: CustomFieldModel, currenciesList: CurrencyModel[] = []) {
    let defaultCurrencyString = "";
    let allowedValuesCriteriaArray: string[] = customField.allowedValuesCriteria.split(",");
    currenciesList.forEach(function (currency) {
      if (String(currency.id) == customField.value) {
        defaultCurrencyString = currency.code;

        if (customField.allowedValuesCriteria.split(",").length > 1) {
          defaultCurrencyString += ", ";
        }
      }
    });

    customField.defaultCurrencyString = defaultCurrencyString;
  }

  getSelectedCurrenciesString(customField: any, currenciesList: CurrencyModel[]) {
    let selectedCurrenciesString = "";
    let allowedValuesCriteriaArray: string[] = customField.allowedValuesCriteria.split(",");
    allowedValuesCriteriaArray.forEach(function (num) {
      currenciesList.forEach(function (currency) {
        if (currency.id == parseInt(num) && customField.value != num)
          selectedCurrenciesString += currency.code + ", ";
      });
    });

    return selectedCurrenciesString.slice(0, -2);
  }

  getDefaultCurrencyString(customField: any, currenciesList: CurrencyModel[]) {
    let defaultCurrencyString = "";
    let allowedValuesCriteriaArray: string[] = customField.allowedValuesCriteria.split(",");
    currenciesList.forEach(function (currency) {
      if (String(currency.id) == customField.value) {
        defaultCurrencyString = currency.code;

        if (customField.allowedValuesCriteria.split(",").length > 1) {
          defaultCurrencyString += ", ";
        }
      }
    });

    return defaultCurrencyString;
  }

  static addNestedField(fieldsList: CustomFieldModel[], field: CustomFieldModel, allNestedFields: CustomFieldModel[]) {
    let newField = _.cloneDeep(field);//Object.assign({}, section);
    newField.nestedFields = [];
    CustomFieldService.addValidationKey(allNestedFields.filter(f => f.isMultipleAllowed && f.nestedFields && f.nestedFields.length > 0).flatMap(field => field.nestedFields), newField);
    fieldsList.push(newField);
  }

  // removeField(fieldsList: CustomFieldModel[], field: CustomFieldModel) {
  //   _.pullAt(fieldsList, _.findIndex(fieldsList, field));
  // }

  static removeFieldByIndex(fieldsList: CustomFieldModel[], index: number) {
    _.pullAt(fieldsList, index);
  }

  static generateString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static addValidationKey(fields: CustomFieldModel[], newField: CustomFieldModel) {
    let key: string = newField.sectionCustomFieldId + 'x' + CustomFieldService.generateString(5);
    if (!CustomFieldService.CustomFieldService(fields, key)) {
      newField.validationKey = key;
    } else {
      this.addValidationKey(fields, newField);
    }
  }

  static CustomFieldService(fields: CustomFieldModel[], key: string): boolean {
    return fields.some(f => f.validationKey === key);
  }
  private instanceCounts: Map<string, number> = new Map<string, number>();
  incrementInstanceCount(parentId: string) {
    if (!this.instanceCounts.has(parentId)) {
      this.instanceCounts.set(parentId, 0);
    }
    const currentCount = this.instanceCounts.get(parentId)!;
    const newCount = currentCount + 1;
    this.instanceCounts.set(parentId, newCount);
    // console.log(`Component instance count for parent`, newCount);
    return newCount
  }
  resetInstanceCount(parentId: string) {
    this.instanceCounts.delete(parentId);
  }
  sectionHasValue(sectionValues): boolean {
    if(sectionValues?.length > 0) {
      const hasNonEmptyValue = sectionValues?.some(section =>
        section.Instances?.some(instance =>
          instance.values?.some(value =>
            value.value !== null && value.value !== undefined && value.value !== ''
          )
        )
      );
      return hasNonEmptyValue
    } else {
      return false
    }
  }
}
