<div class="main-account-container">
  <div>
    <app-header-before-login></app-header-before-login>
    <div class="main-account-content">
      <div fxFlex fxLayout="row" fxLayoutAlign="center stretch">
        <div fxLayout="column" fxLayoutAlign="center" class="login-bg">
          <div class="login-dialog" ngClass.lt-sm="width100">
            <div class="dialog-top-bar"></div>
            <div #loginDialogDiv class="dialog-content-container bgc-white login-border-bottom login-padding">
              <div mat-dialog-title>
                <div fxLayout="row">
                  <div fxFlex>
                    <div class="title" translate="Submit-Login.Almost-Done">
                    </div>
                    <div class="title-description" translate="Submit-Login.Log-Account">
                    </div>
                  </div>
                </div>
              </div>
              <form #loginForm="ngForm" (ngSubmit)="OnSubmit(Email.value,Password.value)">
                <div mat-dialog-content>
                  <div fxLayout="column">
                    <mat-form-field fxFlex>
                      <input matInput #Email [formControl]="email" placeholder="{{'EMAIL-ID' | translate}}" name="email" class="login-input" autocomplete="new-email" required>
                      <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                      <input matInput #Password [formControl]="password" type="password" placeholder="{{'PASSWORD' | translate}}" name="password" autocomplete="new-password" required>
                      <mat-error *ngIf="password.invalid">{{getPasswordErrorMessage()}}</mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngIf="isLoginError" class="login-error" [class.margin-t18]="password.invalid">
                    {{'LOGIN-ERROR-LOGIN' | translate}}
                  </div>
                </div>
                <div mat-dialog-actions class="action-btn-margin">
                  
                  <div fxFlex fxLayoutAlign="end end">
                    <button mat-button class="add-button-gradiant" [disabled]="email.invalid || password.invalid || isCallBack">
                      <span translate="Common.CONTINUE"></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
