<div id="incomplete-profile">
  <div class="dialog-top-bar"></div>
  <div class="dialog-content-container">
    <div [dir]="(_dataSharingService.isRtl)?'rtl':'ltr'">
      <div mat-dialog-content>
        <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="empty-card">
          <div class="icon"><img src="../../../assets/images/{{themeService.imgSrc}}ic_dialog_warning.png"></div>
          <div class="heading">{{'IncompleteProfile.HEADING-TITLE' | translate}}</div>
          <div class="description">
            {{'IncompleteProfile.HEADING-PRE-DESCRIPTION' | translate}} {{labelService.labels?.application | lowercase}}
            <span *ngIf="editable">{{'IncompleteProfile.HEADING-POST-DESCRIPTION-EDITABLE' | translate}}</span>
            <span *ngIf="!editable">{{'IncompleteProfile.HEADING-POST-DESCRIPTION-NOTEDITABLE' | translate}}</span>
          </div>
          <div class="button-container" fxLayoutGap="15px" *ngIf="editable">
            <button *ngIf="callFrom != componentName.ApplicantRequestAuthenticationComponent" mat-button mat-dialog-close class="button-gray-cancel"
              tabindex="-1">{{'Common.LATER' | translate}}</button>
            <button *ngIf="callFrom == componentName.ApplicantRequestAuthenticationComponent" (click)="logOut();" mat-button mat-dialog-close class="button-gray-cancel"
              tabindex="-1">{{'Common.LATER' | translate}}</button>
            <button mat-button class="add-button-gradiant" tabindex="-1"
              (click)="gotoProfile()">{{'IncompleteProfile.PROFILE-BUTTON' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>