import { LookUpOptions } from '../profile-customization/Model/CustomFieldModel';

export class CustomFieldValueModel {
  constructor() {
    this.details = new ValueDetails();
    this.sectionIndex = 0;
    this.index = 0;
  }
  public id?: number;
  public sectionCustomFieldId?: number;
  public value?: string | any;
  public values?: string[];
  public sectionId?: number;
  public sectionIndex?: number;
  public selectedLookupText?: string;
  public isSync?: boolean;
  public details?: ValueDetails;
  public type?: number;
  public isRealTime?: boolean;
  public targetFieldType?: number; //used for mapped and calculated fields
  public lookupItems?: LookUpOptions[] = [];
  public index?: number;
  public assessmentId?: number;
  public isMultiLangual?: boolean;
}

export class ValueDetails {
  public createdOn: Date = new Date();
  public downloadUrl: string = "";
  public mimeType: string = "";
  public name: string = "";
  public fileId: string = "";
  public gallery: ValueDetails[] = []
  //for upload
  public type: string;
  public size: string;
}
export class ErrorDetails {
  public file: any;
  public error: string;
}

export interface FormSectionValue {
  label: string;
  value: string;
}
