import { Component, OnInit, OnDestroy, Input } from '@angular/core';
//import {AuthService, AuthType} from '../../../shared/services/auth.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
//import {SignInProposeService} from 'src/app/shared/modals/signin-propose/signin-propose.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SubSink } from 'subsink';
import { LabelService } from '../common/services/label-service';
import { UserInfoService } from '../common/services/userinfo-service';
import { LookupAdonsModel } from '../models/lookup-ad-ons-model';
import { LookupAdonsService } from '../settings/lookup-ad-ons/lookup-ad-ons.service';
import { DataSharingService } from '../common/services/data-sharing.service';
import { Roles } from '../common/enums/roles-enum';
import { LookupAdonsIsmainFilter } from '../common/pipes/lookup-adons-ismain-pipe';
import { LookupItemsListFilterService } from '../common/services/lookup-items-list-filter.service';
import { SystemSettingsService } from '../common/services/system-settings-service';
import { AccountService } from '../account/services/account.service';
import { OrgAccountsService } from '../shared-modules/account-shared/services/org-accounts.service';
import { SessionStorageService } from '../common/services/session-storage.service';
import { ManageLanguagesService } from '../settings/manage-languages/manage-languages.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SwitchLanguageDialogComponent } from './switch-language-dialog/switch-language-dialog.component';
import { AppilcationCatalogStateService } from '../applicant-request/services/application-catalog-state.service';
import { ReferenceApplicationsFilterStateService } from '../shared-modules/application-shared/services/reference-applications-filter-state.service';

@Component({
  selector: 'xcdrs-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();
  isMobileScreen: boolean;

  public isLoggedIn: boolean = false;
  @Input('loggedIn')
  set loggedIn(value: boolean) {
    this.isLoggedIn = value;
    if (this.isLoggedIn) {
      this.loadLookupsAdon();
      this.setUserOrgAccountsCount();
    }
  }
  public lookupAdonsList: LookupAdonsModel[] = [];
  public filterForLookupAdonsList: string;
  public accountsCount = 0;
  constructor(
    private _matDialog: MatDialog,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    public labelService: LabelService,
    public userInfoService: UserInfoService,
    private _lookupAdonsService: LookupAdonsService,
    public dataSharingService: DataSharingService,
    private _lookupAdonsIsmainFilter: LookupAdonsIsmainFilter,
    public lookupItemsListFilterService: LookupItemsListFilterService,
    private _systemSettingsService: SystemSettingsService,
    private _accountService: AccountService,
    private _orgAccountsService: OrgAccountsService,
    private _sessionService: SessionStorageService,
    private _manageLanguagesService: ManageLanguagesService,
    private _appilcationCatalogStateService: AppilcationCatalogStateService,
    private referenceApplicationsFilterStateService: ReferenceApplicationsFilterStateService
  ) {
    this.subs.add(
      this.breakpointObserver.observe(['(max-width: 992px)']).
        subscribe(state => {
          this.isMobileScreen = state.matches;
        })
    );

    this.dataSharingService.userImageUrl.subscribe(value => {
      if (value) {
        this.userInfoService.imageUrl = value;
      }
    });

    this.subs.sink = this.dataSharingService.lookupAdons.subscribe(value => {
      if (value && value.length) {
        this.lookupAdonsList = value;
        this.filterLookupAddons(this.lookupAdonsList);
      } else {
        this.loadLookupsAdon();
      }
    })
  }

  ngOnInit(): void {
    this.labelService.getLabel();
    this.clearFilters();
    if (this.userInfoService.email) {
      this.subs.sink = this._manageLanguagesService.checkForEnabledLanguages().subscribe();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  logoutUser() {
    this._orgAccountsService.unsetUserPersonas();
    this.labelService.labels = JSON.parse(JSON.stringify(this.labelService.preLoginLabels));
    this._orgAccountsService.logout().subscribe(resp => {

    });
    // if (this._systemSettingsService.systemSettings.windowsAuth && this._systemSettingsService.systemSettings.windowsAuth.toLocaleLowerCase() == 'true') {
    //   this._accountService.logout().subscribe(resp => {

    //     this.router.navigateByUrl('/logout');
    //   });
    // } else {
      this.router.navigateByUrl('/login');
    // }
  }

  private setLookupsFilter() {
    if (this.userInfoService.role == Roles.Admin) {
      this.filterForLookupAdonsList = 'sidenavbar'
    } else if (this.userInfoService.role == Roles.Applicant || this.userInfoService.applicantPersonaId > 0) {
      this.filterForLookupAdonsList = 'applicanttabs';
    }
  }

  loadLookupsAdon() {
    this._lookupAdonsService.getLookupAdonsListInfo().subscribe(resp => {
      this.filterLookupAddons(resp)
    }, error => { });
  }
  filterLookupAddons(lookupAddons) {
    this.setLookupsFilter();
    if (this.userInfoService.role == Roles.User) {
      this.lookupAdonsList = []
    } else {
      this.lookupAdonsList = this._lookupAdonsIsmainFilter.transform(lookupAddons, this.filterForLookupAdonsList, this.userInfoService.profileTemplateIds);

    }


  }

  openSwitchOrgDialog() {
    const dialogRef = this._orgAccountsService.openSwitchOrgDialog();
  }
  openSwitchLanguageDialog() {
    this._matDialog.open(SwitchLanguageDialogComponent, {
      autoFocus: false,
      closeOnNavigation: true,
      width: '500px'
    })
  }

  setUserOrgAccountsCount() {
    this.subs.sink = this._orgAccountsService.userPersonas().subscribe(accounts => {
      this.accountsCount = accounts.length;
    })
  }

  clearFilters() {
    this._sessionService.clearAllFilters();
    this._appilcationCatalogStateService.reset();
    this.referenceApplicationsFilterStateService.clearFilters(); 
    this.referenceApplicationsFilterStateService.clearTabFilters();
    this.showAdons = false;
  }
  public showAdons: boolean = false;
  showLookupAdons(event) {
    event.stopPropagation();
    this.showAdons = true;
  }
}
