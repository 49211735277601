import { Injectable } from '@angular/core';
import { Roles } from '../enums/roles-enum';
import { LabelService } from './label-service';
import { DataSharingService } from './data-sharing.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Persona } from '../../models/persona-model';
import * as _ from 'lodash';
import { NotificationModel } from '../../models/notification-model';
import { NotificationService } from './notification-service';
import { TranslationHelperService } from './translation-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private userInfo: any = null;
  fullName: string;
  email: string;
  role: string;
  imageUrl: string;
  personas: Persona[];
  orgPersonas: Persona[];
  organizationId: number;
  organizationName: string;
  organizationImageUrl: string;
  profileTemplateIds: number[] = [];
  applicantPersonaId: number;
  isApplicant: boolean;
  idenediKey: string; 
  public static readonly applicantDefaultPage = '/applications';
  public static readonly applicantStemexDefaultPage = '/request/my';
  public static readonly adminDefualtPage = '/applications'
  constructor(
    private labelService?: LabelService,
    private _dataSharingService?: DataSharingService,
    private translate?: TranslateService,
    private router?: Router,
    private notificationService?: NotificationService,
    private translationHelperService?: TranslationHelperService
  ) {
    if (JSON.parse(sessionStorage.getItem('ngStorage-auth'))) {
      if (!this.userInfo) {
        this.userInfo = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
        this.fullName = this.userInfo.user.name;
        this.email = this.userInfo.user.email;
        this.role = this.userInfo.user.role;
        this.imageUrl = this.userInfo.user.imageUrl;
        this.personas = this.userInfo.token.persoans;
        this.mergePersona();
        this.orgPersonas = this.filterUniqueOrganizationPersonas(this.personas);
        this.organizationId = this.userInfo.user.organizationId;
        if (this.orgPersonas.filter(x => x.orgId == this.organizationId)[0] != undefined) {
          this.organizationName = this.orgPersonas.filter(x => x.orgId == this.organizationId)[0].orgName;
        }
        if (this.orgPersonas.find(x => x.orgId == this.organizationId) != undefined) {
          this.organizationImageUrl = this.orgPersonas.find(x => x.orgId == this.organizationId).orgImageUrl;
        }
        this.profileTemplateIds = this.userInfo.user.profileTemplateIds;
      }
    }
  }

  update() {
    if (JSON.parse(sessionStorage.getItem('ngStorage-auth'))) {
      this.userInfo = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
      this.fullName = this.userInfo.user.name;
      this.email = this.userInfo.user.email;
      this.role = this.userInfo.user.role;
      this.imageUrl = this.userInfo.user.imageUrl;
      this.personas = this.userInfo.token.persoans;
      this.mergePersona();
      this.orgPersonas = this.filterUniqueOrganizationPersonas(this.userInfo.token.persoans);
      this.organizationId = this.userInfo.user.organizationId;
      if (this.orgPersonas.filter(x => x.orgId == this.organizationId)[0] != undefined) {
        this.organizationName = this.orgPersonas.filter(x => x.orgId == this.organizationId)[0].orgName;
      }
      this.profileTemplateIds = this.userInfo.user.profileTemplateIds;
      this.applicantPersonaId = this.userInfo.user.applicantPersonaId;
    }
  }

  mergePersona() {
    let uniqueOrgIds: number[] = [];
    this.personas.forEach(persona => {
      if (uniqueOrgIds.indexOf(persona.orgId) < 0) {
        uniqueOrgIds.push(persona.orgId);
      }
    });
    uniqueOrgIds.forEach(orgId => {
      let orgPersonas: Persona[] = [];
      orgPersonas = this.personas.filter(p => p.orgId == orgId);
      if (orgPersonas.length > 0) {
        if (orgPersonas.filter(p => p.type == 'ORG' || p.type == 'APP').length >= 2) {
          _.remove(this.personas, function (persona) {
            return persona.orgId == orgId && persona.type == 'APP';
          });
        }
      }
    });
    // console.log(this.personas);
  }

  updateSessionStorage(imageUrl: string) {
    this.imageUrl = imageUrl;
    this.userInfo = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
    this.userInfo.user.imageUrl = imageUrl
    sessionStorage.setItem('ngStorage-auth', JSON.stringify(this.userInfo));
  }

  updateNameInSessionStorage(name: string) {
    this.fullName = name;
    this.userInfo = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
    this.userInfo.user.name = name;
    sessionStorage.setItem('ngStorage-auth', JSON.stringify(this.userInfo));
  }

  updateNameImageInSessionStorage(name: string, imageUrl: string) {
    this.fullName = name;
    this.imageUrl = imageUrl;
    this.userInfo = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
    this.userInfo.user.name = name;
    this.userInfo.user.imageUrl = imageUrl
    sessionStorage.setItem('ngStorage-auth', JSON.stringify(this.userInfo));
  }

  updateProfileTemplateIdsInSessionStorage(ids: number[]) {
    this.profileTemplateIds = ids;
    this.userInfo = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
    this.userInfo.user.profileTemplateIds = ids;
    sessionStorage.setItem('ngStorage-auth', JSON.stringify(this.userInfo));
  }

  removePersonaFromSessionStorage(personaType: string) {
    this.userInfo = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
    for (var i = 0; i < this.userInfo.token.persoans.length; i++) {
      if (this.userInfo.token.persoans[i].type == personaType) {
        this.userInfo.token.persoans.splice(i, 1);
      }
    }
    sessionStorage.setItem('ngStorage-auth', JSON.stringify(this.userInfo));
    return this.userInfo.token.persoans;
  }

  addPersonaSessionStorage(personaType: string, personaId: number) {
    this.userInfo = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
    this.userInfo.token.persoans.push({ id: personaId, type: personaType });
    sessionStorage.setItem('ngStorage-auth', JSON.stringify(this.userInfo));
    return this.userInfo.token.persoans;
  }

  updatePersonaImageUrl(organizationId: number, imageUrl: string) {
    this.userInfo = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
    this.userInfo.token.persoans.filter(x => x.orgId == organizationId)[0].orgImageUrl = imageUrl;
    sessionStorage.setItem('ngStorage-auth', JSON.stringify(this.userInfo));
    this.update();
  }

  setAuthorizationData(data, redirectUrl: string = UserInfoService.applicantDefaultPage) {

    var persoans = JSON.parse(data.personas);

    var result = {
      token: {
        accessToken: data.access_token,
        expiry: new Date(data['.expires']),
        refreshToken: data.refresh_token,
        persoans: persoans
      },
      user: {
        name: data.name,
        email: data.email,
        role: data.role,
        imageUrl: data.imageUrl,
        organizationId: data.organizationId,
        profileTemplateIds: data.profileTemplateIds ? data.profileTemplateIds.split(',').map(Number) : [],
        applicantPersonaId: data.applicantPersonaId ? +data.applicantPersonaId : 0
      }
    };

    sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));
    this.labelService.updateClientLabel();
    this.update();

    if (result.user.role != Roles.Applicant) {
      this.translate.use('en');
    }

    let rdUrl = UserInfoService.adminDefualtPage;
    if (redirectUrl != UserInfoService.applicantDefaultPage) {
      rdUrl = redirectUrl;
    }

    if (result.user.role == Roles.Admin) {
      this._dataSharingService.lookupAdons.next(null);
      this.router.navigateByUrl(rdUrl);
      if (rdUrl == this.router.url) {
        this._dataSharingService.accountSwitched.next(true);
      }
    } else if (result.user.role == Roles.User) {
      this._dataSharingService.lookupAdons.next(null);
      this.router.navigateByUrl(rdUrl);
      if (rdUrl == this.router.url) {
        this._dataSharingService.accountSwitched.next(true);
      }
    } else if (result.user.role == Roles.Applicant) {
      this._dataSharingService.lookupAdons.next(null);
      if (!this._dataSharingService.isStemexUser) {
        if (redirectUrl != UserInfoService.applicantDefaultPage) {
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.notificationService.getNotification().subscribe((resp: NotificationModel) => {
            if (resp.actionCenterCount > 0) {
              this.router.navigateByUrl(UserInfoService.applicantDefaultPage)
            } else {
              this.router.navigateByUrl(UserInfoService.adminDefualtPage);
            }
          }, err => {
            this.router.navigateByUrl(UserInfoService.adminDefualtPage);
          });
        }
      }
      else
        this.router.navigateByUrl((redirectUrl != UserInfoService.applicantDefaultPage) ? redirectUrl : UserInfoService.applicantStemexDefaultPage);

      if (redirectUrl == this.router.url) {
        this._dataSharingService.accountSwitched.next(true);
      }
    }
    this._dataSharingService.systemLanguages.subscribe(systemLanguages => {
      systemLanguages.forEach(systemLanguage => {
        systemLanguage.isActive = false;
      });
      this.translationHelperService.getOrganizationLocales().subscribe(resp => {
        let activeLocales = resp.body.split(',');
        if (activeLocales.length > 0) {
          systemLanguages.forEach(systemLanguage => {
            if (!systemLanguage.isDefault)
              systemLanguage.isActive = false;
          });
          systemLanguages.forEach(systemLanguage => {
            activeLocales.forEach(activeLocale => {
              if (systemLanguage.locale == activeLocale) {
                systemLanguage.isActive = true;
              }
            })
          });
        }
      });

    });
  }

  setWindowsAuthorizationDataInStorage(data) {
    var personas = data.token.personas;
    let expirayDate: Date = new Date();
    expirayDate.setDate(expirayDate.getDate() + 365);
    var result = {
      token: {
        accessToken: data.token.accessToken,
        expiry: expirayDate,
        refreshToken: "",
        persoans: data.token.personas
      },
      user: {
        name: data.user.name,
        email: data.user.email,
        role: data.user.role,
        imageUrl: data.user.imageUrl,
        organizationId: data.user.organizationId,
        profileTemplateIds: data.user.profileTemplateIds ? data.user.profileTemplateIds.split(',').map(Number) : []
      }
    };

    sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));
    this.setWindowsAuthorizationData();
  }
  setWindowsAuthorizationData() {
    this.labelService.updateClientLabel();
    this.update();

    if (this.role != Roles.Applicant) {
      this.translate.use('en');
    }
    if (this.role == Roles.Admin) {
      this._dataSharingService.lookupAdons.next(null);
      this.router.navigateByUrl(UserInfoService.adminDefualtPage);
    } else if (this.role == Roles.User) {
      this._dataSharingService.lookupAdons.next(null);
      this.router.navigateByUrl(UserInfoService.adminDefualtPage);
    } else if (this.role == Roles.Applicant) {
      this._dataSharingService.lookupAdons.next(null);
      if (!this._dataSharingService.isStemex)
        this.router.navigateByUrl(UserInfoService.applicantDefaultPage);
      else
        this.router.navigateByUrl(UserInfoService.applicantStemexDefaultPage);
    }
  }

  filterUniqueOrganizationPersonas(personas: Persona[]): Persona[] {
    let list: Persona[] = [];
    personas.forEach(ele => {
      let item: Persona = new Persona();
      item.orgImageUrl = ele.orgImageUrl;
      item.orgName = ele.orgName;
      item.orgId = ele.orgId;
      item.id = ele.id
      var obj = list.filter(item => item.orgId == ele.orgId);
      if (obj && obj.length == 0) {
        list.push(item);
      }
      else if (ele.type == 'ORG') {
        obj[0].id = ele.id;
      }
    });
    return list;
  }
}


