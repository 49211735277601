import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpCutomInterceptor } from './auth/auth.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SidenavbarComponent } from './sidenavbar/sidenavbar.component';
import { AppHeaderModule } from './app-header/app-header.module';
import { AppRoutingModule } from './app-routing.module';

import { DatePipe } from '@angular/common';
import { AccountModule } from './account/account.module';
import { CanActivateGuard } from './common/guard/can-activate-guard.service';

import { SwitchUserPipe } from './common/pipes/switch-user-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SystemSettingsService } from './common/services/system-settings-service';

import { SharedModule } from './shared/shared.module';
import { CustomizationModule } from './customization/customization.module';
import { InterceptorModule } from './common/interceptor';
import { AutofocusDirective } from './common/directives/autofocus.directive';
import { environment } from '../environments/environment';
import { SortablejsModule } from 'ngx-sortablejs';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { RequestCacheInterceptor } from './common/interceptors/request-cache.interceptor';
import { RequestCacheService } from './common/services/request-cache.service';
import { GuardsCheckEnd, NavigationEnd } from '@angular/router';
import { WinAuthCanActivateGuard } from './common/guard/win-auth-can-activate-guard.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + environment.version);
}

export function loadAppConfig(http: HttpClient) {
  return (): Promise<void> => {
    return http.get('assets/config.json').toPromise().then((config: any) => {
      if (config) {
        Object.keys(config).forEach(key => {
          environment[key] = config[key];
        })
      }
    }).catch(err => {})
  }
}
@NgModule({
  declarations: [
    AppComponent,
    SidenavbarComponent,
    SwitchUserPipe,
    AutofocusDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CustomMaterialModule,
    FlexLayoutModule,
    AppHeaderModule,
    AccountModule,
    AppRoutingModule,
    CustomizationModule,
    NgProgressModule,
    // NgProgressRouterModule.withConfig({
    //   startEvents: [GuardsCheckEnd],
    //   completeEvents: [NavigationEnd],
    //   delay: 1000
    // }),
    NgProgressHttpModule.withConfig({
      silentApis: []
    }),

    NgxPaginationModule,
    SharedModule,
    SortablejsModule.forRoot({
      animation: 150
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InterceptorModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: loadAppConfig, deps: [HttpClient], multi: true },
    RequestCacheService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestCacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCutomInterceptor,
      multi: true
    },
    DatePipe,
    CanActivateGuard,
    WinAuthCanActivateGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: systemSettingsProviderFactory,
      deps: [SystemSettingsService],
      multi: true
    },
    AsyncPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeAr, 'ar');
  }
}

export function systemSettingsProviderFactory(provider: SystemSettingsService) {
  return () => provider.load();
}
