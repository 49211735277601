import { Injectable } from "@angular/core";
import { NameValue } from "../../models/name-value.model";
import { TranslateToLocalePipe } from "../../common/pipes/translate-to-locale.pipe";
import { FormModel } from "../../definitions/models/application-definition";
import { FormSectionModel } from "../../models/CustomizationSettingsSubmitModel";
import { FeildType, FieldTypes } from "../../common/enums/field-type-enum";
import { ActionFieldModel } from '../../models/action-field-model';
import { FormControl } from "@angular/forms";
import { DefinitionStageModel } from "../../definitions/models/definition-stage";
import { FormBuilderConfigurationService } from "./form-builder-configuration.service";

@Injectable({
  providedIn: 'root'
})
export class FormSharingService {
  public form: FormModel;
  public otherForms: FormModel[];
  public stageForms: any;
  public ParentDefinitionForm: FormModel;
  public stages: DefinitionStageModel[];
  public parentDefinitionId : number;
  public definitionId : number;
  private _name: string = '';
  set name(val) {
    this._name = this._translateToLocale.transform(val);
  }
  get name(): string {
    return this._name;
  }
  options: NameValue[];
  tagableOptions: ActionFieldModel[] = [];
  constructor(private _translateToLocale: TranslateToLocalePipe, private _formBuilderconfigService: FormBuilderConfigurationService) {

  }

  public setAggregateOptions() {
    this.options = [];
    this.tagableOptions = [];
    if (this.form && this.form.sections) {
      this.assignFields(this.form.sections, this.otherForms && this.otherForms.length > 0);
      this.tagableActionFields(this.form.sections, 0 , this.stageForms && this.stageForms.length > 0);
    }
    if (this.otherForms && this.otherForms.length > 0) {
      this.otherForms.forEach(otherForm => {
        this.assignFields(otherForm.sections);
      });
    }
    if (this.stageForms && this.stageForms.length > 0) {
      this.stageForms.forEach(criteria => {
        this.tagableActionFields(criteria.form.sections , criteria.criteriaId);
      });
    }

    let option = new ActionFieldModel();
    option.name = 'Current Date';
    option.type = FeildType.Date;
    option.fieldId = -1;
    option.key = '-1';
    option.isMultipleAllowed = false;
    this.tagableOptions.push(option);
  }
  private assignFields(sections: FormSectionModel[], concateName = false) {
    sections.forEach(sec => {
      let fields = sec.fields.filter(f => f.sectionCustomFieldId > 0 && (f.type == FeildType.Number || f.type == FeildType.Money || f.type == FeildType.Calculated) && (sec.isMultipule || f.isMultipleAllowed));
      fields = fields.filter(f => {
        if (f.type == FeildType.Money) {
          const allowedValueFc = f.formGroup.get('allowedValuesCriteriaArray') as FormControl;
          if (allowedValueFc.value && allowedValueFc.value.length == 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      fields.forEach(field => {
        let option = new NameValue();
        option.name = this._translateToLocale.transform(field.label);
        if (concateName) {
          option.description = `${this.name} > ${this._translateToLocale.transform(sec.defaultName)}`;
        } else {
          option.description = this._translateToLocale.transform(sec.defaultName);
        }
        option.value = field.sectionCustomFieldId;
        this.options.push(option);
      });
    });
  }
  private tagableActionFields(sections: FormSectionModel[], criteriaId = 0,  concateName = false) {
    // console.log('form section', sections);
    sections.forEach(sec => {
      const fields = sec.fields.filter(f => f.sectionCustomFieldId > 0 && (f.type == FeildType.DateTime || f.type == FeildType.Date || f.type == FeildType.Number || (f.type == FeildType.Calculated && f.allowedValuesCriteria && JSON.parse(f.allowedValuesCriteria)?.type == FeildType.Number) || f.type == FeildType.RollUp));
      
      fields.forEach(field => {
        let option = new ActionFieldModel();
        option.name = this._translateToLocale.transform(field.label);
        if (concateName) {
          option.description = `${this.name} > ${this._translateToLocale.transform(sec.defaultName)}`;
        } else {
          option.description = this._translateToLocale.transform(sec.defaultName);
        }
        option.type = field.type;
        option.fieldId = field.sectionCustomFieldId
        // option.key = field.sectionCustomFieldId.toString();
        if(criteriaId > 0 ) {
          option.key = `CT/${criteriaId}/${field.sectionCustomFieldId.toString()}`;
        } else {
          option.key = `DT/${field.sectionCustomFieldId.toString()}`;
        }
        option.isMultipleAllowed = field.isMultipleAllowed;
        this.tagableOptions.push(option);
      });

    });


  }

  checkFieldUsageInRollup(scfId: number): boolean{
    const rollupFields = this.form.sections.flatMap(sec => sec.fields).filter(f => f.type == FeildType.Aggregator);
    const isReffered = rollupFields.find(f => {
      const aggregatorFieldFc = f.formGroup.get('aggregatorField') as FormControl;
      if(aggregatorFieldFc.value == scfId){
        return true;
      }else{
        return false;
      }
    });
    return isReffered ? true : false;
  }

  getCurrentStageAndPreviousStages(includeCurrentStage = true): DefinitionStageModel[]{
    const currentStage = this.stages.find(s => s.id == this._formBuilderconfigService.selectedStageId);
    if(currentStage){
      let filteredStages = this.stages.filter(stage => stage.order <= currentStage.order);
      if(!includeCurrentStage){
        filteredStages = filteredStages.filter(fs => fs.id != this._formBuilderconfigService.selectedStageId);
      }
      return filteredStages; 
    }else{
      return [];
    }
  }
}
