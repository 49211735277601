import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class DashboardService {

  constructor(private http: HttpClient) { }

  getApplicationsStatuses(): Observable<HttpResponse<any[]>> {
    return this.http.get<string[]>('/webapi/organization/dashboard', { observe: 'response' });
  }

}
