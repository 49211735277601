import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { ApplicationDefinition } from '../../definitions/models/application-definition';
import { ApplicantModel } from '../../models/ApplicantModel';
import { FormControl, Validators } from '@angular/forms';
import { ApplicantService } from '../../applicant/applicant.service';
import { MessageService } from '../../common/services/message-service';
import { Observable, interval } from 'rxjs';
import { AccountService } from '../services/account.service';
import { ApplicantRequestService } from '../../applicant-request/services/applicant-request.service';

@Component({
  selector: 'app-submit-request-success',
  templateUrl: './submit-request-success.component.html',
  styleUrls: ['./submit-request-success.component.less']
})
export class SubmitRequestSuccessComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  private personaId: number;
  constructor(private router: Router, private dataSharingService: DataSharingService
    , private applicantService: ApplicantService
    , private _msgService: MessageService
    , private accountService: AccountService
    , private _getApplicant: ApplicantRequestService) { }

  ngOnInit() {
    this.dataSharingService.applicationDefinition.subscribe(resp => {
      if (JSON.stringify(resp) === JSON.stringify(new ApplicationDefinition)) {
        this.router.navigateByUrl('login');
      } else {
        this.dataSharingService.applicationDefinition.unsubscribe();
      }
    });
  }
  private emailToBeChecked: string = '';
  private checApplicantStatusInterval: any;
  sendInviteLink() {
    this.emailToBeChecked = this.email.value;
    var applicantModel: ApplicantModel = new ApplicantModel();
    applicantModel.id = this.personaId;
    this.applicantService.inviteAndReinviteApplicant(applicantModel).subscribe(resp => {
      this._msgService.showMessage("Invitation link sent to email");
      this.checApplicantStatusInterval = interval(10000).subscribe(() => {
        let emailRegularExpression: RegExp = /^\w+([-+.']\w+)*@\w+([-. ]\w+)*\.\w+([-. ]\w+)*$/;
        if (emailRegularExpression.test(this.emailToBeChecked) && this.emailToBeChecked == this.email.value) {
          this.accountService.isApplicantExists(this.emailToBeChecked).subscribe((data: any) => {
            this.checApplicantStatusInterval.unsubscribe();
          });
        } else {
          this.checApplicantStatusInterval.unsubscribe();
        }
      });
    });
  }
  close() {
    this.router.navigateByUrl('/login');
  }
}
