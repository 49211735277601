import { Component, OnInit, Inject } from '@angular/core';
import { LabelService } from '../../common/services/label-service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { JobRoleUser } from '../../models/job-role-model';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { ApplicationService } from '../services/application.service';
import { ApplicationAction } from '../../common/enums/application-action-enum';

@Component({
  selector: 'app-cancel-application-dialog',
  templateUrl: './cancel-application-dialog.component.html',
  styleUrls: ['./cancel-application-dialog.component.scss']
})
export class CancelApplicationDialogComponent implements OnInit {

  public organizationUsers: JobRoleUser[] = [];
  public notifyApplicant: boolean = true;
  public showSpinner: boolean = true;
  public comments: string = '';
  public callInProgress: boolean = false;
  public applicationAction: ApplicationAction;
  public applictionActionEnum: typeof ApplicationAction = ApplicationAction;
  constructor(
    public labelService: LabelService,
    public _dataSharingService: DataSharingService,
    public _matDialog: MatDialog,
    private _dialogRef: MatDialogRef<CancelApplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private applicationService: ApplicationService
  ) {
    if (data) {
      this.applicationAction = data.applicationAction;
    }
  }

  ngOnInit() {
    this.applicationService.getApplicationOwners(this.data.applicationId).subscribe(resp => {
      this.showSpinner = false;
      for (var i = 0; i < resp.body.length; i++) {
        let user: JobRoleUser = new JobRoleUser();
        user.id = resp.body[i].id;
        user.fullName = resp.body[i].fullName;
        user.pictureUrl = resp.body[i].pictureUrl;
        user.email = resp.body[i].email;
        this.organizationUsers.push(user);
      }
    }, error => {
      this.showSpinner = false;
    });
  }

  submit() {
    this.callInProgress = true;
    if (this.applicationAction == ApplicationAction.Cancel) {
      this.applicationService.cancelRequest(this.data.applicationId, this.comments, this.organizationUsers.filter(x => x.isSelected).map(x => x.id), this.notifyApplicant).subscribe(resp => {
        this.callInProgress = false;
        this._dialogRef.close({ status: 'OK' });
      },
        error => {
          this.callInProgress = false;
        });
    } else {
      this.applicationService.reactivateRequest(this.data.applicationId).subscribe(resp => {
        this.callInProgress = false;
        this._dialogRef.close({ status: 'OK' });
      },
        error => {
          this.callInProgress = false;
        });
    }
  }

}
