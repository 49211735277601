import { EventEmitter, Injectable, Output, ViewChild } from '@angular/core';
import { CustomFieldModel } from '../../../profile-customization/Model/CustomFieldModel';
import { MatDatepicker } from '@angular/material/datepicker';
import { CustomFieldService } from '../../../common/services/custom-field.service';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

@Injectable({
  providedIn: 'root'
})
export class EspDatePickerService {

  constructor() { }


  removeDate(customField: CustomFieldModel) {
    customField.value = "";
    customField.actionValue = "";
  }

  onCloseDatePicker(customField: CustomFieldModel) {
    if (customField.value) {
      customField.value = this.formatDateTimeValue(customField);
      return true;
      
    }
    return false
  }
  formatDateTimeValue (customField: CustomFieldModel): string {
    let value: string = "";
    if (customField.value) {
      const date = moment(customField.value);
      if (date.isValid()) {
        value = moment(customField.value).format('YYYY-MM-DDTHH:mm:ssZ');//'YYYY-MM-DDT00:00:00Z'
      } else {
        value = customField.value;
      }
      return value
    }
  }
  chosenYearHandler(customField: any, normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    customField.value = moment(`${normalizedYear.year()}-01-01`);
    if (customField.allowedValuesCriteria == "yyyy") {
      datepicker.close();
    }
  }

  chosenMonthHandler(customField: any, normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    customField.value = moment(`${customField.value.year()}-${normalizedMonth.month() + 1}-01`);
    if (customField.allowedValuesCriteria == "MM/yyyy") {
      datepicker.close();
    }

  }

}
