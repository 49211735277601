<div class="main-account-container">
  <div>
    <app-header-before-login></app-header-before-login>
    <div class="main-account-content">
      <div fxFlex fxLayout="row" fxLayoutAlign="center stretch">
        <div fxLayout="column" fxLayoutAlign="center" class="login-bg">
          <div class="login-dialog" ngClass.lt-sm="width100">
            <div class="dialog-top-bar"></div>
            <div class="dialog-content-container bgc-white login-border-bottom login-padding" *ngIf="!isEmailSent">

              <div mat-dialog-title>
                <div fxLayout="row">
                  <div fxFlex>
                    <div class="title">
                      {{"Registeration.HEADING-TITLE" | translate}} {{labelService.labels?.application_short_name | translateToLocale}}!
                    </div>
                    <div class="title-description">
                      {{"Registeration.HEADING-DESCRIPTION" | translate}}
                    </div>
                  </div>
                </div>
              </div>

              <form #loginForm="ngForm" (ngSubmit)="OnSubmit(Fullname.value,Email.value,Organization.value)">
                <div mat-dialog-content>
                  <div fxLayout="column">
                    <mat-form-field fxFlex>
                      <input matInput #Organization [formControl]="organization" placeholder="{{'Common.ORGANIZATION' | translate}}" name="organization" class="login-input" required>
                      <mat-error *ngIf="organization.invalid">{{getOrganizationErrorMessage()}}</mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex>
                      <input matInput #Fullname [formControl]="fullName" placeholder="{{'Common.FULL-NAME' | translate}}" name="fullName" class="login-input" required>
                      <mat-error *ngIf="fullName.invalid">{{getFullnameErrorMessage()}}</mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex>
                      <input matInput #Email [formControl]="email" placeholder="{{'Common.EMAIL' | translate}}" name="email" class="login-input" required>
                      <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div mat-dialog-actions class="action-btn-margin">
                  <!--<a mat-anchor class="frgt-pass" (click)="goto('reset')">Forgot Password?</a>-->
                  <div fxFlex fxLayoutAlign="end end">
                    <button mat-button class="add-button-gradiant" [disabled]="fullName.invalid || email.invalid || organization.invalid || isCallBack">{{"Registeration.CREATE-ORGANIZATION" | translate}}</button>
                  </div>
                </div>
              </form>

            </div>

            <div class="login-bottom" *ngIf="!isEmailSent">
              {{"Registeration.ALREADY-ACCOUNT" | translate}} <a routerLink="/login">{{"Registeration.SIGN-IN-NOW" | translate}}</a>
            </div>

            <div *ngIf="isEmailSent">
              <div class="dialog-content-container email-sent-msg-container">
                <div mat-dialog-title>
                  <div fxLayout="row" fxLayoutAlign="center center" style="margin: 31px 0px 38px 0px;">
                    <img src="../../../assets/images/{{themeService.imgSrc}}link-sent.png" />
                  </div>
                  <div fxLayout="row">
                    <div fxFlex>
                      <div class="title text-center">
                        {{"RegisterationVerfication.HEADING-TITLE" | translate}}
                      </div>
                      <div class="title-description text-center">
                        {{"RegisterationVerfication.HEADING-DESCRIPTION" | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
