<!-- {{count}} -->
<div [attr.data-type]="customField.type"
  *ngIf="((customField.type == FType.Money || customField.type == FType.Price || customField.type == FType.SingleSelection) &&  formatedValue) || (customField.type != FType.Money && customField.type != FType.Price && customField.type != FType.SingleSelection && customField.value) || (customField.type == 6 && customField.lookupValues && customField.lookupValues.length > 0) || (customField.type == FType.Note)"
  fxLayout="column" fxLayoutAlign="start start" class="custom-field-readonly" [ngClass]="{'last-child' : isLast, 'isTableView' : isTableView, 'field-text' : (((count % 2) != 0) && !isTableView)}">
  <div class="custom-field-name" *ngIf="showLabel && customField.type != FType.Note && customField.type != FType.Text && customField.type != FType.MultiLine">
    <div class="verifiable-field-label" *ngIf="((customField.type === FType.Email || customField.type === FType.PhoneNumber) && customField.verifiable && customField.value) else customLabel">
      <div>
        {{customField.label | translateToLocale}} <span *ngIf="nestedFieldsCount > 1">#{{nestedFieldIndex+1}}</span>
      </div>
      <mat-icon class="verified-field-ico">check_circle</mat-icon>
    </div>
    <ng-template #customLabel>
      {{customField.label | translateToLocale}} <span *ngIf="nestedFieldsCount > 1">#{{nestedFieldIndex+1}}</span>
    </ng-template>
  </div>

  <div *ngIf="customField.type == FType.Rating">
    <app-esp-rating [customField]="customField" [readonly]="true" [fieldOptions]="{ showLabel: false }">
    </app-esp-rating>
  </div>
  <div *ngIf="customField.type == FType.Note">
    <app-custom-note-field [customField]="customField"></app-custom-note-field>
  </div>
  <div class="map-conatiner" *ngIf="customField.type == FType.Map">
    <app-esp-map [customField]="customField" [isReadOnly]="true" [fieldOptions]="{ showLabel: false }"></app-esp-map>
  </div>

  <div fxFlex="75" class="label-answer" [ngClass]="{'break-word': customField.type == 2}"
    *ngIf="isExcludedType(customField) && isLookupAddonSingle(customField)"
    [innerHTML]="customField.value ? (customField.value | translateToLocale) : '-'">
    <!--{{ customField.value ? (customField.value | translateToLocale) : '-'}}-->
  </div>
  <div fxFlex="75" class="label-answer"
    *ngIf="customField.type == FType.LookupAddon && customField.lookupType != lookupTypeEnum.Definition && !customField.isMultipleAllowed"
    [innerHTML]="customField.value ? (customField.value | translateToLocale) : '-'">
    <!--{{ customField.value ? (customField.value | translateToLocale) : '-'}}-->
  </div>
  <div fxFlex="75"  class="ql-editor form-details" [ngClass]="{'break-word': customField.type == 2}"
    *ngIf="customField.type == FType.RichText"
    [innerHTML]="customField.value ? customField.value : '-'">
  </div>

  <ng-container *ngIf="customField.type == FType.Email">
    <div fxFlex="75" class="label-answer" *ngIf="customField.value else emptyValue">
      <a href="mailto:{{customField.value}}">{{customField.value}}</a>
    </div>
  </ng-container>

  <ng-container *ngIf="customField.type == FType.PhoneNumber">
    <div fxFlex="75" class="label-answer" *ngIf="customField.value else emptyValue">
      <a [dir]="(dataSharingService.isRtl)?'ltr':'ltr'" href="tel:{{customField.value}}">{{customField.value}}</a>
    </div>
  </ng-container>

  <div fxLayout="column" fxFlex="75" class="field-value"
    *ngIf="customField.type == FType.LookupAddon && customField.lookupType == lookupTypeEnum.Definition && !customField.isMultipleAllowed">
    <a *ngIf="customField.value; else emptyValue" fxFlex target="_blank" rel="noopener noreferrer" class="hyper-link"
      href="{{'applications/' + customField.value}}" disabled>{{formatedValue | translateToLocale }}</a>
  </div>

  <div fxFlex="75" class="label-answer" [ngClass]="{'break-word': customField.type == 2}"
    *ngIf="customField.type == FType.Money || customField.type == FType.Price">
    {{formatedValue ? formatedValue : '-'}}
  </div>
  <div fxFlex="75" class="label-answer" [ngClass]="{'break-word': customField.type == 2}"
  *ngIf="customField.type == FType.Date || customField.type == FType.DateTime || customField.type == FType.DateRange">
  {{formatedValue ? formatedValue : '-'}}
</div>
  <div fxFlex="75" class="label-answer" 
    *ngIf="customField.type == FType.SingleSelection">
    
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" [class.mt-10]="showLabel">
      <div class="select-color-box" *ngIf="formatedValue.color && formatedValue.color != 'none'" [style.background-color]="formatedValue ? formatedValue.color : '-'">
      </div>
      <div>{{formatedValue ? formatedValue.value : '-'}}</div>
    </div>
  </div>

  <div fxFlex="75" class="label-answer"
    *ngIf="(customField.type == 3 || customField.type == 19) && customField.targetFieldType != FType.Gallery && customField.targetFieldType != FType.Attachment && customField.targetFieldType != FType.Image">
    {{customField.value ? (customField.value | translateToLocale | noComma) : '-'}}
  </div>

  <div fxFlex="75" class="multiLingual-field-readonly"
    *ngIf="((customField.type == FType.Text || customField.targetFieldType == FType.Text || customField.type == FType.MultiLine || customField.targetFieldType == FType.MultiLine) && customField.isMultiLingual) && multiLingulaArray.length > 0">
    <ng-container *ngFor="let item of multiLingulaArray">
      <div class="custom-field-name" *ngIf="showLabel">{{item.label}} <span *ngIf="nestedFieldsCount > 1">#{{nestedFieldIndex+1}}</span></div>
      <div class="label-answer">{{item.value ? (item.value | translateToLocale | noComma) : '-'}}</div>
    </ng-container>
  </div>
  <div class="custom-field-name" *ngIf="showLabel && (customField.type == FType.Text || customField.targetFieldType == FType.Text || customField.type == FType.MultiLine || customField.targetFieldType == FType.MultiLine) && !customField.isMultiLingual">
    {{customField.label | translateToLocale}} <span *ngIf="nestedFieldsCount > 1">#{{nestedFieldIndex+1}}</span>
  </div>
  <div fxFlex="75" class="label-answer"
    *ngIf="(customField.type == FType.Text || customField.targetFieldType == FType.Text || customField.type == FType.MultiLine || customField.targetFieldType == FType.MultiLine) && !customField.isMultiLingual">
    {{customField.value ? (customField.value | translateToLocale | noComma) : '-'}}
  </div>
  <div fxLayout="column" fxFlex="75" class="field-value"
    *ngIf="customField.type == FType.Hyperlink || customField.targetFieldType == FType.Hyperlink">
    <a *ngIf="customField.value; else emptyValue" fxFlex target="_blank" rel="noopener noreferrer" class="hyper-link"
      name="{{'field' + customField.id}}" href="{{isObject(formatedValue) ? formatedValue.url : customField.value }}"
      disabled>{{isObject(formatedValue) && formatedValue.title != '' ? formatedValue.title : isObject(formatedValue) && formatedValue.title == '' ? formatedValue.url : customField.value }}</a>
  </div>

  <div fxLayout="column" fxLayoutGap="2px" fxFlex="75" *ngIf="customField.type == 6" id="applicationCheckbox">
    <mat-checkbox class="label-answer" *ngFor="let lookup of customField.lookupValues"
      name="{{'field' + customField.id}}" [checked]="lookup.isSelected" disabled>
      {{lookup.label | translateToLocale}}
    </mat-checkbox>
  </div>

  <ng-container
    *ngIf="(customField.type == FType.Attachment || customField.targetFieldType == FType.Attachment || customField.type == FType.Image || customField.targetFieldType == FType.Image)">
    <xcdrs-attached-file-details class="attached-file-container" *ngIf="customField.value; else emptyValue"
      [customField]="customField"></xcdrs-attached-file-details>
  </ng-container>

  <ng-container *ngIf="customField.type == FType.Gallery || customField.targetFieldType == FType.Gallery">
    <app-gallery-details style="width: 100%;" class="attached-file-container" 
    [customField]="customField"></app-gallery-details>
  </ng-container>

  <ng-container *ngIf="customField.type == FType.Tags || (customField.type == FType.LookupAddon && customField.isMultipleAllowed)">
    <div fxFlex="75" *ngIf="customField.value; else emptyValue">
      <app-view-field-tags class="value-chip-list"  [type]="customField.type" [value]="customField.value"></app-view-field-tags>
    </div>
  </ng-container>

  <ng-template #emptyValue>
    <div class="label-answer">
      -
    </div>
  </ng-template>
</div>
