import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-field-tags',
  templateUrl: './view-field-tags.component.html',
  styleUrls: ['./view-field-tags.component.scss']
})
export class ViewFieldTagsComponent implements OnInit {
  tags: any[] = [];
  @Input() type;
  private _value: any;
  @Input('value')
  set value(val){
    this._value = val;
    if(this.type != 13){
      if(val && val.trim() ){
        this.tags = val.split(',').map(t => t.trim());
      } 
    } else {
      this.tags = val;
    }
  }
  get value(): any{
    return this._value
  }
  constructor() { }

  ngOnInit(): void {
  }

}
