<div [ngClass]="{'tw-flex' : viewAsTableRow }">
  <div fxLayout="row" fxLayoutGap="15px" style="width:100%;"
    *ngIf="isReadOnlyForm && !isApplicationCriteriaFormSection && !isApplicantCriteriaFormSection && !isApplicationAssessmentFormSection">
    <div fxFlex="15" class="custom-fields-icon">
    </div>
    <div fxLayout="column" fxFlex>
      <div *ngFor="let section of sections | formSectionDetailsFilter; let sectionIndex = index" class="custom-field-container"
        [ngClass]="{'margin-bottom60':(sections.length - 1 != sectionIndex)}">
        <div *ngFor="let nestedSection of section.nestedSections; let nestedSectionIndex = index"
          [ngClass]="{'border-bottom-grey':(section.nestedSections.length - 1 != nestedSectionIndex)}">
          <div class="section-header" *ngIf="nestedSectionIndex == 0">
            {{section.defaultName | translateToLocale}}
          </div>
          <ng-container
            *ngFor="let customField of nestedSection.fields | submittedFieldsFilter; let i = index; let isLast = last">
            <app-custom-field-application-submitted [customField]="customField" [isLast]="isLast">
            </app-custom-field-application-submitted>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- readonly for criteria -->
  <div fxLayout="row" fxLayoutGap="15px" style="width:100%;"
    *ngIf="isReadOnlyForm && isApplicationAssessmentFormSection">
    <div fxLayout="column" class="custom-field-container" fxFlex>
      <ng-container
        *ngFor="let customField of fields | customFieldVisibleFilter | submittedFieldsFilter; let isLast = last">
        <app-custom-field-application-submitted [customField]="customField" [isLast]="isLast">
        </app-custom-field-application-submitted>
      </ng-container>
    </div>
  </div>

  <!-- Appliction Criteria  -->
  <ng-conatiner *ngIf="isApplicationCriteriaFormSection" [ngClass]="{'tw-flex' : viewAsTableRow }">
    <div fxLayout="row" fxLayoutGap="15px" style="width:100%;" *ngIf="!viewAsTableRow; else tableRow">
      <div fxLayout="column" class="custom-field-container no-border full-width">
        <div fxLayout="row" fxLayoutAlign="start center" class="custom-field no-border"
          *ngFor="let customField of fields; let customFieldIndex = index"
          [ngClass]="customField.isDelete ? 'lineThrough invisible' : !customField.isVisible ? 'invisible' : !mainObject.isPublished ? 'new' : !customField.id || customField.id <= 0 ? 'new':''"
          [class.hidden]="!customField.isVisible">
          <div class="full-width">
            <app-custom-field *ngIf="!customField.isMultipleAllowed else tempNestedFieldsCriteria"
              [customField]="customField" [isDisabled]="customField.isReadOnly || isDisabled"
              [application-id]="mainObject?.applicationId" [applicant-id]="applicantId"
              [componentName]="ComponentName.CustomFieldsFormComponent"
              (isValid)="setValidity(customField.sectionCustomFieldId, $event)" (isCallback)="isCallback = $event"
              [fieldOptions]="{floatLabel:'auto',showPlaceholder:true}" (blur)="onFieldBlur($event)"
              [dynamicFieldsValues]="dynamicFieldsValues" [showDynamicSpinner]="showDynamicSpinner"
              [sectionIndex]="sectionIndex" [callFrom]="callFrom"></app-custom-field>
            <ng-template #tempNestedFieldsCriteria>
              <div fxLayout="column" fxLayoutGap="8px">
                <app-custom-field
                  *ngFor="let nestedcustomField of customField.nestedFields; let nestedFieldIndex = index; let isLastNestedField = last"
                  [customField]="nestedcustomField" [isDisabled]="nestedcustomField.isReadOnly || isDisabled"
                  [application-id]="mainObject?.applicationId" [applicant-id]="applicantId"
                  [componentName]="ComponentName.CustomFieldsFormComponent"
                  (isValid)="setValidity(nestedcustomField.validationKey, $event)" (isCallback)="isCallback = $event"
                  [fieldOptions]="{floatLabel:'auto',showPlaceholder:true}" (blur)="onFieldBlur($event)"
                  [dynamicFieldsValues]="dynamicFieldsValues" [showDynamicSpinner]="showDynamicSpinner"
                  [sectionIndex]="sectionIndex"
                  (addNestedField)="onAddNestedField(customField)"
                  (deleteNestedField)="onDeletedNestedField($event, customField)" [isLastNestedField]="isLastNestedField"
                  [nestedFieldIndex]="nestedFieldIndex" [nestedFieldsCount]="customField.nestedFields.length" [callFrom]="callFrom"></app-custom-field>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

  </ng-conatiner>

  <!-- Submit applicant form -->
  <ng-container *ngIf="isApplicantCriteriaFormSection">
  <div fxLayout="row" fxLayoutGap="15px" style="width:100%;" *ngIf="!viewAsTableRow; else tableRow">
    <div fxLayout="column" class="custom-field-container no-border full-width">
      <div fxLayout="row" fxLayoutAlign="start center" class="custom-field no-border"
        *ngFor="let customField of fields; let customFieldIndex = index"
        [ngClass]="customField.isDelete ? 'lineThrough invisible' : !customField.isVisible ? 'invisible' : !mainObject.isPublished ? 'new' : !customField.id || customField.id <= 0 ? 'new':''"
        [class.hidden]="!customField.isVisible">
        <div class="full-width">
          <!--index1 - {{customFieldIndex}} event - {{customFieldValid[customFieldIndex]}}-->
          <app-custom-field *ngIf="!customField.isMultipleAllowed else tempNestedFields" [customField]="customField"
            [doFileUpload]="doFileUpload" [doFileUploadOnChange]="doFileUploadOnChange"
            [isDisabled]="customField.isReadOnly" [application-id]="mainObject?.applicationId"
            [applicant-id]="applicantId" [componentName]="ComponentName.CustomFieldsFormComponent" [resubmit]="resubmit"
            (isValid)="setValidity(customField.sectionCustomFieldId, $event)" (isCallback)="isCallback = $event"
            (isFileUploaded)="filesUploadStatusCheck(customField.sectionCustomFieldId, customField.type, $event)"
            [fieldOptions]="{floatLabel:'auto',showPlaceholder:true}" (blur)="onFieldBlur($event)"
            [dynamicFieldsValues]="dynamicFieldsValues" [showDynamicSpinner]="showDynamicSpinner"
            [sectionIndex]="sectionIndex" [showHint]="showHint" [forFilterView]="forFilterView" [field]="customField" [clientMessage]="clientMessage"
            [callFrom]="callFrom"></app-custom-field>
          <ng-template #tempNestedFields>
            <div fxLayout="column" fxLayoutGap="8px">
              <app-custom-field
                *ngFor="let nestedcustomField of customField.nestedFields; let nestedFieldIndex = index; let isLastNestedField = last"
                [customField]="nestedcustomField" [doFileUpload]="doFileUpload"
                [doFileUploadOnChange]="doFileUploadOnChange" [isDisabled]="nestedcustomField.isReadOnly"
                [application-id]="mainObject?.applicationId" [applicant-id]="applicantId"
                [componentName]="ComponentName.CustomFieldsFormComponent" [resubmit]="resubmit"
                (isValid)="setValidity(nestedcustomField.validationKey, $event)" (isCallback)="isCallback = $event"
                (isFileUploaded)="filesUploadStatusCheck(nestedcustomField.validationKey, customField.type, $event)"
                [fieldOptions]="{floatLabel:'auto',showPlaceholder:true}" (blur)="onFieldBlur($event)"
                [dynamicFieldsValues]="dynamicFieldsValues" [showDynamicSpinner]="showDynamicSpinner"
                [sectionIndex]="sectionIndex" [showHint]="showHint" (addNestedField)="onAddNestedField(customField)"
                (deleteNestedField)="onDeletedNestedField($event, customField)" [isLastNestedField]="isLastNestedField"
                [nestedFieldIndex]="nestedFieldIndex" [nestedFieldsCount]="customField.nestedFields.length"
                [forFilterView]="forFilterView" [field]="customField" [clientMessage]="clientMessage" [callFrom]="callFrom"> 
              </app-custom-field>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  
</ng-container>

  <div fxLayout="row" fxLayoutGap="15px" style="width:100%;" *ngIf="isApplicantCriteriaFeedbackFormSection">
    <div fxLayout="column" class="custom-field-container" fxFlex>
      <div fxLayout="row" fxLayoutAlign="start center" class="custom-field"
        *ngFor="let customField of fields; let customFieldIndex = index"
        [ngClass]="!customField.isVisible ? 'invisible' : !mainObject.isPublished ? 'new' : !customField.id || customField.id <= 0 ? 'new':''"
        [class.hidden]="!customField.isVisible">
        <div fxFlex="20" class="custom-field-name">
          <span>{{customField.label | translateToLocale}}</span><span *ngIf="customField.isRequired">*</span>
        </div>
        <div fxFlex="60">
          <!--index1 - {{customFieldIndex}} event - {{customFieldValid[customFieldIndex]}}-->
          <app-custom-field [customField]="customField" [doFileUpload]="doFileUpload"
            [doFileUploadOnChange]="doFileUploadOnChange" [isDisabled]="customField.isReadOnly"
            [application-id]="mainObject?.applicationId" [applicant-id]="applicantId"
            [componentName]="ComponentName.CustomFieldsFormComponent" (isValid)="setValidity(customFieldIndex, $event)"
            (isCallback)="isCallback = $event"
            (isFileUploaded)="filesUploadStatusCheck(customFieldIndex, customField.type, $event)"
            [fieldOptions]="{floatLabel:'auto',showPlaceholder:false}" [callFrom]="callFrom"></app-custom-field>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #tableRow>
  <div class="td tw-flex tw-flex-row tw-w-60" [ngClass]="{'money-width' : customField.type == FType.Price, 'tw-px-6 tw-items-center' : customField.type == FType.Rating}" *ngFor="let customField of fields; let customFieldIndex = index" [class.hidden]="!customField.isVisible">
    <app-custom-field [customField]="customField" class="tw-w-full"
    [doFileUpload]="doFileUpload" [doFileUploadOnChange]="doFileUploadOnChange" [isDisabled]="customField.isReadOnly"
    [application-id]="mainObject?.applicationId" [applicant-id]="applicantId"
    [componentName]="ComponentName.CustomFieldsFormComponent" [resubmit]="resubmit"
    (isValid)="setValidity(customField.sectionCustomFieldId, $event)" (isCallback)="isCallback = $event"
    (isFileUploaded)="filesUploadStatusCheck(customField.sectionCustomFieldId, customField.type, $event)"
    [fieldOptions]="{floatLabel:'auto',showPlaceholder:true}" (blur)="onFieldBlur($event)"
    [dynamicFieldsValues]="dynamicFieldsValues" [showDynamicSpinner]="showDynamicSpinner" [sectionIndex]="sectionIndex"
    [showHint]="showHint" [forFilterView]="forFilterView" [field]="customField" [clientMessage]="clientMessage"
    [callFrom]="callFrom" [showInTable]="true" [showHintIconSpace]="false"></app-custom-field>
  </div>
  <div *ngIf="showRemoveSection"  class="td tw-flex tw-flex-row tw-px-2 tw-justify-center tw-items-center">
    <button 
      mat-icon-button (click)="removeSection.emit(true)">
      <mat-icon>
        <!-- <img src="../../../../assets/images/delete-icon.svg" class="tw-h-6" /> -->
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 4.5V6H6V7.5H18V6H15V4.5H9ZM6.75 8.25H8.25V17.6893L8.56066 18H15.4393L15.75 17.6893V8.25H17.25V18.3107L16.0607 19.5H7.93934L6.75 18.3107V8.25Z" fill="#98A2B3"/>
        </svg> 
      </mat-icon>
      <!-- <span [translate]="'REMOVE'"></span> -->
    </button>
  </div>
</ng-template>