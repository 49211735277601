<!--
  <div class="file-detail" *ngIf="customField.details.gallery[0]?.downloadUrl || selectedDownloadUrl">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" style="height: 300px;background-color: #fafafa;">
      <img class="image-preview cursor-pointer" [src]="selectedDownloadUrl ? selectedDownloadUrl : customField.details.gallery[0]?.downloadUrl"
        (click)="openPreview(selectedDownloadUrl ? selectedDownloadUrl : customField.details.gallery[0]?.downloadUrl)" style="object-fit: contain; max-height: 100%;">
    </div>
  </div> -->

<div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="8px" *ngIf="customField">
  <div fxLayout="column" fxLayoutAlign="start start" *ngFor="let detail of customField.details.gallery; let i = index;">
    <div *ngIf="detail.downloadUrl">
      <img class="thumbnail-size image-preview cursor-pointer" [src]="detail.downloadUrl | secure | async" (click)="onSelect(detail?.downloadUrl, i)" [ngClass]="{'active': activeClass == i}">
      <div *ngIf="allowRemove" fxLayoutAlign="end start">
        <mat-icon class="gray-icon" (click)="removeFile(i)">
          delete_outline
        </mat-icon>
      </div>
    </div>
  </div>
</div>
