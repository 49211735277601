import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm, FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';

import { AccountService } from '../services/account.service'
import { MessageService } from '../../common/services/message-service'
import { LabelService } from '../../common/services/label-service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { InvitationLinkInformation } from '../../models/invitation-link-information';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { Roles } from '../../common/enums/roles-enum';
import { UserInfoService } from '../../common/services/userinfo-service';
import { TranslateService } from '@ngx-translate/core';
import { SystemSettingsService } from '../../common/services/system-settings-service';
import { IdenediAuthService } from '../services/idenedi-auth.service';
import { MatchPasswords } from '../../common/validators/match-password.validator';
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.less']
})
export class SetPasswordComponent implements OnInit {
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  confirmpassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
  setPwForm = this._fb.group({
    password: this.password,
    confirmpassword: this.confirmpassword
  }, { validator: MatchPasswords});
  isCallBack: boolean = false;
  isError: boolean = false;
  userKey: string = '';
  userType: string = '';
  userEmail: string = '';
  definitionKey: string = '';
  invitationLinkInformation: InvitationLinkInformation = new InvitationLinkInformation;

  constructor(private accountService: AccountService, private router: Router, private route: ActivatedRoute, private _dataSharingService: DataSharingService,
    private msgServie: MessageService, public labelService: LabelService, private _translationHelperService: TranslationHelperService,
    private _userInfoService: UserInfoService, public translate: TranslateService
    , public systemSettingsService: SystemSettingsService
    , public dataSharingService: DataSharingService
    , private idenediAuthService: IdenediAuthService,
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    sessionStorage.clear();
    //localStorage.clear();
    this.route.params.subscribe(params => {
      this.userKey = params['key'];
      this.userType = params['type'];
      if (params['definitionkey'])
        this.definitionKey = params['definitionkey'];
    });

    if (this.userKey != '') {
      this.validateKey(this.userKey);
    }

    if (this.definitionKey != '')
      this.accountService.getInvitationLinkInformation(this.definitionKey).subscribe(resp => {
        this.invitationLinkInformation = resp;
        if (this.invitationLinkInformation.definitionId == 0) {
          this.router.navigateByUrl('/invalidlink');
        }
        this.invitationLinkInformation.definitionKey = this.definitionKey;
        this._dataSharingService.invitationLinkInformation.next(this.invitationLinkInformation);
      });
  }

  getPasswordErrorMessage() {
    return this.password.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.PASSWORD-REQUIRED') :
      this.password.hasError('minlength') ? this._translationHelperService.getTranslationByKey('RequiredMessages.PASSWORD-MINIMUM-CHARACTERS') :
        '';
  }

  getCnfrmPasswordErrorMessage() {
    return this.confirmpassword.hasError('required') ? this._translationHelperService.getTranslationByKey('RequiredMessages.CONFIRM-PASSWORD-REQUIRED') :
      this.confirmpassword.hasError('minlength') ? this._translationHelperService.getTranslationByKey('RequiredMessages.CONFIRM-PASSWORD-MINIMUM-CHARACTERS') :
        '';
  }

  goto(comp) {
    if (comp == 'login') {
      this.router.navigateByUrl('/login');
    }
  }

  validateKey(key: string) {
    this.accountService.validateKey(key).subscribe((data: any) => {
      if (data == 'Invalid') {
        this.router.navigateByUrl('/invalidlink');
      } else if (data == 'Expired') {
        this.router.navigateByUrl('/expired');
      } else {
        this.userEmail = data;
      }

    },
      (err: HttpErrorResponse) => {
        if (err.status == 409 && err.error.code == "account-exists") {
          this.router.navigateByUrl('/login?key=' + key + '&type=' + this.userType + '&email=' + err.error.data.email);
        }
      });
  }

  OnSubmit(pass: string, confrmPass: string) {
    this.isError = false;
    this.isCallBack = false;
    if (this.setPwForm.valid) {
      this.isCallBack = true;

      if (this.userType.toLowerCase() == 'applicant') {
        this.accpetApplicantInvite(pass)
      } else if (this.userType.toLowerCase() == 'user') {
        this.accpetUserInvite(pass)
      } else if (this.userType.toLowerCase() == 'passwordreset') {
        this.setPassword(pass);
      } else if (this.userType.toLowerCase() == 'setandactivate') {
        this.setPassword(pass, true);
      }
    }
  }

  accpetApplicantInvite(pass) {
    this.accountService.accpetApplicantInvite(this.userKey, pass).subscribe((data: any) => {
      if (this.invitationLinkInformation.definitionId > 0) {
        this.authenticateUser();
      } else {
        this.isCallBack = false;
        this.msgServie.showMessage(this._translationHelperService.getTranslationByKey('SetPassword.PASSWORD-SET-SUCCESSFULLY'));
        this.router.navigateByUrl('/login');
      }
    },
      (err: HttpErrorResponse) => {
        this.msgServie.showMessage(err.message);
        this.isError = true;
        this.isCallBack = false;
      });
  }
  accpetUserInvite(pass) {
    this.accountService.accpetUserInvite(this.userKey, pass).subscribe((data: any) => {
      this.isCallBack = false;
      this.msgServie.showMessage(this._translationHelperService.getTranslationByKey('SetPassword.PASSWORD-SET-SUCCESSFULLY'));
      this.router.navigateByUrl('/login');
    },
      (err: HttpErrorResponse) => {
        this.msgServie.showMessage(err.message);
        this.isError = true;
        this.isCallBack = false;
      });
  }

  setPassword(pass, activateAccount: boolean = false) {
    this.accountService.setPassword(this.userKey, pass, activateAccount).subscribe((data: any) => {
      this.isCallBack = false;
      if (this.userType.toLowerCase() == 'setandactivate') {
        this.userEmail = data;
        this.authenticateUser();
      } else {
        this.msgServie.showMessage(this._translationHelperService.getTranslationByKey('SetPassword.PASSWORD-SET-SUCCESSFULLY'));
        this.router.navigateByUrl('/login');
      }
    },
      (err: HttpErrorResponse) => {
        this.msgServie.showMessage(err.message);
        this.isError = true;
        this.isCallBack = false;
      });
  }

  private authenticateUser(personaId: number = 0) {
    this.accountService.userAuthentication(this.userEmail, this.password.value, personaId).subscribe((data: any) => {
      var personas = JSON.parse(data.personas);
      var result = {
        token: {
          accessToken: data.access_token,
          expiry: new Date(data['.expires']),
          refreshToken: data.refresh_token,
          persoans: personas
        },
        user: {
          name: data.name,
          email: data.email,
          role: data.role,
          imageUrl: data.imageUrl,
          organizationId: data.organizationId
        }
      };

      sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));
      this._userInfoService.update();
      if (this.userType != Roles.Applicant) {
        this.translate.use('en');
      }
      if (this.userType == 'User') {
        this.accountService.linkToUser(this.userKey).subscribe(resp => {
          this.authenticateUser(<number><any>resp);
          this.userType = "";
        });
      }
      else if (this.userType == 'Applicant') {
        this.accountService.linkToApplicant(this.userKey).subscribe(resp => {
          this.authenticateUser(<number><any>resp);
          this.userType = "";
        });
      }
      this.userType = this.userKey = null;

      if (this._userInfoService.orgPersonas.length > 1) {
        this.router.navigateByUrl('/accounts');
      } else {
        this.isCallBack = false;
        this._userInfoService.setAuthorizationData(data);
      }

      this._dataSharingService.systemLanguages.subscribe(systemLanguages => {
        this._translationHelperService.getOrganizationLocales().subscribe(resp => {
          let activeLocales = resp.body.split(',');
          if (activeLocales.length > 0) {
            systemLanguages.forEach(systemLanguage => {
              if (!systemLanguage.isDefault)
                systemLanguage.isActive = false;
            });
            systemLanguages.forEach(systemLanguage => {
              activeLocales.forEach(activeLocale => {
                if (systemLanguage.locale == activeLocale) {
                  systemLanguage.isActive = true;
                }
              })
            });
          }
        });

      });

    },
      (err: HttpErrorResponse) => {
        this.isCallBack = false;
      });
  }

  public showIdenediLoader: boolean = false;
  public redirectToIdenediLogin() {
    this.showIdenediLoader = true;
    window.location.href = this.idenediAuthService.formatedIdenediLoginUrl(`${this.userKey};${this.userType}`);
  }

}
