import { Component, OnInit, Inject } from '@angular/core';
import { AllDefinitionsService } from '../services/all-definitions.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { StageCriteria } from '../models/stage-criteria';
import { UserResult } from '../../models/UserResult';
import { NameId } from '../../models/UserResult';
import { DefinitionStageModel } from '../models/definition-stage';
import { JobRoleModel } from '../../models/job-role-model';
import { CriteriaOwner, CriteriaOwnerType } from '../../models/criteria-owner';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../../common/services/message-service';
import { LocaleMapperService } from '../../shared/locale-mapper/locale-mapper.service';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { LocaleMapper } from '../../shared/locale-mapper/locale-mapper';
import { CardLoad } from '../../common/animations/animations';
import { StageCriteriaType } from '../../common/enums/stage-criteria-type';
import { LabelService } from '../../common/services/label-service';
import { Reminders } from '../../common/enums/reminders-enum';
import { TranslateService } from '@ngx-translate/core';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { DefinitionType } from '../models/application-definition';

@Component({
  selector: 'app-add-criteria-dialog',
  templateUrl: './add-criteria-dialog.component.html',
  styleUrls: ['./add-criteria-dialog.component.less'],
  animations: [CardLoad]
})
export class AddCriteriaDialogComponent implements OnInit {
  criteria = new StageCriteria();
  stage = new DefinitionStageModel();
  isUpdate: boolean;
  isPublished: boolean;
  isFromApplicationDetail: boolean = false;
  /////reassignAssessments: boolean;
  reassignAssessmentsDisable: boolean;
  ownerAssignAssesment: boolean;
  applicationId: number = 0;
  stageCriteriaType: typeof StageCriteriaType = StageCriteriaType;
  Keys: any;
  private _definitionId: number;
  public criteriaOwners: CriteriaOwner[] = [];
  public criteriaOwner: CriteriaOwner;
  public criteriaOwnerType: typeof CriteriaOwnerType = CriteriaOwnerType;
  public remindersType: typeof Reminders = Reminders;
  public disableAddCriteriaButton: boolean = false;
  public criteriaLocaleMapper: LocaleMapper;
  public oldOwnerId: any;
  public reminders: any;
  public definitionType: DefinitionType;
  constructor(private _definitionService: AllDefinitionsService,
    public dialogRef: MatDialogRef<AddCriteriaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    , public messageService: MessageService
    , public localeMapperService: LocaleMapperService
    , public dataSharingService: DataSharingService
    , public labelService: LabelService
    , public translate: TranslateService
    , private _translationHelperService: TranslationHelperService) {
    this.getTranslations();
    this._definitionId = data.definitionId;
    this.criteria = data.criteria;

    this.isPublished = data.isPublished
    if (data.isFromApplicationDetail != undefined)
      this.isFromApplicationDetail = data.isFromApplicationDetail;
    if (data.applicationId != undefined)
      this.applicationId = data.applicationId;
    if (data.stage != undefined)
      this.stage = data.stage;
    if (data.isUpdate) {
      this.isUpdate = true;
      if ((this.criteria.ownerId && this.criteria.ownerId > 0) || this.criteria.ownerId == -1) {//-1 for Parent Application Applicant
        this.setCriteriaOwner(this.criteria.ownerId, this.criteria.name, CriteriaOwnerType.Users);
      } else if (this.criteria.ownerIdUserLookupCustomFieldPath && this.criteria.ownerIdUserLookupCustomFieldPath.length > 0) {
        this.setCriteriaOwner(this.criteria.ownerIdUserLookupCustomFieldPath, this.criteria.name, CriteriaOwnerType.UserLookup);
      } else {
        this.setCriteriaOwner(this.criteria.ownerIdJobRole, this.criteria.name, CriteriaOwnerType.JobRoles);
      }
    }
    else {
      this.isUpdate = false;
      this.criteria.approveText = '{"en":"Approve","ar":"قبول","fr":""}';
      this.criteria.rejectText = '{"en":"Reject","ar":"رفض","fr":""}';
    }
    this.criteriaLocaleMapper = this.localeMapperService.createLocaleMapperItem(this.criteria, ['name']);
    if (data.definitionType) {
      this.definitionType = data.definitionType;
    }
  }
  setCriteriaOwner(id: any, name: string, type: CriteriaOwnerType): void {
    this.criteriaOwner = new CriteriaOwner();
    this.criteriaOwner.id = id;
    this.criteriaOwner.name = name;
    this.criteriaOwner.type = type;
  }
  ngOnInit() {
    this.reminders = this.enumSelector(Reminders);
    this.criteria.reassignAssessments = false;
    this.reassignAssessmentsDisable = true;
    // this.ownerAssignAssesment = true;
    // if(this.criteria.reassignAssessments == true) {
    //   this.ownerAssignAssesment = true;
    // }
    // else {
    //   this.ownerAssignAssesment = false;
    // }
    if (this.criteriaOwner && this.criteriaOwner.id) {
      this.oldOwnerId = this.criteriaOwner.id;
    }
    var stageid: Number = 0
    if (this.stage == undefined || this.stage.id == undefined)
      stageid = 0
    else
      stageid = this.stage.id
    this.disableAddCriteriaButton = false;
    this._definitionService.getCriteriaOwners(this._definitionId, stageid).subscribe(resp => {
      this.criteriaOwners = resp.body;
      if (this.criteria.type == StageCriteriaType.Feedback || this.criteria.type == StageCriteriaType.Assessment) {
        if (this.definitionType == DefinitionType.Link) {
          let parentApplicationOwner = new CriteriaOwner();
          parentApplicationOwner.id = '-1';
          parentApplicationOwner.name = `${this.labelService.getLabel().applicant} {Submitter}`;
          parentApplicationOwner.type = CriteriaOwnerType.Users;
          this.criteriaOwners.unshift(parentApplicationOwner);
        }
        if (this.definitionType == DefinitionType.Link) {
          let criteriaOwner = new CriteriaOwner();
          criteriaOwner.id = '0';
          criteriaOwner.name = `${this.labelService.getLabel().applicant} {Requester}`;
          criteriaOwner.type = CriteriaOwnerType.Users;
          this.criteriaOwners.unshift(criteriaOwner);
        } else {
          let criteriaOwner = new CriteriaOwner();
          criteriaOwner.id = '0';
          criteriaOwner.name = this.labelService.getLabel().applicant;
          criteriaOwner.type = CriteriaOwnerType.Users;
          this.criteriaOwners.unshift(criteriaOwner);
        }
      }

      if (this.isUpdate) {
        for (let i = 0; i < this.criteriaOwners.length; i++) {
          if (this.criteriaOwners[i].id == this.criteriaOwner.id && this.criteriaOwners[i].type == this.criteriaOwner.type) {
            this.criteriaOwner = this.criteriaOwners[i];
            break;
          }
        }
        if (parseInt(this.criteriaOwner.id) == 0) {
          this.criteriaOwner = this.criteriaOwners[0];
        }
      }


    }, error => { });
  }
  getOwnerId(event) {
    if (this.oldOwnerId == event.value.id) {
      this.criteria.reassignAssessments = false;
      this.reassignAssessmentsDisable = true;
    }
    else {
      this.criteria.reassignAssessments = false;
      this.reassignAssessmentsDisable = false;
    }
    if (this.criteriaOwner.id != (this.criteriaOwner.type).toString()) {
      this.criteria.reassignAssessments = false;
      this.reassignAssessmentsDisable = false;
    }
  }
  onSubmit() {
    if (!this.isFromApplicationDetail) {

      this.criteria.ownerName = this.criteriaOwner.name;
      //this.criteria.type = StageCriteriaType.Assessment;
      if (this.criteriaOwner.type == CriteriaOwnerType.Users) {
        this.criteria.ownerId = <number><any>this.criteriaOwner.id;
        this.criteria.ownerIdJobRole = 0;
        this.criteria.ownerIdUserLookupCustomFieldPath = '';
      }
      else if (this.criteriaOwner.type == CriteriaOwnerType.UserLookup) {
        this.criteria.ownerIdUserLookupCustomFieldPath = this.criteriaOwner.id;
        this.criteria.ownerId = 0;
        this.criteria.ownerIdJobRole = 0;
      }
      else {
        this.criteria.ownerIdJobRole = <number><any>this.criteriaOwner.id;
        this.criteria.ownerId = 0;
        this.criteria.ownerIdUserLookupCustomFieldPath = '';
      }

      this.dialogRef.close({ data: this.criteriaLocaleMapper.mapFromLocaleModelNoReference(this.criteria), isUpdate: this.isUpdate, isFromApplicationDetail: this.isFromApplicationDetail });
    } else {
      this.disableAddCriteriaButton = true;
      let data = {
        name: this.criteria.name,
        stageId: this.stage.id,
        ownerId: null,
        weight: this.stage.totalWeight,
        slaHours: this.criteria.slaHours,
        ownerIdJobRole: null,
        ownerIdUserLookupCustomFieldPath: null,
        type: this.criteria.type,
        approveText: this.criteria.approveText,
        rejectText: this.criteria.rejectText,
        isSigned: this.criteria.isSigned
      };
      if (this.criteriaOwner.type == CriteriaOwnerType.Users) {
        data.ownerId = this.criteriaOwner.id;
      }
      else if (this.criteriaOwner.type == CriteriaOwnerType.UserLookup) {
        data.ownerIdUserLookupCustomFieldPath = this.criteriaOwner.id;
      }
      else {
        data.ownerIdJobRole = this.criteriaOwner.id;
      }

      this._definitionService.addApplicationStagesCriteria(this.criteriaLocaleMapper.mapFromLocaleModelNoReference(data), this.applicationId).subscribe(resp => {
        this.dialogRef.close({ data: this.criteriaLocaleMapper.mapFromLocaleModelNoReference(this.criteria, false), isUpdate: this.isUpdate, isFromApplicationDetail: this.isFromApplicationDetail });
      }, (err: HttpErrorResponse) => {
        this.disableAddCriteriaButton = false;
        if (err.status == 409) {
          if (err.error.code == "OwnerNotFoundForAssignment") {
            this.messageService.showMessage(this._translationHelperService.getTranslationByKey('ADD-APPLICATION-DIALOG.UNABLE-ASSIGN-OWNER'));
          }
          else {
            this.messageService.showMessage(this._translationHelperService.getTranslationByKey('ADD-APPLICATION-DIALOG.FAILED-SUBMITTED-LOOKUP-FIELDS'));
          }
        }
      });

    }

    //this._definitionService.addStage(this.stage).subscribe(resp => {
    //  this._messageService.showMessage("Stage has been saved successfully.");
    //});
  }

  enumSelector(reminders) {
    return Object.keys(reminders)
      .map(key => ({ value: reminders[key], title: this.getEnumTranslation(key) }));
  }
  getEnumTranslation(Key): string {
    let prekey: string = "CRITERIA-DIALOG.Reminders.";
    let value = this.Keys[prekey + Key];
    return value;
  }

  getTranslations() {
    this.Keys = {
      "CRITERIA-DIALOG.Reminders.TwoHours": "2 Hours before end",
      "CRITERIA-DIALOG.Reminders.FourHours": "4 Hours before end",
      "CRITERIA-DIALOG.Reminders.SixHours": "6 Hours before end",
      "CRITERIA-DIALOG.Reminders.EightHours": "8 Hours before end",
      "CRITERIA-DIALOG.Reminders.TenHours": "10 Hours before end",
      "CRITERIA-DIALOG.Reminders.TwelveHours": "12 Hours before end",
      "CRITERIA-DIALOG.Reminders.OneDay": "1 Day before end",
      "CRITERIA-DIALOG.Reminders.TwoDay": "2 Day before end"
    };
    this.translate.get(["CRITERIA-DIALOG.Reminders.TwoHours"
      , "CRITERIA-DIALOG.Reminders.FourHours"
      , "CRITERIA-DIALOG.Reminders.SixHours"
      , "CRITERIA-DIALOG.Reminders.EightHours"
      , "CRITERIA-DIALOG.Reminders.TenHours"
      , "CRITERIA-DIALOG.Reminders.TwelveHours"
      , "CRITERIA-DIALOG.Reminders.OneDay"
      , "CRITERIA-DIALOG.Reminders.TwoDay"]).subscribe(translation => {
        this.Keys = translation;
      });
  }

  removeOwner() {
    this.criteriaOwner = null;
  }
  removeReminder() {
    this.criteria.reminder = null;
  }

  CheckReminderByDays() {
    if (this.criteria.slaHours) {
      let remindersEnum = this.enumSelector(Reminders);
      if (this.criteria.slaHours == 1) {
        this.reminders = remindersEnum.filter(x => x.value != '2D' && x.value != '1D');
        if (this.criteria.reminder && (this.criteria.reminder == this.remindersType.OneDay || this.criteria.reminder == this.remindersType.TwoDay)) {
          this.criteria.reminder = this.remindersType.TwelveHours;
        }
      }
      else if (this.criteria.slaHours == 2) {
        this.reminders = remindersEnum.filter(x => x.value != '2D');
        if (this.criteria.reminder && this.criteria.reminder == this.remindersType.TwoDay) {
          this.criteria.reminder = this.remindersType.OneDay;
        }
      }
      else {
        this.reminders = remindersEnum;
      }
    }
    else {
      if (this.criteria.slaHours == 0 && this.criteria.reminder) {
        this.criteria.reminder = '';
        this.reminders = this.enumSelector(Reminders);
      }
    }
  }
}
