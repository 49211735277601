import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { ImportHistoryModel } from '../../models/database-mapper/import-history-model';
@Injectable()
export class ImportHistoryService {

  constructor(private http: HttpClient) { }

  getLookupImportHistory(lookupId: number): Observable<ImportHistoryModel[]> {
    return this.http.get<any>('/webapi/importHistory/lookup/'+ lookupId);
  }  
}
