import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from "@angular/core";
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';

@Component({
  selector: 'app-esp-rating',
  templateUrl: './esp-rating.component.html',
  styleUrls: ['./esp-rating.component.less'],
})

export class EspRatingComponent implements OnInit {

  @Input() customField: CustomFieldModel
  @Input() fieldOptions: any = { showLabel: true };
  @Input() isLongLabel: TemplateRef<any>;
  @Input() readonly: boolean = false;
  @Output() isValid = new EventEmitter();
  isRating: boolean = true;
  @Output() blur = new EventEmitter();
  onBlur(customField: CustomFieldModel) {
    this.blur.emit(customField.isTigger);
  }
  constructor() {

  }

  ngOnInit() {
    if(this.customField.max){
      this.customField.max = Number(this.customField.max);
    } else {
      this.customField.max = 5;
    }
    if (this.customField.isRequired && !this.customField.isReadOnly && !this.customField.value) {
      this.isValid.emit(false);
      // this.isRating = false;

    } else {
      this.isValid.emit(true);
      this.isRating = true;
    }
    if (this.customField.isReadOnly) {
      this.readonly = true;
    }
  }

  onRatingChange(rating: number) {
      if (!this.readonly) {
        if (this.customField.value == rating.toString()) {
          this.customField.value = "";
          if (this.customField.isRequired) {
            this.isValid.emit(false);
            this.isRating = false;
          }
          this.onBlur(this.customField);
        } else {
          this.customField.value = rating.toString();
          if (rating && !Number.isNaN(rating) && +rating > 0) {
            this.isValid.emit(true);
            this.isRating = true;
            this.onBlur(this.customField);

          } else {
            this.isValid.emit(false);
            this.isRating = false;
            this.onBlur(this.customField);

          }
        }
      }
  }

}
