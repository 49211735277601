import { Injectable } from '@angular/core';
import { FormSectionModel } from '../../models/CustomizationSettingsSubmitModel';
import { FeildType, FieldTypes } from '../enums/field-type-enum';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TagableFieldSerivice {

  constructor(
    public datepipe: DatePipe,
  ) {}

  calculateMinMaxFields(sectionList: FormSectionModel[]) {
    const multiSections = sectionList.filter(s => true);
    sectionList.forEach(section => {
      for (var j = 0; j < section.nestedSections.length; j++) { 
        let secFields = section.nestedSections[j].fields.filter(f => (f.type == FeildType.Number) || (f.type == FeildType.Date) || (f.type == FeildType.DateTime));
        // console.log('Section Fields', secFields);
        secFields.forEach(f => {
          

          // Number Type
          if (f.type == FeildType.Number && (f.min != "" || f.max != "")) {
            
            const min = this.minMaxValueSplit(f.min);
            const max = this.minMaxValueSplit(f.max);

          //   console.log('MIN', min);
          //   console.log('Max', max);

            var calculatedMinVal = this.getValueMinMax(min, sectionList);
            var calculatedMaxVal = this.getValueMinMax(max, sectionList);

          //   console.log('calculatedMinVal', calculatedMinVal);
          //   console.log('calculatedMaxVal', calculatedMaxVal);


            f.calculatedMinVal = calculatedMinVal ? calculatedMinVal : calculatedMinVal == 0 ? 0 : '';
            f.calculatedMaxVal = calculatedMaxVal ? calculatedMaxVal : calculatedMaxVal == 0 ? 0 : '';
            if(f.isMultipleAllowed) {
              f.nestedFields.forEach(nf => {
                nf.calculatedMinVal = calculatedMinVal ? calculatedMinVal : calculatedMinVal == 0 ? 0 : '';
                nf.calculatedMaxVal = calculatedMaxVal ? calculatedMaxVal : calculatedMaxVal == 0 ? 0 : '';
              });
            }

          }
          // Date Type
          if ((f.type == FeildType.Date) && (f.min != "" || f.max != "")) {

            // console.log('Min Date', f.min);
            // console.log('Max Date', f.max);

            const minDate = this.minMaxValueSplit(f.min);
            const maxDate = this.minMaxValueSplit(f.max);
            // console.log('split Min Date', minDate);
            // console.log('split Max Date', maxDate); 

            var calculatedMinDate = this.getDateMinMax(minDate, sectionList);
            var calculatedMaxDate = this.getDateMinMax(maxDate, sectionList);

            console.log('calculatedMinVal', calculatedMinDate);
            console.log('calculatedMaxVal', calculatedMaxDate);

            f.calculatedMinDate = calculatedMinDate ? calculatedMinDate : '';
            f.calculatedMaxDate = calculatedMaxDate ? calculatedMaxDate : '';
            if(f.isMultipleAllowed) {
              f.nestedFields.forEach(nf => {
                nf.calculatedMinDate = calculatedMinDate ? calculatedMinDate : '';
                nf.calculatedMaxDate = calculatedMaxDate ? calculatedMaxDate : '';
              });
            }
          }
          if ((f.type == FeildType.DateTime) && (f.min != "" || f.max != "")) {

            console.log('Min Date', f.min);
            console.log('Max Date', f.max);

            const minDate = this.minMaxValueSplit(f.min);
            const maxDate = this.minMaxValueSplit(f.max);
            console.log('split Min Date', minDate);
            console.log('split Max Date', maxDate); 

            var calculatedMinDate = this.getDateTimeMinMax(minDate, sectionList);
            var calculatedMaxDate = this.getDateTimeMinMax(maxDate, sectionList);

            console.log('calculatedMinVal', calculatedMinDate);
            console.log('calculatedMaxVal', calculatedMaxDate);

            f.calculatedMinDate = calculatedMinDate ? calculatedMinDate : '';
            f.calculatedMaxDate = calculatedMaxDate ? calculatedMaxDate : '';
            if(f.isMultipleAllowed) {
              f.nestedFields.forEach(nf => {
                nf.calculatedMinDate = calculatedMinDate ? calculatedMinDate : '';
                nf.calculatedMaxDate = calculatedMaxDate ? calculatedMaxDate : '';
              });
            }
          }
        });
      }


    });
  }
  // Helping Functions
  minMaxValueSplit(a) {
    if (a) {
      let obj = [];
      var p = /[^{}]*(?=\})/g;
      var theWord = "DT/";

      let b = a.replace(/'/g, '"');
      b = a.split(" ");

      // key 1
      if(b[0]) {
        if (b[0].indexOf(theWord) !== -1) {
          if (b[0].match(p)) {
            obj.push({ key1: b[0].replace(/[{}]/g, "") });
          } else {
            obj.push({ val1: b[0] });
          }
        } else {
          if (b[0].match(p)) {
            obj.push({ val1: b[0].replace(/[{}]/g, "") });
          } else {
            obj.push({ val1: b[0] });
          }
        }
      }

      // key 2
      if(b[2]) {
        if (b[2].indexOf(theWord) !== -1) {
          if (b[2].match(p)) {
            obj.push({ key2: b[2].replace(/[{}]/g, "") });
          } else {
            obj.push({ val2: b[2] });
          }
        } else {
          if (b[2].match(p)) {
            obj.push({ val2: b[2].replace(/[{}]/g, "") });
          } else {
            obj.push({ val2: b[2] });
          }
        }
      }

      if(b[1]) {
        obj.push({ opr: b[1] });
      }
      const abc = Object.assign({}, ...obj);
      return abc;
    }
  }

  getValueMinMax(val, sectionList) {
    if (val != null) {
      let obj = [];
      // console.log('abc', val);
      
      if (val.key1) {
        sectionList.forEach(section => {
          for (var j = 0; j < section.nestedSections.length; j++) { 
            let secFields = section.nestedSections[j].fields.filter(f => (f.type == FeildType.Number)); 
            var getminKeyOne = secFields.filter(f => f.sectionCustomFieldId == val.key1.replace('DT/', ''));
            // console.log('key 1', getminKeyOne);
            
            if(getminKeyOne[0]?.nestedFields) {
              getminKeyOne[0].nestedFields[0]?.value ? obj.push(getminKeyOne[0].nestedFields[0]?.value) : null;
            } else {
              getminKeyOne[0]?.value ? obj.push(getminKeyOne[0]?.value) : null;
            }
          }
        });
      } else {
        var getminValOne = val.val1;
        getminValOne ? obj.push(Number(getminValOne)) : null;
      }
      if (val.key2) {
        // console.log('key 2', val.key2);

        sectionList.forEach(section => {
          for (var j = 0; j < section.nestedSections.length; j++) { 
            let secFields = section.nestedSections[j].fields.filter(f => (f.type == FeildType.Number)); 
            var getminKeyTwo = secFields.filter(f => f.sectionCustomFieldId == val.key2.replace('DT/', ''));

            // console.log('key 2', getminKeyTwo);
            if(getminKeyTwo[0]?.nestedFields) {
              getminKeyTwo[0].nestedFields[0]?.value ? obj.push(getminKeyTwo[0].nestedFields[0]?.value) : null;
    
            } else {
              getminKeyTwo[0]?.value ? obj.push(getminKeyTwo[0]?.value) : null;
            }
          }
        });
      } else {
        var getminValTwo = val.val2
        getminValTwo ? obj.push(Number(getminValTwo)) : null;
      }
      if(val.opr) {
        obj.push(val.opr);
      }
      return this.returnCalculatedValues(obj);
    }
  }
  
  getDateTimeMinMax(val, sectionList) {
    if (val != null) {
      let obj = [];
      console.log("Key", val);
      
      if (val.key1) {
        sectionList.forEach(section => {
          for (var j = 0; j < section.nestedSections.length; j++) { 
            let secFields = section.nestedSections[j].fields.filter(f => (f.type == FeildType.Date || f.type == FeildType.DateTime)); 
            var getminKeyOne = secFields.filter(f => f.sectionCustomFieldId == val.key1.replace('DT/', ''));
            console.log('Date key 1', getminKeyOne);
            
            if(getminKeyOne[0]?.nestedFields) {
              getminKeyOne[0].nestedFields[0]?.value ? obj.push(getminKeyOne[0].nestedFields[0]?.value) : null;
            } else {
              getminKeyOne[0]?.value ? obj.push(getminKeyOne[0]?.value) : null;
            //   console.log('Date key 1 Value', getminKeyOne[0]?.value);

            }
          }
        });
      } else {
        var getminValOne = val.val1;
        getminValOne ? obj.push((getminValOne)) : null;
        // console.log('Date  Value 1', getminValOne);

      }
      // if (val.key2) {
      //   sectionList.forEach(section => {
      //     for (var j = 0; j < section.nestedSections.length; j++) { 
      //       let secFields = section.nestedSections[j].fields.filter(f => (f.type == FeildType.Date || f.type == FeildType.DateTime)); 
      //       var getminKeyTwo = secFields.filter(f => f.sectionCustomFieldId == val.key2.replace('DT/', ''));

      //     //   console.log('Date key 2', getminKeyTwo);
      //       if(getminKeyTwo[0]?.nestedFields) {
      //         getminKeyTwo[0].nestedFields[0]?.value ? obj.push(getminKeyTwo[0].nestedFields[0]?.value) : null;
    
      //       } else {
      //         getminKeyTwo[0]?.value ? obj.push(getminKeyTwo[0]?.value) : null;
      //         // console.log('Date key 2 Value', getminKeyTwo[0]?.value);

      //       }
      //     }
      //   });
      // } else {
      //   var getminValTwo = val.val2
      //   getminValTwo ? obj.push(getminValTwo) : null;
      //   // console.log('Date  Value 2', getminValTwo);

      // }
      // obj.push(val.opr);
      return this.returnCalculatedDateTime(obj);
    }
  }

  getDateMinMax(val, sectionList) {
    if (val != null) {
      let obj = [];
      console.log("Key", val);
      
      if (val.key1) {
        sectionList.forEach(section => {
          for (var j = 0; j < section.nestedSections.length; j++) { 
            let secFields = section.nestedSections[j].fields.filter(f => (f.type == FeildType.Date || f.type == FeildType.DateTime)); 
            var getminKeyOne = secFields.filter(f => f.sectionCustomFieldId == val.key1.replace('DT/', ''));
            // console.log('Date key 1', getminKeyOne);
            
            if(getminKeyOne[0]?.nestedFields) {
              getminKeyOne[0].nestedFields[0]?.value ? obj.push(getminKeyOne[0].nestedFields[0]?.value) : null;
            } else {
              getminKeyOne[0]?.value ? obj.push(getminKeyOne[0]?.value) : null;
            //   console.log('Date key 1 Value', getminKeyOne[0]?.value);

            }
          }
        });
      } else {
        var getminValOne = val.val1;
        getminValOne ? obj.push((getminValOne)) : null;
        // console.log('Date  Value 1', getminValOne);

      }
      if (val.key2) {
        sectionList.forEach(section => {
          for (var j = 0; j < section.nestedSections.length; j++) { 
            let secFields = section.nestedSections[j].fields.filter(f => (f.type == FeildType.Date || f.type == FeildType.DateTime)); 
            var getminKeyTwo = secFields.filter(f => f.sectionCustomFieldId == val.key2.replace('DT/', ''));

          //   console.log('Date key 2', getminKeyTwo);
            if(getminKeyTwo[0]?.nestedFields) {
              getminKeyTwo[0].nestedFields[0]?.value ? obj.push(getminKeyTwo[0].nestedFields[0]?.value) : null;
    
            } else {
              getminKeyTwo[0]?.value ? obj.push(getminKeyTwo[0]?.value) : null;
              // console.log('Date key 2 Value', getminKeyTwo[0]?.value);

            }
          }
        });
      } else {
        var getminValTwo = val.val2
        getminValTwo ? obj.push(getminValTwo) : null;
        // console.log('Date  Value 2', getminValTwo);

      }
      obj.push(val.opr);
      return this.returnCalculatedDates(obj);
    }
  }

  returnCalculatedDates(obj) {
    var today = new Date();
    if(obj[0] == -1){
      obj[0] = today;
    }
    var date = new Date(obj[0]);
    var result = new Date();
    if(obj[0] && !obj[1] && !obj[2]) {
      const formatDate =  this.datepipe.transform(obj[0], 'yyyy-MM-dd');
      return formatDate;
    } else {
      if (obj[2] == '+') {
        if (obj[0] && obj[1] && (obj[0] != undefined && obj[1] != undefined)) {
          let res = result.setDate(date.getDate() + Number(obj[1]));
          const calculatedDate = this.datepipe.transform(res, 'yyyy-MM-dd');
          return calculatedDate; 
        }
      } else {
        if ((obj[0] && obj[1]) && (obj[0] != undefined && obj[1] != undefined)) {
          let res = result.setDate(date.getDate() - Number(obj[1]));
          const calculatedDate = this.datepipe.transform(res, 'yyyy-MM-dd');
          return calculatedDate;
        }
      }
    }
  }
  returnCalculatedDateTime(obj) {
    var today = new Date();
    if(obj[0] == -1){
      obj[0] = today;
    }
    var date = new Date(obj[0]);
    var result = new Date();
    if(obj[0] && !obj[1] && !obj[2]) {
      const formatDate =  moment(obj[0]).format('YYYY-MM-DDTHH:mm:ssZ');
      return formatDate;
    } else {
      if (obj[2] == '+') {
        if (obj[0] && obj[1] && (obj[0] != undefined && obj[1] != undefined)) {
          let res = result.setDate(date.getDate() + Number(obj[1]));
          const calculatedDate = moment(obj[0]).format('YYYY-MM-DDTHH:mm:ssZ');
          return calculatedDate; 
        }
      } else {
        if ((obj[0] && obj[1]) && (obj[0] != undefined && obj[1] != undefined)) {
          let res = result.setDate(date.getDate() - Number(obj[1]));
          const calculatedDate = moment(obj[0]).format('YYYY-MM-DDTHH:mm:ssZ');
          return calculatedDate;
        }
      }
    }
  }
  returnCalculatedValues(obj) {

    if((obj[0] && !obj[1] && !obj[2]) || obj[0] == 0) {
      return Number(obj[0]);
    } else {
      if (obj[2] == '+') {
        if (obj[0] && obj[1]) {
          return Number(obj[0]) + Number(obj[1])
        }
      } else {
        if (obj[0] && obj[1]) {
          return Number(obj[0]) - Number(obj[1])
        }
      }

    }
    

  }
  
}
