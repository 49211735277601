import { Component, OnInit, Input } from '@angular/core';
import { CustomFieldModel } from '../../../profile-customization/Model/CustomFieldModel';
import { LabelService } from '../../../common/services/label-service';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldService } from '../../../common/services/custom-field.service';
import { FeildType } from '../../../common/enums/field-type-enum';
@Component({
  selector: 'app-custom-note-field',
  templateUrl: './custom-note-field.component.html',
  styleUrls: ['./custom-note-field.component.scss']
})
export class CustomNoteFieldComponent implements OnInit {
  
  @Input() customField: CustomFieldModel;
  public fieldType: typeof FeildType = FeildType;

  constructor(
    public labelService: LabelService
    , public translate: TranslateService
    , public customFieldService: CustomFieldService
    ) { }
  FType: typeof FeildType = FeildType;

  ngOnInit(): void {
  }

}
