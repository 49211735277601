/// <reference path="common/enums/globals.ts" />
import { Component, AfterViewChecked, ChangeDetectorRef, HostListener } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { DataSharingService } from './common/services/data-sharing.service';

import * as Globals from './common/enums/globals';
import { systemLanguages } from './common/enums/globals';
import { SystemLanguage } from './models/system-language';
import { UserInfoService } from './common/services/userinfo-service';
import { Roles } from './common/enums/roles-enum';
import { TranslationHelperService } from './common/services/translation-helper.service';
import { BrowserTabService } from './common/services/browser-tab-title.service';
import { IframeService } from './iframe/services/iframe.service';
import { CommonHelperService } from './common/services/common-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewChecked {
  title = 'app';
  direction = "ltr";
  isLoggedIn: boolean = false;
  innerWidth: any;
  message;
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this._commonHelperService.documentClickedTarget.next(event.target)
  }
  constructor(private cdRef: ChangeDetectorRef, private meta: Meta
    , private translate: TranslateService
    , public dataSharingService: DataSharingService
    , public userInfoService: UserInfoService
    , private translationHelperService: TranslationHelperService,
    private _browserTabService: BrowserTabService,
    public iframeService: IframeService,
    private _commonHelperService: CommonHelperService) {
    this.userInfoService.update();
    translate.setDefaultLang('en');

    let preferredLanguage: SystemLanguage = JSON.parse(localStorage.getItem('preferredLanguage'));
    if (preferredLanguage) {
      translate.use(preferredLanguage.locale);
    } else {
      translate.use(translate.getBrowserLang());
    }


    this.isLoggedIn = this.onLoginPage();
    this.IECheck();

    //this.innerWidth = (window.screen.width);
    let scaleContent: string = "";
    //if (this.innerWidth <= 330) {
    //  scaleContent = "width=device-width, initial-scale=0.3,  maximum-scale=0.3";
    //} else if (this.innerWidth <= 420) {
    //  scaleContent = "width=device-width, initial-scale=0.4,  maximum-scale=0.4";
    //} else if (this.innerWidth <= 660) {
    //  scaleContent = "width=device-width, initial-scale=0.5,  maximum-scale=0.5";
    //} else if (this.innerWidth <= 750) {
    //  scaleContent = "width=device-width, initial-scale=0.6,  maximum-scale=0.6";
    //} else if (this.innerWidth <= 900) {
    //  scaleContent = "width=device-width, initial-scale=0.7,  maximum-scale=0.7";
    //} else if (this.innerWidth <= 1200) {
    //  scaleContent = "width=device-width, initial-scale=0.8,  maximum-scale=0.8";
    //} else if (this.innerWidth <= 1300) {
    //  scaleContent = "width=device-width, initial-scale=0.9,  maximum-scale=0.9";
    //} else {
    scaleContent = "width=device-width, initial-scale=1,  maximum-scale=1";
    //}
    const viewport = this.meta.addTags([{ name: 'viewport', content: scaleContent }]);
    this.dataSharingService.systemLanguages.next(Globals.systemLanguages);
    //this.dataSharingService.systemLanguages.subscribe(systemLanguages => {
    //  let ngStorageAuth = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
    //  let activeLocales = [];
    //  if (ngStorageAuth != null && ngStorageAuth.token.persoans.length > 0 && ngStorageAuth.token.persoans[0].locales != undefined && typeof ngStorageAuth.token.persoans[0].locales === "string")


    //this.translationHelperService.getOrganizationLocales().subscribe(resp => {
    //  let activeLocales = resp.body.split(',');
    //  if (activeLocales.length > 0) {
    //    systemLanguages.forEach(systemLanguage => {
    //      systemLanguage.isActive = false;
    //    });
    //    systemLanguages.forEach(systemLanguage => {
    //      activeLocales.forEach(activeLocale => {
    //        if (systemLanguage.locale == activeLocale) {
    //          systemLanguage.isActive = true;
    //        }
    //      })
    //    });
    //  }
    //});

    //});

  }

  IECheck(): void {
    if (FileReader.prototype.readAsBinaryString === undefined) {
      FileReader.prototype.readAsBinaryString = function (fileData) {
        const pt = this;
        const reader = new FileReader();
        reader.onload = function (e) {
          const blobURL = URL.createObjectURL(fileData);
          const xhr = new XMLHttpRequest;
          xhr.open('get', blobURL);
          xhr.overrideMimeType('text/plain; charset=x-user-defined');
          xhr.onload = function () {
            const g = { target: { result: xhr.response } };
            pt.onload(g)
          }
          xhr.send();
        }
        reader.readAsArrayBuffer(fileData);
      }
    }
  }

  ngAfterViewChecked() {
    let isLogIn = this.onLoginPage();
    if (isLogIn != this.isLoggedIn) { // check if it change, tell CD update view
      this.isLoggedIn = isLogIn;
      this.cdRef.detectChanges();
    }
  }

  onLoginPage(): boolean {
    if (window.location.href.includes('login') || window.location.href.includes('accounts')) {
      return false;
    } else if (sessionStorage.getItem('ngStorage-auth') != null) {
      let ngStorageAuth = JSON.parse(sessionStorage.getItem('ngStorage-auth'));
      if (ngStorageAuth['user'].role == '') {
        return false;
      }
      return true;
    }
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      var currentLang: SystemLanguage;
      const body = document.getElementsByTagName('body')[0];
      this.dataSharingService.systemLanguages.subscribe(languages => {
        currentLang = languages.filter(x => x.locale == event.lang)[0];
        this.direction = currentLang.direction;
        this.dataSharingService.isRtl = currentLang.direction == 'rtl';
        if (this.dataSharingService.isRtl) {
          body.classList.add('rtl');
        } else {
          body.classList.remove('rtl');
        }
      });
    });

    this._browserTabService.setTitle();
  }

}
