import { Component, OnInit, Inject } from '@angular/core';
import { ApplicationService } from '../../../../application/services/application.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'xcdrs-stop-submissions-dialog',
  templateUrl: './stop-submissions-dialog.component.html',
  styleUrls: ['./stop-submissions-dialog.component.scss']
})
export class StopSubmissionsDialogComponent implements OnInit {

  constructor(
    public applicationService: ApplicationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StopSubmissionsDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  onUpdateStage() {
    this.applicationService.updateAllowSubmission(this.data.applicationId, this.data.allowSubmission).subscribe(resp => {
      this.dialogRef.close({ data: this.data });
    });
  }

}
