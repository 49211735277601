<form [formGroup]="customFieldForm" novalidate [ngClass]="{'criteria-view-form-fields' : callFrom == 'criteria'}">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="column" [ngClass]="{'mat-readonly':readonly}">
      <div *ngIf="customField.type == FType.Text" class="mat-field-container" fxLayout="column">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [id]="customField.id" [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'"
          fxFlex floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field' : showInTable}">
          <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.isLongLabel && customField.isMultiLingual ? '' : customField.label | translateToLocale}}{{customField.isMultiLingual ? ' (English)' : ''}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          
          </mat-label>
          <div matPrefix *ngIf="customField.isRealTime">
            <mat-icon style="font-size:22px;color:gray;margin-right:8px;">stars</mat-icon>
          </div>
          <input [attr.data-type]="customField.type" type="text" matInput [readonly]="customField.isRealTime"
            placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel) ? (customField.label | translateToLocale):''}}"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
            [required]="customField.isRequired" [maxlength]="customField.max == 0 ? null : customField.max"
            [minlength]="customField.min == 0 ? null : customField.min" (blur)="onBlur(customField)"  [ngStyle]="{'text-align': customField.isMultiLingual ? 'left' : '' }"
            (click)="setFocus()" />
          <div matSuffix *ngIf="customField?.isReadOnly">
            <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
          </div>
          <div matSuffix *ngIf="mobileIcons">
            <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
         </div>
        </mat-form-field>
        <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container>
      </div>
      <div *ngIf="customField.type == FType.Text && customField.isMultiLingual" class="mat-field-container" style="padding-top: 10px;">
        <mat-form-field [id]="customField.id" [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'"
          fxFlex floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field' : showInTable}">
          <mat-label *ngIf="fieldOptions.showPlaceholder">{{customField.isLongLabel && customField.isMultiLingual ? '' :  customField.label | translateToLocale}} (Arabic) <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <input [attr.data-type]="customField.type" matInput [readonly]="customField.isRealTime || customField.isReadOnly"
          placeholder="{{(fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}} (Arabic)" type="text"
          formControlName="isMultiLingualText" [(ngModel)]="customField.isMultiLingualText" [maxlength]="customField.max == 0 ? null : customField.max"
          [minlength]="customField.min == 0 ? null : customField.min" [required]="customField.isRequired" [disabled]="isDisabled || customField.isReadOnly"  [ngStyle]="{'text-align':'right'}" (blur)="onBlur(customField)">
          <div matSuffix *ngIf="customField?.isReadOnly">
            <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
          </div>
          <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
          
        </mat-form-field>
      </div>
      <div *ngIf="customField.type == FType.Rating">
        <app-esp-rating [attr.data-type]="customField.type" [customField]="customField" (isValid)="isValid.emit($event)" (isRating)="Rating($event)"
          [fieldOptions]="{ showLabel: fieldOptions.showPlaceholder && !showInTable}" [isLongLabel]="isLongLabel" (blur)="onBlur(customField)"></app-esp-rating>
      </div>

      <div *ngIf="customField.type == FType.MultiLine" class="mat-field-container">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [id]="customField.id" [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'"
          fxFlex floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field' : showInTable}">
          <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.isLongLabel && customField.isMultiLingual ? '' : customField.label | translateToLocale}}{{customField.isMultiLingual ? ' (English)' : ''}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <div matPrefix *ngIf="customField.isRealTime">
            <mat-icon style="font-size:22px;color:gray;margin-right:8px;">stars</mat-icon>
          </div>
          <textarea [attr.data-type]="customField.type" matInput placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
            [required]="customField.isRequired" [maxlength]="customField.max == 0 ? null : customField.max"
            [minlength]="customField.min == 0 ? null : customField.min" matTextareaAutosize="true"
            matAutosizeMinRows="3.19" matAutosizeMaxRows="10.19" (blur)="onBlur(customField)"></textarea>
            <div matSuffix *ngIf="customField?.isReadOnly">
              <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
            </div>
            <div matSuffix *ngIf="mobileIcons">
              <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
           </div>
        </mat-form-field>
        <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container>
      </div>
      <div *ngIf="customField.type == FType.MultiLine && customField.isMultiLingual" class="mat-field-container"   style="padding-top: 10px;">
        <mat-form-field [id]="customField.id" [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'"
          fxFlex floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}">
          <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder">{{customField.isLongLabel && customField.isMultiLingual ? '' :  customField.label | translateToLocale}} (Arabic) <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <textarea [attr.data-type]="customField.type" matInput placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
          formControlName="isMultiLingualMultiLine" [(ngModel)]="customField.isMultiLingualMultiLine" [disabled]="customField.isReadOnly" [ngStyle]="{'text-align':'right'}"
          [required]="customField.isRequired" [maxlength]="customField.max == 0 ? null : customField.max"
          [minlength]="customField.min == 0 ? null : customField.min" matTextareaAutosize="true"
          matAutosizeMinRows="3.19" matAutosizeMaxRows="10.19" (blur)="onBlur(customField)"></textarea>
          <div matSuffix *ngIf="customField?.isReadOnly">
            <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
          </div>
        </mat-form-field>
      </div>

      <div *ngIf="customField.type == FType.Password" class="mat-field-container" fxLayout="column">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [id]="customField.id" [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'"
          fxFlex floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}" class="password-with-icon" [ngClass]="{'compressed table-view-field' : showInTable}">
          <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}}
          </mat-label>
          <div matPrefix *ngIf="customField.isRealTime">
            <mat-icon style="font-size:22px;color:gray;margin-right:8px;">stars</mat-icon>
          </div>
          <input [attr.data-type]="customField.type" type="password" matInput [readonly]="customField.isRealTime"
            placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel) ? (customField.label | translateToLocale):''}}"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
            [required]="customField.isRequired"  [maxlength]="customField.max == 0 ? null : customField.max"
            [minlength]="customField.min == 0 ? null : customField.min" (blur)="onBlur(customField)"  [ngStyle]="{'text-align': customField.isMultiLingual ? 'left' : '' }"
            (click)="setFocus()" passwordToggle/>
            <div matSuffix *ngIf="customField?.isReadOnly">
              <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
            </div>
         <div matSuffix *ngIf="mobileIcons">
            <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
         </div>
        </mat-form-field>
      </div>

      <div *ngIf="customField.type == FType.Number" class="mat-field-container">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex
          floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field' : showInTable}">
          <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <div matPrefix *ngIf="customField.isRealTime">
            <mat-icon style="font-size:22px;color:gray;margin-right:8px;">stars</mat-icon>
          </div>
          <input [attr.data-type]="customField.type" type="number" matInput [readonly]="customField.isRealTime"
            placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
            [required]="customField.isRequired" [max]="customField.calculatedMaxVal== 0 ? null : customField.calculatedMaxVal"
            [min]="customField.calculatedMinVal" (blur)="onBlur(customField)" (keypress)="removeHyphensAllowNumbers($event)" />
            <div matSuffix *ngIf="customField?.isReadOnly">
              <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
            </div>
            <div matSuffix *ngIf="mobileIcons">
              <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
           </div>
        </mat-form-field>
        <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container>
      </div>
      <div *ngIf="customField.type == FType.DateTime" class="mat-field-container">
        <app-esp-date-time-picker
        [customFieldForm]="customFieldForm"
        [customField]="customField"
        [fieldOptions]="fieldOptions"
        [addBtnForBoxControl]="addBtnForBoxControl"
        [isLongLabel]="isLongLabel"
        [tempNesterFieldIndex]="tempNesterFieldIndex"
        [mobilefieldsicon]="mobilefieldsicon"
        (blur)="onBlur(customField)"
        [showInTable]="showInTable"></app-esp-date-time-picker>
      </div>
      <div *ngIf="customField.type == FType.Date" class="mat-field-container">

        <app-esp-date-picker *ngIf="customField.allowedValuesCriteria == 'dd/MM/yyyy' || customField.allowedValuesCriteria == '' || (customField.allowedValuesCriteria != 'MM/yyyy' && customField.allowedValuesCriteria != 'yyyy')"
        [customFieldForm]="customFieldForm"
        [customField]="customField"
        [fieldOptions]="fieldOptions"
        [addBtnForBoxControl]="addBtnForBoxControl"
        [isLongLabel]="isLongLabel"
        [tempNesterFieldIndex]="tempNesterFieldIndex"
        [mobilefieldsicon]="mobilefieldsicon"
        (blur)="onBlur(customField)"
        [showInTable]="showInTable"></app-esp-date-picker>

        <app-esp-date-month-year-picker *ngIf="customField.allowedValuesCriteria == 'MM/yyyy'"
        [customFieldForm]="customFieldForm"
        [customField]="customField"
        [fieldOptions]="fieldOptions"
        [addBtnForBoxControl]="addBtnForBoxControl"
        [isLongLabel]="isLongLabel"
        [tempNesterFieldIndex]="tempNesterFieldIndex"
        [mobilefieldsicon]="mobilefieldsicon"
        (blur)="onBlur(customField)"
        [showInTable]="showInTable"></app-esp-date-month-year-picker>

        <app-esp-date-year-picker *ngIf="customField.allowedValuesCriteria == 'yyyy'"
        [customFieldForm]="customFieldForm"
        [customField]="customField"
        [fieldOptions]="fieldOptions"
        [addBtnForBoxControl]="addBtnForBoxControl"
        [isLongLabel]="isLongLabel"
        [tempNesterFieldIndex]="tempNesterFieldIndex"
        [mobilefieldsicon]="mobilefieldsicon"
        (blur)="onBlur(customField)"
        [showInTable]="showInTable"></app-esp-date-year-picker>
      </div>

      <div *ngIf="customField.type == FType.DateRange" class="mat-field-container">
        <xcdrs-esp-date-range-picker
        [customFieldForm]="customFieldForm"
        [customField]="customField"
        [fieldOptions]="fieldOptions"
        [isLongLabel]="isLongLabel"
        [addBtnForBoxControl]="addBtnForBoxControl"
        [tempNesterFieldIndex]="tempNesterFieldIndex"
        [mobilefieldsicon]="mobilefieldsicon"
        [showInTable]="showInTable"></xcdrs-esp-date-range-picker>
      </div>

      <div *ngIf="customField.type == FType.SingleSelection" class="mat-field-container">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <!-- <div style="display: flex; flex-direction: row;">{{customField.lookupValues | json}} {{selectedColor | json}} {{customField.value}}</div> -->
        <mat-form-field [id]="customField.id" [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'"
          fxFlex floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field' : showInTable}" class="single-selection">
          <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <!-- <mat-icon [ngClass]="{'disabled': customField.isReadOnly}" *ngIf="customField.value" matSuffix
                class="option-cross" (click)="removeDate();">
        close
      </mat-icon> -->
          <mat-select [attr.data-type]="customField.type" placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
            [required]="customField.isRequired" (selectionChange)="onBlur(customField); onSingleSelectionChange($event)" [matTooltip]="getTooltipText()"
            matTooltipClass="esp-tooltip">
            <mat-select-trigger *ngIf="selectedColor">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                <div class="select-color-box" *ngIf="selectedColor.color && selectedColor?.color != 'none'" [style.background-color]="selectedColor?.color">
                </div>
                <div>{{selectedColor?.label | translateToLocale}}</div>
              </div>
            </mat-select-trigger>
            <mat-option>
              <ngx-mat-select-search ngModel #filter="ngModel" [ngModelOptions]="{standalone: true}"
                [placeholderLabel]="('Common.FIND'| translate) +' ' + (customField.label | translateToLocale) + ' ' +'...'"
                [noEntriesFoundLabel]="('Common.NO-MATCHING'| translate) +' ' + (customField.label | translateToLocale) + ' ' + ('Common.FOUND'| translate)">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="!customField.isRequired">
              <span translate="Common.None"></span>
            </mat-option>
            <mat-option style="padding-top:3px; padding-bottom:3px;"
              *ngFor="let lookup of customField.lookupValues | filterByProperty:['label',filter.value];"
              [matTooltip]="lookup.label | translateToLocale" matTooltipClass="esp-tooltip" value="{{lookup.id}}">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                <div class="select-color-box" *ngIf="lookup.color && lookup.color != 'none'" [style.background-color]="lookup.color">
                </div>
                <div>{{lookup.label | translateToLocale}}</div>
              </div>
          </mat-option>
          </mat-select>
          <div matSuffix fxLayout="row">
            <button mat-icon-button *ngIf="!customField.isReadOnly">
              <mat-icon>
                keyboard_arrow_down
              </mat-icon>
            </button>
            <div matSuffix *ngIf="customField?.isReadOnly">
              <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
            </div>
            <div matSuffix *ngIf="mobileIcons">
              <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
            </div>
          </div>

        </mat-form-field>
        <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container>
      </div>

      <!--<mat-form-field fxFlex *ngIf="customField.type == FType.LookupAddon"
                    floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}">
      <mat-icon matSuffix>
        keyboard_arrow_down
      </mat-icon>
      <mat-select placeholder="{{(fieldOptions.showPlaceholder)?(customField.label | translateToLocale):''}}"
                  formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
                  [required]="customField.isRequired" (selectionChange)="onBlur(customField);onLookupSelection($event);" #lookupSelect>
        <ngx-mat-select-search ngModel #filter="ngModel" [ngModelOptions]="{standalone: true}"
                               [placeholderLabel]="('Common.FIND'| translate) +' ' + (customField.label | translateToLocale) + ' ' + '...'"
                               [noEntriesFoundLabel]="('Common.NO-MATCHING'| translate) +' ' + (customField.label | translateToLocale) + ' ' + ('Common.FOUND'| translate)">
        </ngx-mat-select-search>
        <cdk-virtual-scroll-viewport itemSize="50" minBufferPx="200" maxBufferPx="400" class="virtual-scroll-200px" #virtualScrollViewPort>
          <mat-option style="padding-top:3px; padding-bottom:3px;"
                      *cdkVirtualFor="let lookup of lookUpOptions | filterByProperty:['name',filter.value];" value="{{lookup.id}}">
            {{lookup.name | translateToLocale}}
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-select>
    </mat-form-field>-->
    <div *ngIf="customField.type == FType.LookupAddon" class="mat-field-container">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex
          floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field' : showInTable}">
          <mat-label *ngIf="(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel) || (fieldOptions.showPlaceholder && customField.isLongLabel && formBuilderFieldSettings)">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <!-- <mat-icon [ngClass]="{'disabled': customField.isReadOnly}" *ngIf="customField.value" matSuffix
        class="option-cross" (click)="removeDate();">
        close
      </mat-icon> -->
          <mat-select *ngIf="!customField.isMultipleAllowed || formBuilderFieldSettings" [attr.data-type]="customField.type" placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
            [required]="formBuilderFieldSettings? false : customField.isRequired" (selectionChange)="onBlur(customField)" #lookupSelect
            [matTooltip]="getTooltipText()" matTooltipClass="esp-tooltip">
            <mat-option>
              <ngx-mat-select-search ngModel [ngModelOptions]="{standalone: true}" #ngxSearch
                [placeholderLabel]="('Common.FIND'| translate) +' ' + (customField.label | translateToLocale) + ' ' + '...'"
                [noEntriesFoundLabel]="('Common.NO-MATCHING'| translate) +' ' + (customField.label | translateToLocale) + ' ' + ('Common.FOUND'| translate)">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="!customField.isRequired">
              <span translate="Common.None"></span>
            </mat-option>
            <mat-option *ngIf="customField.lookupType == 'Definitions' && formBuilderFieldSettings" (click)="opentagableInput()">
              Map Value
            </mat-option>
            <mat-option style="padding-top:3px; padding-bottom:3px;" *ngFor="let lookup of lookUpOptions"
              value="{{lookup.id}}" [title]="lookup.name | translateToLocale">
              {{lookup.name | translateToLocale}}
            </mat-option>
          </mat-select>
          <mat-select *ngIf="customField.isMultipleAllowed && !formBuilderFieldSettings" [attr.data-type]="customField.type" placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
            [required]="formBuilderFieldSettings? false : customField.isRequired" (selectionChange)="onBlur(customField)" #lookupSelect
            multiple panelClass="esp-multi-select" [matTooltip]="triggerText" matTooltipClass="esp-tooltip">
            <mat-option>
              <ngx-mat-select-search ngModel [ngModelOptions]="{standalone: true}" #ngxSearch
                [placeholderLabel]="('Common.FIND'| translate) +' ' + (customField.label | translateToLocale) + ' ' + '...'"
                [noEntriesFoundLabel]="('Common.NO-MATCHING'| translate) +' ' + (customField.label | translateToLocale) + ' ' + ('Common.FOUND'| translate)">
              </ngx-mat-select-search>
            </mat-option>
            <mat-select-trigger>
              {{triggerText}}
            </mat-select-trigger>
            <mat-option *ngIf="!customField.isRequired" (click)="disSelectAllSelection()">
              <span translate="Common.None"></span>
            </mat-option>
            <mat-option [value]="-1" (click)="toggleAllSelection()">
              <ng-container>All</ng-container>
            </mat-option>
            <mat-option style="padding-top:3px; padding-bottom:3px;"  (click)="toggleItem()" *ngFor="let lookup of lookUpOptions"
              [value]="lookup.id" [title]="lookup.name | translateToLocale">
              {{lookup.name | translateToLocale}}
            </mat-option>
          </mat-select>
          <div matSuffix fxLayout="row">
            <button mat-icon-button *ngIf="showDynamicSpinner && customField.rollup.conditions.length > 0">
              <mat-spinner class="spinner" diameter="20"></mat-spinner>
            </button>
            <button mat-icon-button *ngIf="!customField.isReadOnly">
              <mat-icon>
                keyboard_arrow_down
              </mat-icon>
            </button>
            <mat-icon matSuffix style="font-size:22px;color:rgba(0, 0, 0, 0.2);margin-right:8px;" *ngIf="customField.isReadOnly">
              lock</mat-icon>
            <div matSuffix *ngIf="mobileIcons">
              <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
            </div>
          </div>

        </mat-form-field>
        <!-- <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container> -->
      </div>
      <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex
        *ngIf="customField.type == FType.SubApplication"
        floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}">
        <mat-label *ngIf="fieldOptions.showPlaceholder">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
        </mat-label>
        <mat-icon matSuffix>
          keyboard_arrow_down
        </mat-icon>
        <mat-select [attr.data-type]="customField.type" placeholder="{{(fieldOptions.showPlaceholder)?(customField.label | translateToLocale):''}}"
          formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
          [required]="customField.isRequired">
          <mat-option>
            <ngx-mat-select-search ngModel #filter="ngModel" [ngModelOptions]="{standalone: true}"
              [placeholderLabel]="('Common.FIND'| translate) +' ' + (customField.label | translateToLocale) + ' ' +'...'"
              [noEntriesFoundLabel]="('Common.NO-MATCHING'| translate) +' ' + (customField.label | translateToLocale) + ' ' + ('Common.FOUND'| translate)">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option style="padding-top:3px; padding-bottom:3px;"
            *ngFor="let subApplication of subApplicationOptions | filterByProperty:['definitionName',filter.value];"
            value="{{subApplication.id}}">
            {{subApplication.definitionName | translateToLocale}} -
            {{subApplication.applicantName | translateToLocale}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div style="display:grid;" fxLayout="column" *ngIf="customField.type == FType.MultiSelection" class="cutom-multi-selection">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <label class="wizart-field-label margin-t1" *ngIf="fieldOptions.floatLabel != 'never' && !customField.isLongLabel">
          {{(customField.label | translateToLocale)}} <span *ngIf="customField.isRequired">*</span>
          <mat-icon matSuffix *ngIf="customField.hint && showHint" fxHide.gt-xs="true" fontSet="material-icons-outlined"
            class="color-grey cursor-pointer vertically-middle f-20" [matTooltip]="customField.hint | translateToLocale"
            matTooltipClass="esp-tooltip" matTooltipPosition="above">info</mat-icon>
          <mat-icon matSuffix style="font-size:18px;color:rgba(0, 0, 0, 0.2);margin-right:8px;" *ngIf="customField.isReadOnly">
            lock</mat-icon>
        </label>
        <mat-checkbox [attr.data-type]="customField.type" class="margin-b5" *ngFor="let lookup of customField.lookupValues"
          formControlName="customFieldName{{lookup.id}}" id="customFieldId{{customField.id}}{{lookup.id}}"
          [checked]="lookup.isSelected" [(ngModel)]="lookup.isSelected" [required]="customField.isRequired"
          (change)="checkboxChange(lookup, customField, customField.isRequired, 'customFieldName'+customField.id)">
          {{lookup.label | translateToLocale}}
        </mat-checkbox>
        <mat-error class="custom-error-message" style="display:none;" id="customFieldName{{customField.id}}">
          Please select atleast one checkbox
        </mat-error>
      </div>

      <div *ngIf="(customField.type == FType.Attachment || customField.type == FType.Image ) && !customField.isReadOnly" fxLayout="column">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <label class="wizart-field-label margin-t1" *ngIf="!showInTable && fieldOptions.floatLabel != 'never' && !customField.isLongLabel">
          {{(customField.label | translateToLocale)}} <span *ngIf="customField.isRequired">*</span> <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
        </label>
        <div [ngClass]="{'file-upload-portion': !showInTable, 'table-view-attachment': showInTable}">
          <div fxLayout="column">
            <div fxLayout="row" fxLayoutGap="22px" *ngIf="!customField.value && (!isDisabled || customField.isReadOnly)">
              <!--<button class="sign-in-btn">Sign In</button>-->
              <button [disabled]="isDisabled" mat-flat-button class="gradient-btn"
                color="primary" class="btn-upload" (click)="openFileUploader()">
                <mat-icon>attach_file</mat-icon>
                {{ (customField.type == FType.Attachment ? "ATTACH"  : "UPLOAD-IMAGE") |translate}}
              </button>
              <div *ngIf="!customField.isExt && customField.isMultipleAllowed && isLastNestedField" fxFlex fxLayoutAlign="start center">
                <button data-action="addMultiField" mat-icon-button class="btn-add-field" (click)="emitAddNestedField()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
              <div matSuffix *ngIf="mobileIcons" fxFlex fxLayoutAlign="end center" class="mobile-fieldattachment-space">
                <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
              </div>
            </div>
            <div fxFlex class="file-detail" *ngIf="customField.value">
              <div *ngIf="customField.type == FType.Attachment" class="file" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxLayoutAlign="center center" class="extension">
                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.66797 1.33203H2.0013C1.64768 1.33203 1.30854 1.47251 1.05849 1.72256C0.808444 1.9726 0.667969 2.31174 0.667969 2.66536V13.332C0.667969 13.6857 0.808444 14.0248 1.05849 14.2748C1.30854 14.5249 1.64768 14.6654 2.0013 14.6654H10.0013C10.3549 14.6654 10.6941 14.5249 10.9441 14.2748C11.1942 14.0248 11.3346 13.6857 11.3346 13.332V5.9987M6.66797 1.33203L11.3346 5.9987M6.66797 1.33203V5.9987H11.3346"
                        stroke="#AAADB8" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div fxLayout="column">
                    <div *ngIf="customField.value ? true : false" fxLayout="row" fxLayoutGap="5px">
                      <a *ngIf="customField.details && customField.details?.downloadUrl"
                        [downloadFile]="customField.details?.downloadUrl" download
                        class="theme-primary-color full-width">{{customFieldService.getFileNameWithoutExtension(customField.details?.name)}}
                        .{{customFieldService.getFileExtention(customField.details?.name).toLowerCase()}}</a>
                      <span class="file-name-exceeder full-width word-break"
                        *ngIf="!customField.details || !customField.details?.downloadUrl">
                        <div class="name">
                          {{customFieldService.getFileNameWithoutExtension(customField.details?.name)}}
                        </div>
                        .{{customFieldService.getFileExtention(customField.details?.name).toLowerCase()}}
                      </span>
                
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="5px">
                      <div class="file-details-exceeder">
                        {{customField.details?.size}}
                      </div>
                      <div matSuffix *ngIf="mobileIcons">
                        <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="customField.details?.name && !isDisabled || customField.isReadOnly">
                  <mat-icon class="color-gray cursor-pointer"
                   (click)="removeAttachedFile(customField)">
                    delete_outline
                  </mat-icon>
                </div>
              </div>
              <div class="file" fxLayout="row" fxLayoutAlign="start start" *ngIf="customField.type == FType.Image">
                <img class="image-type-preview" [src]="customField.details.downloadUrl | secure | async">
                <div fxLayout="row" fxLayoutAlign="end start" fxFlex>
                  <mat-icon class="color-gray cursor-pointer" *ngIf="customField.details?.name && !isDisabled || customField.isReadOnly"
                   (click)="removeAttachedFile(customField)">
                    delete_outline
                  </mat-icon>
                </div>
                <div fxFlex fxLayoutAlign="end center" matSuffix *ngIf="mobileIcons">
                  <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
                </div>
              </div>

              <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container>
            </div>
            <div fxFlex class="file-detail" *ngIf="!customField.value && isDisabled || customField.isReadOnly">
              <div class="file">
                <span class="small-font">
                  <span>No file attached</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <input [attr.data-type]="customField.type" style="display:none" #inputTypeFile type="file" class="custom-textbox"
          [(ngModel)]="customField.fileContent" formControlName="customFieldName{{customField.id}}"
          (change)="pushFile($event,customField)"
          [requireFile]="customField.isRequired ? customField.details?.name ? false : true : false"
          [accept]="customField.allowedValuesCriteria" />
        <mat-error class="attachmentError" style="display:none">
          {{"Error.Value-Error" | translate}} {{customField.max}}
        </mat-error>
      </div>

      <div *ngIf="customField.type == FType.Gallery">
          <app-esp-multi-file-uploader
          [fieldOptions]="{ showLabel: fieldOptions.showPlaceholder }"
          [customFieldForm]="customFieldForm"
          [customField]="customField"
          [addBtnForBoxControl]="addBtnForBoxControl"
          [isLongLabel]="isLongLabel"
          [tempNesterFieldIndex]="tempNesterFieldIndex"
          [mobilefieldsicon]="mobilefieldsicon"
          [doFileUploadOnChange]="doFileUploadOnChange"
          [isDisabled]="isDisabled"
          [showHintIconSpace]="showHintIconSpace"
          [showHint]="showHint"
          (isValid)="isValid.emit($event)"
          (isCallback)="isCallback.emit($event)"
          (blur)="onBlur(customField)"
          (isFileUploaded)="isFileUploaded.emit($event)"></app-esp-multi-file-uploader>
      </div>

      <div
        *ngIf="(customField.type == FType.Attachment && customField.isReadOnly) || ((customField.type == FType.Calculated || customField.type == FType.Mapped) && customField.targetFieldType == FType.Attachment)">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" floatLabel="always" fxFlex>
          <mat-label *ngIf="fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <input [attr.data-type]="customField.type" matInput type="text" style="display:none" disabled />
          <a *ngIf="customField.details?.downloadUrl" [downloadFile]="customField.details?.downloadUrl" class="mapped-attachment">
            <div fxLayout="column">
              <div>
                <span class="file-name-exceeder">
                  {{customFieldService.getFileNameWithoutExtension(customField.details?.name)}}
                </span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <div>
                  <img src="../../../assets/images/{{getIconFileExtention(customField.details?.name)}}"
                    class="icon-extention-exceeder" />
                </div>
                <div class="file-details-exceeder">
                  {{customFieldService.getFileExtention(customField.details?.name)}}
                </div>
              </div>
            </div>
          </a>
          <mat-icon matSuffix style="font-size:22px;color:rgba(0, 0, 0, 0.2);margin-right:8px;" *ngIf="customField.isReadOnly">
            lock</mat-icon>
          <div style="color: rgba(0, 0, 0, 0.54);" *ngIf="customField.details?.downloadUrl == '' || customField.details?.downloadUrl == null">No file attached</div>
        </mat-form-field>
      </div>

      <div
        *ngIf="(customField.type == FType.Image && customField.isReadOnly) || ((customField.type == FType.Calculated || customField.type == FType.Mapped) && customField.targetFieldType == FType.Image) ">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" floatLabel="always" fxFlex>
          <mat-label *ngIf="fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <input [attr.data-type]="customField.type" matInput type="text" style="display:none" disabled />
          <span style="color: rgba(0, 0, 0, 0.54);"  *ngIf="customField.details?.downloadUrl == null">No Image to Show</span>
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <img class="image-type-preview" [src]="customField.details?.downloadUrl | secure | async" style="height: 120px;">
            <mat-icon matSuffix style="font-size:22px;color:rgba(0, 0, 0, 0.2);margin-right:8px;" *ngIf="customField.isReadOnly">
            lock</mat-icon>
          </div>
        </mat-form-field>
      </div>

      <label fxLayout="row" fxLayoutAlign="start center" *ngIf="!showInTable && customField.type == FType.Price && fieldOptions.floatLabel != 'never'" class="wizart-field-label margin-t1 price-label">
        {{(customField.label | translateToLocale)}} <span *ngIf="customField.isRequired">*</span> <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
        <div matSuffix *ngIf="mobileIcons">
          <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
        </div>
      </label>
      <div fxLayout="column" fxLayoutAlign="space-between center"  *ngIf="customField.type == FType.Money || customField.type == FType.Price" >
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <div fxLayout="row" fxLayoutAlign="space-between center"
         class="mat-field-container full-width"
          [ngClass]="{'price-container' : customField.type == FType.Price }">
          <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'"
            [fxFlex.gt-xs]="customField.type == FType.Price && callFrom == '' ? '175px' : customField.type == FType.Price && callFrom == 'criteria' ? '62%' : ''"
            [fxFlex]="customField.type == FType.Price ? '48%' : ''"
            floatLabel="{{isDisabled ? 'always' :  fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field tw-overflow-hidden' : showInTable}" class="currency-control-container">
            <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && customField.type == FType.Money && !customField.isLongLabel">
              {{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
            </mat-label>
            <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && customField.type == FType.Price && !customField.isLongLabel">
              Price
            </mat-label>
            <!--<span matPrefix>{{selectedCurrency?.symobl}}&nbsp;</span>-->
            <input [attr.data-type]="customField.type" type="number" class="textbox-no-arrows" matInput
              (keypress)="removeHyphensAllowNumbers($event)"
              placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel && customField.type == FType.Money)?(customField.label | translateToLocale):''}}"
              formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
              [required]="customField.isRequired" [customMax]="customField.max" [customMin]="customField.min"
              currencyValidator [selectedCurrencyId]="customField.selectedCurrencyId"
              (blur)="onBlur(customField)" />
              <div matSuffix fxLayout="row" fxLayoutAlign="center center">
                <div matSuffix class="currency-control" fxLayout="row" fxLayoutAlign="center center">
                  <div *ngIf="!customField.calculatedCurrency">
                    <span>{{getSelectedCurrencySymbol()}}</span>
                  </div>
                  <div *ngIf="customField.calculatedCurrency">
                    <span>{{customField.allowedCurrencies[0].code}}</span>
                  </div>
                </div>
                <mat-icon matSuffix style="font-size:22px;color:rgba(0, 0, 0, 0.2);margin:0 6px 0 8px;" *ngIf="customField.isReadOnly">
                  lock</mat-icon>
              <div>
                <button mat-icon-button [matMenuTriggerFor]="menuOptionsCurrency" *ngIf="!isDisabled">
                  <mat-icon class="icon-custom-color">keyboard_arrow_down</mat-icon>
                </button>
                <mat-menu #menuOptionsCurrency="matMenu" [overlapTrigger]="false">
                  <ng-container *ngIf="customField.calculatedCurrency">
                    <button  mat-menu-item *ngFor="let currency of customField.allowedCurrencies" (click)="changeSelectedCurrency(currency)">
                      {{currency.code}}
                    </button>
                  </ng-container>
                  <ng-container *ngIf="!customField.calculatedCurrency">
                    <button mat-menu-item *ngFor="let currency of allowedCurrencies" (click)="changeSelectedCurrency(currency)">
                      {{currency.code}}
                    </button>
                  </ng-container>
                  
                </mat-menu>
              </div>
            </div>
          </mat-form-field>
          <mat-form-field *ngIf="customField.type == FType.Price"
            [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex.gt-xs="175px" fxFlex="49.5%"
            floatLabel="{{isDisabled ? 'always' :  fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field tw-overflow-hidden' : showInTable}" class="currency-control-container">
            <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder">Unit <ng-container *ngTemplateOutlet="tempNesterFieldIndex">
              </ng-container>
            </mat-label>
            <mat-select [required]="customField.isRequired" (selectionChange)="onBlur(customField)"
              placeholder="Unit"
              [(ngModel)]="customField.selectedPriceUnit" [required]="customField.isRequired || customField.value > 0"
              formControlName="priceUnit" panelClass="unitField" #matSelect [matTooltip]="matSelect.triggerValue"
              matTooltipClass="esp-tooltip" matTooltipPosition="above" [disabled]="isDisabled">
              <mat-option [value]="unit" *ngFor="let unit of priceUnits">
                {{unit}}
              </mat-option>
            </mat-select>
            <div matSuffix fxLayout="row" *ngIf="!isDisabled">
              <button mat-icon-button>
                <mat-icon>
                  keyboard_arrow_down
                </mat-icon>
              </button>
            </div>
          </mat-form-field>
          <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container>
        </div>
      </div>

      <div *ngIf="customField.type == FType.Email" class="mat-field-container">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex
          floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field' : showInTable}">
          <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <input [attr.data-type]="customField.type" matInput (blur)="onBlur(customField)" type="email"
            placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
            [required]="customField.isRequired" [email]="customField?.value?.length > 0 ? true : false">
            <div matSuffix *ngIf="customField?.isReadOnly">
              <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
            </div>
            <div matSuffix *ngIf="mobileIcons">
              <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
           </div>
        </mat-form-field>
        <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container>
      </div>

      <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex
        *ngIf="customField.type == FType.RollUp" floatLabel="always" [ngClass]="{'compressed table-view-field' : showInTable}">
        <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}}
        </mat-label>
        <input [attr.data-type]="customField.type" matInput type="text"
          placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
          formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value" readonly>
        <div matSuffix *ngIf="customField?.isReadOnly">
          <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
        </div>
        <!-- <mat-icon class="calculateRollup-icon">
        <span>
          <img src="../../../assets/images/ic_show_rollup.png" alt="">
        </span>
      </mat-icon> -->
      </mat-form-field>
      <ng-container *ngIf="customField.type == FType.Calculated || customField.type == FType.Mapped">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex floatLabel="always"
          *ngIf="customField.targetFieldType != FType.Hyperlink && customField.targetFieldType != FType.Attachment && customField.targetFieldType != FType.Image">
          <mat-label *ngIf="fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <input [attr.data-type]="customField.type" matInput type="text" formControlName="customFieldName{{customField.id}}"
            [(ngModel)]="customField.value" readonly>
          <div matSuffix *ngIf="customField?.isReadOnly">
            <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
          </div>
          <mat-icon matSuffix *ngIf="customField.hint && showHint" fxHide.gt-xs="true" fontSet="material-icons-outlined"
            class="color-grey cursor-pointer" [matTooltip]="customField.hint | translateToLocale"
            matTooltipClass="esp-tooltip" matTooltipPosition="above">info</mat-icon>

        </mat-form-field>
      </ng-container>

      <div *ngIf="customField.type == FType.Hyperlink && hyperlinkLabelEnabled" class="mat-field-container" style="padding-top: 10px;">
        <mat-form-field [id]="customField.id" [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'"
          fxFlex floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}">
          <mat-label *ngIf="fieldOptions.showPlaceholder">{{customField.label | translateToLocale}} Title <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <input [attr.data-type]="customField.type" matInput
          placeholder="{{fieldOptions.showPlaceholder?(customField.label | translateToLocale):''}} Title" type="text"
          formControlName="hyperlinkTitle" [(ngModel)]="customField.hyperlinkTitle" [required]="customField.value != ''" (blur)="onBlur(customField)">
          <div matSuffix *ngIf="customField?.isReadOnly">
            <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
          </div>
        </mat-form-field>
      </div>
      <div *ngIf="customField.type == FType.Hyperlink && !customField.isReadOnly" class="mat-field-container">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex
          floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}">
          <mat-label *ngIf="fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <input [attr.data-type]="customField.type" matInput (keyup)="onKeyup()"
            placeholder="{{(fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}" type="url"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
            [required]="customField.isRequired" CustomHyperLink (blur)="onBlur(customField)">
          <div matSuffix *ngIf="customField?.isReadOnly">
            <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
          </div>
          <div matSuffix *ngIf="mobileIcons">
              <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
           </div>
        </mat-form-field>
        <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container>
      </div>
      <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" floatLabel="always" fxFlex
        *ngIf="(customField.type == FType.Hyperlink && customField.isReadOnly) || ((customField.type == FType.Calculated || customField.type == FType.Mapped) && customField.targetFieldType == FType.Hyperlink)">
        <mat-label *ngIf="fieldOptions.showPlaceholder">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
        </mat-label>
        <input [attr.data-type]="customField.type" matInput type="text" style="display:none" disabled />
        <div matSuffix *ngIf="customField?.isReadOnly">
          <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
        </div>
        <a *ngIf="customField.value" fxFlex target="_blank" rel="noopener noreferrer" class="hyper-link"
          name="{{'field' + customField.id}}" href="{{customField.value}}" disabled>{{customField.value}}</a>
      </mat-form-field>

      

      <div *ngIf="customField.type == FType.PhoneNumber" class="mat-field-container">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex
          floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field' : showInTable}">
          <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </mat-label>
          <input [attr.data-type]="customField.type" matInput (blur)="onBlur(customField)" maxlength="15"
            placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}" type="tel"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value"
            [required]="customField.isRequired" CustomPhoneNumber>
            <div matSuffix *ngIf="customField?.isReadOnly">
              <ng-container *ngTemplateOutlet="canUnLockMenu"></ng-container>
            </div>
            <div matSuffix *ngIf="mobileIcons">
              <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
           </div>
        </mat-form-field>
        <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container>
      </div>

      <app-custom-note-field [attr.data-type]="customField.type" *ngIf="customField.type == FType.Note" [customField]="customField"></app-custom-note-field>

      <app-esp-map [attr.data-type]="customField.type" *ngIf="customField.type == FType.Map" [customField]="customField" (isValid)="isValid.emit($event)"
      [fieldOptions]="{ showLabel: fieldOptions.showPlaceholder }" [addBtnForBoxControl]="addBtnForBoxControl" [isLongLabel]="isLongLabel"></app-esp-map>
      
      <div *ngIf="customField.type == FType.Aggregator" fxLayout="column">
        <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
        <mat-form-field [id]="customField.id" [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'" fxFlex
          floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}" [ngClass]="{'compressed table-view-field' : showInTable}">
          <mat-label *ngIf="!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}}
          </mat-label>
          <div matPrefix>
            <mat-icon style="font-size:22px;color:gray;margin-right:8px;">stars</mat-icon>
          </div>
          <input [attr.data-type]="customField.type" type="text" matInput [readonly]="true"
            placeholder="{{(!showInTable && fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
            formControlName="customFieldName{{customField.id}}" [(ngModel)]="customField.value" />
          <mat-icon matSuffix *ngIf="customField.hint && showHint" fxHide.gt-xs="true" fontSet="material-icons-outlined"
            class="color-grey cursor-pointer" [matTooltip]="customField.hint | translateToLocale" matTooltipClass="esp-tooltip"
            matTooltipPosition="above">info</mat-icon>
        </mat-form-field>
      </div>

        <div *ngIf="customField.type == FType.Tags && !forFilterView" class="mat-field-container">
          <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
          <mat-form-field [id]="customField.id" [appearance]="fieldOptions.showPlaceholder ? 'outline' : 'standard'"
            fxFlex floatLabel="{{isDisabled ? 'always' : fieldOptions.floatLabel}}">
            <mat-label *ngIf="fieldOptions.showPlaceholder && !customField.isLongLabel">{{customField.label | translateToLocale}}<span *ngIf="customField.isRequired">*</span><ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
            </mat-label>
            <div matPrefix *ngIf="customField.isRealTime">
              <mat-icon style="font-size:22px;color:gray;margin-right:8px;">stars</mat-icon>
            </div>
            <mat-icon matSuffix style="font-size:22px;color:rgba(0, 0, 0, 0.2);margin-right:8px;" *ngIf="customField.isReadOnly">
              lock</mat-icon>
            <mat-chip-list #chipList aria-label="Fruit selection" class="idenedi-users-mat-chip-list" [disabled]="customField.isReadOnly">
              <mat-chip *ngFor="let tag of tags" [selectable]="true" [removable]="true"
                (removed)="removeTag(tag)">
                {{tag}}
                <button matChipRemove>
                  <mat-icon class="remove-chip">cancel</mat-icon>
                </button>
              </mat-chip>
              <input [attr.data-type]="customField.type" type="text" matInput [readonly]="customField.isRealTime"
              placeholder="{{(fieldOptions.showPlaceholder && !customField.isLongLabel)?(customField.label | translateToLocale):''}}"
              formControlName="customFieldName{{customField.id}}"
              (blur)="onBlur(customField)"
              (click)="setFocus()"
              #tagInput [matAutocomplete]="auto" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addTag($event)"/>
              <!-- <input placeholder="Search User" #fruitInput [formControl]="textCtrl" [matAutocomplete]="auto"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"> -->
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
              <mat-option *ngFor="let tag of filteredSystemTags" [value]="tag.name">
                {{tag.name}}
              </mat-option>
            </mat-autocomplete>
           <div matSuffix *ngIf="mobileIcons">
              <ng-container *ngTemplateOutlet="mobilefieldsicon"></ng-container>
           </div>

          </mat-form-field>
          <ng-container *ngTemplateOutlet="addBtnForBoxControl"></ng-container>
        </div>
        <div *ngIf="customField.type == FType.Tags && forFilterView" class="mat-field-container">
          <ng-container *ngTemplateOutlet="isLongLabel"></ng-container>
          <label *ngIf="fieldOptions.floatLabel != 'never' && !customField.isLongLabel" class="wizart-field-label margin-t1 price-label">
            {{(customField.label | translateToLocale)}} <span *ngIf="customField.isRequired">*</span> <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container>
          </label>
          <mat-icon matSuffix style="font-size:22px;color:rgba(0, 0, 0, 0.2);margin-right:8px;" *ngIf="customField.isReadOnly">
            lock</mat-icon>
          <mat-chip-list #chipList aria-label="Fruit selection" class="idenedi-users-mat-chip-list" [disabled]="customField.isReadOnly">
            <mat-chip *ngFor="let tag of systemTags" [selectable]="true" (click)="selectTagForFilter(tag.name)" class="theme-chip" [ngClass]="{'active' : checkIfTagExists(tag.name)}">
              {{tag.name}}
            </mat-chip>
          </mat-chip-list>
          <div fxLayout="row" fxLayoutAlign="center center" *ngIf="systemTagsLoading">
            <div fxFlex>
              <mat-spinner class="margin-auto" [diameter]="40"></mat-spinner>
            </div>
          </div>
        </div>
        <div  *ngIf="customField.type == FType.RichText && !expandEditor" class="esp-quill-editor-container">
          <div class="rich-text-overlay" style="position: absolute;
          z-index: 100;
          width: 382px;
          height: 240px;" (click)="openEditor()"></div>
          <app-esp-quill-editor 
          [customFieldForm]="customFieldForm" 
          [customField]="customField"
          [isExpanded]="false"
          (blur)="onBlur(customField)" 
          (isValid)="isValid.emit($event)"
          class="esp-quill-editor"></app-esp-quill-editor>
        </div>
      <div
        *ngIf="customFieldForm.controls[formFieldName].touched || (customField.value && customField.value.length > 0)">
        <mat-error
          *ngIf="customFieldForm.controls[formFieldName].errors?.required && customField.type != FType.Attachment && customField.type != FType.Image">
          {{"Error.Required" | translate}}
        </mat-error>
        <mat-error fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" class="note-field"
        *ngIf="customFieldForm.controls[formFieldName].errors?.fieldNotUnique && customField.type != FType.Attachment && customField.type != FType.Image">
        <div class="note-field-label">
          {{notUniqueMsg}}
        </div>
      </mat-error>
      <!-- <div fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" class="note-field" >
        <div class="note-field-label">
          {{customField.label | translateToLocale}}
        </div>
      </div> -->
        <mat-error
          *ngIf="customFieldForm.controls[formFieldName].errors?.endDateMissing">
            {{"Application.End-Date-Missing" |translate}}
        </mat-error>
        <mat-error
          *ngIf="customField.max != null && customField.max != '' && customField.max != 0 && customField.min != null && customField.min != '' && customField.min != 0 && (customFieldForm.controls[formFieldName].errors?.minlength || customFieldForm.controls[formFieldName].errors?.maxlength || customFieldForm.controls['isMultiLingualText']?.errors?.maxlength || customFieldForm.controls['isMultiLingualText']?.errors?.minlength|| customFieldForm.controls['isMultiLingualMultiLine']?.errors?.maxlength || customFieldForm.controls['isMultiLingualMultiLine']?.errors?.minlength)">
          {{"Error.Value-Between" | translate}} {{customField.min}} {{"Error.and" | translate}}
          {{customField.max}}
          {{"Error.Characters" | translate}}
        </mat-error>
        <mat-error
          *ngIf="(customField.max == null || customField.max == 0) && (customFieldForm.controls[formFieldName].errors?.minlength || customFieldForm.controls['isMultiLingualText']?.errors?.minlength || customFieldForm.controls['isMultiLingualMultiLine']?.errors?.minlength)">
          {{"Error.Greater-Equal" | translate}} {{customField.min}} {{"Error.Characters" | translate}}
        </mat-error>
        <mat-error
          *ngIf="(customField.min == null || customField.min == 0) && (customFieldForm.controls[formFieldName].errors?.maxlength || customFieldForm.controls['isMultiLingualText']?.errors?.maxlength || customFieldForm.controls['isMultiLingualMultiLine']?.errors?.maxlength)">
          {{"Error.Less-Equal" | translate}} {{customField.max}} {{"Error.Characters" | translate}}
        </mat-error>

        <mat-error
          *ngIf="customField.max != null && customField.max != '' && customField.max != 0 && customField.min != null && customField.min != '' && customField.min != 0 && (customFieldForm.controls[formFieldName].errors?.customMin || customFieldForm.controls[formFieldName].errors?.customMax)">
          {{"Error.Value-Between" | translate}} {{customField.min}} {{"Error.and" | translate}}
          {{customField.max}}
        </mat-error>
        <mat-error
          *ngIf="(customField.max == null || customField.max == 0) && customFieldForm.controls[formFieldName].errors?.customMin">
          {{"Error.Greater-Equal" | translate}} {{customField.min ? customField.min : '0'}}
        </mat-error>
        <mat-error
          *ngIf="(customField.min == null || customField.min == 0) && customFieldForm.controls[formFieldName].errors?.customMax">
          {{"Error.Less-Equal" | translate}} {{customField.min}}
        </mat-error>

        <mat-error
          *ngIf="customField.type == FType.Number && customField.max != null && customField.max != '' && customField.max != 0 && customField.min != null && customField.min != '' && customField.min != 0 && (customFieldForm.controls[formFieldName].errors?.min || customFieldForm.controls[formFieldName].errors?.max)">
          {{"Error.Value-Between" | translate}} {{customField.calculatedMinVal}} {{"Error.and" | translate}}
          {{customField.calculatedMaxVal}}
        </mat-error>
        <mat-error
          *ngIf="customField.type == FType.Number && (customField.max == null || customField.max == 0) && customFieldForm.controls[formFieldName].errors?.min">
          {{"Error.Greater-Equal" | translate}} {{customField.calculatedMinVal ? customField.calculatedMinVal : '0'}}
        </mat-error>
        <mat-error
          *ngIf="customField.type == FType.Number && (customField.min == null || customField.min == 0) && customFieldForm.controls[formFieldName].errors?.max">
          {{"Error.Less-Equal" | translate}} {{customField.calculatedMaxVal}}
        </mat-error>
        <mat-error *ngIf="customField.type == FType.Date && customFieldForm.controls[formFieldName].errors?.matDatepickerMin">
          {{"Error.Min-Date" | translate}}
          {{customField.calculatedMinVal | date:'MM/dd/yyyy':undefined:translate.currentLang}}.
        </mat-error>
        <mat-error *ngIf="customField.type == FType.Date && customFieldForm.controls[formFieldName].errors?.matDatepickerMax">
          {{"Error.Max-Date" | translate}}
          {{customField.calculatedMaxVal | date:'MM/dd/yyyy':undefined:translate.currentLang}}.
        </mat-error>

        <!--<mat-error *ngIf="customFieldForm.controls[formFieldName].errors?.customEmailValidator">{{"RequiredMessages.EMAIL-INVALID" | translate}}</mat-error>-->
        <mat-error *ngIf="customFieldForm.controls[formFieldName].errors?.email">

          {{"RequiredMessages.EMAIL-INVALID" | translate}}
        </mat-error>
        <mat-error
          *ngIf="customField.value != null && customField.value != '' && customFieldForm.controls[formFieldName].errors?.Hyperlink">
          {{getHyperLinkErrorMessage()}}
        </mat-error>
        <mat-error
          *ngIf="customField.value != null && customField.value != '' && customFieldForm.controls[formFieldName].errors?.PhoneNumber">
          {{getPhoneNumberErrorMessage()}}
        </mat-error>
      </div>

      <div *ngIf="customField.type == FType.Attachment || customField.type == FType.Image" [ngClass]="{'attachment-error' : !showInTable}">
        <mat-error
          *ngIf="customFieldForm.controls[formFieldName].errors?.required && customField.isRequired && isFileButtonTouched">
          {{"Error.Required" | translate}}
        </mat-error>
        <mat-error *ngIf="customField.value != null && customField.value != '' && maxFileSizeError">
          {{"Error.Max-File" | translate}} {{customField.max  ? customField.max : orgSettings.allowedMaxAttatchmentSize}} MB
        </mat-error>
        <mat-error *ngIf="customField.value != null && customField.value != '' && fileExtensionError">
          {{"Error.Field-Support" | translate}} {{getFormatedExtenssions()}}
        </mat-error>
        <mat-error *ngIf="customField.value != null && customField.value != '' && fileExtensionDotLengthError">
          {{ orgSettings.invalidFileNameMessage | translateToLocale}}
        </mat-error>
        <mat-error *ngIf="uploadImgExceptionMsg">
          {{ uploadImgExceptionMsg | translateToLocale}}
        </mat-error>
      </div>
      <ng-template #addBtnForBoxControl>
        <div *ngIf="!customField.isExt && customField.isMultipleAllowed && isLastNestedField && !formBuilderFieldSettings" class="add-element-btn"
          (click)="$event.stopPropagation()">
          <button data-action="addMultiField" mat-icon-button class="btn-add-field" (click)="emitAddNestedField()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </ng-template>
      <ng-template #isLongLabel>
        <div fxFlex  fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px"
          class="note-field" *ngIf="customField.isLongLabel && !formBuilderFieldSettings && !showInTable">
          <div class="note-field-label">
              {{customField.label | translateToLocale}} <ng-container *ngTemplateOutlet="tempNesterFieldIndex"></ng-container> <span *ngIf="customField.isRequired">*</span>
          </div>
      </div>
      </ng-template>
      <!-- <div *ngIf="customField.isMultipleAllowed" fxLayoutAlign="start center" fxLayout="row">
        <ng-container >
          <div *ngIf="isLastNestedField; else tempDeleteNestedField" class="add-nested-section-btn-container"
            fxLayoutAlign="end center" fxFlex>
            <button mat-button color="primary" (click)="emitAddNestedField()" fxLayoutAlign="center center"
              fxLayout="row">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div>
                  <mat-icon fxFlex>add_circle_outline</mat-icon>
                </div>
                <div class="text-ellipsis">
                  <span [translate]="'ADD'" class="add-nested-section"></span>
                </div>
              </div>
            </button>
          </div>
          <ng-template #tempDeleteNestedField>
            <div class="btn-delete-container" fxLayoutAlign="end center" fxFlex>
              <button mat-icon-button (click)="emitDeleteNestedField()">
                <mat-icon><img src="../../../assets/images/delete-icon.svg" /></mat-icon>
              </button>
            </div>
          </ng-template>
        </ng-container>
      </div> -->
    </div>

    <div *ngIf="showHintIconSpace" fxFlex="24px" fxHide.lt-sm="true"
      [style.align-self]="customField.type == FType.MultiSelection?'start':'center'" [ngClass]="{'mt-20': customField.type == FType.Price}">
      <ng-container *ngIf="((showHint) || (customField.isMultipleAllowed && !customField.isExt))">
        <button *ngIf="customField.hint && !customField.isMultipleAllowed; else threeDotsWithMenu" mat-icon-button>
          <mat-icon fontSet="material-icons-outlined" class="color-grey cursor-pointer"
            [matTooltip]="customField.hint | translateToLocale" matTooltipClass="esp-tooltip"
            matTooltipPosition="above">
            info</mat-icon>
        </button>
      </ng-container>
    </div>
    <ng-template #threeDotsWithMenu>
      <ng-container *ngIf="(customField.hint && showHint) || (customField.isMultipleAllowed && nestedFieldsCount > 1 && !customField.isExt)">
        <span [ngClass]="{'mt-20': customField.type == FType.Price}">
          <button mat-icon-button [matMenuTriggerFor]="menu" >
            <mat-icon class="color-grey">more_vert</mat-icon>
          </button>
        </span>
      </ng-container>
    </ng-template>

    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item *ngIf="customField.hint && showHint" [matTooltip]="customField.hint | translateToLocale"
        matTooltipClass="esp-tooltip" matTooltipPosition="above" [matTooltipShowDelay]="30">
        <mat-icon fontSet="material-icons-outlined">info</mat-icon>
        <span>Hint</span>
      </button>
      <button *ngIf="!customField.isExt && customField.isMultipleAllowed && nestedFieldsCount > 1" mat-menu-item
        (click)="emitDeleteNestedField()" class="btn-delete-container">
        <mat-icon>
          <img src="../../../../assets/images/delete-icon.svg" />
        </mat-icon>
        <span [translate]="'REMOVE'"></span>
      </button>
    </mat-menu>

    <ng-template #tempNesterFieldIndex>
      <span *ngIf="nestedFieldsCount > 1">#{{nestedFieldIndex+1}}</span>
    </ng-template>

    <ng-template #mobilefieldsicon>
      <button mat-icon-button *ngIf="(customField.hint && showHint && customField.isMultipleAllowed) || (customField.isMultipleAllowed && nestedFieldsCount > 1)" fxHide.gt-xs="true">
        <mat-icon (click)="$event.stopPropagation()"  class="color-grey cursor-pointer" [matMenuTriggerFor]="menu">more_vert
        </mat-icon>
      </button>
      <button mat-icon-button *ngIf="(customField.hint && showHint && !customField.isMultipleAllowed)" fxHide.gt-xs="true">
        <mat-icon  (click)="$event.stopPropagation()"  fontSet="material-icons-outlined"
          class="color-grey cursor-pointer" [matTooltip]="customField.hint | translateToLocale"
          matTooltipClass="esp-tooltip" [matTooltipShowDelay]="30" matTooltipPosition="above">info
        </mat-icon>
      </button>
    </ng-template>
    <ng-template #canUnLockMenu>
      
        <mat-icon *ngIf="customField.canUnLock" (click)="$event.stopPropagation()"  matSuffix style="font-size:22px;color:rgba(0, 0, 0, 0.6);margin-right:8px;"  [matMenuTriggerFor]="unLockMenu">lock
        </mat-icon>
        <mat-icon *ngIf="!customField.canUnLock" style="font-size:22px;color:rgba(0, 0, 0, 0.2);margin-right:8px;">lock
        </mat-icon>
      <mat-menu #unLockMenu="matMenu" [overlapTrigger]="false">
        <button *ngIf="customField.canUnLock" mat-menu-item
        (click)="unlockField()" class="btn-delete-container">
        Unlock
      </button>
      </mat-menu>
  
    </ng-template>
    
  </div>
</form>
