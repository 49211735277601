<div fxLayout="row" fxLayoutAlign="center center" mat-dialog-title>
    <div>
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="5" width="56" height="56" rx="28" fill="#DEEED0" />
            <rect x="5" y="5" width="56" height="56" rx="28" stroke="#EFF7E8" stroke-width="10" />
            <g clip-path="url(#clip0_7642_27813)">
                <path
                    d="M29.8193 21.187C28.6016 27.8015 27.6144 28.7886 21 30.0063C27.6145 31.224 28.6016 32.2111 29.8193 38.8256C31.037 32.2111 32.0241 31.224 38.6386 30.0063C32.0241 28.7886 31.037 27.8015 29.8193 21.187Z"
                    fill="#5BAA15" />
                <path
                    d="M39.39 33.5952C38.6156 37.8018 37.9877 38.4296 33.7812 39.2039C37.9877 39.9783 38.6156 40.6062 39.39 44.8127C40.1643 40.6062 40.7922 39.9783 44.9987 39.2039C40.7921 38.4296 40.1644 37.8018 39.39 33.5952Z"
                    fill="#5BAA15" />
            </g>
            <defs>
                <clipPath id="clip0_7642_27813">
                    <rect width="24" height="24.0001" fill="white" transform="translate(21 21)" />
                </clipPath>
            </defs>
        </svg>
    
    </div>
    <!-- <button mat-icon-button mat-dialog-close aria-label="Close dialog" class="btn-close"
        [dir]="(dataSharingService.isRtl)?'rtl':'ltr'">
        <mat-icon><img src="assets/images/icons/close.svg" alt="close"></mat-icon>
    </button> -->
</div>
<div [dir]="(dataSharingService.isRtl)?'rtl':'ltr'">
    <div mat-dialog-content>
        <div fxFlex fxLayout="column" fxLayoutAlign="center center">
            <div class="confirmation-dialog-title" fxFlex="none" *ngIf="title" class="f-20" style="font-weight: 700;">
                {{title | translate}}
            </div>
            <div fxFlex=" none" *ngIf="title" class="confirmation-dialog-description">
                {{desc | translate}}
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px" class="full-width">
                <div *ngIf="fileDetails == null" fxLayout="column" fxFlex fxLayoutAlign="center center" class="upload-attach-content cursor-pointer"
                    appFileDrop (click)="openFileUploader()">
                    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
                        <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F9FAFB" stroke-width="6" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M23.2865 19.25C21.6016 19.25 20.0354 20.3997 19.5895 21.9254L19.458 22.375H18.9896C17.8028 22.375 16.75 23.442 16.75 24.875C16.75 26.308 17.8028 27.375 18.9896 27.375L27.5833 27.375C28.4537 27.375 29.25 26.5878 29.25 25.5C29.25 24.4122 28.4537 23.625 27.5833 23.625L26.9583 23.625L26.9583 23C26.9583 20.9183 25.3038 19.25 23.2865 19.25ZM18.5346 21.1561C19.2842 19.2902 21.2289 18 23.2865 18C25.8272 18 27.9035 19.9498 28.1776 22.4389C29.5348 22.7363 30.5 24.0328 30.5 25.5C30.5 27.1736 29.2442 28.625 27.5833 28.625H18.9896C17.0123 28.625 15.5 26.8938 15.5 24.875C15.5 23.0141 16.7849 21.3976 18.5346 21.1561ZM23.7767 23.2589L23.7767 25.8033H22.5267L22.5267 23.2589L22.0089 23.7766L21.125 22.8928L23.1517 20.8661L25.1783 22.8928L24.2944 23.7766L23.7767 23.2589Z"
                            fill="#667085" />
                    </svg>
                        
            
                    <div class="upload-attach-content-desc">
                        <a>{{'Click to upload'}}</a> or drag and drop
                    </div>
                    <div class="upload-attach-content-desc">
                        PDF (up to 5MB)
                    </div>
            
                    <!-- <div>PNG, JPG or JPEG (max. 780x240px)</div> -->
                    <input style="display:none" #fileAttachment type="file" (change)="onFileSelected($event, sectionId)" class="custom-textbox" />
                    
                </div>
                <xcdrs-attached-file-details class="file-details" *ngIf="fileDetails" [uploadedFileDetails]="fileDetails" [allowRemove]="true"
                    (onFileRemove)="removeFile()">
                </xcdrs-attached-file-details>
            </div>
            <div fxFlex="none" fxLayout="row" class="full-width" style="margin-left: -5px;" fxLayoutGap="10px" fxLayoutAlign="center center">
                <button mat-stroked-button color="default" (click)="dialogRef.close()"
                    class="confirm-dialog-button cancel-btn">{{no | translate}}</button>
                <button mat-flat-button color="primary" class="confirm-dialog-button" (click)="dialogRef.close(formData)">{{ok
                    | translate}}
                </button>
            </div>
        </div>
    </div>
</div>