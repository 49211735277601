<div class="title-container" fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <div class="dialog-title" fxFlex>
    {{'Common.CREATE' | translate}} {{labelService.labels?.definition | translateToLocale}}
  </div>
  <button mat-icon-button mat-dialog-close (click)="dialogRef.close()" aria-label="Close dialog">
    <mat-icon><img src="assets/images/icons/close.svg" alt="close"></mat-icon>
  </button>
</div>

<div [formGroup]="createDefForm" mat-dialog-content fxLayout="column" fxLayoutGap="16px" class="pt-12 mnt-12">
  <mat-form-field fxFlex appearance="outline" class="compressed">
    <mat-label>{{labelService.labels?.definition | translateToLocale}} Name</mat-label>
    <input matInput placeholder="{{labelService.labels?.definition | translateToLocale}} Name" [autofocus]="true"
      formControlName="name" required  maxlength="50"/>
  </mat-form-field>
  <mat-form-field fxFlex appearance="outline" class="compressed">
    <mat-label [translate]="'Common.DESCRIPTION'"></mat-label>
    <textarea matInput matTextareaAutosize="true" matAutosizeMinRows="3.19" matAutosizeMaxRows="10.19"
      placeholder="{{'Common.DESCRIPTION' | translate}}" formControlName="description" required>

    </textarea>
  </mat-form-field>
  <mat-form-field fxFlex appearance="outline" class="compressed">
    <mat-label [translate]="'CATEGORY'"></mat-label>
    <input type="text" matInput placeholder="{{'CATEGORY' | translate}}" formControlName="category"
      [matAutocomplete]="auto" required>
    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async | emptyArrayOnDefaultLocale:definitionLocaleMapper"
        [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field fxFlex appearance="outline" class="compressed">
    <mat-label [translate]="'Definition.General.Type'">
    </mat-label>
    <mat-select disableOptionCentering formControlName="isRecord"
       required >
      <mat-option [value]="false" [translate]="'Common.Process'">
        Process
      </mat-option>
      <mat-option [value]="true" [translate]="'Common.Record'">
        Record
      </mat-option>
      <mat-option [value]="'survey'" [translate]="'Common.Record'">
        Survey
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!--<esp-two-level-multi-select></esp-two-level-multi-select>-->
  <div fxFlex class="compressed">
    <app-profile-template-selection [definition]="definitionModel"
      [profileFormControl]="createDefForm?.get('profileTemplate')">
    </app-profile-template-selection>
  </div>
  <mat-form-field fxFlex appearance="outline" class="compressed">
    <mat-label>Definition Controllers</mat-label>
    <mat-select panelClass="esp-multi-select" disableOptionCentering multiple formControlName="definitionControllers"
    [matTooltip]="getUpdateTriggerText(null)" matTooltipClass="esp-tooltip">
      <mat-option>
        <ngx-mat-select-search ngModel #controllerFilter="ngModel" [ngModelOptions]="{standalone: true}"
          [placeholderLabel]="('Common.FIND'| translate) +' Controllers ...'"
          [noEntriesFoundLabel]="('Common.NO-MATCHING'| translate) +' Controller ' + ('Common.FOUND'| translate)">
        </ngx-mat-select-search>
      </mat-option>
      <mat-optgroup label="Users" *ngIf="(controllers.users | filterByProperty:['name',controllerFilter.value])?.length">
        <mat-option *ngFor="let c of controllers.users  | filterByProperty:['name',controllerFilter.value]" [value]="c"
        [matTooltip]="c.name" matTooltipClass="esp-tooltip">{{c.name}}</mat-option>
      </mat-optgroup>
      <mat-optgroup label="Job Roles" *ngIf="(controllers.jobRoles | filterByProperty:['name',controllerFilter.value])?.length">
        <mat-option *ngFor="let c of controllers.jobRoles  | filterByProperty:['name',controllerFilter.value]" [value]="c"
        [matTooltip]="c.name" matTooltipClass="esp-tooltip">{{c.name}}</mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions class="mat-dialog-actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
  <button mat-flat-button (click)="dialogRef.close()">
    <span [translate]="'Cancel'"></span>
  </button>
  <button mat-raised-button color="primary" [disabled]="!createDefForm.valid || callInProgress" (click)="onSubmit()">
    <span [translate]="'Create'"></span>
  </button>
</div>