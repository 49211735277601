import { labels } from "./lookup-ad-ons-model";

export class ApplicantModel {
  constructor(

  ) {
    this.sendInvitation = true;
  }

  public id: number;
  public name: string;
  public emailAddress: string;
  public status: string;
  public sendInvitation: boolean;
  public createdOn: string;
  public invitedOn: string;
  public imageUrl: string;
  public isApplicant: boolean;
  public addAsAssessor: boolean;
  public assessorUserId: number;
  public profileTemplates: number[];
  public formSectionValues: labels[];
}
