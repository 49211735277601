import { FieldVisibilityModel } from "../profile-customization/Model/CustomFieldModel";

export class FieldSettings{
  constructor(){
    this.mainDefinitionVisibilityType = '';
    this.submitFrom3Dots = false;
    this.onMainFormListCardVisibility =  new FieldVisibilityModel();
    this.multiLookupVisisbityType = '';
    this.lookupViewPermission = ''; 
    this.identifier = '';
  }
  mainDefinitionVisibilityType: string;
  submitFrom3Dots: boolean;
  onMainFormListCardVisibility: FieldVisibilityModel;
  multiLookupVisisbityType: string; 
  lookupViewPermission: string; 
  identifier: string; 
}
