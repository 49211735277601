import { Injectable } from '@angular/core';
import { AccountModel } from '../../../models/account.model';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OrgAccountsListDialogComponent } from '../../../app-header/org-accounts-list-dialog/org-accounts-list-dialog.component';
import { DataSharingService } from '../../../common/services/data-sharing.service';
@Injectable({
  providedIn: 'root'
})
export class OrgAccountsService {
  private _userPersonas: AccountModel[] = null;
  constructor(
    private http: HttpClient,
    private _matDialog: MatDialog,
    public dataSharingService: DataSharingService
  ) {

  }

  unsetUserPersonas() {
    this._userPersonas = null;
  }

  userPersonas(): Observable<AccountModel[]> {
    if (this._userPersonas) {
      return of(this._userPersonas);
    } else {
      return this.http.get<any>('/webapi/orguser/personasV2', { observe: 'response' }).pipe(
        map(resp => {
          this._userPersonas = resp.body;
          this._userPersonas.forEach(account => {
            if (account.type == "ORG") {
              account.type = "Admin";
            } else if (account.type == "APP") {
              account.type = "Applicant";
            }
          })
          return this._userPersonas;
        })
      );
    }
  }

  logout(): Observable<any>{
    return this.http.get<any>('/webapi/orguser/logout', { observe: 'response' });
  }

  openSwitchOrgDialog(): MatDialogRef<OrgAccountsListDialogComponent> {
    return this._matDialog.open(OrgAccountsListDialogComponent, {
      autoFocus: false,
      closeOnNavigation: true,
      width: '500px',
      direction: this.dataSharingService.isRtl ? 'rtl' : 'ltr'
    })
  }

}
