<div fxFlex="10" fxFlex.lt-md="0" fxFlex.md="5" fxFlex.xs="0" *ngIf="!isUserLogin"></div>
<div class="main-account-container">
  <div>
    <div class="main-account-container">
      <div fxFlex fxLayout="row" fxLayoutAlign="center stretch">
        <div fxFlex fxLayout="row" fxFlex.lt-md="100" fxLayoutAlign="center start">
          <div fxFlex fxFlex.lt-md="100" fxLayout="column" class="login-dialog">

            <div fxFlex class="gray-card submit-request-container" ngClass.lt-sm="submit-request-container-sm">
              <app-applicant-request-authentication fxFill
                *ngIf="componentCallToString == componentCallTo.ApplicantRequestAuthentication"
                [definitionKey]="routeParams?.definitionKey" (callComponentTo)="changeComponentCall($event)">
              </app-applicant-request-authentication>
              <app-submit-applicant-request fxFill *ngIf="componentCallToString == componentCallTo.AddApplicantRequest"
                [callFrom]="componentName.SubmitRequestV2Component" [definitionKey]="routeParams?.definitionKey"
                [applicantEmail]="routeParams?.email" (submitForAnonymous)="submitAll($event)">
              </app-submit-applicant-request>
              <app-applicant-profile-v2 *ngIf="componentCallToString == componentCallTo.ApplicantProfile"
                (callComponentTo)="changeComponentCall($event)" [submitModel]="submitModel">
              </app-applicant-profile-v2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div fxFlex="10" fxFlex.lt-md="0" fxFlex.md="5" fxFlex.xs="0" *ngIf="!isUserLogin"></div>