<div fxLayout="column" fxLayoutAlign="center start" [ngClass]="{'wizart-field-label' : !readonly, 'margin-t1' : fieldOptions.showLabel }">
  <ng-container [ngTemplateOutlet]="isLongLabel"></ng-container>
  <div *ngIf="fieldOptions.showLabel">
    <label *ngIf="!customField.isLongLabel">
      <span>{{customField.label | translateToLocale}} </span><span *ngIf="customField.isRequired">*</span>
      <mat-icon  matSuffix *ngIf="customField.hint" fxHide.gt-xs="true"  fontSet="material-icons-outlined" class="color-grey cursor-pointer vertically-middle f-20" [matTooltip]="customField.hint | translateToLocale" matTooltipClass="esp-tooltip" matTooltipPosition="above">info</mat-icon>
      <mat-icon matSuffix style="font-size:18px;color:rgba(0, 0, 0, 0.2);margin-right:8px;" *ngIf="customField.isReadOnly">
        lock</mat-icon>
    </label>
  </div>
  <div>
    <div fxLayout="row" class="side-alignments" [ngClass]="{'stars-container' : fieldOptions.showLabel }">
      <div *ngFor="let star of [].constructor(customField.max); let i = index">
        <mat-icon [ngStyle]="{'color' : customField.allowedValuesCriteria}" (click)="onRatingChange(i+1)" [ngClass]="{'cursor-pointer' : !readonly}">
          {{ i < customField.value ? 'star' : 'star_border' }}
        </mat-icon>
        <!--<button mat-icon-button (click)="onRatingChange(i+1)" [disabled]="readonly" *ngIf="!readonly">
          <mat-icon [ngStyle]="{'color' : customField.allowedValuesCriteria}">
            {{ i < customField.value ? 'star' : 'star_border' }}
          </mat-icon>
        </button>-->
        <!--<div *ngIf="readonly">
          <mat-icon [ngStyle]="{'color' : customField.allowedValuesCriteria}">
            {{ i < customField.value ? 'star' : 'star_border' }}
          </mat-icon>
        </div>-->
      </div>
    </div>
  </div>
  <div *ngIf="!isRating">
    <mat-error
      *ngIf="customField.isRequired">
      {{"Error.Required" | translate}}
    </mat-error>
  </div>
</div>
