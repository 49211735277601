import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetIconService {

  constructor() { }
  getIcon(iconImage: string) {
    if (iconImage && iconImage.indexOf('/') > -1) {
      let icon: string[] = iconImage.split('/');
      return icon[1];
    } else {
      return iconImage;
    }
  }
}
