import { Injectable } from '@angular/core'
import { DefinitionFilterModel, FieldValue, SearchableField } from '../../models/iframe/definition-filter.model';

@Injectable({
  providedIn: 'root'
})
export class IframeService {
  isIframeCall = false;
  private _iframeDefinitionFilterModel: DefinitionFilterModel;
  get iframeDefinitionFilterModel(): DefinitionFilterModel{
    return this._iframeDefinitionFilterModel;
  }
  set iframeDefinitionFilterModel(defFilter: DefinitionFilterModel) {
    this._iframeDefinitionFilterModel = defFilter;
    this.setIframeFieldValues(defFilter);
  }

  private _iframeFieldValues: FieldValue[] = [];
  get iframeFieldValues(): FieldValue[] {
    return this._iframeFieldValues;
  }

  private setIframeFieldValues(defFilterModel: DefinitionFilterModel) {
    this._iframeFieldValues = [];
    const allSearchableFields: SearchableField[] = this.getAllSearchableFields(defFilterModel);
    allSearchableFields.forEach(searchableField => {
      const searchableValue = defFilterModel.searchableValues.find(s => s.key == searchableField.key);
      if (searchableValue) {
        this._iframeFieldValues.push({
          fieldPath: searchableField.fieldPath,
          value: searchableValue.value
        });
      }
    });
  }

  private getAllSearchableFields(defFilterModel: DefinitionFilterModel): SearchableField[] {
    let allSearchables: SearchableField[] = [];
    defFilterModel.definitions.forEach(def => {
      allSearchables = allSearchables.concat(def.searchableFields);
    });
    return allSearchables;
  }

}
