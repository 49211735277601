import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { DataSharingService } from '../../common/services/data-sharing.service';

@Component({
  selector: 'app-toast-alert',
  templateUrl: './toast-alert.component.html',
  styleUrls: ['./toast-alert.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastAlertComponent implements OnInit {
  
  textMessage: string = '';
  bgColor: string = 'orange';
  isSuccess: boolean = true;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public dataSharingService: DataSharingService) {

    this.textMessage = data.textMessage;
    this.isSuccess = data.isSuccess;

    if (this.isSuccess) {
      this.bgColor = '#10c164'
    } else {
      this.bgColor = '#ea4832'
    }
  }

  ngOnInit() {
  }

}
