import { Component, OnInit } from '@angular/core';
import { LabelService } from '../../common/services/label-service';
import { ThemeService } from '../../common/services/theme.service';

@Component({
  selector: 'app-invalid-link',
  templateUrl: './invalid-link.component.html',
  styleUrls: ['./invalid-link.component.less']
})
export class InvalidLinkComponent implements OnInit {

  constructor(public labelService: LabelService, public themeService: ThemeService) { }

  ngOnInit() {
  }

}
