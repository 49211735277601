import { FormGroup } from '@angular/forms';
import { Condition } from '../profile-customization/Model/CustomFieldModel';
import { ConstraintModel } from './trigger-model';

export class  ActionModelCreateApplication{
  public definitionId: number;
  public applicantId: number;
}
export class ActionModel extends ActionModelCreateApplication {
  constructor() {
    super();
    this.values = [];
  }
  public id: number;
  public name: string;
  public type: string;
  public trigger: string;
  public actionTrigger: string;
  public isDeleted: boolean;
  public isEnabled: boolean;
  public values: ActionValue[];

  public lookupAddonId: any;
  public emailTo: string;
  public notifyTo: any;
  public subject: string;
  public body: string;
  public attach: boolean;
  public attachments: string;
  public constraint: ConstraintModel;
  public filters: string; //Used for filters as json string
  public jumpStageId: number;
  public formGroup?: FormGroup;
  public endpoint: string;
  public httpMethod: string;
  public isDelete?: boolean;
  public definitionId: number;
  public fields: number[];
  public status: string;
  public startTime: string;
  public endTime: string;
  public location: string;
  public title: string;
  public version: string;
  public purpose: string;
  public attachment: string;
}

export class ActionValue {
  public id?: number;
  public value: string;
  public actionKey: string;
  public operators?: OperatorValue[] = [];
}
export class OperatorValue {
  public isDynamic: boolean = true;
  public operator: string = '';
  public value: string = ''
}
export class ActionType {
  readonly Email = "Email";
  readonly Meeting = "Meeting";
  readonly Notification = "Notification";
  readonly CreateLookupItem = "CreateLookupItem";
  readonly UpdateLookupItem = "UpdateLookupItem";
  readonly JumpStage = "JumpStage";
  readonly CloseApplicationAs = "CloseApplicationAs";
  readonly UpdateApplicant = "UpdateApplicant";
  readonly WebAPI = "WebAPI";
  readonly ChangeApplicationApplicant = "ChangeApplicationApplicant";
  readonly CreateApplication = "CreateApplication";
  readonly ShareApplication = "ShareApplicationWith";
  readonly UpdateApplication = "UpdateApplication";
  readonly UpdateAssessment = "UpdateAssessment";//Change Criterion Owner
  readonly PlanneXe_CreateProject = "PlanneXe_CreateProject";
  readonly PlanneXe_UpdateProject = "PlanneXe_UpdateProject";
  readonly Lock = "Lock";
  readonly UnLock = "UnLock";
  readonly FieldValueLock = "FieldValueLock";
  readonly ReOpenApplication = "ReOpenApplication";
  readonly GeneratePdfLetter = "GeneratePdfLetter";
  readonly GenerateWordDoc = "GenerateWordDoc";
  readonly UploadDocument = "UploadDocument";
  readonly Higher_PublishVacancy = "Higher_PublishVacancy";
}

export class ActionTriggerType {
  readonly OnStageStart = "OnStageStart";
  readonly OnStageEnd = "OnStageEnd";
  readonly OnStageAccepted = "OnStageAccepted";
  readonly OnStageRejected = "OnStageRejected";
  readonly SLADate = "OnSLADate";
}
export class DefinitionActionTriggerType {
  readonly OnApplicationAccepted = "OnApplicationAccepted";
  readonly OnApplicationRejected = "OnApplicationRejected";
}

export class ActionWebApiKey {
  static readonly URL = "URL";
  static readonly Method = "Method"
  static readonly Payload = "Payload";
}

export class GeneratePdfLetterKey {
  static readonly Language = "Language";
  static readonly HeaderImage = "HeaderImage";
  static readonly FooterImage = "FooterImage";
  static readonly SignatureImage = "SignatureImage";
  static readonly EnableQR = "EnableQR";
  static readonly Content = "Content";
}

export class PublishVacancy {
  static readonly VacancyFieldSCFID = 'VacancyFieldSCFID';
  static readonly PublishAs = 'PublishAs';
  static readonly VacanciesLookupId = -802; 
}


