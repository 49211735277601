<div fxLayout="row" fxLayoutAlign="space-between start" mat-dialog-title>
    <div class="save-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M17.0834 3.75H3.75V20.25H6.75H8.25H15.75H17.25H20.25V6.91661L17.0834 3.75ZM15.75 18.75H8.25L8.25 13.5L15.75 13.5V18.75ZM17.25 12L17.25 18.75H18.75V7.53793L16.5 5.28793V9.75H7.5V5.25H5.25V18.75H6.75L6.75 12L17.25 12ZM15 5.25H9V8.25H15V5.25ZM14.25 6H12.75V7.5H14.25V6Z"
                fill="#5BAA15" />
        </svg>

    </div>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog" class="btn-close"
        [dir]="(dataSharingService.isRtl)?'rtl':'ltr'">
        <mat-icon><img src="assets/images/icons/close.svg" alt="close"></mat-icon>
    </button>
</div>
<div [dir]="(dataSharingService.isRtl)?'rtl':'ltr'">
    <div mat-dialog-content>
        <div fxFlex fxLayout="column" fxLayoutAlign="start start">
            <div fxFlex="none" *ngIf="title" class="f-20" style="font-weight: 700;">
                {{title | translate}}
            </div>
            <div fxFlex=" none" *ngIf="title" class="confirmation-dialog-description">
                {{desc | translate}}
            </div>
            <div fxFlex="none" fxLayout="row" class="full-width" style="margin-left: -5px;" fxLayoutGap="10px" fxLayoutAlign="end end">
                <button mat-stroked-button color="default" (click)="dialogRef.close(2)"
                    class="confirm-dialog-button cancel-btn">{{no | translate}}</button>
                <button mat-flat-button color="primary" class="confirm-dialog-button" (click)="dialogRef.close(1)">{{ok
                    | translate}}
                </button>
            </div>
        </div>
    </div>
</div>