import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateToLocalePipe } from '../../common/pipes/translate-to-locale.pipe';
import { LookupAdonsIsmainFilter } from '../../common/pipes/lookup-adons-ismain-pipe';
import { EmptyArrayOnDefaultLocalePipe } from '../../common/pipes/empty-array-on-default-locale.pipe';
import { FilterByKeyValuePipe } from './filter-by-key-value.pipe';
import { EspEditorPipe } from '../../common/pipes/esp-editor.pipe';
import { FilterInsertFieldPipe } from '../../common/pipes/filter-insert-field.pipe';
import { SubmittedFieldFilter } from '../../common/pipes/submitted-fields-filter.pipe';
import { FilterByBooleanPropertyPipe } from '../../common/pipes/filter-by-boolean-property.pipe';
import { FilterByMultiplePropertiesPipe, FilterByPropertyPipe } from '../../common/pipes/filter-by-property.pipe';
import { NoCommaPipe } from '../../common/pipes/no-comma-pipe';
import { FileSizePipe } from './file-size.pipe';
import { FormSectionFilterPipe, FormSectionDetailsFilterPipe, FormSectionVisibleFilterPipe} from '../../common/pipes/form-section-filter.pipe';
import { RemoveSpecialCharactersPipe } from '../../common/pipes/remove-special-characters.pipe';
import { FormSecurityFilterPipe } from '../../common/pipes/form-security-filter.pipe';
import { OrganizationUserPipe } from '../../organization/pipes/organization-user.pipe';
import { FilterBySelectedValuePipe } from '../../common/pipes/filter-by-selected-value.pipe';
import { MegabytesPipe } from '../../common/pipes/CovertNumberToMB.pipe';



@NgModule({
  declarations: [
    TranslateToLocalePipe,
    LookupAdonsIsmainFilter,
    EmptyArrayOnDefaultLocalePipe,
    FilterByKeyValuePipe,
    EspEditorPipe,
    FilterInsertFieldPipe,
    SubmittedFieldFilter,
    FilterByBooleanPropertyPipe,
    FilterByPropertyPipe,
    FilterByMultiplePropertiesPipe,
    NoCommaPipe,
    FileSizePipe,
    FormSectionFilterPipe,
    FormSectionDetailsFilterPipe,
    FormSectionVisibleFilterPipe,
    RemoveSpecialCharactersPipe,
    FormSecurityFilterPipe,
    OrganizationUserPipe,
    FilterBySelectedValuePipe,
    MegabytesPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TranslateToLocalePipe,
    LookupAdonsIsmainFilter,
    EmptyArrayOnDefaultLocalePipe,
    FilterByKeyValuePipe,
    EspEditorPipe,
    FilterInsertFieldPipe,
    SubmittedFieldFilter,
    FilterByBooleanPropertyPipe,
    FilterByPropertyPipe,
    FilterByMultiplePropertiesPipe,
    NoCommaPipe,
    FileSizePipe, 
    FormSectionFilterPipe,
    FormSectionDetailsFilterPipe,
    FormSectionVisibleFilterPipe,
    RemoveSpecialCharactersPipe,
    FormSecurityFilterPipe,
    OrganizationUserPipe,
    FilterBySelectedValuePipe,
    MegabytesPipe
  ],
  providers: [
    TranslateToLocalePipe,
    LookupAdonsIsmainFilter,
    FilterInsertFieldPipe,

  ]
})
export class PipesModule { }
