import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { ApplicantModel } from '../models/ApplicantModel';
import { CustomizationSettingsSubmitModel } from '../models/CustomizationSettingsSubmitModel';
import { ApplicantDTO, ApplicantFieldValueDTO } from '../models/ApplicantDTO';
import { BulkApplicantModel } from '../models/bulk-applicantModel';
import { CommonHelperService } from '../common/services/common-helper.service';
import { map } from 'rxjs/operators';
import { profile } from 'console';


@Injectable({
  providedIn: 'root'
})
export class ApplicantService {
  constructor(private http: HttpClient
    , private commonHelperService: CommonHelperService) { }

  addApplicant(applicant: ApplicantModel): Observable<number> {
    return this.http.post<any>('/webapi/Applicant', applicant);
  }

  inviteAndReinviteApplicant(applicant: ApplicantModel): Observable<number> {
    return this.http.post<any>('/webapi/applicant/invite/' + applicant.id, {});
  }

  getApplicants(): Observable<HttpResponse<any>> {
    return this.http.get<ApplicantModel[]>('/webapi/Applicant/all', { observe: 'response' });
  }

  getAllApplicants(): Observable<HttpResponse<any>> {
    return this.http.get<ApplicantModel[]>('/webapi/applicant/list', { observe: 'response' });
  }

  getApplicantsByDefinitionId(definitionId): Observable<HttpResponse<any>> {
    return this.http.get<ApplicantModel[]>('/WebApi/applicant/applicantsByDefinitionId/' + definitionId, { observe: 'response' });
  }

  getApplicantDetails(applicantId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>('/webapi/applicant/' + applicantId, { observe: 'response' });
  }

  postApplicantDetails(submitModel: CustomizationSettingsSubmitModel): Observable<HttpResponse<any>> {
    let applicant: ApplicantDTO = new ApplicantDTO();
    applicant.applicantSections = [];
    applicant.id = submitModel.id;
    applicant.name = submitModel.name;
    applicant.emailAddress = submitModel.emailAddress;
    var i;
    for (i = 0; i < submitModel.sections.length; i++) {
      if (submitModel.sections[i].isMultipule == false) {
        var j;
        let applicantFieldValueDTO: ApplicantFieldValueDTO[] = [];
        for (j = 0; j < submitModel.sections[i].fields.length; j++) {
          if (submitModel.sections[i].fields[j].value != '') {
            applicantFieldValueDTO.push({ sectionFieldId: submitModel.sections[i].fields[j].sectionTemplateFiledId, value: submitModel.sections[i].fields[j].value });
          } else if (submitModel.sections[i].fields[j].type == 6) {
            var commaSepVal = [];
            for (let lookup of submitModel.sections[i].fields[j].lookupValues) {
              if (lookup.isSelected) {
                commaSepVal.push(lookup.id)
              }
            }
            if (commaSepVal.length > 0) {
              applicantFieldValueDTO.push({ sectionFieldId: submitModel.sections[i].fields[j].sectionTemplateFiledId, value: commaSepVal.join(',') });
            }
          }
        }
        applicant.applicantSections.push({ sectionId: submitModel.sections[i].id, values: applicantFieldValueDTO, index: 0, lastUpdatedOn: new Date() });
      } else {
        var j;
        for (j = 0; j < submitModel.sections[i].nestedSections.length; j++) {
          var k;
          let applicantFieldValueDTO: ApplicantFieldValueDTO[] = [];
          for (k = 0; k < submitModel.sections[i].nestedSections[j].fields.length; k++) {
            if (submitModel.sections[i].nestedSections[j].fields[k].value != '') {
              applicantFieldValueDTO.push({
                sectionFieldId: submitModel.sections[i].nestedSections[j].fields[k].sectionTemplateFiledId,
                value: submitModel.sections[i].nestedSections[j].fields[k].value
              });
            }
            else if (submitModel.sections[i].nestedSections[j].fields[k].type == 6) {
              var commaSepVal = [];
              for (let lookup of submitModel.sections[i].nestedSections[j].fields[k].lookupValues) {
                if (lookup.isSelected) {
                  commaSepVal.push(lookup.id)
                }
              }
              if (commaSepVal.length > 0) {
                applicantFieldValueDTO.push({ sectionFieldId: submitModel.sections[i].nestedSections[j].fields[k].sectionTemplateFiledId, value: commaSepVal.join(',') });
              }
            }
          }
          applicant.applicantSections.push({ sectionId: submitModel.sections[i].id, values: applicantFieldValueDTO, index: 0, lastUpdatedOn: new Date() });
        }
      }
    }
    return this.http.post<any>('/webapi/api/applicant/update', applicant);
  }

  getApplicantsWithPaging(role: number, pageNo: number, recordsPerPage: number, searchText: string, applicantStatus: ApplicantStatus, profileId: number, subTenantId: number): Observable<any> {
    let headers = this.commonHelperService.getCurrentLocaleHeader();
    return this.http.get<Observable<any>>('/webapi/applicant/all?search=' + searchText + '&role=' + role + '&status=' + applicantStatus + '&PageNo=' + pageNo + '&RecordPerPage=' + recordsPerPage + '&profileId=' + profileId + '&subTenantId=' + subTenantId, { headers: headers, observe: 'response' });
  }

  /******************************************Module (Bulk Add Applicants) Start**********************************************/
  uploadApplicantsFile(data: any): Observable<any> {
    return this.http.post<any>('/webapi/applicant/uploadcsv', data);
  }
  uploadBulkApplicants(data: any): Observable<any> {
    return this.http.post<any>('/webapi/applicant/bulkupload', data);
  }

  syncApplicantList(bulkApplicantModels: BulkApplicantModel[]): Observable<any> {
    return this.http.post<any>('/WebApi/applicant/InsertUpdate', bulkApplicantModels);
  }
  /******************************************Module (Bulk Add Applicants) Start**********************************************/

  makeAssessor(applicantId): Observable<any> {
    return this.http.put<any>('/webapi/applicant/addAsUser/' + applicantId, { observe: 'response' });
  }

  activateApplicant(applicantId): Observable<any> {
    return this.http.post<any>('/webapi/applicant/activate/' + applicantId, { observe: 'response' });
  }

  deactivateApplicant(applicantId): Observable<any> {
    return this.http.post<any>('/webapi/applicant/deactivate/' + applicantId, { observe: 'response' });
  }
  verifyApplicant(key) {
    return this.http.post('/webapi/applicant/verify/' + key, { observe: 'response' });
  }

  getNumberOfApplicant(): Observable<any> {
    return this.http.get<any>('/webapi/applicant/count', { observe: 'response' });
  }

  getApplicantsForDefinition(definitionId: number): Observable<ApplicantDTO[]>{
    return this.http.get<ApplicantDTO[]>(`/webapi/applicant/fordefinition?definitionId=${definitionId}`, { observe: 'response' })
    .pipe(
      map(res => res.body)
    );
  }
}

export enum ApplicantStatus {
  New = 1,
  InvitationSent = 2,
  Active = 3,
  Deactivated = 4,
  All = 0
}
export enum ApplicantRole {
  Applicant = 1,
  User = 2,
  All = 0
}
