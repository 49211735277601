import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SystemSettingsService } from "../../common/services/system-settings-service";
import { environment } from '../../../environments/environment';
import { IdenediAuthModel } from "../../models/idenedi-auth-model";

@Injectable({
  providedIn: 'root'
})
export class IdenediAuthService {
  private _idenediUrl;
  constructor(private systemSettingsService: SystemSettingsService
    ,private http: HttpClient) {
      this.idenediUrl().subscribe(resp => { 
        this._idenediUrl = resp['idenediLoginUri'];
      });
  }
  public idenediUrl() {
    return this.http.get('/webapi/settings');
  }
  public formatedIdenediLoginUrl(state: string = ""): string {
    // console.log('this._idenediUrl', this._idenediUrl);
    
    return `${this._idenediUrl}/authorization/?response_type=code&client_id=${this.systemSettingsService.systemSettings.idenediClientId}&redirect_uri=${this.systemSettingsService.systemSettings.redirectUriForIdenedi}&state=${state}`;
  }

  public formatedIdenediLoginUrlForProfile(state: string = ""): string {
    return `${this._idenediUrl}/authorization/?response_type=code&client_id=${this.systemSettingsService.systemSettings.idenediClientId}&redirect_uri=${this.systemSettingsService.systemSettings.redirectUriForIdenediProfile}&state=${state}`;
  }

  public formatedIdenediSelectGroupLink(state: string = ""): string{
    return `${this.systemSettingsService.systemSettings.idenediAuthBaseUri}/LinkGroup.html?client_id=${this.systemSettingsService.systemSettings.idenediClientId}&redirect_uri=${location.href}&state=${state}`;
  }

  public linkIdenediUser(authModel: IdenediAuthModel) {
    return this.http.post('/webapi/idenedi/linkUser', authModel)
  }

  public linkIdenediProfile(idenediCode: string) {
    return this.http.get('/webapi/idenedi/linkProfile?idenediCode=' + idenediCode);
  }
}
