<div class="main-account-container">
  <div>
    <app-header-before-login></app-header-before-login>
    <div class="main-account-content">
      <div fxFlex fxLayout="row" fxLayoutAlign="center stretch">
        <div fxLayout="column" fxLayoutAlign="center" class="reset-bg">
          <div class="invalid-dialog bgc-white" ngClass.lt-sm="width100">
            <div class="dialog-top-bar"></div>
            <div class="dialog-content-container invalid-padding">
              <div mat-dialog-title>
                <div fxLayout="row" fxLayoutAlign="center center" class="dialog-top-img">
                  <img src="../../../assets/images/{{themeService.imgSrc}}invalid-link.png" />
                </div>
                <div fxLayout="row">
                  <div fxFlex>
                    <div class="title text-center">
                      {{'InvalidLink.HEADING-TITLE' | translate}}
                    </div>
                    <div class="title-description text-center">
                      {{'InvalidLink.HEADING-DESCRIPTION' | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div mat-dialog-actions class="contct-btn-padding">
              <div fxFlex fxLayoutAlign="center center">
                <button mat-button class="add-button-gradiant">{{'Common.CONTACT-US' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
