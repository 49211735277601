import { Injectable } from '@angular/core';
import { DefinitionStageModel } from '../../definitions/models/definition-stage';
import { StageCriteria } from '../../definitions/models/stage-criteria';
import { StageCriteriaType } from '../enums/stage-criteria-type';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddCriteriaDialogComponent } from '../../definitions/add-criteria-dialog/add-criteria-dialog.component';
import { ApplicationDefinition, FormModel } from '../../definitions/models/application-definition';
import { DefinitionDelegateUser } from '../../models/delegate-model';
import { CriteriaOwner, CriteriaOwnerType } from '../../models/criteria-owner';

@Injectable()
export class CriteriaHelperService {
  constructor(public criteriaDialog: MatDialog) {

  }

  addCriteriaDialog(stage: DefinitionStageModel, criteria: StageCriteria, isUpdate: boolean, callFrom: string = '', definitionDetails: ApplicationDefinition,
    definitionDelegations: DefinitionDelegateUser[],
    isModelChangedCallback,
    refThis,
    checkDisableButtonConditionsWithRef = null
    ) {
    let criteriaModel = new StageCriteria();
    if (!isUpdate) {
    }
    else {
      criteriaModel = Object.assign({}, criteria);
    }

    criteriaModel.type = (callFrom == 'feedback') ? StageCriteriaType.Feedback : StageCriteriaType.Assessment;
    const addCriteriaDialogRef = this.criteriaDialog.open(AddCriteriaDialogComponent, {
      width: '580px',
      data: {
        criteria: criteriaModel,
        stage: stage,
        isUpdate: isUpdate,
        isPublished: definitionDetails.isPublished,
        definitionId: definitionDetails.id,
        definitionType: definitionDetails.type
      }, disableClose: true
    });

    addCriteriaDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (isModelChangedCallback) {
          isModelChangedCallback(refThis);
        }
        if (!result.isUpdate) {
          //add new object in list
          var addedCriteria: StageCriteria = {
            id: result.data.id,
            reassignAssessments: result.data.reassignAssessments,
            name: result.data.name,
            daysToComplete: result.data.daysToComplete,
            slaHours: result.data.slaHours,
            ownerId: result.data.ownerId,
            isSystem: result.data.isSystem,
            ownerName: result.data.ownerName,
            weight: result.data.weight,
            form: new FormModel,
            formValues: [],
            isExpended: true,
            isEnabled: true,
            assessmentId: 0,
            assessmentStatus: '',
            comments: [],
            isOwner: false,
            stageId: 0,
            ownerIdJobRole: result.data.ownerIdJobRole,
            ownerIdUserLookupCustomFieldPath: result.data.ownerIdUserLookupCustomFieldPath,
            subApplicantId: 0,
            subDefinitionId: 0,
            subApplicationId: 0,
            type: (callFrom == 'feedback') ? StageCriteriaType.Feedback : StageCriteriaType.Assessment,
            isDisabled: false,
            delegatedToName: "",
            approveText: result.data.approveText,
            rejectText: result.data.rejectText,
            reminder: result.data.reminder,
            customFieldsCount: 0,
            hasApplication: false,
          };
          addedCriteria.delegatedToName = this.getDelegatedToUserName(addedCriteria.ownerId, addedCriteria.type, definitionDelegations);
          this.addCriteriaToStage(stage, addedCriteria);
        } else {
          criteria.reassignAssessments = result.data.reassignAssessments;
          criteria.name = result.data.name;
          criteria.daysToComplete = result.data.daysToComplete;
          criteria.slaHours = result.data.slaHours;
          criteria.ownerId = result.data.ownerId;
          criteria.weight = result.data.weight;
          criteria.ownerName = result.data.ownerName;
          criteria.ownerIdJobRole = result.data.ownerIdJobRole;
          criteria.ownerIdUserLookupCustomFieldPath = result.data.ownerIdUserLookupCustomFieldPath;
          criteria.delegatedToName = this.getDelegatedToUserName(criteria.ownerId, criteria.type, definitionDelegations);
          criteria.reminder = result.data.reminder
        }
      }
      if (checkDisableButtonConditionsWithRef) {
        checkDisableButtonConditionsWithRef(refThis);
      }
    });
  }

  private getDelegatedToUserName(criteriaOwnerId: number, criteriaType: string, definitionDelegations: DefinitionDelegateUser[]) {
    //await this.asyncGetUsers();
    if (criteriaOwnerId && criteriaType != StageCriteriaType.Application) {
      var delegation = definitionDelegations.find(x => x.fromUserId == criteriaOwnerId);
      if (delegation != undefined) {
        return delegation.toUserName
      }
    }
    return "";
  }

  addCriteriaToStage(stage: DefinitionStageModel, criteria: StageCriteria) {
    stage.criteriaList.push(criteria);
    if (!stage.isExpended) {
      stage.isExpended = true;
    }
  }

  createCriteriaObjectWithFields(criteria: StageCriteria, stage: DefinitionStageModel): StageCriteria {
    let criteriaData: StageCriteria = new StageCriteria();
    
    criteriaData.name = criteria.name;
    criteriaData.stageId = stage.id;
    criteriaData.ownerId = criteria.ownerId;
    criteriaData.weight = stage.totalWeight;
    criteriaData.daysToComplete = criteria.daysToComplete;
    criteriaData.slaHours = criteria.slaHours;
    criteriaData.ownerIdJobRole = criteria.ownerIdJobRole;
    criteriaData.ownerIdUserLookupCustomFieldPath = criteria.ownerIdUserLookupCustomFieldPath;
    criteriaData.type = criteria.type;
    criteriaData.approveText = criteria.approveText;
    criteriaData.rejectText = criteria.rejectText;
    criteriaData.form = criteria.form;
    return criteriaData;
  }

}
