import { Pipe, PipeTransform } from '@angular/core';
import { ActionFieldModel } from '../../models/action-field-model';
import { FeildType } from '../enums/field-type-enum';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { ActionFieldsDialogService } from '../../form-builder/services/action-fields-diaolog.service';

@Pipe({
  name: 'filterInsertField'
})
export class FilterInsertFieldPipe implements PipeTransform {

  constructor(private actionFieldsDialogService: ActionFieldsDialogService){

  }

  transform(list: ActionFieldModel[], args?: any): any {
    if (!list || !args) {
      return list;
    }

    var type: any = args[0];
    var columnValue = args[1];
    if (!columnValue) {
      columnValue = "";
    }
    let customField: CustomFieldModel = null;
    if (args[2]) {
      customField = args[2];
    }

    if (type == FeildType.All) {
      return [] //list.filter(listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1));
    } else if (type == FeildType.Text || type == FeildType.MultiLine || type == FeildType.RichText || type == "body" || type == "notificationEmailBody") {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1) && listItem.type != FeildType.LookupAddon);
    }
    else if (type == FeildType.LookupAddon) {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1) && listItem.fieldId == customField.lookUpId);
    } else if (type == FeildType.MultiSelection || type == FeildType.SingleSelection) {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1) && listItem.fieldId == customField.id);
    }
    else if (type == "subject" || type == "endpoint" || type == "notificationEmailSubject") {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1 && listItem.type != FeildType.Attachment && listItem.type != FeildType.LookupAddon && listItem.type != FeildType.MultiSelection));
    } else if (type == "startTime" || type == "endTime") {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1 && listItem.type == FeildType.DateTime));
    } else if (type == "nameFieldPath" || type == "descriptionFieldPath") {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1 && listItem.type != FeildType.Attachment && listItem.type != FeildType.LookupAddon && listItem.type != FeildType.MultiSelection && listItem.type != FeildType.SingleSelection));

    } else if (type == "taggableText") {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1 && listItem.type != FeildType.Attachment && listItem.type != FeildType.LookupAddon && listItem.type != FeildType.MultiSelection && listItem.type != FeildType.SingleSelection));

    } else if (type == "ApplicantLookup") {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1) && listItem.type == FeildType.LookupAddon && listItem.fieldId == -1);
    } else if (type == "ShareWith") {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1) && listItem.type == FeildType.LookupAddon && (listItem.fieldId == -1 || listItem.fieldId == -2));
    }
    else if (type == "attachment") {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1) && listItem.type == FeildType.Attachment);
    }
    else if (type == "title" || type == "version" || type == "purpose") {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1) && listItem.type == FeildType.Text);
    }
    //  else if (type == FeildType.Money) {
    //  let filteredList: ActionFieldModel[] = [];
    //  customField.allowedValuesCriteriaArray = customField.allowedValuesCriteria.split(',');
    //  if (customField.allowedValuesCriteriaArray.length > 0) {
    //      list.forEach(itemActionField => {
    //        if (itemActionField.type == FeildType.Money) {
    //          var actionAllowedCurrency: string[] = itemActionField.allowedValuesCriteria.split(',');
    //          var addToList = true;
    //          for (var i = 0; i < customField.allowedValuesCriteriaArray.length; i++) {
    //            if (actionAllowedCurrency.indexOf(customField.allowedValuesCriteriaArray[i]) < 0){
    //              addToList = false;
    //              break;
    //            }
    //          }
    //          if (addToList) {
    //            filteredList.push(itemActionField);
    //          }
    //        }
    //      });
    //  }
    //  return filteredList.filter(listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1 && listItem.type == type));
    //}
    else {
      return this.actionFieldsDialogService.filterActionFields(list, listItem => (listItem.name.toLocaleLowerCase().indexOf(columnValue.toString().toLocaleLowerCase()) !== -1 && listItem.type == type));
    }
  }

}
