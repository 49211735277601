<div class="dialog-top-bar"></div>
<div [ngClass]="(dataSharingService.isRtl)?'rtl':'ltr'" [dir]="(dataSharingService.isRtl)?'rtl':'ltr'"
     class="dialog-content-container" [@cardSlide]="criteriaLocaleMapper.animate"
     *ngIf="criteriaLocaleMapper.activeLocale != null">
  <div mat-dialog-title>
    <div fxLayout="row">
      <div fxFlex>
        <div class="title" *ngIf="!isUpdate">
          {{(criteria.type == stageCriteriaType.Feedback)?("FEEDBACK.ADD-TITLE"|translate):("CRITERIA-DIALOG.ADD-TITLE"|translate)}}
          <span *ngIf="criteriaLocaleMapper.isMultiLanguageSystem()">
            ({{criteriaLocaleMapper.activeLocale.localeName}})
          </span>
        </div>
        <div class="title" *ngIf="isUpdate">
          {{(criteria.type == stageCriteriaType.Feedback)?("FEEDBACK.UPDATE-TITLE"|translate):("CRITERIA-DIALOG.UPDATE-TITLE"|translate)}}
          <span *ngIf="criteriaLocaleMapper.isMultiLanguageSystem()">
            ({{criteriaLocaleMapper.activeLocale.localeName}})
          </span>
        </div>
        <div class="title-description" *ngIf="!isUpdate">
          {{(criteria.type == stageCriteriaType.Feedback)?("FEEDBACK.ADD-DESCRIPTION"|translate):("CRITERIA-DIALOG.ADD-DESCRIPTION"|translate)}}.
        </div>
        <div class="title-description" *ngIf="isUpdate">
          {{(criteria.type == stageCriteriaType.Feedback)?("FEEDBACK.UPDATE-DESCRIPTION"|translate):("CRITERIA-DIALOG.UPDATE-DESCRIPTION"|translate)}}.
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
    <form #addStageForm="ngForm">

      <div fxLayout="column" *ngIf="criteria.type == stageCriteriaType.Feedback">

        <mat-form-field>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-select placeholder="{{'CRITERIA-DIALOG.FORM.FEEDBACK-FROM'|translate}}" [(ngModel)]="criteriaOwner"
                      name="ownerId" [disabled]="!criteriaLocaleMapper.isMappingFirstLocale()" required>
            <mat-option>
              <ngx-mat-select-search ngModel #filter="ngModel" [ngModelOptions]="{standalone: true}"
                                   [placeholderLabel]="'CRITERIA-DIALOG.FORM.FIND-FEEDBACK-FROM' | translate"
                                   [noEntriesFoundLabel]="'CRITERIA-DIALOG.FORM.NOT-FEEDBACK-FROM-FOUND-MSG' | translate">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of criteriaOwners | filterByProperty:['name',filter.value] ; let i = index"
                        [value]="item">
              <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                <div class="icon-owner-type-container" *ngIf="item.type == criteriaOwnerType.Users">
                  <img class="icon-owner-type" src="../../../assets/images/ic_list_member.png" />
                </div>
                <div class="icon-owner-type-container" *ngIf="item.type == criteriaOwnerType.JobRoles">
                  <img class="icon-owner-type" src="../../../assets/images/ic_list_group.png" />
                </div>
                <div class="icon-owner-type-container" *ngIf="item.type == criteriaOwnerType.UserLookup">
                  <img class="icon-owner-type" src="../../../assets/images/ic_list_list.png" />
                </div>
                <div>
                  {{item.name}}
                </div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex>
          <input matInput placeholder="{{'CRITERIA-DIALOG.FORM.TITLE'|translate}}" name="title" maxlength="100"
                 [(ngModel)]="criteria.name" [dir]='criteriaLocaleMapper.activeLocale.direction'
                 [ngStyle]="{'text-align':criteriaLocaleMapper.activeLocale.direction === 'rtl' ? 'right' : 'left' }"
                 required />
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field fxFlex>
            <input type="number" matInput placeholder="{{'CRITERIA-DIALOG.FORM.DURATION'|translate}}" name="duration"
                   [(ngModel)]="criteria.slaHours" [disabled]="!criteriaLocaleMapper.isMappingFirstLocale()"
                   (blur)="CheckReminderByDays()" required min=0 oninput="validity.valid||(value='');" />
          </mat-form-field>
        </div>

      </div>

      <div fxLayout="column" *ngIf="criteria.type == stageCriteriaType.Assessment">

        <mat-form-field fxFlex>
          <input matInput placeholder="{{'CRITERIA-DIALOG.FORM.TITLE'|translate}}" name="title" maxlength="200"
                 [(ngModel)]="criteria.name" [dir]='criteriaLocaleMapper.activeLocale.direction'
                 [ngStyle]="{'text-align':criteriaLocaleMapper.activeLocale.direction === 'rtl' ? 'right' : 'left' }"
                 required />
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field fxFlex>
            <input type="number" matInput placeholder="{{'CRITERIA-DIALOG.FORM.DURATION'|translate}}" name="duration"
                   [(ngModel)]="criteria.slaHours" [disabled]="!criteriaLocaleMapper.isMappingFirstLocale()"
                   (blur)="CheckReminderByDays()" required min=0 oninput="validity.valid||(value='');" />
          </mat-form-field>
        </div>
        <mat-form-field>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <!-- <mat-icon *ngIf="criteriaOwner != null" matSuffix class="option-cross" (click)="removeOwner();">
      close
    </mat-icon> -->
          <mat-select (selectionChange)="getOwnerId($event)" placeholder="{{'CRITERIA-DIALOG.FORM.OWNER'|translate}}"
                      [(ngModel)]="criteriaOwner" name="ownerId" [disabled]="!criteriaLocaleMapper.isMappingFirstLocale()"
                      required>
            <mat-option>
              <ngx-mat-select-search ngModel #filter="ngModel" [ngModelOptions]="{standalone: true}"
                                   [placeholderLabel]="'CRITERIA-DIALOG.FORM.FIND-OWNER' | translate"
                                   [noEntriesFoundLabel]="'CRITERIA-DIALOG.FORM.NOT-FOUND-MSG' | translate"></ngx-mat-select-search>
            </mat-option>
            <mat-option>
              None
            </mat-option>
            <mat-option *ngFor="let item of criteriaOwners | filterByProperty:['name',filter.value] ; let i = index"
                        [value]="item">
              <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                <div class="icon-owner-type-container" *ngIf="item.type == criteriaOwnerType.Users">
                  <img class="icon-owner-type" src="../../../assets/images/ic_list_member.png" />
                </div>
                <div class="icon-owner-type-container" *ngIf="item.type == criteriaOwnerType.JobRoles">
                  <img class="icon-owner-type" src="../../../assets/images/ic_list_group.png" />
                </div>
                <div class="icon-owner-type-container" *ngIf="item.type == criteriaOwnerType.UserLookup">
                  <img class="icon-owner-type" src="../../../assets/images/ic_list_list.png" />
                </div>
                <div>
                  {{item.name}}
                </div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <!-- <mat-icon *ngIf="criteria.reminder != null" matSuffix class="option-cross" (click)="removeReminder();">
      close
    </mat-icon> -->
          <mat-select placeholder="{{'CRITERIA-DIALOG.Reminders.Reminder'|translate}}" [(ngModel)]="criteria.reminder"
                      name="criteria.reminder" [disabled]="!criteriaLocaleMapper.isMappingFirstLocale()"
                      (selectionChange)="CheckReminderByDays()">
            <mat-option>
              None
            </mat-option>
            <mat-option *ngFor="let item of reminders" [value]="item.value">
              {{ item.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-checkbox [disabled]="reassignAssessmentsDisable" *ngIf="isUpdate" name="reassignAssessments"
                      [(ngModel)]="criteria.reassignAssessments">
          <span translate="SendDialog.Assign-Open">
            
          </span>
        </mat-checkbox>

        <mat-checkbox  name="reassignAssessments11" style="margin-top:20px;"
                      [(ngModel)]="criteria.isSigned">
          <span>
            Is Signed Criterion ?
          </span>
        </mat-checkbox>
      </div>
    </form>
    <br />
    <br />
    <br />
    <br />
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close class="btn-cancel">{{"CANCEL"|translate}}</button>
    <div fxFlex fxLayoutAlign="end end">
      <button mat-button class="hollow-primary-button" *ngIf="!criteriaLocaleMapper.isMappingFirstLocale()"
              (click)="criteriaLocaleMapper.mapPrevLocale()">
        {{"PREVIOUS"|translate}}
      </button>
      <button mat-button class="add-button-gradiant" [disabled]="!addStageForm.form.valid"
              *ngIf="!criteriaLocaleMapper.isMappingLastLocale()"
              (click)="criteriaLocaleMapper.mapNextLocale()">
        {{"NEXT"|translate}}
      </button>
      <button mat-button class="add-button-gradiant" (click)="onSubmit()"
              [disabled]="!addStageForm.form.valid || disableAddCriteriaButton"
              *ngIf="!isUpdate && criteriaLocaleMapper.isMappingLastLocale()">
        {{(criteria.type == stageCriteriaType.Feedback)?("FEEDBACK.ADD-REQUEST-FEEDBACK"|translate):"CRITERIA-DIALOG.ADD-TITLE"|translate}}
      </button>
      <button mat-button class="add-button-gradiant" (click)="onSubmit()"
              [disabled]="!addStageForm.form.valid || disableAddCriteriaButton"
              *ngIf="isUpdate && criteriaLocaleMapper.isMappingLastLocale()">
        {{(criteria.type == stageCriteriaType.Feedback)?("FEEDBACK.UPDATE-TITLE"|translate):"CRITERIA-DIALOG.UPDATE-TITLE"|translate}}
      </button>
    </div>
  </div>
</div>
