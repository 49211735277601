import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CreateDefinitionDialogComponent } from '../create-definition-dialog/create-definition-dialog.component';

@Injectable({
  providedIn: 'root'
})

export class DefinitionDialogService {
  constructor(
    private _matDialog: MatDialog
  ) {

  }
  openCreateDefinitionDialog(): MatDialogRef<CreateDefinitionDialogComponent> {
    return this._matDialog.open(CreateDefinitionDialogComponent, {
      width: '564px',
      closeOnNavigation: true,
      disableClose: true,
      panelClass: 'dialog-padding-32',
      autoFocus: false
    });
  }
}
