import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../shared-modules/pipes/pipes.module";
import { EspConfirmationDialogComponent } from "./esp-confirmation-dialog/esp-confirmation-dialog.component";

@NgModule({
    imports:[
        CommonModule,
        FlexLayoutModule,
        FormsModule, ReactiveFormsModule,
        CustomMaterialModule,
        TranslateModule,
        PipesModule,
    ],
    declarations: [
        EspConfirmationDialogComponent
    ],
    exports: [
        EspConfirmationDialogComponent
    ]
})
export class SharedDialogModule { }