import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ApplicantSectionType } from "../common/enums/ApplicantSectionType";
import { CustomFieldModel, FieldVisibilityModel } from "../profile-customization/Model/CustomFieldModel";
import { ApplicantDTO } from './ApplicantDTO';
import { Guid } from 'guid-typescript';
import { Tenant } from './tenant.model';
import { PaymentGatewayType } from '../common/enums/payment-gateway-type.enum'; 

export class CustomizationSettingsSubmitModel {
  constructor(

  ) { }

  public id: number;
  public name: string;
  public emailAddress: string;
  public idenediKey: string;
  public profilePicUrl: string;
  public profileTemplates: number[];
  public profileTemplateString: string;
  public sections: CustomizationSettingsSectionModel[];
  public templates: ProfileTemplate[];
  public subTenants: Tenant[]; 
}

export class ProfileTemplate{
  formId: number;
  isPublished: boolean;
  name: string;
  settings: CustomizationSettingsSectionModel[];
  isExpended: boolean;
}



export class FormSectionModel {
  constructor() {
    this.fields = [];
    this.isVisible = true;
    this.guid = Guid.create();
    this.extSecId = 0;
  }

  public id: number;
  public defaultName: string;
  public defaultNameLocale?: string;
  public isMultipule: boolean = false;
  public isTitleHidden?: boolean = false;
  public visibilityOn: string = 'both';
  public childAccessibility: boolean = false;
  public order?: number;
  public index: number;
  public fields: CustomFieldModel[] = [];
  public nestedSections: FormSectionModel[];
  public isExpended: boolean = false;
  public isRequiredMissing: boolean = true;
  public isActive: boolean;
  public lastUpdatedOn: Date;
  public type: ApplicantSectionType;
  public pgt?: PaymentGatewayType;
  public isDefault: boolean;
  public isDelete: boolean;
  public isBaseSection: boolean;
  public isValid?: boolean;
  public isFocused?: boolean;
  public isSelected?: boolean;
  public version?: number;
  public formGroup?: FormGroup;
  public isHidden: boolean = false;
  public visibility: FieldVisibilityModel;
  public isVisible: boolean = true;
  public pdfStyle: string;
  public pdfLimit: number;
  public guid?: Guid;
  public isWizardView?: boolean; 
  public isTableView?: boolean = false;
  public aiEnabled?: boolean = false;

  extRefSCFId?: number; 
  extSecId?: number; 
  extSecDisabledSCFIds?: string;//comma separated parent sections ids
  extSecPath?: any;
}

export class CustomizationSettingsSectionModel {
  constructor(

  ) {

  }

  public id: number;
  public defaultName: string;
  public isMultipule: boolean;
  public order?: number;
  public index: number;
  public fields: CustomizationSettingsField[];
  public nestedSections: CustomizationSettingsSectionModel[];
  public isExpended: boolean = false;
  public isRequiredMissing: boolean = true;
  public isActive: boolean;
  public lastUpdatedOn: Date;
  public type: ApplicantSectionType;
  public isDefault: boolean;
  // public isVisible: boolean = true;
}

export class CustomizationSettingsField {
  constructor(

  ) {
    this.details = {};

  }

  public id: number;
  public objectId: number;
  public label: string;
  public type: number;
  public value: string;
  public isRequired: boolean;
  public isReadOnly: boolean;
  public isVisible: boolean;
  public maxVal: number;
  public minVal: number;

  public minDate: Date;
  public maxDate: Date;

  public min: any;
  public max: any;

  public order?: number;
  public valueDate: Date;

  public fileContent: any;

  public sectionTemplateFiledId: number;

  public lookupValues: CustomizationSettingsLookupModel[];

  public details?: any;

  public lookUpId: number;
  public lookupValue: string;
  public selectedCurrencySymbol: number;
  public allowedValuesCriteria: string;
  public isTigger: boolean;
  public sectionCustomFieldId: number;
  public targetFieldType?: number; //used for mapped and calculated fields
  public selectedCurrencyId?: number;

  //public sigleSelectedValue: number;
  //public singleSelections: CustomFieldSingleSelection[];


}


//export class CustomFieldSingleSelection {
//  public id: number;
//  public value: string;
//  public isSelected: boolean;
//}

export class CustomizationSettingsLookupModel {
  public id: string;
  public label: string;
  public isSelected: boolean;
  public order: number;
  public color: string
  public conditions: []
}

export class ApplicantModelWithTemplate {
  public applicant: ApplicantDTO;
  public sections: CustomizationSettingsSectionModel[];
}
