<div mat-dialog-title>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog" class="btn-close">
        <mat-icon><img src="assets/images/icons/close.svg" alt="close"></mat-icon>
    </button>
</div>
<div fxLayout="row" fxLayoutAlign="center start" class="language-list-container">
    <div fxFlex="360px" fxFlex.sm="360px" fxFlex.xs="100">
        <div [dir]="(dataSharingService.isRtl)?'rtl':'ltr'" fxLayout="column">
            <div class="title" fxLayoutAlign="center start">
                Languages
            </div>
            <div class="gray-lang-text" fxLayoutAlign="center start">
                {{preferredLanguage?.name}}
            </div>
            <div fxLayout="column" fxLayoutGap="21px">
                <ng-container *ngFor="let lang of systemLanguages">
                    <div class="lang-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px"
                        (click)="switchLanguage(lang)">
                        <div class="lang-name">
                            {{lang.localeName}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>