import { SystemLanguage } from "../../models/system-language";

'use strict';

export const systemLanguages: SystemLanguage[] = [
  {
    locale: 'en',
    name: 'English',
    localeName: 'English',
    direction: 'ltr',
    isDefault: true,
    isActive: true
  },
  {
    locale: 'ar',
    name: 'Arabic',
    localeName: 'العربية',
    direction: 'rtl',
    isDefault: false,
    isActive: true
  },
  {
    locale: 'fr',
    name: 'French',
    localeName: 'français',
    direction: 'ltr',
    isDefault: false,
    isActive: false
  }
  //,{
  //  locale: 'ur',
  //  name: 'Urdu',
  //  localeName: 'اردو',
  //  direction: 'rtl',
  //  isDefault: false,
  //  isActive: true
  //}
];

export const categoryColors: string[] = [
  '#12b9c4',
  '#18cb71',
  '#3494e8',
  '#304ffe',
  '#717ebe',
  '#26abe7',
];

export const categoryColorsDD: any[] = [
  {
    'color': '#12b9c4',
    'ddColor': "rgba(18, 185, 196, 0.867)"
  },
  {
    'color': '#18cb71',
    'ddColor': "rgba(24, 203, 113, 0.867)"
  },
  {
    'color': '#3494e8',
    'ddColor': "rgba(52, 148, 232, 0.867)"
  },
  {
    'color': '#304ffe',
    'ddColor': "rgba(48, 79, 254, 0.867)"
  },
  {
    'color': '#717ebe',
    'ddColor': "rgba(113, 126, 190, 0.867)"
  },
  {
    'color': '#26abe7',
    'ddColor': "rgba(38, 171, 231, 0.867)"
  },
]

