import { CustomFieldModel } from './CustomFieldModel';
import { ApplicationSort } from '../../common/enums/application-sort-enum';
import { FormModel } from '../../definitions/models/application-definition';
import { ConstraintModel } from '../../models/trigger-model';

export class TemplateModel {
  constructor() {
    this.settings = [];
  }
  public id: number;
  public isBase: boolean;
  public isPublished: boolean;
  public isVisiable: boolean;
  public name: string;
  public form: FormModel;
  public settings: SettingModel[];
  public sectionCount: number;

  public enrolmentCriterion: EntrolmentType;
  public enrolmentDefinitionId: number;
  public enrolmentMessage: string;

  public isExpended: boolean;
}

export class TemplateModelWithProfileList extends TemplateModel{
  public uniqueId: number;
  public profileListId: number;
}

export class ProfileTemplateListModel {
  constructor() {

  }
  public id: number;
  public name: boolean;
  public profileTemplateId: number;
  public isVisiable: boolean;
  public constraint: ConstraintModel;
  public conditionFormatText: string;

}

export class SettingModel {
  constructor(

  ) { }

  public id: number;
  public defaultName: string;
  public isMultipule: boolean;
  public order?: number;
  public isPublished: boolean = false;
  public isMultipleChecked: boolean = false;
  public isExpended: boolean = false;
  public fields: CustomFieldModel[];
  public type: number;
  public isActive: boolean;
}

export enum EntrolmentType{
  ONLY_ADMIN = 'ONLY_ADMIN',
  StartAProcess = 'StartAProcess',
  AnyoneCanJoin = 'AnyoneCanJoin'
}
