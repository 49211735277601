import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { CustomFieldModel } from '../../../../profile-customization/Model/CustomFieldModel';
import { Loader } from '@googlemaps/js-api-loader';
@Component({
  selector: 'app-esp-map',
  templateUrl: './esp-map.component.html',
  styleUrls: ['./esp-map.component.scss']
})
export class EspMapComponent implements OnInit {
  lat:any;
  lng:any;
  center: any;
  public markerPositions: any;
  zoom: number;
  public mapVal;
  @Input() customField: CustomFieldModel;
  @Input() fieldOptions: any = { showLabel: true };
  @Input() isReadOnly: boolean = false;
  @Output() isValid = new EventEmitter();
  @Input() showHint: boolean = true;
  @Input() nestedFieldIndex: number = 0;
  @Input() nestedFieldsCount: number;
  @Input() addBtnForBoxControl: TemplateRef<any>;
  @Input() isLongLabel: TemplateRef<any>;
  @Input() tempNesterFieldIndex: TemplateRef<any>;
  mapsLoading = true; 
  constructor() {
  }
  // center: google.maps.LatLngLiteral = {lat: 33.6844, lng: 73.0479};
  
  markerOptions: google.maps.MarkerOptions = {draggable: true};
  options: google.maps.MapOptions = {
   fullscreenControl: true,
  };

  ngOnInit(): void {
    const loader = new Loader({
      apiKey: "AIzaSyA4uSFGNBhlAwBNZppNRLeSwzxiSxSve_M",
      version: "weekly",
    });

    loader.load().then(async () => {
      this.mapsLoading = false;
      // The Google Maps script is loaded, you can use the Maps API here
      this.mapsLoading = false;
      if (this.customField.isRequired) {
        this.isValid.emit(false);
      } else {
        this.isValid.emit(true);
      }
      if(this.isReadOnly == false) {
        this.getCurrentLocation();
      } else {
        this.getMapSubmittedValue();
      }
      // console.log('Is Required', this.customField.isRequired);
    });
  }
 
  getCurrentLocation() {
    if(this.customField.value) {
      this.getMapSubmittedValue();
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            var pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.markerPositions = pos;
            this.center = pos;
            this.zoom = 12;
            this.customField.value = this.markerPositions.lat.toString() + ' , ' + this.markerPositions.lng.toString();
            // console.log('Current Postion', this.markerPositions);
  
          }
        );
      }
    }
    
  }
  addMarker(event: any) {
    if(this.isReadOnly == false) {
      this.lat = event.latLng.lat(),
      this.lng = event.latLng.lng();
      this.markerPositions = {lat: this.lat, lng: this.lng};
      this.customField.value = this.markerPositions.lat.toString() + ',' + this.markerPositions.lng.toString();
      this.center = {lat: this.lat, lng: this.lng};
      this.isValid.emit(true);
      // console.log('Lat Lng' , this.customField.value); 
    }
  }
  markerDragEnd(event: any) {
      this.lat = event.latLng.lat(),
      this.lng = event.latLng.lng(),
      this.zoom = 12;
      this.customField.value = this.lat.toString() + ',' + this.lng.toString();
      this.center = {lat: this.lat, lng: this.lng};
      // console.log('center Position' , this.center); 
      // console.log('center Position Value' , this.customField.value); 
      this.isValid.emit(true); 
  }
  getMapSubmittedValue(){
    this.mapVal = this.customField.value.split(',');
      // console.log('Map Value', this.mapVal);
      var lat = Number(this.mapVal[0]);
      var lng = Number(this.mapVal[1]);
      // console.log('lat', lat);
      // console.log('lng', lng);
      
      var pos = {
        lat: lat,
        lng: lng,
      }
      this.center = pos;
      this.markerPositions = pos;
      this.zoom = 12;
      this.markerOptions.draggable = false;
  }

}
