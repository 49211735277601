import { NgModule } from '@angular/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MaterialEssentialsModule } from './material-essentials.module';
import { EspSearchTextBoxComponent } from './esp-search-text-box/esp-search-text-box.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EspMatSelectComponent } from './esp-mat-select/esp-mat-select.component';
import { TwoLevelMultiSelectComponent } from './two-level-multi-select/two-level-multi-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { AttachedFileDetailsComponent } from './attached-file-details/attached-file-details.component';
import { EspFileUploaderComponent } from './esp-file-uploader/esp-file-uploader.component';
import { TranslateModule } from '@ngx-translate/core';
import { EspContenteditableDirective } from './esp-contenteditable/esp-contenteditable.directive';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PipesModule } from '../shared-modules/pipes/pipes.module';
import { EspLocalizationComponent } from './esp-localization/esp-localization.component';
import { EspRatingComponent } from './esp-rating/esp-rating.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { EspSelectApplicantsComponent } from './esp-select-applicants/esp-select-applicants.component';
import { EspTaggableFieldComponent } from './esp-taggable-field/esp-taggable-field.component';
import { EspResizableInputComponent } from './esp-resizable-input/esp-resizable-input.component';
import { EspDatePickerComponent } from './esp-date-format-controls/esp-date-picker/esp-date-picker.component';
import { EspDateYearPickerComponent } from './esp-date-format-controls/esp-date-year-picker/esp-date-year-picker.component';
import { EspDateMonthYearPickerComponent } from './esp-date-format-controls/esp-date-month-year-picker/esp-date-month-year-picker.component';
import { EspDateRangePickerComponent } from './esp-date-format-controls/esp-date-range-picker/esp-date-range-picker.component';
import { ImagePreviewDialogComponent } from './attached-file-details/image-preview-dialog/image-preview-dialog.component';
import { QuillModule } from 'ngx-quill';
import { EspQuillEditorComponent } from './esp-quill-editor/esp-quill-editor.component';
import { SelectChipListControlComponent } from './select-chip-list-control/select-chip-list-control.component';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { EspTagsCustomFieldComponent } from './esp-tags-custom-field/esp-tags-custom-field.component';
import { GalleryDetailsComponent } from './gallery-details/gallery-details.component';
import { EspMultiFileUploaderComponent } from './esp-date-format-controls/esp-multi-file-uploader/esp-multi-file-uploader.component';
import { EspDateTimePickerComponent } from './esp-date-time-picker/esp-date-time-picker.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { EspCheckboxComponent } from './esp-checkbox/esp-checkbox.component';
import { EspRichTextEditorComponent } from './esp-date-format-controls/esp-rich-text-editor/esp-rich-text-editor.component';
import { EspMatSelectPaginationComponent } from './esp-mat-select-pagination/esp-mat-select-pagination.component';
import { DownloadFileDirective } from '../common/directives/download-file.directive';
import { SecurePipe } from '../common/pipes/secure.pipe';
import { EspTagableQuillEditorComponent } from './esp-tagable-quill-editor/esp-tagable-quill-editor.component';
import { EspFileUploaderTemplateWthContentComponent } from './esp-file-uploader-template-wth-content/esp-file-uploader-template-wth-content.component';
import { FileDetailsComponent } from './esp-file-uploader-template-wth-content/file-details/file-details.component';
import { ValidatorsModule } from '../common/modules/validators.module';
import { EspBtnActionComponent } from './esp-btn-action/esp-btn-action.component';
import { EspTagableFieldValueComponent } from '../form-builder/field-settings/esp-tagable-field-value/esp-tagable-field-value.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialEssentialsModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    TranslateModule,
    NgxMatSelectSearchModule,
    PipesModule,
    ScrollingModule,
    MatListModule,
    QuillModule.forRoot(),
    MatChipsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    DownloadFileDirective,
    SecurePipe,
    ValidatorsModule,
  ],
  exports: [
    EspSearchTextBoxComponent,
    EspMatSelectComponent,
    TwoLevelMultiSelectComponent,
    AttachedFileDetailsComponent,
    EspFileUploaderComponent,
    EspContenteditableDirective,
    EspLocalizationComponent,
    EspRatingComponent,
    EspSelectApplicantsComponent,
    EspTaggableFieldComponent,
    EspResizableInputComponent,
    EspDatePickerComponent,
    EspDateYearPickerComponent,
    EspDateMonthYearPickerComponent,
    EspDateRangePickerComponent,
    EspQuillEditorComponent,
    SelectChipListControlComponent,
    EspTagsCustomFieldComponent, 
    GalleryDetailsComponent,
    EspMultiFileUploaderComponent,
    EspDateTimePickerComponent,
    EspCheckboxComponent,
    EspMatSelectPaginationComponent,
    EspTagableQuillEditorComponent,
    EspFileUploaderTemplateWthContentComponent,
    FileDetailsComponent,
    EspBtnActionComponent,
    EspTagableFieldValueComponent
  ],
  declarations: [
    EspSearchTextBoxComponent,
    EspMatSelectComponent,
    TwoLevelMultiSelectComponent,
    AttachedFileDetailsComponent,
    ImagePreviewDialogComponent,
    EspFileUploaderComponent,
    EspContenteditableDirective,
    EspLocalizationComponent,
    EspRatingComponent,
    EspSelectApplicantsComponent,
    EspTaggableFieldComponent,
    EspResizableInputComponent,
    EspDatePickerComponent,
    EspDateYearPickerComponent,
    EspDateMonthYearPickerComponent,
    EspDateRangePickerComponent,
    EspQuillEditorComponent,
    SelectChipListControlComponent,
    EspTagsCustomFieldComponent,
    GalleryDetailsComponent,
    EspMultiFileUploaderComponent,
    EspDateTimePickerComponent,
    EspCheckboxComponent,
    EspRichTextEditorComponent,
    EspMatSelectPaginationComponent,
    EspTagableQuillEditorComponent,
    EspFileUploaderTemplateWthContentComponent,
    FileDetailsComponent,
    EspBtnActionComponent,
    EspTagableFieldValueComponent
  ],
})
export class EspControlsModule { }
