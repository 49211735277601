import { Component, OnInit, Inject, Output, EventEmitter, IterableDiffers, Input, ChangeDetectorRef, Renderer2, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ApplicantRequest } from '../models/applicant'
import { NameId } from '../../models/UserResult'
import { LabelService } from '../../common/services/label-service';
import { AllDefinitionsService } from '../../definitions/services/all-definitions.service';
import { ApplicantRequestService } from '../services/applicant-request.service'
import { ApplicationDefinition, DefinitionType } from '../../definitions/models/application-definition';
import { FeildType } from '../../common/enums/field-type-enum';
import { MessageService } from '../../common/services/message-service';
import { ThemeService } from '../../common/services/theme.service';
import { CustomFieldValueModel } from '../../models/custom-field-value-model';
import { CustomFieldService } from '../../common/services/custom-field.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DataSharingService } from '../../common/services/data-sharing.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationStatus, ApplicationStatusPost, ApplicationStatuses, ApplicationClasses } from '../../common/enums/application-status-enum';
import { TranslateToLocalePipe } from '../../common/pipes/translate-to-locale.pipe'
import { ComponentName } from '../../common/enums/component-names-enum';
import { CustomFieldModel } from '../../profile-customization/Model/CustomFieldModel';
import { ManageCurrenciesService } from '../../settings/manage-currencies/manage-currencies.service';
import { CurrencyModel } from '../../models/currency-model';
import { ApplicationService } from '../../application/services/application.service';
import { StageCriteria } from '../../definitions/models/stage-criteria';
import { RespondModel } from '../../models/applicationdetailmodel';
import { DefinitionStageModel, StageType } from '../../definitions/models/definition-stage';
import { SelectedApplicationService } from '../../common/services/selected-application-service';
import { SlideUpDown } from '../../common/animations/animations';
import { AccountService } from '../../account/services/account.service';
import { UserInfoService } from '../../common/services/userinfo-service';
import { TranslationHelperService } from '../../common/services/translation-helper.service';
import { Router, NavigationEnd, Event, NavigationStart, ActivatedRoute } from '@angular/router';
import { CustomFieldFormService } from '../../common/services/custom-field-form.service';
import * as _ from 'lodash';
import { FormSectionModel } from '../../models/CustomizationSettingsSubmitModel';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { GetIconService } from '../../common/services/get-icon.service';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { map, distinctUntilChanged, switchMap, tap, catchError } from 'rxjs/operators';
import { RequestSubmittedConfirmationDialogComponent } from '../../shared/submit-request-exceeders/request-submitted-confirmation-dialog/request-submitted-confirmation-dialog.component';
import { SubSink } from 'subsink';
import { IframeService } from '../../iframe/services/iframe.service';
import { EntrolmentType } from '../../profile-customization/Model/SettingModel';
import { OnlyAdminCanSubmitDialogComponent } from './only-admin-can-submit-dialog/only-admin-can-submit-dialog.component';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MultiSectionAggregateService } from '../../common/services/multi-section-aggregate.service';
import { CalculatedResponseModel, Submitters } from '../../models/calculated-api-response.model';
import { OpenSubmittedApplicationService } from '../../shared-modules/application-shared/services/open-submitted-application.service';
import { TagableFieldSerivice } from '../../common/services/tagableField.service';
import { SectionVisibility } from '../../common/enums/section-visibility-enum';
import { DraftConfirmationDialogComponent } from './draft-confirmation-dialog/draft-confirmation-dialog.component';
import { SnackbarType } from '../../common/enums/common-enum';
import { SectionValuesModel } from '../../models/section-values';
import { FillWithAiDialogComponent } from './fill-with-ai-dialog/fill-with-ai-dialog.component';

@Component({
  selector: 'app-submit-applicant-request',
  templateUrl: './submit-applicant-request.component.html',
  styleUrls: ['./submit-applicant-request.component.scss', '../../../assets/images/definition/information-tech/flaticon.css']
})
export class SubmitApplicantRequestComponent implements OnInit, OnDestroy {

  isCallback: boolean = false;
  hasPublishedDefinations: boolean = false;
  isDisabled: boolean = false;
  isUpdate: boolean = false;
  selectedDefinationId: number = null;
  optionList = [];
  applicationDefinations: ApplicationDefinition[] = [];
  currenciesList: CurrencyModel[] = [];
  FType: typeof FeildType = FeildType;
  showSpinner: boolean = true;
  showSpinner2: boolean = false;
  pageNumer: number = 0;
  sectionNumer: number = 0;
  totalRecords: number;
  public hasFields: boolean = true;
  public sectionLength: number;
  public applicationInfoFields: CustomFieldModelType = { category: new CustomFieldModel, application_number: new CustomFieldModel, email: new CustomFieldModel, name: new CustomFieldModel }; /*, separator: new CustomFieldModel*/
  public systemLogoExists: boolean = true;
  showLodingIndicator = true;
  public showSkeleton: boolean = true;

  isVerificationStep: boolean = false; //verification for emails + mobile numbers

  @Input() applicationId: number = 0;
  applicationDefinitionId: number = 0
  @Input('applicationDefinitionId')
  set setApplicationDefinitionId(id: number) {
    this.applicationDefinitionId = id;
  }
  @Input() onBehalfApplicantId: number = 0;
  @Input() resubmit: boolean = false;
  @Input() callFrom: string = '';
  @Input() definitionKey: string = '';
  @Input() applicantEmail: string = '';
  @Input() parentApplicationId: number = 0;
  @Output() draftSaved: EventEmitter<boolean> = new EventEmitter();
  @Output() submitted: EventEmitter<boolean> = new EventEmitter();
  @Output() applicationDetail: EventEmitter<any> = new EventEmitter();
  @Output() submitForAnonymous: EventEmitter<any> = new EventEmitter();

  applicationStatuses: typeof ApplicationStatuses = ApplicationStatuses;
  applicationStatusEnum: typeof ApplicationStatus = ApplicationStatus;
  applicationClasses: typeof ApplicationClasses = ApplicationClasses;
  componentName: typeof ComponentName = ComponentName;

  formFilesUplaodStatus: boolean[] = [];
  formFilesUplaoded: boolean = false;

  doFileUploadOnChange: boolean = false;
  public isUserLogin: boolean = false;
  public definitionExpired: boolean = false;
  /******************************Custom Field Validation From Start*********************************/
  public customValidation: boolean = true;
  private formSectionsValid: FormSectionValidity[] = [];
  public isCriteriaFormValid: boolean[] = [];
  public isModelChanged: boolean = false;
  public criteriaIndex = -1;
  public doFileUpload: boolean = false;

  public formIsValid: boolean = false;
  private applicantEmailIsValid: boolean = false;
  private isApplicantAlreadyMember: boolean = false;
  private applicantNameIsValid: boolean = false;
  private iterableDiffer: any;
  /******************************Custom Field Validation From End*********************************/
  customFeildList: any = [];
  private customFeildListSubject = new BehaviorSubject(this.customFeildList);
  private customFeildListSubjectSubscription$: Subscription;
  public dynamicFieldsValues: CustomFieldValueModel[] = [];
  public allowedSubmitters: Submitters[] = [];
  public showDynamicSpinner: boolean = false;
  private subs = new SubSink();
  public isMySpace = false;
  public isSubmission = false;
  submissionApplicantsArray: FormArray = new FormArray([]);
  notifySubmitters: FormControl = new FormControl(true);
  defintionType = DefinitionType;
  private _refDefId: number = 0; //used to populate definition lookup
  public refApplicationId: number = 0; //used to populate definition lookup
  public refScfId: number = 0; //used to navigate back to folder path 
  public feedOnBehalf: boolean = false;
  public tab = 0;
  public isRecord: boolean = false;
  public sectionVisibilityEnum = SectionVisibility;
  public filteredSections = [];
  public selectedMatStep: number = 0;
  public isDraft: boolean = true;
  public referencedDefinitionName: string;
  public folderAppFor: string;
  public assessmentId: number = 0;
  public subDefApplicationId: number = 0;
  public subDefApplicationName: string = ''; 
  public subApplicationType: boolean = false; 
  verificationFields:CustomFieldModel[]=[];
  allCodesVerified = false;
  

  emailVerificationRequired = false; 
  public notificationEnabled: boolean;
  public whoToInviteType: string;
  public clientMessage;
  public selectedTabIndex: number = 0;
  public cloneApplication: boolean = false;
  @ViewChild('inputTypeFile', { static: false }) inputTypeFile: ElementRef;
  public reviseApplication: boolean = false;

  constructor(private renderer: Renderer2,
    public getIcon: GetIconService,
    private applicantReqService: ApplicantRequestService, private _iterableDiffers: IterableDiffers, public labelService: LabelService,
    private _definitionService: AllDefinitionsService, private messageService: MessageService, private applicationService: ApplicationService,
    public themeService: ThemeService, public dataSharingService: DataSharingService, public translate: TranslateService
    , private translateToLocalePipe: TranslateToLocalePipe, private cdRef: ChangeDetectorRef, private _currencyService: ManageCurrenciesService,
    public selectedApplication: SelectedApplicationService, private accountService: AccountService, private _userInfoService: UserInfoService,
    private router: Router, public customFieldFormService: CustomFieldFormService, private _location: Location
    , private _translationHelperService: TranslationHelperService,
    public commonHelper: CommonHelperService
    , private _router: Router,
    private _matDialog: MatDialog,
    private _route: ActivatedRoute,
    private iframeService: IframeService,
    private _multisectionAggregateService: MultiSectionAggregateService,
    private _openSubmittedApplicationService: OpenSubmittedApplicationService,
    private _tagableFieldSerivice: TagableFieldSerivice,
  ) {
    this.subs.sink = this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLodingIndicator = true;
      }
      if (routerEvent instanceof NavigationEnd) {
        this.showLodingIndicator = false;
      }
    });
    this.subs.sink = this._route.queryParams.subscribe(params => {
      if (params['my'] && params['my'] == 'true') {
        this.isMySpace = true;
      }
      if (params['sub'] && params['sub'] == 'true') {
        this.isSubmission = true;
      }
      if (params['refDefId']) {
        this._refDefId = +params['refDefId'];
      }
      if (params['refAppId']) {
        this.refApplicationId = +params['refAppId'];
      }
      if(params['refScfId']) {
        this.refScfId = +params['refScfId'];
      }
      if (params['feedOnBehalf']) {
        this.feedOnBehalf = params['feedOnBehalf'] == 'true';
      }
      if (params['tab']) {
        this.tab = params['tab'];
      }
      if (params['defName']) {
        this.referencedDefinitionName = params['defName'];
      }
      if (params['folderAppFor']) {
        this.folderAppFor = params['folderAppFor'];
      }
      if (params['sAppId']) {
        this.subDefApplicationId = +params['sAppId'];
      }
      if (params['sAppName']) {
        this.subDefApplicationName = params['sAppName'];
      }
      if (params['clone'] == 'true') {
        this.cloneApplication = true;
        this.resubmit = true;
      }
      if (params['asId']) {
        this.assessmentId = +params['asId'];
      }
      if (params['appId']) {
        this.applicationId = +params['appId'];
      }
      if(params['isRecord'] == '1'){
        this.isRecord = true;
      }else{
        this.isRecord = false;
      }
      if(params['saType'] == '1'){
        this.subApplicationType = true;
      }else{
        this.subApplicationType = false;
      }
      if (params['revise'] == 'true') {
        this.reviseApplication = true;
        this.resubmit = true;
      }
    })
    /******************************Custom Field Validation From Start*********************************/
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    /******************************Custom Field Validation From End*********************************/


  }

  public filteredSection;
  public filteredSectionLength;
  setFormSectionsValid(sectionId, nestedSectionIndex, $event) {
    // console.log($event);
    
    const sectionToSetValidity = this.formSectionsValid.find(fsv => fsv.sectionId == sectionId && fsv.nestedSectionIndex == nestedSectionIndex);
    // console.log('sectionToSetValidity', sectionToSetValidity);
    if(sectionToSetValidity){
      sectionToSetValidity.validity = $event;
    }else{
      this.formSectionsValid.push({
        sectionId: sectionId,
        nestedSectionIndex: nestedSectionIndex,
        validity: $event
      });
    // console.log('formSectionsValid', this.formSectionsValid);
    }
    const section = this.customFeildList.form.sections.find(s => s.id == sectionId);
    // console.log('section', section);
    if(section.isMultipule){
      section.nestedSections[nestedSectionIndex].isValid = $event;
      section.isValid = section.nestedSections.filter(x => x.isValid == false).length == 0;
    }else{
      // console.log('event', $event);
      // setTimeout(() => {
        section.isValid = $event;
        // console.log(section.isValid);
        
      // }, 1000);
    }
  }
  trackByFn(index, section: FormSectionModel) {
    return section.id;
  }
  /******************************Custom Field Validation From Start*********************************/
  ngDoCheck() {
    //let changes = this.iterableDiffer.diff(this.formSectionsValid);
    //if (changes) {
      this.formIsValid = this.isSectionsValid(this.formSectionsValid);
    //}
    if (this.formSectionsValid.length == 0) {
      this.formIsValid = true;
    }

    this.criteriaIndex = -1;
    this.isCriteriaFormValid = [];
  }

  backClicked() {
    this._location.back();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  isFormValid(formSectionsValidParam: boolean[]) {
    let formIsValid = false;
    for (let _i = 0; _i < formSectionsValidParam.length; _i++) {
      if (_i == 0) {
        formIsValid = true;
      }
      if (formSectionsValidParam[_i] != null && formSectionsValidParam[_i] == false) {
        formIsValid = false;
        break;
      }
    }
    return formIsValid;
  }

  isSectionsValid(formSectionsValidParam: FormSectionValidity[]) {
    let formIsValid = false;
    for (let _i = 0; _i < formSectionsValidParam.length; _i++) {
      if (_i == 0) {
        formIsValid = true;
      }
      if (formSectionsValidParam[_i] != null && formSectionsValidParam[_i].validity == false) {
        formIsValid = false;
        break;
      }
    }
    return formIsValid;
  }

  toNumber(bin: string) {
    return parseInt(bin);
  }
  /******************************Custom Field Validation From End*********************************/
  ngOnDestroy() {
    if (this.customFeildListSubjectSubscription$) {
      this.customFeildListSubjectSubscription$.unsubscribe();
    }
    this.subs.unsubscribe();
  }

  ngOnInit() {

    if ((this.applicationDefinitionId > 0 || this.definitionKey != '') && (!this.cloneApplication  && !this.reviseApplication)) {

      this.getCustomFeilds(this.applicationDefinitionId);
    } else if (this.applicationId > 0 || this.cloneApplication || this.reviseApplication) {

      this.getApplicationDetails(this.applicationId);
      this.isUpdate = true;
      this.showSpinner2 = true;
    }
    //this.getPage(this.pageNumer);
    this.doFileUploadOnChange = true;//!(this.callFrom == this.componentName.AddApplicantRequestInpageComponent);
    this.isUserLogin = this.commonHelper.isUserLogIn();
  }

  getCurrenciesList(callFrom: string = '') {

    this._currencyService.getCurrencies().subscribe((resp: HttpResponse<CurrencyModel[]>) => {
      this.currenciesList = resp.body;
      if (callFrom == 'init') {
        if (this.applicationDefinitionId > 0) {
          this.getCustomFeilds(this.applicationDefinitionId);
        } else if (this.applicationId > 0) {
          this.getApplicationDetails(this.applicationId);
          this.isUpdate = true;
          this.showSpinner2 = true;
        }
      }
    },
      error => {
        this.currenciesList = [];
      }
    );
  }


  requestCompleted: boolean = false;
  getCustomFeilds(Id) {

    this.selectedDefinationId = Id;
    let request: any;
    if (this.selectedDefinationId > 0) {
      request = this._definitionService.getApplicationCustomFeilds(this.selectedDefinationId, this.parentApplicationId, this.iframeService.iframeFieldValues, this.onBehalfApplicantId);
    } else if (this.definitionKey != '') {
      request = this._definitionService.getApplicationDefinitionByKey(this.definitionKey);
    }

    request.subscribe(resp => {
      this.customFeildList = resp;
      this.customFeildList.isPublished = true;
      this.customFeildList.form.sections.forEach(s => s.isVisible = true);
      this.customFeildList.onBehalf = this.onBehalfApplicantId;
      if (this.customFeildList.endDate && this.commonHelper.isExpired(this.customFeildList.endDate)) {
        this.definitionExpired = true;
        this.messageService.showMessage(this._translationHelperService.getTranslationByKey('SUBMIT-APPLICATION-ERRORS.EXPIRED'));
      } else if (!this.customFeildList.isActive || (this.customFeildList.startDate && this.commonHelper.isNotStarted(this.customFeildList.startDate))) {
        this.definitionExpired = true;
        let errorMsg = this._translationHelperService.getTranslationByKey('SUBMIT-APPLICATION-ERRORS.EXPIRED-IN-DRAFT');
        errorMsg = errorMsg.replace('[DefinitionName]', this.customFeildList.name);
        this.messageService.showMessage(errorMsg);
      }
      this.customFeildList.form.sections = this.customFeildList.form.sections.filter(sec => sec.fields.filter(field => field.isVisible).length > 0);
      this.customFeildList.form.sections = CustomFieldFormService.mapGenerateNestedSections(this.customFeildList.form.sections, this.customFeildList.sectionValues, false);
      let filteredVisibilitySection = this.customFeildList.form.sections.filter(sec => sec.visibilityOn == this.sectionVisibilityEnum.Both || sec.visibilityOn == this.sectionVisibilityEnum.SubmissionForm);

      if ((this.customFeildList.form.sections.length == 0 || filteredVisibilitySection.length == 0) && this.customFeildList.type != this.defintionType.SUBMISSION ) {
        this.showSpinner2 = true;
        this.saveApplicationDefination();
        return;
      }
      //this.customFeildList.form.sections = CustomFieldFormService.initNestedSections(this.customFeildList.form.sections);
      this.formSectionsValid = [];

      this.customFeildList.stages.forEach((stage) => {
        stage.criteriaList.forEach((criteria) => {
          if (criteria.form.sections != undefined && criteria.form.sections.length > 0)
            criteria.form.sections[0].fields = CustomFieldService.mapGetValueModel(criteria.form.sections[0].fields, criteria.formValues);
        });
      });
      

      if (this.callFrom == this.componentName.SubmitRequestV2Component) {
        this.applicationInfoFields.email.type = this.FType.Email;
        this.applicationInfoFields.email.label = this._translationHelperService.getTranslationByKey('Common.EMAIL');
        this.applicationInfoFields.email.value = this.applicantEmail;
        this.applicationInfoFields.email.isRequired = true;
        this.applicationInfoFields.email.id = this.applicationId;

        this.applicationInfoFields.name.type = this.FType.Text;
        this.applicationInfoFields.name.label = this._translationHelperService.getTranslationByKey('Common.FULL-name');
        this.applicationInfoFields.name.value = "";
        this.applicationInfoFields.name.isRequired = true;
        this.applicationInfoFields.name.id = this.applicationId + 1;
      }
      this.requestCompleted = true;
      this.showSkeleton = false;
      this.checkCalculatedField();

      //check if there is any field in the form that needs email verification.
      this.isVerificationRequired();

      this.notificationEnabled = this.getNotificationEnabledValue(this.customFeildList.stages);
      this.whoToInviteType = this.getWhoToInviteType(this.customFeildList.stages);
      this.checkSectionWizardViewSupport(this.customFeildList.form.isWizardView, this.customFeildList.form.sections);
    }, (error: HttpErrorResponse) => {
      this.isCallback = false;
      this.showSkeleton = false;
      this.applicationSubmitServerErrors(error);
    });
  }

  private checkSectionWizardViewSupport(isWizardView: boolean, sections: FormSectionModel[]){
    if(isWizardView){
      const wizardSupportFields: FeildType[] = [FeildType.MultiLine, FeildType.MultiSelection, FeildType.Hyperlink, FeildType.Note, FeildType.Gallery, FeildType.Map, FeildType.Tags, FeildType.RichText];
      sections.filter(s => s.isMultipule).forEach(section => {
        if(section.fields.length <= 3 || section.fields.some(f => wizardSupportFields.includes(f.type) || f.isMultiLingual || f.isMultipleAllowed)){
          section.isWizardView = false; 
        } else {
          section.isWizardView = true; 
        }
      });
    }
  }

  getWhoToInviteType(stages) {
    let linkedStage = stages.filter(s => s.type == StageType.Link);
    if(linkedStage.length > 0) {
      let whoToInviteType = linkedStage[0]?.childDefinitionProperties.whoToInvite.type;
      return  whoToInviteType;
    }
  }
  getNotificationEnabledValue(stages) {
    let linkedStage = stages.filter(s => s.type == StageType.Link);
    if(linkedStage.length > 0) {
      let notificationEnabled = linkedStage[0]?.childDefinitionProperties.notificationEnabled;

      return  notificationEnabled;
    }
  }

  addNestedSection(sectionsList: FormSectionModel[], section: FormSectionModel) {
    //if (this.checkRequiredFieldsProvided(sectionsList[sectionsList.length - 1])) {
    this.customFieldFormService.addSection(sectionsList, section);
    this.isSecondCall = false;
    this.customFeildListSubject.next(this.customFeildList);
    //}
    this.sectionNumer = sectionsList.length - 1;

    this._multisectionAggregateService.calculateAggregateFields(this.customFeildList.form.sections);
    this._tagableFieldSerivice.calculateMinMaxFields(this.customFeildList.form.sections);
  }

  private checkRequiredFieldsProvided(section: FormSectionModel): boolean {
    var allFieldsProvided: boolean = true;
    for (var i = 0; i < section.fields.length; i++) {
      if (section.fields[i].isRequired && section.fields[i].isVisible && !section.fields[i].isReadOnly && !section.fields[i].value) {
        allFieldsProvided = false;
        break;
      }
    }
    return allFieldsProvided;
  }

  removeNestedSection(sectionsList: FormSectionModel[], index: number) {
    this.customFieldFormService.removeSectionByIndex(sectionsList, index);
    if (this.sectionNumer != 0) {
      this.sectionNumer--;
    }
    let timerId = setInterval(() => {
      this.ngDoCheck();
      this.formSectionsValid = [];
      this._multisectionAggregateService.calculateAggregateFields(this.customFeildList.form.sections);
      this._tagableFieldSerivice.calculateMinMaxFields(this.customFeildList.form.sections);
    }, 300);
    setTimeout(() => { clearInterval(timerId); }, 1000);
  }
  checkIfAnyFieldIsLocked(form): boolean {
    // Iterate through each section
    return form.sections.some(section =>
      section.fields.some(field => field.isReadOnly === true)
    );
  }
  updateFieldUnlockStatus(sections, unlockedFieldSCFIds): void {
    sections.forEach(section => {
      if (!Array.isArray(unlockedFieldSCFIds)) {
        console.error('unlockedFieldSCFIds is not an array.');
        return;
      }

      // Check and update fields in the current section
      section.fields.forEach(field => {
        if (unlockedFieldSCFIds.includes(field.sectionCustomFieldId)) {
          field.canUnLock = true;
        }
      });

      // Recursively check nested sections if any
      if (section.nestedSections && Array.isArray(section.nestedSections)) {
        this.updateFieldUnlockStatus(section.nestedSections, unlockedFieldSCFIds);
      }
    });
  }
  getApplicationDetails(appId) {
    this.isDisabled = false;
    this.showSpinner2 = true;
    console.log('get application details');

    this.applicantReqService.getApplicationDetails(appId, true).subscribe(
      data => {
        this.customFeildList = data.body;
        // if(this.resubmit) {
          console.log('this.checkIfAnyFieldIsLocked(this.customFeildList.form)', this.checkIfAnyFieldIsLocked(this.customFeildList.form));
          console.log('this.customFeildList', this.customFeildList);
          
          if(this.checkIfAnyFieldIsLocked(this.customFeildList.form)) {
            this.applicantReqService.getApplicationUnlockedField(appId).subscribe(resp => {
              let unlockedFieldSCFIds = resp.body;
              // console.log('unlockedFieldSCFIds', unlockedFieldSCFIds);
              
              this.updateFieldUnlockStatus(this.customFeildList.form.sections, unlockedFieldSCFIds)
              console.log('this.customFeildList.form.sections', this.customFeildList.form.sections);
              
              
            })
          }
          
        // }
        this.customFeildList.form.sections.forEach(s => s.isVisible = true);
        if (!this.customFeildList.isActive || (this.customFeildList.endDate && this.commonHelper.isExpired(this.customFeildList.endDate)) || (this.customFeildList.startDate && this.commonHelper.isNotStarted(this.customFeildList.startDate))) {
          this.definitionExpired = true;
          let errorMsg = this._translationHelperService.getTranslationByKey('SUBMIT-APPLICATION-ERRORS.EXPIRED-IN-DRAFT');
          errorMsg = errorMsg.replace('[DefinitionName]', this.customFeildList.name);
          this.messageService.showMessage(errorMsg);
        }
        this.customFeildList.form.sections = this.customFeildList.form.sections.filter(sec => sec.fields.filter(field => field.isVisible).length > 0);
        if (this.customFeildList.form.sections.length == 0 && this.customFeildList.type != this.defintionType.SUBMISSION) {
          this.showSpinner2 = true;
          this.saveApplicationDefination();
          return;
        }
        //this.customFeildList.form.sections[0].fields = CustomFieldService.mapGetValueModel(this.customFeildList.form.sections[0].fields, this.customFeildList.formValues);
        this.customFeildList.form.sections = CustomFieldFormService.mapGenerateNestedSections(this.customFeildList.form.sections, this.customFeildList.sectionValues, false, this.isDraft ?? this.resubmit);
        this.isDisabled = ((this.customFeildList.applicationStatusId != this.applicationStatuses.New && this.customFeildList.applicationStatusId != this.applicationStatuses.Invited) ? true : false)
        this.showSpinner2 = false;
        if (this.callFrom == this.componentName.RequestDetailsComponent) {
          this.applicationInfoFields.category.type = this.FType.Text;
          this.applicationInfoFields.category.label = "CATEGORY";
          this.applicationInfoFields.category.value = this.customFeildList.category;
          this.applicationInfoFields.application_number.type = this.FType.Text;
          let applicationLabel = this.labelService.getLabel().application;
          this.applicationInfoFields.application_number.label = applicationLabel + ' #';
          this.applicationInfoFields.application_number.value = this.customFeildList.applicationNumber;
          //this.applicationInfoFields.separator.type = this.FType.Separator;
          //this.applicationInfoFields.separator.label = "Invoice Details";
          this.customFeildList.stages.forEach((stage) => {
            stage.criteriaList.forEach((criteria) => {
              if (criteria.form.sections != undefined && criteria.form.sections.length > 0)
                criteria.form.sections[0].fields = CustomFieldService.mapGetValueModel(criteria.form.sections[0].fields, criteria.formValues);
              criteria.form.sections.forEach((section) => {
                section.isExpended = true;
              });
            });
          });
        }
        this.selectedApplication.setApplicationName = this.customFeildList.name;
        this.applicationDetail.emit(this.customFeildList);
        this.requestCompleted = true;
        this.showSkeleton = false;
        this.checkCalculatedField();
        // if(this.customFieldFormService.getEmailVerificationFields(this.customFeildList.form).length > 0){
        //   this.emailVerificationRequired = true; 
        // }
        this.notificationEnabled = this.getNotificationEnabledValue(this.customFeildList.stages);
        this.checkSectionWizardViewSupport(this.customFeildList.form.isWizardView, this.customFeildList.form.sections);
      },
      (err: HttpErrorResponse) => {
        this.customFeildList = [];
        this.showSpinner2 = false;
        this.showSkeleton = false;
        if (err.status == 409) {
          this.applicationSubmitServerErrors(err);
        }
      }
    );
  }

  showImageBrowseDlg(customID) {
    let elem: Element = document.getElementById(customID)
    let event = new MouseEvent('click', { bubbles: true });
    elem.dispatchEvent(event);
  }

  dataFiles: FormData = new FormData();

  onFileSelection(customObj, event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      let id = event.target.id;
      this.dataFiles.append(id, file);
      customObj.value = file.name;
    }
  }

  onDeleteAttachment(objCustom) {
    this.dataFiles.delete(objCustom.id);
    objCustom.value = "";
  }

  async saveApplicationDefination(param?) {
    this.isDraft = param ?? true;
    if (this._userInfoService.email) {
      this.doFileUpload = true;
      const msg = await this.fileUploadWait();
      this.doFileUpload = false;

      this.isCallback = true;
      //this.customFeildList.formValues = this.mapPostValue();
      this.customFeildList.sectionValues = CustomFieldFormService.mapFormFieldsPostValue(this.customFeildList.form.sections);
      if (this.callFrom == this.componentName.SubmitRequestV2Component && !this.cloneApplication)
        this.dataSharingService.applicationDefinition.next(<ApplicationDefinition><any>this.customFeildList);
      if ((this.resubmit && this.callFrom != ComponentName.ApplicationDetailComponent && !this.reviseApplication ) || this.cloneApplication) {
        this.customFeildList.referenceApplicationId = this.customFeildList.applicationId;
        this.customFeildList.applicationId = 0;
      }
      this._definitionService.submitApplication(this.customFeildList, this.onBehalfApplicantId, this.submissionApplicantsArray, <ComponentName>this.callFrom, this.isDraft, this.notifySubmitters.value, this.assessmentId, this.reviseApplication).subscribe(resp => {
        
        //when form-submission is from last phone/email verification
        if(this.isVerificationStep)
        {
          this.isVerificationStep = false;
        }

        this.isCallback = false;
        if (this.callFrom == this.componentName.SubmitRequestV2Component) {
          sessionStorage.clear();
          this.router.navigateByUrl('/submit/request/success');
        }
        this.submitted.emit(true);

        if (this.callFrom == this.componentName.AddApplicantRequestInpageComponent || this.callFrom == ComponentName.ApplicationDetailComponent) {
          if (this.callFrom == this.componentName.AddApplicantRequestInpageComponent && resp.openApplication && !this.parentApplicationId || this.reviseApplication) {
            this._openSubmittedApplicationService.applicationId = resp.applicationid;
            // this._openSubmittedApplicationService.openFirstStage = true;
          }
          if (this.isDraft) {

            console.log("Hello Submit Function", resp);

            this.openRequestSubmittedConfirmationDialog(resp.message);
          } else {

            if (this.refApplicationId > 0) {
              this.router.navigate(
                ['/applications/' + this.refApplicationId +'/folder/' + this.customFeildList.id + '/' + this.refScfId],
                { queryParams: { my: this.isMySpace, tab: this.tab, type: this.isRecord ? 'record' : ''  } }
              );
            } else if (this.feedOnBehalf) {
              if(this.isRecord){
                this.router.navigate(
                  [`/records/${this.parentApplicationId}`],
                  { queryParams: { my: this.isMySpace, tab: this.tab, type: 'record' } }
                );
              } else {
                this.router.navigate(
                  [`/applications/${this.parentApplicationId}`],
                  { queryParams: { my: this.isMySpace, tab: this.tab } }
                );
              }
            } else {
              if (!(this.callFrom == ComponentName.ApplicationDetailComponent)) {
                if (!this.isSubmission) {
                  if(this._openSubmittedApplicationService.applicationId > 0){
                    if(this.isRecord){
                      this.router.navigate(
                        [`/records/${this._openSubmittedApplicationService.applicationId}`],
                        { queryParams: { my: this.isMySpace, tab: this.tab, type: 'record' } }
                      );
                    } else {
                      this.router.navigate(
                        [`/applications/${this._openSubmittedApplicationService.applicationId}`],
                        { queryParams: { my: this.isMySpace, tab: this.tab} }
                      );
                    }
                    
                  }else{
                    if(this.isRecord){
                      this.router.navigateByUrl("/records");
                    }else{
                      this.router.navigate([`/applications`], {queryParams: {tab: 1}});
                    }
                  }
                } else {
                  this.router.navigate(
                    [`/applications/submission/${this.selectedDefinationId}/${this.parentApplicationId} `],
                    { queryParams: { my: this.isMySpace, tab: this.tab } }
                  );
                }
              }
            }
            // this._location.back();
            // console.log("Hello Draft Function");
            // if(this.isRecord){
            //   this.router.navigateByUrl("/records");
            // }else{
            //   this.router.navigate([`/applications`], {queryParams: {tab: 1}});
            // }
          }
          //this.messageService.showMessage(this.labelService.labels.application + " " + this._translationHelperService.getTranslationByKey('Add-Applicant-Request.SUBMITED-SUCCESSFULLY'));
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 409) {
            this.applicationSubmitServerErrors(err);
          }
          this.showSpinner2 = false;
          this.isCallback = false;
        }
      )
    } else {
      this.isCallback = true;
      this.submitForAnonymous.emit(true);
    }
  }

  private applicationSubmitServerErrors(err: HttpErrorResponse) {
    let clientMessageString = JSON.parse(err.error.clientMessage);
    if (err.error.code == 'SubmissionDatePassed') {
      this.messageService.showMessage(this._translationHelperService.getTranslationByKey('SUBMIT-APPLICATION-ERRORS.EXPIRED'));
    } else if (err.error.code == 'SubmissionDateNotStarted' || err.error.code == 'DefinitionDisabled') {
      let errorMsg = this._translationHelperService.getTranslationByKey('SUBMIT-APPLICATION-ERRORS.EXPIRED-IN-DRAFT');
      if (this.customFeildList.name) {
        errorMsg = errorMsg.replace('[DefinitionName]', this.customFeildList.name);
      } else {
        errorMsg = errorMsg.replace('[DefinitionName]', 'this ' + this.labelService.labels.definition);
      }
      this.messageService.showMessage(errorMsg);
    } else if (err.error.code == EntrolmentType.StartAProcess) {
      this.router.navigateByUrl(`/request/submit/${err.error.clientMessage}/0?type=definition`);
      this.showSkeleton = true;
      this.getCustomFeilds(err.error.clientMessage);
    } else if (err.error.code == EntrolmentType.ONLY_ADMIN) {
      this._matDialog.open(OnlyAdminCanSubmitDialogComponent, {
        width: '552px',
        autoFocus: false,
        data: err.error.clientMessage,
        closeOnNavigation: true,
        direction: this.dataSharingService.isRtl ? 'rtl' : 'ltr'
      });
    } else if(err.status == 409 && clientMessageString?.SCFId)
    {
      this.clientMessage =JSON.parse(err.error.clientMessage);
      this.messageService.showMessage(err.error.code ,4000, SnackbarType.Error);
    }
    else {
      this.messageService.showMessage(this._translationHelperService.getTranslationByKey('SUBMIT-APPLICATION-ERRORS.PROFILE-NOT-COMPLETE'));
    }
  }

  fileUploadWait() {
    return new Promise((resolve, reject) => {
      var thisRef = this;
      const interval = setInterval(function () {
        if (thisRef.isFormValid(thisRef.formFilesUplaodStatus) || !this.doFileUploadOnChange) {
          clearInterval(interval);
          resolve(true);
        }
      }, 10);
    });
  }

  async saveAsDraftApplication() {
    this.doFileUpload = true;
    const msg = await this.fileUploadWait();
    this.doFileUpload = false;

    this.isCallback = true;
    this.customFeildList.sectionValues = CustomFieldFormService.mapFormFieldsPostValue(this.customFeildList.form.sections);
    this._definitionService.saveAsDraftApplication(this.customFeildList).subscribe(data => {
      let id = data;
      this.messageService.showMessage(this.labelService.labels.application + " " + this._translationHelperService.getTranslationByKey('Add-Applicant-Request.SAVED-SUCCESSFULLY'));
      //this.dialogRef.close("draft");
      this.isCallback = false;
      this.draftSaved.emit(true);
      if (this.callFrom == this.componentName.AddApplicantRequestInpageComponent)
        this.router.navigateByUrl("/applications");
    },
      (error: HttpErrorResponse) => {
        this.isCallback = false;
        if (error.status == 409) {
          this.applicationSubmitServerErrors(error);
        }
      }
    )
  }

  mapPostValue() {
    let customFieldValues: any = [];

    this.customFeildList.form.sections.forEach(section => {
      customFieldValues = customFieldValues.concat(CustomFieldService.mapPostValueModel(section.fields, section.id));
    });

    return customFieldValues;
  }

  setApplicationStatuses(status: string, statusId: number): string {
    let applicationStatus: string = '';

    switch (statusId) {
      case (this.applicationStatuses.Invited):
        applicationStatus = this.applicationStatusEnum.Invited;
        break;

      case (this.applicationStatuses.New):
        applicationStatus = this.applicationStatusEnum.New;
        break;

      case (this.applicationStatuses.Accepted):
        //case (this.applicationStatuses.Approved):
        applicationStatus = this.applicationStatusEnum.Accepted;
        break;

      case (this.applicationStatuses.Rejected):
        applicationStatus = this.applicationStatusEnum.Rejected;
        break;

      default:
        applicationStatus = this.translateToLocalePipe.transform(status);
        break;
    }
    return applicationStatus;
  }

  setApplicationClass(statusId: number): string {
    let applicationClass: string = '';

    switch (statusId) {
      case (this.applicationStatuses.Invited):
        applicationClass = 'application-status-invited';
        break;

      case (this.applicationStatuses.New):
        applicationClass = 'application-status-new';
        break;

      case (this.applicationStatuses.Accepted):
        //case (this.applicationStatuses.Approved):
        applicationClass = 'application-status-approved';
        break;

      case (this.applicationStatuses.Rejected):
        applicationClass = 'application-status-rejected';
        break;

      default:
        applicationClass = 'application-status-under-process';
        break;
    }
    return applicationClass;
  }

  toggleSectionMatCard(section, isExpended) {
    section.isExpended = !isExpended;
  }

  getCriteriaIndex(incrementor) {
    this.criteriaIndex = this.criteriaIndex + incrementor;
    return this.criteriaIndex;
  }

  isSystemLogoExist(e) {
    this.systemLogoExists = false;
  }

  respondCriteriaCallback(criteriaModel: StageCriteria, stageModel: DefinitionStageModel) {
    let Keys = {
      "FEEDBACK.SUBMIT-MSG": "Your feedback has been submitted."
    };
    let criteriaRespond: RespondModel = new RespondModel();
    this.translate.get(["FEEDBACK.SUBMIT-MSG"]).subscribe(translation => {
      Keys = translation;
    });

    criteriaRespond.applicationId = this.applicationId;
    criteriaRespond.assessmentId = criteriaModel.assessmentId;
    criteriaRespond.comments = '';
    criteriaRespond.isAccepted = true;
    criteriaRespond.stageId = stageModel.id;
    criteriaRespond.values = this.mapCriteriaPostValue(criteriaModel);

    this.applicationService.respondCriteria(criteriaRespond).subscribe(
      resp => {
        this.getApplicationDetails(this.applicationId);
        if (criteriaRespond.isAccepted) {
          this.messageService.showMessage(Keys['FEEDBACK.SUBMIT-MSG']);
        }
      },
      err => {
      }
    );
  }

  mapCriteriaPostValue(criteria: StageCriteria) {
    let customFieldValues: CustomFieldValueModel[] = [];

    criteria.form.sections.forEach(section => {
      customFieldValues = customFieldValues.concat(CustomFieldService.mapPostValueModel(section.fields, section.id, criteria.formValues));
    });

    return customFieldValues;
  }

  //authenticateUserAndNext() {
  //  this.isCallback = true;
  //  this.accountService.submitRequestUserAuthentication(this.applicationInfoFields.email.value, this.applicationInfoFields.name.value, this.definitionKey).subscribe((data: any) => {

  //    var result = {
  //      token: {
  //        accessToken: data,
  //        expiry: '',
  //        refreshToken: '',
  //        persoans: []
  //      },
  //      user: {
  //        name: '',
  //        email: '',
  //        role: '',
  //        imageUrl: '',
  //        organizationId: 0
  //      }
  //    };

  //    sessionStorage.setItem('ngStorage-auth', JSON.stringify(result));

  //    if (result.token.accessToken == '') {
  //      //this.customFeildList.formValues = this.mapPostValue();
  //      this.customFeildList.sectionValues = CustomFieldFormService.mapFormFieldsPostValue(this.customFeildList.form.sections);
  //      this.customFeildList.applicantEmail = this.applicationInfoFields.email.value;
  //      this.customFeildList.definitionKey = this.definitionKey;
  //      this.dataSharingService.applicationDefinition.next(<ApplicationDefinition><any>this.customFeildList);
  //      this.router.navigateByUrl('/submit/request/login');
  //    } else {
  //      this.saveApplicationDefination();
  //    }
  //  },
  //    (err: HttpErrorResponse) => {
  //      this.isCallback = false;
  //    });
  //}

  applicantAlreadyIsAMember(applicantEmailIsValid) {
    debugger;
    this.applicantEmailIsValid = applicantEmailIsValid;
    let emailRegularExpression: RegExp = /^\w+([-+.']\w+)*@\w+([-. ]\w+)*\.\w+([-. ]\w+)*$/;
    if (emailRegularExpression.test(this.applicationInfoFields.email.value))
      this.accountService.isApplicantExists(this.applicationInfoFields.email.value).subscribe((data: any) => {
        if (data.length > 0) {
          this.applicationInfoFields.name.value = data;
          this.isApplicantAlreadyMember = true;
        } else {
          this.isApplicantAlreadyMember = false;
        }
      });
  }

  getNumbersArray(arrayNumber: number): number[] {
    let numberArray: number[] = [];
    for (let i = 0; i < arrayNumber; i++) {
      numberArray.push(i + 1);
    }
    return numberArray;
  }

  nextSection() {
    //if (this.formIsValid) {
    if ((this.pageNumer + 1) == this.customFeildList.form.sections.length) {
    } else if ((this.pageNumer + 1) != this.customFeildList.form.sections.length) {
      this.pageNumer++;
      this.isSecondCall = false;
      this.customFeildListSubject.next(this.customFeildList);
    }
    this.sectionNumer = 0;
    setTimeout(() => {
      this.formSectionsValid = [];
      this.ngDoCheck();
      this.cdRef.detectChanges();
    }, 100);

  }

  previousSection() {
    if (this.pageNumer != 0) {
      this.pageNumer--;
      this.isSecondCall = false;
      this.customFeildListSubject.next(this.customFeildList);
    }
    this.sectionNumer = 0;
    setTimeout(() => {
      this.formSectionsValid = [];
      this.ngDoCheck();
      this.cdRef.detectChanges();
    }, 100);
  }

  updateValidations() {
    setTimeout(() => {
      this.formSectionsValid = [];
      this.ngDoCheck();
      this.cdRef.detectChanges();
    }, 100);
  }

  onFormFieldBlur(isTrigger: boolean) {
    
    //check if there is any field in the form that needs email verification.
    this.isVerificationRequired();

    if (isTrigger) {
      this.showDynamicSpinner = true;
      this.isSecondCall = false;
      this.customFeildListSubject.next(this.customFeildList);
      this._tagableFieldSerivice.calculateMinMaxFields(this.customFeildList.form.sections);
    } else {
      if(this._multisectionAggregateService.calculateAggregateFields(this.customFeildList.form.sections)){
        this.customFeildListSubject.next(this.customFeildList);
      }
      this._tagableFieldSerivice.calculateMinMaxFields(this.customFeildList.form.sections);
    }
  }

  showVerificationStep()
  {
    this.isVerificationStep = true;
  }

  private isSecondCall = false;
  private checkCalculatedField() {
    //const sections = this.customFeildList.form.sections.map((section: FormSectionModel) =>
    //  section.fields.filter(f => f.isTigger || f.type == FeildType.Calculated || f.type == FeildType.Mapped || (f.type == FeildType.LookupAddon && f.allowedValuesCriteria && JSON.parse(f.allowedValuesCriteria).length > 0)).length > 0
    //);
    //if (sections.filter(s => s).length > 0) {
    this.customFeildListSubjectSubscription$ = this.customFeildListSubject.pipe(
      //map(definition => definition),
      //distinctUntilChanged((a, b) => {
      //  if (b.form) {
      //    if (a.form) {
      //      return _.isEqual(a.form.sections.map(sectionA => sectionA.nestedSections), b.form.sections.map(sectionB => sectionB.nestedSections))
      //    } else {
      //      return false;
      //    }
      //  } else {
      //    return false;
      //  }
      //}),
      switchMap(definition => {
        this.isCallback = true;
        if(this.cloneApplication) {
          definition.applicationId = 0;
          definition.applicationStatusId = 1;
        } else {
          definition.applicationId = definition.applicationId;
          definition.applicationStatusId = definition.applicationStatusId;
        }

        return !definition.form ? definition : this.applicationService.getCalcuateValues(definition, this.definitionKey, this.onBehalfApplicantId, this.resubmit)
          .pipe(
            catchError(err => {
              this.isCallback = false;
              let model: CalculatedResponseModel = {
                allowedSubmitters: [],
                conditionalFields: [],
                conditionalSections: [],
                dynamicCurrencies: [],
                values: [],
                extSectionValues: [],

              };
              return of(model);
            })
          )
      }),
      tap((fieldValues: CalculatedResponseModel) => {
        this.allowedSubmitters = fieldValues.allowedSubmitters;
        this.mapFieldValues(fieldValues);
      })
    ).subscribe();
    this.customFieldFormService.assignApplicationToDefinitionLookups(this.customFeildList.form.sections, this._refDefId, this.refApplicationId)
    this.customFeildListSubject.next(this.customFeildList);
    //}
  }
  private fillAIFields(formData, sectionId: number) {
    
    this.customFeildListSubjectSubscription$ = this.customFeildListSubject.pipe(
      
      switchMap(definition => {
        this.isCallback = true;
        if(this.cloneApplication) {
          definition.applicationId = 0;
          definition.applicationStatusId = 1;
        } else {
          definition.applicationId = definition.applicationId;
          definition.applicationStatusId = definition.applicationStatusId;
        }

        return this.applicationService.getDefinitionData(formData, this.applicationDefinitionId, sectionId)
          .pipe(
            catchError(err => {
              this.isCallback = false;
              let model: CalculatedResponseModel = {
                allowedSubmitters: [],
                conditionalFields: [],
                conditionalSections: [],
                dynamicCurrencies: [],
                values: [],
                extSectionValues: [],

              };
              return of(model);
            })
          )
      }),
      tap((resp : CustomFieldValueModel[]) => {
        let fieldValues: CalculatedResponseModel = {} as CalculatedResponseModel;
        fieldValues.allowedSubmitters = [],
        fieldValues.conditionalFields = [],
        fieldValues.conditionalSections = [],
        fieldValues.values = resp,
        fieldValues.extSectionValues = [],
        fieldValues.dynamicCurrencies = [],
        
        this.mapFieldValues(fieldValues);
      })
    ).subscribe();
    this.customFieldFormService.assignApplicationToDefinitionLookups(this.customFeildList.form.sections, this._refDefId, this.refApplicationId)
    this.customFeildListSubject.next(this.customFeildList);
  }
  mapFieldValues(fieldValues: CalculatedResponseModel) {
    this.allowedSubmitters = fieldValues.allowedSubmitters;
        let formattedExtSectionValues: SectionValuesModel[] = [];
        if (Array.isArray(fieldValues.extSectionValues) && fieldValues.extSectionValues.length > 0) { 
          formattedExtSectionValues = []; // Clear array before using it
          fieldValues.extSectionValues.forEach(sec => {
            let sectionValue: SectionValuesModel = new SectionValuesModel();
            sectionValue.id = sec.id;
            sectionValue.instances = sec.instances;
            formattedExtSectionValues.push(sectionValue);
          });
        
          console.log('formattedExtSectionValues', formattedExtSectionValues);
          console.log('this.customFeildList.sectionValues', this.customFeildList.sectionValues);
          const formattedExtSectionValuesIds = formattedExtSectionValues.map(item => item.id);

          this.customFeildList.sectionValues = this.customFeildList.sectionValues.filter(sectionValue => {
            // Keep items where the id does not match any id in formattedExtSectionValues
            return !formattedExtSectionValuesIds.includes(sectionValue.id);
          });
          this.customFeildList.sectionValues = this.customFeildList.sectionValues.concat(formattedExtSectionValues);
          console.log('this.customFeildList.sectionValues', this.customFeildList.sectionValues);
        
          this.customFeildList.form.sections = CustomFieldFormService.mapGenerateNestedSections(
            this.customFeildList.form.sections,
            this.customFeildList.sectionValues,
            false
          );
        }

        if (Array.isArray(fieldValues.dynamicCurrencies) && fieldValues.dynamicCurrencies.length > 0) { 
          this.customFieldFormService.assignValuesForCurrencyFields(fieldValues.dynamicCurrencies, this.customFeildList.form.sections)
        }

        this.customFieldFormService.assignValuesForMappedAndCalcualtedTypes(fieldValues.values, this.customFeildList.form.sections, fieldValues.conditionalFields, fieldValues.conditionalSections, this.resubmit);
        this.dynamicFieldsValues = fieldValues.values;
        this.showDynamicSpinner = false;
        if(this._multisectionAggregateService.calculateAggregateFields(this.customFeildList.form.sections) && !this.isSecondCall){
          this.isSecondCall = true;
          this.customFeildListSubject.next(this.customFeildList);
        } else {
          const secVisibilty = this.customFeildList.form.sections.filter(sec => sec.nestedSections.every(n=> n.fields.filter(field => field.isVisible).length > 0));
          if(secVisibilty.length == 0 && this.customFeildList.type != this.defintionType.SUBMISSION){
            this.showSpinner2 = true;
            this.saveApplicationDefination();
          }
        }
        this.isCallback = false;
        this._tagableFieldSerivice.calculateMinMaxFields(this.customFeildList.form.sections);
        if(this.isSecondCall) {

          const secVisibilty = this.customFeildList.form.sections.filter(sec => sec.nestedSections.every(n=> n.fields.filter(field => field.isVisible).length > 0));
          if(secVisibilty.length == 0 && this.customFeildList.type != this.defintionType.SUBMISSION){
            this.showSpinner2 = true;
            this.saveApplicationDefination();
          }
        }
  }

  onCancel() {
    if (this.callFrom == ComponentName.ApplicationDetailComponent) {
      this.submitted.emit(false);
    } else if (this.refApplicationId > 0) {
      this.router.navigate(
        [`/applications/${this.refApplicationId}`],
        { queryParams: { my: this.isMySpace } }
      );
    } else if (this.feedOnBehalf) {
      this.router.navigate(
        [`/applications/${this.parentApplicationId}`],
        { queryParams: { my: this.isMySpace } }
      );
    } else {
      // this.router.navigateByUrl("/request/catelog");
      this._location.back();
    }
  }

  getFirstInvalidSection(): number {
    return this.customFeildList.form.sections.findIndex(x => !x.isValid)
  }

  private openRequestSubmittedConfirmationDialog(message) {
    this._matDialog.open(RequestSubmittedConfirmationDialogComponent, {
      width: '792px',
      disableClose: true,
      autoFocus: false,
      data: {
        my: this.isMySpace,
        isSubmission: this.isSubmission,
        id: this.selectedDefinationId,
        parentId: this.parentApplicationId,
        isResubmitFromDetails: this.callFrom == ComponentName.ApplicationDetailComponent,
        refApplicationId: this.refApplicationId,//submission from folder application
        refScfId: this.refScfId,
        feedOnBehalf: this.feedOnBehalf,
        tab: this.tab,
        isRecord: this.isRecord,
        submitSuccessMessage: message,
        definitionId: this.customFeildList.id,
        assessmentId: this.assessmentId,
        subDefApplicationId: this.subDefApplicationId,
        subDefApplicationName : this.subDefApplicationName,
        subApplicationType: this.subApplicationType ? 1 : 0,
        reviseApplication: this.reviseApplication,
      }
    });
  }

  markSectionAsActive(sectionIndex) {
    this.customFeildList.form.sections.forEach((sec: FormSectionModel) => sec.isFocused = false);
    this.customFeildList.form.sections[sectionIndex].isFocused = true;
  }
  filteredFormSections(sections: FormSectionModel[]){
    if(sections) {
      this.filteredSections = sections.filter(sec => sec.isVisible != false);
    }
      // console.log('this.filteredSections', this.filteredSections);

  }
  nextSections(sectionIndex) {
    this.selectedTabIndex = sectionIndex + 1;
  }
  preSections(sectionIndex) {
    this.selectedTabIndex = sectionIndex - 1;
  }
  openDraftConfirmationDialog() {
    const dialogRef = this._matDialog.open(DraftConfirmationDialogComponent, {
      width: '470px',
      minWidth: '400px',
      maxWidth: '470px',
      panelClass: 'confirmation-dialog',
      direction: this.dataSharingService.isRtl ? 'rtl' : 'ltr',
      closeOnNavigation: true,
      disableClose: true,
      autoFocus: false,
      data: {
        heading: "DraftDialog.confirmation",
        desc: "DraftDialog.description",
        ok:'DraftDialog.saveAsDraft',
        no: 'DraftDialog.No',
      }
    });
    dialogRef.afterClosed().subscribe( result => {
      if(result) {
        if(result == 1) {
      
          this.saveApplicationDefination(false);
        } else if(result == 2){

          this.onCancel();
        }
      } else {
        dialogRef.close();
      }

    } );
  }

  isVerificationRequired()
  {
    if(this.customFeildList && this.customFeildList.form)
    {
      this.verificationFields = this.customFieldFormService.getVerificationFields(this.customFeildList.form);
    }
  }
  onAllCodesVerified(isAllFieldsVerified:boolean)
  {
    if(isAllFieldsVerified)
    {
      this.allCodesVerified = isAllFieldsVerified;
     // this.isVerificationStep = false;
      this.saveApplicationDefination();
    }
  }
  onBackClick()
  {
    this.isVerificationStep = false;
  }
  get verificationRequired():boolean {
    return this.verificationFields.length > 0;
  }

  trackByForNestedSections(index: number, section: FormSectionModel) {
    return section.guid;
  }
  public attachmentName: any = '';
  public showUploadButton: boolean = false;
  openFileUploader(fileInput: HTMLInputElement, sectionId: string): void {
    console.log("Opening file uploader for section:", sectionId);
    fileInput.click(); // Trigger the file input click to open the file dialog
  }
  
  onFileSelected(event: any, sectionId: number) {
    // alert(sectionId)
    console.log(event);
    
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.attachmentName = input.files[0];
      // Create FormData to send the file
      const formData = new FormData();
      formData.append('file', this.attachmentName);
  
      this.fillAIFields(formData, sectionId);
    }
  }
  openAIDialog(sectionId) {
    const dialogRef = this._matDialog.open(FillWithAiDialogComponent, {
      width: '90vw',
      minWidth: '280px',
      maxWidth: '560px',
      height: '530px',
      panelClass: 'AI-dialog',
      direction: this.dataSharingService.isRtl ? 'rtl' : 'ltr',
      closeOnNavigation: true,
      disableClose: true,
      autoFocus: false,
      data: {
        sectionId: sectionId,
        heading: "Upload File",
        desc: "Ellipse blur reesizing draft strikethrough duplicate align. Arrange asset inspect pencil text outline flatten team list.",
        ok:'Countinue',
        no: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe( result => {
      if(result) {
          console.log(result);
          this.fillAIFields(result, sectionId);
      } else {
        dialogRef.close();
      }

    } );
  }
}

interface CustomFieldModelType {
  [key: string]: CustomFieldModel;
}

interface FormSectionValidity{
  sectionId: number;
  nestedSectionIndex: number;
  validity: boolean;
}
