import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'xcdrs-esp-progress-bar',
  templateUrl: './esp-progress-bar.component.html',
  styleUrls: ['./esp-progress-bar.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class EspProgressBarComponent implements OnInit {
  valueInPercentange: string = '0%';
  @Input()
  set value(val: number) {
    this.valueInPercentange = `${val}%`;
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}