import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SystemSettingsService } from './system-settings-service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  //private theme = new BehaviorSubject('');
  //currentTheme = this.theme.asObservable();
  public cssClass: string = "";
  public imgSrc: string = "";
  constructor(private _systemSettingsService: SystemSettingsService) {

  }

  //changeTheme(message: string) {
  //  this.theme.next(message)
  //}
  
  //public cssClass: string = "isp-light-theme";
  //public imgSrc: string = "isp/";
}
