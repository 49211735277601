import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { SnackbarType } from '../../common/enums/common-enum';

@Component({
  templateUrl: './esp-snackbar.component.html',
  styleUrls: ['./esp-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EspSnackbarComponent implements OnInit {
  public SnackbarType = SnackbarType;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackRef: MatSnackBarRef<EspSnackbarComponent>
  ) { }

  ngOnInit(): void {
  }

  dismiss() {
    this.snackRef.dismiss();
  }

}
