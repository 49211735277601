import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DataSharingService } from '../../../common/services/data-sharing.service';
import { ThemeService } from '../../../common/services/theme.service';

@Component({
  selector: 'app-draft-confirmation-dialog',
  templateUrl: './draft-confirmation-dialog.component.html',
  styleUrls: ['./draft-confirmation-dialog.component.scss']
})
export class DraftConfirmationDialogComponent implements OnInit {
  title: string;
  ok: string;
  no: string;
  desc: string;
  constructor(
    public themeService: ThemeService, 
    public dataSharingService: DataSharingService,
    public dialogRef: MatDialogRef<DraftConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    ) { }

  ngOnInit(): void {
    this.title = this._data.heading;
    this.ok = this._data.ok;
    this.no = this._data.no;
    this.desc = this._data.desc;
  }

}
