import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialEssentialsModule } from '../../custom-material/material-essentials.module';
import { RouterModule } from '@angular/router';
import { EspControlsModule } from '../../custom-material/esp-controls.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CustomFieldsFormComponent } from './custom-fields-form/custom-fields-form.component';
import { CustomFieldComponent } from './custom-field/custom-field.component';
import { CustomFieldApplicationSubmittedComponent } from './custom-field-application-submitted/custom-field-application-submitted.component';
import { ValidatorsModule } from '../../common/modules/validators.module';
import { CustomNoteFieldComponent } from './custom-note-field/custom-note-field.component';
import { EspMapComponent } from './custom-field/esp-map/esp-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule } from '@angular/common/http';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { ViewFieldTagsComponent } from './custom-field-application-submitted/view-field-tags/view-field-tags.component';
import { SecurePipe } from '../../common/pipes/secure.pipe';
import { DownloadFileDirective } from '../../common/directives/download-file.directive';
@NgModule({
  declarations: [
    CustomFieldsFormComponent,
    CustomFieldComponent,
    CustomFieldApplicationSubmittedComponent,
    CustomNoteFieldComponent,
    EspMapComponent,
    ViewFieldTagsComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    ValidatorsModule,
    TranslateModule,
    MaterialEssentialsModule,
    MatDialogModule,
    RouterModule,
    EspControlsModule,
    MatCheckboxModule,
    NgxMatSelectSearchModule,
    GoogleMapsModule,
    MatChipsModule,
    MatAutocompleteModule,
    SecurePipe,
    DownloadFileDirective
  ],
  exports: [
    CustomFieldsFormComponent,
    CustomFieldComponent,
    CustomFieldApplicationSubmittedComponent,
    CustomNoteFieldComponent,
    EspMapComponent,
    ViewFieldTagsComponent
  ]
})
export class FormSubmissionSharedModule { }
