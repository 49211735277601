import { Component, OnInit } from '@angular/core';
import { UserInfoService } from '../../common/services/userinfo-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss']
})
export class AccountsListComponent implements OnInit {
  public orgList: any[] = [];
  constructor(public userInfoService: UserInfoService
    , private router: Router
    ) {
    
  }

  ngOnInit() {
    if (this.userInfoService.orgPersonas) {
      this.orgList = this.userInfoService.orgPersonas;
    } else if (this.userInfoService.personas) {
      this.orgList = this.userInfoService.filterUniqueOrganizationPersonas(this.userInfoService.personas);
    } else {
      this.router.navigateByUrl('/login');
    }
  }

}
