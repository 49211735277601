import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DataSharingService } from '../../../common/services/data-sharing.service';
import { LabelService } from '../../../common/services/label-service';

@Component({
  selector: 'app-image-preview-dialog',
  templateUrl: './image-preview-dialog.component.html',
  styleUrls: ['./image-preview-dialog.component.scss']
})
export class ImagePreviewDialogComponent implements OnInit {
  imgUrl = "";
  constructor(
    private _dialogRef: MatDialogRef<ImagePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _dataSharingService: DataSharingService,
    public labelService: LabelService
  ) {
    if(data.imgUrl){
      this.imgUrl = data.imgUrl;
    }
  }

  ngOnInit(): void {
  }

  closeDialog(){
    this._dialogRef.close();
  }
}
