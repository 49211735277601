import { DefinitionStageModel } from './definition-stage';
import { FormSectionModel } from '../../models/CustomizationSettingsSubmitModel';
import { ParentApplicationInfo } from '../../models/applicationdetailmodel';
import { ActionModel } from '../../models/action-model';
import { TriggerModel } from '../../models/trigger-model';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DefinitionSetting } from '../../models/definition-settings.model';

export class ApplicationDefinition {
  constructor() {
    this.type = DefinitionType.Default;
    this.stages = [];
    this.category = "";
    this.triggers = [];
    this.filterLists = [];
  }
  public applicationsCount: number;
  public createdOn: Date;
  public name: string;
  public nameLocale?: string;
  public description: string;
  public descriptionLocale: string;
  public nameUI: string;
  public descriptionUI: string;
  public id: number;
  public isActive: boolean;
  public visible: boolean;
  public isPublished: boolean;
  public typeId: number;
  public category: string;
  public categoryLocale: string;
  public categoryId: number;
  public version: number;
  public criteriaCount: number;
  public actions: ActionModel[];
  public triggers: TriggerModel[];
  public stages: DefinitionStageModel[];
  public form: FormModel;
  public iconName: string;
  public color: string;
  public descriptionLength: number;
  public isProfileCompletionRequired: boolean;
  public key: string;
  public referenceApplicationId: number;
  public isprofileCompleted: boolean;
  public profileTemplateId: number;
  public profileListId: number;
  public profileTemplate: string;
  public delegationsCount: number;
  public type: DefinitionType;
  public parentApplicationId: number;
  public stageVisibilityApplicant: string;
  public stageVisibilityApplicantText: string;
  public linkDefinitionNameCustomFieldId: number;
  public isMultipleSubmissionsAllowed: boolean;

  public parentApplicationInfo: ParentApplicationInfo;
  public startDate: Date;
  public endDate: Date;
  public isSubmission: boolean;
  public isHiddenFromCatalog: boolean;
  public isShownUnderMine: boolean = true;
  public formResubmitEnabled?: boolean = false;
  public allowEditOnClosed: boolean = false;
  public expandApplicationAction: boolean;

  public applicationId: number;

  public validityStatus: string;
  public currentVersion?: number;
  public publishedVersion?: number;
  public hasUnpublishedChanges: boolean;
  public order?: number
  public formGroup?: FormGroup;
  public permissions: string[];
  public definitionControllers: DefinitionController;
  public stageIndex?: number;
  public applicationName: string;
  public applicationNameLocale: string;
  public acceptedLabel: string;
  public acceptedLabelLocale?: string;
  public rejectedLabel: string;
  public rejectedLabelLocale?: string;
  public deactivatedLabel: string;
  public deactivatedLabelLocale?: string;
  public numberPrefix: string;
  public isRecord: boolean;
  public isSurvey: boolean;
  public isActivityViewHidden: boolean;
  public settings: DefinitionSetting;
  public applicantName: string;
  public applicationStatus: string;
  public filterLists: DefinitionFilterListsModel[];
  public subTenant: number;
}



export enum DefinitionType {
  Default = "DEFAULT",
  Link = "LINK",
  SUBMISSION = "SUBMISSION_REQUEST"
}

export enum ValidityStatus {
  Open = 'Open',
  Closed = 'Closed'
}

export class FormModel {
  constructor() {
    this.id = 0;
    this.sections = [];
    this.sections.push(new FormSectionModel);
    this.isWizardView = false;
  }
  public id: number;
  public isExpended: boolean = false;
  public sections: FormSectionModel[];
  public formGroup?: FormGroup;
  public isValid?: boolean = false;
  public submitLabel?: string;
  public cancelLabel?: string;
  public submitLabelLocale?: string;
  public cancelLabelLocale?: string;
  public isWizardView?: boolean = false;
  submitSuccessMessage?: string;
  submitSuccessMessageLocale?: string;
}

export class DefinitionController {
  public users: number[];
  public jobRoles: number[];
}

export class DefinitionFilterListsModel {
  constructor() {
    
  }
  public id: number;
  public name?: string;
  public conditions: any;
  public isDelete?: boolean;
  public fallBackUser: any;
  public formGroup?: FormGroup;
}

export class FilterListsModel {
  constructor() {}
  public userId: number;
  public jobRoleId?: number;
}