import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { ActionFieldModel } from '../../models/action-field-model';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { ActionTriggerType } from '../../models/action-model';
import { isNaN } from 'lodash';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CommonFieldsService {
  public triggerTypes: ActionTriggerType = new ActionTriggerType();
  constructor(private http: HttpClient, private commonHelperService: CommonHelperService,private sanitizer: DomSanitizer) { }

  getCommonFieldsWithDetails(): Observable<any[]> {
    return this.http.get<any>('/webapi/fields/common');
  }

  getFieldsForDefinition(stageId: number, definitionId: number, isIncludeCurrentStage: boolean = false, sectionId: number = 0, forInsertableFields = false, apiVersion: string = ''): Observable<HttpResponse<any>> {
    //availableFields/definition?defintionId={defintionId}&sectionId={sectionId}&stageId={stageId}&isIncludeCurrentStage={isIncludeCurrentStage}
    if (!stageId) {
      stageId = 0;
    }
    let headers = this.commonHelperService.getCurrentLocaleHeader();
    headers = headers.set('cache-request', 'true')
    let queryString = '';
    if(forInsertableFields){
      queryString += '&level=1';
    }
    return this.http.get<any>(`/webapi/fields/availableFields${apiVersion}/definition?defintionId=${definitionId}&sectionId=${sectionId}&stageId=${stageId}&isIncludeCurrentStage=${isIncludeCurrentStage}${queryString}`, { headers: headers, observe: 'response' });
  }

  getFieldsForDefinitionConditional(stageId: number, definitionId: number, actionTrigger: string, apiVersion: string = ''): Observable<HttpResponse<any>> {
    if (stageId > 0) {
      return this.getFieldsForDefinition(stageId, definitionId, actionTrigger != this.triggerTypes.OnStageStart && actionTrigger != this.triggerTypes.SLADate, 0, false, apiVersion);
    } else {
      return this.getFieldsForDefinition(0, definitionId, false, 0, false, apiVersion);
    }
  }

  getFieldsForProfile(profileId: number, sectionId: number = 0, apiVersion: string = ''): Observable<HttpResponse<any>> {
    let headers = this.commonHelperService.getCurrentLocaleHeader();
    headers = headers.set('cache-request', 'true')
    return this.http.get<any>(`/webapi/fields/availableFields${apiVersion}/profileTemplate?profileTemplateId=${profileId}&sectionId=${sectionId}`, { headers: headers, observe: 'response' });
  }

  getFieldsForLookup(lookupId: number, level: number = null, apiVersion: string = ''): Observable<HttpResponse<any>> {
    let headers = this.commonHelperService.getCurrentLocaleHeader();
    headers = headers.set('cache-request', 'true')
    return this.http.get<any>(`/webapi/fields/availableFields${apiVersion}/lookup/${lookupId}/${level}`, { headers: headers, observe: 'response' });
  }

  public formatTag(insertField: ActionFieldModel): string {
    //{Name#PT/003}
    return `{${insertField.name}#${insertField.key}}`
  }
  public formatTagWithDescription(insertField: ActionFieldModel): string {
    //{Name#PT/003}
    return `{${insertField.description}> ${insertField.name}#${insertField.key}}`
  }


  //parse before posting
  parseInsertFieldPlaceholders(value: string): string {
    let resutl = "";
    if (value) {
      let regex = /(\{.*?\})/g;

      let dataList = [];
      dataList = value.toString().split(regex);
      for (let i = 0; i < dataList.length; i++) {
        if (dataList[i] != '') {
          if (this.valueHasTag(dataList[i])) {

            if (dataList[i].indexOf('#') > -1) {
              let text = dataList[i];
              text = this.removeBracketsFromValue(text);
              resutl += '{' + text.substring(text.indexOf('#') + 1).trim() + '}';
            } else {
              resutl += dataList[i];

            }
          } else {
            resutl += dataList[i];
          }
        }
      }
    }else if(!isNaN(value) && +value === 0){
      resutl = value?.toString();
    }
    return resutl?.trim();
  }

  //compose in case of update to show tag
  composeInsertFieldPlaceholders(value: string, actionFeilds: ActionFieldModel[]): string {
    //value.replace(/{.*?}/g, function (x) {
    //  return this.getLabelFromKey(x);
    //});
    //return value;

    let result = "";
    // let regex = /{(.*?)}/g;
    let regex = /(\{.*?\})/g;

    let dataList = [];
    dataList = value?.split(regex);
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i] != '') {
        result += this.getLabelFromKey(dataList[i], actionFeilds);
      }
    }
    return result;
  }


  getLabelFromKey(text: string, actionFeilds: ActionFieldModel[]) {
    //var id = text.replace(/{|}/g, '');
    if (this.valueHasTag(text)) {
      const textFormatted = this.removeBracketsFromValue(text)
      const actionField = actionFeilds.find(x => x.key == textFormatted);
      if (actionField) {
        if (actionField.description) {
          return this.formatTagWithDescription(actionField)
        } else {
          return this.formatTag(actionField)
        }
      }
    }
    return text;

  }

  public valueHasTag(value: string): boolean {
    if(value){
      value = value.toString();
    }
    if (value && (value.indexOf('#') >= 0 || (value.indexOf('{') >= 0 && value.indexOf('/') >= 0 && value.indexOf('}') >= 0))) {
      return true;
    } else {
      return false;
    }
  }

  public removeBracketsFromValue(value: string) {
    if (this.valueHasBrackets(value)) {
      return value.slice(1, (value.trim().length - 1))
    } else {
      return value.trim();
    }
  }

  public valueHasBrackets(value: string): boolean {
    if (value.trim().indexOf('{') == 0 && value.trim().indexOf('}') == (value.trim().length - 1)) {
      return true;
    }
    else {
      return false;
    }
  }


  textToHtml(text: string = null) {
    if (!text) { return '' }
    let regex = /(\{.*?\})/g;
    let splittedValues = text.split(regex);
    let joined = splittedValues.map((tag: string) => {
      if (tag.search(regex) > -1) {
        return this.tagToHtml(tag)
      }
      return tag;
    }).join('');

    return joined;
  }
  textToHtmlnoIcon(text: string = null) {
    if (!text) { return '' }
    let regex = /(\{.*?\})/g;
    let splittedValues = text.split(regex);
    let joined = splittedValues.map((tag: string) => {
      if (tag.search(regex) > -1) {
        return this.tagToHtmlNoIcon(tag);
      }
      return tag;
    }).join('');

    return joined;
  }

  textToHtmlTagableField(text: string = null) {
    if (!text) { return '' }
    let regex = /(\{.*?\})/g;
    let splittedValues = text.split(regex);
    let joined = splittedValues.map((tag: string) => {
      if (tag.search(regex) > -1) {
        if(tag == '{-1}') {
          return this.tagToHtmlCurrentDate(tag);
        } else {
          return this.tagToHtmlNoIcon(tag);
        }
      }
      return tag;
    }).join('');

    return joined;
  }
  getValue(val: string) {
    if (val.indexOf('#') > 0) {
      return '{' + val + '}';
    }

    return val;
  }
  getKey(val: string) {
    if (val.indexOf('#') > 0) {
      return val.split('#')[0];
    }
    return val;
  }
  getText(val: string) {
    let regex = /(\{.*?\})/g;
    if(val == '{**CURRENTDATE**}') {
      val = '{Current Date#**CURRENTDATE**}';
    } else if(val == '{**CURRENTDATETIME**}') {
      val = '{Current Date Time#**CURRENTDATETIME**}';
    } else if(val == '{**STAGESTARTDATE**}') {
      val = '{Stage Start Date#**STAGESTARTDATE**}';
    } 
    if(val == '{M}' || val == '{H}' || val == '{D}') {
      let newVal: any =  val.split('');
      return newVal[1]
    } else {
      if (val.search(regex) > -1) {
        if (val.indexOf('#') > 0) {
          return val.split('#')[0].substring(1);
        }
      }
    }


    return val;
  }
  tagToHtml(tag) {
    return `<span contenteditable="false" class="field-tag" title="${this.getText(tag)}" data-tag="${tag}"><span class="space-before">&nbsp;&nbsp;&nbsp;</span><span class="tag-content">${this.commonHelperService.getActionFieldText(this.getText(tag))}</span><span class="space-center">&nbsp;&nbsp;</span><span class="tag-remove-icon material-icons">close</span><span class="space-after">&nbsp;&nbsp;&nbsp;</span></span>`
  }
  tagToHtmlNoIcon(tag) {
    return `<span contenteditable="false" class="field-tag" title="${this.getText(tag)}" data-tag="${tag}"><span class="space-before">&nbsp;&nbsp;&nbsp;</span><span class="tag-content">${this.commonHelperService.getActionFieldText(this.getText(tag))}</span><span class="space-after">&nbsp;&nbsp;</span></span>`
  }
  tagToHtmlCurrentDate(tag) {
    return `<span contenteditable="false" class="field-tag" title="Current Date" data-tag="${tag}"><span class="space-before">&nbsp;&nbsp;&nbsp;</span><span class="tag-content">Current Date</span><span class="space-after">&nbsp;&nbsp;</span></span>`
  }
  parseHtml(htmlContent) {
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(htmlContent, 'text/html');
    let str = this.recursiveParseHtml(parsedHtml.body.childNodes);
    return this.parseInsertFieldPlaceholders(str);
    return str;
  }

  recursiveParseHtml(lodeList: NodeList) {
    let div = '';
    for (let i = 0; i < lodeList.length; i++) {
      if (lodeList[i].nodeName == "SPAN") {
        if (lodeList[i]["className"].indexOf("field-tag") != -1) {
          div += lodeList[i]["dataset"]["tag"];
        } else {
          div += lodeList[i].textContent;
        }
      } else if (lodeList[i].nodeName == "#text") {
        div += lodeList[i].textContent;
      } else if (lodeList[i].nodeName == "BR") {
        div += '\r\n';
      } else if (lodeList[i].nodeName == "DIV") {
        if (i > 0)
          div += '\r\n';
        div += this.recursiveParseHtml(lodeList[i].childNodes);
      }
    }

    return div;
  }
  getCaretPosition() {
    if (window.getSelection && window.getSelection().getRangeAt) {
      let range = window.getSelection().getRangeAt(0);
      let selectedObj = window.getSelection();
      let rangeCount = 0;
      let childNodes = selectedObj.anchorNode.parentNode.childNodes as any;
      for (var i = 0; i < childNodes.length; i++) {
        if (childNodes[i] == selectedObj.anchorNode) {
          break;
        }
        if (childNodes[i].outerHTML)
          rangeCount += childNodes[i].outerHTML.length;
        else if (childNodes[i].nodeType == 3) {
          rangeCount += childNodes[i].textContent.length;
        }
      }
      return range.startOffset + rangeCount;
    }
    return -1;
  }
  replaceStringRange(s, start, end, substitute) {
    return s.substring(0, start) + substitute + s.substring(end);
  }
  sanitizeHtml(value){
    if(value != "" || value != null) {
    let safeHtml : SafeHtml;      
    safeHtml = this.sanitizer.bypassSecurityTrustHtml(value);
     return safeHtml;
    } else {
      value = ""
    }
  }
  public generateRandomId(length: number = 8): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomId = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }
}

