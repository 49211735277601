<!-- <p>esp-map works!</p> -->
<!-- google-map-demo.component.html -->
<div fxLayout="column" fxLayoutAlign="center" [ngClass]="{'wizart-field-label' : !isReadOnly, 'margin-t1' : fieldOptions.showLabel }">
  <ng-container [ngTemplateOutlet]="isLongLabel"></ng-container>
  <div *ngIf="fieldOptions.showLabel">
    <mat-label *ngIf="!customField.isLongLabel">
      <span>{{customField.label | translateToLocale}} </span><span *ngIf="customField.isRequired">*</span>
      <mat-icon matSuffix style="font-size:18px;color:rgba(0, 0, 0, 0.2);margin-right:8px" *ngIf="customField.isReadOnly">lock</mat-icon>
      <mat-icon  matSuffix *ngIf="customField.hint" fxHide.gt-xs="true"  fontSet="material-icons-outlined" class="color-grey cursor-pointer vertically-middle f-20" [matTooltip]="customField.hint | translateToLocale" matTooltipClass="esp-tooltip" matTooltipPosition="above">info</mat-icon>
      <ng-container [ngTemplateOutlet]="tempNesterFieldIndex"></ng-container>
    </mat-label>
  </div>
  <div *ngIf="!mapsLoading; else tmpMapsLoading" [ngClass]="{'stars-container' : fieldOptions.showLabel }">
    <google-map height="350px"
                width="100%"
                [options]="options"
                [center]="center"
                [zoom]="zoom"
                (mapClick)="addMarker($event)">
      <map-marker [position]="markerPositions"
                  [options]="markerOptions"
                  (mapDragend)="markerDragEnd($event)">
      </map-marker> 
    </google-map> 
    <ng-container [ngTemplateOutlet]="addBtnForBoxControl"></ng-container>
  </div>
  <ng-template #tmpMapsLoading>
    <div #tmpMapsLoading class="maps-loading tw-flex tw-justify-center tw-items-center">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
  </ng-template>
</div>
