import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { LabelService } from "./label-service";
import { TranslateService } from "@ngx-translate/core";
import { TranslationHelperService } from "./translation-helper.service";
import { filter, map, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BrowserTabService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public labelService: LabelService,
    private titleService: Title,
    private translate: TranslateService,
    private translationHelperService: TranslationHelperService
  ) { }

  setTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: any) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route: any) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        let titleSeperator = " |";

        var ls: LabelService = this.labelService;
        var ts: Title = this.titleService;

        this.labelService.getLabel((labels) => {
          let application_short_name = labels.application_short_name
            ? labels.application_short_name
            : "";
          let title: string = ls.get(event["title"])
            ? ls.get(event["title"])
            : event["title"];
          if (event["title"] == undefined || application_short_name == "") {
            event["title"] = "";
            titleSeperator = "";
          }

          if (typeof title === "string" && title.length > 0)
            this.translate.get(title).subscribe((resp) => {
              if (resp && typeof resp === "string") {
                title = resp;
              }
            });
          if (
            typeof application_short_name === "string" &&
            application_short_name.length > 0
          )
            this.translate.get(application_short_name).subscribe((resp) => {
              application_short_name = resp;
            });
          ts.setTitle(
            this.translationHelperService.translateToLocale(title) +
            titleSeperator +
            " " +
            application_short_name
          );
        });

        if (event["title"] == "") {
          event["title"] = event["pageTitle"];
        } else if (event["pageTitle"] == "") {
          event["pageTitle"] = event["title"];
        }
      });
  }
}
