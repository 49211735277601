import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LookupAdonsModel } from '../../models/lookup-ad-ons-model';
import { SystemLanguage } from '../../models/system-language';
import { InvitationLinkInformation } from '../../models/invitation-link-information';
import { ApplicationDefinition } from '../../definitions/models/application-definition';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  public userImageUrl: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public userFullName: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public personas: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public isRtl: boolean = false;
  public isStemex: boolean = false;
  public isStemexUser: boolean = false;
  public isUrlLoaded: boolean = false;
  public searchStemex: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public lookupAdons: BehaviorSubject<LookupAdonsModel[]> = new BehaviorSubject<LookupAdonsModel[]>([]);
  public lookupAdon: BehaviorSubject<LookupAdonsModel> = new BehaviorSubject<LookupAdonsModel>(new LookupAdonsModel);
  public invitationLinkInformation: BehaviorSubject<InvitationLinkInformation> = new BehaviorSubject<InvitationLinkInformation>(new InvitationLinkInformation);
  public storage: any;
  public systemLanguages: BehaviorSubject<SystemLanguage[]> = new BehaviorSubject<SystemLanguage[]>([]);
  public interceptorLocale: string = '*';
  public applicationDefinition: BehaviorSubject<ApplicationDefinition> = new BehaviorSubject<ApplicationDefinition>(new ApplicationDefinition);
  public isModelChanged: boolean = false;
  public isConfirmed: boolean = false;
  public accountSwitched: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 
  public constructor(private router: Router) {
    this.isStemex = false;
    this.isUrlLoaded = false;
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isUrlLoaded = true;
        if (val.url == "/request/my") {
          this.isStemex = true;
        }
        else {
          this.isStemex = false;
        }
      }
    });
  }
}
