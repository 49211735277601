<!--The content below is only a placeholder and can be replaced.-->
<div [ngClass]="{'rtl' : dataSharingService.isRtl }">
  <ng-progress [color]="'#71b546'" [spinner]="false" [direction]="dataSharingService.isRtl ? 'rtl+' : 'ltr+'">
  </ng-progress>

  <!--<app-sidenavbar class="mat-typography" [loggedIn]="isLoggedIn" dir="{{direction}}"></app-sidenavbar>-->
  <xcdrs-app-header *ngIf="isLoggedIn && !iframeService.isIframeCall"
                    [loggedIn]="isLoggedIn"></xcdrs-app-header>
  <!--<xcdrs-alert></xcdrs-alert>-->
  <div class="content" [ngClass]="{'top-zero' : iframeService.isIframeCall}" [dir]="dataSharingService.isRtl ? 'rtl' : 'ltr'">
    <div class="main-container">
      <!--<xcdrs-loading></xcdrs-loading>-->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
