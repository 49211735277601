import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ApplicantRequestService } from '../../../applicant-request/services/applicant-request.service';
import { EmailVerificationRequest, EmailVerifyObj } from '../../../applicant-request/models/email-verification';
import { CustomFieldModel } from '../../../profile-customization/Model/CustomFieldModel';
import { MessageService } from '../../../common/services/message-service';
import { SnackbarType } from '../../../common/enums/common-enum';
import { interval } from 'rxjs';

@Component({
  selector: 'app-form-submission-emails-verification',
  templateUrl: './form-submission-emails-verification.component.html',
  styleUrls: ['./form-submission-emails-verification.component.scss']
})
export class FormSubmissionEmailsVerificationComponent implements OnInit {

  private _verificationRequest: EmailVerificationRequest;
  @Input()
  set verificationRequest(value: EmailVerificationRequest){
    if(value)
    {
      this._verificationRequest = value;
      this.sendVerificationCode();
    }
  }
  get verificationRequest():EmailVerificationRequest{
    return this._verificationRequest;
  }

  isCodeEntered: boolean = false;

  code:string='';

  isVerificationAttempted: boolean = false;
  isVerificationAttemptSucceeded: boolean = false;
  errorMsg:string='';

  isVerificationCodeSent = false;

  timer$:any;
  timeLeft:number;
  timeElapsed:boolean;

  @Output()
  onVerificationSucceeded:EventEmitter<boolean>=new EventEmitter<boolean>();

  private applicationRequestService: ApplicantRequestService;

  constructor(applicationRequestService:ApplicantRequestService, private msgService: MessageService) { 
    this.applicationRequestService = applicationRequestService;
    this.isVerificationCodeSent =false;
  }

  ngOnInit(): void {
  }
  onCodeCompleted(code: string) {
    this.code = code;
    this.isCodeEntered = true;
  }

  sendVerificationCode()
  {
    this.applicationRequestService.sendVerificationCode(this.verificationRequest).subscribe(x=>{
      this.isVerificationCodeSent = true;
      this.msgService.showMessage(`Code was successfully sent to ${this.verificationRequest.type} ${this.verificationRequest.contact}`, 5000, SnackbarType.Success);
      console.log('verification code was sent to ', this.verificationRequest.type, this.verificationRequest.contact);
      this.initiateTimer();
    });
  }

  resendVerificationCode()
  {
    if(this.timeElapsed)
    {
      this.applicationRequestService.resendVerificationCode(this.verificationRequest).subscribe(x=>{
       this.msgService.showMessage(`Code was successfully resent to ${this.verificationRequest.type} ${this.verificationRequest.contact}`, 5000, SnackbarType.Success);
       this.isVerificationCodeSent = true;
       this.initiateTimer();
      });
    }
  }
  verifyEmail()
  {
    if(this.isVerificationCodeSent)
    {
        let verifyObj:EmailVerifyObj=new EmailVerifyObj();
        verifyObj.code = this.code;
        verifyObj.type = this.verificationRequest.type;
        verifyObj.contact = this.verificationRequest.contact;
        verifyObj.reference = this.verificationRequest.reference;
        
        this.applicationRequestService.verifyEmailCode(verifyObj).subscribe(verifyResult=>{
          this.isVerificationAttempted = true;
          this.isVerificationAttemptSucceeded = verifyResult.isVeified;
          if(this.isVerificationAttemptSucceeded)
          {
            this.code='';
            this.msgService.showMessage(`Code was successfully verified against ${verifyObj.type} ${verifyObj.contact}`, 5000, SnackbarType.Success);
            this.onVerificationSucceeded.emit(true);
          }
          else
          {
            this.errorMsg=verifyResult.status;
            this.msgService.showMessage(`Code could not be verified against ${verifyObj.type} ${verifyObj.contact}`, 5000, SnackbarType.Error);
          }
        });
      }
  }

  initiateTimer()
  {
    if(this.timer$)
    {
      this.timer$.unsubscribe();
    }
    this.timeLeft = 15;
    this.timeElapsed=false;
    this.timer$=interval(1000).subscribe(x=>{
        this.timeLeft -= 1;
        if(this.timeLeft<=0)
        {
          this.timeElapsed = true;
        }
    });
  }

  ngOnqDestroy() {
    this.timer$.unsubscribe();
 }

}
