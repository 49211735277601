<ng-container *ngFor="let def of childSubmitterInfoModel; let i = index">
  <ng-container *ngIf="def.submissionOption != SubmissionOptionEnum.RequesterOnly">
    <div class="field-container">
      <app-custom-field [customField]="def.noteField" [isDisabled]="false" [application-id]="0" [applicant-id]="0"
        [componentName]="" [fieldOptions]="{floatLabel:'auto',showPlaceholder:true}">
      </app-custom-field>
    </div>
    <div class="field-container toggle-buttons"
      *ngIf="(applicationId == 0  || applicationStatus == 'Draft') && def.submissionOption == SubmissionOptionEnum.Both">
      <button mat-button color="primary" class="first" (click)="setAssign(i, true)"
        [ngClass]="submissionApplicantsArray?.controls[i].get('isAssign').value == true ? 'mat-flat-button' : 'mat-stroked-button'"
        fxFlex>{{'Application.ASSIGN' | translate}}</button>
      <button mat-button color="primary" class="second" (click)="setAssign(i, false)"
        [ngClass]="submissionApplicantsArray?.controls[i].get('isAssign').value == false ? 'mat-flat-button' : 'mat-stroked-button'"
        fxFlex>{{'Invite' | translate}}</button>
    </div>
    <div class="field-container">
      <!-- {{submissionApplicantsArray?.controls[i].value | json}} - {{submissionApplicantsArray.valid}} -->
      <esp-mat-select *ngIf="submissionApplicantsArray?.controls[i].get('isAssign').value"
        [espFormControl]="submissionApplicantsArray?.controls[i].get('applicants')" [options]="whoToInviteType == 'DefinitionSelection' ? allowedSubmittersOptions : def.applicantsOptions"
        placeholder="{{'Application.WHO-CAN-SUBMIT' | translate}}" [allowMultiSelect]="false" appearance="outline"
        [optionInTwoLines]="true" fxFelx>
      </esp-mat-select>
      <app-submitters-select *ngIf="!submissionApplicantsArray?.controls[i].get('isAssign').value"
        [espFormGroup]="submissionApplicantsArray?.controls[i]" [applicants]=" whoToInviteType == 'DefinitionSelection' ? preAllowedSubmitters : def.applicants"
        [subUserGroupLists]="whoToInviteType == 'DefinitionSelection' ? [] : def.subUserGroupLists"
        [selectedApplicantsIds]="def.applicantIds" [selectedUserGroupsIds]="def.profileGroupIds"
        [selectAllByDefault]="selectAllByDefault" [disabled]="disabled" fxLayout="column"></app-submitters-select>
    </div>
    <div class="send-notificaion-cb" *ngIf="notificationEnabled">
      <mat-checkbox [formControl]="notifySubmitters" [disabled]="disabled">{{'Send-Notification-Email-to-Submitters' | translate}} </mat-checkbox>
    </div>
  </ng-container>
</ng-container>