import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpResponse } from '@angular/common/http';
import { CurrencyModel } from '../../models/currency-model';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageCurrenciesService {
  public currencies: CurrencyModel[] = [];
  private _forceUpdate = false;
  constructor(private http: HttpClient) { }

  updateCurrencies(Currencies: CurrencyModel[]): Observable<any> {
    this._forceUpdate = true;
    return this.http.put<any>('/WebApi/currency', Currencies);
  }

  getCurrencies(): Observable<HttpResponse<CurrencyModel[]>> {
    return this.http.get<CurrencyModel[]>('/WebApi/currency', { headers: { 'cache-request': 'true' }, observe: 'response' });
  }

  private _cachedCurrencies: CurrencyModel[] = [];
  getCachedCurrencies(): Observable<CurrencyModel[]> {
    if (this._cachedCurrencies.length == 0 || this._forceUpdate) {
      return this.getCurrencies().pipe(
        map(resp => {
          this._cachedCurrencies = resp.body;
          this._forceUpdate = false;
          return this._cachedCurrencies;
        })
      )
    } else {
      return of(this._cachedCurrencies);
    }
  }
}

