import { ValueDetails } from "../../models/custom-field-value-model";
import { CurrencyModel } from "../../models/currency-model";
import { LookupAdonsModel } from "../../models/lookup-ad-ons-model";
import { ApplicationDefinition } from "../../definitions/models/application-definition";
import { ActionFieldModel } from "../../models/action-field-model";
import { lookup } from 'dns';
import { FeildType } from '../../common/enums/field-type-enum';
import { FormGroup } from '@angular/forms';
import { FieldVisibility } from "../../common/enums/field-visibility.enum";
import { KeyValueModel } from "../../models/key-value.model";
import { FieldSettings } from "../../models/field-settings.model";

export class CustomFieldModel extends FieldSettings {
  constructor() {
    super();
    this.isVisible = true;
    this.originalAllowedValuesCriteria = "";
    this.label = "";
    this.value = "";
    this.type = 0;
    this.details = new ValueDetails();

    this.id = 0;
    this.objectId = 0;
    this.sectionTemplateFiledId = 0;
    this.labelLocale = "";
    this.actionValue = "";
    this.isRequired = false;
    this.hyperlinkTitle = ""
    this.isUnique = false;
    this.UniqueValueMessage = "";
    this.UniqueValueMessageLocale = "";
    this.verifiable = false;
    this.isCommon = false;
    this.isReadOnly = false;
    this.minVal = null;
    this.maxVal = null;
    this.minDate = null;
    this.maxDate = null;
    this.hint = "";
    this.hintLocale = "";
    this.order = 0;
    this.valueDate = null;
    this.lookupValues = [];
    this.sigleSelectedValue = 0;
    this.singleSelections = [];
    this.rollup = new RollUp();
    this.isSearchable = false;
    this.isShowInList = false;
    this.isMultiLingual  = false;
    this.nestedFields = [];
    this.settings = [];
    this.isRealTime = false;
    this.onMainFormListCardVisibility = new FieldVisibilityModel();
    this.isMultiLingualText = "";
    this.isMultiLingualMultiLine = "";
    this.isMultipleAllowed = false;
    this.canUnLock = false;
  }

  public id: number = 0;
  public objectId: number = 0;
  public sectionTemplateFiledId: number = 0;
  public label: string
  public labelLocale?: string
  public type: number;
  public isLongLabel: boolean = false;
  public value?: string | any;
  public actionValue?: string;
  public isRequired: boolean;
  public hyperlinkLabelEnabled: boolean = false;
  public hyperlinkTitle?: String;
  public isUnique: boolean;
  public UniqueValueMessage: string;
  public UniqueValueMessageLocale: string;
  public verifiable: boolean;
  public isCommon: boolean;
  public isReadOnly: boolean;
  public maxVal: number;
  public minVal: number;
  public isVisible: boolean;

  public calculatedMinVal: any;
  public calculatedMaxVal: any;

  public minDate: any;
  public maxDate: any;
  public min: any;
  public max: any;
  public calculatedMinDate: any;
  public calculatedMaxDate: any;

  public hint: string;
  public hintLocale: string;

  public order?: number;
  public valueDate: Date;

  public lookupValues: CustomFieldLookupModel[];

  public sigleSelectedValue: number;
  public singleSelections: KeyValuePair[];

  public isSystem: boolean;

  public isShowInList: boolean;

  public allowedValuesCriteria: string | any;
  public filter: any;
  public filterLookUp: number;
  public originalAllowedValuesCriteria: string;
  public allowedValuesCriteriaArray: string[] | any;
  public selectedCurrencyId: number;
  public selectedCurrencySymbol: string;

  public selectedCurrenciesString: string;
  public defaultCurrencyString: string;

  //new implementation of lookups start
  public sectionCustomFieldId: number;
  public isTitleField: boolean;
  public selectedLookupText: string;
  public details: ValueDetails;
  public fileContent: any;

  public canDisabled: boolean;
  //new implementation of lookups end

  public lookUpId: number;
  public lookupValue: string; //populated in case of type 13 Lookup
  public lookupType: string; //populated in case of type 13 Lookup

  public rollup: RollUp = new RollUp();
  public calculations: Calculate[] = [];
  public reference: Reference = new Reference();
  //database mapping
  public associatedColumnName: string;
  public associatedColumn: string;
  public allowedCurrencies: CurrencyModel[];
  public selectedCurrency: CurrencyModel;
  public associatedProfileColumn: number;
  public isSyncColumn: boolean;
  public isSyncable: boolean;
  public isRealTime: boolean;
  public templateName: string;
  public isDelete: boolean;
  public targetFieldType: number; //used for mapped and calculated fields
  public isTigger: boolean;
  public readOnlyOnUpdate: boolean;
  public formGroup: FormGroup;
  public version?: number;

  public isSearchable: boolean;
  public visibility: FieldVisibilityModel;
  public isDynamic: boolean | any;
  public isMultiLingual?: boolean;
  public context: string;
  public isMultipleAllowed: boolean;
  public nestedFields: CustomFieldModel[] = [];
  public validationKey: string;
  public settings: KeyValueModel[];
  public selectedPriceUnit: string;
  public hasValue: boolean = false;
  public inDocuments: boolean; 
  //OnMainFormListCardVisibility for reference applications
  public onMainFormListCardVisibility: FieldVisibilityModel;
  public isMultiLingualText: string;
  public isMultiLingualMultiLine: string;
  public showNumberPrefix: boolean;
  public actions: FieldAction[]; 

  isExt: boolean; //If true child form has parent field if false child form has added own field 
  canUnLock?: boolean;
  public readOnlyFor: FieldVisibilityModel;
  public calculatedCurrency? : boolean;
  public isClear?: boolean = false;
  public isCommand?: boolean = false;
}

export class KeyValuePair {
  public id: number;
  public value: string;
}

export class CustomFieldLookupModel {
  constructor() {
    this.id = 0;
    this.label = '';
    this.labelLocale = '';
  }
  public id: any;
  public label: string;
  public labelLocale?: string;
  public isSelected: boolean;
  public isDeleted: boolean;
  public order: number;
  public color: string;
  public conditions: Condition[] = [];
  public formGroup?: FormGroup = new FormGroup({});
}

export class LookUpOptions {
  public id: number;
  public name: string;
  public color: string;
  public checked: boolean;
}
export class StatusOptions {
  createdBy: any;
  createdByName: any;
  createdOn: Date;
  id: number;
  isActive: boolean;
  name: string;
  organizationId: number;
  usedIn: any;
}

export class SubApplicationOptions {
  public id: number;
  public definitionName: string;
  public applicantName: string;
}

export class ImportLookupFieldModel extends CustomFieldModel {
  public nestedLookupCustomFields?: CustomFieldModel[];
}

export class RollUp {
  constructor() {
    this.conditions = [];
  }
  public aggregation: string;
  public aggregatedFieldId: number;
  public objectId: number;
  public objectType: string;
  public conditions: Condition[];
}

export class RollUpOptions {
  public lookUps: LookupAdonsModel[] = [];
  public applicationDefinitions: ApplicationDefinition[] = [];
}

export class Condition {
  constructor() {
    this.currencies = [];
  }
  public order: number;
  public andOr: string;
  public fieldPath: string;
  public fieldType: FeildType;
  public operator: string;
  public value: string;
  public values: number[] = [];
  public singleMultiLookupValues: CustomFieldLookupModel[] = [];
  public lookUpOptions: LookUpOptions[] = [];
  public conditions: Condition[];
  public currencies: CurrencyModel[] = [];
  public selectedCurrency: CurrencyModel;
  public dynamicValue: boolean = true;
  public formGroup?: FormGroup;
  public statusOptions: StatusOptions[] = [];
}

export class Reference {
  public fieldPath: string;
  public type: number;
}

export class Calculate extends Reference {
  public order: number;
  public operator: string;
  public value: string;
}

export class LookUpsOnDefinition {
  public relatedFormType: string;
  public lookupId: number;
  public referenceSectionCustomFieldId: number;
  public filterSCFIdInLookup: number;
  public sourceValueSCFId: number;
  public label: string;

  constructor(lookupid?: number, refrenceSectionCustomFieldId?: number, relatedFormType?: string, filterSCFIdInLookup?: number, sourceValueSCFId?: number, label?: string) {
    this.lookupId = lookupid;
    this.referenceSectionCustomFieldId = refrenceSectionCustomFieldId;
    this.relatedFormType = relatedFormType;
    this.filterSCFIdInLookup = filterSCFIdInLookup;
    this.sourceValueSCFId = sourceValueSCFId;
    this.label = label;
  }
}

// export class LookUpFilterAllowCriteria {
//   public lookupId: number;
//   public referenceSectionCustomFieldId: number;
//   public relatedFormType: string;
//   public lookupFieldForFilterSCFId: number;
//   public label: string;
// }

export class RelatedLookupReferenceModel {
  public lookupId: number;
  public refrenceSectionCustomFieldId: number;
  public relatedFormType: string;
  public lookupFieldForFilterSCFId: number;
  public label: string;

  constructor();
  constructor(lookupid?: number, refrenceSectionCustomFieldId?: number, relatedFormType?: string, lookupFieldForFilterSCFId?: number, label?: string) {
    this.lookupId = lookupid;
    this.refrenceSectionCustomFieldId = refrenceSectionCustomFieldId;
    this.relatedFormType = relatedFormType;
    this.lookupFieldForFilterSCFId = lookupFieldForFilterSCFId;
    this.label = label;
  }
}

export class LookupFilters {
  public usedIn: string;
  public lookupId: number;
  public referenceSectionCustomFieldId: number;
  public lookupFieldForFilterSCFId: number;
  public label: string;
}

export class FieldVisibilityModel {
  public type: FieldVisibility;
  public visibilityConditions: Condition[];
}

export class PriceAllowedValuesCriteria{
  allowed: number[];
  default: number;
  units: string[];
}
export class FieldUniqueCheckModel{
  public message: string;
  public isUnique: boolean;
}

export interface FieldAction{
  type: string; 
  targetFieldSCFId: number; 
  targetFieldValue: string 
}