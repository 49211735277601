import { NgModule } from '@angular/core';
import { AvatarModule } from 'ngx-avatar';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import { OrgAccountsListComponent } from './org-accounts-list/org-accounts-list.component';
import { EspControlsModule } from '../../custom-material/esp-controls.module';

@NgModule({
  declarations: [
    OrgAccountsListComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    FlexLayoutModule,
    TranslateModule,
    EspControlsModule
  ],
  exports: [
    OrgAccountsListComponent
  ],
  providers: [
    
  ]
})
export class AccountSharedModule {

}
