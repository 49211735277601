<div class="main-content-height newDesign-container" fxFill fxLayout="row" fxLayoutAlign="center stretch">
  <div fxFlex>
    <div fxFlex="30%" fxLayout="column" fxShow fxHide.lt-md="true" fxHide.lg="false"
      class="gray-card defination-detail defination-detail-margin" fxLayoutAlign="center center">
      <div fxLayout="column" fxFlex fxLayoutAlign="center start" fxLayoutGap="70px">
        <div fxLayout="column" fxFlex fxLayoutGap="87px" fxLayoutAlign="center start">
          <div fxFlex class="gray-card-icon">
            <img src="../../../assets/images/ic_step_login.svg" alt="">
            <div class="title" fxLayoutAlign="center center" translate="SubmitForm.Let-us">
            </div>
            <div class="title-description float-right" fxLayoutAlign="center center">
              <div class="des-inner" translate="SubmitForm.Sign-up">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFill fxFlex="70%" fxLayout="column" fxFlex.lt-md="100%" class="white-card application-detail">
      <div fxFill class="inner-formContainer">
        <div style="text-align: center; border-bottom: 1px solid #10c164;" class="mobile-containers">
          <img style="height: 50px;" src="../../../assets/images/systemlogo.png" alt="">
        </div>
        <div class="title mobile-containers" fxLayoutAlign="center center" fxShow fxHide.lt-md="false" fxHide.lg="true"
          style="font-weight: bold; margin-top: 18px !important; text-align: center;">
          <div class="" style="font-size:24PX; padding-top:0px; text-align: center; display: inline-block;">
            <div fxLayoutAlign="start center">
              Welcome!
            </div>
          </div>
        </div>
        <form [formGroup]="authenticationForm" novalidate class="resp-form submit-loginContainer">
          <div fxLayout="column" class="newdesign-definition-info definition-info-container fields-container-box">
            <div fxLayout="column" class="definition-info-container">
              <mat-form-field fxFlex>
                <input matInput #Email formControlName="email" placeholder="{{'EMAIL-ID' | translate}}" name="email"
                  class="login-input" (blur)="applicantAlreadyIsAMember()" required>
                <mat-error *ngIf="email.errors && email.errors.required" translate="RequiredMessages.EMAIL-REQUIRED">
                </mat-error>
                <mat-error *ngIf="email.errors && email.errors.email && !email.errors.required"
                  translate="RequiredMessages.EMAIL-INVALID"></mat-error>
              </mat-form-field>
              <mat-form-field fxFlex *ngIf="!applicantNotActive">
                <input matInput formControlName="fullName" placeholder="{{'Common.FULL-name' | translate}}"
                  name="fullName" required [readonly]="isApplicantAlreadyMember" />
                <mat-error *ngIf="fullName.errors && fullName.errors.required"
                  translate="RequiredMessages.FULLNAME-REQUIRED"></mat-error>
                <mat-error *ngIf="fullName.errors && fullName.errors.minlength"
                  translate="RequiredMessages.FULLNAME-MINIMUM-CHARACTERS"></mat-error>
              </mat-form-field>

              <div *ngIf="applicantNotActive" style="margin-top:10px;">
                <span translate="ACCOUNT-PASSWORD-NOT-SET.Message" class="details-content"></span>
                <br /><br />
                <span (click)="sendInviteLink()" class="theme-color cursor-pointer"
                  translate="Resend-Invite">here</span>
              </div>
              <mat-form-field fxFlex *ngIf="isApplicantAlreadyMember">
                <input matInput #Password formControlName="password" type="password"
                  placeholder="{{'PASSWORD' | translate}}" name="password" required>
                <mat-error *ngIf="password.invalid">{{getPasswordErrorMessage()}}</mat-error>
              </mat-form-field>
              <div [@slideUpDown]="isLoginError && !password.invalid" *ngIf="isLoginError && !password.invalid"
                class="login-error" [class.margin-t18]="password.invalid">
                {{loginErrorMsg}}
                <br />
                <br />
              </div>
              <div *ngIf="isApplicantAlreadyMember">
                <br *ngIf="password.invalid" />
                <a mat-anchor class="frgt-pass" (click)="goto()">{{'FORGOT-PASSWORD' | translate}}</a>
              </div>
            </div>
          </div>
          <div class="newButton-containers">
            <div fxFlex fxLayoutAlign="end end">
              <button type="button" style="margin-right: 0;" mat-button class="add-button-gradiant"
                [disabled]="email.invalid || fullName.invalid || isCallback || (isApplicantAlreadyMember && password.invalid)"
                (click)="continueToSubmission()">
                {{"Common.CONTINUE" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>