<div fxLayout="row" fxLayoutGap="15px" [dir]="(dataSharingService.isRtl)?'rtl':'ltr'">
  <div fxFlex fxLayoutGap="15px" class="bg-alert" [style.background-color]="bgColor" >
    <div fxFlex fxLayoutAlign="start center" class="content-width">
      <span class="alert-icon">
        <i class=""  *ngIf="isSuccess">
          <img src="../../../assets/images/ic_toast_approve.png" />
        </i>
        <i class="" *ngIf="!isSuccess">
          <img src="../../../assets/images/ic_toast_reject.png" />
        </i>
      </span>
      {{textMessage}}
    </div>
  </div>
</div>
