import { Component, OnInit, Input } from '@angular/core';
import { LabelService } from '../../common/services/label-service';

@Component({
  selector: 'app-header-before-login',
  templateUrl: './header-before-login.component.html',
  styleUrls: ['./header-before-login.component.less']
})
export class HeaderBeforeLoginComponent implements OnInit {

  @Input()
  customClasses = { headerUpdate: '' };

  systemLogoExists: boolean = true;

  constructor(public labelService: LabelService) { }

  ngOnInit() {
  }

  isSystemLogoExist(e) {
    this.systemLogoExists = false;
  }
}
