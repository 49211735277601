import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidatorsModule } from '../common/modules/validators.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { AvatarModule } from 'ngx-avatar';

/*Components*/
import { AddLookupAdOnItemDialogComponent } from './add-lookup-ad-on-item-dialog/add-lookup-ad-on-item-dialog.component';
import { AlertComponent } from './alert/alert.component';
import { ApplicantProfileDetailsComponent } from './applicant-profile-details/applicant-profile-details.component'
import { ApplicantProfileAddSectionComponent } from './applicant-profile-add-section/applicant-profile-add-section.component';
import { NoResultComponent } from './no-result/no-result.component';
import { NoResultComponent2 } from './no-result-2/no-result-2.component';
import { ToastAlertComponent } from './toast-alert/toast-alert.component';
import { CapitalizeFirstPipe } from '../common/pipes/capitalize-first.pipe';
import { HeaderBeforeLoginComponent } from './header-before-login/header-before-login.component';
import { orderByPipe } from '../common/pipes/order-by-pipe';
import { LookupFieldNotIsTitleFilter } from '../common/pipes/lookup-adons-ismain-pipe';
import { FilterPipe } from '../common/pipes/filter.pipe';
import { FilterVisibleToApplicantPipe } from '../common/pipes/filter-visible-to-applicant.pipe';
import { CustomFieldValueTextOnlyComponent } from './custom-field-value-text-only/custom-field-value-text-only.component';
import { LookupItemsListComponent } from './lookup-items-list/lookup-items-list.component';
import { CustomFieldsTemplateFormComponent } from './custom-fields-template-form/custom-fields-template-form.component';
import { ErrorMessageDialogComponent } from './error-message-dialog/error-message-dialog.component';
import { DatabaseMapperDialogComponent } from './database-mapper/database-mapper-dialog/database-mapper-dialog.component';
import { DatabaseMapperService } from './database-mapper/database-mapper.service';
import { ColumnTypeFilter } from '../common/pipes/column-type-filter.pipe';
import { CustomFieldDatabaseMapperFilterPipe, LookupValuesSelectedFilterPipe } from '../common/pipes/custom-field-visible-filter.pipe';
import { DisableNotificationDialogComponent } from './disable-notification-dialog/disable-notification-dialog.component';
import { LocaleMapperService } from './locale-mapper/locale-mapper.service';
import { TranslateModule } from '@ngx-translate/core';
import { NoRecordComponent } from './no-record/no-record.component';
import { FeedbackViewComponent } from './feedback-view/feedback-view.component';
import { CustomInputComponent } from '../custom-material/custom-input/custom-input.component';
import { EditableDivDirective } from '../common/directives/editable-div.directive';
import { FormatFieldsPipe, Safe } from '../common/pipes/format-fields.pipe';
import { PasswordShowHideDirective } from '../common/directives/password-showhide.directive';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddApplicantRequestComponent } from '../applicant-request/add-applicant-request/add-applicant-request.component';
import { SubmitApplicantRequestComponent } from '../applicant-request/submit-applicant-request/submit-applicant-request.component';
import { PaginationConfigService } from '../common/services/pagination-service';
import { LongPressDirective } from '../common/directives/long-press.directive';
import { EspEditorComponent } from '../custom-material/esp-editor/esp-editor.component';
import { StemexSearchComponent } from '../custom-material/stemex-search/stemex-search.component';
import { SearchControlComponent } from './search-control/search-control.component';
import { CustomFieldTemplateControlOnlyComponent } from './custom-field-template-control-only/custom-field-template-control-only.component';
import { ImportHistoryService } from './database-mapper/import-history.service';
import { ConditionFilter } from '../common/pipes/condition-filter.pipe'
import { ApplicationDetailsHelperService } from '../common/services/application-details-helper.service'
import { ActionFieldsByTypeFilter } from '../common/pipes/action-fields-by-type-filter.pipe';
import {CustomDatePipe} from '../common/pipes/customDate.pipe';
//Delegation Imports
import { DelegationComponent } from './delegation/delegation/delegation.component';
import { ViewDelegationComponent } from './delegation/view-delegation/view-delegation.component';
import { RevokeDelegationComponent } from './delegation//revoke-delegation/revoke-delegation.component';
import { ReassignDialogComponent } from './re-assign-dialog/re-assign-dialog.component';
import { AttachmentControlComponent } from './attachment-control/attachment-control.component';
import { ApplicantRequestAuthenticationComponent } from '../applicant-request/applicant-request-authentication/applicant-request-authentication.component'
import { ApplicantProfileV2Component } from './applicant-profile-v2/applicant-profile-v2.component';
import { RecursiveFieldsComponent } from './database-mapper/recursive-fields/recursive-fields.component';
import { DeleteFieldDialogComponent } from './delete-field-dialog/delete-field-dialog.component';
import { ApplicationStagesDetailsComponent } from './application-stages-details/application-stages-details.component';
import { ApplicationListItemComponent } from '../application/application-list-item/application-list-item.component';
import { GenericConfirmationDialogComponent } from './generic-confirmation-dialog/generic-confirmation-dialog.component';

import { ApplicationCriteriaDetailsComponent } from './application-criteria-details/application-criteria-details.component';
import { AddFiltersComponent } from './add-filters/add-filters.component';
import { FiltersService } from '../common/services/filters.service';
import { CriteriaHelperService } from '../common/services/criteria-helper.service';

//Exceeders Import
import { SignatureComponent } from './application-stages-details/signature/signature.component';
import { CriteriaCommentsListComponent } from './application-stages-details/criteria-comments-list/criteria-comments-list.component';
import { LinkDefinitionCardComponent } from './link-definition-card/link-definition-card.component';
import { LeftSectionsComponent } from './submit-request-exceeders/left-sections/left-sections.component';
import { CirclesSectionsComponent } from './submit-request-exceeders/circles-sections/circles-sections.component';
import { RequestSubmittedConfirmationDialogComponent } from './submit-request-exceeders/request-submitted-confirmation-dialog/request-submitted-confirmation-dialog.component';
import { UsersSelectListComponent } from './users-select-list/users-select-list.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { DefinationVisibilityDialogComponent } from './defination-visibility-dialog/defination-visibility-dialog.component';


import { ImageVerificationModule } from '../image-verification/image-verification.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PipesModule } from '../shared-modules/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { EspControlsModule } from '../custom-material/esp-controls.module';
import { EspSnackbarComponent } from '../custom-material/esp-snackbar/esp-snackbar.component';
import { CriteriaPermissionsMenuComponent } from './application-stages-details/criteria-permissions-menu/criteria-permissions-menu.component';
import { AskQuestionComponent } from './application-stages-details/ask-question/ask-question.component';
import { WhoCanSubmitComponent } from '../applicant-request/submit-applicant-request/who-can-submit/who-can-submit.component';
import { StagePermissionsMenuComponent } from './application-stages-details/stage-permissions-menu/stage-permissions-menu.component';
import { ReopenStageDialogComponent } from './application-stages-details/reopen-stage-dialog/reopen-stage-dialog.component';
import { ReopenStageCommentsComponent } from './application-stages-details/reopen-stage-comments/reopen-stage-comments.component';
import { CriteriaFormComponent } from './application-stages-details/criteria-form/criteria-form.component';
import { ApplicationSharedModule } from '../shared-modules/application-shared/application-shared.module';
import { SubmissionsTabsComponent } from './application-stages-details/submissions-tabs/submissions-tabs.component';
import { StopSubmissionsDialogComponent } from './application-stages-details/submissions-tabs/stop-submissions-dialog/stop-submissions-dialog.component';
import { AddSubmissionDialogComponent } from './application-stages-details/submissions-tabs/add-submission-dialog/add-submission-dialog.component';
import { FormSubmissionSharedModule } from '../shared-modules/form-submission-shared/form-submission-shared.module';
import { EmailVerificationComponent } from '../applicant-request/submit-applicant-request/email-verification/email-verification.component';
import { SharedDialogModule } from './shared-dialog.module';
import { FilterHistoryCriteria } from '../common/pipes/filter-history-criteria.pipe';
import { CriteriaViewFormDialogComponent } from './application-stages-details/criteria-view-form-dialog/criteria-view-form-dialog.component';
import { EditApplicationsProgressDialogComponent } from '../applicant-request/submit-bulk-applicant-request/edit-applications-progress-dialog/edit-applications-progress-dialog.component';
import { EspProgressBarComponent } from '../custom-material/esp-progress-bar/esp-progress-bar.component';
import { CountUpDirective } from '../common/directives/count-animation.directive';
import { EditApplicationsStatusDialogComponent } from '../applicant-request/submit-bulk-applicant-request/edit-applications-status-dialog/edit-applications-status-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule, ReactiveFormsModule,
    CustomMaterialModule,
    ValidatorsModule,
    SortablejsModule,
    FormsModule,
    AvatarModule,
    TranslateModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    PipesModule,
    RouterModule,
    EspControlsModule,
    ApplicationSharedModule,
    FormSubmissionSharedModule,
    SharedDialogModule,
    FilterHistoryCriteria,
    BreadcrumbsComponent,
    CountUpDirective,
    EspProgressBarComponent
  ],
  declarations: [AlertComponent, ApplicantProfileDetailsComponent, ApplicantProfileAddSectionComponent, NoResultComponent, NoResultComponent2, ToastAlertComponent, EspSnackbarComponent,
    CapitalizeFirstPipe,
    HeaderBeforeLoginComponent, FilterVisibleToApplicantPipe, orderByPipe
    , LookupFieldNotIsTitleFilter, FilterPipe
    , AddLookupAdOnItemDialogComponent
    , CustomFieldValueTextOnlyComponent
    , LookupItemsListComponent, CustomFieldsTemplateFormComponent, ErrorMessageDialogComponent, DatabaseMapperDialogComponent
    , ColumnTypeFilter, CustomFieldDatabaseMapperFilterPipe, LookupValuesSelectedFilterPipe, DisableNotificationDialogComponent, NoRecordComponent, FeedbackViewComponent
    , CustomInputComponent, EditableDivDirective, FormatFieldsPipe, Safe, PasswordShowHideDirective,
    AddApplicantRequestComponent, ApplicantRequestAuthenticationComponent, LongPressDirective, EspEditorComponent, StemexSearchComponent, SearchControlComponent, CustomFieldTemplateControlOnlyComponent
    , DelegationComponent
    , ReassignDialogComponent
    , SubmitApplicantRequestComponent
    , WhoCanSubmitComponent
    , EmailVerificationComponent
    , ViewDelegationComponent
    , RevokeDelegationComponent, AttachmentControlComponent, ApplicantProfileV2Component, RecursiveFieldsComponent
    , ConditionFilter
    , ActionFieldsByTypeFilter
    , CustomDatePipe
    , DeleteFieldDialogComponent
    , ApplicationStagesDetailsComponent
    , ReopenStageCommentsComponent
    , ReopenStageDialogComponent
    , StagePermissionsMenuComponent
    , ApplicationCriteriaDetailsComponent
    , ApplicationListItemComponent
    , GenericConfirmationDialogComponent
    , LinkDefinitionCardComponent
    , AddFiltersComponent
    , SignatureComponent
    , CriteriaCommentsListComponent,
    LeftSectionsComponent,
    CirclesSectionsComponent,
    RequestSubmittedConfirmationDialogComponent,
    UsersSelectListComponent,
    DefinationVisibilityDialogComponent,
    CriteriaPermissionsMenuComponent,
    AskQuestionComponent,
    CriteriaFormComponent,
    SubmissionsTabsComponent,
    StopSubmissionsDialogComponent,
    AddSubmissionDialogComponent,
    CriteriaViewFormDialogComponent,
    EditApplicationsProgressDialogComponent,
    EditApplicationsStatusDialogComponent
  ],
  exports: [AlertComponent, ApplicantProfileDetailsComponent, NoResultComponent, NoResultComponent2, ToastAlertComponent, EspSnackbarComponent
    , CapitalizeFirstPipe, FilterVisibleToApplicantPipe, orderByPipe
    , LookupFieldNotIsTitleFilter, FilterPipe
    , AddLookupAdOnItemDialogComponent
    , CustomFieldValueTextOnlyComponent
    , LookupItemsListComponent, CustomMaterialModule, CustomFieldsTemplateFormComponent, ErrorMessageDialogComponent
    , ColumnTypeFilter, CustomFieldDatabaseMapperFilterPipe, LookupValuesSelectedFilterPipe, DisableNotificationDialogComponent
    , TranslateModule, NoRecordComponent, FeedbackViewComponent
    , CustomInputComponent, EditableDivDirective, FormatFieldsPipe, Safe, PasswordShowHideDirective,
    AddApplicantRequestComponent, ApplicantRequestAuthenticationComponent, LongPressDirective, EspEditorComponent, StemexSearchComponent, SearchControlComponent
    , ValidatorsModule
    , SubmitApplicantRequestComponent
    , DelegationComponent
    , ReassignDialogComponent
    , ViewDelegationComponent
    , RevokeDelegationComponent, AttachmentControlComponent
    , ApplicantProfileV2Component, RecursiveFieldsComponent, DeleteFieldDialogComponent
    , ConditionFilter
    , ActionFieldsByTypeFilter
    , CustomDatePipe
    , ApplicationStagesDetailsComponent
    , ApplicationCriteriaDetailsComponent
    , ApplicationListItemComponent
    , GenericConfirmationDialogComponent
    , LinkDefinitionCardComponent
    , AddFiltersComponent,
    LeftSectionsComponent,
    CirclesSectionsComponent,
    RequestSubmittedConfirmationDialogComponent,
    BreadcrumbsComponent,
    ImageVerificationModule,
    DefinationVisibilityDialogComponent,
    PipesModule,
    WhoCanSubmitComponent,
    SharedDialogModule,
    FormSubmissionSharedModule,
    CriteriaViewFormDialogComponent,
    EditApplicationsProgressDialogComponent,
    EditApplicationsStatusDialogComponent
  ],
  providers: [DatabaseMapperService, ImportHistoryService, LocaleMapperService, PaginationConfigService, CustomFieldDatabaseMapperFilterPipe, LookupValuesSelectedFilterPipe, ApplicationDetailsHelperService, FiltersService, CriteriaHelperService],
  entryComponents: [ApplicantProfileAddSectionComponent, ToastAlertComponent, EspSnackbarComponent
    , AddLookupAdOnItemDialogComponent, ErrorMessageDialogComponent, DatabaseMapperDialogComponent, DisableNotificationDialogComponent,
    AddApplicantRequestComponent
    , SubmitApplicantRequestComponent
    , ApplicantRequestAuthenticationComponent
    , ApplicantProfileV2Component
    , DelegationComponent
    , ReassignDialogComponent
    , ViewDelegationComponent
    , RevokeDelegationComponent
    , DeleteFieldDialogComponent
    , GenericConfirmationDialogComponent,
    RequestSubmittedConfirmationDialogComponent,
    DefinationVisibilityDialogComponent,
    ReopenStageDialogComponent
  ]
})

export class SharedModule { }
