import { NgModule } from '@angular/core';
import { CustomMaxDirective } from '../../common/validators/custom-max-validator.directive';
import { CustomMinDirective } from '../../common/validators/custom-min-validator.directive';
import { FileValueAccessor } from '../../common/validators/input-file-required-validator.directive';
import { FileValidator } from '../../common/validators/input-file-required-validator.directive';
import { EmailsValidatorDirective } from '../validators/emails.validator';

import { CustomFieldVisibleFilterPipe, ProfileSectionActiveAndVisibleFilterPipe, SectionActiveAndVisibleFilterPipe } from '../../common/pipes/custom-field-visible-filter.pipe';
import { CheckboxGroupRequiredValidator } from '../validators/checkbox-group-required-validator.directive';
import { textAlreadyExistsValidator } from '../validators/text-already-exists';
import { CustomInputValidatorDirective } from '../validators/custom-input-email.validator';
import { CustomHyperLinkDirective } from '../validators/custom-hyperlink-validator.directive';
import { CustomPhoneNumberDirective } from '../validators/custom-phonenumber-validator.directive';
import { LocaleRequiredDirective } from '../validators/locale-required.directive';
import { CustomRequiredDirective } from '../validators/custom-required-validator.directive';
import { CustomDateDirective } from '../validators/tagableField-validator.directive';
import { NumericOnlyDirective } from '../validators/editablediv-numeric-value-only.directive';
import { CurrencyValidatorDirective } from '../validators/currency-validator.directive';
import { FileDropDirective } from '../validators/drag-and-drop.directive';


@NgModule({
  imports: [
  ],
  declarations: [
    CustomMaxDirective,
    CustomMinDirective,
    CustomPhoneNumberDirective,
    CustomHyperLinkDirective,
    FileValueAccessor,
    FileValidator,
    CheckboxGroupRequiredValidator,
    textAlreadyExistsValidator,
    ProfileSectionActiveAndVisibleFilterPipe,
    CustomFieldVisibleFilterPipe,
    EmailsValidatorDirective,
    CustomInputValidatorDirective,
    SectionActiveAndVisibleFilterPipe,
    LocaleRequiredDirective,
    CustomRequiredDirective,
    CustomDateDirective,
    NumericOnlyDirective,
    CurrencyValidatorDirective,
    FileDropDirective
  ],
  exports: [
    CustomMaxDirective,
    CustomMinDirective,
    CustomHyperLinkDirective,
    CustomPhoneNumberDirective,
    FileValueAccessor,
    FileValidator,
    CheckboxGroupRequiredValidator,
    textAlreadyExistsValidator,
    ProfileSectionActiveAndVisibleFilterPipe,
    CustomFieldVisibleFilterPipe,
    EmailsValidatorDirective,
    CustomInputValidatorDirective,
    SectionActiveAndVisibleFilterPipe,
    LocaleRequiredDirective,
    CustomRequiredDirective,
    CustomDateDirective,
    NumericOnlyDirective,
    CurrencyValidatorDirective,
    FileDropDirective
  ],
  providers: [],
  entryComponents: []
})
export class ValidatorsModule { }
