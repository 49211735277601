import { Pipe, PipeTransform } from '@angular/core';
import { LocaleMapper } from '../../shared/locale-mapper/locale-mapper';

@Pipe({
  name: 'emptyArrayOnDefaultLocale'
})
export class EmptyArrayOnDefaultLocalePipe implements PipeTransform {

  transform(value: any, args?: LocaleMapper): any {
    if (args.activeLocale.locale == args.defaultLocale.locale) {
      return value;
    }
    return [];
  }

}
